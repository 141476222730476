import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Select from 'react-select';

import { setBranchSelected } from "../../Branch/_redux/branchActions";

export const BranchDropDown = () => {
  const dispatch = useDispatch();

  const { branches, auth } = useSelector((state) => ({
    branches: state.branches,
    auth: state.auth,
  }), shallowEqual);

  let colourOptions = [
    {value: 1, label: 'branch 1'},
    {value: 2, label: 'branch 2'},
    {value: 3, label: 'branch 3'},
  ];

  useEffect(() => {
    console.log(branches);
  }, [branches])

  const setBranch = (value) => {
    dispatch(setBranchSelected(value));

    console.log(auth);
  }

  return (
    <>
      {branches.branchesCompany && <Select
        className="basic-single pr-2"
        value={auth.branchSelected}
        options={branches.branchesCompany}
        getOptionValue={(option) => option.id && `${option['id']}`}
        getOptionLabel={(option) => option.attributes && `${option.attributes.name}`}
        isDisabled={branches.branchesCompany.length <= 1}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        name="color"
        onChange={value => setBranch(value)}
        styles={{
          container: (baseStyles, state) => ({
            ...baseStyles,
            height: 'min-content',
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: '200px',
            marginTop: '1rem'
          }),
        }}
      />}
    </>
  );
}
