import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import { getUserByToken } from "./authCrud";

import { populateBranchesCompany } from "../../Branch/_redux/branchActions";
import { fetchLogged } from "./authActions";

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const { branches, authToken } = useSelector((state) => ({
    authToken: state.auth.authToken,
    branches: state.branches,
  }), shallowEqual);

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          dispatch(fetchLogged()).then(() => {
            setShowSplashScreen(false);
          }).finally(() => {
            setShowSplashScreen(false);
          });
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          dispatch(props.logout());
        }

        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined));
      dispatch(populateBranchesCompany([]));

      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
