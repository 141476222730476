import * as requestFromServer from "./creditPurchaseCrud";
import { creditPurchaseSlice, callTypes } from "./creditPurchaseSlice";
import { notifyError } from "../../../utils/Notify";

const { actions } = creditPurchaseSlice;

export const fetchCreditPurchases = (data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    per_page: data.perPage,
    page: data.page,
  };

  if (data.statusId) {
    params.status_id = data.statusId;
  }

  if (data.branchId) {
    params.branch_id = data.branchId;
  }

  if (data.clientId) {
    params.client_id = data.clientId;
  }

  if (data.originType) {
    params.origin_type = data.originType;
  }

  if (data.fieldId) {
    params.field_id = data.fieldId;
  }

  if (data.duration) {
    params.duration = data.duration;
  }

  return requestFromServer
    .getCreditPurchases(params)
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.creditPurchasesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.creditPurchaseMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.creditPurchasesFetched({ totalCount: 0, entities: null }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'client-failed-update', 2000);
      }
    });
};

export const fetchAvailableCreditPurchases = (data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    origin_type: data.originType,
    field_id: data.fieldId,
    branch_id: data.branchId,
    duration: data.duration,
  };

  if (data.date) {
    params.date = data.date;
  }

  return new Promise((resolve, reject) => {
    requestFromServer
      .getAvailableCreditPurchases(data.clientId, params)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response &&
          error.response.data &&
          error.response.data.message &&
          (typeof error.response.data.message) == "string"
        ) {
          notifyError(error.response.data.message, 'client-failed-list', 2000);
        }

        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);

      });
    });
};

export const fetchGetClientCreditPurchases = (clientId, branchId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .getClientCreditPurchases(clientId, branchId)
      .then(response => {
        dispatch(actions.loadingFinish());
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);
      });
  });
};

export const fetchCreditPurchase = (creditPurchaseId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCreditPurchaseById(creditPurchaseId)
    .then(response => {
      const creditPurchase = response.data;


      dispatch(actions.creditPurchaseFetched({ creditPurchase: creditPurchase }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.creditPurchaseFetched({ creditPurchase: {} }));
    });
};

export const clearCreditPurchase = () => dispatch => {
  dispatch(actions.creditPurchaseFetched({ creditPurchase: {} }));
};

export const fetchCreateCreditPurchase = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .createCreditPurchase(params).then(response => {
        dispatch(actions.creditPurchaseFetched({ creditPurchase: response.data }));
        dispatch(actions.creditPurchaseCreated());
        resolve(response.data);
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const fetchRenewCreditPurchase = (creditPurchaseId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .renewCreditPurchase(creditPurchaseId)
    .then(response => {
      dispatch(actions.creditPurchaseRenewed());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateCreditPurchaseExpiredAt = (creditPurchaseId, expiredAt) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateCreditPurchase(creditPurchaseId, {expired_at: expiredAt ?? null})
    .then(response => {
      dispatch(actions.creditPurchaseUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDeleteCreditPurchase = (creditPurchaseId, canceledAt) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deleteCreditPurchase(creditPurchaseId, { canceled_at: canceledAt })
    .then(response => {
      dispatch(actions.creditPurchaseUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};