import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  selected: {},
  entityForEdit: undefined,
  lastError: null,
  created: false,
  updated: false,
  photoUpdated: false,
  error: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const userSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;

      if (
        action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    usersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      return {
        ...state,
        entities: entities,
        totalCount: totalCount,
        listLoading: false,
        lastError: null,
        created: false,
        updated: false,
        photoUpdated: false,
        error: null,
      };
    },

    userCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.created = true;
      state.photoUpdated = false;
    },

    userUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.updated = true;
      state.photoUpdated = false;
    },

    userPhotoUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.photoUpdated = true;
    },
  },
});
