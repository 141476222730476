import React, { useState, useEffect } from "react";

import { lighten, makeStyles } from '@material-ui/core/styles';

import { ContractList } from '../components/ContractList';

const useStyles = makeStyles(theme => ({
  //
}));

export const ContractPage = () => {
  const classes = useStyles();
  return (<>
      <ContractList />
  </>);
}
