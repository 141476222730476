import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/login`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/api/auth/register`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/reset-password`;
export const ME_URL = `${process.env.REACT_APP_API_URL}/logged`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function resetPasswordStartFlow(email) {
  return axios.post(RESET_PASSWORD_URL, { email });
}

export function resetPasswordConfirmNewPassword(
  reset_token,
  password,
  password_confirmation
) {
  console.log({ reset_token, password, password_confirmation });
  return axios.put(RESET_PASSWORD_URL, {
    hash: reset_token,
    password,
    password_confirmation,
  });
}

export function getUserByToken() {
  return axios.get(ME_URL);
}
