import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entity: undefined,
  lastError: null,
  created: false,
  updated: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const branchRecipientSlice = createSlice({
  name: "branchRecipients",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;

      if (action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    branchRecipientFetched: (state, action) => {
      const { entity } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entity = entity;
      state.created = false;
      state.updated = false;
    },

    branchRecipientsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.created = false;
      state.updated = false;
      state.totalCount = totalCount;
    },

    branchRecipientCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.created = true;
    },

    branchRecipientUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.updated = true;
    },
  }
});
