import * as requestFromServer from "./paymentChargebackCrud";
import { paymentChargebackSlice, callTypes } from "./paymentChargebackSlice";
import { notifyError } from "../../../utils/Notify";

const { actions } = paymentChargebackSlice;

export const fetchPaymentChargebacks = (data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    per_page: data.perPage,
    page: data.page,
  };

  if (data.statusId) {
    params.status_id = data.statusId;
  }

  if (data.branchId) {
    params.branch_id = data.branchId;
  }

  if (data.clientId) {
    params.client_id = data.clientId;
  }

  if (data.originType) {
    params.origin_type = data.originType;
  }

  if (data.fieldId) {
    params.field_id = data.fieldId;
  }

  if (data.duration) {
    params.duration = data.duration;
  }

  return requestFromServer
    .getPaymentChargebacks(params)
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.paymentChargebacksFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.paymentChargebackMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.paymentChargebacksFetched({ totalCount: 0, entities: null }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'payment-chargeback-list-error', 2000);
      }
    });
};

export const fetchApprovePaymentChargeback = (paymentChargebackId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .approvePaymentChargeback(paymentChargebackId)
    .then(response => {
      dispatch(actions.paymentChargebackUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCancelPaymentChargeback = (paymentChargebackId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .cancelPaymentChargeback(paymentChargebackId)
    .then(response => {
      dispatch(actions.paymentChargebackUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};