import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import { format } from 'date-fns';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import ControlPoint from '@material-ui/icons/ControlPoint';
import CloseIcon from '@material-ui/icons/Close';

import { RenderError } from "../../../components/RenderError";
import { notifySuccess } from "../../../utils/Notify";
import DayPicker from '../../../components/DayPicker';
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CreateClient from "../../Client/components/CreateClient";
import EditPlan from "../../Plan/components/EditPlan";

import { makeStyles } from '@material-ui/core/styles';

import { fetchSignatures, fetchCreateSignature } from "../_redux/signatureActions";
import { fetchClientName } from "../../Client/_redux/clientActions";
import { fetchPlans } from "../../Plan/_redux/planActions";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
  lineThrough: {
    textDecoration: 'line-through',
    fontSize: '10px'
  },
}));

const CreateSignature = (props) => {
  const dispatch = useDispatch();
  const { signatures, clients, plans, branchSelected } = useSelector((state) => ({
    signatures: state.signatures,
    clients: state.clients,
    plans: state.plans,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();
  const nameRef = useRef(null);

  const [rows, setRows] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [showClient, setShowClient] = useState(false);
  const [startDateAt, setStartDateAt] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});

  useEffect(() => {
    if (signatures.created == true) {
      notifySuccess('Cadastrado com sucesso', 'signatures-created', 2000);
    }
  }, [signatures]);

  useEffect(() => {
    if (plans.entities) {
      setRows(plans.entities);
    }
  }, [plans]);

  useEffect(() => {
    if (props.show) {
      nameRef.current.focus();

      setStartDateAt(new Date);
      formik.setFieldValue('billing_day', moment().format('DD'), false);

      dispatch(fetchPlans('active', 'signature', '', branchSelected.id));
    }

    if (!props.show) {
      close();
    }
  }, [props.show]);

  const renew = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const close = () => {
    props.onHide();
    setShowClient(false);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('billing_day', '', false);
    formik.setFieldValue('auto_renew', 'yes', false);

    setSelectedClient({});
    setSelectedPlan({});
    setStartDateAt(new Date);
  }

  const selectClient = (client) => {
    setSelectedClient(client);
  }

  const searchClientName = () => {
    if (formik.values.name && formik.values.name.length >= 2) {
      const clientNameRequestTimeout = setTimeout(() => {
        dispatch(fetchClientName(formik.values.name, branchSelected.id));
        setShowClient(true);
      }, 400);

      return () => {
        clearTimeout(clientNameRequestTimeout);
      };
    }
  }

  const planSelected = (value) => {
    if (selectedPlan.id && value == selectedPlan.id) {
      return true
    }

    return false;
  }

  const openEditEntity = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }

  const formik = useFormik({
    initialValues: {
      billing_day: '',
      auto_renew: 'yes',
    },
    // validationSchema: createClientsignatureschema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        let params = {
          auto_renew: values.auto_renew == 'yes' ? true : false,
          plan_id: selectedPlan.id,
          client_id: selectedClient.id,
          billing_day: values.billing_day,
          start_at: moment(startDateAt).format('YYYY-MM-DD'),
          branch_id: branchSelected.id,
        };

        dispatch(fetchCreateSignature(params));
      }, 200);
    },
  });

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);


  const headRows = [
    { id: 'check', numeric: false, disablePadding: false, label: '' },
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Preço' },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  const showCard = (entities, title = '') => {
    return <>

        <div className={classes.tableWrapper}>
          <Table
            key="tableWrapper"
                className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                aria-labelledby="tableTitle"
                size="large"
          >
            <TableHead>
              <TableRow>
                {headRows.map(row => (
                  <TableCell
                    key={row.id}
                    align="left"
                    padding={row.disablePadding ? 'none' : 'default'}
                  >
                    <span style={{
                      color: "#B5B5C3",
                      textTransform: "uppercase"
                    }}>
                    {row.label}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {entities.map((row, index) => {
                {/*const isItemSelected = isSelected(row.name);*/}
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
                        <input
                          type="checkbox"
                          key={row.id}
                          name={row.id}
                          checked={planSelected(row.id)}
                          onChange={() => setSelectedPlan(row)}
                        />
                        <span></span>
                      </label>
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.attributes.name}
                    </TableCell>

                    <TableCell align="left" className="flex">
                      {selectedClient.attributes.is_member && row.attributes.member_price && <span className="mr-2">R$ {row.attributes.member_price}</span>}
                      <span className={selectedClient.attributes.is_member && row.attributes.member_price && classes.lineThrough}>R$ {row.attributes.price}</span>

                      <br />
                      {row.attributes.type == 'signature' && <span>{row.attributes.duration} {row.attributes.duration > 1 ? 'meses' : ' mês'}</span>}
                      {row.attributes.type == 'day_use' && <span>Por dia</span>}
                    </TableCell>

                    <TableCell align="right">
                      <a
                        title="Ver plano"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => openEditEntity(row)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            title="Ver plano"
                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                          />
                        </span>
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
    </>
  }

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !createEntityShow && !editEntityShow}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cadastrar assinante
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          {!selectedClient.id && <Container maxWidth="sm">
            <Grid container spacing={2}>
              <Grid item xs={12} className="text-right">
                <a href="#" className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                  <ControlPoint fontSize="inherit" /> Cadastrar cliente
                </a>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Pesquisar por nome"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {clients.actionsLoading && <CircularProgress size={30} />}
                      </InputAdornment>
                    ),
                  }}
                  {...formik.getFieldProps("name")}
                  inputRef={nameRef}
                />

                {formik.touched.name && formik.errors.clientId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.clientId}</div>
                  </div>
                ) : null}
              </Grid>

              {showClient && <Grid item xs={12}>
                {clients.clientName.data && clients.clientName.data.map((client) =>
                  <div className={classes.detailedExpansionPanel}>
                    <DetailedExpansionPanel client={client} onSelect={selectClient} />
                  </div>
                )}
                {clients.clientName.data && !clients.clientName.data.length && <span>Nenhum cliente encontrado</span>}
              </Grid>}
            </Grid>

            {(typeof clients.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {clients.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>}

          {selectedClient.id && <>
            <Container maxWidth="sm">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">
                    Dados do assinante
                  </span>
                </Grid>

                <Grid item xs={12}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-block">Nome: {selectedClient.attributes.name}</div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-block">E-mail: {selectedClient.attributes.email}</div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-block">Telefone: {selectedClient.attributes.phone}</div>
                  </div>
                </Grid>
              </Grid>
            </Container>

            <Container maxWidth="sm">
              <Grid container className={classes.label} spacing={2}>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">Inicio da assinatura</span>
                </Grid>
                <Grid item xs={6}>
                  <DayPicker date={startDateAt} onDateChange={setStartDateAt} />

                  {signatures.error && <RenderError error={signatures.error.day} />}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    label="Renovação automatica?"
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        }
                      }
                    }}
                    name="auto_renew"
                    fullWidth
                    className={classes.textField}
                    {...formik.getFieldProps("auto_renew")}
                  >
                    {renew.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                  </TextField>

                  {signatures.error && <RenderError error={signatures.error.auto_renew} />}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="billing_day"
                    label="Dia vencimento"
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    autoComplete="off"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    {...formik.getFieldProps("billing_day")}
                    disabled={selectedPlan && selectedPlan.attributes && selectedPlan.attributes.duration > 1}
                  />

                  {formik.touched.name && formik.errors.billing_day ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.billing_day}</div>
                    </div>
                  ) : null}

                  {signatures.error && <RenderError error={signatures.error.billing_day} />}
                </Grid>

                {selectedPlan && selectedPlan.attributes && selectedPlan.attributes.duration > 1 && <Grid item xs={12}>
                  <Alert key="alert-accept-schedule" variant="primary">
                    Será gerada uma fatura única para o período do plano selecionado
                  </Alert>
                </Grid>}

                <Grid item xs={12}>
                  {showCard(rows)}

                  {signatures.error && <RenderError error={signatures.error.plan_id} />}
                </Grid>
              </Grid>
            </Container>
          </>}

          {(typeof signatures.error) == "string" && <Grid item xs={12}>
            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                  {signatures.error}
                </span>
              </div>
            </div>
          </Grid>}
        </Modal.Body>

        {selectedClient.id && <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large">
                Confirmar {(signatures.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>}
      </form>
    </Modal>

    <CreateClient show={createEntityShow} onHide={() => setCreateEntityShow(false)} />
    <EditPlan readOnly={true} show={editEntityShow} onHide={() => setEditEntityShow(false)} event={editEntity} />
  </>);
};

export default CreateSignature;