import React, { useState, useEffect } from 'react';
// import moment from "moment";
import moment from 'moment/min/moment-with-locales';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";

import TextField from '@material-ui/core/TextField';


export default function DayPicker(props) {
  moment().locale('pt-br');

  const currentMoment = moment();

  useEffect(() => {
    setValues(oldValues => ({
      ...oldValues,
      date: moment(props.date),
    }));
  }, [props.date]);

  const [values, setValues] = useState({
      focused: false,
      date: currentMoment,
  });

  const onDateChange = date => {
    setValues({
      date: date,
      focused: false
    });

    props.onDateChange(date);
  };

  const setFocused = (focused) => {
    setValues(oldValues => ({
      ...oldValues,
      focused: focused,
    }));
  }

  return (<>
    <SingleDatePicker
        focused={values.focused} // PropTypes.bool
        date={values.date}
        onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
        id="single_date_picker" // PropTypes.string.isRequired,
        numberOfMonths={1}
        hideKeyboardShortcutsPanel={true}
        isDayBlocked={() => false}
        isOutsideRange={(value) => { return props.beforeToday ? value.isBefore(new Date, 'day') : false}}
        onDateChange={onDateChange}
        displayFormat={() => "DD/MM/YYYY"}
        noBorder
        readOnly
        enableOutsideDays
      />
    </>);
}