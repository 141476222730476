import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  makeStyles,
  Grid,
  TextField,
  Container,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  OutlinedInput,
  FormControl
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { notifySuccess } from "app/utils/Notify";

import {
  fetchUpdateUser,
  fetchUpdateUserPassword,
  fetchUpdateUserPhoto
} from "../_redux/userActions";
import { createUserSchema } from "../_schemas/createUserSchema";
import { RenderError } from "../../../../components/RenderError";
import ClientPhoto from '../../../Client/components/ClientPhoto';
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    //
  },
}));

const USER_STATUS = [
  {
    name: "active",
    display_name: "Ativo",
  },
  {
    name: "blocked",
    display_name: "Bloqueado",
  },
];

const EditUser = (props) => {
  const dispatch = useDispatch();
  const { branches, userPermissions, users } = useSelector(
    (state) => ({
      branches: state.branches,
      userPermissions: state.permissions,
      users: state.users,
    }),
    shallowEqual
  );

  const classes = useStyles();
  const [openUserPhoto, setOpenUserPhoto] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const eventUserBranches = props?.event?.relationships?.branches?.map(
    (el) => el.id
  );

  useEffect(() => {
    if (props.event && props.event.id) {
      formik.setFieldValue("userId", props.event.id, false);
      formik.setFieldValue("name", props.event.attributes.name, false);
      formik.setFieldValue("email", props.event.attributes.email, false);
      formik.setFieldValue('photo', props.event.attributes.photo, false);
      formik.setFieldValue(
        "status",
        props.event.relationships.status.attributes.name,
        false
      );
      formik.setFieldValue(
        "permissions",
        props.event.attributes.permissions,
        false
      );
      formik.setFieldValue("branchesId", eventUserBranches, false);
    }
  }, [props.event]);

  useEffect(() => {
    if (users.updated) {
      close();
      notifySuccess("Atualizado com sucesso", "user-updated", 2000);
    }

    if (users.photoUpdated == true) {
      close();

      notifySuccess('Foto atualizada com sucesso', 'user-photo-updated', 2000);
    }
  }, [users]);

  const isUser = () => {
    return props.type == 'user' ? true : false;
  }

  const selectAllPermissions = () => {
    formik.setFieldValue('permissions', userPermissions.entities.map((permission) => {
      return permission.id;
    }), false);
  }

  const close = () => {
    formik.setFieldValue("userId", "", false);
    formik.setFieldValue("name", "", false);
    formik.setFieldValue("password", "", false);
    formik.setFieldValue("email", "", false);
    formik.setFieldValue("photo", "", false);
    formik.setFieldValue("branchesId", [], false);
    formik.setFieldValue("status", "", false);
    formik.setFieldValue("permissions", [], false);

    setShowChangePassword(false);

    props.onHide();
  };

  const updateUserPhoto = (photo) => {
    setOpenUserPhoto(false);

    dispatch(fetchUpdateUserPhoto(formik.values.userId, {
      image: photo
    }));
  }

  const dispatchUpdateUserPassword = () => {
    dispatch(fetchUpdateUserPassword(formik.values.userId, formik.values.password));
  }

  const formik = useFormik({
    initialValues: {
      userId: "",
      name: "",
      password: "",
      email: "",
      photo: "",
      status: "",
      branchesId: [],
      permissions: [],
    },
    validationSchema: createUserSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        dispatch(fetchUpdateUser(values.userId, values));
        setSubmitting(false);
      }, 500);
    },
  });

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={true}
        show={props.show && !openUserPhoto}
      >
        <form
          className="form form-label-right"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          variant="outlined"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Editar {isUser() ? 'usuário' : 'professor'}
            </Modal.Title>
            <CloseIcon onClick={close} className="mt-3 mr-3 cursor-pointer" />
          </Modal.Header>
          <Modal.Body>
            <Container maxWidth="lg">
              <Grid
                container
                className={classes.content}
                alignItems="flex-start"
                justify="space-evenly"
              >
                <Grid container md={6} spacing={3} className="px-5">
                  <TextField
                    type="hidden"
                    disabled={true}
                    name="userId"
                    {...formik.getFieldProps("userId")}
                  />
                  <Grid item className={classes.label}>
                    <Grid item xs={12}>
                      <div class="image-input image-input-outline image-input-circle cursor-pointer" id="kt_user_avatar">
                        <div className="image-input-wrapper" style={{backgroundPosition: 'center', backgroundImage: `url(${formik.values.photo ?? toAbsoluteUrl('/media/users/blank.png')})`}}></div>
                        <label className="btn btn-sm btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" onClick={() => setOpenUserPhoto(true)}>
                          <i className="flaticon-photo-camera icon-md text-dark-75"></i>
                        </label>
                        {formik.values.photo && <span className="btn btn-sm btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove">
                          <i className="fas fa-expand-arrows-alt icon-sm text-dark-75"></i>
                        </span>}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label="Nome *"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}

                    {users.error && <RenderError error={users.error.name} />}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="E-mail *"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}

                    {users.error && <RenderError error={users.error.email} />}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Status *"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        },
                      }}
                      name="status"
                      fullWidth
                      className={classes.textField}
                      {...formik.getFieldProps("status")}
                    >
                      {USER_STATUS.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.display_name}
                        </MenuItem>
                      ))}
                    </TextField>

                    {formik.touched.status && formik.errors.status ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.status}
                        </div>
                      </div>
                    ) : null}

                    {users.error && <RenderError error={users.error.status_id} />}
                  </Grid>
                </Grid>

                <Grid container md={6} spacing={3} className="px-5 pt-5">
                  <Grid item xs={12}>
                    <div className="d-flex">
                      <InputLabel id="permissions-label">Permissões</InputLabel>
                      <span className="font-weight-bolder text-primary text-right" style={{flex: 1}}>
                        <span className="cursor-pointer" onClick={() => selectAllPermissions()}>Selecionar todos</span>
                      </span>
                    </div>
                    <Select
                      multiple
                      fullWidth
                      displayEmpty
                      labelId="permissions-label"
                      name="permissions"
                      input={<OutlinedInput />}
                      renderValue={(selected) =>
                        userPermissions.entities.length > 0 ? (
                          selected.length > 0 ? (
                            userPermissions.entities
                              .filter((entity) => selected.includes(entity.id))
                              .map((el) => el.attributes.description)
                              .join(", ")
                          ) : (
                            "⚠️ Nenhuma permissão selecionada!"
                          )
                        ) : (
                          <div className="d-flex align-items-center">
                            <div className="mr-2 text-muted">Carregando...</div>
                            <div className="spinner spinner-primary mr-10"></div>
                          </div>
                        )
                      }
                      {...formik.getFieldProps("permissions")}
                    >
                      {userPermissions.entities.length > 0 ? (
                        userPermissions.entities.map((perm) => (
                          <MenuItem key={perm.id} value={perm.id}>
                            <Checkbox
                              checked={formik?.values?.permissions.includes(
                                perm.id
                              )}
                            />
                            {perm.attributes.description}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>
                          <div className="d-flex align-items-center">
                            <div className="mr-2 text-muted">Carregando...</div>
                            <div className="spinner spinner-primary mr-10"></div>
                          </div>
                        </MenuItem>
                      )}
                    </Select>

                    {formik.touched.permissions && formik.errors.permissions ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.permissions}
                        </div>
                      </div>
                    ) : null}

                    {users.error && <RenderError error={users.error.permissions} />}
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel id="branchesId-label">Unidades</InputLabel>
                    <Select
                      multiple
                      fullWidth
                      displayEmpty
                      labelId="branchesId-label"
                      name="branchesId"
                      input={<OutlinedInput />}
                      renderValue={(selected) =>
                        branches.branchesCompany.length > 0 ? (
                          selected.length > 0 ? (
                            branches.branchesCompany
                              .filter((branch) => selected.includes(branch.id))
                              .map((el) => el.attributes.name)
                              .join(", ")
                          ) : (
                            "⚠️ Nenhuma unidade selecionada!"
                          )
                        ) : (
                          <div className="d-flex align-items-center">
                            <div className="mr-2 text-muted">Carregando...</div>
                            <div className="spinner spinner-primary mr-10"></div>
                          </div>
                        )
                      }
                      {...formik.getFieldProps("branchesId")}
                    >
                      {branches.branchesCompany.length > 0 ? (
                        branches.branchesCompany.map((branch) => (
                          <MenuItem key={branch.id} value={branch.id}>
                            <Checkbox
                              checked={formik?.values?.branchesId.includes(
                                branch.id
                              )}
                            />
                            {branch.attributes.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>Carregando...</MenuItem>
                      )}
                    </Select>

                    {formik.touched.branchesId && formik.errors.branchesId ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.branchesId}
                        </div>
                      </div>
                    ) : null}

                    {users.error && <RenderError error={users.error.branch_id} />}
                  </Grid>
                  <Grid item xs={12}>
                    <a class="btn btn-light-primary font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => setShowChangePassword(true)}>
                      Alterar senha
                    </a>
                  </Grid>

                  {showChangePassword && <>
                    <Grid container className={classes.label} spacing={2}>
                      <div class="col-lg-8 py-1">
                        <TextField
                          name="password"
                          label={'Nova senha'}
                          fullWidth
                          className={classes.textField}
                          placeholder="Digite a senha"
                          variant="outlined"
                          type="password"
                          {...formik.getFieldProps("password")}
                        />
                      </div>

                      <div class="col-lg-4 py-1 flex-column align-items-center">
                        <span class="label label-xl font-weight-boldest label-success mt-5 cursor-pointer" onClick={() => dispatchUpdateUserPassword()}>
                          <i class="ki ki-check text-white"></i>
                        </span>
                        <span class="label label-xl font-weight-boldest label-danger mt-5 ml-2 cursor-pointer" onClick={() => setShowChangePassword(false)}>
                          <i class="ki ki-close text-white"></i>
                        </span>
                      </div>
                    </Grid>
                  </>}
                </Grid>
              </Grid>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Grid container spacing={3}>
              <Grid item xs style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled={users.actionsLoading}
                >
                  Confirmar
                  {users.actionsLoading && (
                    <div className="spinner spinner-primary mr-10 ml-5"></div>
                  )}
                </Button>
              </Grid>
              <Grid item xs style={{ textAlign: "left" }}>
                <Button size="large" onClick={close}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Modal.Footer>
        </form>
      </Modal>

      <ClientPhoto
        show={openUserPhoto}
        takePhoto={updateUserPhoto}
        onHide={() => setOpenUserPhoto(false)}
        style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
      />
    </>
  );
};

export default EditUser;
