import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup'
import Alert from 'react-bootstrap/Alert';
import CloseIcon from '@material-ui/icons/Close';

// import { fetchCreateClient } from "../_redux/clientActions";

// import { createClientSchema } from "../_schemas/createClientSchema";
import { fetchClientName } from "../../Client/_redux/clientActions";
import { fetchListRecurrenceDays } from "../_redux/scheduleActions";

import { notifySuccess, notifyError } from "../../../utils/Notify";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";

import { makeStyles } from '@material-ui/core/styles';

import {
  fetchCancelSchedule,
} from "../_redux/scheduleActions";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const CancelSchedule = (props) => {
  const dispatch = useDispatch();
  const { schedules } = useSelector((state) => ({
    schedules: state.schedules,
  }), shallowEqual);

  const classes = useStyles();

  const [checked, setChecked] = useState(false);
  const [type, setType] = useState('single');
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (schedules.canceled == true) {
      close();
      notifyError('Cancelado com sucesso', 'schedules-canceled');
    }
  }, [schedules]);

  useEffect(() => {
    if (props.show) {
      //
    } else {
      close();
    }
  }, [props.show]);

  const close = () => {
    props.onHide();

    formik.setFieldValue('name', '', false);
    setType('single');
  }

  useEffect(() => {
    console.log('props.show!' + props.show);
    if (props.show) {
      if (
        props.schedule &&
        props.schedule.attributes &&
        props.schedule.attributes.recurrence
      ) {
        setType('recurrence')
        listRecurrenceDays();
      }

      if (
        props.schedule &&
        props.schedule.attributes &&
        props.schedule.attributes.start_at
      ) {
        formik.setFieldValue('date', moment(props.schedule.attributes.start_at).format('YYYY-MM-DD'), false);
      }
    }
  }, [props.show]);

  const changedCheck = (value) => {
    setChecked(!checked);
  }

  const listRecurrenceDays = () => {
    let startAt = moment(props.schedule.attributes.recurrence_start_at).format('YYYY-MM-DD');
    let finishAt = '';

    if (props.schedule.attributes.recurrence_finish_at) {
      finishAt = moment(props.schedule.attributes.recurrence_finish_at).format('YYYY-MM-DD');
    }

    dispatch(fetchListRecurrenceDays(props.schedule.attributes.recurrence_days, startAt, finishAt, 60)).then((data) => {
      setDates(data);
    });
  }

  const dispatchCancelSchedule = (force = false, refundType = '') => {
    let params = {
      type: type == 'single' ? 'event' : 'gt',
    };

    if (formik.values.date) {
      params.date = formik.values.date
    }

    if (force) {
      params.type = 'event';
    }

    if (params.type == 'event') {
      params.date = '';
    }

    if (refundType) {
      params.refund_type = refundType;
    }

    dispatch(fetchCancelSchedule(props.schedule.id, params));
  }

  const formik = useFormik({
    initialValues: {
      date: '',
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    },
  });

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cancelar agendamento
          </Modal.Title>
          <CloseIcon onClick={close} className="mt-3 mr-3 cursor-pointer" />
        </Modal.Header>
        <Modal.Body>
          {props.schedule && props.schedule.id && <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              {type == 'single' && <>
                {props.schedule.attributes.modality == 'unavailable' && <>
                  <Grid item xs={12}  className="text-center">
                    <label className="col-12 col-form-label font-weight-bolder text-center">
                      O bloqueio será cancelado e o horário liberado
                    </label>

                    <label className="col-12 col-form-label font-weight-normel text-center">
                      O processo é irreversível, você pode bloquear novamente.
                    </label>

                    <a class="btn btn-light-danger font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelSchedule(true)} disabled={formik.isSubmitting}>
                      Cancelar horário {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                    </a>
                  </Grid>
                </>}

                {props.schedule.attributes.modality == 'class' && <>
                  <Grid item xs={12}  className="text-center">
                    <label className="col-12 col-form-label font-weight-bolder text-center">
                      A aula será cancelada e o horário liberado
                    </label>

                    <label className="col-12 col-form-label font-weight-normel text-center">
                      O processo é irreversível, todas as faturas serão cancelados.
                    </label>

                    <a class="btn btn-light-danger font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelSchedule(true)} disabled={formik.isSubmitting}>
                      Cancelar horário {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                    </a>
                  </Grid>
                </>}

                {props.schedule.attributes.modality == 'reservation' && <>
                  {props.schedule.attributes.can_cancel_with_payment  &&<Grid item xs={12}  className="text-center">
                    <label className="col-12 col-form-label font-weight-bolder text-center">
                      Escolha a forma de estorno para efetuar o cancelamento do agendamento
                    </label>

                    <label className="col-12 col-form-label font-weight-normel text-center">
                      Receber 01 crédito para reservar com válidade de 30 dias.
                    </label>

                    <a class="btn btn-light-primary font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelSchedule(true, 'credit')} disabled={formik.isSubmitting}>
                      Receber 1 crédtio {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                    </a>
                  </Grid>}

                  {props.schedule.attributes.can_cancel_with_credit && <Grid item xs={12}  className="text-center">
                    <label className="col-12 col-form-label font-weight-normel text-center">
                      Solicitar o estorno do valor pago via operadora
                    </label>

                    <a class="btn btn-light-danger font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelSchedule(true, 'chargeback')} disabled={formik.isSubmitting}>
                      Estornar pagamento {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                    </a>
                  </Grid>}


                  {!props.schedule.attributes.can_cancel_with_credit && !props.schedule.attributes.can_cancel_with_credit && <Grid item xs={12}  className="text-center">
                    <label className="col-12 col-form-label font-weight-bolder text-center">
                      O agendamento será cancelado e o horário liberado
                    </label>

                    <label className="col-12 col-form-label font-weight-normel text-center">
                      O processo é irreversível, todas as faturas também irá ser cancelada.
                    </label>

                    <a class="btn btn-light-danger font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelSchedule(true)} disabled={formik.isSubmitting}>
                      Cancelar horário {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                    </a>
                  </Grid>}
                </>}
              </>}

              {type == 'recurrence' && <>
                {props.schedule.attributes.modality == 'class' && <Grid item xs={12}>
                  <Alert key="alert-accept-schedule" variant="secondary">
                    Os contratos atrelados a essa recorrência não serão finalizados, somente a inscrição com esse agendamento.
                  </Alert>
                </Grid>}

                <Grid item xs={12} className="text-center">
                  <label className="col-12 col-form-label font-weight-normel text-center">
                    Para finalizar a recorrência selecione a data da última partida que vai acontecer
                  </label>
                </Grid>

                <Grid item xs={3} />
                <Grid item xs={6}>
                  <TextField
                    select
                    label={'Data cancelamento'}
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        }
                      }
                    }}
                    name="date"
                    fullWidth
                    className={classes.textField}
                    {...formik.getFieldProps("date")}
                  >
                    {dates.length > 0 && dates.map((day, index) => <MenuItem key={`day-${index}`} value={day}>
                      {moment(day).format('DD/MM/YYYY')}
                    </MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item xs={3} />

                <Grid container className={classes.label}>
                  <Grid item xs={12} className="text-center">
                    <a class="btn btn-light-warning font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelSchedule()} disabled={formik.isSubmitting}>
                      Finalizar recorrência {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                    </a>
                  </Grid>


                  <Grid item xs={12} className="text-center">
                    <div className="separator separator-dashed mt-6 mb-4 mx-5"></div>

                    <label className="col-12 col-form-label font-weight-normel text-center">
                      Ou se preferir você também pode excluir a recorrência do sistema, removendo todos os eventos do calendário
                    </label>

                    <a class="btn btn-light-danger font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelSchedule(true)} disabled={formik.isSubmitting}>
                      Apagar recorrência {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                    </a>
                  </Grid>
                </Grid>
              </>}
            </Grid>

            {(typeof schedules.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {schedules.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>}
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>
  </>);
};

export default CancelSchedule;