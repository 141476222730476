import React, { useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import CurrencyInput from "react-currency-input-field";

import { Modal, Dropdown } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import ControlPoint from '@material-ui/icons/ControlPoint';
import CloseIcon from '@material-ui/icons/Close';

import { fetchCreateWithdrawal } from "../_redux/withdrawalActions";

import { RenderError } from "../../../components/RenderError";
import { formatMoney } from "../../../utils/IntegerParse";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const CurrencyInputCustom = ({ inputRef, onChange, ...props }) => {
  return (
    <CurrencyInput
      {...props}
      intlConfig={{
        locale: "pt-BR",
        currency: "BRL"
      }}
      decimalSeparator=","
      allowNegativeValue={false}
      disableGroupSeparators={true}
      decimalScale={2}
      decimalsLimit={2}
      // fixedDecimalLength={2}
      prefix=" "
      // onValueChange={(value, name) =>
      //   onChange({
      //     target: { value, name }
      //   })
      // }
      onValueChange={(value, name) =>
        onChange({
          target: { value, name }
        })
      }
    />
  );
};

const CreateWithdrawal = (props) => {
  const dispatch = useDispatch();

  const { withdrawals, branchSelected } = useSelector((state) => ({
    withdrawals: state.withdrawals,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();
  const amountRef = useRef(null);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (props.show) {
      //
    }
  }, [props.show]);

  useEffect(() => {
    if (withdrawals.created == true) {
      close();
    }
  }, [withdrawals]);

  const close = () => {
    props.onHide();
    setAmount(0);
  }

  const requestCreateWithdral = () => {
    dispatch(fetchCreateWithdrawal(branchSelected.id, {amount: amount}));

  }

  const calculateTransferAmount = () => {
    let value = amount;

    value = formatMoney(amount - 3.67);

    return value < 0 ? `-${value}` : value;
  }

  const formik = useFormik({
    initialValues: {
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        requestCreateWithdral()
        setSubmitting(false);
      }, 100);
    },
  });

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Solicitar transferência
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="sm">
            <Grid container className={classes.label}>
              <Grid item xs={12}>
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-bold text-dark-50 font-size-md mb-1">Saldo disponível para transferência</span>
                  <span className="font-weight-bold font-size-md mb-1 text-success">
                    {(
                      withdrawals.balance &&
                      withdrawals.balance.attributes &&
                      withdrawals.balance.attributes.available_amount
                    ) ? formatMoney(withdrawals.balance.attributes.available_amount, true) : `R$ 0,00`}
                  </span>
                </div>
              </Grid>
            </Grid>

            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="amount"
                  label="Valor da transferência"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  onChange={({ target: { value } }) => {
                    setAmount(value ? value.toString().replace(",", ".") : 0);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    inputComponent: CurrencyInputCustom
                  }}
                />

                {withdrawals.error && <RenderError error={withdrawals.error.amount} />}
              </Grid>
            </Grid>

            <div className="separator separator-dashed my-5"></div>

            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <div className="d-flex flex-wrap mb-3">
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <span className="font-weight-bold text-dark-75 font-size-md mb-1">Valor solicitado</span>
                  </div>
                  <span className="my-lg-0 my-2 text-dark-50 font-weight-bolder">
                    {formatMoney(amount)}
                  </span>
                </div>
                <div className="d-flex flex-wrap mb-3">
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <span className="font-weight-bold text-dark-75 font-size-md mb-1">Taxa de transferência</span>
                  </div>
                  <span className="my-lg-0 my-2 font-weight-bolder text-danger">
                    -R$ 3,67
                  </span>
                </div>
                <div className="separator separator-dashed my-5"></div>
                <div className="d-flex flex-wrap mb-3">
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <span className="font-weight-bold text-dark-75 font-size-md mb-1">Valor a ser transferido</span>
                  </div>
                  <span className="my-lg-0 my-2 font-weight-bolder text-primary">
                    {calculateTransferAmount()}
                  </span>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={formik.isSubmitting || withdrawals.actionsLoading}>
                Transferir {(formik.isSubmitting || withdrawals.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>
  </>);
};

export default CreateWithdrawal;