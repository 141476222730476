/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

import { notifySuccess } from "../../../utils/Notify";
import { fetchUpdatePassword, notifiedPasswordUpdated } from "../_redux/accountActions";

import { RenderError } from "../../../components/RenderError";

export const UserProfilePage = (props) => {
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);

  const { user, account } = useSelector((state) => ({
    user: state.auth.user,
    account: state.account,
  }), shallowEqual);

  useEffect(() => {
    if (account.passwordUpdated == true) {
      notifySuccess("Senha alterada com sucesso", 'account-passwordUpdated');

      setTimeout(() => {
        dispatch(notifiedPasswordUpdated());
      }, 10);

      formik.setFieldValue('password', '', false);
      formik.setFieldValue('confirm_password', '', false);
    }
  }, [account]);

  const initialValues = {
    name: user.attributes.name,
    email: user.attributes.email,
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(() => {
        dispatch(fetchUpdatePassword(values.password, values.confirm_password));

        setSubmitting(false);
      }, 10);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  return (<>
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Minha conta
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Altere as configurações da conta
          </span>
        </div>
      </div>
      <div className="form">
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Informações:</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Nome</label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "username"
                  )}`}
                  name="username"
                  {...formik.getFieldProps("name")}
                  readOnly="readonly"
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="invalid-feedback">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              E-mail
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="E-mail"
                  className={`form-control form-control-lg form-control-solid`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>
              <span className="form-text text-muted">
                Respeitamos todas as normas da LGPD.{` `}
                <a href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm" className="font-weight-bold">
                  Saiba mais
                </a>
                .
              </span>
            </div>
          </div>

          <div className="separator separator-dashed my-5"></div>

          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Segurança:</h5>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Senha</label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="password"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "password"
                  )}`}
                  placeholder="Digite seu nova senha"
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="invalid-feedback">
                    {formik.errors.username}
                  </div>
                ) : null}
              </div>

              {account.error && <RenderError error={account.error.password} />}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Confirme a senha</label>
            <div className="col-lg-9 col-xl-6">
              <div>
                <input
                  type="password"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "confirm_password"
                  )}`}
                  placeholder="Confirme a senha"
                  name="confirm_password"
                  {...formik.getFieldProps("confirm_password")}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="invalid-feedback">
                    {formik.errors.username}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label"></label>
            <div className="col-lg-9 col-xl-6">
              <button
                type="submit"
                className="btn btn-success mr-2"
                disabled={
                  formik.isSubmitting || (formik.touched && !formik.isValid)
                }
              >
                ALTERAR SENHA {(formik.isSubmitting || account.actionsLoading) && <span className="ml-2 spinner spinner-default mr-3"></span>}
              </button>
            </div>
          </div>

          <div className="separator separator-dashed my-5"></div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Atualizar aplicativo</label>
            <div className="col-lg-9 col-xl-6">
              <button type="button" className="btn btn-primary mr-2" onClick={() => window.location.reload(true)}>RECARREGAR APP</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </>);
}
