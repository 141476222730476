import * as requestFromServer from "./fieldCrud";
import { fieldSlice, callTypes } from "./fieldSlice";

const { actions } = fieldSlice;

export const clearFields = () => dispatch => {
   dispatch(actions.fieldsFetched({ entities: [] }));
}

export const fetchFields = (branchId = '') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  let params = {};

  if (branchId) {
    params.branch_id = branchId;
  }

  return requestFromServer
    .getFields(params)
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.fieldsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.fieldsFetched({ totalCount: 0, entities: null }));
    });
};


export const fetchCreateField = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .createField(
      params.branchId,
      params.name,
      params.groundType,
      params.maxPlayers,
      params.serviceId
    ).then(response => {
      dispatch(actions.fieldCreated());
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateField = (fieldId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateField(fieldId,
      params.branchId,
      params.name,
      params.groundType,
      params.maxPlayers,
      params.serviceId
    ).then(response => {
      dispatch(actions.fieldUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};