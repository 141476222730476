import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetBranchSelected: "[Set branch selected] Action",
  SetFieldCalendarView: "[Set field calendar View] Action",
  SetDefaultCalendarView: "[Set default calendar View] Action",
  SetStartCalendarView: "[Set start calendar View] Action",
  SetEndCalendarView: "[Set end calendar View] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  branchSelected: {},
  fieldCalendarView: [],
  defaultCalendarView: 'day',
  startCalendarView: 6,
  endCalendarView: 23,
};

export const reducer = persistReducer(
  { storage, key: "atacante-auth", whitelist: ["authToken", "branchSelected", "fieldCalendarView", "defaultCalendarView", "startCalendarView", "endCalendarView"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { ...state, authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { ...state, authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetBranchSelected: {
        const { branchSelected } = action.payload;
        return { ...state, branchSelected };
      }

      case actionTypes.SetFieldCalendarView: {
        const { fieldCalendarView } = action.payload;
        return { ...state, fieldCalendarView };
      }

      case actionTypes.SetDefaultCalendarView: {
        const { defaultCalendarView } = action.payload;
        return { ...state, defaultCalendarView };
      }

      case actionTypes.SetStartCalendarView: {
        const { startCalendarView } = action.payload;
        return { ...state, startCalendarView };
      }

      case actionTypes.SetEndCalendarView: {
        const { endCalendarView } = action.payload;
        return { ...state, endCalendarView };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setBranchSelected: (branchSelected) => ({ type: actionTypes.SetBranchSelected, payload: { branchSelected } }),
  setFieldCalendarView: (fieldCalendarView) => ({ type: actionTypes.SetFieldCalendarView, payload: { fieldCalendarView } }),
  setDefaultCalendarView: (defaultCalendarView) => ({ type: actionTypes.SetDefaultCalendarView, payload: { defaultCalendarView } }),
  setStartCalendarView: (startCalendarView) => ({ type: actionTypes.SetStartCalendarView, payload: { startCalendarView } }),
  setEndCalendarView: (endCalendarView) => ({ type: actionTypes.SetEndCalendarView, payload: { endCalendarView } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });
}
