import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import SVG from "react-inlinesvg";

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Icon from '@material-ui/core/Icon';

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import CreateField from '../components/CreateField';
import EditField from '../components/EditField';

import { fetchFields } from "../_redux/fieldActions";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Nome' },
  { id: 'attributes.groundType', numeric: false, disablePadding: true, label: 'Tipo' },
  { id: 'attributes.branch', numeric: false, disablePadding: false, label: 'Unidade' },
  { id: 'attributes.services', numeric: false, disablePadding: false, label: 'Serviços' },
  { id: 'actions', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align="left"
            // align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <span style={{
                color: "#B5B5C3",
                textTransform: "uppercase"
              }}>
              {row.label}
              </span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (<>
          {/*<Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>*/}
        </>)}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 14
  },
}));

export const FieldPage = () => {
  const { fields, branchSelected } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
    fields: state.fields,
  }), shallowEqual);

  const classes = useStyles();
  const [pagination, setPagination] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('attributes.name');
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    requestEntities();
  }, []);

  useEffect(() => {
    if (pagination) {
      requestEntities();
    }
  }, [rowsPerPage, page]);

  useEffect(() => {
    requestEntities();
  }, [branchSelected]);

  useEffect(() => {
    if (fields.entities) {
      setRows(fields.entities);
    }
  }, [fields]);

  const requestEntities = async () => {
    try {
      dispatch(fetchFields(branchSelected.id));
    } catch (error) {
      console.error(error);
    }
  };

  const closeCreateEntity = () => {
    {createEntityShow &&
      requestEntities();
    };

    setCreateEntityShow(false);
  };

  const openEditEntity = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }

  const closeEditEntity = () => {
    {editEntityShow &&
      requestEntities();
    };

    setEditEntityShow(false);
  }

  const getClassStatus = (status) => {
    if (status == 'pending') {
      return 'warning';
    }

    if (status == 'blocked') {
      return 'danger';
    }

    return 'success';
  }

  const getType = (type) => {
    return type == 'single' ? 'Avulso' : 'Mensalista';
  }

  const getRecurrence = (period, times) => {
    let string = period == 'weekly' ? ' na semana' : ' no mês';

    return times + (times > 1 ? ' vezes' : ' vez') + string;
  }

  const getServices = (services) => {
    var names = services.map(service => {
      return service.attributes.name;
    });

    return services.length + (services.length > 1 ? ' serviços ' : ' serviço ') + ': ' + names.join(', ');
  }

  const getMinMax = (arr) => {
    return arr.reduce(({min, max}, v) => ({
      min: min < v ? min : v,
      max: max > v ? max : v,
    }), { min: arr[0], max: arr[0] });
  }

  const getDuration = (start, end) => {
    if (start == end) {
      return `${start} min`;
    }

    return `${start} ~ ${end} min`;
  }

  const getPrices = (entitiesPrice) => {
    let prices = [];

    entitiesPrice.map(price => {
      prices.push(price.attributes.price);
    });

    let minMax = getMinMax(prices);

    if (minMax.min == minMax.max) {
      return `R$ ${minMax.min} - ${entitiesPrice[0].attributes.time} min`;
    }

    return `R$ ${minMax.min} ~ R$ ${minMax.max} - ${entitiesPrice[0].attributes.time} min`;//prices.length + (prices.length > 1 ? ' preços relacinados' : ' preço relacionado') + ' com esse serviço';
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (<>
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <span>Campos</span>
        </div>
        <div className="card-toolbar">
          <a href="#" className="btn btn-light-primary font-weight-bold" onClick={() => setCreateEntityShow(true)}>
            <i className="ki ki-plus "></i> Cadastrar
          </a>
        </div>
      </div>
      <div className={fields.listLoading ? 'card-body filtering' : 'card-body'}>
        {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
        <div className={classes.tableWrapper}>
          <Table
            key="tableWrapper"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={fields.totalCount}
            />
            <TableBody>
              {/*{stableSort(rows, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {*/}
              {stableSort(rows, getSorting(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.attributes.name}
                      </TableCell>
                      <TableCell align="left">
                        {row.attributes.groundTypeLabel} - {row.attributes.maxPlayers} jogadores
                      </TableCell>
                      <TableCell align="left">
                        {row.relationships.branch.attributes.name}
                      </TableCell>

                      {(row.relationships.services.length > 0) && <TableCell align="left">
                        {row.relationships.services.length} assoc.
                        <Tooltip title={<h5 className="font-weight-lighter text-white">{getServices(row.relationships.services)}</h5>}>
                          <Icon className={classes.icon}>info_outlined</Icon>
                        </Tooltip>
                      </TableCell>}

                      {(row.relationships.services.length <= 0) && <TableCell align="left">
                        Nenhum serviço
                        <Tooltip title={<h5 className="font-weight-lighter text-white">Nenhum serviço está associado a esse campo, associe um serviço parar utilizar esse campo</h5>}>
                          <Icon className={classes.icon} style={{ color: 'red' }}>info_outlined</Icon>
                        </Tooltip>
                      </TableCell>}

                      <TableCell align="right">
                        <a
                          title="Edit customer"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          onClick={() => openEditEntity(row)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                            />
                          </span>
                        </a>
                        <> </>

                        {/*<a
                          title="Delete customer"
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          // onClick={() => openDeleteCustomerDialog(row.id)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                          </span>
                        </a>*/}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/*{emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}*/}
            </TableBody>
          </Table>
        </div>

        <div className="d-flex align-items-center py-3">
          {fields.listLoading && <div className="d-flex align-items-center">
            <div className="mr-2 text-muted">Carregando...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>}

          {pagination && <TablePagination
            labelRowsPerPage="Registros por página:"
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={fields.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima Página',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />}

          {!pagination && <span style={{color: "#B5B5C3", textTransform: "uppercase"}}>
            {fields.totalCount} registro(s)
          </span>}
        </div>
      </div>

      <CreateField show={createEntityShow} onHide={closeCreateEntity} />

      <EditField show={editEntityShow} onHide={closeEditEntity} event={editEntity} />
    </div>
  </>);
}
