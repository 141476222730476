import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export const Menu = (props) => {
  return (<>
    <div className="card card-custom">
      <div className="card-body">
        <div className="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon navi-accent" role="tablist">
          {/*<div className="navi-section mb-2 font-size-h5 font-weight-bold pb-0">{props.title ?? 'Modalidade'}</div>*/}

          {props.menu?.map(menu => <>
            {/*{menu.category && <div className="navi-section mt-7 mb-2 font-size-h6 font-weight-bold pb-0">{menu.category}</div>}*/}
            {menu.category && <div class="text-primary text-uppercase navi-section font-size-h6 font-weight-light pb-0">{menu.category}</div>}

            <div className="navi-item my-2">
              <NavLink className={`navi-link ${menu[props.activeParam] == props.active && `active`}`} to={menu.path}>
                {<span className="navi-icon mr-2">
                  {/*<i className="fa fa-circle" style={{color: '#ccc'}}></i>*/}
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-right.svg")}/>
                </span>}
                <span className="navi-text font-weight-bolder font-size-lg">{menu.label}</span>
              </NavLink>
            </div>
          </>)}

        </div>
      </div>
    </div>
  </>);
}