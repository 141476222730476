import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { useFormik } from "formik";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

import { checkIsActive, getCurrentUrl } from "../../../../_metronic/_helpers";
import { hasPermission } from "../../../utils/Permission";
import { Menu } from "../../../components/Menu";

import { DayUseCardList } from '../../DayUse/components/DayUseCardList';
import { SignatureCardList } from '../../Signature/components/SignatureCardList';
import { CreditPage } from "../../Credit/pages/CreditPage";
import { CouponPage } from "../../Coupon/pages/CouponPage";
import { CreditPurchasePage } from "../../CreditPurchase/pages/CreditPurchasePage";
import { ContractPage } from "../../Contract/pages/ContractPage";
import { PaymentChargebackPage } from "../../PaymentChargeback/pages/PaymentChargebackPage";
import { PaymentPage } from "../../Payment/pages/PaymentPage";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}));

export const ProductDashboardPage = () => {
  const { branchSelected, user } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
    user: state.auth.user,
  }), shallowEqual);

  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url);
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const initialValues = {
    name: '',
    email: '',
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(() => {
        // dispatch(fetchUpdatePassword(values.password, values.confirm_password));

        setSubmitting(false);
      }, 10);
    },
    onReset: (values, { resetForm }) => {
      // resetForm();
    },
  });

  const menu = [
    {
      category: 'Planos',
      label: 'Clubinho',
      path: '/clubinho',
      permission: 'signature-list'
    },
    {
      label: 'Day use',
      path: '/day-use',
      permission: 'day_use-list'
    },
    {
      category: 'Locações e aulas',
      label: 'Locações',
      path: '/contratos/locacoes',
      permission: 'schedule-list'
    },
    {
      label: 'Locações mensal',
      path: '/contratos/mensalistas',
      permission: 'schedule-list'
    },
    {
      label: 'Aulas avulso',
      path: '/contratos/aulas',
      permission: 'schedule-list'
    },
    {
      label: 'Alunos mensalistas',
      path: '/contratos/alunos',
      permission: 'schedule-list'
    },
    {
      category: 'Pacotes e créditos',
      label: 'Pacotes de créditos',
      path: '/pacotes-de-creditos',
      permission: 'credit-purchase-list'
    },
    {
      label: 'Créditos',
      path: '/creditos',
      permission: 'credit-list'
    },
    {
      category: 'Pagamento online',
      label: 'Cupom de desconto',
      path: '/cupons-de-desconto',
      permission: 'coupon-list'
    },
    {
      label: 'Pagamentos',
      path: '/pagamentos',
      permission: 'payment-list'
    },
    {
      label: 'Estornos',
      path: '/estornos',
      permission: 'payment-chargeback-list'
    },
  ];

  return (<>
    <div className="d-flex row">
      <div className="col-md-3 pb-3" id="kt_todo_aside">
        <Menu
          menu={menu}
          active={getCurrentUrl(location)}
          activeParam='path'
        />
      </div>

      <div className="col-md-9">

        <div className="card card-custom">
          <div className='card-body'>
            <div className="row">

              {/*<div className="col-lg-3">
                <ul className="navi navi-link-rounded navi-accent navi-hover navi-active nav flex-column mb-3 mt-3" role="tablist">
                  <Menu
                    menu={menu}
                    active={getCurrentUrl(location)}
                    activeParam='path'
                  />

                  {/*{menu.map(data => {
                    return <>
                      {data.category && <div class="text-primary text-uppercase navi-section mt-4 mb-5 font-size-h5 font-weight-light pb-0">{data.category}</div>}
                      {hasPermission(user, data.permission) && <li className="navi-item mb-2">
                        <NavLink className={`navi-link ${getMenuItemActive(data.path) && `active`}`} to={data.path}>
                          <span className={`navi-text ${getMenuItemActive(data.path) ? 'text-dark' : 'text-dark-50'} font-size-h5 font-weight-bold`}>{data.label}</span>
                        </NavLink>
                      </li>}
                    </>
                  })}
                </ul>
              </div>*/}

              <div className="col-lg-12">
                {getMenuItemActive('/clubinho') && <SignatureCardList />}
                {getMenuItemActive('/day-use') && <DayUseCardList />}
                {getMenuItemActive('/pacotes-de-creditos') && <CreditPurchasePage />}
                {getMenuItemActive('/creditos') && <CreditPage />}
                {getMenuItemActive('/contratos/locacoes') && <ContractPage />}
                {getMenuItemActive('/contratos/mensalistas') && <ContractPage />}
                {getMenuItemActive('/contratos/aulas') && <ContractPage />}
                {getMenuItemActive('/contratos/alunos') && <ContractPage />}
                {getMenuItemActive('/cupons-de-desconto') && <CouponPage />}
                {getMenuItemActive('/pagamentos') && <PaymentPage />}
                {getMenuItemActive('/estornos') && <PaymentChargebackPage />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}
