import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup'
import { RenderError } from "../../../components/RenderError";

import CloseIcon from '@material-ui/icons/Close';

import { fetchClientName } from "../../Client/_redux/clientActions";
import {
  fetchAvailableCredits
} from "../../Credit/_redux/creditActions";
import { fetchGetAvailableContracts } from "../../Contract/_redux/contractActions";

import { notifySuccess } from "../../../utils/Notify";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import { fetchListRecurrenceDays } from "../_redux/scheduleActions";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
  lineThrough: {
    textDecoration: 'line-through',
    fontSize: '10px'
  },
}));

const CreateClientSchedule = (props) => {
  const dispatch = useDispatch();
  const { schedules, credits, clients, prices, branchSelected } = useSelector((state) => ({
    schedules: state.schedules,
    credits: state.credits,
    clients: state.clients,
    prices: state.prices,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();
  const nameRef = useRef(null);

  const [selectedClient, setSelectedClient] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const [selectedCredit, setSelectedCredit] = useState({});
  const [selectedContract, setSelectedContract] = useState({});
  const [selectedPrice, setSelectedPrice] = useState({});
  const [showClient, setShowClient] = useState(false);
  const [checked, setChecked] = useState(false);
  const [useCredit, setUseCredit] = useState(false);
  const [useExistentContract, setUseExistentContract] = useState(false);
  const [availableCredits, setAvailableCredits] = useState([]);
  const [type, setType] = useState('single');
  const [contractType, setContractType] = useState('');
  const [startDateAt, setStartDateAt] = useState('');
  const [finishDateAt, setFinishDateAt] = useState('infinite');
  const [weekdays, setWeekdays] = useState([]);
  const [dates, setDates] = useState([]);
  const [contracts, setContracts] = useState([]);

  const plans = [
    { label: 'Mensal', value: 'monthly' },
    { label: 'Trimestral', value: 'quarterly' },
    { label: 'Semestral', value: 'semester' },
    { label: 'Anual', value: 'yearly' },
  ];

  useEffect(() => {
    if (schedules.addClient == true) {
      close();
      notifySuccess('Jogador adicionado!', 'schedules-addClient', 2000);
    }
  }, [schedules]);

  useEffect(() => {
    if (props.type) {
      setType(props.type);
    }
  }, [props.type]);

  useEffect(() => {
    clearSelectService();

    if (contractType == 'single' && selectedClient && selectedClient.id) {
      dispatch(fetchAvailableCredits({
        branchId: branchSelected.id,
        clientId: selectedClient.id,
        fieldId: props.fieldId,
        duration: props.duration,
        originType: props.modality,
      })).then((data) => {
        if (data.length > 0) {
          changeUseCredit(true);
        }

        setAvailableCredits(data);
      });
    }

    if (contractType == 'recurrence' && selectedClient && selectedClient.id) {
      getExistentContracts();
    }
  }, [
    contractType,
    selectedClient,
  ]);

  const getExistentContracts = () => {
    dispatch(fetchGetAvailableContracts({
      client_id: selectedClient.id,
      modality: props.modality,
      field_id: props.fieldId,
      date: formik.values.startDateAt
    })).then((data) => {
      if (data.length > 0) {
        changeUseExistentContract(true);
      }

      setContracts(data);
    });
  }

  useEffect(() => {
    if (props.show && props.startDateAt) {
      nameRef.current.focus();
      formik.setFieldValue('startDateAt', moment(props.startDateAt).format('YYYY-MM-DD'), false);
      formik.setFieldValue('billing_day', moment(props.startDateAt).format('DD'), false);

      if (props.type == 'single' && props.modality == 'class') {
        setContractType('single');
      }
      if (props.type == 'single' && props.modality == 'reservation') {
        setContractType('single');
      }
      if (props.type == 'recurrence' && props.modality == 'reservation') {
        setContractType('recurrence');
      }

      if ((props.type == 'recurrence' && props.modality == 'reservation') || (props.weekdays && props.weekdays.length == 1)) {
        setWeekdays(props.weekdays)
      }
    }

    if (!props.show) {
      close();
    }
  }, [props.show]);

  const listRecurrenceDays = () => {
    if (contractType) {
      if (type == 'single') {
        setDates([moment(props.startDateAt).format('YYYY-MM-DD')])
      } else {
        let weekdaysArray = contractType == 'single' ? props.weekdays : weekdays;

        if (weekdaysArray.length) {
          let endDateAt = ''

          if (props.endDateAt) {
            endDateAt = moment(props.endDateAt).format('YYYY-MM-DD');
          }

          dispatch(fetchListRecurrenceDays(weekdaysArray.reduce((x, y) => x + y, 0), moment(props.startDateAt).format('YYYY-MM-DD'), endDateAt)).then((data) => {
            setDates(data);

            let day = data.find(value => value == formik.values.startDateAt);

            if (!day) {
              formik.setFieldValue('startDateAt', '', false);
            }
          });
        }
      }
    }
  }

  const close = () => {
    props.onHide();

    setShowClient(false);
    setUseCredit(false);
    setUseExistentContract(false);
    clearSelectClient();
    clearSelectService();
    setContractType('');
    setStartDateAt('');
    setWeekdays([]);
    setAvailableCredits([]);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('startDateAt', '', false);
    formik.setFieldValue('plan', 'monthly', false);
  }

  const selectClient = (client) => {
    setSelectedClient(client);
  }

  const selectRecurrenceClient = () => {
    if (contractType == 'recurrence' && useExistentContract) {
      dispatchContract();
    } else {
      dispatchClient(selectedClient);
    }
  }

  const dispatchClient = (attributes) => {
    let client = JSON.parse(JSON.stringify(attributes));

    client.relationships.service = selectedService;
    client.attributes.start_at = formik.values.startDateAt;

    if (contractType == 'recurrence') {
      client.attributes.recurrence_days = weekdays.reduce((x, y) => x + y, 0);
      client.attributes.billing_day = formik.values.billing_day;
      client.attributes.plan = formik.values.plan;

      if (finishDateAt != 'infinite') {
        client.attributes.finish_at = moment(formik.values.startDateAt).add(finishDateAt, 'months').format('YYYY-MM-DD')
      }
    }

    if (contractType == 'single' && useCredit) {
      client.attributes.credit_id = selectedCredit.id;
    }

    if (props.selectClient) {
      props.selectClient(client);
    }
  }

  const dispatchContract = () => {
    selectedContract.startDateAt = formik.values.startDateAt;

    let value = JSON.parse(JSON.stringify(selectedContract));

    if (props.selectContract) {
      props.selectContract(value);
    }
  }

  const clearSelectClient = () => {
    setSelectedClient({});
  }

  const clearSelectService = () => {
    setSelectedService({});
    setSelectedPrice({});
    setSelectedCredit({});
    setSelectedContract({});
  }

  const setSelectServicePrice = (item) => {
    setSelectedPrice(item);
    setSelectedService(item.service);
  }

  const changedCheck = (value) => {
    setChecked(!checked);
  }

  const selectCredit = (value) => {
    setSelectedCredit(value);
    setSelectedService(value.relationships.origin);
  }

  const selectContract = (value) => {
    setSelectedContract(value);
    setSelectedService(value.relationships.service);
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const searchClientName = () => {
    if (formik.values.name && formik.values.name.length >= 2) {
      const clientNameRequestTimeout = setTimeout(() => {
        dispatch(fetchClientName(formik.values.name, branchSelected.id));
        setShowClient(true);
      }, 400);

      return () => {
        clearTimeout(clientNameRequestTimeout);
      };
    }
  }

  const typeClass = (item) => {
    return contractType == item ? "contained" : "outlined";
  }

  const styleWeekDay = (weekday) => {
    if (!checkWeekDay(weekday)) {
      return {
        border: '1px solid rgb(158 158 158 / 27%)',
        color: 'rgb(0 0 0 / 29%)'
      };
    }

    return {};
  }

  const selectedWeekDayColor = (weekday) => {
    return weekdays.includes(weekday) ? "primary" : "default";
  }

  const checkWeekDay = (weekday) => {
    if (props.weekdays) {
      return props.weekdays.includes(weekday) ? true : false;
    }

    return true;
  }

  const selectWeekDay = (weekday) => {
    if (props.type == 'recurrence' && props.modality == 'reservation') {
      return;
    }

    if (checkWeekDay(weekday)) {
      if(!weekdays.includes(weekday)) {
        if (selectedService.id && weekdays.length >= selectedService.attributes.recurrence_times) {
          clearSelectService();
        }

        setWeekdays([...weekdays, weekday]);
      } else{
          var array = [...weekdays];
          var index = array.indexOf(weekday);

          if (index !== -1) {
            if (weekdays.length == 1) {
              clearSelectService();
            }

            array.splice(index, 1);

            setWeekdays(array);
          }
      }
    }
  }


  const getType = (value) => {
    return value == 'single' ? 'Avulso' : 'recurrence' ? 'Mensalista' : 'Indisponivel';
  }

  const getStatusColor = (value) => {
    if (value != 'recurrence' && props.modality == 'schedule') {
      return '#17c191';
    }
    if (value == 'recurrence' && props.modality == 'schedule') {
      return '#a6bf54';
    }

    if (value != 'recurrence' && props.modality == 'class') {
      return '#1772c1';
    }
    if (value == 'recurrence' && props.modality == 'class') {
      return '#54a6bf';
    }

    return 'gray';
  }

  const convertMinsToHrsMins = (mins) => {
    if (mins < 60) {
      return `${mins} min`;
    }

    let h = Math.floor(mins / 60);
    let m = mins % 60;

    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;

    return `${h}:${m} hrs`;
  }

  const getServicePrice = () => {
    let value = 0;
    let period = '';

    if (formik.values.plan == 'monthly') {
      value = selectedPrice.price;
    }

    if (formik.values.plan == 'quarterly') {
      value = selectedPrice.price_quarterly;
    }

    if (formik.values.plan == 'semester') {
      value = selectedPrice.price_semester;
    }

    if (formik.values.plan == 'yearly') {
      value = selectedPrice.price_yearly;
    }

    if (contractType == 'recurrence') {
      period = '/mês';
    }

    if (selectedClient.attributes.is_member && selectedPrice.price_member != 0.00) {
      value = selectedPrice.price_member;
    }

    return value != 0.00 ?
      `R$ ${parseFloat(value).toLocaleString('pt-br', { minimumFractionDigits: 2 })}${period}` : `Grátis`
  }

  const changeUseCredit = (value) => {
    setUseCredit(value);
    clearSelectService();
  }

  const changeUseExistentContract = (value) => {
    setUseExistentContract(value);
    clearSelectService();
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      startDateAt: '',
      serviceId: '',
      plan: 'monthly',
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    },
  });

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);

  useEffect(() => {
    if (contractType == 'recurrence' && selectedClient && selectedClient.id) {
      getExistentContracts();
    }
  }, [formik.values.startDateAt]);

  useEffect(() => {
    listRecurrenceDays();

    changeUseCredit();
    changeUseExistentContract();
  }, [
    weekdays,
    contractType
  ]);

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Criar novo contrato
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          {!selectedClient.id && <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Pesquisar por nome"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {clients.actionsLoading && <CircularProgress size={30} />}
                      </InputAdornment>
                    ),
                  }}
                  {...formik.getFieldProps("name")}
                  inputRef={nameRef}
                />

                {formik.touched.name && formik.errors.clientId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.clientId}</div>
                  </div>
                ) : null}
              </Grid>

              {showClient && <Grid item xs={12}>
                {clients.clientName.data && clients.clientName.data.map((client) =>
                  <div className={classes.detailedExpansionPanel}>
                    <DetailedExpansionPanel client={client} onSelect={selectClient} />
                  </div>
                )}
                {clients.clientName.data && !clients.clientName.data.length && <span>Nenhum cliente encontrado</span>}
              </Grid>}
            </Grid>

            {(typeof clients.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {clients.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>}

          {selectedClient.id && <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Confirme os dados do jogador
                </span>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">Nome: {selectedClient.attributes.name}</div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">E-mail: {selectedClient.attributes.email}</div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">Telefone: {selectedClient.attributes.phone}</div>
                </div>
              </Grid>
            </Grid>
          </Container>}

          {selectedClient.id && props.type == 'recurrence' && props.modality == 'class' && <Container maxWidth="sm">
            <Grid item xs={12}>
              <span className="text-dark">
                Selecione o tipo de contrato
              </span>
            </Grid>
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs>
                <Button fullWidth variant={typeClass('single')} onClick={() => setContractType('single')} color="primary" size="large">
                  Avulso
                </Button>
              </Grid>
              <Grid item xs>
                <Button fullWidth variant={typeClass('recurrence')} onClick={() => setContractType('recurrence')} color="primary" size="large">
                  Mensalista
                </Button>
              </Grid>
            </Grid>
          </Container>}

          {selectedClient.id && contractType && <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Contrato
                </span>
              </Grid>

              {contractType == 'recurrence' && <>
                {contracts && contracts.length > 0 && <div className="row">
                  <div className="col-lg-6">
                    <label className="option option-plain">
                      <span className="option-control">
                        <span className="radio">
                          <input type="radio" checked={useExistentContract} onClick={() => changeUseExistentContract(true)} />
                          <span></span>
                        </span>
                      </span>
                      <span className="option-label">
                        <span className="option-head">
                          <span className="option-title">Contratos existentes</span>
                        </span>
                        <span className="option-body">Usar contrato com slot disponivel</span>
                      </span>
                    </label>
                  </div>
                  <div className="col-lg-6">
                    <label className="option option option-plain">
                      <span className="option-control">
                        <span className="radio">
                          <input type="radio" checked={!useExistentContract} onClick={() => changeUseExistentContract(false)} />
                          <span></span>
                        </span>
                      </span>
                      <span className="option-label">
                        <span className="option-head">
                          <span className="option-title">Nova venda</span>
                        </span>
                        <span className="option-body">Efetuar uma nova venda</span>
                      </span>
                    </label>
                  </div>
                </div>}

                {/*<label className="px-2">Escolha os dias da semana que o jogador vai participar</label>
                <Grid container className={"mb-2"} spacing={1}>
                  <Grid item xs><Chip onClick={() => selectWeekDay(2)}  clickable={checkWeekDay(2)} color={selectedWeekDayColor(2)} style={styleWeekDay(2)} variant="outlined" label="Seg" /></Grid>
                  <Grid item xs><Chip onClick={() => selectWeekDay(4)}  clickable={checkWeekDay(4)} color={selectedWeekDayColor(4)} style={styleWeekDay(4)} variant="outlined" label="Ter" /></Grid>
                  <Grid item xs><Chip onClick={() => selectWeekDay(8)}  clickable={checkWeekDay(8)} color={selectedWeekDayColor(8)} style={styleWeekDay(8)} variant="outlined" label="Qua" /></Grid>
                  <Grid item xs><Chip onClick={() => selectWeekDay(16)}  clickable={checkWeekDay(16)} color={selectedWeekDayColor(16)} style={styleWeekDay(16)} variant="outlined" label="Qui" /></Grid>
                  <Grid item xs><Chip onClick={() => selectWeekDay(32)}  clickable={checkWeekDay(32)} color={selectedWeekDayColor(32)} style={styleWeekDay(32)} variant="outlined" label="Sex" /></Grid>
                  <Grid item xs><Chip onClick={() => selectWeekDay(64)}  clickable={checkWeekDay(64)} color={selectedWeekDayColor(64)} style={styleWeekDay(64)} variant="outlined" label="Sab" /></Grid>
                  <Grid item xs><Chip onClick={() => selectWeekDay(1)}  clickable={checkWeekDay(1)} color={selectedWeekDayColor(1)} style={styleWeekDay(1)} variant="outlined" label="Dom" /></Grid>
                </Grid>*/}
              </>}

              {(weekdays.length > 0 || contractType == 'single') && <>
                <Grid item xs={12}>
                  <span className="text-dark">
                    {contractType == 'single' && 'Selecione o dia que o jogador vai participar'}
                    {contractType == 'recurrence' && 'Selecione o dia que vai iniciar o contrato'}
                  </span>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    label={contractType == 'recurrence' ? 'Inicio contrato' : 'Data do evento'}
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        }
                      }
                    }}
                    name="date"
                    fullWidth
                    className={classes.textField}
                    disabled={props.modality == 'reservation' || props.type == 'single'}
                    {...formik.getFieldProps("startDateAt")}
                  >
                    {dates.length > 0 && dates.map((day, index) => <MenuItem key={`day-${index}`} value={day}>
                      {moment(day).format('DD/MM/YYYY')}
                    </MenuItem>)}
                  </TextField>
                </Grid>

                {contractType == 'recurrence' && !useExistentContract && <>
                  <Grid item xs={6}>
                    <TextField
                      name="billing_day"
                      label="Dia vencimento"
                      fullWidth
                      className={classes.textField}
                      variant="outlined"
                      autoComplete="off"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      {...formik.getFieldProps("billing_day")}
                    />

                    {formik.touched.name && formik.errors.billing_day ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.billing_day}</div>
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Período de cobrança"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          }
                        }
                      }}
                      name="plan"
                      fullWidth
                      className={classes.textField}
                      {...formik.getFieldProps("plan")}
                    >
                      {plans.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </TextField>

                    {clients.error && <RenderError error={clients.error.plan} />}
                  </Grid>
                </>}
              </>}

              {/*<Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Dados do responsável
                </span>
              </Grid>*/}

              {/*<Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">Guilherme Duarte</div>
                </div>
              </Grid>*/}

            {/* Forma de pagamento */}
              {/*<Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Forma de pagamento
                </span>
              </Grid>

              <Grid container className={classes.label} spacing={2}>
                <Grid item xs={6}>
                  <Button fullWidth variant={typeClass('single1')} onClick={() => setType('single1')} color="primary" size="large">
                    Dinheiro
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={typeClass('recurrence')} onClick={() => setType('recurrence')} color="primary" size="large">
                    Débito
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={typeClass('unavailable')} onClick={() => setType('unavailable')} color="primary" size="large">
                    Crédito
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={typeClass('unavailable')} onClick={() => setType('unavailable')} color="primary" size="large">
                    Pix
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={typeClass('unavailable')} onClick={() => setType('unavailable')} color="primary" size="large">
                    Cheque
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <span className={`switch switch-sm ${checked ? 'switch-success' :  'switch-danger'}`}>
                  <label>
                    <input
                      type="checkbox"
                      name="sendCopyToPersonalEmail"
                      checked={checked}
                      onChange={changedCheck}
                    />
                    <span></span>
                  </label>
                </span>
                <span className="text-dark">
                  Pagamento realizado?
                </span>
              </Grid>
              </Grid>*/}
            </Grid>
          </Container>}

          {selectedClient.id && contractType && (contractType == 'single' || weekdays.length > 0) &&  <>
            {availableCredits && availableCredits.length > 0 && <div className="row">
              <div className="col-lg-6">
                <label className="option option-plain">
                  <span className="option-control">
                    <span className="radio">
                      <input type="radio" checked={useCredit} onClick={() => changeUseCredit(true)} />
                      <span></span>
                    </span>
                  </span>
                  <span className="option-label">
                    <span className="option-head">
                      <span className="option-title">Créditos disponiveis</span>
                    </span>
                    <span className="option-body">Usar crédito do cliente</span>
                  </span>
                </label>
              </div>
              <div className="col-lg-6">
                <label className="option option option-plain">
                  <span className="option-control">
                    <span className="radio">
                      <input type="radio" checked={!useCredit} onClick={() => changeUseCredit(false)} />
                      <span></span>
                    </span>
                  </span>
                  <span className="option-label">
                    <span className="option-head">
                      <span className="option-title">Nova venda</span>
                    </span>
                    <span className="option-body">Efetuar uma nova venda</span>
                  </span>
                </label>
              </div>
            </div>}

            {useCredit && <>
              <div className="mb-1 font-weight-bold text-dark">Créditos</div>
              <div className="mt-3 font-weight-light font-size-sm mb-5">
                Selecione o serviço que jogador vai realizar<br />A fatura irá ser gerada como paga e o crédito será utilizado.
              </div>

              {availableCredits.map((item, index) => <>
                <div className="separator separator-dashed my-3"></div>
                <div className="d-flex flex-wrap mb-3 cursor-pointer" onClick={() => selectCredit(item)}>
                  <div className="radio-list">
                    <label className="radio radio-lg mb-7">
                      <input type="radio" name="serviceId" checked={selectedCredit.id == item.id} onClick={() => selectCredit(item)} />
                      <span></span>
                    </label>
                  </div>

                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <span className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">{item.relationships.origin.attributes.name} ({item.attributes.duration}min)</span>

                    {item.attributes.expire_dates.length == 0 && <span className="font-weight-bold font-size-sm" style={{color: 'gray'}}>
                      Sem data limite para uso
                    </span>}


                    {item.attributes.expire_dates.length == 1 && <span className="font-weight-bold font-size-sm" style={{color: 'gray'}}>
                      válido até {item.attributes.expire_dates[0]}
                    </span>}

                    {item.attributes.expire_dates.length > 1 && <>
                      <span className="font-weight-bold font-size-sm" style={{color: 'gray'}}>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="user-notification-tooltip">
                              {item.attributes.expire_dates.map(expireDate => <p>
                                {expireDate}
                              </p>)}
                            </Tooltip>
                          }
                        >
                          <span>válidos até <i className="ki ki-outline-info text-muted ml-2" /></span>
                        </OverlayTrigger>
                      </span>
                    </>}
                  </div>
                  <span className="label label-lg label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder" style={{minWidth: 'fit-content'}}>
                    <span className="ml-2">{item.attributes.total} {item.attributes.total > 1 ? `créditos` : `crédito`}</span>
                  </span>
                </div>
              </>)}
            </>}

            {useExistentContract && <>
              <div className="mb-1 font-weight-bold text-dark">Créditos</div>
              <div className="mt-3 font-weight-light font-size-sm mb-5">
                Selecione o contrato que deseja associar<br />Nenhuma fatura nova será gerada.
              </div>

              {contracts.map((item, index) => <>
                <div className="separator separator-dashed my-3"></div>
                <div className="d-flex flex-wrap mb-3 cursor-pointer" onClick={() => selectContract(item)}>
                  <div className="radio-list">
                    <label className="radio radio-lg mb-7">
                      <input type="radio" name="serviceId" checked={selectedContract.id == item.id} onClick={() => selectContract(item)} />
                      <span></span>
                    </label>
                  </div>

                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <span className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">{item.relationships.service.attributes.name}</span>
                    {/*{item.attributes.expire_dates.length > 0 && <span className="font-weight-bold font-size-sm" style={{color: 'gray'}}>
                      {item.attributes.total > 1 ? `válidos` : `válido`} até
                      {item.attributes.expire_dates.length > 1 && <i className="ki ki-outline-info text-muted ml-2" />}
                      {item.attributes.expire_dates.length == 1 && ` ${item.attributes.expire_dates[0]}`}
                    </span>}
                    {item.attributes.expire_dates.length == 0 && <span className="font-weight-bold font-size-sm" style={{color: 'gray'}}>
                      Sem data limite para uso
                    </span>}*/}
                    <span className="font-weight-bold font-size-sm" style={{color: 'gray'}}>
                      {item.relationships.service.attributes.recurrence_times}x por semana
                      <span style={{
                        // color: "#59ba32",
                        textTransform: "uppercase"
                      }} className="text-primary">
                        {item.attributes.available_spots == 1 ? ` - 1 slot disponível` : ` - ${item.attributes.available_spots} slots disponíveis`}
                      </span>
                    </span>
                  </div>
                  <span className="label label-lg label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder" style={{minWidth: 'fit-content'}}>
                    <span className="ml-2">
                      {item.attributes.price != 0.00 && `R$ ${parseFloat(item.attributes.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
                      {item.attributes.price == 0.00 && `Grátis`}
                    </span>
                  </span>
                </div>
              </>)}
            </>}

            {!useCredit && !useExistentContract && <>
              <div className="mb-5 font-weight-bold text-dark">Serviço</div>
              <div className="mt-3 font-weight-bold font-size-sm mb-5">Selecione o serviço que jogador vai realizar</div>

              {prices.priceService && contractType == 'single' && prices.priceService.filter(item => item.service.attributes.type == 'single').map((item, index) => <>
                <div className="separator separator-dashed my-3"></div>
                <div className="d-flex flex-wrap mb-3 cursor-pointer" onClick={() => {setSelectServicePrice(item)}}>
                  <div className="radio-list">
                    <label className="radio radio-lg mb-7">
                      <input type="radio" name="serviceId" checked={selectedService.id == item.service.id} onClick={() => setSelectServicePrice(item)} />
                      <span></span>
                    </label>
                  </div>

                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <a className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">{item.service.attributes.name}</a>
                    <span className="font-weight-bold" style={{color: getStatusColor(item.service.attributes.type)}}>
                      {getType(item.service.attributes.type)}
                    </span>
                    <span className="text-muted font-weight-bold">
                      Entre {convertMinsToHrsMins(item.service.attributes.minTime)} ~ {convertMinsToHrsMins(item.service.attributes.maxTime)}, a cada {convertMinsToHrsMins(item.service.attributes.granularity)}
                    </span>
                  </div>
                  <span className="label label-lg label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder" style={{minWidth: 'fit-content'}}>
                    <span className={selectedClient.attributes.is_member && item.price_member != 0.00 && item.price_member != item.price && classes.lineThrough}>
                      {item.price != 0.00 && `R$ ${parseFloat(item.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
                      {item.price == 0.00 && `Grátis`}
                    </span>
                    {selectedClient.attributes.is_member && item.price_member != 0.00 && item.price_member != item.price && <span className="ml-2">R$ {parseFloat(item.price_member).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</span>}
                  </span>
                </div>
              </>)}

              {prices.priceService && contractType == 'recurrence' && prices.priceService.filter(item => item.service.attributes.type == 'recurrence').map((item, index) => <>
                <div className="separator separator-dashed my-3"></div>
                <div className="d-flex mb-3" onClick={() => setSelectServicePrice(item)}>
                  <div className="radio-list">
                    <label className={`radio radio-lg mb-7 ${(weekdays.length > item.service.attributes.recurrence_times) && "radio-disabled"}`}>
                      <input
                        type="radio"
                        name="serviceId"
                        checked={selectedService.id == item.service.id}
                        onClick={() => setSelectServicePrice(item)}
                        disabled={weekdays.length > item.service.attributes.recurrence_times}
                      />
                      <span></span>
                    </label>
                    {/*{weekdays.length > item.service.attributes.recurrence_times && <label className="mb-7">
                      <span>Indisponivel</span>
                    </label>}*/}
                  </div>
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <a className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">{item.service.attributes.name}</a>
                    <span className="text-muted font-weight-bold">
                      <span style={{color: getStatusColor(item.service.attributes.type)}}>{getType(item.service.attributes.type)}</span> - {item.service.attributes.recurrence_times}x por semana
                    </span>
                    <span className="text-muted font-weight-bold">
                      Entre {convertMinsToHrsMins(item.service.attributes.minTime)} ~ {convertMinsToHrsMins(item.service.attributes.maxTime)}, a cada {convertMinsToHrsMins(item.service.attributes.granularity)}
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    <span className="label label-lg label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder" style={{minWidth: 'fit-content'}}>
                      {weekdays.length > item.service.attributes.recurrence_times && "Indisponivel"}
                      {weekdays.length <= item.service.attributes.recurrence_times && <>
                        {item.price != 0.00 && `R$ ${parseFloat(item.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}/mês`}
                        {item.price == 0.00 && `Grátis`}
                      </>}
                    </span>

                    {item.price_quarterly != item.price && item.price_quarterly != 0.00 && <span className="label label-lg label-light label-inline mt-2 text-dark-50 font-weight-bolder mb-2 strikethrough">
                      {`R$ ${parseFloat(item.price_quarterly).toLocaleString('pt-br', { minimumFractionDigits: 2 })}/trimestral`}
                    </span>}

                    {item.price_semester != item.price_quarterly && item.price_semester != 0.00 && <span className="label label-lg label-light label-inline mt-2 text-dark-50 font-weight-bolder mb-2 strikethrough">
                      {`R$ ${parseFloat(item.price_semester).toLocaleString('pt-br', { minimumFractionDigits: 2 })}/semestral`}
                    </span>}

                    {item.price_yearly != item.price_semester && item.price_yearly != 0.00 && <span className="label label-lg label-light label-inline mt-2 text-dark-50 font-weight-bolder mb-2 strikethrough">
                      {`R$ ${parseFloat(item.price_yearly).toLocaleString('pt-br', { minimumFractionDigits: 2 })}/anual`}
                    </span>}
                  </div>
                </div>
              </>)}
            </>}
          </>}
        </Modal.Body>

        {selectedClient.id && <Modal.Footer>
          <Grid container spacing={3}>
            {selectedService && selectedService.id && <>
              <Grid item xs={6}>
                <span className=" font-size-h4 text-dark-50 font-weight-bolder mb-2">
                  Subtotal
                </span>
              </Grid>
              <Grid item xs={6} className="text-right">
                {/*<span class="label label-xl font-weight-boldest mr-2 cursor-pointer" onClick={() => console.log('1231231')}>
                  <i class="fas fa-pen"></i>
                </span>*/}
                <span className=" font-size-h4 text-dark-50 font-weight-bolder mb-2 text-right">
                  {!useCredit && !useExistentContract && getServicePrice()}
                  {useCredit && `1 crédito`}
                  {useExistentContract && `1 slot`}
                </span>
              </Grid>
            </>}

            <Grid item xs style={{textAlign: 'right'}}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                size="large"
                onClick={selectRecurrenceClient}
                disabled={(formik.isSubmitting || !selectedService.id || !formik.values.startDateAt || (contractType == 'recurrence' && !useExistentContract && !formik.values.billing_day ))}
              >
                Confirmar {(schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>}
      </form>
    </Modal>
  </>);
};

export default CreateClientSchedule;