import * as Yup from "yup";

export const resetPasswordStartFlowSchema = (props) => {
  return Yup.object().shape({
    email: Yup.string()
      .email("O valor deve ser um email")
      .min(3, "Mínimo de 3 caracteres")
      .max(50, "Máximo de 50 caracteres")
      .required("O campo email é obrigatório"),
  });
};
