import * as requestFromServer from "./bankCrud";

import { bankSlice } from "./bankSlice";

const { actions } = bankSlice;

export const fetchBanks = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .getBanks()
      .then(response => {
        const entities = response.data;

        dispatch(actions.banksFetched({ entities }));

        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};