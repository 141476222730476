import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup'

import CloseIcon from '@material-ui/icons/Close';

import { fetchGetAvailableContracts } from "../../Contract/_redux/contractActions";

import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import { fetchListRecurrenceDays } from "../_redux/scheduleActions";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const SelectContract = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { schedules } = useSelector((state) => ({
    schedules: state.schedules,
  }), shallowEqual);

  const [selectedContract, setSelectedContract] = useState({});
  const [dates, setDates] = useState([]);
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    if (schedules.addClient == true) {
      close();
    }
  }, [schedules]);

  useEffect(() => {
    if (!props.show) {
      close();
    } else {
      formik.setFieldValue('startDateAt', moment(props.startDateAt).format('YYYY-MM-DD'), false);

      listRecurrenceDays();
    }
  }, [props.show]);

  const close = () => {
    props.onHide();

    setSelectedContract({});

    formik.setFieldValue('startDateAt', '', false);
  }

  const dispatchContract = () => {
    selectedContract.startDateAt = formik.values.startDateAt;

    let value = JSON.parse(JSON.stringify(selectedContract));

    if (props.selectContract) {
      props.selectContract(value);
    }
  }

  const listRecurrenceDays = () => {
    let weekdaysArray = props.weekdays

    if (weekdaysArray.length) {
      let endDateAt = ''

      if (props.endDateAt) {
        endDateAt = moment(props.endDateAt).format('YYYY-MM-DD');
      }

      dispatch(fetchListRecurrenceDays(weekdaysArray.reduce((x, y) => x + y, 0), moment(props.startDateAt).format('YYYY-MM-DD'), endDateAt)).then((data) => {
        setDates(data);
      });
    }
  }

  const formik = useFormik({
    initialValues: {
      startDateAt: '',
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    },
  });

  useEffect(() => {
    if (props.show && formik.values.startDateAt) {
      dispatch(fetchGetAvailableContracts({
        modality: props.modality,
        field_id: props.field_id,
        date: formik.values.startDateAt
      })).then((data) => {
        setContracts(data);
      });
    }
  }, [formik.values.startDateAt]);

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Associar contrato
          </Modal.Title>
          <CloseIcon onClick={close} className="mt-3 mr-3 cursor-pointer" />
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={6}>
                <TextField
                  select
                  label={'Inicio contrato'}
                  variant="outlined"
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      }
                    }
                  }}
                  name="date"
                  fullWidth
                  className={classes.textField}
                  disabled={props.modality == 'reservation' || props.type == 'single'}
                  {...formik.getFieldProps("startDateAt")}
                >
                  {dates.length > 0 && dates.map((day, index) => <MenuItem key={`day-${index}`} value={day}>
                    {moment(day).format('DD/MM/YYYY')}
                  </MenuItem>)}
                </TextField>
              </Grid>

              {<Grid item xs={12}>
                {contracts.map((contract) =>
                  <div className={classes.detailedExpansionPanel} onClick={() => setSelectedContract(contract)}>
                    <div className={classes.root}>
                      <ListGroup className="my-3" key={contract.id} style={{cursor: 'pointer'}}>
                        {contract.attributes && <ListGroup.Item variant="light" style={selectedContract.id == contract.id ? {borderColor: '#787878', backgroundColor: '#f2f2f2'} : null}>
                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <span className="font-weight-normel mb-1">
                              <span className="font-weight-bold">Nome</span>: {contract.relationships.client.attributes.name}

                              {contract.relationships.client.attributes.is_dependent && <span className={`ml-2 label label-lg label-light label-inline`}>
                                Dependente
                              </span>}

                              {contract.relationships.client.attributes.type == 'instructor' && <span className={`ml-2 label label-lg label-light-success label-inline`}>
                                Professor
                              </span>}
                            </span>
                          </div>

                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <span className="font-weight-normel mb-1">
                              <span className="font-weight-bold">Serviço</span>: {contract.relationships.service.attributes.name}
                            </span>
                          </div>

                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <span className="font-weight-normel mb-1">
                              <span className="font-weight-bold">Recorrência</span>: {contract.relationships.service.attributes.recurrence_times}x por semana
                            </span>
                          </div>

                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <span className="font-weight-normel mb-1">
                              <span className="font-weight-bold">Recorrência disponível</span>: {contract.attributes.available_spots}
                            </span>
                          </div>

                          <div className="d-flex flex-column flex-grow-1 mr-2">
                            <span className="font-weight-normel mb-1">
                              <span className="font-weight-bold">Início do contrato:</span>: {moment(contract.attributes.start_at).format('DD/MM/YYYY')}
                            </span>
                          </div>

                          {contract.attributes.finish_at && <div className="d-flex flex-column flex-grow-1 mr-2">
                            <span className="font-weight-normel mb-1">
                              <span className="font-weight-bold">Término do contrato:</span>: {moment(contract.attributes.finish_at).format('DD/MM/YYYY')}
                            </span>
                          </div>}
                        </ListGroup.Item>}
                      </ListGroup>
                    </div>
                  </div>
                )}

                {!contracts.length && <span>Nenhum contrato encontrado</span>}
              </Grid>}
            </Grid>

            {(typeof schedules.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {schedules.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" onClick={dispatchContract} disabled={(formik.isSubmitting || !selectedContract.id)}>
                Confirmar {(schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>
  </>);
};

export default SelectContract;