import * as requestFromServer from "./planCrud";
import { planSlice, callTypes } from "./planSlice";

const { actions } = planSlice;

export const fetchPlans = (status = 'active', type = '', date = '', branchId = '') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return new Promise((resolve, reject) => {
    requestFromServer
      .getPlans(
        status,
        type,
        date,
        branchId
      )
      .then(response => {
        const entities = response.data;
        const totalCount = entities.length;

        dispatch(actions.plansFetched({ totalCount, entities }));
        resolve(entities);
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.list }));

        dispatch(actions.plansFetched({ totalCount: 0, entities: null }));

        reject(error);
      });
    });
};

export const fetchCreatePlan = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let data = {
    name: params.name,
    description: params.description,
    type: params.type,
    weekday: params.weekday,
    price: params.price,
    duration: params.duration,
    branches: params.branches,
    available_app: params.availableApp == 'yes' ? true : false,
  };

  if (params.package) {
    data.package = params.package;
  }

  if (params.memberPrice) {
    data.member_price = params.memberPrice;
  }

  return new Promise((resolve, reject) => {
    requestFromServer
      .createPlan(data).then(response => {
        dispatch(actions.planCreated());
        resolve(response.data);
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const fetchUpdatePlan = (planId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    name: params.name,
    description: params.description,
    weekday: params.weekday,
    price: params.price,
    duration: params.duration,
    branches: params.branches,
    available_app: params.availableApp == 'yes' ? true : false,
  };

  if (params.package) {
    data.package = params.package;
  }

  if (params.memberPrice) {
    data.member_price = params.memberPrice;
  }

  return requestFromServer
    .updatePlan(planId, data)
    .then(response => {
      dispatch(actions.planUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDeletePlan = (planId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deletePlan(planId)
    .then(response => {
      dispatch(actions.planUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
