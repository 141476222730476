import axios from "axios";

export const GET_USER = `${process.env.REACT_APP_API_URL}/users`;
export const CREATE_USER = `${process.env.REACT_APP_API_URL}/users`;
export const UPDATE_USER = `${process.env.REACT_APP_API_URL}/users`;

export function getUsers(params) {
  return axios.get(GET_USER, { params: params});
}

export function createUser(params) {
  return axios.post(CREATE_USER, params);
}

export function updateUser(userId, params) {
  return axios.put(`${UPDATE_USER}/${userId}`, params);
}

export function updateUserPassword(userId, params) {
  return axios.put(`${UPDATE_USER}/${userId}/password`, params);
}

export function updateUserPhoto(userId, params) {
  return axios.put(`${UPDATE_USER}/${userId}/photo`, params);
}