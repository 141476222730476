import * as requestFromServer from "./couponCrud";
import { couponSlice, callTypes } from "./couponSlice";
import { notifyError } from "../../../utils/Notify";

const { actions } = couponSlice;

export const fetchCoupons = (data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    per_page: data.perPage,
    page: data.page,
  };

  if (data.statusId) {
    params.status_id = data.statusId;
  }

  if (data.branchId) {
    params.branch_id = data.branchId;
  }

  if (data.clientId) {
    params.client_id = data.clientId;
  }

  if (data.originType) {
    params.origin_type = data.originType;
  }

  if (data.fieldId) {
    params.field_id = data.fieldId;
  }

  if (data.duration) {
    params.duration = data.duration;
  }

  return requestFromServer
    .getCoupons(params)
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.couponsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.couponMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.couponsFetched({ totalCount: 0, entities: null }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'client-failed-update', 2000);
      }
    });
};

export const fetchAvailableCoupons = (data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    origin_type: data.originType,
    field_id: data.fieldId,
    branch_id: data.branchId,
    duration: data.duration,
  };

  if (data.date) {
    params.date = data.date;
  }

  return new Promise((resolve, reject) => {
    requestFromServer
      .getAvailableCoupons(data.clientId, params)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response &&
          error.response.data &&
          error.response.data.message &&
          (typeof error.response.data.message) == "string"
        ) {
          notifyError(error.response.data.message, 'client-failed-list', 2000);
        }

        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);

      });
    });
};

export const fetchGetClientCoupons = (clientId, branchId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .getClientCoupons(clientId, branchId)
      .then(response => {
        dispatch(actions.loadingFinish());
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);
      });
  });
};

export const fetchCoupon = (couponId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCouponById(couponId)
    .then(response => {
      const coupon = response.data;


      dispatch(actions.couponFetched({ coupon: coupon }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.couponFetched({ coupon: {} }));
    });
};

export const clearCoupon = () => dispatch => {
  dispatch(actions.couponFetched({ coupon: {} }));
};

export const fetchCreateCoupon = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .createCoupon(params).then(response => {
        dispatch(actions.couponFetched({ coupon: response.data }));
        dispatch(actions.couponCreated());
        resolve(response.data);
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const fetchRenewCoupon = (couponId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .renewCoupon(couponId)
    .then(response => {
      dispatch(actions.couponRenewed());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateCouponExpiredAt = (couponId, expiredAt) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateCoupon(couponId, {expired_at: expiredAt ?? null})
    .then(response => {
      dispatch(actions.couponUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDeleteCoupon = (couponId, canceledAt) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deleteCoupon(couponId, { canceled_at: canceledAt })
    .then(response => {
      dispatch(actions.couponUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};