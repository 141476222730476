import store from "../../../../redux/store";

import * as requestFromServer from "./withdrawalCrud";
import { actions as authPersistor } from "../../Auth/_redux/authRedux";
import { withdrawalSlice, callTypes } from "./withdrawalSlice";
import { notifyError } from "../../../utils/Notify";

const { actions } = withdrawalSlice;

export const fetchWithdrawals = (branchId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.balance }));

  requestFromServer.getBranchRecipientBalance(branchId).then(response => {
      const data = response.data;

      if (data) {
        dispatch(actions.balanceFetched({ data }));
      } else {
        dispatch(actions.balanceFetched({ data: undefined }));
      }
    }).catch(error => {

      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.balanceFetched({ entity: undefined }));
    });

  dispatch(actions.startCall({ callType: callTypes.operations }));

  requestFromServer.getBranchRecipientBalanceOperations(branchId).then(response => {
      const data = response.data;

      if (data) {
        dispatch(actions.operationsFetched({ data }));
      } else {
        dispatch(actions.operationsFetched({ data: [] }));
      }
    }).catch(error => {

      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.operationsFetched({ entity: [] }));
    });

  dispatch(actions.startCall({ callType: callTypes.payables }));

  requestFromServer.getBranchRecipientPayables(branchId).then(response => {
      const data = response.data;

      if (data) {
        dispatch(actions.payablesFetched({ data }));
      } else {
        dispatch(actions.payablesFetched({ data: [] }));
      }
    }).catch(error => {

      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.payablesFetched({ entity: [] }));
    });

  dispatch(actions.startCall({ callType: callTypes.withdrawals }));

  requestFromServer.getBranchRecipientWithdrawals(branchId).then(response => {
      const data = response.data;

      if (data) {
        dispatch(actions.withdrawalsFetched({ data }));
      } else {
        dispatch(actions.withdrawalsFetched({ data: [] }));
      }
    }).catch(error => {

      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.withdrawalsFetched({ entity: [] }));
    });
};

export const fetchCreateWithdrawal = (branchId, params) => dispatch => {
  // return new Promise((resolve, reject) => {
    dispatch(actions.startCall({ callType: callTypes.action }));

    return requestFromServer
      .createWithdrawal(branchId, params).then(response => {
        dispatch(actions.withdrawalCreated());
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.action }));

        if (error.response &&
          error.response.data &&
          error.response.data.message &&
          (typeof error.response.data.message) == "string"
        ) {
          notifyError(error.response.data.message, 'client-failed-update', 2000);
        }
      });
};

export const fetchUpdateWithdrawal = (branchId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateWithdrawal(branchId, {
      name: params.name,
      address: params.address,
      business_hours: params.business_hours,
      max_period: params.max_period,
      phone: params.phone,
      tags: params.tags,
      zipcode: params.zipcode
    })
    .then(response => {
      dispatch(actions.withdrawalUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};