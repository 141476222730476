import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';

import SVG from "react-inlinesvg";

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { formatMoney } from "../../../utils/IntegerParse";

// import CreateField from '../components/CreateField';
// import EditEvent from '../../Calendar/components/EditEvent';
import EditSchedule from '../../Calendar/components/EditSchedule';

import { fetchSchedules } from "../../Calendar/_redux/scheduleActions";

import StatusChip from "../../../components/StatusChip";
import { InfoBox } from "../components/InfoBox";
import { RenderError } from "../../../components/RenderError";
import SelectClient from "../../Client/components/SelectClient";
import SelectUser from "../../Configuration/User/components/SelectUser";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Tipo' },
  { id: 'relationships.client.attributes.name', numeric: false, disablePadding: true, label: 'Participante(s)' },
  { id: 'relationships.field.attributes.name', numeric: false, disablePadding: true, label: 'Campo' },
  { id: 'attributes.start_at', numeric: false, disablePadding: true, label: 'Horário' },
  { id: 'relationships.status.attributes.name', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'actions', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id + '-headers'}
            align="left"
            // align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <span style={{
                color: "#B5B5C3",
                textTransform: "uppercase"
              }}>
              {row.label}
              </span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (<>
          {/*<Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>*/}
        </>)}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 14
  },
}));

export const ScheduleDateReportPage = () => {
  const { schedules, branchSelected } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
    schedules: state.schedules,
  }), shallowEqual);

  const classes = useStyles();
  const [pagination, setPagination] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('attributes.name');
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [client, setClient] = useState({});
  const [user, setUser] = useState({});

  // const [startAt, setStartAt] = useState();
  // const [finishAt, setFinishAt] = useState();

  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY'));

  const [single, setSingle] = useState(0);
  const [recurrence, setRecurrence] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [openSelectClient, setOpenSelectClient] = useState(false);
  const [openSelectUser, setOpenSelectUser] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const dispatch = useDispatch();

  // useEffect(() => {
  //   setStartAt(moment().startOf('month').format('YYYY-MM-DD'));
  //   setFinishAt(moment().endOf('month').format('YYYY-MM-DD'));
  // }, []);

  useEffect(() => {
    if (month && year) {
      return requestEntities();
    }
  }, [month, year, client, user, branchSelected]);

  useEffect(() => {
    if (schedules.entities) {
      setRows(schedules.entities);

      let countSingle = 0;
      let countRecurrence = 0;

      schedules.entities.map(schedule => {
        if(schedule.attributes.recurrence) {
          countSingle++;
        } else {
          countRecurrence++;
        }
      });

      setSingle(countSingle);
      setRecurrence(countRecurrence);

    }
  }, [schedules]);


  const stringToColour = function(str) {
    var hash = 0;
    for (var a = 0; a < str.length; a++) {
      hash = str.charCodeAt(a) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var b = 0; b < 3; b++) {
      var value = (hash >> (b * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  const pickTextColorBasedOnBgColorAdvanced = (bgColor) => {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? '#000' : '#fff';
  }

  const requestEntities = () => {
    const requestEntity = setTimeout(() => {
      let startAt = moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD');
      let finishAt = moment(`${year}-${month}`).endOf('month').format('YYYY-MM-DD');

      dispatch(fetchSchedules(startAt, finishAt, client.id, [], branchSelected.id, user.id));
    }, 500);

    return () => {
      clearTimeout(requestEntity);
    }
  };

  // const closeCreateEntity = () => {
  //   {createEntityShow &&
  //     requestEntities();
  //   };

  //   setCreateEntityShow(false);
  // };

  const openEditEntity = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }

  const closeEditEntity = () => {
    setEditEntityShow(false);

    if(editEntityShow) {
      return requestEntities();
    };
  }

  const getClassStatus = (status) => {
    if (status == 'pending') {
      return 'warning';
    }

    if (status == 'blocked') {
      return 'danger';
    }

    return 'success';
  }

  const getType = (type) => {
    return type == 'single' ? 'Avulso' : 'Mensalista';
  }

  const getRecurrence = (period, times) => {
    let string = period == 'weekly' ? ' na semana' : ' no mês';

    return times + (times > 1 ? ' vezes' : ' vez') + string;
  }

  const getServices = (services) => {
    var names = services.map(service => {
      return service.attributes.name;
    });

    return services.length + (services.length > 1 ? ' serviços relacinados' : ' serviço relacionado') + ' com esse campo. ' + names.join(', ');
  }

  const getMinMax = (arr) => {
    return arr.reduce(({min, max}, v) => ({
      min: min < v ? min : v,
      max: max > v ? max : v,
    }), { min: arr[0], max: arr[0] });
  }

  const getDuration = (start, end) => {
    if (start == end) {
      return `${start} min`;
    }

    return `${start} ~ ${end} min`;
  }

  const getPrices = (entitiesPrice) => {
    let prices = [];

    entitiesPrice.map(price => {
      prices.push(price.attributes.price);
    });

    let minMax = getMinMax(prices);

    if (minMax.min == minMax.max) {
      return `R$ ${minMax.min} - ${entitiesPrice[0].attributes.time} min`;
    }

    return `R$ ${minMax.min} ~ R$ ${minMax.max} - ${entitiesPrice[0].attributes.time} min`;//prices.length + (prices.length > 1 ? ' preços relacinados' : ' preço relacionado') + ' com esse serviço';
  }

  const dispatchSelectClient = (value) => {
    setClient(value);
    setOpenSelectClient(false);
  }

  const dispatchSelectUser = (value) => {
    setUser(value);
    setOpenSelectUser(false);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (<>
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <span>Relatório de agendamento por data</span>
        </div>
        <div className="card-toolbar">
          {/*<a
            href="#"
            className="btn btn-light-primary font-weight-bold"
            // onClick={() => setCreateEntityShow(true)}
          >
            <i className="ki ki-plus "></i> Cadastrar
          </a>*/}
        </div>
      </div>
      <div className={schedules.listLoading ? 'card-body filtering' : 'card-body'}>
        <Grid container className={classes.label} spacing={2}>
          <Grid item xs={6} spacing={2}>
            <a class="btn btn-light-success font-weight-light font-size-lg mr-5 mt-2" onClick={() => setOpenSelectClient(true)}>
              Filtrar cliente
            </a>
            <a class="btn btn-light-primary font-weight-light font-size-lg mr-5 mt-2" onClick={() => setOpenSelectUser(true)}>
              Filtrar professor
            </a>

            {client.id && <Grid item xs={12}>
              <Chip
                avatar={<Avatar>{client.attributes.name.match(/\b(\w)/g).join('').slice(0,2)}</Avatar>}
                label={client.attributes.name}
                color={client.attributes.type == 'client' ? 'default' : 'black' }
                onDelete={() => setClient({})}
                style={{marginTop: 10}}
                variant="outlined"
              />
            </Grid>}

            {user.id && <Grid item xs={12}>
              <Chip
                avatar={<Avatar>{user.attributes.name.match(/\b(\w)/g).join('').slice(0,2)}</Avatar>}
                label={user.attributes.name}
                color={'default'}
                onDelete={() => setUser({})}
                style={{marginTop: 10}}
                variant="outlined"
              />
            </Grid>}
          </Grid>

          {/*<Grid item xs={3} spacing={2}>
            <TextField
              name="startAt"
              label="Inicio"
              type="date"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={startAt}
              onChange={(date) => setStartAt(date.target.value)}
            />

            {schedules.error && <RenderError error={schedules.error.start_at} />}
          </Grid>
          <Grid item xs={3} spacing={2}>
            <TextField
              name="finishAt"
              label="Final"
              type="date"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={finishAt}
              onChange={(date) => setFinishAt(date.target.value)}
            />

            {schedules.error && <RenderError error={schedules.error.finish_at} />}
          </Grid>*/}

          <Grid item xs={6} spacing={2}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group">
                  <select
                    class="custom-select form-control form-control-solid form-control-lg"
                    value={month}
                    onChange={(value) => setMonth(value.target.value)}
                  >
                    <option value="01">Janeiro</option>
                    <option value="02">Fevereiro</option>
                    <option value="03">Março</option>
                    <option value="04">Abril</option>
                    <option value="05">Maio</option>
                    <option value="06">Junho</option>
                    <option value="07">Julho</option>
                    <option value="08">Agosto</option>
                    <option value="09">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                  </select>
                  <span className="form-text text-muted">Mês de início</span>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="form-group">
                  <select
                    class="custom-select form-control form-control-solid form-control-lg"
                    value={year}
                    onChange={(value) => setYear(value.target.value)}
                  >
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                  </select>
                  <span className="form-text text-muted">Ano de início</span>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs spacing={2}>
            <InfoBox className="bg-info" icon="Home/Timer" value={schedules.listLoading ? <span className="ml-2 spinner spinner-white"></span> : schedules.totalCount} description="Agendamentos" />
          </Grid>
          <Grid item xs spacing={2}>
            <InfoBox className="bg-warning" icon="Home/Clock" value={schedules.listLoading ? <span className="ml-2 spinner spinner-white"></span> : schedules.single} description="Avulso"/>
          </Grid>
          <Grid item xs spacing={2}>
            <InfoBox className="bg-success" icon="Navigation/Check" value={schedules.listLoading ? <span className="ml-2 spinner spinner-white"></span> : schedules.recurrence} description="Mensalista"/>
          </Grid>
          {/*<Grid item xs spacing={2}>
            <InfoBox className="bg-primary" icon="Shopping/Money" value={formatMoney(schedules.total)} description="Total"/>
          </Grid>*/}
        </Grid>
      </div>
      <div className={schedules.listLoading ? 'card-body filtering' : 'card-body'}>
        {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
        <div className={classes.tableWrapper}>
          {/*<Table
            key="tableWrapper"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
          >*/}

          <Table
            className="table table-head-custom table-head-bg table-vertical-center"
            key="tableWrapper"
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={schedules.totalCount}
            />
            <TableBody key='dsadas'>
              {stableSort(rows, getSorting(order, orderBy)).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      tabIndex={-1}
                      // key={row.id + 'aaaaaaaa'}
                    >
                      <TableCell component="th" id={labelId+'125'} scope="row" padding="none">
                        {row.attributes.recurrence && <>
                          <span className="label label-inline px-3 py-3 ml-2 mx-2" style={{backgroundColor: stringToColour(row.id)}}>
                            <span style={{color: pickTextColorBasedOnBgColorAdvanced(stringToColour(row.id))}}>
                              {row.attributes.modality == 'reservation' ? 'Locação' : 'Aula'} #{row.attributes.occurrence}
                            </span>
                          </span>
                        </>}

                        {!row.attributes.recurrence && <>
                          {row.attributes.modality == 'reservation' ? 'Locação' : 'Aula'} avulso
                        </>}
                      </TableCell>
                      <TableCell component="th" id={labelId+'123'} scope="row" padding="none">
                        {row.relationships.schedule_spot && row.relationships.schedule_spot.length == 0 && <span>
                          Nenhum Participante
                        </span>}
                        {row.relationships.schedule_spot.map((schedule_spot, index) => <span>
                            {schedule_spot.relationships.contract.relationships.client.attributes.name}
                            <span className="label label-lg label-light-warning label-inline font-weight-bold py-4 mx-1 my-1">{schedule_spot.relationships.contract.relationships.service.attributes.name}</span><br />
                            {/*{row.relationships.schedule_spot.length - 1 != index ? ', ':''}*/}
                        </span>)}
                      </TableCell>
                      <TableCell component="th" id={labelId+'124'} scope="row" padding="none">
                        {row.relationships.field.attributes.name}
                      </TableCell>
                      <TableCell component="th" id={labelId+'126'} scope="row" padding="none">
                        {moment(row.attributes.start_at).format('DD/MM - HH:mm')}<br />
                        {moment(row.attributes.finish_at).format('DD/MM - HH:mm')}
                      </TableCell>
                      <TableCell component="th" id={labelId+'127'} scope="row" padding="none">
                        <StatusChip status={row.relationships.status} />
                      </TableCell>
                      {/*<TableCell component="th" id={labelId+'128'} scope="row" padding="none">
                        {formatMoney(row.attributes.price)}

                        {(row.relationships.service.attributes.type == 'recurrence') && <Tooltip title="Valor referente a mensalidade">
                          <Icon style={{ color: "#a6bf54" }}>info_outlined</Icon>
                        </Tooltip>}
                      </TableCell>*/}

                      <TableCell align="right" id={labelId+'129'}>
                        <a
                          title="Editar agendamento"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          onClick={() => openEditEntity(row)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/icons/Design/ZoomPlus.svg")}
                            />
                          </span>
                        </a>
                        <> </>

                        {/*<a
                          title="Delete customer"
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          // onClick={() => openDeleteCustomerDialog(row.id)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                          </span>
                        </a>*/}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/*{emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}*/}
            </TableBody>
          </Table>
        </div>

        <div className="d-flex align-items-center py-3">
          {schedules.listLoading && <div className="d-flex align-items-center">
            <div className="mr-2 text-muted">Carregando...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>}

          {pagination && <TablePagination
            labelRowsPerPage="Registros por página:"
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={schedules.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima Página',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />}

          {!pagination && <span style={{color: "#B5B5C3", textTransform: "uppercase"}}>
            {schedules.totalCount} registro(s)
          </span>}
        </div>
      </div>

      {/*<CreateField show={createEntityShow} onHide={closeCreateEntity} />*/}

      {/*<EditEvent show={editEntityShow} onHide={closeEditEntity} event={{event: editEntity}} />*/}
      <EditSchedule show={editEntityShow} onHide={closeEditEntity} event={{event: editEntity}} />

      <SelectClient
        show={openSelectClient}
        title={'Filtrar cliente'}
        selectClient={dispatchSelectClient}
        onHide={() => setOpenSelectClient(false)}
      />

      <SelectUser
        show={openSelectUser}
        title={'Filtrar professor'}
        selectUser={dispatchSelectUser}
        onHide={() => setOpenSelectUser(false)}
      />
    </div>
  </>);
}
