import axios from "axios";

export const GET_FIELDS = `${process.env.REACT_APP_API_URL}/fields`;
export const CREATE_FIELD = `${process.env.REACT_APP_API_URL}/branches`;
export const UPDATE_FIELD = `${process.env.REACT_APP_API_URL}/fields`;

export function getFields(params) {
  return axios.get(GET_FIELDS, { params: params });
}

export function createField(branchId, name, groundType, maxPlayers, serviceId) {
  let params = {
    name: name,
    ground_type: groundType,
    max_players: maxPlayers,
    service_id: serviceId
  };

  return axios.post(`${CREATE_FIELD}/${branchId}/fields`, params);
}

export function updateField(fieldId, branchId, name, groundType, maxPlayers, serviceId) {

  let params = {
    name: name,
    ground_type: groundType,
    max_players: maxPlayers,
    service_id: serviceId
  };

  return axios.put(`${UPDATE_FIELD}/${fieldId}`, params);
}