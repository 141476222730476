import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";
import { inRange } from "lodash";
// import Chart from "react-apexcharts";
import { Chart } from "react-google-charts";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';

import CloseIcon from '@material-ui/icons/Close';

import ShowInvoice from "../components/ShowInvoice";
import CancelContract from "../../Calendar/components/CancelContract";
import CancelScheduleSpot from "../../Calendar/components/CancelScheduleSpot";

import {
  fetchInvoice,
  closeInvoice,
  getPaymentTypeLabel,
  getPaymentMethodLabel,
} from "../_redux/invoiceActions";

import {
  fetchGetContractById,
  fetchUpdateContractPrice,
  fetchRenewContractInvoice,
  contractPriceClose
} from "../../Contract/_redux/contractActions";

import {
  getWeekDays
} from "../../../components/DateUtils";
import Snackbar from "../../../components/Snackbar";
import { formatMoney } from "../../../utils/IntegerParse";
import { InfoBox } from "../../Report/components/InfoBox";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { notifySuccess } from "../../../utils/Notify";

import {
  getLabelInvoiceStatus,
  getColorInvoiceStatus,
} from "../../../utils/Labels";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const InvoicePage = (props) => {
  const dispatch = useDispatch();
  const { schedules, invoices, contracts } = useSelector((state) => ({
    schedules: state.schedules,
    invoices: state.invoices,
    contracts: state.contracts,
  }), shallowEqual);

  const classes = useStyles();

  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [selectedScheduleSpot, setSelectedScheduleSpot] = useState({});
  const [tab, setTab] = useState('info');
  const [contract, setContract] = useState({});
  const [showEditPrice, setShowEditPrice] = useState(false);
  const [data, setData] = useState([]);

  const [cancelContract, setCancelContract] = useState({});
  const [showCancelContract, setShowCancelContract] = useState(false);
  const [showCancelScheduleSpot, setShowCancelScheduleSpot] = useState(false);
  const [seeAll, setSeeAll] = useState(false);

  useEffect(() => {
    if (
      schedules.removeContract == true ||
      schedules.removeScheduleSpot == true ||
      contracts.updated == true
    ) {
      loadContract();
    }
  }, [schedules, contracts.updated]);

  // let data = {
  //   series: [
  //     {
  //       data: [
  //         {
  //           x: 'Spots',
  //           y: [
  //             new Date('2019-02-27').getTime(),
  //             new Date('2019-03-03').getTime()
  //           ],
  //           fillColor: '#008FFB'
  //         },
  //         {
  //           x: 'Spots',
  //           y: [
  //             new Date('2019-03-04').getTime(),
  //             new Date('2019-03-06').getTime()
  //           ],
  //           fillColor: '#00E396'
  //         },
  //         {
  //           x: 'Spots',
  //           y: [
  //             new Date('2019-03-07').getTime(),
  //             new Date('2019-03-10').getTime()
  //           ],
  //           fillColor: '#775DD0'
  //         },
  //         {
  //           x: 'Spots',
  //           y: [
  //             new Date('2019-03-11').getTime(),
  //             new Date('2019-03-12').getTime()
  //           ],
  //           fillColor: '#FEB019'
  //         },
  //         {
  //           x: 'Spots',
  //           y: [
  //             new Date('2019-03-01').getTime(),
  //             new Date('2019-03-17').getTime()
  //           ],
  //           fillColor: '#FF4560'
  //         }
  //       ]
  //     }
  //   ],
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: 'rangeBar'
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: true,
  //         distributed: false,
  //         dataLabels: {
  //           hideOverflowingLabels: true
  //         },
  //         rangeBarOverlap: false,
  //         rangeBarGroupRows: true,
  //       }
  //     },
  //     dataLabels: {
  //       enabled: true,
  //       formatter: function(val, opts) {
  //         var label = opts.w.globals.labels[opts.dataPointIndex]
  //         var a = moment(val[0])
  //         var b = moment(val[1])
  //         var diff = b.diff(a, 'days')
  //         return label + ': ' + diff + (diff > 1 ? ' days' : ' day')
  //       },
  //       style: {
  //         colors: ['#f3f4f5', '#fff']
  //       }
  //     },
  //     xaxis: {
  //       type: 'datetime'
  //     },
  //     yaxis: {
  //       show: false
  //     },
  //     grid: {
  //       row: {
  //         colors: ['#f3f4f5', '#fff'],
  //         opacity: 1
  //       }
  //     }
  //   },
  // };

  // const data = [
  //   [
  //     { type: "string", id: "Term" },
  //     { type: "string", id: "Name" },
  //     { type: "date", id: "Start" },
  //     { type: "date", id: "End" },
  //   ],
  //   ["", "01/01 até 15/08", new Date(2023, 1, 1), new Date(2023, 8, 15)],
  //   ["", "Ativo desde 17/01", new Date(2023, 1, 17), new Date()],
  //   ["", "Ativo desde 22/08", new Date(2023, 8, 22), new Date()],
  // ];

  const options = {
    chart: {
      locales: [{
        "name": "pt-br",
        "options": {
          "months": [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
          ],
          "shortMonths": [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez"
          ],
          "days": [
            "Domingo",
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado"
          ],
          "shortDays": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
          "toolbar": {
            "exportToSVG": "Baixar SVG",
            "exportToPNG": "Baixar PNG",
            "exportToCSV": "Baixar CSV",
            "menu": "Menu",
            "selection": "Selecionar",
            "selectionZoom": "Selecionar Zoom",
            "zoomIn": "Aumentar",
            "zoomOut": "Diminuir",
            "pan": "Navegação",
            "reset": "Reiniciar Zoom"
          }
        }
      }],
      defaultLocale: 'pt-br',
    },
    timeline: {
      showRowLabels: false,
    }
  }

  const getPlanLabel = (value) => {
    if (value == 'monthly') { return 'Mensal' };
    if (value == 'quarterly') { return 'Trimestral' };
    if (value == 'semester') { return 'Semestral' };
    if (value == 'yearly') { return 'Anual' };

    return '';
  }

  const closeShowInvoice = () => {
    setShowInvoice(false);
  }

  const openShowInvoice = (invoice) => {
    setShowInvoice(true);
    setSelectedInvoice(invoice);
  }

  const closeCancelScheduleSpot = () => {
    setShowCancelScheduleSpot(false);
    setSelectedScheduleSpot({});
  }

  const openShowCancelScheduleSpot = (schedule_spot) => {
    setShowCancelScheduleSpot(true);
    setSelectedScheduleSpot(schedule_spot);
  }

  const close = () => {
    props.onHide();

    setSelectedInvoice({});
    setContract({});
    setShowEditPrice(false);
    setSeeAll(false);

    formik.setFieldValue('price', '', false);
  }

  const confirmModal = () => {
    props.onHide();
  }

  const dispatchUpdatePrice = () => {
    dispatch(fetchUpdateContractPrice(contract.id, formik.values.price));
  }

  const dispatchRenewContractInvoice = () => {
    dispatch(fetchRenewContractInvoice(contract.id));
  }

  const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
      <a
        ref={ref}
        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        onClick={e => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        {props.children}
      </a>
    );
  });

  const getInvoices = () => {
    if (props.show && props.contract) {
      dispatch(fetchInvoice({
        origin_id: props.contract.id,
        sort: '-start_at'
      }));
    }
  }

  const getInvoiceStatusLabel = (invoice) => {
    let label = 'A vencer';

    if (invoice.attributes && invoice.attributes.is_canceled) {
      label = 'Cancelado';
    }

    if (invoice.attributes && invoice.attributes.is_paid) {
      label = 'Pago';
    }

    if (invoice.attributes && invoice.attributes.is_expired) {
      label = 'Vencido';
    }

    return label;
  }

  const getServiceLabel = (type) => {
    if (type == 'single') {
      return 'Avulso';
    }

    if (type == 'recurrence') {
      return 'Mensal';
    }

    if (type == 'unavailable') {
      return 'Indisponível';
    }

    return '';
  }

  const getModalityLabel = (modality) => {
    if (modality == 'reservation') {
      return 'Locação';
    }

    if (modality == 'class') {
      return 'Aula';
    }

    return modality;
  }

  const openCancelContract = (value) => {
    setCancelContract(value)
    setShowCancelContract(true);
  }

  const closeCancelContract = () => {
    setShowCancelContract(false);
  }

  const loadContract = () => {
    getInvoices();

    if (props.contract && props.contract.id) {
      dispatch(fetchGetContractById(props.contract.id)).then(data => {
        let value = data[0];

        setContract(value);
        if (value.attributes && value.attributes.price) {
          formik.setFieldValue('price', value.attributes.price, false);
        }

        let spots = [
          [
            { type: "string", id: "Term" },
            { type: "string", id: "Name" },
            { type: "date", id: "Start" },
            { type: "date", id: "End" },
          ],
          // ["", "01/01 até 15/08", new Date(2023, 1, 1), new Date(2023, 8, 15)],
          // ["", "Ativo desde 17/01", new Date(2023, 1, 17), new Date()],
          // ["", "Ativo desde 22/08", new Date(2023, 8, 22), new Date()],
        ];

        value.relationships.schedule_spot.map(schedule_spot => {
          if (schedule_spot.attributes.finish_at) {
            if (moment(schedule_spot.attributes.start_at).isSameOrAfter(moment(schedule_spot.attributes.finish_at))) {
              return
            }
          }

          spots.push([
            "",
            schedule_spot.attributes.finish_at ? `${moment(schedule_spot.attributes.start_at).format('DD/MM/YYYY')} até ${moment(schedule_spot.attributes.finish_at).format('DD/MM/YYYY')}` : `Ativo desde ${moment(schedule_spot.attributes.start_at).format('DD/MM/YYYY')}`,
            new Date(schedule_spot.attributes.start_at),
            schedule_spot.attributes.finish_at ? new Date(schedule_spot.attributes.finish_at) : new Date(),
          ]);
        })

        console.log(spots);

        setData(spots);
      })
    }
  }

  useEffect(() => {
    if (props.show) {
      loadContract();
    } else {
      close();
    }
  }, [props.show]);

  useEffect(() => {
    if ((invoices.updated || invoices.priceUpdated) && !invoices.actionsLoading) {
      closeShowInvoice();
      getInvoices();

      notifySuccess('Atualizado com sucesso', 'invoices-updated', 2000);
      dispatch(closeInvoice());
    }
  }, [invoices]);

  useEffect(() => {
    if (contracts.priceUpdated) {
      close();
      dispatch(contractPriceClose());

      notifySuccess('Atualizado com sucesso', 'contracts-updated', 2000);
    }
  }, [contracts]);

  const formik = useFormik({
    initialValues: {
      price: '',
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    },
  });

  return (<>
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={
        props.show &&
        !showInvoice &&
        !showCancelContract &&
        !showCancelScheduleSpot
      }
      contenStyle={{maxWidth: '1000px', backgroundColor: 'transparent !important'}}
    >
      <div className="card card-custom card-transparent">
        <div className="card-header card-header-tabs-line nav-tabs-line-2x">
          <div className="card-toolbar">
            <ul className="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-2x">
              <li className="nav-item mr-3">
                <a className={tab == 'info' ? 'nav-link active' : 'nav-link'} data-toggle="tab" onClick={() => setTab('info')}>
                  <span className="nav-icon">
                    <span className="svg-icon">
                      <i className="ki ki-info"></i>
                    </span>
                  </span>
                  <span className="nav-text font-size-lg">Contrato</span>
                </a>
              </li>
            </ul>
          </div>

          <CloseIcon onClick={close} className="mt-3 mr-3 cursor-pointer" style={{alignSelf: 'flex-end', position: 'absolute', right: 10, top: 10}} />
        </div>

        <div className="card-body pt-10 pb-10 pl-15 pr-15">
          <div className="row">
            <div class="col-lg-6">
              <Grid container className={classes.label}>
                <Grid item xs={8}>
                  <span className="font-weight-bolder text-dark">
                    Informações do contrato
                  </span>
                </Grid>
              </Grid>

              <div className="separator separator-dashed mb-5 mt-1"></div>

              {contract && contract.attributes && <>
                <Grid container className={classes.label} spacing={2}>
                  <div class="col-lg-12 py-1">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Nome:</span> {contract.relationships.client.attributes.name}
                    </span>
                  </div>
                  <div class="col-lg-12 py-1">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Serviço:</span> {contract.relationships.service.attributes.name}
                    </span>
                  </div>
                  <div class="col-lg-12 py-1">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Tipo:</span> {getServiceLabel(contract.relationships.service.attributes.type)} - {getModalityLabel(contract.relationships.service.attributes.modality)}
                    </span>
                  </div>
                  {contract.relationships.service.attributes.type == 'recurrence' && <>
                    <div class="col-lg-12 py-1">
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark-50">Recorrência:</span> {contract.relationships.service.attributes.recurrence_times}x por semana
                      </span>
                    </div>

                    <div class="col-lg-12 py-1">
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark-50">Início do contrato:</span> {moment(contract.attributes.start_at).format('DD/MM/YYYY')}
                      </span>
                    </div>

                    {contract.attributes && contract.attributes.finish_at && <div class="col-lg-12 py-1">
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark-50">Término do contrato</span>: {moment(contract.attributes.finish_at).format('DD/MM/YYYY')}
                      </span>
                    </div>}
                  </>}

                  {contract.relationships.service.attributes.type == 'single' && <>
                    <div class="col-lg-12 py-1">
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark-50">Vigência:</span> {moment(contract.attributes.start_at).format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </>}

                  {contract.relationships.status.attributes.name && <div class="col-lg-12 py-1">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Status: </span>
                      {
                        contract.relationships.status.attributes.name == 'active' &&
                        moment(contract.attributes.finish_at).isBefore(moment()) ? `Finalizado` : contract.relationships.status.attributes.display_name
                      }
                    </span>
                  </div>}

                  {contract.attributes.canceled_at && <Grid item xs={12}>
                    <Alert key="alert-accept-schedule" variant="warning">
                      Contrato finalizado em {moment(contract.attributes.canceled_at).format('DD/MM/YYYY HH:mm')}
                    </Alert>
                  </Grid>}

                  {contract.relationships.service.attributes.modality == 'class' && <a class="btn btn-light-warning font-weight-bolder font-size-sm mt-2" onClick={() => openCancelContract(contract)}>
                    Finalizar contrato
                  </a>}
                </Grid>

                <Grid container className={classes.label}>
                  <Grid item xs={8}>
                    <span className="font-weight-bolder text-dark">
                      {contract.relationships.service.attributes.type == 'recurrence' ? `Partidas associadas` : `Jogos associados`}
                    </span>
                  </Grid>
                </Grid>

                <div className="separator separator-dashed mb-5 mt-1"></div>

                {false && <Grid container className={classes.label} spacing={2}>
                  {/*<Chart
                    options={data.options}
                    series={data.series}
                    type="rangeBar"
                    width="500"
                  />*/}
                  {data && data.length > 1 && <Chart
                    chartType="Timeline"
                    // data={[
                    //   [
                    //     { type: "string", id: "Term" },
                    //     { type: "string", id: "Name" },
                    //     { type: "date", id: "Start" },
                    //     { type: "date", id: "End" },
                    //   ],
                    //   ["", "01/01 até 15/08", new Date(2023, 1, 1), new Date(2023, 8, 15)],
                    //   ["", "Ativo desde 17/01", new Date(2023, 1, 17), new Date()],
                    //   ["", "Ativo desde 22/08", new Date(2023, 8, 22), new Date()],
                    // ]}
                    data={data}
                    width="100%"
                    // height="100%"
                    options={{
                      height: (data.length * 30) + 40,
                      colors: ["#cbb69d", "#603913", "#c69c6e"],
                      timeline: {
                        showRowLabels: false,
                        groupByRowLabel: true,
                      }
                    }}
                  />}
                </Grid>}

                <Grid container className={classes.label} spacing={2}>

                  {contract.attributes.available_spots > 0 &&
                    !contract.attributes.canceled_at &&
                    contract.relationships.status.attributes.name == 'active' &&
                    (!contract.attributes.finish_at || moment(contract.attributes.finish_at).isAfter(moment())) && <Grid item xs={12}>
                    <Alert key="alert-accept-schedule" variant="primary">
                      {contract.attributes.available_spots}
                      {contract.attributes.available_spots > 1 ? ` inscrições disponíveis` : ` inscrição disponível`}
                    </Alert>
                  </Grid>}

                  <Grid item xs={12}>
                    <ListGroup>
                      {contract.relationships.schedule_spot.map(schedule_spot => <>
                        <ListGroup.Item>
                          <Grid container>
                            <Grid item xs={7} fontSize="large">
                              <div className="d-flex flex-column">
                                {schedule_spot.relationships.schedule.relationships.field && <span>
                                  Campo: <span className="font-weight-bold">{schedule_spot.relationships.schedule.relationships.field.attributes.name}</span>
                                </span>}

                                {schedule_spot.relationships.schedule && contract.relationships.service.attributes.type == 'recurrence' && <span>
                                  Dia da semana: <span className="font-weight-bold">
                                    {getWeekDays(schedule_spot.relationships.schedule.attributes.recurrence_days_bit_mask, true)}
                                  </span>
                                </span>}

                                {schedule_spot.relationships.schedule && <span>
                                  Hórario: <span className="font-weight-bold">
                                    {moment(schedule_spot.relationships.schedule.attributes.start_at).format('HH:mm')} - {moment(schedule_spot.relationships.schedule.attributes.finish_at).format('HH:mm')}
                                  </span>
                                </span>}

                                {contract.relationships.service.attributes.type == 'recurrence' && <>
                                  <span>
                                    Início da inscrição: <span className="font-weight-bold">{moment(schedule_spot.attributes.start_at).format('DD/MM/YYYY')}</span>
                                  </span>

                                  {schedule_spot.attributes.finish_at && <span>
                                    Termino da inscrição: <span className="font-weight-bold">{moment(schedule_spot.attributes.finish_at).format('DD/MM/YYYY')}</span>
                                  </span>}
                                </>}

                                {contract.relationships.service.attributes.type == 'single' && <span>
                                  Data: <span className="font-weight-bold">{moment(schedule_spot.attributes.start_at).format('DD/MM/YYYY')}</span>
                                </span>}

                                {schedule_spot.relationships.status && <span>
                                  Status: <span className="font-weight-bold">
                                    {
                                      schedule_spot.relationships.status.attributes.name == 'active' &&
                                      moment(schedule_spot.attributes.finish_at).isBefore(moment()) ? `Finalizado` : schedule_spot.relationships.status.attributes.display_name
                                    }
                                  </span>
                                </span>}

                                {schedule_spot.attributes.canceled_at && <span>
                                  Cancelado em <span className="font-weight-bold">{moment(schedule_spot.attributes.canceled_at).format('DD/MM/YYYY HH:mm')}</span>
                                </span>}
                              </div>
                            </Grid>

                            <Grid item xs={5} style={{textAlign: 'right'}}>
                              {!schedule_spot.attributes.canceled_at &&
                                contract.relationships.service.attributes.modality == 'class' &&
                                contract.relationships.service.attributes.type == 'recurrence' &&
                                <a class="btn btn-light-danger font-weight-bolder font-size-sm mt-2" onClick={() => openShowCancelScheduleSpot(schedule_spot)}>
                                Finalizar inscrição
                              </a>}
                            </Grid>
                          </Grid>
                        </ListGroup.Item>
                      </>)}
                    </ListGroup>
                  </Grid>
                </Grid>
              </>}
            </div>

            <div class="col-lg-6">
              {contract && contract.attributes && <>
                <Grid container className={classes.label}>
                  <Grid item xs={8}>
                    <span className="font-weight-bolder text-dark">
                      Pagamento
                    </span>
                  </Grid>
                </Grid>

                <div className="separator separator-dashed mb-5 mt-1"></div>

                <Grid container className={classes.label} spacing={2}>
                  <div class="col-lg-12 py-1">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">
                        {contract.relationships.service.attributes.type == 'recurrence' && `Valor do plano: `}
                        {contract.relationships.service.attributes.type == 'single' && `Valor da partida: `}
                      </span>
                      <span className="text-dark">
                        {contract.attributes.price == 0.00 && `Grátis`}
                        {contract.attributes.price != 0.00 && formatMoney(contract.attributes.price)}
                      </span>
                    </span>
                  </div>
                  {contract.attributes.plan && <div class="col-lg-12 py-1">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Periodo de pagamento: </span>
                      <span className="text-dark">
                        {getPlanLabel(contract.attributes.plan)}
                      </span>
                    </span>
                  </div>}
                  {contract.relationships.service.attributes.type == 'recurrence' && <div class="col-lg-12 py-1">
                    <a class="btn btn-light-primary font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => setShowEditPrice(true)}>
                      Editar valor
                    </a>
                  </div>}
                </Grid>

                {showEditPrice && <>
                  <div className="separator separator-dashed mb-5 mt-1"></div>

                  <Grid container className={classes.label} spacing={2}>

                    <Grid item xs={12}>
                      <Alert key="alert-accept-schedule" variant="secondary">
                        Somente na próxima fatura será refletivo o novo valor, você também pode editar as faturas existentes.
                      </Alert>
                    </Grid>

                    <div class="col-lg-6 py-1">
                      <TextField
                        name="price"
                        label={'Valor do plano'}
                        fullWidth
                        className={classes.textField}
                        placeholder="ex: 260"
                        variant="outlined"
                        type="number"
                        min="1"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        {...formik.getFieldProps("price")}
                      />
                    </div>

                    <div class="col-lg-6 py-1 flex-column align-items-center">
                      <span class="label label-xl font-weight-boldest label-success mt-5 cursor-pointer" onClick={() => dispatchUpdatePrice()}>
                        <i class="ki ki-check text-white"></i>
                      </span>
                      <span class="label label-xl font-weight-boldest label-danger mt-5 ml-2 cursor-pointer" onClick={() => setShowEditPrice(false)}>
                        <i class="ki ki-close text-white"></i>
                      </span>
                    </div>
                  </Grid>
                </>}
              </>}

              <Grid container className={classes.label}>
                <Grid item xs={8}>
                  <span className="font-weight-bolder text-dark">
                    Faturas
                  </span>
                </Grid>
              </Grid>

              <div className="separator separator-dashed mb-1 mt-1"></div>

              <Grid container className={classes.label} spacing={2}>
                {contract && contract.attributes && contract.relationships.service.attributes.type == 'recurrence' && <>
                  <Grid item xs={6} spacing={2}>
                    <InfoBox className="bg-info" icon={false} widgetHeight={100} fontSize="h3"  value={formatMoney(invoices.counters.pending)} description="A vencer" />
                  </Grid>
                  <Grid item xs={6} spacing={2}>
                    <InfoBox className="bg-warning" icon={false} widgetHeight={100} fontSize="h3"  value={formatMoney(invoices.counters.expired)} description="Em atraso"/>
                  </Grid>
                </>}

                {contract && contract.attributes && contract.relationships.service.attributes.type == 'recurrence' && <Grid item xs={12} className="text-right">
                  <a class="btn btn-light-info font-weight-bolder font-size-sm mt-2" onClick={() => dispatchRenewContractInvoice()}>
                    Gerar próxima fatura

                    {(contracts.actionsLoading) && <span className="pr-3 ml-4 spinner spinner-dark-75"></span>}
                  </a>
                </Grid>}

                {(typeof contracts.error) == "string" && <Grid item xs={12}>
                  <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                    <div className="d-flex flex-column flex-grow-1 mr-2">
                      <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                        {contracts.error}
                      </span>
                    </div>
                  </div>
                </Grid>}

                <Grid item xs={12}>
                  <ListGroup>
                    {invoices.entities.map((invoice, index) => <>
                      {(index < 3 || seeAll) && <ListGroup.Item>
                        <Grid container>
                          <Grid item xs={12} fontSize="large">
                            <div className="row">
                              {contract && contract.attributes && contract.relationships.service.attributes.type == 'recurrence' && <div className="col-lg-4 pt-2">
                                <span>
                                  <span className="font-weight-bold">Vigência</span><br/>
                                  {moment(invoice.attributes.start_at).format('DD/MM/YYYY')}<br />{moment(invoice.attributes.finish_at).format('DD/MM/YYYY')}
                                </span>
                              </div>}

                              <div className="col-lg-4 pt-2">
                                <span className="font-weight-bold">Vencimento</span><br/>
                                {moment(invoice.attributes.expired_at).format('DD/MM/YYYY')}
                              </div>

                              <div className="col-lg-3 pt-2">
                                <span className="font-weight-bold">Preço</span><br/>
                                {(invoice.attributes.payment_type == 'online' && invoice.attributes.payment_method == 'credit') ?
                                  `1 Crédito` :
                                  formatMoney(invoice.attributes.full_price)
                                }
                              </div>

                              <div className="col-lg-4 pt-2">
                                <span className="font-weight-bold">Pagamento</span><br/>
                                {getPaymentTypeLabel(invoice.attributes.payment_type)}
                              </div>

                              <div className="col-lg-4 pt-2">
                                <span className="font-weight-bold">Método</span><br/>
                                {getPaymentMethodLabel(invoice.attributes.payment_type, invoice.attributes.payment_method)}
                              </div>

                              <div className="col-lg-4 pt-2">
                                <span className="font-weight-bold">Status</span><br/>
                                <span className={`label label-md label-light-${getColorInvoiceStatus(invoice)} label-inline`}>
                                  {getLabelInvoiceStatus(invoice)}
                                </span>
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={12} className="pt-3 text-left">
                            <a className="btn btn-light-primary btn-sm" onClick={() => openShowInvoice(invoice)}>
                              <span className="svg-icon svg-icon-md svg-icon-default">
                                <i className="flaticon-edit-1"></i>
                              </span>
                              {(invoice.attributes.is_pending || invoice.attributes.is_expired) ? `Pagar fatura` : `Ver fatura`}
                            </a>

                            {
                              invoice.relationships.status.attributes.name != 'canceled' &&
                              invoice.attributes.is_current &&
                              (contract && contract.attributes && contract.relationships.service.attributes.type == 'recurrence') && <Chip
                              size="small"
                              label="Fatura atual"
                              variant="outlined"
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                                color: "#59ba32",
                                border: "1px solid #59ba32"
                              }}
                            />}

                            {
                              invoice.relationships.status.attributes.name == 'pending' &&
                              inRange(moment(invoice.attributes.expired_at).diff(moment(), 'days'), 0, 3) &&
                              (contract && contract.attributes && contract.relationships.service.attributes.type == 'recurrence') && <Chip
                              size="small"
                              label="Vence em breve"
                              variant="outlined"
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                                color: "#d4aa02",
                                border: "1px solid #d4aa02"
                              }}
                            />}
                          </Grid>
                        </Grid>
                      </ListGroup.Item>}
                    </>)}
                  </ListGroup>

                  {invoices.entities && invoices.entities.length > 3 && !seeAll && <a className="w-100 btn btn-light mt-3" onClick={() => setSeeAll(true)}>
                    Ver todas faturas
                  </a>}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </div>
    </Modal>

    <ShowInvoice show={showInvoice} onHide={() => closeShowInvoice()} invoice={selectedInvoice} />

    <CancelContract
      contract={cancelContract}
      show={showCancelContract}
      weekday={
        contract &&
        contract.relationships &&
        contract.relationships.schedule_spot &&
        contract.relationships.schedule_spot[0] &&
        contract.relationships.schedule_spot[0].relationships &&
        contract.relationships.schedule_spot[0].relationships.schedule &&
        contract.relationships.schedule_spot[0].relationships.schedule.attributes &&
        contract.relationships.schedule_spot[0].relationships.schedule.attributes.recurrence_days}
      date={contract && contract.attributes && contract.attributes.start_at}
      onHide={() => closeCancelContract()}
    />

    <CancelScheduleSpot
      schedule_spot={selectedScheduleSpot}
      show={showCancelScheduleSpot}
      onHide={() => closeCancelScheduleSpot()}
    />
  </>);
};

export default InvoicePage;