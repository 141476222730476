import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import { format } from 'date-fns';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import ControlPoint from '@material-ui/icons/ControlPoint';
import CloseIcon from '@material-ui/icons/Close';

import { RenderError } from "../../../components/RenderError";
import { notifySuccess } from "../../../utils/Notify";
import DayPicker from '../../../components/DayPicker';
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CreateClient from "../../Client/components/CreateClient";
import EditPlan from "../../Plan/components/EditPlan";

import { makeStyles } from '@material-ui/core/styles';

import { fetchDayUses, fetchCreateDayUse } from "../_redux/dayUseActions";
import { fetchClientName } from "../../Client/_redux/clientActions";
import { fetchPlans } from "../../Plan/_redux/planActions";
import {
  fetchAvailableCredits
} from "../../Credit/_redux/creditActions";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
  lineThrough: {
    textDecoration: 'line-through',
    fontSize: '10px'
  },
  credit: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  }
}));

const CreateDayUse = (props) => {
  const dispatch = useDispatch();
  const { dayUses, clients, plans, branchSelected } = useSelector((state) => ({
    dayUses: state.dayUses,
    clients: state.clients,
    plans: state.plans,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();
  const nameRef = useRef(null);

  const [rows, setRows] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [showClient, setShowClient] = useState(false);
  const [startDateAt, setStartDateAt] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [useCredit, setUseCredit] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState({});
  const [availableCredits, setAvailableCredits] = useState([]);

  useEffect(() => {
    if (dayUses.created == true) {
      notifySuccess('Cadastrado com sucesso', 'dayUses-created', 2000);
    }
  }, [dayUses]);

  useEffect(() => {
    if (plans.entities) {
      setRows(plans.entities.filter(plan => plan.attributes.type == 'day_use'));
    }
  }, [plans]);

  useEffect(() => {
    if (props.show) {
      nameRef.current.focus();

      setStartDateAt(new Date);
    }

    if (!props.show) {
      close();
    }
  }, [props.show]);

  useEffect(() => {
    if (startDateAt) {
      setSelectedPlan({});
      dispatch(fetchPlans('active', 'day_use', moment(startDateAt).format('YYYY-MM-DD'), branchSelected.id));
      getAvailableCredits();
    }
  }, [startDateAt]);

  useEffect(() => {
    if (selectedClient.id) {
      getAvailableCredits();
    }
  }, [selectedClient]);

  const getAvailableCredits = () => {
    if (branchSelected.id && selectedClient.id && startDateAt) {
      dispatch(fetchAvailableCredits({
        branchId: branchSelected.id,
        clientId: selectedClient.id,
        date: moment(startDateAt).format('YYYY-MM-DD'),
        originType: 'day_use',
      })).then((data) => {
        setAvailableCredits(data);
        changeUseCredit(data.length > 0);
      });
    }
  }

  const paymentTypes = [
    { label: 'Pagamento na unidade', value: 'locally' },
    { label: 'Pagamento Online', value: 'online' },
  ];

  const paymentMethods = [
    { label: 'Não informado', value: 'unknown' },
    { label: 'Crédito', value: 'credit' },
    { label: 'Débito', value: 'debit' },
    { label: 'Pix', value: 'pix' },
    { label: 'Dinheiro', value: 'cash' },
  ];

  const payment = [
    { label: 'Pago', value: 'paid' },
    { label: 'Pendente', value: 'pending' },
  ];

  const close = () => {
    props.onHide();
    setShowClient(false);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('status_id', 'paid', false);

    setSelectedClient({});
    setSelectedPlan({});
    setStartDateAt(null);
  }

  const selectClient = (client) => {
    setSelectedClient(client);
  }

  const changeUseCredit = (value) => {
    setUseCredit(value);
    clearSelected();
  }

  const selectCredit = (value) => {
    setSelectedCredit(value);
    setSelectedPlan(value.relationships.origin);
  }

  const clearSelected = () => {
    setSelectedPlan({});
    setSelectedCredit({});
  }

  const getPlanPrice = () => {
    let value = 0.00;

    if (selectedClient.attributes.is_member && selectedPlan.attributes.member_price) {
      value = selectedPlan.attributes.member_price;
    }

    if (selectedClient.attributes.is_member && !selectedPlan.attributes.member_price) {
      value = selectedPlan.attributes.price;
    }

    if (!selectedClient.attributes.is_member) {
      value = selectedPlan.attributes.price;
    }

    return value != 0.00 ?
      `R$ ${parseFloat(value).toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : `Grátis`
  }

  const searchClientName = () => {
    if (formik.values.name && formik.values.name.length >= 2) {
      const clientNameRequestTimeout = setTimeout(() => {
        dispatch(fetchClientName(formik.values.name, branchSelected.id));
        setShowClient(true);
      }, 400);

      return () => {
        clearTimeout(clientNameRequestTimeout);
      };
    }
  }

  const planSelected = (value) => {
    if (selectedPlan.id && value == selectedPlan.id) {
      return true
    }

    return false;
  }

  const openEditEntity = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }

  const formik = useFormik({
    initialValues: {
      status_id: 'paid',
      // paymentType: 'locally',
      paymentMethod: 'unknown',
    },
    // validationSchema: createClientdayUseschema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        let params = {
          plan_id: selectedPlan.id,
          client_id: selectedClient.id,
          day: moment(startDateAt).format('YYYY-MM-DD'),
          branch_id: branchSelected.id,
        };

        if (useCredit) {
          params.credit_id = selectedCredit.id;
          params.paymentType = 'online';
          params.paymentMethod = 'credit';
        }

        if (!useCredit) {
          params.status_id = values.status_id;
          params.paymentType = 'locally';
          params.paymentMethod = values.paymentMethod;
        }

        dispatch(fetchCreateDayUse(params));
      }, 200);
    },
  });

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);


  const headRows = [
    { id: 'check', numeric: false, disablePadding: false, label: '' },
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Preço' },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  const showCard = (entities, title = '') => {
    return <>

        <div className={classes.tableWrapper}>
          <Table
            key="tableWrapper"
                className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                aria-labelledby="tableTitle"
                size="large"
          >
            <TableHead>
              <TableRow>
                {headRows.map(row => (
                  <TableCell
                    key={row.id}
                    align="left"
                    padding={row.disablePadding ? 'none' : 'default'}
                  >
                    <span style={{
                      color: "#B5B5C3",
                      textTransform: "uppercase"
                    }}>
                    {row.label}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {entities.map((row, index) => {
                {/*const isItemSelected = isSelected(row.name);*/}
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
                        <input
                          type="checkbox"
                          key={row.id}
                          name={row.id}
                          checked={planSelected(row.id)}
                          onChange={() => setSelectedPlan(row)}
                        />
                        <span></span>
                      </label>
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.attributes.name}
                    </TableCell>

                    <TableCell align="left" className="flex">
                      <span className={selectedClient.attributes.is_member && row.attributes.member_price && classes.lineThrough}>
                        R$ {parseFloat(row.attributes.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                      </span>
                      {selectedClient.attributes.is_member && row.attributes.member_price && <span className="ml-2">
                        R$ {parseFloat(row.attributes.member_price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                      </span>}
                    </TableCell>

                    <TableCell align="right">
                      <a
                        title="Ver plano"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => openEditEntity(row)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            title="Ver plano"
                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                          />
                        </span>
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}

              {entities && entities.length == 0 && <>
                <TableRow key="dayuse-plan-empty-row">
                  <TableCell component="th" id="dayuse-plan-empty-cel" scope="row" padding="none" />
                  <TableCell component="th" id="dayuse-plan-empty-cel" scope="row" padding="none">
                    Nenhum plano disponivel para a data selecionada
                  </TableCell>
                </TableRow>
              </>}
            </TableBody>
          </Table>
        </div>
    </>
  }

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !createEntityShow && !editEntityShow}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cadastrar day use
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          {!selectedClient.id && <Container maxWidth="sm">
            <Grid container spacing={2}>
              <Grid item xs={12} className="text-right">
                <a href="#" className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                  <ControlPoint fontSize="inherit" /> Cadastrar cliente
                </a>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Pesquisar por nome"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {clients.actionsLoading && <CircularProgress size={30} />}
                      </InputAdornment>
                    ),
                  }}
                  {...formik.getFieldProps("name")}
                  inputRef={nameRef}
                />

                {formik.touched.name && formik.errors.clientId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.clientId}</div>
                  </div>
                ) : null}
              </Grid>

              {showClient && <Grid item xs={12}>
                {clients.clientName.data && clients.clientName.data.map((client) =>
                  <div className={classes.detailedExpansionPanel}>
                    <DetailedExpansionPanel client={client} onSelect={selectClient} />
                  </div>
                )}
                {clients.clientName.data && !clients.clientName.data.length && <span>Nenhum cliente encontrado</span>}
              </Grid>}
            </Grid>

            {(typeof clients.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {clients.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>}

          {selectedClient.id && <>
            <Container maxWidth="sm">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">
                    Dados do jogador
                  </span>
                </Grid>

                <Grid item xs={12}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-block">Nome: {selectedClient.attributes.name}</div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-block">E-mail: {selectedClient.attributes.email}</div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-block">Telefone: {selectedClient.attributes.phone}</div>
                  </div>
                </Grid>
              </Grid>
            </Container>

            <Container maxWidth="sm">
              <Grid container className={classes.label} spacing={2}>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">Data do day use</span>
                </Grid>
                <Grid item xs={6}>
                  <DayPicker date={startDateAt} onDateChange={setStartDateAt} beforeToday={true} />

                  {dayUses.error && <RenderError error={dayUses.error.day} />}
                </Grid>
              </Grid>
            </Container>

            {availableCredits && availableCredits.length > 0 && <div className={classes.credit}>
              <div className="row">
                <div className="col-lg-6">
                  <label className="option option-plain">
                    <span className="option-control">
                      <span className="radio">
                        <input type="radio" checked={useCredit} onClick={() => changeUseCredit(true)} />
                        <span></span>
                      </span>
                    </span>
                    <span className="option-label">
                      <span className="option-head">
                        <span className="option-title">Créditos disponiveis</span>
                      </span>
                      <span className="option-body">Usar crédito do cliente</span>
                    </span>
                  </label>
                </div>
                <div className="col-lg-6">
                  <label className="option option option-plain">
                    <span className="option-control">
                      <span className="radio">
                        <input type="radio" checked={!useCredit} onClick={() => changeUseCredit(false)} />
                        <span></span>
                      </span>
                    </span>
                    <span className="option-label">
                      <span className="option-head">
                        <span className="option-title">Nova venda</span>
                      </span>
                      <span className="option-body">Efetuar uma nova venda</span>
                    </span>
                  </label>
                </div>
              </div>
              <div className="separator separator-dashed my-3"></div>
            </div>}

            {!useCredit && <>
              <Container maxWidth="sm">
                <Grid container className={classes.label} spacing={2}>
                  <Grid item xs={12}>
                    <span className="font-weight-bolder text-dark">Pagamento</span>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Método de pagamento"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          }
                        }
                      }}
                      name="paymentMethod"
                      fullWidth
                      className={classes.textField}
                      {...formik.getFieldProps("paymentMethod")}
                    >
                      {paymentMethods.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </TextField>

                    {dayUses.error && <RenderError error={dayUses.error.payment_method} />}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Já foi pago?"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          }
                        }
                      }}
                      name="status_id"
                      fullWidth
                      className={classes.textField}
                      {...formik.getFieldProps("status_id")}
                    >
                      {payment.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </TextField>

                    {dayUses.error && <RenderError error={dayUses.error.status_id} />}
                  </Grid>
                </Grid>
              </Container>
            </>}

            {useCredit && <div className={classes.credit}>
              <div className="mb-1 font-weight-bold text-dark">Créditos</div>
              <div className="mt-3 font-weight-light font-size-sm mb-5">
                Selecione o serviço que jogador vai realizar<br />A fatura irá ser gerada como paga e o crédito será utilizado.
              </div>

              {availableCredits.map((item, index) => <>
                <div className="separator separator-dashed my-3"></div>
                <div className="d-flex flex-wrap mb-3 cursor-pointer" onClick={() => selectCredit(item)}>
                  <div className="radio-list">
                    <label className="radio radio-lg mb-7">
                      <input type="radio" name="serviceId" checked={selectedCredit.id == item.id} onClick={() => selectCredit(item)} />
                      <span></span>
                    </label>
                  </div>

                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <span className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">{item.relationships.origin.attributes.name}</span>
                    {item.attributes.expire_dates.length > 0 && <span className="font-weight-bold font-size-sm" style={{color: 'gray'}}>
                      {item.attributes.total > 1 ? `válidos` : `válido`} até
                      {item.attributes.expire_dates.length > 1 && <i className="ki ki-outline-info text-muted ml-2" />}
                      {item.attributes.expire_dates.length == 1 && ` ${item.attributes.expire_dates[0]}`}
                    </span>}
                    {item.attributes.expire_dates.length == 0 && <span className="font-weight-bold font-size-sm" style={{color: 'gray'}}>
                      Sem data limite para uso
                    </span>}
                  </div>
                  <span className="label label-lg label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder" style={{minWidth: 'fit-content'}}>
                    <span className="ml-2">{item.attributes.total} {item.attributes.total > 1 ? `créditos` : `crédito`}</span>
                  </span>
                </div>
              </>)}
            </div>}

            {!useCredit && <Container maxWidth="sm">
              <Grid container className={classes.label} spacing={2}>
                <Grid item xs={12}>
                  {showCard(rows)}

                  {dayUses.error && <RenderError error={dayUses.error.plan_id} />}
                </Grid>
              </Grid>
            </Container>}
          </>}

          {(typeof dayUses.error) == "string" && <Grid item xs={12}>
            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                  {dayUses.error}
                </span>
              </div>
            </div>
          </Grid>}
        </Modal.Body>

        {selectedClient.id && <Modal.Footer>
          <Grid container spacing={3}>
            {selectedPlan && selectedPlan.id && <>
              <Grid item xs={6}>
                <span className="font-size-h4 text-dark-50 font-weight-bolder mb-2 ml-10">
                  Subtotal
                </span>
              </Grid>
              <Grid item xs={6} className="text-right">
                <span className=" font-size-h4 text-dark-50 font-weight-bolder mb-2 mr-10 text-right">
                  {!useCredit && getPlanPrice()}
                  {useCredit && `1 crédito`}
                </span>
              </Grid>
            </>}

            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={(dayUses.actionsLoading || !selectedPlan.id)}>
                {useCredit ? `Check-in` : `Confirmar`} {(dayUses.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>}
      </form>
    </Modal>

    <CreateClient show={createEntityShow} onHide={() => setCreateEntityShow(false)} />
    <EditPlan readOnly={true} show={editEntityShow} onHide={() => setEditEntityShow(false)} event={editEntity} />
  </>);
};

export default CreateDayUse;