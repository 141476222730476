import axios from "axios";

export const GET_PRICES = `${process.env.REACT_APP_API_URL}/prices`;

// export const GET_PRICE_SERVICE = `${process.env.REACT_APP_API_URL}/prices/services`;
export const CREATE_PRICE = `${process.env.REACT_APP_API_URL}/services`;
export const UPDATE_PRICE = `${process.env.REACT_APP_API_URL}/prices`;

export function getPrices(data) {
  let params = {};

  if (data.serviceId) {
    params.service_id = data.serviceId
  }

  return axios.get(GET_PRICES, { params: params });
}

export function getPriceServiceByDate(fieldId, startAt, endAt, modality, type) {
  return axios.get(`${GET_PRICES}/${fieldId}/services`, {
    params: {
      start_at: startAt,
      end_at: endAt,
      modality: modality,
      type: type,
    }
  });
}

export function createPrice(serviceId, params) {
  return axios.post(`${CREATE_PRICE}/${serviceId}/prices`, params);
}

export function updatePrice(priceId, params) {
  return axios.put(`${UPDATE_PRICE}/${priceId}`, params);
}

export function removePrice(priceId) {
  return axios.delete(`${UPDATE_PRICE}/${priceId}`);
}