import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
  root: {
    background: 'transparent',
    flexGrow: 1,
  },
});

export default function DotsMobileStepper(props) {
  const classes = useStyles();
  const theme = useTheme();

  function handleNext() {
    if (props.step !== (props.totalSteps - 1)) {
      props.move(props.step + 1);
    }
  }

  function handleBack() {
    if (props.step > 0) {
      props.move(props.step - 1);
    }
  }

  return (
    <MobileStepper
      background="#FFF"
      variant="dots"
      steps={props.totalSteps}
      position="static"
      activeStep={props.step}
      className={classes.root}
      nextButton={<>
        {props.step === (props.totalSteps - 1)  && <Button variant="outlined" color="primary" size="large" type="submit" disabled={props.loading}>
          {props.submitLabel} {props.loading && <span className="ml-2 spinner spinner-success"></span>}
        </Button>}
        {props.step != (props.totalSteps - 1)  && <Button variant="outlined" color="primary" size="large" onClick={handleNext}>
          Próximo
        </Button>}
      </>}
      backButton={
        <Button size="large" onClick={handleBack} disabled={props.step === 0}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          Anterior
        </Button>
      }
    />
  );
}