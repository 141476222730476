import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SVG from "react-inlinesvg";
import clsx from "clsx";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Icon,
  Grid,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CreateService from "../components/CreateService";
import EditService from "../components/EditService";
import { fetchServices } from "../_redux/serviceActions";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "attributes.name",
    numeric: false,
    disablePadding: true,
    label: "Nome",
  },
  // {
  //   id: "attributes.type",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Tipo",
  // },
  {
    id: "attributes.minTime",
    numeric: true,
    disablePadding: false,
    label: "Duração",
  },
  { id: "fields", numeric: false, disablePadding: false, label: "Campos" },
  { id: "prices", numeric: false, disablePadding: false, label: "Preços" },
  { id: "actions", numeric: false, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align="left"
            // align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <span
                style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase",
                }}
              >
                {row.label}
              </span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const styleClasses = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(styleClasses.root, {
        [styleClasses.highlight]: numSelected > 0,
      })}
    >
      <div className={styleClasses.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle"></Typography>
        )}
      </div>
      <div className={styleClasses.spacer} />
      <div className={styleClasses.actions}>
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    // overflowX: "auto",
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 14,
  },
}));

export const ServicePage = () => {
  const styleClasses = useStyles();
  const [pagination, _] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("attributes.name");
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [currentModality, setCurrentModality] = useState("class");
  const [type, setType] = useState("single");
  const dispatch = useDispatch();
  const { services, branchSelected } = useSelector(
    (state) => ({
      branchSelected: state.auth.branchSelected,
      services: state.services,
    }),
    shallowEqual
  );

  useEffect(() => console.log({ services }), [services]);

  useEffect(() => console.log({ branchSelected }), [branchSelected]);

  useEffect(() => {
    requestEntities();
  }, [branchSelected]);

  function requestEntities() {
    dispatch(fetchServices(branchSelected.id));
  }

  function closeCreateEntity() {
    requestEntities();
    setCreateEntityShow(false);
  }

  function openEditEntity(event) {
    setEditEntity(event);
    setEditEntityShow(true);
  }

  function closeEditEntity() {
    requestEntities();
    setEditEntityShow(false);
  }

  const getFields = (fields) => {
    var names = fields.map((field) => {
      return field.attributes.name;
    });

    return (
      fields.length +
      (fields.length > 1 ? " campos relacinados" : " campo relacionado") +
      " com esse serviço.\n" +
      names.join(", ")
    );
  };

  const getMinMax = (arr) => {
    return arr.reduce(
      ({ min, max }, v) => ({
        min: min < v ? min : v,
        max: max > v ? max : v,
      }),
      { min: arr[0], max: arr[0] }
    );
  };

  const getGranularity = (granularity) => {
    return `A cada ${granularity} minutos`;
  };

  const getDuration = (start, end) => {
    if (start === end) {
      return `${start} min`;
    }

    return `${start} ~ ${end} min`;
  };

  const getPrices = (entitiesPrice) => {
    let prices = [];

    entitiesPrice.map((price) => {
      prices.push(price.attributes.price);
    });

    let minMax = getMinMax(prices);

    if (minMax.min === minMax.max) {
      return `R$ ${minMax.min} - ${entitiesPrice[0].attributes.time} min`;
    }

    return `R$ ${minMax.min} ~ R$ ${minMax.max} - ${entitiesPrice[0].attributes.time} min`; //prices.length + (prices.length > 1 ? ' preços relacinados' : ' preço relacionado') + ' com esse serviço';
  };

  const changeModalityType = (valueModality, valueType) => {
    setCurrentModality(valueModality);
    setType(valueType);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function TableCards({
    entity,
    type,
    currentModality,
    onOpenEditEntity,
    title,
    ...rest
  }) {
    const sortedEntities = stableSort(
      entity.entities,
      getSorting(order, orderBy)
    ).filter(
      (el) =>
        el.attributes.modality === currentModality &&
        el.attributes.type === type
    );

    return (
      <Grid item lg={12} xs={12} {...rest}>
        <div className="card card-custom">
          <div className="card-header">
            <div className="card-title">
              <span>{currentModality == 'class' ? 'Aula' : 'Locação'} {type === "single" ? "avulso" : "mensal"}</span>
            </div>
          </div>
          <div
            className={entity.listLoading ? "card-body filtering" : "card-body"}
          >
            <Table
              key="tableWrapper"
              className="table table-head-custom table-head-bg table-borderless table-vertical-center d-block d-md-table overflow-auto"
              aria-labelledby="tableTitle"
              size="large"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={entity.totalCount}
              />
              <TableBody>
                {entity.entities.length
                  ? sortedEntities.map((row, index) => (
                      <TableRow tabIndex={-1} key={row.id}>
                        <TableCell
                          component="th"
                          id={`enhanced-table-checkbox-${index}`}
                          scope="row"
                          padding="none"
                        >
                          {row.attributes.name}
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip
                            title={
                              <h5 className="font-weight-lighter text-white">
                                {getGranularity(row.attributes.granularity)}
                              </h5>
                            }
                          >
                            <Icon className={styleClasses.icon}>
                              access_time
                            </Icon>
                          </Tooltip>
                          {getDuration(
                            row.attributes.minTime,
                            row.attributes.maxTime
                          )}
                        </TableCell>

                        {row.relationships.fields.length > 0 && (
                          <TableCell align="left">
                            {row.relationships.fields.length} assoc.
                            <Tooltip
                              title={
                                <h5 className="font-weight-lighter text-white">
                                  {getFields(row.relationships.fields)}
                                </h5>
                              }
                            >
                              <Icon className={styleClasses.icon}>
                                info_outlined
                              </Icon>
                            </Tooltip>
                          </TableCell>
                        )}

                        {!row.relationships.fields.length && (
                          <TableCell align="left">
                            Nenhum campo
                            <Tooltip
                              title={
                                <h5 className="font-weight-lighter text-white">
                                  Nenhum campo está associado a esse serviço,
                                  associe um campo parar utilizar esse serviço
                                </h5>
                              }
                            >
                              <Icon
                                className={styleClasses.icon}
                                style={{ color: "red" }}
                              >
                                info_outlined
                              </Icon>
                            </Tooltip>
                          </TableCell>
                        )}

                        {row.relationships.prices.length > 0 && (
                          <TableCell align="left">
                            {getPrices(row.relationships.prices)}
                          </TableCell>
                        )}

                        {!row.relationships.prices.length && (
                          <TableCell align="left">
                            R$ 0,00
                            <Tooltip
                              title={
                                <h5 className="font-weight-lighter text-white">
                                  Nenhum preço está associado a esse serviço,
                                  associe um preço parar visualizar o calculo do
                                  agendamento desse serviço
                                </h5>
                              }
                            >
                              <Icon
                                className={styleClasses.icon}
                                style={{ color: "red" }}
                              >
                                info_outlined
                              </Icon>
                            </Tooltip>
                          </TableCell>
                        )}

                        <TableCell align="right">
                          <a
                            title="Edit customer"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            onClick={() => onOpenEditEntity(row)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              />
                            </span>
                          </a>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>

            <div className="d-flex align-items-center py-3">
              {entity.listLoading && (
                <div className="d-flex align-items-center">
                  <div className="mr-2 text-muted">Carregando...</div>
                  <div className="spinner spinner-primary mr-10"></div>
                </div>
              )}

              {
                <span style={{ color: "#B5B5C3", textTransform: "uppercase" }}>
                  {sortedEntities.length} registro(s)
                </span>
              }
            </div>
          </div>
        </div>
      </Grid>
    );
  }

  return (<>
      <div className="d-flex row">
        <div className="col-md-3 pb-3" id="kt_todo_aside">
          <div className="card card-custom card-stretch">
            <div className="card-body px-5">
              <div className="navi navi-hover navi-active navi-link-rounded navi-bold navi-icon-center navi-light-icon navi-accent" role="tablist">
                <div className="navi-section mb-2 font-size-h5 font-weight-bold pb-0">Modalidade</div>
                <div className="navi-section mt-7 mb-2 font-size-h6 font-weight-bold pb-0">Aulas</div>
                <div className="navi-item my-2">
                  <a onClick={() => changeModalityType('class', 'single')} className={`navi-link ${currentModality == 'class' && type === "single" && 'active'}`}>
                    <span className="navi-icon mr-4">
                      <i className="fa fa-circle" style={{color: '#17c191'}}></i>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Aula avulso</span>
                  </a>
                </div>
                <div className="navi-item my-2">
                  <a onClick={() => changeModalityType('class', 'recurrence')} className={`navi-link ${currentModality == 'class' && type === "recurrence" && 'active'}`}>
                    <span className="navi-icon mr-4">
                      <i className="fa fa-circle" style={{color: '#a6bf54'}}></i>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Aula mensal</span>
                  </a>
                </div>

                <div className="navi-section mt-7 mb-2 font-size-h6 font-weight-bold pb-0">Locações</div>

                <div className="navi-item my-2">
                  <a onClick={() => changeModalityType('reservation', 'single')} className={`navi-link ${currentModality == 'reservation' && type === "single" && 'active'}`}>
                    <span className="navi-icon mr-4">
                      <i className="fa fa-circle" style={{color: '#1772c1'}}></i>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Locação Avulso</span>
                  </a>
                </div>
                <div className="navi-item my-2">
                  <a onClick={() => changeModalityType('reservation', 'recurrence')} className={`navi-link ${currentModality == 'reservation' && type === "recurrence" && 'active'}`}>
                    <span className="navi-icon mr-4">
                      <i className="fa fa-circle" style={{color: '#54a6bf'}}></i>
                    </span>
                    <span className="navi-text font-weight-bolder font-size-lg">Locação mensal</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="d-flex flex-column flex-grow-1">

            <div className="row">
              <div className="col-lg-12">
                <div className="card card-custom">
                  <div className="card-header">
                    <div className="card-title">
                      <span>Serviços</span>
                    </div>
                    <div className="card-toolbar">
                      <a
                        href="#"
                        className="btn btn-light-primary font-weight-bold"
                        onClick={() => setCreateEntityShow(true)}
                      >
                        <i className="ki ki-plus "></i> Cadastrar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 py-3">
                {/*{["single", "recurrence"].map((el) => (
                  <TableCards
                    entity={services}
                    type={el}
                    currentModality={currentModality}
                    onOpenEditEntity={openEditEntity}
                  />
                ))}*/}
                <TableCards
                  entity={services}
                  type={type}
                  currentModality={currentModality}
                  onOpenEditEntity={openEditEntity}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


    <CreateService show={createEntityShow} onHide={closeCreateEntity} modality={currentModality} type={type} />

    <EditService
      show={editEntityShow}
      onHide={closeEditEntity}
      event={editEntity}
    />
  </>);
};
