import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import ListGroup from 'react-bootstrap/ListGroup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Chip from '@material-ui/core/Chip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import {
  fetchUpdateDayUse,
  fetchRenewDayUse,
  fetchDayUse,
  clearDayUse,
} from "../_redux/dayUseActions";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import EditPlan from "../../Plan/components/EditPlan";
import ShowInvoice from "../../Invoice/components/ShowInvoice";
import CancelDayUse from "./CancelDayUse";
import EditClient from "../../Client/components/EditClient";

import { notifySuccess, notifyError } from "../../../utils/Notify";

import { formatMoney } from "../../../utils/IntegerParse";
import { useInterval } from "../../../utils/Interval";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditDayUse = (props) => {
  const dispatch = useDispatch();
  const { dayUses, branches, invoices } = useSelector((state) => ({
    dayUses: state.dayUses,
    branches: state.branches,
    invoices: state.invoices,
  }), shallowEqual);

  const classes = useStyles();

  const [editClientShow, setEditClientShow] = useState(false);
  const [editClient, setEditClient] = useState({});
  const [showInvoice, setShowInvoice] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showPlan, setShowPlan] = useState(false);
  const [showCancelDayUse, setShowCancelDayUse] = useState(false);
  const [event, setEvent] = useState({});
  const [cancelDateAt, setCancelDateAt] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  const paymentTypes = [
    { label: 'Pagamento na unidade', value: 'locally' },
    { label: 'Pagamento Online', value: 'online' },
  ];

  const paymentMethods = [
    { label: 'Não informado', value: 'unknown' },
    { label: 'Crédito', value: 'credit' },
    { label: 'Débito', value: 'debit' },
    { label: 'Pix', value: 'pix' },
    { label: 'Dinheiro', value: 'cash' },
  ];

  useEffect(() => {
    if (dayUses.updated == true) {
      close();
      setShowCancelDayUse(false);
      notifyError('Cancelado com sucesso', 'dayUses-updated');
    }
  }, [dayUses]);

  useEffect(() => {
    if (dayUses.renewed) {
      dispatch(fetchDayUse(event.id));
    }
  }, [dayUses.renewed]);

  useEffect(() => {
    if ((invoices.updated || invoices.priceUpdated) && !invoices.actionsLoading) {
      if (event.id) {
        closeShowInvoice();
        dispatch(fetchDayUse(event.id));
      }
    }
  }, [invoices]);

  useEffect(() => {
    if (dayUses.dayUse && dayUses.dayUse.id) {
      setEvent(dayUses.dayUse);

      formik.setFieldValue('dayUseId', dayUses.dayUse.id, false);
      formik.setFieldValue('auto_renew', dayUses.dayUse.attributes.auto_renew == true ? 'yes' : 'no', false);
    }
  }, [dayUses.dayUse]);

  useEffect(() => {
    if (props.event && props.event.id) {
      setEvent(props.event);
      setCancelDateAt(new Date);

      formik.setFieldValue('dayUseId', props.event.id, false);
      formik.setFieldValue('auto_renew', props.event.attributes.auto_renew == true ? 'yes' : 'no', false);
    }
  }, [props.show]);

  useInterval(() => {
    verifyPayment();
  }, 5000);

  const verifyPayment = async () => {
    if (props.show && !showInvoice && event && event.relationships && event.relationships.invoices.find(invoice => invoice.attributes.payment_type == 'online')) {
      dispatch(fetchDayUse(event.id));
    }
  }

  const close = () => {
    props.onHide();

    formik.setFieldValue('dayUseId', '', false);
    formik.setFieldValue('auto_renew', 'no', false);
    dispatch(clearDayUse());
    setEvent({});
  }

  const closeShowInvoice = () => {
    setShowInvoice(false);
    setSelectedInvoice({});
  }

  const openShowInvoice = (invoice) => {
    setShowInvoice(true);
    setSelectedInvoice(invoice);
  }
  const openEditClient = (event) => {
    setEditClient(event);

    setEditClientShow(true);
  }

  const closeEditClient = () => {
    setEditClientShow(false);
  }

  const getColorStatus = (invoice) => {
    if (invoice.attributes.is_canceled || invoice.attributes.is_expired) {
      return 'danger';
    }

    if (invoice.attributes.is_paid) {
      return 'success';
    }

    return 'primary';
  }

  const getStatusLabel = (invoice) => {
    let label = 'A vencer';

    if (invoice.attributes.is_canceled) {
      label = 'Cancelado';
    }

    if (invoice.attributes.is_paid) {
      label = 'Pago';
    }

    if (invoice.attributes.is_expired) {
      label = 'Vencido';
    }

    return label;
  }

  const getPaymentTypeLabel = (value) => {
    let type = paymentTypes.find(type => type.value == value)

    return type ? type.label : value;
  }

  const getPaymentMethodLabel = (value) => {
    let type = paymentMethods.find(type => type.value == value)

    return type ? type.label : value;
  }

  const copyPixLink = (link) => {
    navigator.clipboard.writeText(link);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  const formik = useFormik({
    initialValues: {
      dayUseId: '',
      auto_renew: 'no',
    },
    // validationSchema: createDayUseSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        let params = {
          auto_renew: values.auto_renew == 'yes' ? true : false,
        };

        dispatch(fetchUpdateDayUse(values.dayUseId, params));

        setSubmitting(false);
      }, 500);
    },
  });

  const renew = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const headRows = [
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Valor' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  const showCard = (entities, title = '') => {
    return <Container minWidth="sm">
      {entities.map((invoice, index) => <>
        <div class="separator separator-dashed my-1"></div>
        <div className="row">
          <Grid container spacing={2}>
            <div className="row w-100">
              <div className="col">
                <div className="row">
                  <label className="col-12  col-form-label font-weight-bold text-left">
                    Status
                  </label>
                  <div className=" col-12">
                    <span className={`label label-md label-light-${getColorStatus(invoice)} label-inline`}>
                      {getStatusLabel(invoice)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <label className="col-12  col-form-label font-weight-bold text-left">
                    Valor total
                  </label>
                  <div className=" col-12">
                    <label>
                      <span></span>{invoice.attributes && formatMoney(invoice.attributes.full_price)}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col mw-300">
                <div className="row">
                  <label className="col-12 col-form-label font-weight-bold text-right">
                    <a
                      title="Ver fatura"
                      className="btn btn-light btn-hover-primary btn-sm mx-3"
                      onClick={() => openShowInvoice(invoice)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          title="Ver fatura"
                          src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                        /> Abrir
                      </span>
                    </a>
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <label className="col-12  col-form-label font-weight-bold text-left">
                Forma de pagamento
              </label>
              <div className=" col-12">
                <label>
                  <span></span>
                  {invoice.attributes && getPaymentTypeLabel(invoice.attributes.payment_type)}
                  {" - "}
                  {invoice.attributes && getPaymentMethodLabel(invoice.attributes.payment_method)}
                </label>
              </div>
            </div>

            {invoice.attributes && invoice.attributes.paid_at && <div className="row">
              <label className="col-12  col-form-label font-weight-bold text-left">
                Data pagamento
              </label>
              <div className=" col-12">
                <label>
                  <span></span>{moment(invoice.attributes.paid_at).format('DD/MM/YYYY HH:MM')}
                </label>
              </div>
            </div>}

            {invoice.attributes && invoice.attributes.canceled_at && <div className="row">
              <label className="col-12  col-form-label font-weight-bold text-left">
                Data cancelamento
              </label>
              <div className=" col-12">
                <label>
                  <span></span>{moment(invoice.attributes.canceled_at).format('DD/MM/YYYY')}
                </label>
              </div>
            </div>}


            {invoice.relationships && invoice.relationships.payments && invoice.relationships.payments.length > 0 && <>
              {invoice.relationships.payments.map(payment => <>
                {!payment.attributes.canceled_at && !payment.attributes.paid_at && <>
                  <div class="separator separator-dashed my-1"></div>

                  <div class="row w-100 justify-content-center">
                    {payment.attributes && payment.attributes.pix_qrcode && <div className="text-center">
                      <img class="w-100" src={payment.attributes.pix_qrcode} />
                    </div>}
                  </div>

                  <div class="row w-100">
                    <label>Copia e cola</label>
                    <div class="input-group">
                      <input type="text" class="form-control" value={payment.attributes.pix_link} />
                      <div class="input-group-append">
                        <button class="btn btn-primary" type="button" onClick={() => copyPixLink(payment.attributes.pix_link)}>{copied ? 'Copiado!' : 'Copiar'}</button>
                      </div>
                    </div>
                  </div>
                </>}
              </>)}
            </>}
          </Grid>
        </div>
      </>)}

      {/*<div className={classes.tableWrapper}>
        <Table
          key="tableWrapper"
              className="table table-head-custom table-head-bg table-borderless table-vertical-center"
              aria-labelledby="tableTitle"
              size="large"
        >
          <TableHead>
            <TableRow>
              {headRows.map(row => (
                <TableCell
                  key={row.id}
                  align="left"
                  padding={row.disablePadding ? 'none' : 'default'}
                >
                  <span style={{
                    color: "#B5B5C3",
                    textTransform: "uppercase"
                  }}>
                  {row.label}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {entities.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (<>
                <TableRow
                  tabIndex={-1}
                  key={row.id}
                >
                  <TableCell component="th" id={labelId} scope="row" padding="none">
                    {moment(row.attributes.expired_at).format('DD/MM/YYYY')}
                  </TableCell>

                  <TableCell align="left" className="flex">
                    R$ {row.attributes.price}
                  </TableCell>

                  <TableCell align="left" className="flex">
                    <span className={`label label-lg label-light-${getColorStatus(row)} label-inline`}>
                      {getStatusLabel(row)}
                    </span>
                  </TableCell>

                  <TableCell align="right">
                    <a
                      title="Ver fatura"
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      onClick={() => openShowInvoice(row)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          title="Ver fatura"
                          src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                        />
                      </span>
                    </a>
                  </TableCell>
                </TableRow>

                {row.relationships && row.relationships.payments && row.relationships.payments.length > 0 && <>
                  {row.relationships.payments.map(payment => <>
                    {!payment.attributes.canceled_at && !payment.attributes.paid_at && <>
                      <div class="separator separator-dashed my-1"></div>
                      <TableRow key={row.id + '-payment-qr'}>
                        {payment.attributes && payment.attributes.pix_qrcode && <div className="text-center">
                          <img class="w-100" src={payment.attributes.pix_qrcode} />
                        </div>}
                      </TableRow>
                      <TableRow key={row.id + '-payment-copy'}>

                          <label>Copia e cola</label>
                          <div class="input-group">
                            <input type="text" class="form-control" value={payment.attributes.pix_link} />
                            <div class="input-group-append">
                              <button class="btn btn-primary" type="button" onClick={() => copyPixLink(payment.attributes.pix_link)}>{copied ? 'Copiado!' : 'Copiar'}</button>
                            </div>
                          </div>
                      </TableRow>
                    </>}
                  </>)}
                </>}


              </>);
            })}
          </TableBody>
        </Table>
      </div>*/}
    </Container>
  }

  return (<>
    {event.id && <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !showInvoice && !showCancelDayUse && !showPlan && !editClientShow}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Visualizar day use
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="sm">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Dados do assinante
                </span>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">Nome: {event.relationships.client.attributes.name}</div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">E-mail: {event.relationships.client.attributes.email}</div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">Telefone: {event.relationships.client.attributes.phone}</div>
                </div>
              </Grid>

              {!props.hideClient && <Grid item xs={12}>
                <a class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-2" onClick={() => openEditClient(event.relationships.client)}>
                  Ver cliente
                </a>
              </Grid>}
            </Grid>
          </Container>

          <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    Data day use:
                    <span className="font-weight-bolder text-dark">
                      {' '}{moment(event.attributes.day).format('DD/MM/YYYY')}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    Plano: <span className="font-weight-bolder text-dark">{event.relationships.plan.attributes.name}</span>
                    <a
                      title="Ver plano"
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      onClick={() => setShowPlan(true)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          title="Ver plano"
                          src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Faturas</span>

                {showCard(event.relationships.invoices)}
              </Grid>

              {(typeof dayUses.error) == "string" && <Grid item xs={12}>
                <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                      {dayUses.error}
                    </span>
                  </div>
                </div>
              </Grid>}
            </Grid>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              {dayUses.listLoading && <div className="spinner spinner-primary p-5" />}
            </Grid>
            <Grid item xs={8} style={{textAlign: 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
            <Grid item xs={2} style={{textAlign: 'right'}}>
              {!props.readOnly && <Tooltip title="Cancelar assinatura" placement="top">
                <IconButton variant="outlined" aria-label="delete" onClick={() => setShowCancelDayUse(true)} disableFocusRipple={true}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>}

    <ShowInvoice show={showInvoice} onHide={() => closeShowInvoice()} invoice={selectedInvoice} />
    <CancelDayUse show={showCancelDayUse} onHide={() => setShowCancelDayUse(false)} event={event} />
    <EditPlan readOnly={true} show={showPlan} onHide={() => setShowPlan(false)} event={event.relationships && event.relationships.plan} />
    <EditClient show={editClientShow} onHide={closeEditClient} event={editClient} />
  </>);
};

export default EditDayUse;