import React, { useState, useEffect } from "react";

import { lighten, makeStyles } from '@material-ui/core/styles';

import { CreditList } from '../components/CreditList';
// import { DayUseCardList } from '../../DayUse/components/DayUseCardList';

const useStyles = makeStyles(theme => ({
  //
}));

export const CreditPage = () => {
  const classes = useStyles();
  return (<>
      <CreditList />
  </>);
}
