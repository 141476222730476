import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import ReactMarkdown from 'react-markdown';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup'

import CloseIcon from '@material-ui/icons/Close';

import Snackbar from "../../../components/Snackbar";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import DayPicker from '../../../components/DayPicker';
import { RenderError } from '../../../components/RenderError';

import { makeStyles } from '@material-ui/core/styles';

import {
  fetchLogsEvents,
} from "../../Log/_redux/logActions";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const ShowLogs = (props) => {
  const dispatch = useDispatch();
  const { logs } = useSelector((state) => ({
    logs: state.logs,
  }), shallowEqual);

  const classes = useStyles();
  const [logsEvents, setLogsEvents] = useState([]);

  const getLogBadge = (value) => {
    if (value == 'create') { return 'bg-success' };
    if (value == 'delete') { return 'bg-danger' };
    if (value == 'cancel') { return 'bg-danger' };
    if (value == 'update') { return 'bg-primary' };

    return 'bg-primary';
  }

  useEffect(() => {
    if (props.show) {
      dispatch(fetchLogsEvents(props.data)).then((data) => {
        setLogsEvents(data);
      });
    }
  }, [props.show]);

  return (<>
    <div class="row">
      <div class="col-lg-12">
        <div class="timeline timeline-justified timeline-4 mb-10 mt-5">
          <div class="timeline-bar"></div>
          <div class="timeline-items">
            {logsEvents.map(log => <>
              {log.attributes && <div class="timeline-item">
                <div class="timeline-badge">
                  <div class={getLogBadge(log.attributes.event)}></div>
                </div>

                <div class="timeline-label">
                  <span class="font-weight-bold">{log.attributes.user_name}</span>
                  <span class="text-primary font-weight-bold"> - {moment(log.attributes.created_at).format('DD/MM/YYYY - HH:mm')}</span>
                  <span class="text-dark-75"> - {moment(log.attributes.created_at).locale('pt-br').fromNow()}</span>
                </div>

                <div class="timeline-content" style={{whiteSpace: "pre-line"}}>
                  <ReactMarkdown children={log.attributes.message} />
                </div>
              </div>}
            </>)}

            {logsEvents.length == 0 && <div class="timeline-item">
              <div class="timeline-badge">
                  <div class="bg-danger"></div>
                </div>

                <div class="timeline-label">Indisponível</div>

                <div class="timeline-content">
                  Nenhum log disponível
                </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default ShowLogs;