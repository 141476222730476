import * as requestFromServer from "./invoiceCrud";
import { invoiceSlice, callTypes } from "./invoiceSlice";

const { actions } = invoiceSlice;

export const fetchInvoice = (params = {}) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getInvoices(params)
    .then(response => {
      const entities = response.data.data;
      const counters = {
        total: response.data.total,
        expired: response.data.total_expired,
        paid: response.data.total_paid,
        pending: response.data.total_pending,
        canceled: response.data.total_canceled,
      };
      const totalCount = entities.length;

      dispatch(actions.invoicesFetched({ totalCount, entities, counters }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.invoicesFetched({ totalCount: 0, entities: [] }));
    });
};

export const closeInvoice = () => dispatch => {
  dispatch(actions.invoiceClosed())
}

// export const fetchCreateInvoice = (params) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));

//   if (params.type == 'recurrence') {
//     params.recurrencePeriod = 'weekly';
//   }

//   return requestFromServer
//     .createInvoice(
//       params.name,
//       params.granularity,
//       params.minTime,
//       params.maxTime,
//       params.type,
//       params.recurrencePeriod,
//       params.recurrenceTimes
//     ).then(response => {
//       dispatch(actions.invoiceCreated());
//     })
//     .catch(error => {
//       error.clientMessage = "Not found";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const fetchUpdateInvoice = (invoiceId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    statusId: params.statusId,
    paymentType: params.paymentType,
    paymentMethod: params.paymentMethod,
  };

  if (params.paymentAt && params.paymentDateAt && params.statusId == 'paid') {
    data.paidAt = `${params.paymentDateAt} ${params.paymentAt}`
  }

  return requestFromServer
    .updateInvoice(invoiceId, data)
    .then(response => {
      dispatch(actions.invoiceUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateInvoicePrice = (invoiceId, price) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateInvoice(invoiceId, {
      price: price,
    })
    .then(response => {
      dispatch(actions.invoicePriceUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchInvoicePayments = (invoiceId) => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.action }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .getInvoicePayments(invoiceId).then(response => {
        resolve(response.data);
      })
      .catch(error => {
        // error.clientMessage = "Not found";
        // dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const paymentTypes = [
  { label: 'Na unidade', value: 'locally' },
  { label: 'Online', value: 'online' },
];

export const paymentMethods = [
  { label: 'Não informado', value: 'unknown' },
  { label: 'Cartão de crédito', value: 'credit' },
  { label: 'Débito', value: 'debit' },
  { label: 'Pix', value: 'pix' },
  { label: 'Dinheiro', value: 'cash' },
];

export const paymentOnlineMethods = [
  { label: 'Créditos', value: 'credit' },
  { label: 'Cartão de crédito', value: 'credit_card' },
  { label: 'Pix', value: 'pix' },
];

export const getPaymentTypeLabel = (value) => {
  let type = paymentTypes.find(type => type.value == value)

  return type ? type.label : value;
}

export const getPaymentMethodLabel = (type, method) => {
  let label = method;

  if (type == 'locally') {
    let f = paymentMethods.find(type => type.value == method);

    if (f) {
      label = f.label;
    }
  }

  if (type == 'online') {
    let f = paymentOnlineMethods.find(type => type.value == method);

    if (f) {
      label = f.label;
    }
  }

  return label;
}