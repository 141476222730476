import axios from "axios";

export const GET_INVOICE = `${process.env.REACT_APP_API_URL}/invoices`;
export const UPDATE_INVOICE = `${process.env.REACT_APP_API_URL}/invoices`;

export function getInvoices(params) {
  let payload = {};

  if (params.schedule_id != '') {
    payload.schedule_id = params.schedule_id;
  }

  if (params.client_id != '') {
    payload.client_id = params.client_id;
  }

  if (params.date != '') {
    payload.date = params.date;
  }

  if (params.branch_id != '') {
    payload.branch_id = params.branch_id;
  }

  if (params.schedule_spot_id != '') {
    payload.schedule_spot_id = params.schedule_spot_id;
  }

  if (params.origin_id != '') {
    payload.origin_id = params.origin_id;
  }

  if (params.sort != '') {
    payload.sort = params.sort;
  }

  return axios.get(GET_INVOICE, {
    params: payload
  });
}

export function updateInvoice(invoiceId, params) {
  let payload = {};

  if (params.statusId) {
    payload.status_id = params.statusId;
  }

  if (params.paymentType) {
    payload.payment_type = params.paymentType;
  }

  if (params.paymentMethod) {
    payload.payment_method = params.paymentMethod;
  }

  if (params.paidAt) {
    payload.paid_at = params.paidAt;
  }

  if (params.price != null) {
    payload.price = params.price;
  }

  return axios.patch(`${UPDATE_INVOICE}/${invoiceId}`, payload);
}

export function getInvoicePayments(invoiceId) {
  return axios.get(`${GET_INVOICE}/${invoiceId}/payments`);
}