import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import {
  resetPasswordStartFlow,
  resetPasswordConfirmNewPassword,
} from "../_redux/authCrud";
import { resetPasswordStartFlowSchema } from "../_schemas/resetPasswordStartFlowSchema";
import { resetPasswordConfirmNewPasswordSchema } from "../_schemas/resetPasswordConfirmNewPasswordSchema";

const initialValues = {
  email: "",
};

const initialValuesNewPassword = {
  password: "",
  password_confirmation: "",
  reset_token: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const { search } = useLocation();
  const [isRequested, setIsRequested] = useState(false);
  const [isNewPasswordSubmitted, setIsNewPasswordSubmitted] = useState(false);
  const searchToken = new URLSearchParams(search);
  const resetToken = searchToken.get("h");

  useEffect(() => {
    if (resetToken)
      formikNewPassword.setFieldValue("reset_token", resetToken, false);
  }, [resetToken]);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordStartFlowSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      resetPasswordStartFlow(values.email)
        .then(() => setIsRequested(true))
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  function resetNewPasswordForm() {
    formik.setFieldValue("password", "", false);
    formik.setFieldValue("password_confirmation", "", false);
    formik.setFieldValue("reset_token", "", false);
  }

  const formikNewPassword = useFormik({
    initialValues: initialValuesNewPassword,
    validationSchema: resetPasswordConfirmNewPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(() => {
        const { reset_token, password, password_confirmation } = values;
        resetPasswordConfirmNewPassword(
          reset_token,
          password,
          password_confirmation
        )
          .then(() => {
            setSubmitting(true);
            setIsNewPasswordSubmitted(true);
            resetNewPasswordForm();
          })
          .catch((err) => {
            console.log({ err });
            setIsNewPasswordSubmitted(false);
            setSubmitting(false);
            setStatus("");
          });
      }, 0);
    },
  });

  return (
    <>
      {isRequested ? (
        <Redirect
          to={{
            pathname: "/auth/login",
            state: {
              resetPassword: {
                message: `Um email foi enviado para ${
                  formik.getFieldProps("email").value
                } com instruções para recuperar sua senha.`,
              },
            },
          }}
        />
      ) : resetToken ? (
        isNewPasswordSubmitted ? (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: {
                resetPassword: {
                  message:
                    "Sua senha foi redefinida com sucesso. Agora faça login.",
                },
              },
            }}
          />
        ) : (
          <div className="login-form login-forgot" style={{ display: "block" }}>
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">Redefina sua senha</h3>
              <p>
                A nova senha deve ter no mínimo 8 caracteres com pelo menos 1
                maiúscula, 1 minúscula e 1 caractere especial.
              </p>
            </div>
            <form
              onSubmit={formikNewPassword.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              <input
                type="hidden"
                disabled={true}
                name="reset_token"
                {...formikNewPassword.getFieldProps("reset_token")}
              />
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Nova senha"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 my-4 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formikNewPassword.getFieldProps("password")}
                />
                {formikNewPassword.touched.password &&
                formikNewPassword.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formikNewPassword.errors.password}
                    </div>
                  </div>
                ) : null}

                <input
                  placeholder="Confirme nova senha"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 my-4 ${getInputClasses(
                    "password_confirmation"
                  )}`}
                  name="password_confirmation"
                  {...formikNewPassword.getFieldProps("password_confirmation")}
                />
                {formikNewPassword.touched.password_confirmation &&
                formikNewPassword.errors.password_confirmation ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formikNewPassword.errors.password_confirmation}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap flex-center">
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  disabled={formikNewPassword.isSubmitting}
                >
                  Resetar senha
                </button>
                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Voltar
                  </button>
                </Link>
              </div>
            </form>
          </div>
        )
      ) : (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Esqueceu sua senha ?</h3>
            <div className="text-muted font-weight-bold">
              Entre com o e-mail cadastrado para recuperar a senha ou fale com o
              nosso suporte
              <a
                href="https://api.whatsapp.com/send?phone=5511978894211"
                target="_blank"
                rel="noopener noreferrer"
                className="px-2"
              >
                <i className="socicon-whatsapp"></i> WhatsApp
              </a>
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="E-mail"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Resetar senha
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Voltar
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
