import React, { useEffect, useState, useRef } from "react";
import SVG from "react-inlinesvg";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from "moment/min/moment-with-locales";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { fetchGetContracts } from "../../Contract/_redux/contractActions";

const ContractsList = (props) => {
  const { branchSelected } = useSelector(
    (state) => ({
      branchSelected: state.auth.branchSelected,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [contracts, setContracts] = useState([]);
  const [currentContracts, setCurrentContracts] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [classes, setClasses] = useState([]);
  // const [currentModality, setCurrentModality] = useState("class");
  const [isLoading, setIsLoading] = useState(false);

  const dispatchFetchClientContracts = (clientId) => {
    setIsLoading(true);
    dispatch(
      fetchGetContracts({ client_id: clientId, branch_id: branchSelected.id })
    ).then((response) => {
      // el.relationships.service.attributes.modality => reservation | class

      response.forEach((el) =>
        el.relationships.service.attributes.modality === "class"
          ? setClasses((prev) => [...prev, el])
          : setReservations((prev) => [...prev, el])
      );
      setContracts(response);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const currContracts = props.modality === "class" ? classes : reservations;
    setCurrentContracts(currContracts);
  }, [props.modality, contracts]);

  useEffect(() => {
    dispatchFetchClientContracts(props.client_id);
  }, []);

  const openEditEntity = (signature) => {
    props.onEdit(signature);
  };

  return (
    <>
      {/*<Grid container>
        <Grid item lg={12} xs={12}>
          <div className="w-100 align-self-end mt-3">
            <ul
              className="nav nav-pills nav-pills-sm nav-dark-75 float-right pr-0"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className={
                    currentModality === "class"
                      ? "nav-link py-2 px-4 active"
                      : "nav-link py-2 px-4"
                  }
                  data-toggle="tab"
                  onClick={() => setCurrentModality("class")}
                >
                  <span className="nav-text font-size-sm">Aulas</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    currentModality === "reservation"
                      ? "nav-link py-2 px-4 active"
                      : "nav-link py-2 px-4"
                  }
                  data-toggle="tab"
                  onClick={() => setCurrentModality("reservation")}
                >
                  <span className="nav-text font-size-sm">Locações</span>
                </a>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>*/}
      <Grid container justify="space-around">
        {props.type == 'single' && <Grid item lg={12} xs={12} className="pr-2">
          <h4 class="card-title">
            {props.modality === "reservation" && `Locações avulso`}
            {props.modality === "class" && `Aulas avulso`}
          </h4>
          <Table
            className="table table-head-custom table-head-bg table-borderless table-vertical-center d-block d-md-table overflow-auto"
            aria-labelledby="tableTitle"
            size="large"
            style={{}}
          >
            <TableHead>
              <TableRow>
                <TableCell>Serviço</TableCell>
                <TableCell>Início</TableCell>
                <TableCell>Término</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {contracts &&
                currentContracts
                  .filter(
                    (el) =>
                      el.relationships.service.attributes.type === "single"
                  )
                  .map((contract, index) => {
                    return (
                      <TableRow tabIndex={-1} key={contract.id}>
                        <TableCell component="th" scope="row" padding="none">
                          {contract.relationships.service.attributes.name}
                        </TableCell>

                        <TableCell align="left">
                          {moment(contract.attributes.start_at).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {moment(contract.attributes.finish_at).isValid()
                            ? moment(contract.attributes.finish_at).format(
                                "DD/MM/YYYY"
                              )
                            : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {contract.relationships.status.attributes.name ===
                            "active" &&
                          moment(contract.attributes.finish_at).isBefore(
                            moment()
                          ) ? (
                            <span
                              className={`ml-2 label label-lg label-light-warning label-inline`}
                            >
                              Finalizado
                            </span>
                          ) : (
                            <span
                              className={`ml-2 label label-lg label-light-${
                                contract.relationships.status.attributes
                                  .name === "active"
                                  ? "success"
                                  : "danger"
                              } label-inline`}
                            >
                              {
                                contract.relationships.status.attributes
                                  .display_name
                              }
                            </span>
                          )}
                        </TableCell>

                        <TableCell align="right">
                          <a
                            title="Editar assinatura"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            onClick={() => openEditEntity(contract)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                title="Editar assinatura"
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              />
                            </span>
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}

              {!isLoading && contracts &&
                currentContracts
                  .filter(
                    (el) =>
                      el.relationships.service.attributes.type === "single"
                  ).length == 0 && <TableRow><TableCell colSpan={12} className="text-center pt-5">Nenhum registro encontrado</TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </Grid>}

        {props.type == 'recurrence' && <Grid item lg={12} xs={12} className="pr-2">
          <h4 class="card-title">
            {props.modality === "reservation" && `Locações mensal`}
            {props.modality === "class" && `Aulas mensal`}
          </h4>
          <Table
            className="table table-head-custom table-head-bg table-borderless table-vertical-center d-block d-md-table overflow-auto"
            aria-labelledby="tableTitle"
            size="large"
            style={{}}
          >
            <TableHead>
              <TableRow>
                <TableCell>Serviço</TableCell>
                <TableCell>Início</TableCell>
                <TableCell>Término</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {contracts &&
                currentContracts
                  .filter(
                    (el) =>
                      el.relationships.service.attributes.type === "recurrence"
                  )
                  .map((contract, index) => {
                    return (
                      <TableRow tabIndex={-1} key={contract.id}>
                        <TableCell component="th" scope="row" padding="none">
                          {contract.relationships.service.attributes.name}
                        </TableCell>

                        <TableCell align="left">
                          {moment(contract.attributes.start_at).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {moment(contract.attributes.finish_at).isValid()
                            ? moment(contract.attributes.finish_at).format(
                                "DD/MM/YYYY"
                              )
                            : "--"}
                        </TableCell>

                        <TableCell align="left">
                          {contract.relationships.status.attributes.name ===
                            "active" &&
                          moment(contract.attributes.finish_at).isBefore(
                            moment()
                          ) ? (
                            <span
                              className={`ml-2 label label-lg label-light-warning label-inline`}
                            >
                              Finalizado
                            </span>
                          ) : (
                            <span
                              className={`ml-2 label label-lg label-light-${
                                contract.relationships.status.attributes
                                  .name === "active"
                                  ? "success"
                                  : "danger"
                              } label-inline`}
                            >
                              {
                                contract.relationships.status.attributes
                                  .display_name
                              }
                            </span>
                          )}
                        </TableCell>

                        <TableCell align="right">
                          <a
                            title="Editar assinatura"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            onClick={() => openEditEntity(contract)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                title="Editar assinatura"
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              />
                            </span>
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })
              }

              {!isLoading && contracts &&
                currentContracts
                  .filter(
                    (el) =>
                      el.relationships.service.attributes.type === "recurrence"
                  ).length == 0 && <TableRow><TableCell colSpan={12} className="text-center pt-5">Nenhum registro encontrado</TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </Grid>}
      </Grid>

      <Grid lg={12} xs={12}>
        <div className="d-flex align-items-center py-3">
          {isLoading && (
            <div className="d-flex align-items-center">
              <div className="mr-2 text-muted">Carregando...</div>
              <div className="spinner spinner-primary mr-10"></div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default ContractsList;
