import * as requestFromServer from "./permissionCrud";
import { permissionSlice, callTypes } from "./permissionSlice";

const { actions } = permissionSlice;

export const fetchPermissions = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getPermissions()
    .then((response) => {
      const entities = response.data.filter((el) => el.id !== "full-access");
      const totalCount = entities.length;

      console.log({ entities, totalCount });

      dispatch(actions.permissionsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Not found";
      // dispatch(actions.catchError({ error, callType: callTypes.list }));

      // dispatch(actions.branchesFetched({ totalCount: 0, entities: null }));
    });
};
