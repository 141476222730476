import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid';

export const RenderError = (props) => {
  return (<>
    {props.error && <Grid item xs={12}>
      <div className="fv-plugins-message-container">
        {(typeof props.error) == "string" &&
          <div className="fv-help-block">{props.error}</div>
        }

        {Array.isArray(props.error) && props.error.map((item) =>
          <div className="fv-help-block">{item}</div>
        )}
      </div>
    </Grid>}
  </>);
}