import axios from "axios";

export const GET_PAYMENT_CHARGEBACKS = `${process.env.REACT_APP_API_URL}/payment_chargebacks`;
export const APPROVE_PAYMENT_CHARGEBACK = `${process.env.REACT_APP_API_URL}/payment_chargebacks/approve`;
export const CANCEL_PAYMENT_CHARGEBACK = `${process.env.REACT_APP_API_URL}/payment_chargebacks`;

export function getPaymentChargebacks(params) {
  return axios.get(GET_PAYMENT_CHARGEBACKS, { params: params });
}

export function approvePaymentChargeback(paymentChargebackId) {
  return axios.patch(`${APPROVE_PAYMENT_CHARGEBACK}/${paymentChargebackId}`);
}

export function cancelPaymentChargeback(paymentChargebackId) {
  return axios.delete(`${CANCEL_PAYMENT_CHARGEBACK}/${paymentChargebackId}`);
}
