import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import moment from 'moment/min/moment-with-locales';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import { lighten, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers";

import DayPicker from '../../../components/DayPicker';
import SearchClient from "../../Client/components/SearchClient";

import CreateSignature from "./CreateSignature";
import EditSignature from "./EditSignature";

import { fetchSignatures } from "../_redux/signatureActions";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export const SignatureCardList = (props) => {
  const { signatures, branchSelected } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
    signatures: state.signatures,
  }), shallowEqual);

  const classes = useStyles();

  const dispatch = useDispatch();
  const location = useLocation();

  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState('active');
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [clientId, setClientId] = useState('');
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('client_name');
  const [sort, setSort] = useState('client_name');

  useEffect(() => {
    requestEntities();
  }, [rowsPerPage, page, status, branchSelected, clientId, sort]);

  useEffect(() => {
    if (signatures.entities) {
      setRows(signatures.entities.data);
    }
  }, [signatures]);


  useEffect(() => {
    if (signatures.created == true) {
      closeCreateEntity();
      openEditEntity(signatures.signature);
    }
  }, [signatures.created]);

  const requestEntities = async () => {
    try {
      dispatch(fetchSignatures(rowsPerPage, page + 1, sort, status == 'active' ? 1 : 0, branchSelected.id, clientId));
    } catch (error) {
      console.error(error);
    }
  };

  const closeCreateEntity = () => {
    {createEntityShow &&
      requestEntities();
    };

    setCreateEntityShow(false);
  };

  const openEditEntity = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }

  const closeEditEntity = () => {
    {editEntityShow &&
      requestEntities();
    };

    setEditEntityShow(false);
  }

  const getLabelDate = (day) => {
    if (day < 0) {
      return 'default';
    }

    if (day <= 3) {
      return 'danger';
    }

    if (day > 3 && day <= 10) {
      return 'warning';
    }

    return 'primary';
  }

  const getStatus = (invoices) => {
    let status = 'paid';

    invoices.map(invoice => {
      if (invoice.attributes.is_pending == true && status != 'expired') {
        status = 'pending';
      }

      if (invoice.attributes.is_canceled == true && status != 'expired') {
        status = 'canceled';
      }

      if (invoice.attributes.is_expired == true) {
        status = 'expired';
      }
    });

    return status;
  }

  const getLabelStatus = (status) => {
    if (status == 'paid') {
      return 'Pago';
    }

    if (status == 'canceled') {
      return 'Cancelado';
    }

    if (status == 'pending') {
      return 'A vencer';
    }

    return 'Em atraso';
  }

  const getColorStatus = (status) => {
    if (status == 'paid') {
      return 'success';
    }

    if (status == 'canceled') {
      return 'danger';
    }

    if (status == 'pending') {
      return 'primary';
    }

    return 'danger';
  }

  const selectClient = (value) => {
    setPage(0);
    setClientId(value);
  }

  const handleRequestSort = (property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    setSort(isDesc ? property : `-${property}`);
  }

  const headRows = [
    { id: 'client_name', ordered: true, disablePadding: true, label: 'Nome' },
    { id: 'plan.name', ordered: false, disablePadding: true, label: 'Plano' },
    { id: 'finish_at', ordered: true, disablePadding: false, label: 'Vencimento' },
    { id: 'status', ordered: false, disablePadding: false, label: 'Pgto' },
    { id: 'actions', ordered: false, disablePadding: false, label: '' },
  ];

  return (<>
    <div class="d-flex">
      <div class="col-6">
        <h3 class="card-title">Clubinho</h3>
      </div>
      <div class="col-6 text-right">
        <a href="#" className="btn btn-light-success font-weight-bold" onClick={() => setCreateEntityShow(true)}>
          <i className="ki ki-plus "></i> Assinante
        </a>
      </div>
    </div>

    <div className="separator separator-dashed mt-1 mb-10"></div>

    <div class="row">
      <div class="col-lg-12">
        <SearchClient onChange={(value) => selectClient(value)} />
      </div>
    </div>

    <div class="row pb-3">
      <div class="col-lg-6 align-self-end"></div>
      <div class="col-lg-6 align-self-end">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75 float-right pr-0" role="tablist">
          <li class="nav-item">
            <a class={status == 'active' ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setStatus('active')}>
              <span class="nav-text font-size-sm">Ativos</span>
            </a>
          </li>
          <li class="nav-item">
            <a class={status == 'canceled' ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setStatus('canceled')}>
              <span class="nav-text font-size-sm">Expirados</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div className={classes.tableWrapper}>
      <div className="table-responsive">
        <Table
          className="table table-head-custom table-head-bg table-borderless table-vertical-center"
          aria-labelledby="tableTitle"
          size="large"
        >
          <TableHead>
            <TableRow>
              {headRows.map(row => (
                <TableCell
                  key={row.id}
                  align="left"
                  padding={row.disablePadding ? 'none' : 'default'}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={() => handleRequestSort(row.id)}
                    disabled={!row.ordered}
                  >
                    <span style={{
                      color: "#B5B5C3",
                      textTransform: "uppercase"
                    }}>
                    {row.label}
                    </span>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows && rows.map((row, index) => {
              let status = getStatus(row.relationships.invoices);

              return <TableRow
                tabIndex={-1}
                key={'clubinho-client-' + row.id}
              >
                <TableCell component="th" scope="row" padding="none">
                  {row.relationships.client.attributes.name}
                </TableCell>
                <TableCell align="left">{row.relationships.plan.attributes.name}</TableCell>
                <TableCell align="left">
                  <span className={`label label-lg label-light-${getLabelDate(row.attributes.remaining_days)} label-inline`}>
                    {moment(row.attributes.finish_at).format('DD/MM/YYYY')}
                  </span>
                </TableCell>
                <TableCell align="left">
                  <span className={`label label-lg label-light-${getColorStatus(status)} label-inline`} style={{minWidth: 'max-content'}}>
                    {getLabelStatus(status)}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <a
                    title="Editar assinatura"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={() => openEditEntity(row)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        title="Editar assinatura"
                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                      />
                    </span>
                  </a>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </div>
    </div>

    <div className="d-flex align-items-center py-3">
      {signatures.listLoading && <div className="d-flex align-items-center">
        <div className="mr-2 text-muted">Carregando...</div>
        <div className="spinner spinner-primary mr-10"></div>
      </div>}

      <TablePagination
        labelRowsPerPage="Por página"
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={signatures.totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Página anterior',
        }}
        nextIconButtonProps={{
          'aria-label': 'Próxima Página',
        }}
        onChangePage={(event, newPage) => setPage(newPage)}
        onChangeRowsPerPage={(event) => setRowsPerPage(+event.target.value)}
        labelDisplayedRows={(page) =>
          `${page.from} até ${page.to === -1 ? page.count : page.to} total de ${
            page.count
          }`
        }
      />
    </div>

    <CreateSignature
      show={createEntityShow}
      onHide={closeCreateEntity}
      style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
    />

    <EditSignature show={editEntityShow} onHide={closeEditEntity} event={editEntity} />
  </>);
}
