import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';

export default function StatusChip(props) {
  const [status, setStatus] = useState({});

  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  const getChipStatusColor = (name) => {
    let color = '#17c191';

    if (name == 'canceled') {
      color = 'red';
    }

    if (name == 'pending') {
      color = '#ffcc00';
    }

    return {
      color: color,
      border: `1px solid ${color}`
    };
  }

  const getChipStatusIcon = (name) => {
    if (name == 'canceled') {
      return <BlockIcon fontSize="small" style={{ fill: "red" }} />;
    }

    if (name == 'pending') {
      return <ErrorOutlineIcon fontSize="small" style={{ fill: "#ffcc00" }} />;
    }

    return <DoneIcon fontSize="small" style={{ fill: "#17c191" }} />;
  }

  return (<>
    {(status.id && <Chip
      label={status.attributes.display_name}
      style={getChipStatusColor(status.attributes.name)}
      deleteIcon={getChipStatusIcon(status.attributes.name)}
      variant="outlined"
      size="small"
    />)}
  </>);
}