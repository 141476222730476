import axios from "axios";

export const GET_CLIENTS = `${process.env.REACT_APP_API_URL}/clients`;
export const CREATE_CLIENT = `${process.env.REACT_APP_API_URL}/clients`;
export const UPDATE_CLIENTS = `${process.env.REACT_APP_API_URL}/clients`;
export const ASSOCIATE_CLIENTS = `${process.env.REACT_APP_API_URL}/clients/associate`;

export const GET_CLIENT_PHONE = `${process.env.REACT_APP_API_URL}/clients/phone/`;
export const GET_CLIENT_EMAIL = `${process.env.REACT_APP_API_URL}/clients/email/`;
export const GET_CLIENT_ID = `${process.env.REACT_APP_API_URL}/clients/`;

export function getClients(params) {
  return axios.get(GET_CLIENTS, { params: params });
}

export function createClient(params) {
  return axios.post(CREATE_CLIENT, params);
}

export function associateClient(branchId, params) {
  return axios.post(`${ASSOCIATE_CLIENTS}/${branchId}`, params);
}

export function updateClient(clientId, params) {
  return axios.put(`${UPDATE_CLIENTS}/${clientId}`, params);
}

export function updateClientCompany(clientId, params) {
  return axios.put(`${UPDATE_CLIENTS}/${clientId}/company`, params);
}

export function updateClientPhoto(clientId, params) {
  return axios.put(`${UPDATE_CLIENTS}/${clientId}/photo`, params);
}

export function getClientByPhone(string) {
  return axios.get(GET_CLIENT_PHONE + string);
}

export function getClientByEmail(string) {
  return axios.get(GET_CLIENT_EMAIL + string);
}

export function getClientById(string, params) {
  return axios.get(GET_CLIENT_ID + string, { params: params});
}

export function deleteClient(string) {
  return axios.delete(GET_CLIENT_ID + string);
}
