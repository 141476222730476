import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup'

import CloseIcon from '@material-ui/icons/Close';

import { RenderError } from "../../../components/RenderError";
import Snackbar from "../../../components/Snackbar";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { InfoBox } from "../../Report/components/InfoBox";
import { formatMoney } from "../../../utils/IntegerParse";
import { useInterval } from "../../../utils/Interval";

import {
  getLabelPaymentChargebackStatus,
  getLabelInvoiceStatus,
  getColorPaymentChargebackStatus,
  getColorInvoiceStatus,
  getBrandImage,
  getLabelPaymentChargebackOrigin,
  getLabelPaymentChargebackRefundType,
} from "../../../utils/Labels";

import {
  fetchUpdateInvoice,
  fetchUpdateInvoicePrice,
  fetchInvoicePayments,
  paymentTypes,
  paymentMethods,
  paymentOnlineMethods,
  getPaymentTypeLabel,
  getPaymentMethodLabel,
} from "../_redux/invoiceActions";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const ShowInvoice = (props) => {
  const dispatch = useDispatch();
  const { invoices } = useSelector((state) => ({
    invoices: state.invoices,
  }), shallowEqual);

  const classes = useStyles();

  const [changed, setChanged] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [payments, setPayments] = useState([]);
  const [copied, setCopied] = useState(false);
  const [showEditPrice, setShowEditPrice] = useState(false);
  const [customPaymentDate, setCustomPaymentDate] = useState(false);

  // const status = [
  //   { label: 'Pendente', value: 'pending' },
  //   { label: 'Pago', value: 'paid' }
  // ];

  // const paymentTypes = [
  //   { label: 'Pagamento na unidade', value: 'locally' },
  //   { label: 'Pagamento Online', value: 'online' },
  // ];

  // const paymentMethods = [
  //   { label: 'Não informado', value: 'unknown' },
  //   { label: 'Cartão de crédito', value: 'credit' },
  //   { label: 'Débito', value: 'debit' },
  //   { label: 'Pix', value: 'pix' },
  //   { label: 'Dinheiro', value: 'cash' },
  // ];

  // const paymentOnlineMethods = [
  //   { label: 'Créditos', value: 'credit' },
  //   { label: 'Cartão de crédito', value: 'credit_card' },
  //   { label: 'Pix', value: 'pix' },
  // ];

  const payment = [
    { label: 'Pago', value: 'paid' },
    { label: 'Pendente', value: 'pending' },
  ];

  const getAttribute = (attribute) => {
    if (props.attributes && props.attributes[attribute]) {
      return props.attributes[attribute];
    }

    return null;
  }

  // const getPaymentTypeLabel = (value) => {
  //   let type = paymentTypes.find(type => type.value == value)

  //   return type ? type.label : value;
  // }

  // const getPaymentMethodLabel = (type, method) => {
  //   let label = method;

  //   if (type == 'locally') {
  //     let f = paymentMethods.find(type => type.value == method);

  //     if (f) {
  //       label = f.label;
  //     }
  //   }

  //   if (type == 'online') {
  //     let f = paymentOnlineMethods.find(type => type.value == method);

  //     if (f) {
  //       label = f.label;
  //     }
  //   }

  //   return label;
  // }

  const close = () => {
    props.onHide();

    setShowEditPrice(false);
    setCustomPaymentDate(false);
  }

  const confirmModal = () => {
    props.onHide();
  }

  const copyPixLink = (link) => {
    navigator.clipboard.writeText(link);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  const setActivePayments = (values) => {
    if (values) {
      let params = values.filter(arrayItem => !arrayItem.attributes.canceled_at);

      setPayments(params)
    }
  }

  const verifyPayment = async () => {
    if (invoice && invoice.attributes && invoice.attributes.payment_type == 'online') {
      let payments = await dispatch(fetchInvoicePayments(invoice.id));

      setActivePayments(payments);
    }
  }

  const dispatchUpdatePrice = () => {
    dispatch(fetchUpdateInvoicePrice(invoice.id, formik.values.price));
  }

  useEffect(() => {
    if (!props.show) {
      setInvoice({});
      setPayments({});
    } else {
      setInvoice(props.invoice)
    }
  }, [props.show]);

  useEffect(() => {
    setInvoice(props.invoice);
  }, [props.invoice]);

  useEffect(() => {
    setShowEditPrice(false);
    setCustomPaymentDate(false);

    if (invoice && invoice.attributes && invoice.relationships) {
      formik.setFieldValue('statusId', invoice.relationships.status.attributes.name, false);
      formik.setFieldValue('paymentType', invoice.attributes.payment_type, false);
      formik.setFieldValue('paymentMethod', invoice.attributes.payment_method, false);
      formik.setFieldValue('price', parseInt(invoice.attributes.price), false);

      let paidAt = invoice.attributes.paid_at ? moment(invoice.attributes.paid_at) : moment();

      formik.setFieldValue('paymentDateAt', paidAt.format('YYYY-MM-DD'), false);
      formik.setFieldValue('paymentAt', paidAt.format('HH:mm'), false);

      if (invoice.attributes.payment_type == 'online') {
        invoice.relationships && setActivePayments(invoice.relationships.payments);
      }
    }
  }, [invoice]);

  // useInterval(() => {
  //   verifyPayment();
  // }, 5000);
  //

  const formik = useFormik({
    initialValues: {
      statusId: '',
      paymentType: 'locally',
      paymentMethod: 'unknown',
      paymentDateAt: '',
      paymentAt: '',
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        dispatch(fetchUpdateInvoice(invoice.id, values));

        setSubmitting(false);
      }, 10);
    },
  });

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Detalhes da fatura
          </Modal.Title>
          <CloseIcon onClick={close} className="mt-3 mr-3 cursor-pointer" />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Grid container className={classes.label} spacing={2}>
              <div className="d-flex flex-column">

                <Grid item xs={12} className="mb-5">
                  <span className="font-weight-bolder text-dark">
                    Informações
                  </span>
                </Grid>

                <div className="row">
                  <label className="col-12 col-form-label font-weight-bold text-left">
                    Identificação da transação
                  </label>
                  <div className="col-12">
                    <label>
                      <span></span>{invoice.id}
                    </label>
                  </div>
                </div>
                {invoice.relationships && invoice.relationships.client_schedule && <div className="row">
                  <label className="col-12  col-form-label font-weight-bold text-left">
                    Nome
                  </label>
                  <div className=" col-12">
                    <label>
                      <span></span>{invoice.relationships.client_schedule.relationships.client.attributes.name}
                    </label>
                  </div>
                </div>}

                <div className="row">
                  <div className="col-12">
                    {invoice.relationships && invoice.relationships.origin && invoice.relationships.origin && invoice.relationships.origin.id && <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Serviço / Plano
                      </label>
                      <div className=" col-12">
                        {
                          invoice.relationships.origin.type == 'contract' &&
                          invoice.relationships.origin.relationships.service.attributes.modality == 'class' &&
                          invoice.relationships.origin.relationships.service.attributes.type == 'single' &&
                          `Aula avulso`
                        }
                        {
                          invoice.relationships.origin.type == 'contract' &&
                          invoice.relationships.origin.relationships.service.attributes.modality == 'class' &&
                          invoice.relationships.origin.relationships.service.attributes.type == 'recurrence' &&
                          `Aluno mensalista`
                        }

                        {
                          invoice.relationships.origin.type == 'contract' &&
                          invoice.relationships.origin.relationships.service.attributes.modality == 'reservation' &&
                          invoice.relationships.origin.relationships.service.attributes.type == 'single' &&
                          `Locação avulso`
                        }

                        {
                          invoice.relationships.origin.type == 'contract' &&
                          invoice.relationships.origin.relationships.service.attributes.modality == 'reservation' &&
                          invoice.relationships.origin.relationships.service.attributes.type == 'recurrence' &&
                          `Locação mensal`
                        }

                        {invoice.relationships.origin.type == 'day_use' && `Day use`}
                        {invoice.relationships.origin.type == 'signature' && `Clubinho`}
                        {invoice.relationships.origin.type == 'credit_purchase' && `Compra de créditos`}

                        {invoice.relationships.origin.type && <span style={{
                          color: "#B5B5C3",
                          textTransform: "uppercase"
                        }}>
                          <br />

                          {
                            invoice.relationships.origin.type == 'contract' &&
                            invoice.relationships.origin.relationships.service &&
                            invoice.relationships.origin.relationships.service.attributes &&
                            invoice.relationships.origin.relationships.service.attributes.name
                          }

                          {
                            (invoice.relationships.origin.type == 'day_use' || invoice.relationships.origin.type == 'signature') &&
                            invoice.relationships.origin.relationships.plan &&
                            invoice.relationships.origin.relationships.plan.attributes &&
                            invoice.relationships.origin.relationships.plan.attributes.name
                          }

                          {
                            invoice.relationships.origin.type == 'credit_purchase' &&
                            invoice.relationships.origin.relationships.origin &&
                            invoice.relationships.origin.relationships.origin.attributes &&
                            invoice.relationships.origin.relationships.origin.attributes.name
                          }
                        </span>}
                      </div>
                    </div>}

                    <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Data
                      </label>
                      <div className=" col-12">
                        {invoice.attributes && invoice.attributes.start_at != invoice.attributes.finish_at && <label>
                          <span></span>{moment(invoice.attributes.start_at).format('DD/MM/YYYY')} até {invoice.attributes && moment(invoice.attributes.finish_at).format('DD/MM/YYYY')}
                        </label>}
                        {invoice.attributes && invoice.attributes.start_at == invoice.attributes.finish_at && <label>
                          <span></span>{moment(invoice.attributes.start_at).format('DD/MM/YYYY')}
                        </label>}
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Vencimento
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{invoice.attributes && moment(invoice.attributes.expired_at).format('DD/MM/YYYY')}
                        </label>
                      </div>
                    </div>
                    {invoice.attributes && invoice.attributes.canceled_at && <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Data cancelamento
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{moment(invoice.attributes.canceled_at).format('DD/MM/YYYY')}
                        </label>
                      </div>
                    </div>}
                  </div>
                </div>

                <div class="separator separator-dashed my-5"></div>

                <Grid item xs={12} className="mb-3">
                  <span className="font-weight-bolder text-dark">
                    Pagamento
                  </span>
                </Grid>

                <div className="row">
                  <div className="col-4">
                    <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Status
                      </label>
                      <div className=" col-12">
                        <span className={`label label-md label-light-${getColorInvoiceStatus(invoice)} label-inline`}>
                          {getLabelInvoiceStatus(invoice)}
                        </span>
                      </div>
                    </div>
                  </div>

                  {invoice.attributes && invoice.attributes.paid_at && <div className="col-4">
                    <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Data pagamento
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{moment(invoice.attributes.paid_at).format('DD/MM/YYYY HH:mm')}
                        </label>
                      </div>
                    </div>
                  </div>}
                </div>

                <div className="row">
                  <div className="col-4">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Valor
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{invoice.attributes && formatMoney(invoice.attributes.price)}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Desconto
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{invoice.attributes && formatMoney(invoice.attributes.discount)}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Subtotal
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{invoice.attributes && formatMoney(invoice.attributes.full_price)}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {invoice.attributes && invoice.attributes.can_edit == true && <div className="row">
                  <div class="col-lg-12 py-1">
                    <a class="btn btn-light-primary font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => setShowEditPrice(true)}>
                      Editar valor
                    </a>
                  </div>
                </div>}

                {invoice.attributes && invoice.attributes.can_edit == true && showEditPrice && <>
                  <div className="separator separator-dashed mb-5 mt-1"></div>

                  <div className="row">
                    <div class="col-lg-6 py-1">
                      <TextField
                        name="price"
                        label={'Valor da fatura'}
                        fullWidth
                        className={classes.textField}
                        placeholder="ex: 260"
                        variant="outlined"
                        type="number"
                        min="1"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        {...formik.getFieldProps("price")}
                      />

                      {invoices.error && <RenderError error={invoices.error.price} />}
                    </div>

                    <div class="col-lg-6 py-1 flex-column align-items-center">
                      <span class="label label-xl font-weight-boldest label-success mt-5 cursor-pointer" onClick={() => dispatchUpdatePrice()}>
                        <i class="ki ki-check text-white"></i>
                      </span>
                      <span class="label label-xl font-weight-boldest label-danger mt-5 ml-2 cursor-pointer" onClick={() => setShowEditPrice(false)}>
                        <i class="ki ki-close text-white"></i>
                      </span>
                    </div>
                  </div>
                </>}

                <div class="separator separator-dashed my-5"></div>
                <div className="row">
                  <label className="col-12 col-form-label font-weight-bolder text-left mt-2">
                    Forma de pagamento
                  </label>
                  <div className="col-6">
                    <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Pagamento
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{invoice.attributes && getPaymentTypeLabel(invoice.attributes.payment_type)}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Método
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{invoice.attributes && getPaymentMethodLabel(invoice.attributes.payment_type, invoice.attributes.payment_method)}
                        </label>
                      </div>
                    </div>
                  </div>

                  {
                    invoice &&
                    invoice.attributes &&
                    invoice.attributes.payment_type == 'online' &&
                    invoice.attributes.payment_method == 'credit_card' &&
                    payments &&
                    payments.length == 1 &&
                    payments[0] &&
                    payments[0].relationships &&
                    payments[0].relationships.client_card &&
                    payments[0].relationships.client_card.attributes && <div className="col-6">
                    <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Cartão utilizado
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>

                          <img
                            className="mx-2"
                            src={toAbsoluteUrl(getBrandImage(payments[0].relationships.client_card.attributes.brand))}
                            style={{width: "2.5rem"}}
                          />
                           ••• {payments[0].relationships.client_card.attributes.last_four_digits}
                        </label>
                      </div>
                    </div>
                  </div>}


                  {
                    payments &&
                    payments.length == 1 &&
                    payments[0] &&
                    payments[0].relationships &&
                    payments[0].relationships.payment_chargebacks &&
                    payments[0].relationships.payment_chargebacks[0] && <div className="col-12">
                    <div class="separator separator-dashed my-5"></div>
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bolder text-left mt-2">
                        Solicitação de estorno
                      </label>
                      <div className="col-6">
                        <div className="row">
                          <label className="col-12  col-form-label font-weight-bold text-left">
                            Valor
                          </label>
                          <div className=" col-12">
                            <label>
                              <span></span>{formatMoney(payments[0].relationships.payment_chargebacks[0].attributes.price)}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <label className="col-12  col-form-label font-weight-bold text-left">
                            Solicitado pelo
                          </label>
                          <div className=" col-12">
                            <label>
                              <span></span>
                              {getLabelPaymentChargebackOrigin(payments[0].relationships.payment_chargebacks[0].attributes.origin)}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        {payments[0].relationships.payment_chargebacks[0].attributes.payment_requested_at && <div className="row">
                          <label className="col-12  col-form-label font-weight-bold text-left">
                            Data solicitação
                          </label>
                          <div className=" col-12">
                            <label>
                              <span></span>{moment(payments[0].relationships.payment_chargebacks[0].attributes.payment_requested_at).format('DD/MM/YYYY HH:mm')}
                            </label>
                          </div>
                        </div>}
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <label className="col-12  col-form-label font-weight-bold text-left">
                            Status
                          </label>
                          <div className=" col-12">
                            <span className={`label label-lg label-light-${getColorPaymentChargebackStatus(payments[0].relationships.payment_chargebacks[0].relationships.status.attributes.name)} label-inline`} style={{minWidth: 'max-content'}}>
                              {getLabelPaymentChargebackStatus(payments[0].relationships.payment_chargebacks[0].relationships.status.attributes.name)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        {payments[0].relationships.payment_chargebacks[0].attributes.paid_at && <div className="row">
                          <label className="col-12  col-form-label font-weight-bold text-left">
                            Pagamento do estorno
                          </label>
                          <div className=" col-12">
                            <label>
                              <span></span>{moment(payments[0].relationships.payment_chargebacks[0].attributes.paid_at).format('DD/MM/YYYY HH:mm')}
                            </label>
                          </div>
                        </div>}
                      </div>
                      {payments[0].relationships.payment_chargebacks[0].attributes.refund_type && <div className="col-6">
                        <div className="row">
                          <label className="col-12  col-form-label font-weight-bold text-left">
                            Tipo de estorno
                          </label>
                          <div className=" col-12">
                            <label>
                              <span></span>
                              {getLabelPaymentChargebackRefundType(payments[0].relationships.payment_chargebacks[0].attributes.refund_type)}
                            </label>
                          </div>
                        </div>
                      </div>}
                    </div>
                  </div>}
                </div>
              </div>
            </Grid>


            {invoice.attributes && invoice.attributes.can_edit == true && <>
              <div class="separator separator-dashed my-5"></div>
              <Grid container className={classes.label} spacing={2}>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">Alterar pagamento da fatura</span>
                </Grid>

                {/*<Grid item xs={6}>
                  <TextField
                    select
                    label="Tipo de pagamento"
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        }
                      }
                    }}
                    name="paymentType"
                    fullWidth
                    className={classes.textField}
                    {...formik.getFieldProps("paymentType")}
                  >
                    {paymentTypes.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                  </TextField>

                  {invoices.error && <RenderError error={invoices.error.payment_type} />}
                </Grid>*/}

                <Grid item xs={6}>
                  {formik.values.paymentType == 'locally' && <>
                    <TextField
                      select
                      label="Método de pagamento"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          }
                        }
                      }}
                      name="paymentMethod"
                      fullWidth
                      className={classes.textField}
                      {...formik.getFieldProps("paymentMethod")}
                    >
                      {paymentMethods.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </TextField>

                    {invoices.error && <RenderError error={invoices.error.payment_method} />}
                  </>}
                </Grid>

                {formik.values.paymentType == 'locally' && <>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Já foi pago?"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          }
                        }
                      }}
                      name="statusId"
                      fullWidth
                      className={classes.textField}
                      {...formik.getFieldProps("statusId")}
                    >
                      {payment.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </TextField>

                    {invoices.error && <RenderError error={invoices.error.status_id} />}
                  </Grid>

                  {formik.values.statusId == 'paid' && <Grid item xs={12}>
                    <label>Customizar data de pagamento?</label>
                    <span className="switch switch-brand switch-icon">
                      <label>
                        <input type="checkbox" check={customPaymentDate} onClick={() => setCustomPaymentDate(!customPaymentDate)} name="select"/>
                      <span></span>
                    </label>
                   </span>
                  </Grid>}

                  {formik.values.statusId == 'paid' && customPaymentDate && <>
                    <Grid item xs={6}>
                      <TextField
                        name="paymentDateAt"
                        label={'Data Pagamento'}
                        type="date"
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...formik.getFieldProps("paymentDateAt")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        // value={startAt}
                        name="paymentAt"
                        label="Horário pagamento"
                        type="time"
                        fullWidth
                        step={15}
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: "900", // 15 min
                        }}
                        {...formik.getFieldProps("paymentAt")}
                      />
                    </Grid>
                  </>}
                </>}

                {/*{formik.values.paymentType == 'online' && invoice.attributes.payment_type != 'online' && <Grid item xs={12}>
                  <div class="alert  alert-custom alert-secondary" role="alert">
                    <div class="alert-text">Após a confirmação vamos gerar um qrcode pix para pagamento</div>
                  </div>
                </Grid>}

                {formik.values.paymentType == 'locally' && invoice.attributes.payment_type != 'locally' && <Grid item xs={12}>
                  <div class="alert  alert-custom alert-danger" role="alert">
                    <div class="alert-text">O pagamento via pix abaixo vai ser cancelado, não será mais possível pagar esse qrcode.</div>
                  </div>
                </Grid>}*/}
              </Grid>
            </>}

            {(typeof invoices.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {invoices.error}
                  </span>
                </div>
              </div>
            </Grid>}

            {/*{payments && payments.length > 0 && <>
              <div class="separator separator-dashed my-5"></div>
              <Grid container className={classes.label} spacing={2}>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">Pagamento via Pix</span>
                </Grid>

                {payments.map(payment => <>
                  <Grid item xs={12} justify="center">
                    {payment.attributes && payment.attributes.paid_at && <div class="alert  alert-custom alert-success" role="alert">
                      <div class="alert-icon"><i class="flaticon2-check-mark"></i></div>
                      <div class="alert-text">Pagamento realizado em {moment(payment.attributes.paid_at).format('DD/MM/YYYY HH:mm')}</div>
                    </div>}

                    {!payment.attributes.paid_at && <>
                      {payment.attributes && payment.attributes.pix_qrcode && <div className="text-center">
                        <img src={payment.attributes.pix_qrcode} />
                      </div>}

                      <div class="form-group">
                        <label>Copia e cola</label>
                        <div class="input-group">
                          <input type="text" class="form-control" value={payment.attributes.pix_link} />
                          <div class="input-group-append">
                            <button class="btn btn-primary" type="button" onClick={() => copyPixLink(payment.attributes.pix_link)}>{copied ? 'Copiado!' : 'Copiar'}</button>
                          </div>
                        </div>
                      </div>
                    </>}
                  </Grid>
                </>)}
              </Grid>
            </>}*/}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            {invoice.attributes && invoice.attributes.can_edit == true && <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={changed || formik.isSubmitting}>
                Alterar {(formik.isSubmitting || invoices.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>}
            <Grid item xs style={{textAlign: (invoice.attributes && invoice.attributes.can_edit == true) ? 'left' : 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>
  </>);
};

export default ShowInvoice;