import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import { format } from 'date-fns';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import ControlPoint from '@material-ui/icons/ControlPoint';
import CloseIcon from '@material-ui/icons/Close';

import { RenderError } from "../../../components/RenderError";
import { notifySuccess } from "../../../utils/Notify";
import DayPicker from '../../../components/DayPicker';
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CreateClient from "../../Client/components/CreateClient";
import EditPlan from "../../Plan/components/EditPlan";

import { makeStyles } from '@material-ui/core/styles';

import { fetchCoupons, fetchCreateCoupon } from "../_redux/couponActions";
import { fetchClientName } from "../../Client/_redux/clientActions";
import { fetchPlans } from "../../Plan/_redux/planActions";
import { fetchServices } from "../../Service/_redux/serviceActions";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
  lineThrough: {
    textDecoration: 'line-through',
    fontSize: '10px'
  },
}));

const CreateCoupon = (props) => {
  const dispatch = useDispatch();
  const { coupons, clients, plans, branchSelected } = useSelector((state) => ({
    coupons: state.coupons,
    clients: state.clients,
    plans: state.plans,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [showClient, setShowClient] = useState(false);
  const [originType, setOriginType] = useState('');
  // const [startDateAt, setStartDateAt] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [selectedOrigin, setSelectedOrigin] = useState([]);
  const [couponRestrict, setCouponRestrict] = useState(false);
  const [weekdays, setWeekdays] = useState([2,4,8,16,32,64,1]);

  useEffect(() => {
    if (coupons.created == true) {
      notifySuccess('Cadastrado com sucesso', 'coupons-created', 2000);
    }
  }, [coupons]);

  // useEffect(() => {
  //   if (plans.entities) {
  //     setRows(plans.entities);
  //   }
  // }, [plans]);

  useEffect(() => {
    if (!props.show) {
      close();
    }
  }, [props.show]);

  useEffect(() => {
    if (props.show && originType) {
      setRows([]);
      setSelectedOrigin([]);
      // setStartDateAt(new Date);
      // formik.setFieldValue('billing_day', moment().format('DD'), false);
      // formik.setFieldValue('billing_day', moment().format('DD'), false);

      if (originType == 'day_use' || originType == 'signature') {
        dispatch(fetchPlans('active', 'day_use', '', branchSelected.id)).then((data) => {
          setRows(data);
        });
      }

      if (originType == 'class' || originType == 'reservation') {
        dispatch(fetchServices(branchSelected.id, originType, 'single')).then((data) => {
          setRows(data);
        });
      }
    }
  }, [originType]);

  const renew = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const close = () => {
    props.onHide();
    setShowClient(false);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('description', '', false);
    formik.setFieldValue('selling_modality', '', false);
    formik.setFieldValue('value', '', false);
    formik.setFieldValue('limit_per_client', '', false);
    formik.setFieldValue('usage_limit', '', false);
    formik.setFieldValue('valid_at', moment().format('YYYY-MM-DD'), false);
    formik.setFieldValue('expired_at', moment().add(1, 'months').format('YYYY-MM-DD'), false);
    formik.setFieldValue('weekdays', [2,4,8,16,32,64,1], false);

    setSelectedOrigin([]);
    setOriginType('');
    setCouponRestrict(false);
    setWeekdays([2,4,8,16,32,64,1]);
  }

  const getPrices = (entitiesPrice) => {
    let prices = [];

    if (entitiesPrice.length > 0) {
      entitiesPrice.map(price => {
        prices.push(price.attributes.price);
      });

      let minMax = getMinMax(prices);

      if (minMax.min == minMax.max) {
        return <>
          R$ {minMax.min} <br />{entitiesPrice[0].attributes.time} min
        </>
      }

      return <>
        R$ {minMax.min} ~ R$ {minMax.max} <br />{entitiesPrice[0].attributes.time} min
      </>
    }
  }

  const getMinMax = (arr) => {
    return arr.reduce(({min, max}, v) => ({
      min: min < v ? min : v,
      max: max > v ? max : v,
    }), { min: arr[0], max: arr[0] });
  }

  const searchClientName = () => {
    if (formik.values.name && formik.values.name.length >= 2) {
      const clientNameRequestTimeout = setTimeout(() => {
        dispatch(fetchClientName(formik.values.name, branchSelected.id));
        setShowClient(true);
      }, 400);

      return () => {
        clearTimeout(clientNameRequestTimeout);
      };
    }
  }

  const changeType = (value) => {
    setOriginType(value)

    if (value == 'reservation' || value == 'signature') {
      formik.setFieldValue('selling_type', 'single', false);
    }
    if (value == 'day_use' || value == 'class') {
      formik.setFieldValue('selling_type', 'package', false);
    }
  }

  // const planSelected = (value) => {
  //   if (selectedOrigin.id && value == selectedOrigin.id) {
  //     return true
  //   }

  //   return false;
  // }

  const originCheck = (value) => {
    if (originIsChecked(value)) {
      setSelectedOrigin(selectedOrigin.filter(arrayItem => arrayItem !== value));
    } else {
      setSelectedOrigin([...selectedOrigin, value]);
    }
  }

  const originIsChecked = (value) => {
    return selectedOrigin.includes(value);
  }

  const originTypeClass = (item) => {
    return originType == item ? "contained" : "outlined";
  }

  const openEditEntity = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }


  const selectedWeekDayColor = (weekday) => {
    return weekdays.includes(weekday) ? "primary" : "default";
  }

  const selectWeekDay = (weekday) => {
    if(!weekdays.includes(weekday)) {
      setWeekdays([...weekdays, weekday]);
    } else{
        var array = [...weekdays];
        var index = array.indexOf(weekday);

        if (index !== -1) {
          array.splice(index, 1);

          setWeekdays(array);
        }
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      valid_at: moment().format('YYYY-MM-DD'),
      expired_at: moment().add(1, 'months').format('YYYY-MM-DD'),
      start_minutes: '00:00',
      end_minutes: '23:59',
      type: 'fixed',
      selling_type: 'single',
      limit_per_client: '',
      usage_limit: '',
    },
    // validationSchema: createClientcouponschema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        let params = {
          selling_modality: originType,
          branch_id: branchSelected.id,
          available_app: true,
          name: values.name,
          description: values.description,
          valid_at: values.valid_at,
          expired_at: values.expired_at,
          selling_type: values.selling_type,
          type: values.type,
          value: values.value,
        };

        if (originType == 'reservation' && values.selling_type == 'single') {
          params.weekday = weekdays.reduce((x, y) => x + y, 0);
          params.start_minutes = values.start_minutes;
          params.end_minutes = values.end_minutes;
        }

        if (couponRestrict) {
          params.coupon_restriction = selectedOrigin.map(value => { return {origin_id: value} });
        }

        if (values.usage_limit) {
          params.usage_limit = values.usage_limit;
        }

        if (values.limit_per_client) {
          params.limit_per_client = values.limit_per_client;
        }

        // console.log(params);
        dispatch(fetchCreateCoupon(params));
      }, 200);
    },
  });

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);


  const headRows = [
    { id: 'check', numeric: false, disablePadding: false, label: '' },
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Preço' },
    // { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  const showCard = (entities, title = '') => {
    return <>

        <div className={classes.tableWrapper}>
          <Table
            key="tableWrapper"
                className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                aria-labelledby="tableTitle"
                size="large"
          >
            <TableHead>
              <TableRow>
                {headRows.map(row => (
                  <TableCell
                    key={row.id}
                    align="left"
                    padding={row.disablePadding ? 'none' : 'default'}
                  >
                    <span style={{
                      color: "#B5B5C3",
                      textTransform: "uppercase"
                    }}>
                    {row.label}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {entities.map((row, index) => {
                {/*const isItemSelected = isSelected(row.name);*/}
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
                        <input
                          type="checkbox"
                          key={row.id}
                          name={row.id}
                          checked={originIsChecked(row.id)}
                          onChange={() => originCheck(row.id)}
                        />
                        <span></span>
                      </label>
                    </TableCell>

                    <TableCell component="th" scope="row" padding="none" align="left">
                      {row.attributes.name}
                    </TableCell>

                    <TableCell align="left" className="flex">
                      {originType == 'day_use' && <>
                        <br />
                        {row.attributes.type && row.attributes.type == 'coupon' && <span>{row.attributes.duration} {row.attributes.duration > 1 ? 'meses' : ' mês'}</span>}
                        {row.attributes.type && row.attributes.type == 'day_use' && <span>Por dia</span>}
                      </>}

                      {(originType == 'class' || originType == 'reservation' ) && <>
                        {(row.relationships && row.relationships.prices && row.relationships.prices.length > 0) && getPrices(row.relationships.prices)}
                        {(row.relationships && row.relationships.prices && row.relationships.prices.length <= 0) && `R$ 0,00`}
                      </>}
                    </TableCell>

                    {/*<TableCell align="right">
                      <a
                        title="Ver plano"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => openEditEntity(row)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            title="Ver plano"
                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                          />
                        </span>
                      </a>
                    </TableCell>*/}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
    </>
  }

  return (<>
    <Modal
      {...props}
      size={originType ? "xl" : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !createEntityShow && !editEntityShow}
      contenStyle={{maxWidth: '1000px', backgroundColor: 'transparent !important'}}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cadastrar cupom de desconto
          </Modal.Title>
          <CloseIcon className="cursor-pointer" onClick={close} />
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class={originType ? "col-lg-6" : "col-lg-12"}>
              <Container maxWidth="sm">
                <Grid item xs={12}>
                  <span className="text-dark">
                    Selecione o tipo de serviço do cupom
                  </span>
                </Grid>
                <Grid container className={classes.label} spacing={1}>
                  <Grid item xs={3}>
                    <Button fullWidth variant={originTypeClass('reservation')} onClick={() => changeType('reservation')} color="primary" size="large">
                      Locação
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button fullWidth variant={originTypeClass('class')} onClick={() => changeType('class')} color="primary" size="large">
                      Aula
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button fullWidth variant={originTypeClass('day_use')} onClick={() => changeType('day_use')} color="primary" size="large">
                      Day use
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button fullWidth variant={originTypeClass('signature')} onClick={() => changeType('signature')} color="primary" size="large">
                      Clubinho
                    </Button>
                  </Grid>
                </Grid>

                {originType && <>
                  <Grid container className={classes.label} spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        name="name"
                        label="Nome*"
                        fullWidth
                        className={`${classes.textField} is-invalid`}
                        variant="outlined"
                        autoComplete="new-password"
                        {...formik.getFieldProps("name")}
                      />

                      {coupons.error && <RenderError error={coupons.error.name} />}

                      {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.name}</div>
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name="description"
                        label="Descrição*"
                        fullWidth
                        className={`${classes.textField} is-invalid`}
                        variant="outlined"
                        autoComplete="new-password"
                        multiline
                        rows={3}
                        maxRows={5}
                        {...formik.getFieldProps("description")}
                      />

                      {coupons.error && <RenderError error={coupons.error.description} />}

                      {formik.touched.description && formik.errors.description ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.description}</div>
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container className={classes.label} spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        select
                        label="Modalidade"
                        variant="outlined"
                        SelectProps={{
                          MenuProps: {
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            }
                          }
                        }}
                        name="selling_type"
                        fullWidth
                        className={classes.textField}
                        {...formik.getFieldProps("selling_type")}
                        disabled={originType != 'reservation'}
                      >
                        {(originType == 'reservation' || originType == 'signature') &&
                          <MenuItem key="single" value="single">{originType == 'signature' ? `Assinatura` : `Avulso`}</MenuItem>
                        }

                        {(originType == 'reservation' || originType == 'day_use' || originType == 'class') && <MenuItem key="package" value="package">Pacote de créditos</MenuItem>}
                      </TextField>

                      {coupons.error && <RenderError error={coupons.error.selling_type} />}
                    </Grid>
                  </Grid>

                  <Grid container className={classes.label} spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        select
                        label="Tipo de desconto"
                        variant="outlined"
                        SelectProps={{
                          MenuProps: {
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            }
                          }
                        }}
                        name="type"
                        fullWidth
                        className={classes.textField}
                        {...formik.getFieldProps("type")}
                      >
                        <MenuItem key="fixed" value="fixed">Valor fixo</MenuItem>
                        <MenuItem key="percentage" value="percentage">Porcentagem</MenuItem>
                      </TextField>

                      {coupons.error && <RenderError error={coupons.error.type} />}
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="value"
                        label={formik.values.type == 'fixed' ? 'Valor*' : 'Porcentagem*'}
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        InputProps={{
                          startAdornment: formik.values.type == 'fixed' && <InputAdornment position="start">R$</InputAdornment>,
                          endAdornment: formik.values.type == 'percentage' && <InputAdornment position="start">%</InputAdornment>,
                        }}
                        {...formik.getFieldProps("value")}
                      />

                      {coupons.error && <RenderError error={coupons.error.value} />}
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="valid_at"
                        label={'Data de início*'}
                        type="date"
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...formik.getFieldProps("valid_at")}
                      />

                      {coupons.error && <RenderError error={coupons.error.valid_at} />}
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="expired_at"
                        label={'Data de expiração*'}
                        type="date"
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...formik.getFieldProps("expired_at")}
                      />

                      {coupons.error && <RenderError error={coupons.error.expired_at} />}
                    </Grid>
                  </Grid>
                </>}
              </Container>
            </div>

            <div class="col-lg-6">
              <Container maxWidth="sm">
                {originType && <Grid container className={classes.label} spacing={2}>
                  <Grid item xs={12}>
                    <span className="font-weight-bolder text-dark">
                      Limite de uso
                    </span>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="limit_per_client"
                      label="Limite por cliente"
                      placeholder="Opcional"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">por cliente</InputAdornment>,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...formik.getFieldProps("limit_per_client")}
                    />

                    {coupons.error && <RenderError error={coupons.error.limit_per_client} />}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="usage_limit"
                      label="Limite de uso"
                      placeholder="Opcional"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">disponivel</InputAdornment>,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...formik.getFieldProps("usage_limit")}
                    />

                    {coupons.error && <RenderError error={coupons.error.usage_limit} />}
                  </Grid>
                  {(originType == 'reservation') && formik.values.selling_type == 'single' && <>
                    <Grid item xs={12}>
                      <span className="font-weight-bolder text-dark">
                        Restrição de Horário
                      </span>
                    </Grid>

                    <Grid container className={classes.label} spacing={1}>
                      <Grid item xs><Chip onClick={() => selectWeekDay(2)} clickable color={selectedWeekDayColor(2)} variant="outlined" label="Seg" /></Grid>
                      <Grid item xs><Chip onClick={() => selectWeekDay(4)} clickable color={selectedWeekDayColor(4)} variant="outlined" label="Ter" /></Grid>
                      <Grid item xs><Chip onClick={() => selectWeekDay(8)} clickable color={selectedWeekDayColor(8)} variant="outlined" label="Qua" /></Grid>
                      <Grid item xs><Chip onClick={() => selectWeekDay(16)} clickable color={selectedWeekDayColor(16)} variant="outlined" label="Qui" /></Grid>
                      <Grid item xs><Chip onClick={() => selectWeekDay(32)} clickable color={selectedWeekDayColor(32)} variant="outlined" label="Sex" /></Grid>
                      <Grid item xs><Chip onClick={() => selectWeekDay(64)} clickable color={selectedWeekDayColor(64)} variant="outlined" label="Sab" /></Grid>
                      <Grid item xs><Chip onClick={() => selectWeekDay(1)} clickable color={selectedWeekDayColor(1)} variant="outlined" label="Dom" /></Grid>
                    </Grid>

                    {coupons.error && <RenderError error={coupons.error.weekday} />}

                    <Grid item xs={6}>
                      <TextField
                        name="start_minutes"
                        label="Horário Inicio"
                        type="time"
                        fullWidth
                        step={15}
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: "900", // 15 min
                        }}
                        {...formik.getFieldProps("start_minutes")}
                      />

                      {coupons.error && <RenderError error={coupons.error.start_minutes} />}
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="end_minutes"
                        label="Horário Fim"
                        type="time"
                        fullWidth
                        step={15}
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: "900", // 15 min
                        }}
                        {...formik.getFieldProps("end_minutes")}
                      />

                      {coupons.error && <RenderError error={coupons.error.end_minutes} />}
                    </Grid>
                  </>}
                  <Grid item xs={12}>
                      <label>
                        {(originType == 'class' || originType == 'reservation') && `Restringir por serviço?`}
                        {(originType == 'day_use' || originType == 'signature') && `Restringir por plano?`}
                      </label>
                      <span className="switch switch-brand switch-icon">
                        <label>
                          <input type="checkbox" check={couponRestrict} onClick={() => setCouponRestrict(!couponRestrict)} name="select"/>
                        <span></span>
                      </label>
                     </span>
                  </Grid>

                  {couponRestrict && <Grid container className={classes.label} spacing={2}>
                    <Grid item xs={12}>
                      {showCard(rows)}

                      {coupons.error && <RenderError error={coupons.error.plan_id} />}

                      {/*<div className="col-xl-12 pt-2">
                        <Alert key="alert-accept-schedule" variant="secondary">
                          Nenhuma fatura será criada, caso deseje registrar o faturamento deve ser criado um pacote de créditos
                        </Alert>
                      </div>*/}
                    </Grid>
                  </Grid>}

                  {/*{couponRestrict && <>
                    <div className="row">
                      <div className="col-xl-12 pt-5">
                        <label>Escolha a data que expira os créditos</label>
                        <TextField
                          name="expired_at"
                          label={'Data de expiração'}
                          type="date"
                          fullWidth
                          className={classes.textField}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...formik.getFieldProps("expired_at")}
                        />
                      </div>
                    </div>
                  </>}*/}
                </Grid>}
              </Container>
            </div>
          </div>

          {(typeof coupons.error) == "string" && <Grid item xs={12}>
            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                  {coupons.error}
                </span>
              </div>
            </div>
          </Grid>}
        </Modal.Body>

        {<Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                size="large"
                disabled={(!originType)}
              >
                Confirmar {(coupons.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>}
      </form>
    </Modal>

    <CreateClient show={createEntityShow} onHide={() => setCreateEntityShow(false)} />
    <EditPlan readOnly={true} show={editEntityShow} onHide={() => setEditEntityShow(false)} event={editEntity} />
  </>);
};

export default CreateCoupon;