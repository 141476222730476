import * as requestFromServer from "./contractCrud";
import { contractSlice, callTypes } from "./contractSlice";
import { notifySuccess, notifyError } from "../../../utils/Notify";

const { actions } = contractSlice;

export const fetchGetContracts = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return new Promise((resolve, reject) => {
    let data = {};
    console.log(params);
    if (params.client_id) {
      data.client_id = params.client_id;
    }
    if (params.origin_id) {
      data.origin_id = params.origin_id;
    }
    if (params.branch_id) {
      data.branch_id = params.branch_id;
    }
    if (params.modality) {
      data.modality = params.modality;
    }
    if (params.type) {
      data.type = params.type;
    }
    if (params.statusId) {
      data.status_id = params.statusId;
    }
    if (params.month_start_at) {
      data.month_start_at = params.month_start_at;
    }
    if (params.start_at) {
      data.start_at = params.start_at;
    }
    if (params.finish_at) {
      data.finish_at = params.finish_at;
    }

    requestFromServer
      .getContracts(data)
      .then(response => {
        dispatch(actions.contractsFetched());
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);

        if (error.response &&
          error.response.data &&
          error.response.data.message &&
          (typeof error.response.data.message) == "string"
        ) {
          notifyError(error.response.data.message, 'contract-failed-fetch', 2000);
        }
      });
  });
};

export const fetchGetAvailableContracts = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return new Promise((resolve, reject) => {
    let data = {};

    if (params.modality) {
      data.modality = params.modality;
    }
    if (params.client_id) {
      data.client_id = params.client_id;
    }
    if (params.origin_id) {
      data.origin_id = params.origin_id;
    }
    if (params.field_id) {
      data.field_id = params.field_id;
    }
    if (params.status_id) {
      data.status_id = params.status_id;
    }
    if (params.date) {
      data.date = params.date;
    }

    requestFromServer
      .getAvailableContracts(data)
      .then(response => {
        dispatch(actions.contractsFetched());
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);
      });
  });
};

export const contractPriceClose = (id) => dispatch => {
  dispatch(actions.contractPriceClose());
}

export const fetchGetContractById = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .getContracts({
        id: id
      })
      .then(response => {
        dispatch(actions.contractsFetched());
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);
      });
  });
};

export const fetchUpdateContractPrice = (contractId, price) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateContractPrice(contractId, {
      price: price,
    })
    .then(response => {
      dispatch(actions.contractPriceUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRenewContractInvoice = (contractId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .renewContractInvoice(contractId)
    .then(response => {
      dispatch(actions.contractUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
