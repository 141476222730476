import * as requestFromServer from "./logCrud";
import { logSlice, callTypes } from "./logSlice";
import { notifyError } from "../../../utils/Notify";

const { actions } = logSlice;

export const fetchLogsEvents = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return new Promise((resolve, reject) => {
    let data = {};

    if (params.branch_id != '') {
      data.branch_id = params.branch_d;
    }

    if (params.category != '') {
      data.category = params.category;
    }

    if (params.type != '') {
      data.type = params.type;
    }

    if (params.event != '') {
      data.event = params.event;
    }

    if (params.origin_id != '') {
      data.origin_id = params.origin_id;
    }

    if (params.user_name != '') {
      data.user_name = params.user_name;
    }

    requestFromServer
      .getLogsEvents(data)
      .then(response => {
        dispatch(actions.logsFetched());

        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));

        dispatch(actions.logsFetched());
        reject(error);

        if (error.response &&
          error.response.data &&
          error.response.data.message &&
          (typeof error.response.data.message) == "string"
        ) {
          notifyError(error.response.data.message, 'log-events', 2000);
        }
      });
  });
};


export const fetchCreateObservation = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .createObservation(params)
    .then(response => {
      dispatch(actions.logCreated());
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'observation-create', 2000);
      }
    });
};