import axios from "axios";

const API_URL = 'https://viacep.com.br/ws/';

export function getCep(value) {
  return new Promise((resolve, reject) => {
    axios.get(API_URL + `/${value}/json`).then(response => {
      resolve(response.data)
    }).catch(error => {
      reject(error)
    });
  });
}
