import * as Yup from "yup";

export const createClientSchema = (props) => {
  return Yup.object().shape({
    phone: Yup.string()
      .nullable()
      .notRequired(
        'Telefone deve ser preenchido'
      ),
    email: Yup.string()
      .nullable()
      .notRequired(
        'E-mail deve ser preenchido'
      ),
    name: Yup.string()
      .required(
        'Nome deve ser preenchido'
      ),
  });
}