import React, {Component} from "react";
import { connect } from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";

import { clearFields } from "../../Field/_redux/fieldActions";
import { setBranchSelected } from "../../Branch/_redux/branchActions";

class Logout extends Component {

  componentDidMount() {
    this.props.fetchClearField();
    this.props.setBranchSelected();
    this.props.logout();
    console.log('dispatched logout');
  }

  render() {
    const { hasAuthToken } = this.props;
    console.log(hasAuthToken);

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchClearField: () => dispatch(clearFields()),
        setBranchSelected: () => dispatch(setBranchSelected()),
        logout: () => dispatch(auth.actions.logout()),
    }
};

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  mapDispatchToProps
)(Logout);
