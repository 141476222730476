import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entityForEdit: undefined,
  clientPhone: {},
  clientEmail: {},
  clientName: {},
  lastError: null,
  created: false,
  updated: false,
  photoUpdated: false,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const clientSlice = createSlice({
  name: "clients",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    clientFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.created = false;
      state.updated = false;
      state.photoUpdated = false;
    },

    clientPhoneFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.clientPhone = action.payload.clientPhone;
      state.error = null;
    },

    clientEmailFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.clientEmail = action.payload.clientEmail;
      state.error = null;
    },

    clientNameFetched: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.clientName = action.payload.clientName;
      state.error = null;
      state.created = false;
      state.updated = false;
      state.photoUpdated = false;
    },

    clientsFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.created = false;
      state.updated = false;
      state.photoUpdated = false;
      state.totalCount = entities.meta.total;
    },

    clientCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.created = true;
    },

    clientUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.updated = true;
    },

    clientPhotoUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.photoUpdated = true;
    },

    clientDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },

    clientsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },

    clientsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
