import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import ListGroup from 'react-bootstrap/ListGroup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Chip from '@material-ui/core/Chip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Alert from 'react-bootstrap/Alert';

import {
  fetchUpdateSignature,
  fetchRenewSignature,
  fetchSignature,
  clearSignature,
} from "../_redux/signatureActions";

import {
  fetchPrices,
  fetchCreatePrice,
  fetchRemovePrice,
} from "../../Price/_redux/priceActions";

import { fetchBranches } from "../../Branch/_redux/branchActions";

import { createSignatureSchema } from "../_schemas/createSignatureSchema";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import CreatePrice from '../../Price/components/CreatePrice';
import EditPrice from '../../Price/components/EditPrice';
import ShowInvoice from "../../Invoice/components/ShowInvoice";
import CancelSignature from "./CancelSignature";
import EditClient from "../../Client/components/EditClient";

import { notifySuccess } from "../../../utils/Notify";
import DayPicker from '../../../components/DayPicker';
import { formatMoney } from "../../../utils/IntegerParse";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditSignature = (props) => {
  const dispatch = useDispatch();
  const { signatures, branches, invoices } = useSelector((state) => ({
    signatures: state.signatures,
    branches: state.branches,
    invoices: state.invoices,
  }), shallowEqual);

  const classes = useStyles();

  const [editClientShow, setEditClientShow] = useState(false);
  const [editClient, setEditClient] = useState({});
  const [showInvoice, setShowInvoice] = useState(false);
  const [showCancelSignature, setShowCancelSignature] = useState(false);
  const [showEditPrice, setShowEditPrice] = useState(false);
  const [event, setEvent] = useState({});
  const [cancelDateAt, setCancelDateAt] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  useEffect(() => {
    if (signatures.updated == true) {
      close();
      setShowCancelSignature(false);
      notifySuccess('Atualizado com sucesso', 'signatures-updated', 2000);
    }
  }, [signatures]);

  useEffect(() => {
    if (signatures.renewed) {
      dispatch(fetchSignature(event.id));
    }
  }, [signatures.renewed]);

  useEffect(() => {
    if ((invoices.updated || invoices.priceUpdated) && !invoices.actionsLoading) {
      if (event.id) {
        closeShowInvoice();
        dispatch(fetchSignature(event.id));
      }
    }
  }, [invoices]);

  useEffect(() => {
    if (signatures.signature && signatures.signature.id) {
      setEvent(signatures.signature);

      formik.setFieldValue('signatureId', signatures.signature.id, false);
      formik.setFieldValue('auto_renew', signatures.signature.attributes.auto_renew == true ? 'yes' : 'no', false);
      formik.setFieldValue('price', signatures.signature.attributes.price, false);
    }
  }, [signatures.signature]);

  useEffect(() => {
    if (props.event && props.event.id && props.show) {
      setEvent(props.event);
      setCancelDateAt(new Date);

      formik.setFieldValue('signatureId', props.event.id, false);
      formik.setFieldValue('auto_renew', props.event.attributes.auto_renew == true ? 'yes' : 'no', false);
      formik.setFieldValue('price', props.event.attributes.price, false);
    } else {
      close();
    }
  }, [props.show]);

  const close = () => {
    props.onHide();

    setShowEditPrice(false);
    setShowInvoice(false);
    setShowCancelSignature(false);

    formik.setFieldValue('signatureId', '', false);
    formik.setFieldValue('auto_renew', 'no', false);
    formik.setFieldValue('price', '', false);
    setEvent({});
    dispatch(clearSignature());
  }

  const closeShowInvoice = () => {
    setShowInvoice(false);
  }

  const openShowInvoice = (invoice) => {
    setShowInvoice(true);
    setSelectedInvoice(invoice);
  }

  const getColorStatus = (invoice) => {
    if (invoice.attributes.is_canceled || invoice.attributes.is_expired) {
      return 'danger';
    }

    if (invoice.attributes.is_paid) {
      return 'success';
    }

    return 'primary';
  }

  const getStatusLabel = (invoice) => {
    let label = 'A vencer';

    if (invoice.attributes.is_canceled) {
      label = 'Cancelado';
    }

    if (invoice.attributes.is_paid) {
      label = 'Pago';
    }

    if (invoice.attributes.is_expired) {
      label = 'Vencido';
    }

    return label;
  }

  const openEditClient = (event) => {
    setEditClient(event);

    setEditClientShow(true);
  }

  const closeEditClient = () => {
    setEditClientShow(false);
  }

  const dispatchUpdatePrice = () => {
    dispatch(fetchUpdateSignature(formik.values.signatureId, { price: formik.values.price }));
  }

  const formik = useFormik({
    initialValues: {
      signatureId: '',
      price: '',
      auto_renew: 'no',
    },
    validationSchema: createSignatureSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        let params = {
          auto_renew: values.auto_renew == 'yes' ? true : false,
        };

        dispatch(fetchUpdateSignature(values.signatureId, params));

        setSubmitting(false);
      }, 500);
    },
  });

  const renew = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const headRows = [
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Mês' },
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Valor' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  const showCard = (entities, title = '') => {
    return <>

        <div className={classes.tableWrapper}>
          <Table
            key="tableWrapper"
                className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                aria-labelledby="tableTitle"
                size="large"
          >
            <TableHead>
              <TableRow>
                {headRows.map(row => (
                  <TableCell
                    key={row.id}
                    align="left"
                    padding={row.disablePadding ? 'none' : 'default'}
                  >
                    <span style={{
                      color: "#B5B5C3",
                      textTransform: "uppercase"
                    }}>
                    {row.label}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {entities.map((row, index) => {
                {/*const isItemSelected = isSelected(row.name);*/}
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.attributes.month}/{row.attributes.year}
                    </TableCell>

                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {moment(row.attributes.expired_at).format('DD/MM/YYYY')}
                    </TableCell>

                    <TableCell align="left" className="flex">
                      R$ {row.attributes.price}
                    </TableCell>

                    <TableCell align="left" className="flex">
                      <span className={`label label-lg label-light-${getColorStatus(row)} label-inline`}>
                        {getStatusLabel(row)}
                      </span>
                    </TableCell>

                    <TableCell align="right">
                      <a
                        title="Ver plano"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => openShowInvoice(row)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            title="Ver plano"
                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                          />
                        </span>
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
    </>
  }

  return (<>
    {event.id && <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !showInvoice && !showCancelSignature && !editClientShow}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editar assinatura
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7 mb-5"></div>

          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Nome</span>: {event.relationships.client.attributes.name}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">E-mail</span>: {event.relationships.client.attributes.email}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Telefone</span>: {event.relationships.client.attributes.phone}
                    </span>
                  </div>
                </div>
              </Grid>

              {!props.hideClient && <Grid item xs={12}>
                <a class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-5" onClick={() => openEditClient(event.relationships.client)}>
                  Ver cliente
                </a>
              </Grid>}
            </Grid>
          </Container>

          <Container maxWidth="sm">
            <Grid container className="mt-5">
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7"></div>

          <Container maxWidth="sm">
            <Grid container className={classes.label}>
              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Plano</span>: {event.relationships.plan.attributes.name}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Renovação</span>: todo dia {event.attributes.billing_day}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Preço mensal</span>
                        : {event.attributes && event.attributes.price && formatMoney(event.attributes.price)}
                        {!showEditPrice && <span class="cursor-pointer ml-2" onClick={() => setShowEditPrice(!showEditPrice)}>
                          <i class="fas fa-edit icon-nm text-dark-50"></i>
                        </span>}
                    </span>
                  </div>
                </div>
              </Grid>

              {showEditPrice && <>
                <div className="separator separator-dashed mb-5 mt-1"></div>

                <Grid container className={classes.label} spacing={2}>

                  <Grid item xs={12}>
                    <Alert key="alert-accept-schedule" variant="secondary">
                      Somente na próxima fatura será refletivo o novo valor, você também pode editar as faturas existentes.
                    </Alert>
                  </Grid>

                  <div class="col-lg-6 py-1">
                    <TextField
                      name="price"
                      label={'Valor do plano'}
                      fullWidth
                      className={classes.textField}
                      placeholder="ex: 260"
                      variant="outlined"
                      type="number"
                      min="1"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      }}
                      {...formik.getFieldProps("price")}
                    />
                  </div>

                  <div class="col-lg-6 py-1 flex-column align-items-center">
                    <span class="label label-xl font-weight-boldest label-success mt-5 cursor-pointer" onClick={() => dispatchUpdatePrice()}>
                      <i class="ki ki-check text-white"></i>
                    </span>
                    <span class="label label-xl font-weight-boldest label-danger mt-5 ml-2 cursor-pointer" onClick={() => setShowEditPrice(false)}>
                      <i class="ki ki-close text-white"></i>
                    </span>
                  </div>
                </Grid>
              </>}

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Início da assinatura</span>: {moment(event.attributes.start_at).format('DD/MM/YYYY')}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block mb-5">
                    <span className="pr-5">
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark-50">Válido até</span>: {moment(event.attributes.finish_at).format('DD/MM/YYYY')}
                      </span>
                    </span>
                  </div>

                  {event.attributes.remaining_days < 15 && event.attributes.remaining_days >= 0 && <Grid item xs={12}>
                    <Alert key="alert-remaining-days" variant={event.attributes.remaining_days == 0 ? "primary" : "secondary"}>
                      Clubinho vence <span className="font-weight-bold">
                        {event.attributes.remaining_days > 0 && `${event.attributes.remaining_days == 1 ? `amanhã` : `em ${event.attributes.remaining_days} dias`}`}
                        {event.attributes.remaining_days == 0 && `hoje`}
                      </span>
                    </Alert>
                  </Grid>}

                  {event.attributes.canceled_at && <Grid item xs={12}>
                    <Alert key="alert-signature-canceled" variant="warning">
                      Clubinho finalizado em {moment(event.attributes.canceled_at).format('DD/MM/YYYY')}
                    </Alert>
                  </Grid>}

                  {(event && event.relationships && event.relationships.status.attributes.name != 'canceled') && <Tooltip title="Renovação manual" placement="top">
                    <a href="#" className="btn btn-light-success font-weight-bold" onClick={() => dispatch(fetchRenewSignature(event.id))}>
                      <i className="ki ki-plus "></i> Renovar ({event.relationships.plan.attributes.duration} {event.relationships.plan.attributes.duration > 1 ? 'meses' : ' mês'})
                    </a>
                  </Tooltip>}
                </div>
              </Grid>
            </Grid>
          </Container>


          <Container maxWidth="sm">
            <Grid container className="mt-5">
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Renovação automatica</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7"></div>

          <Container maxWidth="sm">
            <Grid container className={classes.label}>
              <Grid item xs={12}>
                <div className="fv-plugins-message-container pb-3">
                  <div className="fv-block">
                    Quando a assinatura estiver próximo do vencimento será renovado automaticamente, criando uma nova fatura e extendendo a assinatura
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  type="hidden"
                  disabled={true}
                  name="signatureId"
                  {...formik.getFieldProps("signatureId")}
                />

                <TextField
                  select
                  label="Renovação automatica?"
                  variant="outlined"
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      }
                    }
                  }}
                  name="auto_renew"
                  fullWidth
                  className={classes.textField}
                  {...formik.getFieldProps("auto_renew")}
                >
                  {renew.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                </TextField>
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="sm">
            <Grid container className="mt-5">
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Faturas</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7"></div>

          <Container maxWidth="sm">
            <Grid container className={classes.label}>
              <Grid item xs={12}>
                {showCard(event.relationships.invoices)}
              </Grid>

              {(typeof signatures.error) == "string" && <Grid item xs={12}>
                <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                      {signatures.error}
                    </span>
                  </div>
                </div>
              </Grid>}
            </Grid>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{textAlign: 'right'}}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                size="large"
                disabled={formik.isSubmitting || props.readOnly || (event && event.relationships && event.relationships.status.attributes.name == 'canceled')}
              >
                Salvar {(formik.isSubmitting || signatures.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs={4} style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
            <Grid item xs={2} style={{textAlign: 'right'}}>
              {!props.readOnly && event && event.relationships && event.relationships.status.attributes.name != 'canceled' && <Tooltip title="Cancelar assinatura" placement="top">
                <IconButton variant="outlined" aria-label="delete" onClick={() => setShowCancelSignature(true)} disableFocusRipple={true}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>}

    <ShowInvoice show={showInvoice} onHide={() => closeShowInvoice()} invoice={selectedInvoice} />
    <CancelSignature show={showCancelSignature} onHide={() => setShowCancelSignature(false)} event={event} />
    <EditClient show={editClientShow} onHide={closeEditClient} event={editClient} />
  </>);
};

export default EditSignature;