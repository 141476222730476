import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entityForEdit: undefined,
  signature: {},
  lastError: null,
  created: false,
  updated: false,
  renewed: false,
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const signatureSlice = createSlice({
  name: "signatures",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    loadingFinish: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.created = false;
      state.updated = false;
    },

    signaturesFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.created = false;
      state.updated = false;
      state.totalCount = 0;

      if (entities && entities.meta && entities.meta.total) {
        state.totalCount = entities.meta.total;
      }
    },

    signatureFetched: (state, action) => {
      state.listLoading = false;
      state.signature = action.payload.signature;
      state.created = false;
      state.updated = false;
      state.renewed = false;
      state.error = null;
    },

    // signatureEmailFetched: (state, action) => {
    //   state.actionsLoading = false;
    //   state.signatureEmail = action.payload.signatureEmail;
    //   state.error = null;
    // },

    // signatureNameFetched: (state, action) => {
    //   state.actionsLoading = false;
    //   state.signatureName = action.payload.signatureName;
    //   state.error = null;
    //   state.created = false;
    //   state.updated = false;
    // },

    // signaturesFetched: (state, action) => {
    //   const { entities } = action.payload;
    //   state.listLoading = false;
    //   state.error = null;
    //   state.entities = entities;
    //   state.created = false;
    //   state.updated = false;
    //   state.totalCount = entities.meta.total;
    // },

    signatureCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.created = true;
    },

    signatureUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.updated = true;
    },

    signatureRenewed: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.renewed = true;
    },

    signatureDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },

    // signaturesDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(
    //     el => !action.payload.ids.includes(el.id)
    //   );
    // },

    // signaturesStatusUpdated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   const { ids, status } = action.payload;
    //   state.entities = state.entities.map(entity => {
    //     if (ids.findIndex(id => id === entity.id) > -1) {
    //       entity.status = status;
    //     }
    //     return entity;
    //   });
    // }
  }
});
