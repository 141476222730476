import * as Yup from "yup";

// userId

// branchesId
// status
export const createUserSchema = (props) => {
  return Yup.object().shape({
    name: Yup.string().required("Nome deve ser preenchido"),
    email: Yup.string().required("E-mail deve ser preenchido"),
    status: Yup.string().required("O status deve ser preenchido"),
    permission: Yup.array().of(Yup.string()),
    branchesId: Yup.array()
      .of(Yup.string())
      .min(1, "O usuário deve estar associado a pelo menos 1 campo")
      .required("O usuário deve estar associado a pelo menos 1 campo"),
  });
};
