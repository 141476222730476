import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import { useFormik } from "formik";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';

import { checkIsActive, toAbsoluteUrl } from "../../../../_metronic/_helpers";

import { fetchBranchRecipients } from "../../BranchRecipient/_redux/branchRecipientActions";

import { fetchBranchAppIntegration } from "../../Branch/_redux/branchActions";

import { BranchRecipientPage } from '../../BranchRecipient/pages/BranchRecipientPage';
import { WithdrawalPage } from '../../Withdrawal/pages/WithdrawalPage';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
}));

export const SettingsPage = () => {
  const { branchRecipients, branchSelected } = useSelector((state) => ({
    branchRecipients: state.branchRecipients,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const [branchIntegration, setBranchIntegration] = useState({});

  const requestBranchRecipients = async () => {
    dispatch(fetchBranchRecipients(branchSelected.id));
  };

  const requestBranchAppIntegration = async () => {
    dispatch(fetchBranchAppIntegration(branchSelected.id)).then(value => {
      if (value) {
        setBranchIntegration(value);
      } else {
        setBranchIntegration({});
      }
    });
  };

  useEffect(() => {
    requestBranchRecipients();
    requestBranchAppIntegration();
  }, []);

  useEffect(() => {
    requestBranchRecipients();
    requestBranchAppIntegration();
  }, [branchSelected]);

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url);
  }

  const [step, setStep] = useState(1);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const initialValues = {
    name: '',
    email: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 10);
    },
    onReset: (values, { resetForm }) => {

    },
  });

  return (<>
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <span>Configurações</span>
        </div>
      </div>
      <div className={'card-body'}>
        <div className="row">
          <div className="col-lg-3">
            <ul className="navi navi-link-rounded navi-accent navi-hover navi-active nav flex-column mb-8 mb-lg-0" role="tablist">
              {/*<li className="navi-item mb-2">
                <NavLink className={`navi-link ${getMenuItemActive('/configuracoes/empresa') && `active`}`} to="/configuracoes/empresa">
                  <span className={`navi-text ${getMenuItemActive('/configuracoes/empresa') ? 'text-dark' : 'text-dark-50'} font-size-h5 font-weight-bold`}>Empresa</span>
                </NavLink>
              </li>*/}
              <li className="navi-item mb-2">
                <NavLink className={`navi-link ${getMenuItemActive('/configuracoes/aplicativo') && `active`}`} to="/configuracoes/aplicativo">
                  <span className={`navi-text ${getMenuItemActive('/configuracoes/aplicativo') ? 'text-dark' : 'text-dark-50'} font-size-h5 font-weight-bold`}>Aplicativo</span>
                </NavLink>
              </li>
              <li className="navi-item mb-2">
                <NavLink className={`navi-link ${getMenuItemActive('/configuracoes/conta-bancaria') && `active`}`} to="/configuracoes/conta-bancaria">
                  <span className={`navi-text ${getMenuItemActive('/configuracoes/conta-bancaria') ? 'text-dark' : 'text-dark-50'} font-size-h5 font-weight-bold`}>
                    Conta bancária
                    {!branchRecipients.entity && <span className="label label-light-info label-inline font-weight-bold px-3 py-3 ml-2 mx-2 text-right">
                      ação necessária
                    </span>}
                  </span>
                </NavLink>
              </li>
              <li className="navi-item mb-2">
                <NavLink className={`navi-link ${getMenuItemActive('/configuracoes/saldo') && `active`}`} to="/configuracoes/saldo">
                  <span className={`navi-text ${getMenuItemActive('/configuracoes/saldo') ? 'text-dark' : 'text-dark-50'} font-size-h5 font-weight-bold`}>Saldo</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-lg-9">
            {getMenuItemActive('/configuracoes/aplicativo') && <>
              <div className="row">
                <label className="col-xl-3"></label>
                <div className="col-lg-9 col-xl-6">
                  <h5 className="font-weight-bold mb-6">Aplicativo:</h5>
                </div>
              </div>
              <div className="form-group row align-items-center">
                <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Listar unidade</label>
                <div className="col-lg-9 col-xl-6">
                  <span className="switch switch-sm">
                    <label>
                      <input type="checkbox" checked={branchIntegration?.attributes?.verified} name="email_notification_1" readOnly />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
              {/*<div className="form-group row align-items-center">
                <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Permitir vendas</label>
                <div className="col-lg-9 col-xl-6">
                  <span className="switch switch-sm">
                    <label>
                      <input type="checkbox" checked="checked" name="email_notification_2" />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>*/}
              <div className="separator separator-dashed my-10"></div>

              <div className="row">
                <label className="col-xl-3"></label>
                <div className="col-lg-9 col-xl-6">
                  <h5 className="font-weight-bold mb-6">Vendas:</h5>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Serviços a venda</label>
                <div className="col-lg-9 col-xl-6">
                  <div className="checkbox-list">
                    <label className="checkbox">
                    <input type="checkbox" checked={branchIntegration?.attributes?.services.includes("reservation")} name="reservation" disabled className="disabled" />
                    <span></span>Locação avulsa</label>
                    <label className="checkbox">
                    <input type="checkbox" checked={branchIntegration?.attributes?.services.includes("day_use")} name="day_use" disabled className="disabled" />
                    <span></span>Pacote de créditos</label>
                    <label className="checkbox">
                    <input type="checkbox" checked={branchIntegration?.attributes?.services.includes("reservation_credit_purchase")} name="reservation_credit_purchase" disabled className="disabled" />
                    <span></span>Pacote de aulas</label>
                    <label className="checkbox">
                    <input type="checkbox" checked={branchIntegration?.attributes?.services.includes("signature")} name="signature" disabled className="disabled" />
                    <span></span>Day use</label>
                    <label className="checkbox">
                    <input type="checkbox" checked={branchIntegration?.attributes?.services.includes("class_credit_purchase")} name="class_credit_purchase" disabled className="disabled" />
                    <span></span>Clubinho</label>
                  </div>
                </div>
              </div>
            </>}

            {getMenuItemActive('/configuracoes/conta-bancaria') && <BranchRecipientPage />}
            {getMenuItemActive('/configuracoes/saldo') && <WithdrawalPage />}
          </div>
        </div>
      </div>
    </div>
  </>);
}
