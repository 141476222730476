import React, { useState, useEffect } from "react";

import { lighten, makeStyles } from '@material-ui/core/styles';

import { CreditPurchaseList } from '../components/CreditPurchaseList';

const useStyles = makeStyles(theme => ({
  //
}));

export const CreditPurchasePage = () => {
  const classes = useStyles();
  return (<>
      <CreditPurchaseList />
  </>);
}
