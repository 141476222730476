import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SVG from "react-inlinesvg";
import { useFormik } from "formik";

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import { lighten, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers";

import CreateClient from '../components/CreateClient';
import EditClient from '../components/EditClient';
import { formatPhoneToString } from "../../../utils/PhoneParse";

import { fetchClients, fetchinstructors } from "../_redux/clientActions";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Nome' },
  { id: 'attributes.email', numeric: true, disablePadding: false, label: 'Nível' },
  { id: 'attributes.phone', numeric: true, disablePadding: false, label: 'Telefone' },
  { id: 'attributes.relationships.status.attributes.display_name', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'actions', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all desserts' }}
          />
        </TableCell>*/}
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align="left"
            // align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <span style={{
                color: "#B5B5C3",
                textTransform: "uppercase"
              }}>
              {row.label}
              </span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (<>
          {/*<Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>*/}
        </>)}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export const ClientPage = () => {
  const { clients, branchSelected } = useSelector((state) => ({
    clients: state.clients,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();
  const [type, setType] = useState();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('attributes.name');
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [branchFilter, setBranchFilter] = useState(true);
  const [editEntity, setEditEntity] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    requestClients();
  }, []);

  useEffect(() => {
    setType(checkIsActive(location, '/clientes') ? 'client' : 'instructor');
  }, [location.pathname]);

  useEffect(() => {
    requestClients();
  }, [rowsPerPage, page, type, branchFilter, branchSelected]);

  useEffect(() => {
    if (clients.entities.data) {
      setRows(clients.entities.data);
    }
  }, [clients]);

  const isClient = () => {
    return (type == 'client') ? true : null;
  }

  const requestClients = async () => {
    try {
      let name = formik.values.name;

      if (type) {
        let filter = branchFilter ? true : false;

        if (isClient()) {
          dispatch(fetchClients(rowsPerPage, page + 1, name, branchSelected.id, filter));
        } else {
          dispatch(fetchinstructors(rowsPerPage, page + 1, name, branchSelected.id, filter));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeCreateEntity = () => {
    {createEntityShow &&
      requestClients();
    };

    setCreateEntityShow(false);
  };

  const openEditClient = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }

  const closeEditEntity = () => {
    {editEntityShow &&
      requestClients();
    };

    setEditEntityShow(false);
  }

  const getClassStatus = (status) => {
    if (status == 'pending') {
      return 'warning';
    }

    if (status == 'blocked') {
      return 'danger';
    }

    return 'success';
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const isSelected = name => selected.indexOf(name) !== -1;

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
  });

  const searchClientName = () => {
    const clientFetch = setTimeout(() => {
      setPage(0);
      requestClients();
    }, 1000);

    return () => {
      clearTimeout(clientFetch);
    }
  }

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);

  return (<>
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <span>{isClient() ? 'Clientes' : 'Professores'}</span>
        </div>
        <div className="card-toolbar">
          <a href="#" className="btn btn-light-primary font-weight-bold" onClick={() => setCreateEntityShow(true)}>
            <i className="ki ki-plus "></i> Cadastrar
          </a>
        </div>

      </div>
      <div className="card-body pt-10 pb-0">
        <Grid container>
          <Grid item lg={4} xs={12}>
            <TextField
              name="name"
              label="Pesquisar por nome"
              fullWidth
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {clients.listLoading && <CircularProgress size={30} />}
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps("name")}
            />
          </Grid>
          <Grid item lg={8} xs={12}>
            <div class="w-100 align-self-end mt-3">
              <ul class="nav nav-pills nav-pills-sm nav-dark-75 float-right pr-0" role="tablist">
                <li class="nav-item">
                  <a class={branchFilter == false ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setBranchFilter(false)}>
                    <span class="nav-text font-size-sm">Todas unidades</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class={branchFilter == true ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setBranchFilter(true)}>
                    <span class="nav-text font-size-sm">
                      Esta unidade
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={clients.listLoading ? 'card-body filtering' : 'card-body'}>
        {/*<EnhancedTableToolbar numSelected={selected.length} />*/}
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="large"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={clients.totalCount}
            />
            <TableBody>
              {/*{stableSort(rows, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {*/}
              {stableSort(rows, getSorting(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.attributes.name}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <div class="d-flex align-items-center">
                          <div class="symbol symbol-40 symbol-sm flex-shrink-0">
                           <img class="" src={row.attributes.photo ?? toAbsoluteUrl('/media/users/blank.png')} alt="photo" />
                          </div>
                          <div class="ml-4">
                            <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">
                              {row.attributes.name}

                              {row.attributes.is_dependent && <span className={`ml-2 label label-lg label-light label-inline`}>
                                Dependente
                              </span>}

                              {row.attributes.is_member && <span className={`ml-2 label label-lg label-light-primary label-inline`}>
                                Aluno
                              </span>}
                            </div>
                            <span class="text-muted font-weight-bold text-hover-primary">
                              {row.attributes.email}
                              {!row.attributes.email && `-`}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div class="d-flex flex-column">
                          {row.attributes.skills.map(skill => <span key={skill.skill_label} class="text-muted font-weight-bold text-hover-primary">
                            {skill.sport_label} - {skill.skill_label}
                          </span>)}
                          {row.attributes.skills.length == 0 && `-`}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {row.attributes.phone && formatPhoneToString(row.attributes.phone)}
                        {!row.attributes.phone && `-`}
                      </TableCell>
                      <TableCell align="left">
                        {!row.attributes.verified && row.relationships.status && row.relationships.status.attributes && row.relationships.status.attributes.name && <span className={`label label-lg label-light-${getClassStatus(row.relationships.status.attributes.name)} label-inline`}>
                          {row.relationships.status.attributes.display_name}
                        </span>}

                        {row.attributes.verified && <span className={`label label-lg label-light-info label-inline`}>
                          Aplicativo
                        </span>}
                      </TableCell>
                      <TableCell align="right">
                        <a
                          title="Edit customer"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                          onClick={() => openEditClient(row)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                            />
                          </span>
                        </a>

                        {/*<a
                          title="Delete customer"
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          // onClick={() => openDeleteCustomerDialog(row.id)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                          </span>
                        </a>*/}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/*{emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}*/}
            </TableBody>
          </Table>
        </div>

        <div className="d-flex align-items-center py-3">
          {clients.listLoading && <div className="d-flex align-items-center">
            <div className="mr-2 text-muted">Carregando...</div>
            <div className="spinner spinner-primary mr-10"></div>
          </div>}

          <TablePagination
            labelRowsPerPage="Por página"
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={clients.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima Página',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={(page) =>
              `${page.from} até ${page.to === -1 ? page.count : page.to} total de ${
                page.count
              }`
            }
          />
        </div>
      </div>

      <CreateClient show={createEntityShow} onHide={closeCreateEntity} type={type} />

      <EditClient show={editEntityShow} onHide={closeEditEntity} event={editEntity} type={type} />
    </div>
  </>);
}
