import axios from "axios";

export const GET_PLANS = `${process.env.REACT_APP_API_URL}/plans`;
export const CREATE_PLAN = `${process.env.REACT_APP_API_URL}/plans`;
export const UPDATE_PLAN = `${process.env.REACT_APP_API_URL}/plans`;

export function getPlans(status, type = '', day = '', branchId = '') {
  let params = {
    status_id: status
  };

  if (type) {
    params.type = type;
  }

  if (day) {
    params.day = day;
  }

  if (branchId) {
    params.branch_id = branchId;
  }

  return axios.get(GET_PLANS, { params: params});
}

export function createPlan(params) {
  return axios.post(CREATE_PLAN, params);
}

export function updatePlan(planId, params) {
  return axios.put(`${UPDATE_PLAN}/${planId}`, params);
}

export function deletePlan(planId) {
  return axios.delete(`${UPDATE_PLAN}/${planId}`);
}