import axios from "axios";

export const GET_CREDITS = `${process.env.REACT_APP_API_URL}/credits`;
export const CREATE_CREDITS = `${process.env.REACT_APP_API_URL}/credits`;
export const UPDATE_CREDITS = `${process.env.REACT_APP_API_URL}/credits`;

export function getCredits(params) {
  return axios.get(GET_CREDITS, { params: params });
}

export function getAvailableCredits(clientId, params) {
  return axios.get(`${GET_CREDITS}/${clientId}`, { params: params });
}

export function getClientCredits(clientId, branchId) {
  let params = {
    client_id: clientId,
    branch_id: branchId,
  }

  return axios.get(GET_CREDITS, { params: params });
}

export function getCreditById(creditId) {
  return axios.get(`${GET_CREDITS}/${creditId}`);
}

export function renewCredit(creditId) {
  return axios.post(`${GET_CREDITS}/${creditId}/renew`);
}

export function createCredit(params) {
  return axios.post(CREATE_CREDITS, params);
}

export function updateCredit(creditId, params) {
  return axios.put(`${UPDATE_CREDITS}/${creditId}`, params);
}

export function deleteCredit(creditId, params) {
  return axios.delete(`${UPDATE_CREDITS}/${creditId}`, {data: params});
}
