import axios from "axios";

export const UPDATE_PASSWORD = `${process.env.REACT_APP_API_URL}/auth/password`;

export function updatePassword(password, passwordConfirmation) {
  let params = {
    password: password,
    password_confirmation: passwordConfirmation,
  };

  return axios.patch(UPDATE_PASSWORD, params);
}