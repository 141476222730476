import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  // error: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const reportSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error.response &&
          action.payload.error.response.data &&
          action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    reportsFetched: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
    },
  }
});
