import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import moment from 'moment/min/moment-with-locales';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import { lighten, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';

import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers";
import { formatMoney } from "../../../utils/IntegerParse";

import {
  getWeekDaysAbbreviated
} from "../../../components/DateUtils";

import DayPicker from '../../../components/DayPicker';
import SearchClient from "../../Client/components/SearchClient";

import CreateCoupon from "./CreateCoupon";
import EditCoupon from "./EditCoupon";

import { fetchCoupons } from "../_redux/couponActions";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export const CouponList = (props) => {
  const { coupons, branchSelected } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
    coupons: state.coupons,
  }), shallowEqual);

  const classes = useStyles();

  const dispatch = useDispatch();
  const location = useLocation();

  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState('active');
  const [originType, setOriginType] = useState('');
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [clientId, setClientId] = useState('');
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [sort, setSort] = useState('client_name');
  const [hideClient, setHideClient] = useState(false);

  useEffect(() => {
    requestEntities();
  }, [rowsPerPage, page, status, branchSelected, clientId, originType]);

  useEffect(() => {
    if (coupons.entities) {
      setRows(coupons.entities.data);
    }
  }, [coupons]);

  useEffect(() => {
    if (props.onlyClientId) {
      setHideClient(true);
    } else {
      setHideClient(false);
    }
  }, [props.onlyClientId]);

  useEffect(() => {
    console.log(props);
  }, [props]);

  useEffect(() => {
    if (coupons.created == true) {
      closeCreateEntity();
      openEditEntity(coupons.coupon);
    }
  }, [coupons.created]);

  const requestEntities = async () => {
    try {
      dispatch(fetchCoupons({
        perPage: rowsPerPage,
        page: page + 1,
        statusId: status,
        branchId: branchSelected.id,
        clientId: props.onlyClientId ?? clientId,
        originType: originType
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const closeCreateEntity = () => {
    {createEntityShow &&
      requestEntities();
    };

    setCreateEntityShow(false);
  };

  const openEditEntity = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }

  const closeEditEntity = () => {
    {editEntityShow &&
      requestEntities();
    };

    setEditEntityShow(false);
  }

  const getLabelDate = (day) => {
    if (day <= 3) {
      return 'danger';
    }

    if (day > 3 && day <= 10) {
      return 'warning';
    }

    return 'primary';
  }

  const getLabelType = (value) => {
    if (value == 'branch') {
      return 'Criado pelo operador';
    }

    if (value == 'purchase') {
      return 'Pacote de crédito';
    }

    if (value == 'refund') {
      return 'Reposição';
    }

    return '';
  }

  const selectClient = (value) => {
    setPage(0);
    setClientId(value);
  }

  const handleRequestSort = (property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    setSort(isDesc ? property : `-${property}`);
  }

  const headRows = [
    { id: 'client_name', ordered: false, disablePadding: false, label: 'Nome' },
    { id: 'origin', ordered: false, disablePadding: false, label: 'Tipo' },
    { id: 'origin.name', ordered: false, disablePadding: false, label: 'Desconto' },
    { id: 'origin_type', ordered: false, disablePadding: true, label: 'Limite de uso' },
    { id: 'restrictions', ordered: false, disablePadding: false, label: 'Horário' },
    { id: 'start_at', ordered: false, disablePadding: false, label: 'Início' },
    { id: 'finish_at', ordered: false, disablePadding: false, label: 'Expiração' },
    { id: 'is_expired', ordered: false, disablePadding: false, label: 'Status' },
    { id: 'actions', ordered: false, disablePadding: false, label: '' },
  ];

  return (<>
    <div class="d-flex">
      <div class="col-6">
        <h3 class="card-title">Cupons de desconto</h3>
      </div>

      {!props.disableCreate && <div class="col-6 text-right">
        <a href="#" className="btn btn-light-success font-weight-bold" onClick={() => setCreateEntityShow(true)}>
          <i className="ki ki-plus "></i> Adicionar cupom
        </a>
      </div>}
    </div>

    <div className="separator separator-dashed mt-1 mb-10"></div>

    {/*<div class="row">
      <div class="col-lg-6">
        {!props.disableSearch && <SearchClient onChange={(value) => selectClient(value)} />}
      </div>
      <div class="col-lg-4">
        <select
          class="custom-select form-control form-control-solid form-control-sm"
          value={originType}
          onChange={(value) => setOriginType(value.target.value)}
        >
          <option value="">Todos</option>
          <option value="day_use">Day use</option>
          <option value="class">Aula</option>
          <option value="reservation">Locação</option>
        </select>
        <span className="form-text text-muted">Tipo de crédito</span>
      </div>
    </div>*/}

    <div class="row pb-3">
      <div class="col-lg-6"></div>
      <div class="col-lg-6 align-self-end">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75 float-right pr-0" role="tablist">
          <li class="nav-item">
            <a class={status == 'active' ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setStatus('active')}>
              <span class="nav-text font-size-sm">Ativos</span>
            </a>
          </li>
          <li class="nav-item">
            <a class={status == 'canceled' ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setStatus('canceled')}>
              <span class="nav-text font-size-sm">Expirados</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div className={classes.tableWrapper}>
      <div className="table-responsive">
        <Table
          className="table table-head-custom table-head-bg table-borderless table-vertical-center"
          aria-labelledby="tableTitle"
          size="large"
        >
          <TableHead>
            <TableRow>
              {headRows.map(row => <>
                {!(row.id == 'client_name' && hideClient) && <TableCell
                  key={row.id}
                  align="left"
                  padding={row.disablePadding ? 'none' : 'default'}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={() => handleRequestSort(row.id)}
                    disabled={!row.ordered}
                  >
                    <span style={{
                      color: "#B5B5C3",
                      textTransform: "uppercase"
                    }}>
                    {row.label}
                    </span>
                  </TableSortLabel>
                </TableCell>}
              </>)}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows && rows.map((row, index) => {
              return <TableRow
                tabIndex={-1}
                key={'couponos-' + row.id}
              >
                {!hideClient && <TableCell component="th" scope="row" padding="none">
                  {row.attributes.name}
                </TableCell>}
                <TableCell component="th" scope="row" padding="none">
                  {row.attributes.selling_modality == 'reservation' && `Locação`}
                  {row.attributes.selling_modality == 'day_use' && `Day use`}
                  {row.attributes.selling_modality == 'class' && `Aula`}
                  {row.attributes.selling_modality == 'signature' && `Clubinho`}
                  <span style={{
                    color: "#B5B5C3",
                    textTransform: "uppercase"
                  }}>
                    <br />
                    {row.attributes.selling_type == 'single' && `Avulso`}
                    {row.attributes.selling_type == 'package' && `Pacote`}
                  </span>
                </TableCell>
                <TableCell align="left">
                  {row.attributes.type == 'fixed' && formatMoney(row.attributes.value)}
                  {row.attributes.type == 'percentage' && `${row.attributes.value}% OFF`}
                </TableCell>
                <TableCell align="left">
                  {row.attributes.limit_per_client && `Por cliente: ${row.attributes.limit_per_client}`}
                  {row.attributes.limit_per_client && row.attributes.usage_limit && <br />}
                  {row.attributes.usage_limit && `Total: ${row.attributes.usage_limit}`}
                  {!row.attributes.limit_per_client && !row.attributes.usage_limit && `-`}
                </TableCell>
                <TableCell align="left">
                  {row.attributes.weekdays?.length > 0 && <>
                    {getWeekDaysAbbreviated(row.attributes.weekdays)}
                  </>}
                  {row.attributes.start_at && row.attributes.end_at && <span style={{
                    color: "#B5B5C3",
                    textTransform: "uppercase"
                  }}>
                    <br />{row.attributes.start_at} até {row.attributes.end_at}
                  </span>}
                  {!row.attributes.start_at && !row.attributes.end_at && row.attributes.weekdays.length == 0 && `-`}
                </TableCell>
                <TableCell align="left">
                  {row.attributes.valid_at && <span>
                    {moment(row.attributes.valid_at).format('DD/MM/YYYY')}<br />
                    {moment(row.attributes.valid_at).format('HH:mm')}
                  </span>}
                  {!row.attributes.valid_at && `-`}
                </TableCell>
                <TableCell align="left">
                  {row.attributes.expired_at && <span>
                    {moment(row.attributes.expired_at).format('DD/MM/YYYY')}<br />
                    {moment(row.attributes.expired_at).format('HH:mm')}
                  </span>}
                  {!row.attributes.expired_at && `-`}
                </TableCell>
                <TableCell align="left">
                  {!row.attributes.is_expired && <span className={`label label-lg label-light-success label-inline`}>
                    Ativo
                  </span>}
                  {row.attributes.is_expired && <span className={`label label-lg label-light-danger label-inline`}>
                    Expirado
                  </span>}
                </TableCell>
                <TableCell align="right">
                  <a
                    title="Editar cupom"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={() => openEditEntity(row)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        title="Editar cupom"
                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                      />
                    </span>
                  </a>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </div>
    </div>

    <div className="d-flex align-items-center py-3">
      {coupons.listLoading && <div className="d-flex align-items-center">
        <div className="mr-2 text-muted">Carregando...</div>
        <div className="spinner spinner-primary mr-10"></div>
      </div>}

      {/*<TablePagination
        labelRowsPerPage="Por página"
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={coupons.totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Página anterior',
        }}
        nextIconButtonProps={{
          'aria-label': 'Próxima Página',
        }}
        onChangePage={(event, newPage) => setPage(newPage)}
        onChangeRowsPerPage={(event) => setRowsPerPage(+event.target.value)}
        labelDisplayedRows={(page) =>
          `${page.from} até ${page.to === -1 ? page.count : page.to} total de ${
            page.count
          }`
        }
      />*/}
    </div>

    <CreateCoupon
      show={createEntityShow}
      onHide={closeCreateEntity}
      style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
    />

    <EditCoupon show={editEntityShow} onHide={closeEditEntity} event={editEntity} hideClient={hideClient} />
  </>);
}
