/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export function Box({
  icon = "Layout/Layout-4-blocks",
  className = "bg-primary",
  baseColor = "white",
  iconColor = "white",
  widgetHeight = "150px",
  value = "",
  description = "",
  fontSize = "h2",
}) {
  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className="card-body">
          {icon && <span className={`svg-icon svg-icon-3x svg-icon-${iconColor} ml-n2`}>
            <SVG src={toAbsoluteUrl(`/media/svg/icons/${icon}.svg`)} />
          </span>}
          <div
            className={`text-${baseColor} font-weight-bolder font-size-${fontSize} mt-3`}
          >
            {value}
          </div>

          <a
            href="#"
            className={`text-${baseColor} font-weight-bold font-size-lg mt-1`}
          >
            {description}
          </a>
        </div>
      </div>
    </>
  );
}
