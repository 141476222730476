import * as Yup from "yup";

export const resetPasswordConfirmNewPasswordSchema = (props) => {
  return Yup.object().shape({
    password: Yup.string()
      .required("O campo senha é obrigatório")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "A nova senha deve ter no mínimo 8 caracteres com pelo menos 1 maiúscula, 1 minúscula e 1 caractere especial."
      ),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "As senhas não correspondem.")
      .required("O campo confirmar senha é obrigatório"),
  });
};
