import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import SVG from "react-inlinesvg";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  Grid,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";

import { toAbsoluteUrl, checkIsActive } from "../../../../../_metronic/_helpers";
import { formatPhoneToString } from "../../../../utils/PhoneParse";
import CreateUser from "../components/CreateUser";
import EditUser from "../components/EditUser";
import {
  fetchUsers,
  fetchinstructors
} from "../_redux/userActions";
import { fetchPermissions } from "app/modules/Configuration/Permission/_redux/permissionActions";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "attributes.name",
    numeric: false,
    disablePadding: true,
    label: "Nome",
  },
  {
    id: "attributes.email",
    numeric: false,
    disablePadding: true,
    label: "Telefone",
  },
  {
    id: "relationships.status.attributes.display_name",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  { id: "actions", numeric: false, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align="left"
            // align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              <span
                style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase",
                }}
              >
                {row.label}
              </span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle"></Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            {/*<Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>*/}
          </>
        )}
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 14,
  },
}));

export const UserPage = () => {
  const { users, branchSelected } = useSelector(
    (state) => ({
      users: state.users,
      branchSelected: state.auth.branchSelected,
    }),
    shallowEqual
  );
  const location = useLocation();
  const classes = useStyles();

  const [pagination] = useState(false);
  const [type, setType] = useState('');
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("attributes.name");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [branchFilter, setBranchFilter] = useState(true);
  const [editEntity, setEditEntity] = useState({});
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
  });

  useEffect(() => {
    dispatch(fetchPermissions());
  }, []);

  useEffect(() => {
    if (checkIsActive(location, '/configuracoes/usuarios')) {
      setType('user');
    }

    if (checkIsActive(location, '/gerencial/professores')) {
      setType('instructor');
    }
  }, [location.pathname]);

  useEffect(() => {
    requestEntities();
  }, [type, branchFilter, branchSelected]);

  useEffect(() => {
    if (users.entities) {
      setRows(users.entities);
      // setRows(users.entities.filter(user => !user.attributes.permissions.includes('full-access')));
    }
  }, [users]);

  const getClassStatus = (status) => {
    if (status == 'pending') {
      return 'warning';
    }

    if (status == 'blocked') {
      return 'danger';
    }

    return 'success';
  }

  const isUser = () => {
    return (type == 'user') ? true : null;
  }

  const requestEntities = async () => {
    try {
      let name = formik.values.name;
      let branchId = branchFilter ? branchSelected.id : '';
      if (type) {
        if (isUser()) {
          dispatch(fetchUsers(name, branchId));
        } else {
          dispatch(fetchinstructors(name, branchId));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeCreateEntity = () => {
    if (createEntityShow) {
      requestEntities();
    }

    setCreateEntityShow(false);
  };

  const openEditEntity = (user) => {
    setEditEntity(user);
    setEditEntityShow(true);
  };

  const closeEditEntity = () => {
    if (editEntityShow) {
      requestEntities();
    }

    setEditEntityShow(false);
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  const searchUserName = () => {
    const userFetch = setTimeout(() => {
      requestEntities();
    }, 500);

    return () => {
      clearTimeout(userFetch);
    }
  }

  useEffect(() => {
    return searchUserName();
  }, [formik.values.name]);

  // function handleSelectAllClick(event) {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  return (
    <>
      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <span>{isUser() ? 'Usuários' : 'Professores'}</span>
          </div>
          <div className="card-toolbar">
            <button
              href="#"
              className="btn btn-light-primary font-weight-bold"
              onClick={() => setCreateEntityShow(true)}
            >
              <i className="ki ki-plus "></i> Cadastrar
            </button>
          </div>
        </div>
        <div className="card-body pt-10 pb-0">
          <Grid container>
            <Grid item lg={4} xs={12}>
              <TextField
                name="name"
                label="Pesquisar por nome"
                fullWidth
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {users.listLoading && <CircularProgress size={30} />}
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("name")}
              />
            </Grid>
            <Grid item lg={8} xs={12}>
              <div class="w-100 align-self-end mt-3">
                <ul class="nav nav-pills nav-pills-sm nav-dark-75 float-right pr-0" role="tablist">
                  <li class="nav-item">
                    <a class={branchFilter == false ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setBranchFilter(false)}>
                      <span class="nav-text font-size-sm">Todas unidades</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class={branchFilter == true ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setBranchFilter(true)}>
                      <span class="nav-text font-size-sm">
                        Esta unidade
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={users.listLoading ? "card-body filtering" : "card-body"}>
          <div className={classes.tableWrapper}>
            <Table
              key="tableWrapper"
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={users.totalCount}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy)).map(
                  (row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        {/*<TableCell
                          component="th"
                          id={`${labelId}-1`}
                          scope="row"
                          padding="none"
                        >
                          {row.attributes.name}
                        </TableCell>*/}

                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-40 symbol-sm flex-shrink-0">
                             <img class="" src={row.attributes.photo ?? toAbsoluteUrl('/media/users/blank.png')} alt="photo" />
                            </div>
                            <div class="ml-4">
                              <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">
                                {row.attributes.name}
                              </div>
                              <span class="text-muted font-weight-bold text-hover-primary">
                                {row.attributes.email}
                                {!row.attributes.email && `-`}
                              </span>
                            </div>
                          </div>
                        </TableCell>

                        <TableCell
                          component="th"
                          id={`${labelId}-2`}
                          scope="row"
                          padding="none"
                        >
                          {row.attributes.phone && formatPhoneToString(row.attributes.phone)}
                          {!row.attributes.phone && `-`}
                        </TableCell>

                        <TableCell
                          component="th"
                          id={`${labelId}-2`}
                          scope="row"
                          padding="none"
                        >
                          {/*{row?.relationships?.status?.attributes?.display_name}*/}

                          {row.relationships.status && row.relationships.status.attributes && row.relationships.status.attributes.name && <span className={`label label-lg label-light-${getClassStatus(row.relationships.status.attributes.name)} label-inline`}>
                            {row.relationships.status.attributes.display_name}
                          </span>}
                        </TableCell>

                        <TableCell align="right">
                          <button
                            title="Edit customer"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            onClick={() => openEditEntity(row)}
                          >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Write.svg"
                                )}
                              />
                            </span>
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </div>

          <div className="d-flex align-items-center py-3">
            {users.listLoading && (
              <div className="d-flex align-items-center">
                <div className="mr-2 text-muted">Carregando...</div>
                <div className="spinner spinner-primary mr-10"></div>
              </div>
            )}

            {pagination && (
              <TablePagination
                labelRowsPerPage="Registros por página:"
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={users.totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Página anterior",
                }}
                nextIconButtonProps={{
                  "aria-label": "Próxima Página",
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}

            {!pagination && !users.listLoading && (
              <span style={{ color: "#B5B5C3", textTransform: "uppercase" }}>
                {users.totalCount}{" "}
                {users.totalCount === 1 ? "Registro" : "Registros"}
              </span>
            )}
          </div>
        </div>

        <CreateUser show={createEntityShow} onHide={closeCreateEntity} type={type} />

        <EditUser
          show={editEntityShow}
          onHide={closeEditEntity}
          event={editEntity}
          type={type}
        />
      </div>
    </>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
