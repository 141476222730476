import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  total: 0,
  single: 0,
  recurrence: 0,
  entities: [],
  notifications: [],
  entityForEdit: undefined,
  entityCreated: undefined,
  entityMoved: undefined,
  lastError: null,
  created: false,
  canceled: false,
  updated: false,
  moved: false,
  approved: false,
  addClient: false,
  removeContract: false,
  removeScheduleSpot: false,
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const scheduleSlice = createSlice({
  name: "schedules",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    scheduleFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.canceled = false;
      state.updated = false;
      state.moved = false;
      state.approved = false;
      state.addClient = false;
      state.removeContract = false;
      state.removeScheduleSpot = false;
    },

    schedulesFetched: (state, action) => {
      const { totalCount, entities, total, single, recurrence } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.created = false;
      state.entityCreated = false;
      state.canceled = false;
      state.updated = false;
      state.approved = false;
      state.addClient = false;
      state.removeContract = false;
      state.removeScheduleSpot = false;
      state.entities = entities;
      state.totalCount = totalCount;
      state.total = total ?? 0;
      state.single = single ?? 0;
      state.recurrence = recurrence ?? 0;
    },

    schedulesNotificationsFetched: (state, action) => {
      const { notifications } = action.payload;

      state.notifications = notifications;
      state.created = false;
      state.approved = false;
      state.canceled = false;
      state.updated = false;
      state.addClient = false;
      state.removeContract = false;
    },

    scheduleCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.created = true;
      state.entityCreated = action.payload;
      state.canceled = false;
    },

    scheduleCanceled: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.canceled = true;
    },

    scheduleUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.updated = true;
      state.canceled = false;
    },

    scheduleMoved: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.moved = true;
      state.updated = false;
      state.canceled = false;
      state.entityMoved = action.payload.id;
    },

    scheduleApproved: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.approved = true;
    },

    scheduleAddClientCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.addClient = true;
    },

    scheduleRemoveClientSchedule: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.removeContract = true;
    },

    cancelScheduleSpot: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.removeScheduleSpot = true;
    },

    scheduleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },

    schedulesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },

    schedulesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
