import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

import ControlPoint from '@material-ui/icons/ControlPoint';

import { fetchUpdateField } from "../_redux/fieldActions";

import { createFieldSchema } from "../_schemas/createFieldSchema";
import { fetchServices } from "../../Service/_redux/serviceActions";

import { notifySuccess } from "../../../utils/Notify";
import CreateService from "../../Service/components/CreateService";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditField = (props) => {
  const dispatch = useDispatch();
  const { fields, services, branchSelected } = useSelector((state) => ({
    fields: state.fields,
    services: state.services,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();

  const [showEntity, setShowEntity] = useState(false);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    if (props.show) {
      dispatch(fetchServices(branchSelected.id));
    }
  }, [props.show]);

  useEffect(() => {
    if (fields.updated == true) {
      close();
      notifySuccess('Atualizado com sucesso', 'fields-updated', 2000);
    }
  }, [fields]);

  useEffect(() => {
    if (props.event && props.event.id) {
      formik.setFieldValue('fieldId', props.event.id, false);
      formik.setFieldValue('branchId', props.event.relationships.branch.id, false);
      formik.setFieldValue('name', props.event.attributes.name, false);
      formik.setFieldValue('maxPlayers', props.event.attributes.maxPlayers, false);
      formik.setFieldValue('groundType', props.event.attributes.groundType, false);

      let filteredServices = [];

      props.event.relationships.services.map(service => filteredServices.push(service.id));

      setSelectedServices(filteredServices);
    }
  }, [props.event]);

  const close = () => {
    props.onHide();
    setShowEntity(false);

    formik.setFieldValue('fieldId', '', false);
    formik.setFieldValue('branchId', '', false);
    formik.setFieldValue('name', '', false);
    formik.setFieldValue('maxPlayers', 2, false);
    formik.setFieldValue('groundType', 'grass', false);

    setSelectedServices([]);
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const serviceCheck = (serviceId) => {
    if (serviceIsChecked(serviceId)) {
      setSelectedServices(selectedServices.filter(arrayItem => arrayItem !== serviceId));
    } else {
      setSelectedServices([...selectedServices, serviceId]);
    }
  }

  const serviceIsChecked = (serviceId) => {
    return selectedServices.includes(serviceId);
  }

  const closeCreateEntityShow = () => {
    setCreateEntityShow(false);
  }

  const getServiceLabel = (type) => {
    if (type == 'single') {
      return 'Avulso';
    }

    if (type == 'recurrence') {
      return 'Mensal';
    }

    if (type == 'unavailable') {
      return 'Indisponível';
    }

    return '';
  }

  const renderError = (error) => {
    return (error ? (
      <Grid item xs={12}>
        <div className="fv-plugins-message-container">
          {(typeof error) == "string" &&
            <div className="fv-help-block">{error}</div>
          }

          {Array.isArray(error) && error.map((item) =>
            <div className="fv-help-block">{item}</div>
          )}
        </div>
      </Grid>
    ) : null);
  }

  const formik = useFormik({
    initialValues: {
      fieldId: '',
      branchId: '',
      name: '',
      maxPlayers: 2,
      groundType: 'grass',
    },
    validationSchema: createFieldSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        values.serviceId = selectedServices;

        dispatch(fetchUpdateField(values.fieldId, values));

        setSubmitting(false);
      }, 500);
    },
  });

  const type = [
    { label: 'Avulso', value: 'single' },
    { label: 'Mensalista', value: 'recurrence' },
  ];

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !createEntityShow}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editar campo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="sm">
            <Grid container className={classes.label}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Dados de exibição
                </span>
              </Grid>
            </Grid>

            <Grid container className={classes.label} spacing={2}>
              <TextField
                type="hidden"
                disabled={true}
                name="fieldId"
                {...formik.getFieldProps("fieldId")}
              />

              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Nome"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  {...formik.getFieldProps("name")}
                />

                {fields.error && renderError(fields.error.name)}

                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Especificações <Icon className={classes.icon}>info_outlined</Icon>
                </span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  select
                  label="Tipo"
                  variant="outlined"
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      }
                    }
                  }}
                  name="groundType"
                  fullWidth
                  className={classes.textField}
                  {...formik.getFieldProps("groundType")}
                >
                  <MenuItem key="1" value="1">Campo de Grama</MenuItem>
                  <MenuItem key="3" value="3">Quadra de Areia</MenuItem>
                  <MenuItem key="5" value="5">Quadra de Saibro</MenuItem>
                  <MenuItem key="2" value="2">Campo sintético</MenuItem>
                  <MenuItem key="4" value="4">Quadra dura</MenuItem>
                </TextField>

                {fields.error && renderError(fields.error.ground_type)}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="maxPlayers"
                  label="Capacidade máxima"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">jogadores</InputAdornment>,
                  }}
                  {...formik.getFieldProps("maxPlayers")}
                />

                {fields.error && renderError(fields.error.max_players)}
              </Grid>

              <Grid container className={classes.label}>
                <Grid item xs={8}>
                  <span className="card-title font-weight-bolder text-dark">
                    Serviços
                  </span>
                </Grid>

                <Grid item xs={4}>
                  <a href="#" className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                    <ControlPoint fontSize="inherit" /> Cadastrar serviço
                  </a>
                </Grid>
              </Grid>

              <Grid container className={classes.label} spacing={2}>
                <Grid item xs={12}>
                  <div className="checkbox-list">
                    {services.entities.map(service => <>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          key={service.id}
                          name={service.id}
                          checked={serviceIsChecked(service.id)}
                          onChange={() => serviceCheck(service.id)}
                        />
                        <span></span>{service.attributes.name} ({getServiceLabel(service.attributes.type)})
                      </label>
                    </>)}
                  </div>

                  {fields.error && renderError(fields.error.service_id)}
                </Grid>
              </Grid>

            </Grid>

            {(typeof fields.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {fields.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={formik.isSubmitting}>
                Salvar {(formik.isSubmitting || fields.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>

    <CreateService show={createEntityShow} onHide={() => closeCreateEntityShow()} />
  </>);
};

export default EditField;