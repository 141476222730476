import axios from "axios";

export const GET_BRANCH_RECIPIENTS = `${process.env.REACT_APP_API_URL}/branches`;
export const CREATE_BRANCH_RECIPIENT = `${process.env.REACT_APP_API_URL}/branches`;
export const UPDATE_BRANCH_RECIPIENT = `${process.env.REACT_APP_API_URL}/branches`;

export function getBranchRecipients(branchId) {
  return axios.get(`${GET_BRANCH_RECIPIENTS}/${branchId}/branch_recipient`);
}

export function getBranchRecipientKyc(branchId) {
  return axios.get(`${GET_BRANCH_RECIPIENTS}/${branchId}/branch_recipient/kyc`);
}

export function createBranchRecipient(branchId, params) {
  return axios.post(`${CREATE_BRANCH_RECIPIENT}/${branchId}/branch_recipient`, params);
}

export function updateBranchRecipient(branchId, params) {
  return axios.put(`${UPDATE_BRANCH_RECIPIENT}/${branchId}/branch_recipient`, params);
}