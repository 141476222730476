import * as requestFromServer from "./signatureCrud";
import { signatureSlice, callTypes } from "./signatureSlice";
import { notifySuccess, notifyError } from "../../../utils/Notify";

const { actions } = signatureSlice;

export const fetchSignatures = (perPage = 10, page = 1, sort = '', status = true, branchId = null, clientId = null) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    per_page: perPage,
    page: page,
  };

  if (status == 1 || status == 0) {
    params.is_active = status;
  }

  if (branchId) {
    params.branch_id = branchId;
  }

  if (clientId) {
    params.client_id = clientId;
  }

  if (sort) {
    params.sort = sort;
  }

  return requestFromServer
    .getSignatures(params)
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.signaturesFetched({ totalCount, entities }));
    })
    .catch(error => {
      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'signature-failed-fetch', 2000);
      }

      error.signatureMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.signaturesFetched({ totalCount: 0, entities: null }));
    });
};

export const fetchGetClientSignatures = (clientId, branchId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .getClientSignatures(clientId, branchId)
      .then(response => {
        dispatch(actions.loadingFinish());
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);
      });
  });
};

export const fetchSignature = (signatureId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getSignatureById(signatureId)
    .then(response => {
      const signature = response.data;


      dispatch(actions.signatureFetched({ signature: signature }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.signatureFetched({ signature: {} }));
    });
};

export const clearSignature = () => dispatch => {
  dispatch(actions.signatureFetched({ signature: {} }));
};

export const fetchCreateSignature = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .createSignature(params).then(response => {
        dispatch(actions.signatureFetched({ signature: response.data }));
        dispatch(actions.signatureCreated());
        resolve(response.data);
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const fetchRenewSignature = (signatureId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .renewSignature(signatureId)
    .then(response => {
      dispatch(actions.signatureRenewed());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateSignature = (signatureId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {};

  if (params.auto_renew) {
    data.auto_renew = params.auto_renew;
  }

  if (params.price) {
    data.price = params.price;
  }

  return requestFromServer
    .updateSignature(signatureId, data)
    .then(response => {
      dispatch(actions.signatureUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDeleteSignature = (signatureId, canceledAt) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deleteSignature(signatureId, { canceled_at: canceledAt })
    .then(response => {
      dispatch(actions.signatureUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};