import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment/min/moment-with-locales';
// import 'moment/locale/en-gb';
import { StickyContainer, Sticky } from 'react-sticky';

import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';

import { useSubheader } from "../../../../_metronic/layout";
import { hasPermission } from "../../../utils/Permission";

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import CustomToolbar from '../components/CustomToolbar';
import CustomEvent from '../components/CustomEvent';
import CreateEvent from '../components/CreateEvent';
import EditEvent from '../components/EditEvent';
import CreateSchedule from '../components/CreateSchedule';
import EditSchedule from '../components/EditSchedule';

// import InitCalendar from "../_redux/initCalendar";
import { fetchSchedules } from "../_redux/scheduleActions";
import { fetchFields } from "../../Field/_redux/fieldActions";
import * as auth from "../../Auth/_redux/authRedux";

// import { DropdownCustomToggler, DropdownMenu1 } from "../../../../_metronic/_partials/dropdowns";

import {
  Card,
  CardGroup,
  CardDeck,
  CardColumns,
  Dropdown
} from "react-bootstrap";

import Grid from '@material-ui/core/Grid';

const DragAndDropCalendar = withDragAndDrop(Calendar);

export default (props) => {
  const { schedules, fields, branchSelected, fieldCalendarView, defaultCalendarView, startCalendarView, endCalendarView, user } = useSelector((state) => ({
    schedules: state.schedules,
    fields: state.fields,
    branchSelected: state.auth.branchSelected,
    fieldCalendarView: state.auth.fieldCalendarView,
    defaultCalendarView: state.auth.defaultCalendarView,
    startCalendarView: state.auth.startCalendarView,
    endCalendarView: state.auth.endCalendarView,
    user: state.auth.user,
  }), shallowEqual);

  const [createEventShow, setCreateEventShow] = useState(false);
  const [newEvent, setNewEvent] = useState({});

  const [editEventShow, setEditEventShow] = useState(false);
  const [editedEvent, setEditedEvent] = useState({});

  const [calendarDates, setCalendarDates] = useState({
    startAt: '',
    finishAt: ''
  });

  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  moment().locale('pt-br');
  // moment.locale('en-gb');
  // let moment1 = moment().locale('en-gb');

  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();

  useEffect(() => {
    const { actions } = auth;

    if (branchSelected && branchSelected.id) {
      actions.setFieldCalendarView([]);
      dispatch(fetchFields(branchSelected.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchSelected]);

  useEffect(() => {
    const { actions } = auth;

    if(!fieldCalendarView.length) {
      dispatch(actions.setFieldCalendarView(fields.entities.map(field => field.id)));
    }
  }, [fields.entities]);

  useEffect(() => {
    if (!calendarDates.startAt || !fieldCalendarView.length || !fields.entities.length) {
      return;
    }

    requestSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDates, fields.entities, fieldCalendarView]);

  useEffect(() => {
    if (newEvent.resourceId && hasPermission(user, 'schedule-create')) {
      setCreateEventShow(true);
    }
  }, [newEvent]);

  useEffect(() => {
    if (schedules.created == true) {
      onCreated();
    }
  }, [schedules]);

  const requestSchedules = async () => {
    try {
      let startAt = calendarDates.startAt.format('YYYY-MM-DD');
      let finishAt = calendarDates.finishAt.format('YYYY-MM-DD');

      dispatch(fetchSchedules(startAt, finishAt, '', fieldCalendarView.filter(id => fields.entities.filter(field => field.id == id).length)));
    } catch (error) {
      console.error(error);
    }
  };

  const closeEditEvent = () => {
    {editEventShow &&
      requestSchedules();
    };

    setEditEventShow(false);
  };

  const closeCreateEvent = () => {
    {createEventShow &&
      requestSchedules();
    };

    setCreateEventShow(false);
  };

  useEffect(() => {
    if (schedules.approved) {
      requestSchedules();
    }
  }, [schedules.approved]);

  useEffect(() => {
    if (editedEvent.event && editedEvent.event && editedEvent.event.id) {
      schedules.entities.map(schedule => {
        if (schedule.id == editedEvent.event.id) {
          setEditedEvent({event: schedule});
        }
      })
    }
  }, [schedules.entities]);

  const changeCalendarView = ({start, end, view}) => {
    const { actions } = auth;

    dispatch(actions.setDefaultCalendarView(view));

    setTimeout(() => {
      dispatch(actions.setStartCalendarView(start));
      dispatch(actions.setEndCalendarView(end));
    }, 100);
  }

  const changeFieldView = (field) => {
    const { actions } = auth;

    if (field.target.value.length) {
      dispatch(actions.setFieldCalendarView(field.target.value));
    }
  }

  const onView = (view) => {
    calculateDate(calendarDates.startAt, view)
  }

  const calculateDate = (event, view) => {
    if (view === 'week') {
        setCalendarDates({
          startAt: moment(event).startOf('week'),
          finishAt: moment(event).endOf('week')
        });
    } else {
      setCalendarDates({
        startAt: moment(event).startOf('day'),
        finishAt: moment(event).endOf('day')
      });
    }
  }

  const onSelectSlot = (event) => {
    console.log('event:')
    console.log(event)
    setNewEvent(event);
    // setCreateEventShow(true);
  }

  const getStatusColor = (event) => {

    let recurrence = event.attributes.recurrence;
    let modality = event.attributes.modality;

    if (!recurrence && modality == 'reservation') {
      return '#17c191';
    }
    if (recurrence && modality == 'reservation') {
      return '#a6bf54';
    }

    if (!recurrence && modality == 'class') {
      return '#1772c1';
    }
    if (recurrence && modality == 'class') {
      return '#54a6bf';
    }

    return 'gray';
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    var style = {
        backgroundColor: 'rgba(255, 255, 255, .9)',
        borderRadius: '5px',
        border: '1px solid #ccc',
        opacity: 1,
        color: 'black',
        display: 'block',
        zIndex: 2,
        boxShadow: '2px 1px 5px rgba(0, 0, 0, .4)',
        borderLeft: `.525rem solid ${getStatusColor(event)}`,
    };

    return {
        style: style,
        className: 'ribbon ribbon-right',
    };
  }

  const stringToColour = function(str) {
    var hash = 0;
    for (var a = 0; a < str.length; a++) {
      hash = str.charCodeAt(a) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var b = 0; b < 3; b++) {
      var value = (hash >> (b * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  const today = new Date();

  // MOVE TO COMPONENT
  function TimeSlotWrapper(props: { children: React.ReactNode, resource: null /* grid */ | undefined /* gutter */, value: Date }) {
    if (props.resource === undefined) {
      return props.children;
    }

    const child = React.Children.only(props.children);

    return React.cloneElement(child, { className: child.props.className + ' selectable-slot' });
  }

  function resourceHeader(item) {
    return (<StickyContainer><Sticky relative={false}>
      {({
        style,
        isSticky,
        wasSticky,
        distanceFromTop,
        distanceFromBottom,
        calculatedHeight
      }) => (
        <div className="rbc-row rbc-row-resource" style={{ backgroundColor: stringToColour(item.resource.id), height: '60px', ...style }}>
          <div className="rbc-header" style={{textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'}}>{item.label}</div>
        </div>
      )}
    </Sticky></StickyContainer>);
  }

  function editSlot(event) {
    setEditedEvent(event);
    setEditEventShow(true);
  }

  const onCreated = (value) => {
    setEditedEvent({event: schedules.entityCreated});
    setEditEventShow(true);
  }

  function doubleClickSlot(event) {
    setEditedEvent({
      event: event
    });

    setEditEventShow(true);
  }

  const TouchCellWrapper = ({ children, value, onSelectSlot }) =>
    React.cloneElement(React.Children.only(children), {
      onTouchEnd: () => onSelectSlot({ action: "click", slots: [value] }),
      style: {
        className: `${children}`
      }
    });

  return (<>
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <TextField
            select
            multiple
            label="Campo"
            variant="outlined"
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                }
              }
            }}
            style={{
              width: '300px'
            }}
            name="fieldId"
            SelectProps={{
              multiple: true,
              value: fieldCalendarView,
              onChange: changeFieldView
            }}
          >
            {fields.entities.map((item) => <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>)}
          </TextField>
        </div>
        <div className="card-toolbar">
          {hasPermission(user, 'schedule-create') && <a href="#" className="btn btn-light-primary font-weight-bold" onClick={() => setCreateEventShow(true)}>
            <i className="ki ki-plus "></i> Agendar
          </a>}
        </div>
      </div>
      <div className={schedules.listLoading ? 'card-body filtering' : 'card-body'}>
        <DragAndDropCalendar
          showMultiDayTimes={true}
          localizer={localizer}
          events={schedules.entities}
          resources={fields.entities.filter(({ id }) => fieldCalendarView.includes(id))}
          resourceIdAccessor={(field) => { return field.id }}
          resourceTitleAccessor={(field) => { return field.attributes.name }}
          culture="pt-br"
          startAccessor={(event) => { return moment(event.attributes.start_at).toDate() }}
          endAccessor={(event) => { return moment(event.attributes.finish_at).toDate() }}
          resourceAccessor={(event) => { return event.relationships.field.id }}
          eventPropGetter={eventStyleGetter}
          style={{ height: '100%', width: '100%' }}
          views={[Views.WEEK, Views.DAY]}
          defaultView={defaultCalendarView}
          step={15}
          length={30}
          // resizable
          // onEventDrop={editSlot}
          // onEventResize={editSlot}
          // onDragStart={console.log}
          onDragStart={editSlot}
          onNavigate={calculateDate}
          onView={onView}
          onSelectSlot={onSelectSlot}
          onDoubleClickEvent={doubleClickSlot}
          popup={false}
          selectable={true}
          // resizableAccessor={(props) => true}
          dragFromOutsideItem={(props) => console.log(props)}
          //   this.state.displayDragItemInCell ? this.dragFromOutsideItem : null
          // }
          onDropFromOutside={console.log}
          handleDragStart={console.log}

          components={{
            eventWrapper: (props) => <CustomEvent editSlot={editSlot} {...props} />,
            toolbar: (props) => (
              <CustomToolbar
                onChange={changeCalendarView}
                start={startCalendarView}
                end={endCalendarView}
                {...props}
              />
            ),
            timeSlotWrapper: TimeSlotWrapper,
            resourceHeader: resourceHeader,
            dateCellWrapper: (props) => (
              <TouchCellWrapper {...props} onSelectSlot={onSelectSlot} />
            )
          }}

          min={
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              startCalendarView
            )
          }
          max={
            new Date(
              0,
              0,
              0,
              endCalendarView,
              (endCalendarView == 23 ? 59 : 0)
            )
          }
          messages={{
            date: 'Data',
            time: 'Tempo',
            event: 'Evento',
            allDay: 'Dia todo',
            week: 'Semana',
            work_week: 'Semana',
            day: 'Dia',
            month: 'Mês',
            previous: 'Anterior',
            next: 'Próximo',
            yesterday: 'Ontem',
            tomorrow: 'Amanhã',
            today: 'Hoje',
            agenda: 'Agenda',
            noEventsInRange: 'Não há eventos neste intervalo.',
            showMore: function showMore(total) {
              return "+" + total + " mais";
            }
          }}
        />
      </div>
    </div>

    {/*
    <CreateEvent show={createEventShow} onHide={closeCreateEvent} event={newEvent} />

    <EditEvent show={editEventShow} onHide={closeEditEvent} event={editedEvent} />
    */}

    <CreateSchedule
      show={createEventShow}
      onHide={closeCreateEvent}
      event={newEvent}
    />

    <EditSchedule show={editEventShow} onHide={closeEditEvent} event={editedEvent} />
  </>);
};
