import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { lighten, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Alert from 'react-bootstrap/Alert';

import { getLabel } from "../../../components/DateUtils";
import CreateBranchRecipient from "../components/CreateBranchRecipient";

import {
  fetchBranchRecipients,
  fetchBranchRecipientKyc,
} from "../_redux/branchRecipientActions";

import { fetchBanks } from "../../Bank/_redux/bankActions";

const useStyles = makeStyles(theme => ({
  //
}));

export const BranchRecipientPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { banks, branchRecipients, branchSelected } = useSelector((state) => ({
    banks: state.banks,
    branchRecipients: state.branchRecipients,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const [step, setStep] = useState(1);

  const requestEntities = async () => {
    try {
      dispatch(fetchBranchRecipients(branchSelected.id));
    } catch (error) {
      console.error(error);
    }
  };

  const requestBranchRecipientKyc = async () => {
    try {
      dispatch(fetchBranchRecipientKyc(branchSelected.id)).then((data) => {
        data?.attributes?.url && window.open(data.attributes.url, '_blank');
      });

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(fetchBanks());
  }, []);

  useEffect(() => {
    if (branchRecipients.created) {
      requestEntities();
    }
  }, [branchRecipients]);

  return (<>
    {!branchRecipients.entity && <CreateBranchRecipient />}
    {/*{!branchRecipients.entity && <div className="wizard wizard-6 d-flex flex-column flex-lg-row flex-column-fluid" id="kt_wizard">
      <div className="wizard-content d-flex flex-column mx-auto py-10 py-lg-20 w-100 w-md-700px">
        <div className="d-flex flex-column-auto flex-column px-10">
          <div className="wizard-nav pb-lg-10 pb-3 d-flex flex-column align-items-center align-items-md-start">
            <div className="wizard-steps d-flex flex-column flex-md-row">
              <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step" data-wizard-state={step > 1 ? "done" : step == 1 && "current"}>
                <div className="wizard-wrapper pr-lg-7 pr-5">
                  <div className="wizard-icon">
                    <i className="wizard-check ki ki-check"></i>
                    <span className="wizard-number">1</span>
                  </div>
                  <div className="wizard-label mr-3">
                    <h3 className="wizard-title">Informações</h3>
                    <div className="wizard-desc">Sobre a unidade</div>
                  </div>
                  <span className="svg-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0L24 0 24 24 0 24z"></path>
                        <rect
                          width="2"
                          height="9"
                          x="7.5"
                          y="7.5"
                          fill="#000"
                          opacity="0.3"
                          rx="1"
                          transform="rotate(-90 8.5 12)"
                        ></rect>
                        <path
                          fill="#000"
                          fillRule="nonzero"
                          d="M11.293 17.293a1 1 0 101.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step" data-wizard-state={step > 2 ? "done" : step == 2 && "current"}>
                <div className="wizard-wrapper pr-lg-7 pr-5">
                  <div className="wizard-icon">
                    <i className="wizard-check ki ki-check"></i>
                    <span className="wizard-number">2</span>
                  </div>
                  <div className="wizard-label mr-3">
                    <h3 className="wizard-title">Banco</h3>
                    <div className="wizard-desc">Conta bancária</div>
                  </div>
                  <span className="svg-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0L24 0 24 24 0 24z"></path>
                        <rect
                          width="2"
                          height="9"
                          x="7.5"
                          y="7.5"
                          fill="#000"
                          opacity="0.3"
                          rx="1"
                          transform="rotate(-90 8.5 12)"
                        ></rect>
                        <path
                          fill="#000"
                          fillRule="nonzero"
                          d="M11.293 17.293a1 1 0 101.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step" data-wizard-state={step == 3 ? "current" : "step"}>
                <div className="wizard-wrapper">
                  <div className="wizard-icon">
                    <i className="wizard-check ki ki-check"></i>
                    <span className="wizard-number">3</span>
                  </div>
                  <div className="wizard-label">
                    <h3 className="wizard-title">Transferência</h3>
                    <div className="wizard-desc">Automática ou manual</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="px-10" novalidate="novalidate" id="kt_form">
          <div className="pb-5" data-wizard-type="step-content" data-wizard-state={step == 1 ? "current" : "step"}>
            <div className="row">
              <div className="col-lg-5 col-xl-5">
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">CNPJ</label>
                  <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name="firstname" placeholder="CNPJ da unidade" value="" />
                </div>
              </div>
              <div className="col-lg-5 col-xl-5">
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">E-mail</label>
                  <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name="lastname" placeholder="E-mail do responsável" value="" />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-5" data-wizard-type="step-content" data-wizard-state={step == 2 ? "current" : "step"}>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">Agência</label>
                  <div className="row">
                    <div className="col-xl-8">
                      <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name="branch" placeholder="Agência *" />
                    </div>
                    <div className="col-xl-4">
                      <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name="branch" placeholder="Digito" />
                    </div>
                  </div>
                  <span className="form-text text-muted">Número da agência</span>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">Conta</label>
                  <div className="row">
                    <div className="col-xl-8">
                      <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name="account" placeholder="Conta *" />
                    </div>
                    <div className="col-xl-4">
                      <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name="account_digit" placeholder="Digito" />
                    </div>
                  </div>
                  <span className="form-text text-muted">Número da conta</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">Titular da conta</label>
                  <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name="holder_name" placeholder="Nome *" />
                  <span className="form-text text-muted">Nome do titular da conta</span>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">Banco</label>
                  <select name="country" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6">
                    {banks && banks.map(bank => <option value={bank.id}>{bank.attributes.name}</option>)}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-5" data-wizard-type="step-content" data-wizard-state={step == 3 ? "current" : "step"}>
            <div className="form-group row align-items-center">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Transferência automática</label>
              <div className="col-lg-9 col-xl-6">
                <span className="switch switch-sm">
                  <label>
                    <input type="checkbox" name="email_notification_1" />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div className="separator separator-dashed my-10"></div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Frequencia de transferência</label>
              <div className="col-lg-9 col-xl-6">
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">Período</label>
                  <select name="country" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6">
                    <option value="">Selecione o período</option>
                    <option value="AF">Diariamente</option>
                    <option value="AX">Toda semana</option>
                    <option value="AU" selected="selected">Todo mês</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Dia da transferência</label>
              <div className="col-lg-9 col-xl-9">
                <Chip className="mx-1" onClick={() => console.log(2)} clickable color={"default"} variant="outlined" label="Seg" />
                <Chip className="mx-1" onClick={() => console.log(4)} clickable color={"default"} variant="outlined" label="Ter" />
                <Chip className="mx-1" onClick={() => console.log(8)} clickable color={"default"} variant="outlined" label="Qua" />
                <Chip className="mx-1" onClick={() => console.log(16)} clickable color={"primary"} variant="outlined" label="Qui" />
                <Chip className="mx-1" onClick={() => console.log(32)} clickable color={"default"} variant="outlined" label="Sex" />
                <Chip className="mx-1" onClick={() => console.log(64)} clickable color={"default"} variant="outlined" label="Sab" />
                <Chip className="mx-1" onClick={() => console.log(1)} clickable color={"default"} variant="outlined" label="Dom" />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Dia da transferência</label>
              <div className="col-lg-5 col-xl-5">
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">Dia do mês</label>
                  <input type="text" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6" name="firstname" placeholder="15" value="" />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between pt-7">
            <div className="mr-2">
              {step > 1 && <button type="button" className="btn btn-light-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3">
              <span className="svg-icon svg-icon-md mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0L24 0 24 24 0 24z"></path>
                    <rect
                      width="2"
                      height="10"
                      x="14"
                      y="7"
                      fill="#000"
                      opacity="0.3"
                      rx="1"
                      transform="matrix(0 -1 -1 0 27 27)"
                    ></rect>
                    <path
                      fill="#000"
                      fillRule="nonzero"
                      d="M12.707 17.293a1 1 0 11-1.414 1.414l-6-6a1 1 0 01-.03-1.383l5.5-6a1 1 0 111.474 1.352L7.384 11.97l5.323 5.323z"
                    ></path>
                  </g>
                </svg>
              </span>Voltar</button>}
            </div>
            <div>
              {step == 3 && <button type="button" className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3">Cadastrar
              <span className="svg-icon svg-icon-md ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0L24 0 24 24 0 24z"></path>
                    <rect
                      width="2"
                      height="9"
                      x="7.5"
                      y="7.5"
                      fill="#000"
                      opacity="0.3"
                      rx="1"
                      transform="rotate(-90 8.5 12)"
                    ></rect>
                    <path
                      fill="#000"
                      fillRule="nonzero"
                      d="M11.293 17.293a1 1 0 101.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
                    ></path>
                  </g>
                </svg>
              </span></button>}
              {step != 3 && <button type="button" className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" onClick={() => setStep(step + 1)}>Próximo
              <span className="svg-icon svg-icon-md ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0L24 0 24 24 0 24z"></path>
                    <rect
                      width="2"
                      height="9"
                      x="7.5"
                      y="7.5"
                      fill="#000"
                      opacity="0.3"
                      rx="1"
                      transform="rotate(-90 8.5 12)"
                    ></rect>
                    <path
                      fill="#000"
                      fillRule="nonzero"
                      d="M11.293 17.293a1 1 0 101.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
                    ></path>
                  </g>
                </svg>
              </span></button>}
            </div>
          </div>
        </form>
      </div>
    </div>}*/}

    {branchRecipients.entity && branchRecipients.entity.attributes && <div className="col-lg-12 pb-15">
      <div className="d-flex flex-row">
        <div className="pt-lg-0 pt-5 pb-5 text-center text-md-left">
          <h3 className="font-weight-bolder text-dark font-size-h2">Conta bancária</h3>
          <div className="text-muted font-weight-bold font-size-h4">Configurações de recebimento</div>
        </div>
        {branchRecipients.entity.relationships.status.attributes?.name === 'requested' && <div className="text-right" style={{flex: 1}}>
          <button type="button" disabled={branchRecipients.actionsLoading} className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" onClick={() => requestBranchRecipientKyc()}>
            Enviar documentos
            {branchRecipients.actionsLoading ? <span className="ml-2 spinner spinner-success"></span> : <span className="icon svg-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M0 0L24 0 24 24 0 24z"></path>
                  <rect
                    width="2"
                    height="9"
                    x="7.5"
                    y="7.5"
                    fill="#000"
                    opacity="0.3"
                    rx="1"
                    transform="rotate(-90 8.5 12)"
                  ></rect>
                  <path
                    fill="#000"
                    fillRule="nonzero"
                    d="M11.293 17.293a1 1 0 101.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
                  ></path>
                </g>
              </svg>
            </span>}
          </button>
        </div>}
      </div>

      {branchRecipients.entity.relationships.status.attributes?.name === 'pending' && <div className="text-center pb-5 pt-5">
        <Alert key="alert-creating-kyc" variant="secondary">
          Estamos gerando o link para reconhecimento facial e envio dos documentos
        </Alert>
      </div>}

      {branchRecipients.entity.relationships.status.attributes?.name === 'requested' && <div className="text-center pb-5 pt-5">
        <Alert key="alert-creating-kyc" variant="secondary">
          Faça o reconhecimento facial e envie seus documentos para habilitar sua unidade
        </Alert>
      </div>}

      {branchRecipients.entity.relationships.status.attributes?.name === 'in_analysis' && <div className="text-center pb-5 pt-5">
        <Alert key="alert-creating-kyc" variant="light">
          Recebemos seus documentos e estamos validando seus dados
        </Alert>
      </div>}

      {branchRecipients.entity.relationships.status.attributes?.name === 'blocked' && <div className="text-center pb-5 pt-5">
        <Alert key="alert-creating-kyc" variant="warning">
          Encontramos problemas ao válidar seus dados, vamos entrar em contato
        </Alert>
      </div>}

      <h4 className="font-weight-bolder mb-3">Informações:</h4>
      <div className="text-dark-50 font-weight-bold line-height-lg mb-8">
        <div>{branchRecipients.entity.attributes.document}</div>
        <div>{branchRecipients.entity.attributes.email}</div>
      </div>
      <h4 className="font-weight-bolder mb-3">Dados bancários</h4>
      <div className="text-dark-50 font-weight-bold line-height-lg mb-8">
        <div>{banks.entities && banks.entities.find(bank => bank.id == branchRecipients.entity.attributes.bank) && banks.entities.find(bank => bank.id == branchRecipients.entity.attributes.bank).attributes.name}</div>
        <div>Agência: {branchRecipients.entity.attributes.branch_number}-{branchRecipients.entity.attributes.branch_check_digit}</div>
        <div>Conta: {branchRecipients.entity.attributes.account_number}-{branchRecipients.entity.attributes.account_check_digit}</div>
        <div>{branchRecipients.entity.attributes.holder_name}</div>
      </div>
      <h4 className="font-weight-bolder mb-3">Transferência automática:</h4>
      <div className="text-dark-50 font-weight-bold line-height-lg">
        <div>{branchRecipients.entity.attributes.transfer_enabled ? `Ativado` : `Desativado`}</div>
        {branchRecipients.entity.attributes.transfer_enabled && <>
          {branchRecipients.entity.attributes.transfer_interval == 'daily' && <div>Todos os dias</div>}
          {branchRecipients.entity.attributes.transfer_interval == 'weekly' && <div>Semanal - Toda {getLabel(branchRecipients.entity.attributes.transfer_day)}</div>}
          {branchRecipients.entity.attributes.transfer_interval == 'monthly' && <div>Mensal - Todo dia {branchRecipients.entity.attributes.transfer_day}</div>}
        </>}
      </div>
    </div>}
  </>);
}
