import * as requestFromServer from "./accountCrud";
import { accountSlice, callTypes } from "./accountSlice";

const { actions } = accountSlice;

export const notifiedPasswordUpdated = () => dispatch => {
  dispatch(actions.notifiedPasswordUpdated());
};

export const fetchUpdatePassword = (password, passwordConfirmation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updatePassword(
      password,
      passwordConfirmation
    ).then(response => {
      dispatch(actions.notifyPasswordUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};