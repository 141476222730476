import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import PhoneInput from 'react-phone-number-input';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

import { fetchCreateBranchRecipient } from "../_redux/branchRecipientActions";

import { notifySuccess, notifyError } from "../../../utils/Notify";
import { getCep } from "../../../utils/viacep";
import { RenderError } from "../../../components/RenderError";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  //
}));

const TextFieldPhoneInput = React.forwardRef((props, ref) => (
  <TextField variant="outlined" {...props} inputRef={ref} />
));

const CreateBranchRecipient = (props) => {
  const dispatch = useDispatch();
  const { branchRecipients, banks, branchSelected } = useSelector((state) => ({
    branchRecipients: state.branchRecipients,
    banks: state.banks,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [transferEnabled, setTransferEnabled] = useState(false);
  const [transferWeekday, setTransferWeekday] = useState('');

  useEffect(() => {

  }, []);

  useEffect(() => {
  //   if (branches.created == true) {
  //     close();
  //     notifySuccess('Cadastrado com sucesso', 'branches-created', 2000);
  //   }

    if (branchRecipients.error) {
      notifyError('Erro ao cadastrar', 'branches-created', 2000);
      setStep(1);
    }
  }, [branchRecipients]);

  const fetchGetCep = (cep) => {
    return new Promise((resolve, reject) => {
      getCep(cep).then(response => {
        resolve(response)
      }).catch((error) => {
        console.log('fetchGetCep falhou:');
        console.log({error});

        reject(error);
      });
    });
  }

  const beforeMaskedCnpjChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 15) {
      value = newValue.replace(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/, "$1.$2.$3/$4-$5");
    }

    return {
      ...newState,
      value
    };
  }

  const styleWeekDay = (weekday) => {
    if (transferWeekday == weekday) {
      return {
        border: '1px solid rgb(158 158 158 / 27%)',
        color: 'rgb(0 0 0 / 29%)'
      };
    }

    return {};
  }

  const selectedWeekDayColor = (weekday) => {
    return transferWeekday == weekday ? "primary" : "default";
  }

  const close = () => {
    props.onHide();

    setStep(1);
    setTransferEnabled(false);

    formik.setFieldValue('document', '', false);
    formik.setFieldValue('email', '', false);
    formik.setFieldValue('branch_number', '', false);
    formik.setFieldValue('branch_check_digit', '', false);
    formik.setFieldValue('account_number', '', false);
    formik.setFieldValue('account_check_digit', '', false);
    formik.setFieldValue('holder_name', '', false);
    formik.setFieldValue('transfer_interval', '', false);
    formik.setFieldValue('bank', '', false);
  }

  const formik = useFormik({
    initialValues: {
      document: '',
      email: '',
      founding_date: '',
      annual_revenue: '',
      street: '',
      street_number: '',
      complementary: '',
      neighborhood: '',
      city: '',
      state: '',
      zip_code: '',
      phone: '',
      managing_partners: {
        name: '',
        email: '',
        document: '',
        birthdate: '',
        zip_code: '',
        street: '',
        street_number: '',
        complementary: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      branch_number: '',
      branch_check_digit: '',
      account_number: '',
      account_check_digit: '',
      holder_name: '',
      transfer_interval: '',
      bank: '',
    },
    // validationSchema: createBranchSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {

      setTimeout(() => {
        values.transfer_enabled = transferEnabled;

        if (transferEnabled) {
          if (values.transfer_interval == 'daily') {
            delete values.transfer_day;
          }

          if (values.transfer_interval == 'weekly') {
            values.transfer_day = transferWeekday;
          }

          if (values.transfer_interval == 'monthly') {
            values.transfer_day = values.transfer_day;
          }
        }

        dispatch(fetchCreateBranchRecipient(branchSelected.id, values));

        setSubmitting(false);
      }, 500);
    },
  });

  useEffect(() => {
    const cep = formik.values.zip_code;

    if (cep?.length > 0 && cep?.match(/\d/g).join("").length == 8) {
      fetchGetCep(cep.match(/\d/g).join("")).then((value : any) => {
        formik.setFieldValue("street", value.logradouro, false);
        formik.setFieldValue("neighborhood", value.bairro, false);
        formik.setFieldValue("city", value.localidade, false);
        formik.setFieldValue("state", value.uf, false);
      });
    }
  }, [formik.values.zip_code]);

  useEffect(() => {
    const cep = formik.values.managing_partners.zip_code;

    if (cep?.length > 0 && cep?.match(/\d/g).join("").length == 8) {
      fetchGetCep(cep.match(/\d/g).join("")).then((value : any) => {
        formik.setFieldValue("managing_partners.street", value.logradouro, false);
        formik.setFieldValue("managing_partners.neighborhood", value.bairro, false);
        formik.setFieldValue("managing_partners.city", value.localidade, false);
        formik.setFieldValue("managing_partners.state", value.uf, false);
      });
    }
  }, [formik?.values?.managing_partners?.zip_code]);

  return (<>
    <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
      <div className="wizard wizard-6 d-flex flex-column flex-lg-row flex-column-fluid" id="kt_wizard">
        <div className="wizard-content d-flex flex-column mx-auto py-10 py-lg-20 w-100">
          <div className="d-flex flex-column-auto flex-column px-10">
            <div className="wizard-nav pb-lg-10 pb-3 d-flex flex-column align-items-center align-items-md-start">
              <div className="wizard-steps d-flex flex-column flex-md-row">
                <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step" data-wizard-state={step > 1 ? "done" : step == 1 && "current"}>
                  <div className="wizard-wrapper pr-lg-7 pr-5">
                    <div className="wizard-icon">
                      <i className="wizard-check ki ki-check"></i>
                      <span className="wizard-number">1</span>
                    </div>
                    <div className="wizard-label mr-3">
                      <h3 className="wizard-title">Informações</h3>
                      <div className="wizard-desc">Sobre a unidade</div>
                    </div>
                    <span className="svg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path d="M0 0L24 0 24 24 0 24z"></path>
                          <rect
                            width="2"
                            height="9"
                            x="7.5"
                            y="7.5"
                            fill="#000"
                            opacity="0.3"
                            rx="1"
                            transform="rotate(-90 8.5 12)"
                          ></rect>
                          <path
                            fill="#000"
                            fillRule="nonzero"
                            d="M11.293 17.293a1 1 0 101.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step" data-wizard-state={step > 2 ? "done" : step == 2 && "current"}>
                  <div className="wizard-wrapper pr-lg-7 pr-5">
                    <div className="wizard-icon">
                      <i className="wizard-check ki ki-check"></i>
                      <span className="wizard-number">2</span>
                    </div>
                    <div className="wizard-label mr-3">
                      <h3 className="wizard-title">Responsável</h3>
                      <div className="wizard-desc">Responsável legal</div>
                    </div>
                    <span className="svg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path d="M0 0L24 0 24 24 0 24z"></path>
                          <rect
                            width="2"
                            height="9"
                            x="7.5"
                            y="7.5"
                            fill="#000"
                            opacity="0.3"
                            rx="1"
                            transform="rotate(-90 8.5 12)"
                          ></rect>
                          <path
                            fill="#000"
                            fillRule="nonzero"
                            d="M11.293 17.293a1 1 0 101.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step" data-wizard-state={step > 3 ? "done" : step == 3 && "current"}>
                  <div className="wizard-wrapper pr-lg-7 pr-5">
                    <div className="wizard-icon">
                      <i className="wizard-check ki ki-check"></i>
                      <span className="wizard-number">3</span>
                    </div>
                    <div className="wizard-label mr-3">
                      <h3 className="wizard-title">Banco</h3>
                      <div className="wizard-desc">Conta bancária</div>
                    </div>
                    <span className="svg-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path d="M0 0L24 0 24 24 0 24z"></path>
                          <rect
                            width="2"
                            height="9"
                            x="7.5"
                            y="7.5"
                            fill="#000"
                            opacity="0.3"
                            rx="1"
                            transform="rotate(-90 8.5 12)"
                          ></rect>
                          <path
                            fill="#000"
                            fillRule="nonzero"
                            d="M11.293 17.293a1 1 0 101.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="wizard-step flex-grow-1 flex-basis-0" data-wizard-type="step" data-wizard-state={step == 4 ? "current" : "step"}>
                  <div className="wizard-wrapper">
                    <div className="wizard-icon">
                      <i className="wizard-check ki ki-check"></i>
                      <span className="wizard-number">4</span>
                    </div>
                    <div className="wizard-label">
                      <h3 className="wizard-title">Transferência</h3>
                      <div className="wizard-desc">Automática ou manual</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form className="px-10" novalidate="novalidate" id="kt_form">
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state={step == 1 ? "current" : "step"}>
              <div className="row">
                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">CNPJ da unidade</label>
                    <InputMask
                      fullWidth
                      mask="99.999.999/9999-99"
                      maskChar={null}
                      beforeMaskedValueChange={beforeMaskedCnpjChange}
                      autoComplete="cnpj"
                      {...formik.getFieldProps("document")}
                    >
                      {() => <Form.Control
                        name="document"
                        placeholder="CNPJ da unidade"
                        className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      />}
                    </InputMask>

                    {branchRecipients.error && <RenderError error={branchRecipients.error.document} />}
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">E-mail da unidade</label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="E-mail do responsável"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("email")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error.email} />}
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Data de fundação do CNPJ</label>
                    <Form.Control
                      type="date"
                      name="founding_date"
                      placeholder="Data de fundação"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("founding_date")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error.founding_date} />}
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Receita anual</label>
                    <InputMask
                      fullWidth
                      mask="99999999"
                      maskChar={''}
                      {...formik.getFieldProps("annual_revenue")}
                    >
                      {() => <Form.Control
                        name="annual_revenue"
                        placeholder="Receita anual"
                        className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      />}
                    </InputMask>

                    {branchRecipients.error && <RenderError error={branchRecipients.error.annual_revenue} />}
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Telefone responsável pelo CNPJ</label>

                    <PhoneInput
                      key="client-phone"
                      defaultCountry="BR"
                      international
                      countryCallingCodeEditable={false}
                      withCountryCallingCode
                      // numberInputProps={{ className: classes.textField }}
                      value={formik.values.phone ?? ''}
                      onChange={(value) => formik.setFieldValue('phone', value, false)}
                      inputComponent={TextFieldPhoneInput}
                      fullWidth
                      label="Telefone"
                      variant="outlined"
                      autoComplete="new-password"
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error.phone} />}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Cep do CNPJ</label>
                    <InputMask
                      fullWidth
                      mask="99999-999"
                      maskChar={null}
                      autoComplete="zip_code"
                      {...formik.getFieldProps("zip_code")}
                    >
                      {() => <Form.Control
                        name="zip_code"
                        placeholder="CEP"
                        className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      />}
                    </InputMask>

                    {branchRecipients.error && <RenderError error={branchRecipients.error.zip_code} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Endereço</label>
                    <Form.Control
                      type="text"
                      name="street"
                      placeholder="Endereço"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("street")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error.street} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Número</label>
                    <Form.Control
                      type="text"
                      name="street_number"
                      placeholder="Número"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("street_number")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error.street_number} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Complemento</label>
                    <Form.Control
                      type="text"
                      name="complementary"
                      placeholder="Complemento"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("complementary")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error.complementary} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Bairro</label>
                    <Form.Control
                      type="text"
                      name="neighborhood"
                      placeholder="Bairro"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("neighborhood")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error.neighborhood} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Cidade</label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="Cidade"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("city")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error.city} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Estado</label>
                    <Form.Control
                      type="text"
                      name="state"
                      placeholder="Estado"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("state")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error.state} />}
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state={step == 2 ? "current" : "step"}>
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Nome do resposável</label>
                    <Form.Control
                      name="managing_partners.name"
                      placeholder="Nome"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("managing_partners.name")}
                    />
                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.name"]} />}
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">E-mail do resposável</label>
                    <Form.Control
                      name="managing_partners.email"
                      placeholder="E-mail"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("managing_partners.email")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error?.["managing_partners.email"]} />}
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">CPF</label>
                    <InputMask
                      fullWidth
                      mask="999.999.999-99"
                      maskChar={null}
                      autoComplete="document"
                      {...formik.getFieldProps("managing_partners.document")}
                    >
                      {() => <Form.Control
                        name="managing_partners.document"
                        placeholder="CPF do responsável"
                        className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      />}
                    </InputMask>

                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.document"]} />}
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Data de nascimento</label>
                    <Form.Control
                      name="managing_partners.birthdate"
                      type="date"
                      placeholder="Data de nascimento"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("managing_partners.birthdate")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.birthdate"]} />}
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Ocupação profissional</label>
                    <Form.Control
                      name="managing_partners.professional_occupation"
                      placeholder="Ocupação profissional"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("managing_partners.professional_occupation")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.professional_occupation"]} />}
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Renda mensal</label>
                    <InputMask
                      fullWidth
                      mask="99999999"
                      maskChar={''}
                      {...formik.getFieldProps("managing_partners.monthly_income")}
                    >
                      {() => <Form.Control
                        name="managing_partners.monthly_income"
                        placeholder="Renda mensal"
                        className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      />}
                    </InputMask>

                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.monthly_income"]} />}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">CEP</label>
                    <InputMask
                      fullWidth
                      mask="99999-999"
                      maskChar={null}
                      autoComplete="managing_partners.zip_code"
                      {...formik.getFieldProps("managing_partners.zip_code")}
                    >
                      {() => <Form.Control
                        name="managing_partners.zip_code"
                        placeholder="CEP"
                        className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      />}
                    </InputMask>

                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.zip_code"]} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Endereço</label>
                    <Form.Control
                      type="text"
                      name="managing_partners.street"
                      placeholder="Endereço"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("managing_partners.street")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.street"]} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Número</label>
                    <Form.Control
                      type="text"
                      name="managing_partners.street_number"
                      placeholder="Número"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("managing_partners.street_number")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.street_number"]} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Complemento</label>
                    <Form.Control
                      type="text"
                      name="managing_partners.complementary"
                      placeholder="Complemento"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("managing_partners.complementary")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error?.["managing_partners.complementary"]} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Bairro</label>
                    <Form.Control
                      type="text"
                      name="managing_partners.neighborhood"
                      placeholder="Bairro"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("managing_partners.neighborhood")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.neighborhood"]} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Cidade</label>
                    <Form.Control
                      type="text"
                      name="managing_partners.city"
                      placeholder="Cidade"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("managing_partners.city")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.city"]} />}
                  </div>
                </div>

                <div className="col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Estado</label>
                    <Form.Control
                      type="text"
                      name="managing_partners.state"
                      placeholder="Estado"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("managing_partners.state")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients?.error?.["managing_partners.state"]} />}
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state={step == 3 ? "current" : "step"}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Agência</label>
                    <div className="row">
                      <div className="col-lg-8">
                        <Form.Control
                          name="branch_number"
                          placeholder="Agência *"
                          className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6 mt-1"
                          {...formik.getFieldProps("branch_number")}
                        />

                        {branchRecipients.error && <RenderError error={branchRecipients.error.branch_number} />}
                      </div>
                      <div className="col-lg-4">
                        <Form.Control
                          name="branch_check_digit"
                          placeholder="Digito"
                          className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6 mt-1"
                          {...formik.getFieldProps("branch_check_digit")}
                        />

                        {branchRecipients.error && <RenderError error={branchRecipients.error.branch_check_digit} />}
                      </div>
                    </div>
                    <span className="form-text text-muted">Número da agência</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Conta</label>
                    <div className="row">
                      <div className="col-lg-8">
                        <Form.Control
                          name="account_number"
                          placeholder="Conta *"
                          className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6 mt-1"
                          {...formik.getFieldProps("account_number")}
                        />

                        {branchRecipients.error && <RenderError error={branchRecipients.error.account_number} />}
                      </div>
                      <div className="col-lg-4">
                        <Form.Control
                          name="account_check_digit"
                          placeholder="Digito"
                          className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6 mt-1"
                          {...formik.getFieldProps("account_check_digit")}
                        />

                        {branchRecipients.error && <RenderError error={branchRecipients.error.account_check_digit} />}
                      </div>
                    </div>
                    <span className="form-text text-muted">Número da conta</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Titular da conta</label>
                    <Form.Control
                      name="holder_name"
                      placeholder="Nome *"
                      className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                      {...formik.getFieldProps("holder_name")}
                    />
                    <span className="form-text text-muted">Nome do titular da conta</span>

                    {branchRecipients.error && <RenderError error={branchRecipients.error.holder_name} />}
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">Banco</label>
                    {/*<select name="country" className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6">
                      {banks.entities && banks.entities.map(bank => <option value={bank.id}>{bank.attributes.name}</option>)}
                    </select>*/}
                    <Autocomplete
                      id="bank"
                      name="bank"
                      freeSolo
                      options={banks.entities}
                      getOptionLabel={(option) => option.attributes.name}
                      renderInput={(params) => <TextField
                        {...params}
                        InputProps={{...params.InputProps, disableUnderline: true}}
                        // variant="outlined"
                        className="form-control form-control-solid h-auto p-4 border-0 rounded-lg font-size-h6"
                        placeholder="Banco *"
                      />}
                      onChange={(e, value) => formik.setFieldValue('bank', value.id, false)}
                      // {...formik.getFieldProps("bank")}
                    />

                    {branchRecipients.error && <RenderError error={branchRecipients.error.bank} />}
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state={step == 4 ? "current" : "step"}>
              <div className="form-group row align-items-center">
                <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Transferência automática</label>
                <div className="col-lg-9 col-xl-6">
                  <span className="switch switch-sm">
                    <label>
                      <input
                        type="checkbox"
                        name="transferEnabled"
                        checked={transferEnabled}
                        onChange={(value) => setTransferEnabled(!transferEnabled)}
                      />
                      <span></span>
                    </label>
                  </span>
                </div>
              </div>
              <div className="separator separator-dashed my-10"></div>

              {transferEnabled && <>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Frequencia de transferência</label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">Período</label>
                      <select
                        name="country"
                        className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                        {...formik.getFieldProps('transfer_interval')}
                      >
                        <option value="">Selecione o período</option>
                        <option value="daily">Diariamente</option>
                        <option value="weekly">Toda semana</option>
                        <option value="monthly">Todo mês</option>
                      </select>

                      {branchRecipients.error && <RenderError error={branchRecipients.error.transfer_interval} />}
                    </div>
                  </div>
                </div>
                {formik.values.transfer_interval == 'weekly' && <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Dia da transferência</label>
                  <div className="col-lg-9 col-xl-9">
                    <Chip className="mx-1" onClick={() => setTransferWeekday(1)} clickable color={selectedWeekDayColor(1)} variant="outlined" label="Seg" />
                    <Chip className="mx-1" onClick={() => setTransferWeekday(2)} clickable color={selectedWeekDayColor(2)} variant="outlined" label="Ter" />
                    <Chip className="mx-1" onClick={() => setTransferWeekday(3)} clickable color={selectedWeekDayColor(3)} variant="outlined" label="Qua" />
                    <Chip className="mx-1" onClick={() => setTransferWeekday(4)} clickable color={selectedWeekDayColor(4)} variant="outlined" label="Qui" />
                    <Chip className="mx-1" onClick={() => setTransferWeekday(5)} clickable color={selectedWeekDayColor(5)} variant="outlined" label="Sex" />
                  </div>

                  {branchRecipients.error && <RenderError error={branchRecipients.error.transfer_day} />}
                </div>}
                {formik.values.transfer_interval == 'monthly' && <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">Dia da transferência</label>
                  <div className="col-lg-5 col-xl-5">
                    <div className="form-group">
                      <label className="font-size-h6 font-weight-bolder text-dark">Dia do mês</label>
                      <Form.Control
                        type="integer"
                        name="holder_name"
                        placeholder="15"
                        className="form-control form-control-solid h-auto p-5 border-0 rounded-lg font-size-h6"
                        {...formik.getFieldProps("transfer_day")}
                      />

                      {branchRecipients.error && <RenderError error={branchRecipients.error.transfer_day} />}
                    </div>
                  </div>
                </div>}
              </>}
            </div>
            {(typeof branchRecipients.error) == "string" && <>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {branchRecipients.error}
                  </span>
                </div>
              </div>
            </>}
            <div className="d-flex justify-content-between pt-7">
              <div className="mr-2">
                {step > 1 && <button type="button" className="btn btn-light-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3" onClick={() => setStep(step - 1)}>
                  <span className="svg-icon svg-icon-md mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0L24 0 24 24 0 24z"></path>
                        <rect
                          width="2"
                          height="10"
                          x="14"
                          y="7"
                          fill="#000"
                          opacity="0.3"
                          rx="1"
                          transform="matrix(0 -1 -1 0 27 27)"
                        ></rect>
                        <path
                          fill="#000"
                          fillRule="nonzero"
                          d="M12.707 17.293a1 1 0 11-1.414 1.414l-6-6a1 1 0 01-.03-1.383l5.5-6a1 1 0 111.474 1.352L7.384 11.97l5.323 5.323z"
                        ></path>
                      </g>
                    </svg>
                  </span>

                  Voltar
                </button>}
              </div>

              <div>
                {step == 4 && <button type="button" type="submit" className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" disabled={formik.isSubmitting}>Cadastrar
                  {(!formik.isSubmitting) && <span className="svg-icon svg-icon-md ml-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0L24 0 24 24 0 24z"></path>
                        <rect
                          width="2"
                          height="9"
                          x="7.5"
                          y="7.5"
                          fill="#000"
                          opacity="0.3"
                          rx="1"
                          transform="rotate(-90 8.5 12)"
                        ></rect>
                        <path
                          fill="#000"
                          fillRule="nonzero"
                          d="M11.293 17.293a1 1 0 101.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
                        ></path>
                      </g>
                    </svg>
                  </span>}
                  {(formik.isSubmitting) && <span className="mx-2 px-2 spinner spinner-info"></span>}
                </button>}

                {step != 4 && <button type="button" className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" onClick={() => setStep(step + 1)}>Próximo
                  <span className="svg-icon svg-icon-md ml-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0L24 0 24 24 0 24z"></path>
                        <rect
                          width="2"
                          height="9"
                          x="7.5"
                          y="7.5"
                          fill="#000"
                          opacity="0.3"
                          rx="1"
                          transform="rotate(-90 8.5 12)"
                        ></rect>
                        <path
                          fill="#000"
                          fillRule="nonzero"
                          d="M11.293 17.293a1 1 0 101.414 1.414l6-6a1 1 0 00.03-1.383l-5.5-6a1 1 0 00-1.474 1.352l4.853 5.294-5.323 5.323z"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </button>}
              </div>
            </div>
          </form>
        </div>
      </div>

      {/*<TextField
        name="name"
        label="Nome"
        fullWidth
        className={`${classes.textField} is-invalid`}
        variant="outlined"
        autoComplete="new-password"
        {...formik.getFieldProps("name")}
      />

      {branches.error && <RenderError error={branches.error.name} />}

      {formik.touched.name && formik.errors.name ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors.name}</div>
        </div>
      ) : null}*/}

      {/*{(typeof branches.error) == "string" && <>
        <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
              {branches.error}
            </span>
          </div>
        </div>
      </>}*/}
    </form>
  </>);
};

export default CreateBranchRecipient;