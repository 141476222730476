import * as requestFromServer from "./reportCrud";
import { reportSlice, callTypes } from "./reportSlice";
import { notifyError, notifySuccess } from "../../../utils/Notify";
import moment from "moment/min/moment-with-locales";

const { actions } = reportSlice;

export const fetchGetReports = (branch_id, type, month, year, start_at) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return new Promise((resolve, reject) => {
    const data = start_at
      ? {
          branch_id,
          type,
          start_at: moment(start_at).format("YYYY-MM-DD"),
        }
      : { branch_id, type, date: `${year}-${month}` };

    requestFromServer
      .getReports(data)
      .then((response) => {
        dispatch(actions.reportsFetched());
        notifySuccess("Relatório gerado com sucesso", "get-reports", 2000);

        resolve(response.data);
      })
      .catch((error) => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));

        dispatch(actions.reportsFetched());
        reject(error);

        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message == "string"
        ) {
          notifyError(error.response.data.message, "error-reports", 2000);
        }
      });
  });
};
