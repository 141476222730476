import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import ListGroup from 'react-bootstrap/ListGroup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Chip from '@material-ui/core/Chip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Alert from 'react-bootstrap/Alert';

import {
  getWeekDaysAbbreviated
} from "../../../components/DateUtils";

import {
  fetchUpdateCouponExpiredAt,
  fetchRenewCoupon,
  fetchCoupon,
  clearCoupon,
} from "../_redux/couponActions";

import {
  fetchPrices,
  fetchCreatePrice,
  fetchRemovePrice,
} from "../../Price/_redux/priceActions";

import { fetchBranches } from "../../Branch/_redux/branchActions";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import CreatePrice from '../../Price/components/CreatePrice';
import EditPrice from '../../Price/components/EditPrice';
import CancelCoupon from "./CancelCoupon";
import EditClient from "../../Client/components/EditClient";
import ShowLogs from "../../Log/components/ShowLogs";

import { notifySuccess } from "../../../utils/Notify";
import DayPicker from '../../../components/DayPicker';
import { RenderError } from "../../../components/RenderError";
import { formatMoney } from "../../../utils/IntegerParse";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditCoupon = (props) => {
  const dispatch = useDispatch();
  const { coupons, branches } = useSelector((state) => ({
    coupons: state.coupons,
    branches: state.branches,
  }), shallowEqual);

  const classes = useStyles();

  const [editClientShow, setEditClientShow] = useState(false);
  const [editClient, setEditClient] = useState({});
  const [showCancelCoupon, setShowCancelCoupon] = useState(false);
  const [showEditExpiredAt, setShowEditExpiredAt] = useState(false);
  const [event, setEvent] = useState({});

  useEffect(() => {
    if (coupons.updated == true) {
      close();
      setShowCancelCoupon(false);
      notifySuccess('Atualizado com sucesso', 'coupons-updated', 2000);
    }
  }, [coupons]);

  useEffect(() => {
    if (coupons.coupon && coupons.coupon.id) {
      setEvent(coupons.coupon);

      formik.setFieldValue('expiredAt', moment(coupons.coupon.attributes.expired_at).format('YYYY-MM-DD'), false);
    }
  }, [coupons.coupon]);

  useEffect(() => {
    if (props.event && props.event.id && props.show) {
      setEvent(props.event);

      formik.setFieldValue('expiredAt', moment(props.event.attributes.expired_at).format('YYYY-MM-DD'), false);
      console.log(props.event);
    } else {
      close();
    }
  }, [props.show]);

  const close = () => {
    props.onHide();

    setShowEditExpiredAt(false);
    setShowCancelCoupon(false);

    formik.setFieldValue('expiredAt', '', false);

    setEvent({});
    dispatch(clearCoupon());
  }

  const getLabelType = (value) => {
    if (value == 'branch') {
      return 'Criado pelo operador';
    }

    if (value == 'purchase') {
      return 'Pacote de crédito';
    }

    if (value == 'refund') {
      return 'Reposição';
    }

    return '';
  }

  const getLabelStatus = (status, isExpired) => {
    if (status == 'active') {
      if (isExpired) {
        return 'Expirado';
      }

      return 'Disponível para uso';
    }

    if (status == 'expired') {
      return 'Expirado';
    }

    if (status == 'paid') {
      return 'Já utilizado';
    }

    return '';
  }

  const getColorStatus = (status, isExpired) => {
    if (status == 'active') {
      if (isExpired) {
        return 'warning';
      }

      return 'primary';
    }

    if (status == 'paid') {
      return 'success';
    }

    if (status == 'expired') {
      return 'warning';
    }

    return 'primary';
  }

  const openEditClient = (event) => {
    setEditClient(event);

    setEditClientShow(true);
  }

  const closeEditClient = () => {
    setEditClientShow(false);
  }

  const dispatchUpdateExpiredAt = () => {
    dispatch(fetchUpdateCouponExpiredAt(event.id, formik.values.expiredAt));
  }

  const formik = useFormik({
    initialValues: {
      expiredAt: '',
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    },
  });

  const renew = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const headRows = [
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Mês' },
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Valor' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  return (<>
    {event.id && <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !showCancelCoupon && !editClientShow}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editar cupom
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7 mb-5"></div>

          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={6}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Nome:</span><br />{event.attributes.name}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      {event.attributes.selling_modality == 'reservation' && `Locação`}
                      {event.attributes.selling_modality == 'day_use' && `Day use`}
                      {event.attributes.selling_modality == 'class' && `Aula`}
                      {event.attributes.selling_modality == 'signature' && `Clubinho`}

                      <span style={{
                        color: "#B5B5C3",
                        textTransform: "uppercase"
                      }}>
                        <br />
                        {event.attributes.selling_type == 'single' && `Avulso`}
                        {event.attributes.selling_type == 'package' && `Pacote`}
                      </span>
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Descrição</span>:<br />{event.attributes.description}
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="sm">
            <Grid container className="mt-5">
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7"></div>

          <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={1}>
              <Grid item xs={6}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Desconto</span>:<br />
                      {event.attributes.type == 'fixed' && formatMoney(event.attributes.value)}
                      {event.attributes.type == 'percentage' && `${event.attributes.value}% OFF`}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Limite de uso</span>:<br />
                      {event.attributes.limit_per_client && `Por cliente: ${event.attributes.limit_per_client}`}
                      {event.attributes.limit_per_client && event.attributes.usage_limit && <br />}
                      {event.attributes.usage_limit && `Total: ${event.attributes.usage_limit}`}
                      {!event.attributes.limit_per_client && !event.attributes.usage_limit && `-`}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Início</span>:<br />
                      {event.attributes.valid_at && <span>
                        {moment(event.attributes.valid_at).format('DD/MM/YYYY')}<br />
                        {moment(event.attributes.valid_at).format('HH:mm')}
                      </span>}
                      {!event.attributes.valid_at && `-`}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Expiração</span>:
                      {!event.attributes.is_expired && !showEditExpiredAt && <span class="cursor-pointer ml-2" onClick={() => setShowEditExpiredAt(!showEditExpiredAt)}>
                        <i class="fas fa-edit icon-nm text-dark-50"></i>
                      </span>}
                      <br />
                      {event.attributes.expired_at && <span>
                        {moment(event.attributes.expired_at).format('DD/MM/YYYY')}<br />
                        {moment(event.attributes.expired_at).format('HH:mm')}
                      </span>}
                      {!event.attributes.expired_at && `Sem validade`}
                    </span>
                  </div>
                </div>
              </Grid>

              {showEditExpiredAt && <>
                <Grid item xs={6} className="pt-3">
                  <TextField
                    name="expiredAt"
                    label={'Data de expiração'}
                    type="date"
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...formik.getFieldProps("expiredAt")}
                  />
                </Grid>
                <Grid item xs={6} className="pt-3">
                  <div class="pl-3 py-1 flex-column align-items-center">
                    <span class="label label-xl font-weight-boldest label-success mt-5 cursor-pointer" onClick={() => dispatchUpdateExpiredAt()}>
                      <i class="ki ki-check text-white"></i>
                    </span>
                    <span class="label label-xl font-weight-boldest label-danger mt-5 ml-2 cursor-pointer" onClick={() => setShowEditExpiredAt(false)}>
                      <i class="ki ki-close text-white"></i>
                    </span>
                  </div>
                </Grid>

                {coupons.error && <RenderError error={coupons.error.expired_at} />}
              </>}

              {event.attributes.weekdays?.length > 0 && <Grid item xs={6}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Dias da semana</span>:<br />{getWeekDaysAbbreviated(event.attributes.weekdays)}
                    </span>
                  </div>
                </div>
              </Grid>}

              {event.attributes.start_at && event.attributes.end_at && <Grid item xs={6}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Limite de horário</span>:<br />{event.attributes.start_at} até {event.attributes.end_at}
                    </span>
                  </div>
                </div>
              </Grid>}


              {(event.relationships?.coupon_restrictions?.length > 0) && <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Restrição de serviço</span>:<br />
                      {event.relationships?.coupon_restrictions?.map(restriction => <>
                        {restriction.relationships?.origin?.attributes?.name}<br />
                      </>)}
                    </span>
                  </div>
                </div>
              </Grid>}

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block pt-3">
                    <span className="text-dark">
                      {!event.attributes.is_expired && <span className={`label label-lg label-light-success label-inline`}>
                        Ativo
                      </span>}
                      {event.attributes.is_expired && <span className={`label label-lg label-light-danger label-inline`}>
                        Expirado
                      </span>}
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7"></div>

          <Container maxWidth="sm">
            <ShowLogs show={true} data={{origin_id: event.id, type: "coupon"}} />
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs={2} />
            <Grid item xs={8} style={{textAlign: 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
            <Grid item xs={2} style={{textAlign: 'right'}}>
              {!props.readOnly && !event.attributes?.is_expired && <Tooltip title="Expirar cupom" placement="top">
                <IconButton variant="outlined" aria-label="delete" onClick={() => setShowCancelCoupon(true)} disableFocusRipple={true}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>}

    <CancelCoupon show={showCancelCoupon} onHide={() => setShowCancelCoupon(false)} event={event} />
    <EditClient show={editClientShow} onHide={closeEditClient} event={editClient} />
  </>);
};

export default EditCoupon;