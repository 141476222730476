import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import SVG from "react-inlinesvg";

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Icon from '@material-ui/core/Icon';

import { BranchPage } from '../../Branch/pages/BranchPage';

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/sass/pages/wizard/wizard-2.scss";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
    height: 24,
    width: 24,
    fontSize: '2rem',
    marginRight: '1.1rem',
    transition: 'color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease',
  },
}));

export const SetupPage = () => {
  const { branches } = useSelector((state) => ({
    branches: state.branches,
  }), shallowEqual);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);

  return (<>
    <div class="d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div class="d-flex flex-column-fluid">
          <div class="container">
            <div class="card card-custom">
              <div class="card-body p-0">
                <div
                  class="wizard wizard-2"
                  id="kt_wizard"
                  data-wizard-clickable="true"
                >
                  <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                    <div class="wizard-steps">
                      <div class="wizard-step" onClick={() => step > 0 && setStep(0)} data-wizard-type="step" data-wizard-state={step == 0 ? 'current' : ''}>
                        <div class="wizard-wrapper">
                          <div class={classes.icon}>
                            <span class={step != 0 ? 'svg-icon svg-icon-2x' : ''}>
                              <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Custom/store.svg")}
                                width="2rem"
                                height="2rem"
                              />
                            </span>
                          </div>
                          <div class="wizard-label">
                            <h3 class="wizard-title">Lojas</h3>
                            <div class="wizard-desc">Cadastre os pontos de atendimento</div>
                          </div>
                        </div>
                      </div>
                      <div class="wizard-step" onClick={() => step > 1 && setStep(1)} data-wizard-type="step" data-wizard-state={step == 1 ? 'current' : 'done'}>
                        <div class="wizard-wrapper">
                          <div class="wizard-icon">
                            <span class="svg-icon svg-icon-2x">
                              <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                              />
                            </span>
                          </div>
                          <div class="wizard-label">
                            <h3 class="wizard-title">Setup Locations</h3>
                            <div class="wizard-desc">Choose Your Location Map</div>
                          </div>
                        </div>
                      </div>
                      <div class="wizard-step" onClick={() => step > 2 && setStep(2)} data-wizard-type="step" data-wizard-state={step == 2 ? 'current' : 'done'}>
                        <div class="wizard-wrapper">
                          <div class="wizard-icon">
                            <span class="svg-icon svg-icon-2x">
                              <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                              />
                            </span>
                          </div>
                          <div class="wizard-label">
                            <h3 class="wizard-title">Support Channels</h3>
                            <div class="wizard-desc">Add Your Support Agents</div>
                          </div>
                        </div>
                      </div>
                      <div class="wizard-step" onClick={() => step > 3 && setStep(3)} data-wizard-type="step" data-wizard-state={step == 3 ? 'current' : 'done'}>
                        <div class="wizard-wrapper">
                          <div class="wizard-icon">
                            <span class="svg-icon svg-icon-2x">
                              <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                              />
                            </span>
                          </div>
                          <div class="wizard-label">
                            <h3 class="wizard-title">Set Your Delivery Address</h3>
                            <div class="wizard-desc">Address, Street, City, State</div>
                          </div>
                        </div>
                      </div>
                      <div class="wizard-step" onClick={() => step > 4 && setStep(4)} data-wizard-type="step" data-wizard-state={step == 4 ? 'current' : 'done'}>
                        <div class="wizard-wrapper">
                          <div class="wizard-icon">
                            <span class="svg-icon svg-icon-2x">
                              <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                              />
                            </span>
                          </div>
                          <div class="wizard-label">
                            <h3 class="wizard-title">Make Payment</h3>
                            <div class="wizard-desc">Use Credit or Debit Cards</div>
                          </div>
                        </div>
                      </div>
                      <div class="wizard-step" data-wizard-type="step" data-wizard-state={step == 5 ? 'current' : ''}>
                        <div class="wizard-wrapper">
                          <div class="wizard-icon">
                            <span class="svg-icon svg-icon-2x">
                              <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                              />
                            </span>
                          </div>
                          <div class="wizard-label">
                            <h3 class="wizard-title">Completed!</h3>
                            <div class="wizard-desc">Review and Submit</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
                    {true && <div class="row">
                      <div class="offset-xxl-2 col-xxl-8">
                        <form class="form" id="kt_form">
                          {step == 0 && <div class="pb-5">
                            <BranchPage />
                            {/*<h4 class="mb-10 font-weight-bold text-dark">Enter your Account Details</h4>
                            <div class="form-group">
                              <label>First Name</label>
                              <input type="text" class="form-control form-control-solid form-control-lg" name="fname" placeholder="First Name" value="" />
                              <span class="form-text text-muted">Please enter your first name.</span>
                            </div>
                            <div class="form-group">
                              <label>Last Name</label>
                              <input type="text" class="form-control form-control-solid form-control-lg" name="lname" placeholder="Last Name" value="" />
                              <span class="form-text text-muted">Please enter your last name.</span>
                            </div>
                            <div class="row">
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Phone</label>
                                  <input type="tel" class="form-control form-control-solid form-control-lg" name="phone" placeholder="phone" value="+61412345678" />
                                  <span class="form-text text-muted">Please enter your phone number.</span>
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Email</label>
                                  <input type="email" class="form-control form-control-solid form-control-lg" name="email" placeholder="Email" value="john.wick@reeves.com" />
                                  <span class="form-text text-muted">Please enter your email address.</span>
                                </div>
                              </div>
                            </div>*/}
                          </div>}
                          {step == 1 && <div class="pb-5">
                            <h4 class="mb-10 font-weight-bold text-dark">Setup Your Current Location</h4>
                            <div class="row">
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Address Line 1</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="address1" placeholder="Address Line 1" value="Address Line 1" />
                                  <span class="form-text text-muted">Please enter your Address.</span>
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Address Line 2</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="address2" placeholder="Address Line 2" value="Address Line 2" />
                                  <span class="form-text text-muted">Please enter your Address.</span>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Postcode</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="postcode" placeholder="Postcode" value="3000" />
                                  <span class="form-text text-muted">Please enter your Postcode.</span>
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>City</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="city" placeholder="City" value="Melbourne" />
                                  <span class="form-text text-muted">Please enter your City.</span>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>State</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="state" placeholder="State" value="VIC" />
                                  <span class="form-text text-muted">Please enter your State.</span>
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Country</label>
                                  <select name="country" class="form-control form-control-solid form-control-lg">
                                    <option value="">Select</option>
                                    <option value="AF">Afghanistan</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>}
                          {step == 2 && <div class="pb-5">
                            <h4 class="mb-10 font-weight-bold text-dark">Select your Services</h4>
                            <div class="form-group">
                              <label>Delivery Type:</label>
                              <select name="delivery" class="form-control form-control-solid form-control-lg">
                                <option value="">Select a Service Type Option</option>
                                <option value="overnight" selected="selected">Overnight Delivery (within 48 hours)</option>
                                <option value="express">Express Delivery (within 5 working days)</option>
                                <option value="basic">Basic Delivery (within 5 - 10 working days)</option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label>Packaging Type:</label>
                              <select name="packaging" class="form-control form-control-solid form-control-lg">
                                <option value="">Select a Packaging Type Option</option>
                                <option value="regular" selected="selected">Regular Packaging</option>
                                <option value="oversized">Oversized Packaging</option>
                                <option value="fragile">Fragile Packaging</option>
                                <option value="frozen">Frozen Packaging</option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label>Preferred Delivery Window:</label>
                              <select name="preferreddelivery" class="form-control form-control-solid form-control-lg">
                                <option value="">Select a Preferred Delivery Option</option>
                                <option value="morning" selected="selected">Morning Delivery (8:00AM - 11:00AM)</option>
                                <option value="afternoon">Afternoon Delivery (11:00AM - 3:00PM)</option>
                                <option value="evening">Evening Delivery (3:00PM - 7:00PM)</option>
                              </select>
                            </div>
                          </div>}
                          {step == 3 && <div class="pb-5">
                            <h4 class="mb-10 font-weight-bold text-dark">Setup Your Delivery Location</h4>
                            <div class="row">
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Address Line 1</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="locaddress1" placeholder="Address Line 1" value="Address Line 1" />
                                  <span class="form-text text-muted">Please enter your Address.</span>
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Address Line 2</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="locaddress2" placeholder="Address Line 2" value="Address Line 2" />
                                  <span class="form-text text-muted">Please enter your Address.</span>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Postcode</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="locpostcode" placeholder="Postcode" value="3072" />
                                  <span class="form-text text-muted">Please enter your Postcode.</span>
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>City</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="loccity" placeholder="City" value="Preston" />
                                  <span class="form-text text-muted">Please enter your City.</span>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>State</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="locstate" placeholder="State" value="VIC" />
                                  <span class="form-text text-muted">Please enter your state.</span>
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Country</label>
                                  <select name="loccountry" class="form-control form-control-solid form-control-lg">
                                    <option value="">Select</option>
                                    <option value="AF">Afghanistan</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>}
                          {step == 4 && <div class="pb-5">
                            <h4 class="mb-10 font-weight-bold text-dark">Enter your Payment Details</h4>
                            <div class="row">
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Name on Card</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="ccname" placeholder="Card Name" value="John Wick" />
                                  <span class="form-text text-muted">Please enter your Card Name.</span>
                                </div>
                              </div>
                              <div class="col-xl-6">
                                <div class="form-group">
                                  <label>Card Number</label>
                                  <input type="text" class="form-control form-control-solid form-control-lg" name="ccnumber" placeholder="Card Number" value="4444 3333 2222 1111" />
                                  <span class="form-text text-muted">Please enter your Address.</span>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xl-4">
                                <div class="form-group">
                                  <label>Card Expiry Month</label>
                                  <input type="number" class="form-control form-control-solid form-control-lg" name="ccmonth" placeholder="Card Expiry Month" value="01" />
                                  <span class="form-text text-muted">Please enter your Card Expiry Month.</span>
                                </div>
                              </div>
                              <div class="col-xl-4">
                                <div class="form-group">
                                  <label>Card Expiry Year</label>
                                  <input type="number" class="form-control form-control-solid form-control-lg" name="ccyear" placeholder="Card Expire Year" value="21" />
                                  <span class="form-text text-muted">Please enter your Card Expiry Year.</span>
                                </div>
                              </div>
                              <div class="col-xl-4">
                                <div class="form-group">
                                  <label>Card CVV Number</label>
                                  <input type="password" class="form-control form-control-solid form-control-lg" name="cccvv" placeholder="Card CVV Number" value="123" />
                                  <span class="form-text text-muted">Please enter your Card CVV Number.</span>
                                </div>
                              </div>
                            </div>
                          </div>}
                          {step == 5 && <div class="pb-5">
                            <h4 class="mb-10 font-weight-bold text-dark">Review your Details and Submit</h4>
                            <h6 class="font-weight-bolder mb-3">Current Address:</h6>
                            <div class="text-dark-50 line-height-lg">
                              <div>Address Line 1</div>
                              <div>Address Line 2</div>
                              <div>Melbourne 3000, VIC, Australia</div>
                            </div>
                            <div class="separator separator-dashed my-5"></div>
                            <h6 class="font-weight-bolder mb-3">Delivery Details:</h6>
                            <div class="text-dark-50 line-height-lg">
                              <div>Package: Complete Workstation (Monitor, Computer, Keyboard &amp; Mouse)</div>
                              <div>Weight: 25kg</div>
                              <div>Dimensions: 110cm (w) x 90cm (h) x 150cm (L)</div>
                            </div>
                            <div class="separator separator-dashed my-5"></div>
                            <h6 class="font-weight-bolder mb-3">Delivery Service Type:</h6>
                            <div class="text-dark-50 line-height-lg">
                              <div>Overnight Delivery with Regular Packaging</div>
                              <div>Preferred Morning (8:00AM - 11:00AM) Delivery</div>
                            </div>
                            <div class="separator separator-dashed my-5"></div>
                            <h6 class="font-weight-bolder mb-3">Delivery Address:</h6>
                            <div class="text-dark-50 line-height-lg">
                              <div>Address Line 1</div>
                              <div>Address Line 2</div>
                              <div>Preston 3072, VIC, Australia</div>
                            </div>
                          </div>}
                          <div class="d-flex justify-content-between border-top mt-5 pt-10">
                            <div class="mr-2">
                              {step > 0 && <button type="button" class="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4">Previous</button>}
                            </div>
                            <div>
                              {step == 5 && <button type="button" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4">Submit</button>}
                              {step < 5 && <button type="button" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" onClick={() => setStep(step + 1)}>Next</button>}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}
