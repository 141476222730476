import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

import { createPriceSchema } from "../_schemas/createPriceSchema";

import { notifySuccess } from "../../../utils/Notify";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const CreatePrice = (props) => {
  const dispatch = useDispatch();
  const { prices, services } = useSelector((state) => ({
    prices: state.prices,
    services: state.services,
  }), shallowEqual);

  const classes = useStyles();

  const [showClient, setShowClient] = useState(false);
  const [weekdays, setWeekdays] = useState([2,4,8,16,32,64,1]);

  useEffect(() => {
    if (prices.created == true) {
      close();
      notifySuccess('Cadastrado com sucesso', 'proces-created', 2000);
    }
  }, [prices]);

  const close = () => {
    props.onHide();
    setShowClient(false);

    formik.setFieldValue('startAt', '00:00', false);
    formik.setFieldValue('finishAt', '23:59', false);
    formik.setFieldValue('time', '60', false);
    formik.setFieldValue('price', '', false);
    formik.setFieldValue('weekdays', [2,4,8,16,32,64,1], false);
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const ValueLabelComponent = (props) => {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={`${value}C*`}>
        {children}
      </Tooltip>
    );
  }

  const renderError = (error) => {
    return (error ? (
      <Grid item xs={12}>
        <div className="fv-plugins-message-container">
          {(typeof error) == "string" &&
            <div className="fv-help-block">{error}</div>
          }

          {Array.isArray(error) && error.map((item) =>
            <div className="fv-help-block">{item}</div>
          )}
        </div>
      </Grid>
    ) : null);
  }

  const formik = useFormik({
    initialValues: {
      // serviceId: '',
      startAt: '00:00',
      finishAt: '23:59',
      time: '60',
      price: '',
      priceQuarterly: '',
      priceSemester: '',
      priceYearly: '',
      weekdays: [2,4,8,16,32,64,1]
    },
    validationSchema: createPriceSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      if(weekdays.length == 0) {
        setSubmitting(false);

        return;
      }

      values.weekday = weekdays.reduce((x, y) => x + y, 0);
      values.selectedWeekdays = weekdays;
      // values.serviceId = props.service;

      // dispatch(fetchCreatePrice(values));
      props.onSubmit(values);

      setSubmitting(false);
    },
  });

  const selectedWeekDayColor = (weekday) => {
    return weekdays.includes(weekday) ? "primary" : "default";
  }

  const selectWeekDay = (weekday) => {
    if(!weekdays.includes(weekday)) {
      setWeekdays([...weekdays, weekday]);
    } else{
        var array = [...weekdays];
        var index = array.indexOf(weekday);

        if (index !== -1) {
          array.splice(index, 1);

          setWeekdays(array);
        }
    }
  }

  const sliderLabel = (value) => {
    return `${value}°C`;
  };

  const type = [
    { label: 'Avulso', value: 'single' },
    { label: 'Mensalista', value: 'recurrence' },
  ];

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cadastrar preço
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="sm">
            {/*<Grid container className={classes.label}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Dados de exibição
                </span>
              </Grid>
            </Grid>*/}

            <Grid container className={classes.label} spacing={2}>
              {/*<Grid item xs={12}>
                <TextField
                  select
                  label="Serviço"
                  variant="outlined"
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      }
                    }
                  }}
                  name="serviceId"
                  fullWidth
                  className={classes.textField}
                  {...formik.getFieldProps("serviceId")}
                >
                  {services.entities.map((item) => <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>)}
                </TextField>

                {formik.touched.serviceId && formik.errors.serviceId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.serviceId}</div>
                  </div>
                ) : null}

                {prices.error && renderError(prices.error.service_id)}
              </Grid>*/}

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Defina o período <Icon className={classes.icon}>info_outlined</Icon>
                </span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="startAt"
                  label="Horario Inicio"
                  type="time"
                  fullWidth
                  step={15}
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: "900", // 15 min
                  }}
                  {...formik.getFieldProps("startAt")}
                />

                {prices.error && renderError(prices.error.start_at)}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="time"
                  label="Tempo"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  type="number"
                  min="1"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
                  }}
                  {...formik.getFieldProps("time")}
                />

                {services.error && renderError(services.error.time)}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="finishAt"
                  label="Horario Fim"
                  type="time"
                  fullWidth
                  step={15}
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: "900", // 15 min
                  }}
                  {...formik.getFieldProps("finishAt")}
                />

                {prices.error && renderError(prices.error.finish_at)}
              </Grid>
            </Grid>

            <Grid container className={classes.label}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Selecione os dias da semana
                </span>
              </Grid>

              <Grid container className={classes.label} spacing={1}>
                <Grid item xs><Chip onClick={() => selectWeekDay(2)} clickable color={selectedWeekDayColor(2)} variant="outlined" label="Seg" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(4)} clickable color={selectedWeekDayColor(4)} variant="outlined" label="Ter" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(8)} clickable color={selectedWeekDayColor(8)} variant="outlined" label="Qua" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(16)} clickable color={selectedWeekDayColor(16)} variant="outlined" label="Qui" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(32)} clickable color={selectedWeekDayColor(32)} variant="outlined" label="Sex" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(64)} clickable color={selectedWeekDayColor(64)} variant="outlined" label="Sab" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(1)} clickable color={selectedWeekDayColor(1)} variant="outlined" label="Dom" /></Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Defina o preço
                </span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="price"
                  label={props.type == 'single' ? `Preço por ${formik.values.time}min` : 'Mensal *'}
                  fullWidth
                  className={classes.textField}
                  placeholder="ex: 260"
                  variant="outlined"
                  type="number"
                  min="1"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  {...formik.getFieldProps("price")}
                />

                {prices.error && renderError(prices.error.price)}
              </Grid>

              {props.type && props.type =='single' && <Grid item xs={6}>
                <TextField
                  name="memberPrice"
                  label={'Preço para alunos'}
                  fullWidth
                  className={classes.textField}
                  placeholder="ex: 240"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  {...formik.getFieldProps("memberPrice")}
                />

                {prices.error && renderError(prices.error.member_price)}
              </Grid>}

              {props.type && props.type =='recurrence' && <>
                <Grid item xs={6}>
                  <TextField
                    name="priceQuarterly"
                    label="Trimestral / por mês"
                    fullWidth
                    className={classes.textField}
                    placeholder="ex: 240"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    {...formik.getFieldProps("priceQuarterly")}
                  />

                  {prices.error && renderError(prices.error.price_quarterly)}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="priceSemester"
                    label="Semestral / por mês"
                    placeholder="ex: 220"
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    {...formik.getFieldProps("priceSemester")}
                  />

                  {prices.error && renderError(prices.error.price_semester)}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="priceYearly"
                    label="Anual / por mês"
                    placeholder="ex: 200"
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    {...formik.getFieldProps("priceYearly")}
                  />

                  {prices.error && renderError(prices.error.price_yearly)}
                </Grid>
              </>}
            </Grid>

            {(typeof prices.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {prices.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={formik.isSubmitting}>
                Cadastrar {(formik.isSubmitting || prices.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>
  </>);
};

export default CreatePrice;