import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from "moment/min/moment-with-locales";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { Grid, TextField, Container, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  Modal,
  Alert,
} from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import {
  fetchUpdateClient,
  fetchUpdateClientCompany,
  fetchUpdateClientPhoto,
  fetchGetClientById,
  fetchForceDeleteClient
} from "../_redux/clientActions";

import SelectClient from "./SelectClient";
import ClientPhoto from "./ClientPhoto";
import { createClientSchema } from "../_schemas/createClientSchema";
import { hasPermission } from "../../../utils/Permission";
import { notifySuccess } from "../../../utils/Notify";
import ContractsList from "./ContractsList";
import SignaturesList from "./SignaturesList";
import DayUseList from "./DayUseList";
import {CreditList} from "../../Credit/components/CreditList";
import InvoicePage from "../../Invoice/pages/InvoicePage";
import { getAge } from "../../../components/DateUtils";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    //
  },
}));

const TextFieldPhoneInput = React.forwardRef((props, ref) => (
  <TextField {...props} inputRef={ref} />
));

const EditClient = (props) => {
  const dispatch = useDispatch();
  const { clients, branchSelected, user } = useSelector(
    (state) => ({
      clients: state.clients,
      branchSelected: state.auth.branchSelected,
      user: state.auth.user,
    }),
    shallowEqual
  );

  const classes = useStyles();

  const [verified, setVerified] = useState(false);
  const [tab, setTab] = useState("info");
  const [modality, setModality] = useState(null);
  const [type, setType] = useState(null);
  const [openSelectClient, setOpenSelectClient] = useState(false);
  const [openClientPhoto, setOpenClientPhoto] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [dependents, setDependents] = useState([]);

  const [editContractShow, setEditContractShow] = useState(false);
  const [editContract, setEditContract] = useState({});
  const [client, setClient] = useState({});

  const sports = [
    { value: "1", label: "Futebol" },
    { value: "2", label: "Beach tennis" },
    { value: "3", label: "Tennis" },
    { value: "4", label: "Padel" },
    { value: "5", label: "Pickleball" },
  ];


  const menu = [
    {
      label: 'Dados',
      path: 'info',
      permission: 'client-update'
    },
    {
      label: 'Dados adicionais',
      path: 'sports',
      permission: 'client-update'
    },
    {
      label: 'Clubinho',
      path: 'signatures',
      permission: 'signature-list'
    },
    {
      label: 'Day use',
      path: 'day_use',
      permission: 'day_use-list'
    },
    // {
    //   label: 'Pacotes créditos',
    //   path: '/pacotes-de-creditos',
    //   permission: 'credit-purchase-list'
    // },
    {
      label: 'Créditos',
      path: 'credits',
      permission: 'credit-list'
    },
    {
      label: 'Locações',
      path: 'contracts-reservation-single',
      modality: 'reservation',
      type: 'single',
      permission: 'contract-list'
    },
    {
      label: 'Locações mensal',
      path: 'contracts-reservation-recurrence',
      modality: 'reservation',
      type: 'recurrence',
      permission: 'contract-list'
    },
    {
      label: 'Aulas avulso',
      path: 'contracts-class-single',
      modality: 'class',
      type: 'single',
      permission: 'contract-list'
    },
    {
      label: 'Aulas mensal',
      path: 'contracts-class-recurrence',
      modality: 'class',
      type: 'recurrence',
      permission: 'contract-list'
    },
    {
      label: 'Excluir cliente',
      path: 'force-delete',
      permission: 'full-access'
    },
    // {
    //   label: 'Faturas',
    //   path: '/faturas',
    //   permission: 'invoice-list'
    // },
  ];

  useEffect(() => {
    if (clients.updated === true) {
      close();

      notifySuccess("Atualizado com sucesso", "clients-updated", 2000);
    }

    if (clients.photoUpdated === true) {
      dispatchFetchClient(formik.values.clientId);

      notifySuccess(
        "Foto atualizada com sucesso",
        "clients-photo-updated",
        2000
      );
    }
  }, [clients]);

  useEffect(() => {
    if (props.show && props.event && props.event.id) {
      dispatchFetchClient(props.event.id);
    }
  }, [props.event, props.show]);

  const dispatchFetchClient = (clientId) => {
    if (clientId) {
      formik.setFieldValue("clientId", clientId, false);
      dispatch(fetchGetClientById(clientId, branchSelected.id)).then(
        (value) => {
          formik.setFieldValue("phone", "", false);
          formik.setFieldValue("name", "", false);
          formik.setFieldValue("email", "", false);
          formik.setFieldValue("cpf", "", false);
          formik.setFieldValue("birthday", "", false);
          formik.setFieldValue("photo", "", false);
          formik.setFieldValue("skills", "", false);
          formik.setFieldValue("external_id", "", false);
          formik.setFieldValue("created_at", "", false);

          setClient(value);
          setDependents([]);
          setSelectedClient({});

          formik.setFieldValue("phone", value.attributes.phone, false);
          formik.setFieldValue("name", value.attributes.name, false);
          formik.setFieldValue("email", value.attributes.email, false);
          formik.setFieldValue("cpf", value.attributes.cpf, false);
          formik.setFieldValue("birthday", value.attributes.birthday, false);
          formik.setFieldValue("photo", value.attributes.photo, false);
          formik.setFieldValue("created_at", value.attributes.created_at, false);
          formik.setFieldValue(
            "external_id",
            value.attributes.external_id,
            false
          );

          let skills = [];

          value.attributes.skills &&
            value.attributes.skills.map((value) => {
              skills[value.sport] = value.skill;
            });

          formik.setFieldValue("skills", skills, false);

          setVerified(value.attributes.verified);

          if (value.relationships.dependents) {
            setDependents(value.relationships.dependents);
          }

          if (value.relationships.responsible) {
            setSelectedClient(value.relationships.responsible);
          }
        }
      );
    }
  };

  const isClient = () => {
    return props.type === "instructor" ? null : true;
  };

  const close = () => {
    props.onHide();
    setTab("info");

    formik.setFieldValue("phone", "", false);
    formik.setFieldValue("name", "", false);
    formik.setFieldValue("email", "", false);
    formik.setFieldValue("cpf", "", false);
    formik.setFieldValue("photo", "", false);
    formik.setFieldValue("skills", "", false);

    setSelectedClient({});
  };

  const selectClient = (client) => {
    setSelectedClient(client);
    closeOpenSelectClient();
  };

  const updateClientPhoto = (photo) => {
    console.log(photo);

    setOpenClientPhoto(false);

    dispatch(
      fetchUpdateClientPhoto(formik.values.clientId, {
        image: photo,
      })
    );
  };

  const forceDeleteClient = () => {
    dispatch(
      fetchForceDeleteClient(formik.values.clientId)
    );
  };

  const clearOpenSelectClient = () => {
    if (selectedClient && selectedClient.id) {
      setSelectedClient({});
    } else {
      setOpenSelectClient(!openSelectClient);
    }
  };

  const closeOpenSelectClient = () => {
    setOpenSelectClient(false);
  };

  const openEditContract = (value) => {
    setEditContractShow(true);
    setEditContract(value);
  };

  const closeEditContract = () => {
    setEditContractShow(false);
    setEditContract({});
  };

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value,
    };
  };

  const beforeMaskedCpfChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    }

    return {
      ...newState,
      value,
    };
  };

  const renderError = (error) => {
    return error ? (
      <Grid item xs={12}>
        <div className="fv-plugins-message-container">
          {typeof error === "string" && (
            <div className="fv-help-block">{error}</div>
          )}

          {Array.isArray(error) &&
            error.map((item) => <div className="fv-help-block">{item}</div>)}
        </div>
      </Grid>
    ) : null;
  };

  const formik = useFormik({
    initialValues: {
      clientId: "",
      name: "",
      email: "",
      phone: "",
      cpf: "",
      photo: "",
      skills: "",
    },
    validationSchema: createClientSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        let params = {
          name: values.name,
          responsible_id: selectedClient.id ?? "",
        };

        if (values.email) {
          params.email = values.email;
        }

        if (values.phone) {
          params.phone = values.phone;
        }

        dispatch(fetchUpdateClient(values.clientId, params));

        setSubmitting(false);
      }, 500);
    },
  });

  const updateClientCompany = () => {
    let params = {
      cpf: formik.values.cpf ?? "",
    };

    if (formik.values.birthday) {
      params.birthday = formik.values.birthday
    }

    if (formik.values.skills) {
      params.skills = formik.values.skills
        .map((value, index) => {
          return {
            sport: index,
            skill: value,
          };
        })
        .filter(function(element) {
          return element.skill !== undefined && element.skill !== "0";
        });
    }

    dispatch(fetchUpdateClientCompany(formik.values.clientId, params));
  };

  const resolveTab = (value) => {
    setTab(value.path);

    if (value.modality) {
      setModality(value.modality);
    }

    if (value.type) {
      setType(value.type);
    }
  }

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        backdrop="static"
        keyboard={true}
        size="xl"
        contenStyle={{
          maxWidth: "1000px",
          backgroundColor: "transparent !important",
        }}
        onHide={() => close()}
        show={props.show && !editContractShow && !openClientPhoto}
      >
        <div className="card card-custom card-transparent">
          <CloseIcon
            onClick={close}
            className="mt-3 mr-3 cursor-pointer"
            style={{
              alignSelf: "flex-end",
              position: "absolute",
              right: 10,
              top: 10,
              zIndex: 999,
            }}
          />

          <div className="row">
            <div className="col-lg-3">
              <div className='card-body pb-15'>
                <ul className="navi navi-link-rounded navi-accent navi-hover navi-active nav flex-column mb-8 mb-lg-0 mt-5" role="tablist">
                  {menu.map(data => {
                    return hasPermission(user, data.permission) && <li className="navi-item mb-2">
                      <span className={`cursor-pointer navi-link ${tab == data.path && `active`}`} onClick={() => resolveTab(data)}>
                        <span className={`navi-text ${tab == data.path ? 'text-dark' : 'text-dark-50'} font-size-h5 font-weight-bold`}>{data.label}</span>
                      </span>
                    </li>
                  })}
                </ul>
              </div>
            </div>

            <div className="col-lg-9">
              {tab === "info" && (
                <form
                  className="form"
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                  variant="outlined"
                >
                  <div className="pt-10 pb-10 pr-5">
                    <div className="row">
                      <div className="col-lg-6 col-xl-6 col-xxl-6">
                        <Container maxWidth="sm">
                          <Grid container className={classes.label}>
                            <Grid item xs={12}>
                              <div
                                class="image-input image-input-outline image-input-circle cursor-pointer"
                                id="kt_user_avatar"
                              >
                                <div
                                  className="image-input-wrapper"
                                  style={{
                                    backgroundPosition: "center",
                                    backgroundImage: `url(${formik.values.photo ??
                                      toAbsoluteUrl("/media/users/blank.png")})`,
                                  }}
                                ></div>
                                <label
                                  className="btn btn-sm btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                  data-action="change"
                                  onClick={() => setOpenClientPhoto(true)}
                                >
                                  <i className="flaticon-photo-camera icon-md text-dark-75"></i>
                                </label>
                                {formik.values.photo && (
                                  <span
                                    className="btn btn-sm btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                    data-action="remove"
                                  >
                                    <i className="fas fa-expand-arrows-alt icon-sm text-dark-75"></i>
                                  </span>
                                )}
                              </div>
                            </Grid>
                          </Grid>

                          <Grid container className={classes.label}>
                            <Grid item xs={6}>
                              <span className="font-weight-bolder text-dark">
                                Dados do {isClient() ? "cliente" : "professor"}
                              </span>
                            </Grid>
                            <Grid item xs={6} className="text-right">
                              {client &&
                                client.attributes &&
                                client.attributes.is_dependent && (
                                  <span
                                    className={`ml-2 label label-lg label-light label-inline`}
                                  >
                                    Dependente
                                  </span>
                                )}

                              {client &&
                                client.attributes &&
                                client.attributes.type === "instructor" && (
                                  <span
                                    className={`ml-2 label label-lg label-light-success label-inline`}
                                  >
                                    Professor
                                  </span>
                                )}

                              {client &&
                                client.attributes &&
                                client.attributes.is_member && (
                                  <span
                                    className={`ml-2 label label-lg label-light-primary label-inline`}
                                  >
                                    Aluno
                                  </span>
                                )}
                            </Grid>
                          </Grid>

                          <Grid container className={classes.label} spacing={2}>
                            <TextField
                              type="hidden"
                              disabled={true}
                              name="clientId"
                              {...formik.getFieldProps("clientId")}
                            />
                            <Grid item xs={12}>
                              <TextField
                                name="name"
                                label="Nome *"
                                fullWidth
                                className={`${classes.textField} is-invalid`}
                                variant="outlined"
                                autoComplete="new-password"
                                disabled={verified}
                                {...formik.getFieldProps("name")}
                              />

                              {clients.error && renderError(clients.error.name)}

                              {formik.touched.name && formik.errors.name ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.name}
                                  </div>
                                </div>
                              ) : null}
                            </Grid>
                            <Grid item xs={12}>
                              <PhoneInput
                                key="client-phone"
                                defaultCountry="BR"
                                international
                                countryCallingCodeEditable={false}
                                withCountryCallingCode
                                numberInputProps={{ className: classes.textField }}
                                value={formik.values.phone ?? ''}
                                onChange={(value) => formik.setFieldValue('phone', value, false)}
                                inputComponent={TextFieldPhoneInput}
                                disabled={verified}
                                fullWidth
                                label={
                                  selectedClient.id ? "Telefone" : "Telefone *"
                                }
                                variant="outlined"
                                autoComplete="new-password"
                              />

                              {formik.values.phone && <a
                                href={"https://wa.me/"+formik.values.phone}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="py-5"
                              >
                                <i className="socicon-whatsapp px-1"></i> Abrir WhatsApp
                              </a>}

                              {/*<InputMask
                                disabled={verified}
                                mask={
                                  formik.values.phone <= 11
                                    ? "(99) 99999-9999"
                                    : "+99 (99) 99999-9999"
                                }
                                maskChar={null}
                                beforeMaskedValueChange={beforeMaskedValueChange}
                                autoComplete="new-password"
                                {...formik.getFieldProps("phone")}
                              >
                                {() => (
                                  <TextField
                                    fullWidth
                                    name="phone"
                                    label={
                                      selectedClient.id ? "Telefone" : "Telefone *"
                                    }
                                    className={classes.textField}
                                    variant="outlined"
                                    autoComplete="new-password"
                                    disabled={verified}
                                  />
                                )}
                              </InputMask>*/}

                              {clients.error && renderError(clients.error.phone)}

                              {formik.touched.phone && formik.errors.phone ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.phone}
                                  </div>
                                </div>
                              ) : null}
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                name="email"
                                label={selectedClient.id ? "E-mail" : "E-mail *"}
                                fullWidth
                                className={classes.textField}
                                variant="outlined"
                                autoComplete="new-password"
                                disabled={verified}
                                {...formik.getFieldProps("email")}
                              />

                              {clients.error && renderError(clients.error.email)}

                              {formik.touched.email && formik.errors.email ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.email}
                                  </div>
                                </div>
                              ) : null}
                            </Grid>
                          </Grid>

                          {formik && formik.values && formik.values.email && formik.values.email.includes('privaterelay.appleid.com') && <Grid item xs={12}>
                            <Alert key="alert-accept-schedule" variant="info">
                              Esse cliente optou por ocultar seu e-mail
                            </Alert>
                          </Grid>}

                          {verified && (
                            <Grid item xs={12}>
                              <Alert key="alert-accept-schedule" variant="primary">
                                Esse cliente entrou pelo aplicativo.
                                <br />
                                Somente ele pode alterar as informações pessoais
                              </Alert>
                            </Grid>
                          )}

                          {typeof clients.error === "string" && (
                            <Grid item xs={12}>
                              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                                <div className="d-flex flex-column flex-grow-1 mr-2">
                                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                                    {clients.error}
                                  </span>
                                </div>
                              </div>
                            </Grid>
                          )}
                        </Container>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-xxl-6">
                        {isClient() && (
                          <>
                            {dependents.length === 0 && (
                              <>
                                <Grid item xs={12}>
                                  <div class="mt-3 text-dark">
                                    Cliente é dependente?
                                  </div>
                                  <span
                                    className={`switch switch-sm ${
                                      openSelectClient ||
                                      (selectedClient && selectedClient.id)
                                        ? "switch-success"
                                        : "switch-disabled"
                                    }`}
                                  >
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="dependent"
                                        checked={
                                          openSelectClient ||
                                          (selectedClient && selectedClient.id)
                                        }
                                        onChange={(value) =>
                                          clearOpenSelectClient()
                                        }
                                        disabled={verified || !hasPermission(user, 'client-update')}
                                      />
                                      <span></span>
                                    </label>
                                  </span>
                                </Grid>
                                {selectedClient && selectedClient.id && (
                                  <Grid item xs={12}>
                                    <div class="mt-5 font-weight-bold text-dark">
                                      Responsável
                                    </div>

                                    <div class="mt-3 text-dark">
                                      Nome: {selectedClient.attributes.name}
                                    </div>
                                    <div class="mt-3 text-dark">
                                      E-mail: {selectedClient.attributes.email}
                                    </div>
                                    <div class="mt-3 text-dark">
                                      Telefone: {selectedClient.attributes.phone}
                                    </div>
                                    <div class="card-toolbar">
                                      <a
                                        class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-2"
                                        onClick={() =>
                                          dispatchFetchClient(selectedClient.id)
                                        }
                                      >
                                        Ver cliente
                                      </a>
                                    </div>
                                  </Grid>
                                )}
                              </>
                            )}

                            {dependents && dependents.length > 0 && (
                              <Grid item xs={12}>
                                <div class="mt-5 font-weight-bold text-dark">
                                  Dependentes
                                </div>

                                {dependents.map((dependent) => (
                                  <>
                                    <div className="separator separator-dashed my-3"></div>
                                    <div>
                                      <div class="mt-3 text-dark">
                                        Nome: {dependent.attributes.name}
                                      </div>
                                      <div class="mt-3 text-dark">
                                        E-mail: {dependent.attributes.email}
                                        {!dependent.attributes.email && `-`}
                                      </div>
                                      <div class="mt-3 text-dark">
                                        Telefone: {dependent.attributes.phone}
                                        {!dependent.attributes.phone && `-`}
                                      </div>
                                    </div>
                                    <div>
                                      <div class="card-toolbar">
                                        <a
                                          class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-2"
                                          onClick={() =>
                                            dispatchFetchClient(dependent.id)
                                          }
                                        >
                                          Ver cliente
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </Grid>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between border-top mt-5 pt-10">
                      {!verified && (
                        <>
                          <div className="mr-2">
                            <button
                              type="button"
                              className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                              onClick={() => close()}
                            >
                              Fechar
                            </button>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                              data-wizard-type="action-next"
                              disabled={
                                formik.isSubmitting || clients.actionsLoading || !hasPermission(user, 'client-update')
                              }
                              onClick={formik.current?.submitForm}
                            >
                              Salvar dados{" "}
                              {(formik.isSubmitting || clients.actionsLoading) && (
                                <span className="ml-2 spinner spinner-white"></span>
                              )}
                            </button>
                          </div>
                        </>
                      )}

                      {verified && (
                        <>
                          <div className="mr-2" />
                          <div className="mr-2">
                            <button
                              type="button"
                              className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4 center"
                              onClick={() => close()}
                            >
                              Fechar
                            </button>
                          </div>
                          <div className="mr-2" />
                        </>
                      )}
                    </div>
                  </div>
                </form>
              )}

              {tab === "sports" && (
                <div className="pt-10 pb-10 pr-5">
                  <div class="row">
                    <div class="col-lg-5">
                      <div className="row justify-content-center px-4 py-lg-7 px-lg-5">
                        <div className="col-lg-10 col-xl-10 col-xxl-10">
                          <div class="mb-10 font-weight-bold text-dark">
                            Identificação
                          </div>

                          <InputMask
                            fullWidth
                            mask="999.999.999-99"
                            maskChar={null}
                            beforeMaskedValueChange={beforeMaskedCpfChange}
                            autoComplete="cpf"
                            {...formik.getFieldProps("cpf")}
                          >
                            {() => (
                              <TextField
                                fullWidth
                                name="cpf"
                                label="Cpf"
                                className={classes.textField}
                                variant="outlined"
                                autoComplete="cpf"
                              />
                            )}
                          </InputMask>

                          {clients.error && renderError(clients.error.cpf)}

                          {formik.touched.cpf && formik.errors.cpf ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.cpf}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="row justify-content-center px-4 py-lg-7 px-lg-5">
                        <div className="col-lg-10 col-xl-10 col-xxl-10">
                          <div class="mb-10 font-weight-bold text-dark">
                            Data nascimento
                          </div>

                          <TextField
                            name="birthday"
                            label={'Data de nascimento'}
                            type="date"
                            fullWidth
                            className={classes.textField}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            {...formik.getFieldProps("birthday")}
                          />

                          {formik.values.birthday && <span className="py-5">
                            Aluno(a) tem {getAge(formik.values.birthday)} anos
                          </span>}

                          {clients.error && renderError(clients.error.birthday)}

                          {formik.touched.birthday && formik.errors.birthday ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.birthday}
                              </div>
                            </div>
                          ) : null}
                        </div>

                        {/*<div className="col-lg-10 col-xl-10 col-xxl-10 pt-10">
                      {formik.values.external_id && <div class="row w-100">
                        <label>Id externo</label>
                        <div class="input-group">
                          <input type="text" class="form-control" value={formik.values.external_id} />
                          <div class="input-group-append">
                            <button class="btn btn-primary" type="button" onClick={() => console.log(formik.values.external_id)}>Copiar</button>
                          </div>
                        </div>
                      </div>}
                    </div>*/}

                        <div className="col-lg-10 col-xl-10 col-xxl-10">
                          <div class="mt-5 text-dark">
                            Cadastrado em {moment(formik.values.created_at).isValid()
                                ? moment(formik.values.created_at).format(
                                    "DD/MM/YYYY HH:mm"
                                  )
                                : "--"}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-7">
                      <div className="row justify-content-center py-lg-7 px-5">
                        <div className="col-lg-10 col-xl-10 col-xxl-10">
                          <div class="mb-10 font-weight-bold text-dark">
                            Nível de habilidade por esporte
                          </div>

                          {sports.map((sport) => (
                            <div class="form-group">
                              <label>{sport.label}</label>
                              <select
                                name={`skills[${sport.value}]`}
                                class="form-control"
                                {...formik.getFieldProps(`skills.${sport.value}`)}
                              >
                                <option value="0">Não informado</option>
                                <option value="1">Iniciante</option>
                                <option value="2">Intermediario</option>
                                <option value="3">Avançado</option>
                                <option value="4">Profissional</option>
                              </select>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between border-top mt-5 pt-10">
                    <div className="mr-2">
                      <button
                        type="button"
                        className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                        onClick={() => close()}
                      >
                        Fechar
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                        disabled={formik.isSubmitting || clients.actionsLoading}
                        onClick={() => updateClientCompany()}
                      >
                        Salvar dados adicionais
                        {(formik.isSubmitting || clients.actionsLoading) && (
                          <span className="ml-2 spinner spinner-white"></span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {(tab === 'contracts-reservation-single' ||
                tab === 'contracts-reservation-recurrence' ||
                tab === 'contracts-class-single' ||
                tab === 'contracts-class-recurrence') &&
                modality &&
                type && (

                <div className="pt-10 pb-10 pr-5">
                  <div className="row justify-content-center px-4 py-lg-7 px-lg-5">
                    <div className="col-lg-12">
                      <div className="mb-10">
                        <ContractsList
                          client_id={formik.values.clientId}
                          modality={modality}
                          type={type}
                          onEdit={(value) => openEditContract(value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {tab === "signatures" && (
                <div className="pt-10 pb-10 pr-5">
                  <div className="row justify-content-center px-4 py-lg-7 px-lg-5">
                    <div className="col-lg-12">
                      <div className="mb-10">
                        <SignaturesList client_id={formik.values.clientId} />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {tab === "day_use" && (
                <div className="pt-10 pb-10 pr-5">
                  <div className="row justify-content-center px-4 py-lg-7 px-lg-5">
                    <div className="col-lg-12">
                      <div className="mb-10">
                        <DayUseList client_id={formik.values.clientId} />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {tab === "credits" && (
                <div className="pt-10 pb-10 pr-5">
                  <div className="row justify-content-center px-4 py-lg-7 px-lg-5">
                    <div className="col-lg-12">
                      <div className="mb-10">
                        <CreditList onlyClientId={props.event.id} disableCreate={true} disableSearch={true} />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {tab === "force-delete" && (
                <div className="pt-10 pb-10 pr-5">
                  <div className="row justify-content-center px-4 py-lg-7 px-lg-5">
                    <div className="col-lg-6">
                      <div className="mb-5">
                        <Alert key="force-delete" variant="danger">
                          Somente para usuários full-access
                        </Alert>
                      </div>

                      <div className="mb-5">
                        <span className="font-weight-bolder text-dark mb-15 centered">
                          Vamos remover o e-mail, telefone, associação com todas empresas e unidades e mudar bloquear o cliente! Somente para clientes não verificados.
                        </span>
                      </div>

                      <button
                        type="button"
                        className="btn btn-light-danger font-weight-bolder text-uppercase px-9 py-4"
                        onClick={() => forceDeleteClient()}
                      >
                        DELETAR CLIENTE
                      </button>

                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <SelectClient
        show={openSelectClient}
        title={"Selecionar responsável pelo dependente"}
        selectClient={selectClient}
        onHide={() => closeOpenSelectClient(true)}
        style={{ backgroundColor: "rgba(0,0,0, 0.5)" }}
      />

      <InvoicePage
        show={editContractShow}
        onHide={closeEditContract}
        contract={editContract}
      />

      <ClientPhoto
        show={openClientPhoto}
        takePhoto={updateClientPhoto}
        onHide={() => setOpenClientPhoto(false)}
        style={{ backgroundColor: "rgba(0,0,0, 0.5)" }}
      />
    </>
  );
};

export default EditClient;
