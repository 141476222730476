import axios from "axios";

export const GET_TAGS = `${process.env.REACT_APP_API_URL}/tags`;
export const CREATE_TAG = `${process.env.REACT_APP_API_URL}/branches`;
export const UPDATE_TAG = `${process.env.REACT_APP_API_URL}/tags`;

export function getTags() {
  return axios.get(GET_TAGS);
}

export function createTag(branchId, name, groundType, maxPlayers, serviceId) {
  let params = {
    name: name,
    ground_type: groundType,
    max_players: maxPlayers,
    service_id: serviceId
  };

  return axios.post(`${CREATE_TAG}/${branchId}/tags`, params);
}

export function updateTag(tagId, branchId, name, groundType, maxPlayers, serviceId) {

  let params = {
    name: name,
    ground_type: groundType,
    max_players: maxPlayers,
    service_id: serviceId
  };

  return axios.put(`${UPDATE_TAG}/${tagId}`, params);
}