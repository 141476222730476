import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  created: false,
  updated: false,
  deleted: false,
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const logSlice = createSlice({
  name: "logs",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    logsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.created = false;
      state.updated = false;
      state.deleted = false;
    },

    logCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.created = true;
    },

    logUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.updated = true;
    },

    logDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.deleted = true;
    },
  }
});
