import * as requestFromServer from "./clientCrud";
import { clientSlice, callTypes } from "./clientSlice";
import { notifyError } from "../../../utils/Notify";

const { actions } = clientSlice;

export const fetchClients = (perPage = 10, page = 1, name = '', branchId, filter = false) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    per_page: perPage,
    page: page,
    type: 'client',
    filter: filter ? 1 : 0,
    branch_id: branchId,
  };

  if (name) {
    params.search = name;
  }

  return requestFromServer
    .getClients(params)
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.clientsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.clientsFetched({ totalCount: 0, entities: null }));
    });
};

export const fetchinstructors = (perPage = 10, page = 1, name = '', branchId, filter = false) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    per_page: perPage,
    page: page,
    type: 'instructor',
    filter: filter ? 1 : 0,
    branch_id: branchId,
  };

  if (name) {
    params.name = name;
  }

  return requestFromServer
    .getClients(params)
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.clientsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.clientsFetched({ totalCount: 0, entities: null }));
    });
};

export const fetchClientPhone = (phone) => dispatch => {
  if (!phone) {
    return dispatch(actions.clientPhoneFetched({ clientPhone: {} }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getClientByPhone(phone)
    .then(response => {
      const client = response.data;

      dispatch(actions.clientPhoneFetched({ clientPhone: client }));
    })
    .catch(error => {
      dispatch(actions.clientPhoneFetched({ clientPhone: {} }));
    });
};

export const fetchClientEmail = (email) => dispatch => {
  if (!email) {
    return dispatch(actions.clientEmailFetched({ clientEmail: {} }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getClientByEmail(email)
    .then(response => {
      const client = response.data;

      dispatch(actions.clientEmailFetched({ clientEmail: client }));
    })
    .catch(error => {
      dispatch(actions.clientEmailFetched({ clientEmail: {} }));
    });
};

export const clearClientEmail = () => dispatch => {
  dispatch(actions.clientEmailFetched({ clientEmail: {} }));
};

export const clearClientPhone = () => dispatch => {
  dispatch(actions.clientPhoneFetched({ clientPhone: {} }));
};

export const fetchClientName = (name, branchId, filter = false, type = 'client') => dispatch => {
  if (!name && type == 'client') {
    return dispatch(actions.clientNameFetched({ clientName: {} }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let params = {
    per_page: 20,
    page: 1,
    filter: filter ? 1 : 0,
    branch_id: branchId,
  };

  if (name) {
    params.name = name;
  }

  if (type) {
    params.type = type;
  }

  return requestFromServer
    .getClients(params)
    .then(response => {
      const client = response.data;
      dispatch(actions.clientNameFetched({ clientName: client }));
    })
    .catch(error => {
      dispatch(actions.clientNameFetched({ clientName: {} }));
    });
};

export const fetchGetClientById = (clientId, branchId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    branch_id: branchId
  };

  return new Promise((resolve, reject) => {
    requestFromServer
      .getClientById(clientId, params)
      .then(response => {
        dispatch(actions.clientFetched());
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);
      });
  });
};

export const fetchCreateClient = (values) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let params = {
    name: values.name,
    type: values.type,
  }

  if (values.phone) {
    params.phone = values.phone;
  }

  if (values.email) {
    params.email = values.email;
  }

  if (values.cpf) {
    params.cpf = values.cpf;
  }

  if (values.birthday) {
    params.birthday = values.birthday;
  }

  if (values.skills) {
    params.skills = values.skills;
  }

  if (values.branchId) {
    params.branch_id = values.branchId;
  }

  if (values.responsibleId) {
    params.responsible_id = values.responsibleId;
  }

  if (values.photo) {
    params.image = values.photo;
  }

  return requestFromServer
    .createClient(params)
    .then(response => {
      const client = response.data;

      dispatch(actions.clientCreated());
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAssociateClient = (clientId, branchId = '') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let params = {
    client_id: clientId,
  };

  if (branchId) {
    params.branch_id = branchId;
  }

  return requestFromServer
    .associateClient(branchId, params)
    .then(response => {
      const client = response.data;

      dispatch(actions.clientCreated());
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateClient = (clientId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateClient(clientId, params)
    .then(response => {
      dispatch(actions.clientUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'client-failed-update', 2000);
      }
    });
};

export const fetchUpdateClientCompany = (clientId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateClientCompany(clientId, params)
    .then(response => {
      dispatch(actions.clientUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'client-failed-update', 2000);
      }
    });
};

export const fetchUpdateClientPhoto = (clientId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateClientPhoto(clientId, params)
    .then(response => {
      dispatch(actions.clientPhotoUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'client-failed-update', 2000);
      }
    });
};

export const fetchForceDeleteClient = (clientId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deleteClient(clientId)
    .then(response => {
      dispatch(actions.clientUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'client-failed-delete', 2000);
      }
    });
};
