import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import { Modal, Dropdown } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Chip from '@material-ui/core/Chip';

import { fetchCreatePlan } from "../_redux/planActions";

import { createPlanSchema } from "../_schemas/createPlanSchema";

import { notifySuccess } from "../../../utils/Notify";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  weekday: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const CreatePlan = (props) => {
  const dispatch = useDispatch();
  const { plans, branches } = useSelector((state) => ({
    plans: state.plans,
    branches: state.branches,
  }), shallowEqual);

  const classes = useStyles();

  const [showClient, setShowClient] = useState(false);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [weekdays, setWeekdays] = useState([2,4,8,16,32,64,1]);
  const [selectedBranches, setSelectedBranches] = useState([]);

  useEffect(() => {
    if (plans.created == true) {
      close();
      notifySuccess('Cadastrado com sucesso', 'plans-created', 2000);
    }
  }, [plans]);

  const close = () => {
    props.onHide();
    setShowClient(false);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('description', '', false);
    formik.setFieldValue('type', 'signature', false);
    formik.setFieldValue('duration', '1', false);
    formik.setFieldValue('price', '', false);
    formik.setFieldValue('memberPrice', '', false);
    formik.setFieldValue('weekdays', [2,4,8,16,32,64,1], false);
    formik.setFieldValue('package3Unit', '', false);
    formik.setFieldValue('package5Unit', '', false);
    formik.setFieldValue('package10Unit', '', false);
    formik.setFieldValue('availableApp', 'no', false);

    setWeekdays([2,4,8,16,32,64,1]);
    setSelectedBranches([]);
  }

  const renderError = (error) => {
    return (error ? (
      <Grid item xs={12}>
        <div className="fv-plugins-message-container">
          {(typeof error) == "string" &&
            <div className="fv-help-block">{error}</div>
          }

          {Array.isArray(error) && error.map((item) =>
            <div className="fv-help-block">{item}</div>
          )}
        </div>
      </Grid>
    ) : null);
  }

  const requestCreatePlan = (values) => {
    if (values.type == 'day_use') {
      values.duration = '1';
    }

    if (values.type == 'day_use' && values.availableApp == 'yes') {
      values.package = [
        {
          amount: 3,
          price: values.package3Unit,
        },
        {
          amount: 5,
          price: values.package5Unit,
        },
        {
          amount: 10,
          price: values.package10Unit,
        }
      ];
    }

    dispatch(fetchCreatePlan(values));
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      type: 'signature',
      price: '',
      memberPrice: '',
      duration: '1',
      weekdays: [2,4,8,16,32,64,1],
      package3Unit: '',
      package5Unit: '',
      package10Unit: '',
      availableApp: 'no',
    },
    validationSchema: createPlanSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      values.weekday = weekdays.reduce((x, y) => x + y, 0);
      values.branches = selectedBranches;

      setTimeout(() => {
        requestCreatePlan(values);

        setSubmitting(false);
      }, 100);
    },
  });

  const selectedWeekDayColor = (weekday) => {
    return weekdays.includes(weekday) ? "primary" : "default";
  }

  const selectWeekDay = (weekday) => {
    if(!weekdays.includes(weekday)) {
      setWeekdays([...weekdays, weekday]);
    } else{
        var array = [...weekdays];
        var index = array.indexOf(weekday);

        if (index !== -1) {
          array.splice(index, 1);

          setWeekdays(array);
        }
    }
  }

  const type = [
    { label: 'Clubinho', value: 'signature' },
    { label: 'Day use', value: 'day_use' },
  ];

  const availableApps = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const getTime = (time) => {
    return `Valor referente a ${time} minutos`;
  }

  const branchCheck = (serviceId) => {
    if (branchIsChecked(serviceId)) {
      setSelectedBranches(selectedBranches.filter(arrayItem => arrayItem !== serviceId));
    } else {
      setSelectedBranches([...selectedBranches, serviceId]);
    }
  }

  const branchIsChecked = (branchId) => {
    return selectedBranches.includes(branchId);
  }

  return (<>
    {!createEntityShow && <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cadastrar plano
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="sm">
            <Grid container className={classes.label}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Dados de exibição
                </span>
              </Grid>
            </Grid>

            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Nome"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  {...formik.getFieldProps("name")}
                />

                {plans.error && renderError(plans.error.name)}

                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="description"
                  label="Descrição"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  multiline
                  rows={3}
                  maxRows={5}
                  {...formik.getFieldProps("description")}
                />

                {plans.error && renderError(plans.error.description)}

                {formik.touched.description && formik.errors.description ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.description}</div>
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Tipo de plano e preço
                </span>
              </Grid>

              <Grid item xs={12}>
                <Grid item xs={6}>
                  <TextField
                    select
                    label="Tipo de plano"
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        }
                      }
                    }}
                    name="fieldId"
                    fullWidth
                    className={classes.textField}
                    {...formik.getFieldProps("type")}
                  >
                    {type.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                  </TextField>

                  {plans.error && renderError(plans.error.type)}
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="price"
                  label="Preço"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  {...formik.getFieldProps("price")}
                />

                {plans.error && renderError(plans.error.price)}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="memberPrice"
                  label="Preço para alunos"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  {...formik.getFieldProps("memberPrice")}
                />

                {plans.error && renderError(plans.error.member_price)}
              </Grid>

              {formik.getFieldProps('type').value == 'signature' && <Grid item xs={6}>
                <TextField
                  name="duration"
                  label="Duração"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">mês</InputAdornment>,
                  }}
                  {...formik.getFieldProps("duration")}
                />

                {plans.error && renderError(plans.error.duration)}
              </Grid>}

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Disponivel no aplicativo
                </span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  select
                  label="Disponivel no app"
                  variant="outlined"
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      }
                    }
                  }}
                  name="availableApp"
                  fullWidth
                  className={classes.textField}
                  {...formik.getFieldProps("availableApp")}
                >
                  {availableApps.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                </TextField>

                {plans.error && renderError(plans.error.available_app)}
              </Grid>

              {formik.getFieldProps('availableApp').value == 'yes' && formik.getFieldProps('type').value == 'day_use' && <>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">
                    Pacotes de créditos
                  </span>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="package3Unit"
                    label="3 créditos"
                    fullWidth
                    className={`${classes.textField} is-invalid`}
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    {...formik.getFieldProps("package3Unit")}
                  />

                  {plans.error && renderError(plans.error['package.0.price'])}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="package5Unit"
                    label="5 créditos"
                    fullWidth
                    className={`${classes.textField} is-invalid`}
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    {...formik.getFieldProps("package5Unit")}
                  />

                  {plans.error && renderError(plans.error['package.1.price'])}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="package10Unit"
                    label="10 créditos"
                    fullWidth
                    className={`${classes.textField} is-invalid`}
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    {...formik.getFieldProps("package10Unit")}
                  />

                  {plans.error && renderError(plans.error['package.2.price'])}
                </Grid>
              </>}

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  {formik.getFieldProps('type').value == 'signature' && "Dias da semana que o jogador pode fazer check-in:"}
                  {formik.getFieldProps('type').value == 'day_use' && "Dias da semana que pode ser utilizado:"}
                </span>
              </Grid>

              <Grid container className={classes.weekday} spacing={1}>
                <Grid item xs><Chip onClick={() => selectWeekDay(2)} clickable color={selectedWeekDayColor(2)} variant="outlined" label="Seg" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(4)} clickable color={selectedWeekDayColor(4)} variant="outlined" label="Ter" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(8)} clickable color={selectedWeekDayColor(8)} variant="outlined" label="Qua" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(16)} clickable color={selectedWeekDayColor(16)} variant="outlined" label="Qui" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(32)} clickable color={selectedWeekDayColor(32)} variant="outlined" label="Sex" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(64)} clickable color={selectedWeekDayColor(64)} variant="outlined" label="Sab" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(1)} clickable color={selectedWeekDayColor(1)} variant="outlined" label="Dom" /></Grid>
              </Grid>

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Selecione as unidades que participam do plano <Icon className={classes.icon}>info_outlined</Icon>
                </span>
              </Grid>

              <Grid item xs={12}>
                <div className="checkbox-list">
                  {branches.branchesCompany.map(branch => <>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        key={branch.id}
                        name={branch.id}
                        checked={branchIsChecked(branch.id)}
                        onChange={() => branchCheck(branch.id)}
                      />
                      <span></span>{branch.attributes.name}
                    </label>
                  </>)}
                </div>

                {plans.error && renderError(plans.error.service_id)}
              </Grid>
            </Grid>

            {(typeof plans.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {plans.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={formik.isSubmitting}>
                Cadastrar {(formik.isSubmitting || plans.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>}
  </>);
};

export default CreatePlan;