import React, { useState, useEffect } from "react";

import { lighten, makeStyles } from '@material-ui/core/styles';

import { PaymentChargebackList } from '../components/PaymentChargebackList';

const useStyles = makeStyles(theme => ({
  //
}));

export const PaymentChargebackPage = () => {
  const classes = useStyles();
  return (<>
      <PaymentChargebackList />
  </>);
}
