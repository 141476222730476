import * as requestFromServer from "./scheduleCrud";
import * as clientRequestFromServer from "../../Client/_redux/clientCrud";
import * as contractRequestFromServer from "../../Contract/_redux/contractCrud";
import { scheduleSlice, callTypes } from "./scheduleSlice";

const { actions } = scheduleSlice;

export const fetchSchedules = (startAt, endAt, clientId = '', fieldId = [], branchId = '', userId = '') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  let params = {
    start_at: startAt,
    finish_at: endAt,
  }

  if (clientId) {
    params.client_id = clientId;
  }

  if (userId) {
    params.user_id = userId;
  }

  if (fieldId.length) {
    params.field_id = fieldId;
  }

  if (branchId) {
    params.branch_id = branchId;
  }

  return requestFromServer
    .getSchedules(params)
    .then(response => {
      const entities = response.data.data;
      const totalCount = entities.length;
      const total = response.data.total;
      const single = response.data.single;
      const recurrence = response.data.recurrence;

      dispatch(actions.schedulesFetched({
        totalCount,
        entities,
        total,
        single,
        recurrence
      }));
    })
    .catch(error => {
      dispatch(actions.schedulesFetched({ totalCount: 0, entities: [] }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSchedulesNotifications = (statusId = 'pending') => dispatch => {
  return requestFromServer
    .getSchedulesCompany(statusId)
    .then(response => {
      const notifications = response.data.data;

      dispatch(actions.schedulesNotificationsFetched({
        notifications
      }));
    })
    .catch(error => {
      dispatch(actions.schedulesNotificationsFetched({ notifications: [] }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

const createSchedule = (fieldId, params) => dispatch => {
  console.log(params);
  let data = {
    type: params.type,
    modality: params.modality,
    schedule_spots: params.schedule_spots,
    participants: params.participants,
    status_id: params.statusId,
    start_at: `${params.startDateAt} ${params.startAt}`,
    finish_at: `${params.endDateAt} ${params.endAt}`,
  }

  if (params.recurrence_start_at) {
    data.recurrence_start_at = params.recurrence_start_at;
  }
  if (params.recurrence_finish_at) {
    data.recurrence_finish_at = params.recurrence_finish_at;
  }
  if (params.recurrence_days) {
    data.recurrence_days = `${params.recurrence_days}`;
    data.recurrence_times = 3; // REMOVER
  }
  if (params.spots) {
    data.spots = params.spots;
  }
  if (params.recurrence_period) {
    data.recurrence_period = params.recurrence_period;
  }
  if (params.level) {
    data.level = params.level;
  }

  return requestFromServer
    .createSchedule(fieldId, data)
    .then(response => {
      const schedule = response.data;

      dispatch(actions.scheduleCreated(schedule.data[0]));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      // dispatch(actions.schedulesFetched({ totalCount: 0, entities: [] }));
    });
};

export const fetchCreateSchedule = (fieldId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return dispatch(createSchedule(fieldId, params));
};

export const fetchMoveSchedule = (scheduleId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    field_id: params.fieldId,
    // service_id: params.serviceId,
    // status_id: params.statusId,
    start_at: `${params.startDateAt} ${params.startAt}`,
    finish_at: `${params.endDateAt} ${params.endAt}`,
  }

  if (params.recurrence_start_at) {
    data.recurrence_start_at = params.recurrence_start_at;
  }
  if (params.recurrence_finish_at) {
    if (params.recurrence_finish_at == 'infinite') {
      data.recurrence_finish_at = null;
    } else {
      data.recurrence_finish_at = params.recurrence_finish_at;
    }
  }
  if (params.recurrence_days) {
    data.recurrence_days = `${params.recurrence_days}`;
  }

  return requestFromServer
    .moveSchedule(scheduleId, data)
    .then(response => {
      dispatch(actions.scheduleMoved(response.data));
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateScheduleLevel = (scheduleId, level) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    level: level,
  }

  return requestFromServer
    .updateSchedule(scheduleId, data)
    .then(response => {
      dispatch(actions.scheduleUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateScheduleSpots = (scheduleId, spots) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    spots: spots,
  }

  return requestFromServer
    .updateSchedule(scheduleId, data)
    .then(response => {
      dispatch(actions.scheduleUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAddClientSchedule = (scheduleId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    schedule_id: scheduleId,
    client_id: params.client_id,
    service_id: params.service_id,
  }

  if (params.plan) {
    data.plan = params.plan;
  }

  if (params.start_at) {
    data.start_at = params.start_at;
  }

  if (params.billing_day) {
    data.billing_day = params.billing_day;
  }

  if (params.credit_id) {
    data.credit_id = params.credit_id;
  }

  return contractRequestFromServer
    .createContract(data)
    .then(response => {
      dispatch(actions.scheduleAddClientCreated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAddContractSchedule = (scheduleId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    contract_id: params.contract_id,
    start_at: params.start_at,
  }

  return requestFromServer
    .addContractSchedule(scheduleId, data)
    .then(response => {
      dispatch(actions.scheduleAddClientCreated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchAddParticipantSchedule = (scheduleId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    user_id: params.participant_id,
  }

  return requestFromServer
    .addParticipanteSchedule(scheduleId, data)
    .then(response => {
      dispatch(actions.scheduleAddClientCreated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCancelSchedule = (scheduleId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    type: params.type,
  }

  if (params.date) {
    data.date = params.date
  }

  if (params.refund_type) {
    data.refund_type = params.refund_type;
  }

  return requestFromServer
    .cancelSchedule(scheduleId, data)
    .then(response => {
      dispatch(actions.scheduleCanceled());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCancelContract = (contractId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    type: params.type,
  }

  if (params.date) {
    data.date = params.date
  }

  if (params.discount) {
    data.discount = params.discount
  }

  return contractRequestFromServer
    .cancelContract(contractId, data)
    .then(response => {
      dispatch(actions.scheduleRemoveClientSchedule());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCancelScheduleSpot = (scheduleSpotId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    type: params.type,
  }

  if (params.date) {
    data.date = params.date
  }

  return contractRequestFromServer
    .cancelScheduleSpot(scheduleSpotId, data)
    .then(response => {
      dispatch(actions.cancelScheduleSpot());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRemoveParticipantSchedule = (participantScheduleId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .removeParticipantSchedule(participantScheduleId)
    .then(response => {
      dispatch(actions.scheduleRemoveClientSchedule());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchApproveSchedule = (scheduleId) => dispatch => {
  return requestFromServer
    .approveSchedule(scheduleId)
    .then(response => {
      dispatch(actions.scheduleApproved());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchListRecurrenceDays = (recurrenceDays, startAt, finishAt = '', limit = 30) => dispatch => {
  let data = {
    recurrence_days: recurrenceDays,
    start_at: startAt,
    limit: limit,
  }

  if (finishAt) {
    data.finish_at = finishAt;
  }

  return new Promise((resolve, reject) => {
    requestFromServer
      .listRecurrenceDays(data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};


export const fetchGetScheduleById = (scheduleId, date) => dispatch => {
  let data = {
    date: date,
  }

  return new Promise((resolve, reject) => {
    requestFromServer
      .getScheduleById(scheduleId, data)
      .then(response => {
        dispatch(actions.scheduleFetched());
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};
