import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';

import CloseIcon from '@material-ui/icons/Close';

import { fetchTags } from "../../Tag/_redux/tagActions";
import { fetchUpdateBranch } from "../_redux/branchActions";

import { createBranchSchema } from "../_schemas/createBranchSchema";

import { notifySuccess } from "../../../utils/Notify";
import DotsMobileStepper from "../../../components/DotsMobileStepper";
import WeekRangeTime from "../../../components/WeekRangeTime";
import { RenderError } from "../../../components/RenderError";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditBranch = (props) => {
  const dispatch = useDispatch();
  const { branches, tags } = useSelector((state) => ({
    branches: state.branches,
    tags: state.tags,
  }), shallowEqual);

  const classes = useStyles();

  let initialBusinessDays = [
    {
      weekday: 0,
      weekday_label: 'Domingo',
      notChecked: true,
      start_at: '',
      finish_at: '',
    },
    {
      weekday: 1,
      weekday_label: 'Segunda',
      notChecked: true,
      start_at: '',
      finish_at: '',
    },
    {
      weekday: 2,
      weekday_label: 'Terça',
      notChecked: true,
      start_at: '',
      finish_at: '',
    },
    {
      weekday: 3,
      weekday_label: 'Quarta',
      notChecked: true,
      start_at: '',
      finish_at: '',
    },
    {
      weekday: 4,
      weekday_label: 'Quinta',
      notChecked: true,
      start_at: '',
      finish_at: '',
    },
    {
      weekday: 5,
      weekday_label: 'Sexta',
      notChecked: true,
      start_at: '',
      finish_at: '',
    },
    {
      weekday: 6,
      weekday_label: 'Sábado',
      notChecked: true,
      start_at: '',
      finish_at: '',
    }
  ];

  const [showClient, setShowClient] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [businessDays, setBusinessDays] = useState([]);

  useEffect(() => {
    dispatch(fetchTags());
  }, []);

  const merge = (a, b, prop) => {
    var reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]))
    return reduced.concat(b);
  }

  const dynamicSort = (property) => {
    var sortOrder = 1;
    if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a,b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }

  useEffect(() => {
    if (props.event && props.event.id) {
      toStep(0);

      formik.setFieldValue('branchId', props.event.id, false);
      formik.setFieldValue('name', props.event.attributes.name, false);
      formik.setFieldValue('phone', props.event.attributes.phone, false);
      formik.setFieldValue('address', props.event.attributes.address, false);
      formik.setFieldValue('zipcode', props.event.attributes.zipcode, false);


      let filteredTags = [];

      props.event.relationships.tags.map(tag => filteredTags.push(tag.id));

      setSelectedTags(filteredTags);

      console.log('asdadsad');
      setBusinessDays(merge(initialBusinessDays, props.event.attributes.business_hours, 'weekday').sort(dynamicSort("weekday")));
    }
  }, [props.event]);

  useEffect(() => {
    if (branches.updated == true) {
      close();
      notifySuccess('Atualizado com sucesso', 'branches-updated', 2000);
    }

    if (branches.error) {
      toStep(0);
    }
  }, [branches]);

  const toStep = (step) => {
    setStep(step);
  }

  const close = () => {
    props.onHide();
    setShowClient(false);
    toStep(0);
    // setBusinessDays(initialBusinessDays);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('phone', '', false);
    formik.setFieldValue('address', '', false);
    formik.setFieldValue('zipcode', '', false);

    setSelectedTags([])
  }

  const tagCheck = (tagId) => {
    if (tagIsChecked(tagId)) {
      setSelectedTags(selectedTags.filter(arrayItem => arrayItem !== tagId));
    } else {
      setSelectedTags([...selectedTags, tagId]);
    }
  }

  const tagIsChecked = (tagId) => {
    return selectedTags.includes(tagId);
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      address: '',
      zipcode: '',
      max_period: 30,
      tags: [],
    },
    validationSchema: createBranchSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {

      setTimeout(() => {
        if (values.phone) {
          values.phone = values.phone.replace(/\D/g,'');
        }
        values.tags = selectedTags;
        values.business_hours = businessDays;

        dispatch(fetchUpdateBranch(values.branchId, values));

        setSubmitting(false);
      }, 500);
    },
  });

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      // centered
      // backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editar unidade
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="sm">
            {step == 0 && <>
            <Grid container className={classes.label}>
              <Grid item xs>
                <span className="font-weight-bolder text-dark">
                  Dados de exibição no aplicativo
                </span>
              </Grid>
            </Grid>

            <Grid container className={classes.label} spacing={2}>
              <TextField
                type="hidden"
                disabled={true}
                name="branchId"
                {...formik.getFieldProps("branchId")}
              />
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Nome"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  {...formik.getFieldProps("name")}
                />

                {branches.error && <RenderError error={branches.error.name} />}

                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </Grid>
              <Grid item md={6}>
                <InputMask
                  disabled={false}
                  mask="(99) 9999-99999"
                  maskChar={null}
                  beforeMaskedValueChange={beforeMaskedValueChange}
                  autoComplete="new-password"
                  {...formik.getFieldProps("phone")}
                >
                  {() => <TextField
                    fullWidth
                    name="phone"
                    label="Telefone"
                    className={classes.textField}
                    variant="outlined"
                    autoComplete="new-password"
                  />}
                </InputMask>

                {branches.error && <RenderError error={branches.error.phone} />}

                {formik.touched.phone && formik.errors.phone ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.phone}</div>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="max_period"
                  label="Agenda aberta"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">dias</InputAdornment>,
                  }}
                  {...formik.getFieldProps("max_period")}
                />

                {branches.error && <RenderError error={branches.error.max_period} />}

                {formik.touched.max_period && formik.errors.max_period ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.max_period}</div>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="address"
                  label="Endereço"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  {...formik.getFieldProps("address")}
                />

                {branches.error && <RenderError error={branches.error.address} />}

                {formik.touched.address && formik.errors.address ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.address}</div>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="zipcode"
                  label="Cep"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  {...formik.getFieldProps("zipcode")}
                />

                {branches.error && <RenderError error={branches.error.zipcode} />}

                {formik.touched.zipcode && formik.errors.zipcode ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.zipcode}</div>
                  </div>
                ) : null}
              </Grid>
            </Grid>

            <Grid container className={classes.label}>
              <Grid item xs>
                <span className="font-weight-bolder text-dark">
                  Descreva seu negócio
                </span>
              </Grid>
            </Grid>

            <Grid container className={classes.label} spacing={2}>
              {tags.entities.map(tag =>
                <Grid item md={6} key={tag.id}>
                  <div className="checkbox-list">
                    <label className="checkbox checkbox-success">
                      <input
                        type="checkbox"
                        key={tag.id}
                        name={tag.id}
                        checked={tagIsChecked(tag.id)}
                        onChange={() => tagCheck(tag.id)}
                      />
                      <span></span>{tag.attributes.nome}
                    </label>
                  </div>
                </Grid>
              )}

              {branches.error && <RenderError error={branches.error.tags} />}
            </Grid>
            </>}

            {step == 1 && <>
            <Grid container className={classes.label}>
              <Grid item xs>
                <span className="font-weight-bolder text-dark">
                  Defina o período de funcionamento<Icon className={classes.icon}>info_outlined</Icon>
                </span>
              </Grid>
            </Grid>

            <Grid container className={classes.label} spacing={2}>
              {businessDays.map((day, index) =>
                <WeekRangeTime key={index} setDay={(value) => {
                  var days = [...businessDays];
                  days[index] = value;
                  setBusinessDays(days);
                }} day={day} notChecked={day.notChecked} />
              )}

              {branches.error && <RenderError error={branches.error.business_hours} />}
            </Grid>
            </>}


            {(typeof branches.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {branches.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>
        </Modal.Body>
        <DotsMobileStepper submitLabel="Salvar" totalSteps={2} step={step} move={toStep} loading={branches.actionsLoading} />
      </form>
    </Modal>
  </>);
};

export default EditBranch;