import React, { useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

import CloseIcon from '@material-ui/icons/Close';

import { RenderError } from "../../../../components/RenderError";
import { notifySuccess } from "../../../../utils/Notify";
import DetailedExpansionPanel from "../../../../components/DetailedExpansionPanel";

import { makeStyles } from '@material-ui/core/styles';

import { fetchinstructors } from "../../../Configuration/User/_redux/userActions";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const SelectUser = (props) => {
  const dispatch = useDispatch();
  const { users, branchSelected } = useSelector((state) => ({
    users: state.users,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();
  const nameRef = useRef(null);

  const [selectedClient, setSelectedClient] = useState({});
  const [showUser, setShowUser] = useState(false);
  const [inputAutoFocus, setInputAutoFocus] = useState(true);

  useEffect(() => {
    if (!props.show) {
      close();
    } else {
      if (nameRef.current) {
        dispatch(fetchinstructors('', branchSelected.id));
        setShowUser(true);

        nameRef.current.focus();
      }
    }
  }, [props.show]);

  const close = () => {
    props.onHide();

    setShowUser(false);
    formik.setFieldValue('name', '', false);
    setSelectedClient({});
  }

  const selectUser = (value) => {
    props.selectUser(value);
  }

  const searchClientName = () => {
    if (formik.values.name && formik.values.name.length >= 2) {
      const userRequestTimeout = setTimeout(() => {
        dispatch(fetchinstructors(formik.values.name, branchSelected.id));
        setShowUser(true);
      }, 400);

      return () => {
        clearTimeout(userRequestTimeout);
      };
    }
  }


  const formik = useFormik({
    initialValues: {
      //
    },
    // validationSchema: createClientdayUseschema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {

    },
  });

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);

  return (<>
    <Modal
      style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      {...props}
      show={props.show}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            {props && props.title}
            {props && !props.title && `Selecionar usuário`}
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          {!selectedClient.id && <Container maxWidth="sm">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {props.show && <TextField
                  name="name"
                  label="Pesquisar por nome"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {users.actionsLoading && <CircularProgress size={30} />}
                      </InputAdornment>
                    ),
                  }}
                  {...formik.getFieldProps("name")}
                  inputRef={nameRef}
                />}

                {formik.touched.name && formik.errors.userId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.userId}</div>
                  </div>
                ) : null}
              </Grid>

              {showUser && <Grid item xs={12}>
                {users.entities && users.entities.map((user) => <>
                  {user && user.attributes && <div className={classes.detailedExpansionPanel}>
                    <DetailedExpansionPanel client={user} onSelect={selectUser} />
                  </div>}
                </>)}
                {users.entities && !users.entities.length && <span>Nenhum professor encontrado</span>}
              </Grid>}
            </Grid>

            {(typeof users.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {users.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>}
        </Modal.Body>
      </form>
    </Modal>
  </>);
};

export default SelectUser;