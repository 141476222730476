import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import ListGroup from 'react-bootstrap/ListGroup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Chip from '@material-ui/core/Chip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Alert from 'react-bootstrap/Alert';

import {
  fetchUpdateCreditPurchaseExpiredAt,
  fetchRenewCreditPurchase,
  fetchCreditPurchase,
  clearCreditPurchase,
} from "../_redux/creditPurchaseActions";

import {
  fetchPrices,
  fetchCreatePrice,
  fetchRemovePrice,
} from "../../Price/_redux/priceActions";

import { fetchBranches } from "../../Branch/_redux/branchActions";

// import { createCreditPurchaseSchema } from "../_schemas/createCreditPurchaseSchema";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import CreatePrice from '../../Price/components/CreatePrice';
import EditPrice from '../../Price/components/EditPrice';
import CancelCreditPurchase from "./CancelCreditPurchase";
import EditClient from "../../Client/components/EditClient";
import ShowInvoice from "../../Invoice/components/ShowInvoice";
import ShowLogs from "../../Log/components/ShowLogs";

import { notifySuccess } from "../../../utils/Notify";
import DayPicker from '../../../components/DayPicker';
import { RenderError } from "../../../components/RenderError";
import { formatMoney } from "../../../utils/IntegerParse";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditCreditPurchase = (props) => {
  const dispatch = useDispatch();
  const { creditPurchases, branches } = useSelector((state) => ({
    creditPurchases: state.creditPurchases,
    branches: state.branches,
  }), shallowEqual);

  const classes = useStyles();

  const [editClientShow, setEditClientShow] = useState(false);
  const [editClient, setEditClient] = useState({});
  const [showCancelCreditPurchase, setShowCancelCreditPurchase] = useState(false);
  const [showEditExpiredAt, setShowEditExpiredAt] = useState(false);
  const [event, setEvent] = useState({});
  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  useEffect(() => {
    if (creditPurchases.updated == true) {
      close();
      setShowCancelCreditPurchase(false);
      notifySuccess('Atualizado com sucesso', 'creditPurchases-updated', 2000);
    }
  }, [creditPurchases]);

  // useEffect(() => {
  //   if (!showEditExpiredAt) {
  //     formik.setFieldValue('expired_at', '', false);
  //   }
  // }, [showEditExpiredAt]);

  useEffect(() => {
    if (creditPurchases.creditPurchase && creditPurchases.creditPurchase.id) {
      setEvent(creditPurchases.creditPurchase);

      formik.setFieldValue('expiredAt', moment(creditPurchases.creditPurchase.attributes.expired_at).format('YYYY-MM-DD'), false);
    }
  }, [creditPurchases.creditPurchase]);

  useEffect(() => {
    if (props.event && props.event.id && props.show) {
      setEvent(props.event);

      formik.setFieldValue('expiredAt', moment(props.event.attributes.expired_at).format('YYYY-MM-DD'), false);
      console.log(props.event);
      console.log('222222222222');
    } else {
      close();
    }
  }, [props.show]);

  const close = () => {
    props.onHide();

    setShowEditExpiredAt(false);
    setShowCancelCreditPurchase(false);

    formik.setFieldValue('expiredAt', '', false);

    setEvent({});
    dispatch(clearCreditPurchase());
  }

  const getLabelType = (value) => {
    if (value == 'branch') {
      return 'Criado pelo campo';
    }

    if (value == 'purchase') {
      return 'Compra pelo app';
    }

    if (value == 'refund') {
      return 'Reposição';
    }

    return '';
  }

  const getLabelStatus = (status) => {
    if (status == 'pending') {
      return 'Não pago';
    }

    if (status == 'canceled') {
      return 'Cancelado';
    }

    if (status == 'paid') {
      return 'Pago';
    }

    return '';
  }

  const getColorStatus = (status) => {
    if (status == 'pending') {
      return 'warning';
    }

    if (status == 'paid') {
      return 'success';
    }

    if (status == 'canceled') {
      return 'danger';
    }

    return 'primary';
  }

  const closeShowInvoice = () => {
    setShowInvoice(false);
    setSelectedInvoice({});
  }

  const openShowInvoice = (invoice) => {
    setShowInvoice(true);
    setSelectedInvoice(invoice);
  }

  const openEditClient = (event) => {
    setEditClient(event);

    setEditClientShow(true);
  }

  const closeEditClient = () => {
    setEditClientShow(false);
  }

  const dispatchUpdateExpiredAt = () => {
    dispatch(fetchUpdateCreditPurchaseExpiredAt(event.id, formik.values.expiredAt));
  }

  const dayOfWeekAsString = (dayIndex) => {
    return ["Dom", "Seg","Ter","Qua","Qui","Sex","Sab"][dayIndex] || '';
  }

  const getWeekDays = (weekdays) => {
    let string = '';

    weekdays = weekdays.map(weekday => {
      return dayOfWeekAsString(weekday);
    });

    return weekdays.join(', ');
  }

  const getPaymentTypeLabel = (value) => {
    let type = paymentTypes.find(type => type.value == value)

    return type ? type.label : value;
  }

  const getPaymentMethodLabel = (value) => {
    let type = paymentMethods.find(type => type.value == value)

    return type ? type.label : value;
  }

  const getStatusLabel = (invoice) => {
    let label = 'A vencer';

    if (invoice.attributes.is_canceled) {
      label = 'Cancelado';
    }

    if (invoice.attributes.is_paid) {
      label = 'Pago';
    }

    if (invoice.attributes.is_expired) {
      label = 'Vencido';
    }

    return label;
  }

  const formik = useFormik({
    initialValues: {
      expiredAt: '',
    },
    // validationSchema: createCreditPurchaseSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        // dispatch(fetchUpdateCreditPurchase(event.id, values));

        setSubmitting(false);
      }, 500);
    },
  });

  const renew = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const headRows = [
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Mês' },
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Valor' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  const paymentTypes = [
    { label: 'Pagamento na unidade', value: 'locally' },
    { label: 'Pagamento Online', value: 'online' },
  ];

  const paymentMethods = [
    { label: 'Não informado', value: 'unknown' },
    { label: 'Crédito', value: 'credit' },
    { label: 'Cartão de crédito', value: 'credit_card' },
    { label: 'Débito', value: 'debit' },
    { label: 'Pix', value: 'pix' },
    { label: 'Dinheiro', value: 'cash' },
  ];

  return (<>
    {event.id && <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !showCancelCreditPurchase && !editClientShow && !showInvoice}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Informações do pacote de créditos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7 mb-5"></div>

          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Nome</span>: {event.relationships.client.attributes.name}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">E-mail</span>: {event.relationships.client.attributes.email}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Telefone</span>: {event.relationships.client.attributes.phone}
                    </span>
                  </div>
                </div>
              </Grid>

              {!props.hideClient && <Grid item xs={12}>
                <a class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-5" onClick={() => openEditClient(event.relationships.client)}>
                  Ver cliente
                </a>

                {event.relationships.status.attributes.name == 'active' && !event.attributes.is_expired && <a class="btn btn-light-warning font-weight-bolder font-size-sm mr-2 mt-5" onClick={() => console.log('event')}>
                  Transferir crédito
                </a>}
              </Grid>}
            </Grid>
          </Container>

          <Container maxWidth="sm">
            <Grid container className="mt-5">
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7"></div>

          <Container maxWidth="sm">
            {/*<Grid container className={classes.label}>
              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Tipo</span>
                      : {event.relationships.origin.type == 'plan' && event.relationships.origin.attributes.type == 'day_use' && `Day use`}
                        {event.relationships.origin.type == 'service' && event.relationships.origin.attributes.modality == 'class' && `Aula`}
                        {event.relationships.origin.type == 'service' && event.relationships.origin.attributes.modality == 'reservation' && `Locação`}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Serviço</span>: {event.relationships.origin.attributes.name}

                      {event.attributes.duration && <>
                        (<span className="font-weight-light text-dark-50">{event.attributes.duration}min</span>)
                      </>}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Origem</span>: {event.attributes.amount} créditos
                  <span style={{
                    color: "#B5B5C3",
                    textTransform: "uppercase"
                  }}>
                    <br />{formatMoney(event.attributes.price)}
                  </span>
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Vencimento dos créditos</span>: {event.attributes.expired_at && <span className={`label label-lg label-light label-inline`}>
                          {moment(event.attributes.expired_at).format('DD/MM/YYYY')}
                        </span>}
                        {!event.attributes.expired_at && `Sem validade`}
                    </span>
                    {event.relationships.status.attributes.name == 'active' && !event.attributes.is_expired && !showEditExpiredAt && <span class="cursor-pointer ml-2" onClick={() => setShowEditExpiredAt(!showEditExpiredAt)}>
                      <i class="fas fa-edit icon-nm text-dark-50"></i>
                    </span>}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block pt-3">
                    <span className="text-dark">
                      <span className={`label label-lg label-light-${getColorStatus(event.relationships.status.attributes.name)} label-inline`} style={{minWidth: 'max-content'}}>
                        {getLabelStatus(event.relationships.status.attributes.name)}
                      </span>
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>*/}


            <Grid container className={classes.label} spacing={2}>
              <div className="d-flex flex-column mx-3">


                <div className="row">
                  <div className="col-4">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Status
                      </label>
                      <div className=" col-12">
                        <label>
                          <span className={`label label-lg label-light-${getColorStatus(event.relationships.status.attributes.name)} label-inline`} style={{minWidth: 'max-content'}}>
                            {getLabelStatus(event.relationships.status.attributes.name)}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Serviço
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{event.relationships.origin.attributes.name}

                          {event.attributes.duration && <>
                            {` `}(<span className="font-weight-light text-dark-50">{event.attributes.duration}min</span>)
                          </>}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-4">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Quantidade
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>3 créditos
                        </label>
                      </div>
                    </div>
                  </div>
                  {event.attributes.weekdays && <div className="col-4">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Válido nos dias
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{getWeekDays(event.attributes.weekdays)}
                        </label>
                      </div>
                    </div>
                  </div>}
                  <div className="col-4">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Válido até
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>
                          {event.attributes.expired_at && moment(event.attributes.expired_at).format('DD/MM/YYYY')}
                          {!event.attributes.expired_at && `-`}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Realizada em
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>
                          {event.attributes.created_at && moment(event.attributes.created_at).format('DD/MM/YYYY HH:mm')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Container>

          {event.relationships.invoice.attributes && <>
            <Container maxWidth="sm">
              <Grid container>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">Fatura</span>
                </Grid>
              </Grid>
            </Container>

            <div className="separator separator-dashed mx-7 mb-5"></div>

            <Container maxWidth="sm">
              <Grid container className={classes.label} spacing={2}>
                <div className="row mx-4">
                  <Grid container spacing={2}>
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <label className="col-12  col-form-label font-weight-bold text-left">
                            Status
                          </label>
                          <div className=" col-12">
                            <span className={`label label-md label-light-${getColorStatus(event.relationships.invoice)} label-inline`}>
                              {getStatusLabel(event.relationships.invoice)}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col">
                        <div className="row">
                          <label className="col-12  col-form-label font-weight-bold text-left">
                            Valor total
                          </label>
                          <div className=" col-12">
                            <label>
                              <span></span>{formatMoney(event.relationships.invoice.attributes.full_price)}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col mw-300">
                        <div className="row">
                          <label className="col-12 col-form-label font-weight-bold text-right">
                            <a
                              title="Ver fatura"
                              className="btn btn-light btn-hover-primary btn-sm mx-3"
                              onClick={() => openShowInvoice(event.relationships.invoice)}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  title="Ver fatura"
                                  src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                /> Abrir
                              </span>
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Forma de pagamento
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>
                          {getPaymentTypeLabel(event.relationships.invoice.attributes.payment_type)}
                          {" - "}
                          {getPaymentMethodLabel(event.relationships.invoice.attributes.payment_method)}
                        </label>
                      </div>
                    </div>
                  </Grid>
                </div>
              </Grid>
            </Container>
          </>}

          {/*<div className="separator separator-dashed mx-7"></div>

          <Container maxWidth="sm">
            <ShowLogs show={true} data={{origin_id: event.id, type: "creditPurchases"}} />
          </Container>*/}
        </Modal.Body>

        <Modal.Footer>
          <Grid container spacing={3}>
            <>
              <Grid item xs={6}>
                <span className=" font-size-h4 text-dark-50 font-weight-bolder mb-2">
                  Subtotal
                </span>
              </Grid>
              <Grid item xs={6} className="text-right">
                {/*<span class="label label-xl font-weight-boldest mr-2 cursor-pointer" onClick={() => console.log('1231231')}>
                  <i class="fas fa-pen"></i>
                </span>*/}
                <span className=" font-size-h4 text-dark-50 font-weight-bolder mb-2 text-right">
                  {formatMoney(event.attributes.price)}
                </span>
              </Grid>
            </>

            <Grid item xs={2} />
            <Grid item xs={8} style={{textAlign: 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
            <Grid item xs={2} style={{textAlign: 'right'}}>
              {!props.readOnly && event && event.relationships && event.relationships.status.attributes.name == 'active' && !event.attributes.is_expired && <Tooltip title="Expirar crédito" placement="top">
                <IconButton variant="outlined" aria-label="delete" onClick={() => setShowCancelCreditPurchase(true)} disableFocusRipple={true}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>}

    <ShowInvoice show={showInvoice} onHide={() => closeShowInvoice()} invoice={selectedInvoice} />
    <CancelCreditPurchase show={showCancelCreditPurchase} onHide={() => setShowCancelCreditPurchase(false)} event={event} />
    <EditClient show={editClientShow} onHide={closeEditClient} event={editClient} />
  </>);
};

export default EditCreditPurchase;