import React, { useState, useEffect } from "react";

import { lighten, makeStyles } from '@material-ui/core/styles';

import { CouponList } from '../components/CouponList';
// import { DayUseCardList } from '../../DayUse/components/DayUseCardList';

const useStyles = makeStyles(theme => ({
  //
}));

export const CouponPage = () => {
  const classes = useStyles();
  return (<>
      <CouponList />
  </>);
}
