import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entityForEdit: undefined,
  coupon: {},
  lastError: null,
  created: false,
  updated: false,
  renewed: false,
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const couponSlice = createSlice({
  name: "coupons",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    loadingFinish: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.created = false;
      state.updated = false;
    },

    couponsFetched: (state, action) => {
      const { entities, totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.created = false;
      state.updated = false;
      state.totalCount = totalCount;
    },

    couponFetched: (state, action) => {
      state.listLoading = false;
      state.coupon = action.payload.coupon;
      state.created = false;
      state.updated = false;
      state.renewed = false;
      state.error = null;
    },

    // couponEmailFetched: (state, action) => {
    //   state.actionsLoading = false;
    //   state.couponEmail = action.payload.couponEmail;
    //   state.error = null;
    // },

    // couponNameFetched: (state, action) => {
    //   state.actionsLoading = false;
    //   state.couponName = action.payload.couponName;
    //   state.error = null;
    //   state.created = false;
    //   state.updated = false;
    // },

    // couponsFetched: (state, action) => {
    //   const { entities } = action.payload;
    //   state.listLoading = false;
    //   state.error = null;
    //   state.entities = entities;
    //   state.created = false;
    //   state.updated = false;
    //   state.totalCount = entities.meta.total;
    // },

    couponCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.created = true;
    },

    couponUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.updated = true;
    },

    couponRenewed: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.renewed = true;
    },

    couponDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },

    // couponsDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(
    //     el => !action.payload.ids.includes(el.id)
    //   );
    // },

    // couponsStatusUpdated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   const { ids, status } = action.payload;
    //   state.entities = state.entities.map(entity => {
    //     if (ids.findIndex(id => id === entity.id) > -1) {
    //       entity.status = status;
    //     }
    //     return entity;
    //   });
    // }
  }
});
