import store from "../../../../redux/store";

import * as requestFromServer from "./branchRecipientCrud";
import { actions as authPersistor } from "../../Auth/_redux/authRedux";
import { branchRecipientSlice, callTypes } from "./branchRecipientSlice";

const { actions } = branchRecipientSlice;

export const fetchBranchRecipients = (branchId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getBranchRecipients(branchId)
    .then(response => {
      const entity = response.data;

      if (entity) {
        dispatch(actions.branchRecipientFetched({ entity }));
      } else {
        dispatch(actions.branchRecipientFetched({ entity: undefined }));
      }
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.branchRecipientFetched({ entity: undefined }));
    });
};

export const fetchBranchRecipientKyc = (branchId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .getBranchRecipientKyc(branchId)
      .then(response => {
        dispatch(actions.branchRecipientCreated());
        resolve(response.data);
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.list }));

        dispatch(actions.branchRecipientCreated());

        reject(false);
      });
  });
};

export const fetchCreateBranchRecipient = (branchId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));


  return requestFromServer
    .createBranchRecipient(branchId, params).then(response => {
      dispatch(actions.branchRecipientCreated());
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateBranchRecipient = (branchId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateBranchRecipient(branchId, {
      name: params.name,
      address: params.address,
      business_hours: params.business_hours,
      max_period: params.max_period,
      phone: params.phone,
      tags: params.tags,
      zipcode: params.zipcode
    })
    .then(response => {
      dispatch(actions.branchRecipientUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};