import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import CalendarPage from "./modules/Calendar/pages/CalendarPage";
import { SetupPage } from "./modules/Setup/pages/SetupPage";

import { ClientPage } from "./modules/Client/pages/ClientPage";
import { UserPage } from "./modules/Configuration/User/pages/UserPage";
// import { SignaturePage } from "./modules/Signature/pages/SignaturePage";
import { ServicePage } from "./modules/Service/pages/ServicePage";
import { PlanPage } from "./modules/Plan/pages/PlanPage";
// import { PricePage } from "./modules/Price/pages/PricePage";
import { FieldPage } from "./modules/Field/pages/FieldPage";
import { BranchPage } from "./modules/Branch/pages/BranchPage";
import { ScheduleDateReportPage } from "./modules/Report/pages/ScheduleDateReportPage";
import { InvoiceReportPage } from "./modules/Report/pages/InvoiceReportPage";
import { ExportReportPage } from "./modules/Report/pages/ExportReportPage";
import { UserProfilePage } from "./modules/Account/pages/UserProfilePage";
// import { CreditPage } from "./modules/Credit/pages/CreditPage";
import { SettingsPage } from "./modules/Settings/pages/SettingsPage";
import { ProductDashboardPage } from "./modules/Dashboard/pages/ProductDashboardPage";

import { DashboardPage } from "./pages/DashboardPage";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect exact from="/dashboard" to="/" />
        }
        <ContentRoute exact path="/" component={CalendarPage} />
        <ContentRoute path="/setup" component={SetupPage} />

        {/*<ContentRoute path="/clubinho" component={SignaturePage} />*/}
        {/*<ContentRoute path="/creditos" component={CreditPage} />*/}
        <ContentRoute path="/clientes" component={ClientPage} />

        {/* Vendas */}
        <ContentRoute path="/day-use" component={ProductDashboardPage} />
        <ContentRoute path="/clubinho" component={ProductDashboardPage} />
        <ContentRoute path="/creditos" component={ProductDashboardPage} />
        <ContentRoute path="/pacotes-de-creditos" component={ProductDashboardPage} />
        <ContentRoute path="/locacoes" component={ProductDashboardPage} />
        <ContentRoute path="/contratos/locacoes" component={ProductDashboardPage} />
        <ContentRoute path="/contratos/mensalistas" component={ProductDashboardPage} />
        <ContentRoute path="/contratos/aulas" component={ProductDashboardPage} />
        <ContentRoute path="/contratos/alunos" component={ProductDashboardPage} />
        <ContentRoute path="/cupons-de-desconto" component={ProductDashboardPage} />
        <ContentRoute path="/pagamentos" component={ProductDashboardPage} />
        <ContentRoute path="/estornos" component={ProductDashboardPage} />

        {/* Gerencial */}
        <ContentRoute path="/gerencial/professores" component={UserPage} />
        <ContentRoute path="/gerencial/servicos" component={ServicePage} />
        <ContentRoute path="/gerencial/planos" component={PlanPage} />
        {/*<ContentRoute path="/gerencial/precos" component={PricePage} />*/}
        <ContentRoute path="/gerencial/campos" component={FieldPage} />
        <ContentRoute path="/gerencial/unidades" component={BranchPage} />

        {/* Configurações */}
        <ContentRoute path="/configuracoes/usuarios" component={UserPage} />

        {/* Relatórios */}
        <ContentRoute path="/relatorios/agendamento" component={ScheduleDateReportPage} />
        <ContentRoute path="/relatorios/faturas" component={InvoiceReportPage} />
        <ContentRoute path="/relatorios/exportar" component={ExportReportPage} />

        {/* Minha conta */}
        <ContentRoute path="/minha-conta" component={UserProfilePage} />

        {/* Configurações gerais */}
        <ContentRoute path="/configuracoes/empresa" component={SettingsPage} />
        <ContentRoute path="/configuracoes/aplicativo" component={SettingsPage} />
        <ContentRoute path="/configuracoes/conta-bancaria" component={SettingsPage} />
        <ContentRoute path="/configuracoes/saldo" component={SettingsPage} />

        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
