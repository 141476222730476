import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entityForEdit: undefined,
  lastError: null,
  created: false,
  updated: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const planSlice = createSlice({
  name: "plans",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;

      if (action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    planFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.entityForEdit;
      state.error = null;
      state.created = false;
      state.updated = false;
    },

    plansFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.created = false;
      state.updated = false;
      state.totalCount = totalCount;
    },

    planCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.created = true;
    },

    planUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.updated = true;
    },

    planDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },

    plansDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },

    plansStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
