import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import {
  fetchCreateClient,
  fetchAssociateClient,
  fetchClientEmail,
  fetchClientPhone,
  clearClientEmail,
  clearClientPhone,
} from "../_redux/clientActions";

import SelectClient from './SelectClient';
import ClientPhoto from './ClientPhoto';

import { createClientSchema } from "../_schemas/createClientSchema";

import { notifySuccess } from "../../../utils/Notify";
import { formatPhoneToString } from "../../../utils/PhoneParse";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const TextFieldPhoneInput = React.forwardRef((props, ref) => (
  <TextField {...props} inputRef={ref} />
));

const CreateClient = (props) => {
  const dispatch = useDispatch();
  const { clients, branchSelected } = useSelector((state) => ({
    clients: state.clients,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();

  const [showClient, setShowClient] = useState(false);
  const [openSelectClient, setOpenSelectClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [openClientPhoto, setOpenClientPhoto] = useState(false);

  const sports = [
    { value: '1', label: 'Futebol' },
    { value: '2', label: 'Beach tennis' },
    { value: '3', label: 'Tennis' },
    { value: '4', label: 'Padel' },
    { value: '5', label: 'Pickleball' },
  ];

  useEffect(() => {
    if (clients.created == true) {
      close();
      notifySuccess('Cadastrado com sucesso', 'client-created', 2000);
    }
  }, [clients]);

  const isClient = () => {
    return props.type == 'instructor' ? null : true;
  }

  const close = () => {
    props.onHide();
    setShowClient(false);

    formik.setFieldValue('phone', '', false);
    formik.setFieldValue('name', '', false);
    formik.setFieldValue('email', '', false);
    formik.setFieldValue('cpf', '', false);
    formik.setFieldValue('birthday', '', false);
    formik.setFieldValue('skills', '', false);

    setSelectedClient({});
  }

  const selectClient = (client) => {
    setSelectedClient(client);
    closeOpenSelectClient();
  }

  const clearOpenSelectClient = () => {
    if (selectedClient && selectedClient.id) {
      setSelectedClient({})
    } else {
      setOpenSelectClient(!openSelectClient)
    }
  }

  const closeOpenSelectClient = () => {
    setOpenSelectClient(false);
  }

  const searchClientEmail = () => {
    if (formik.values.email && formik.values.email.length >= 2) {
      const clientEmailTimeout = setTimeout(() => {
        dispatch(fetchClientEmail(formik.values.email));
      }, 1000);

      return () => {
        clearTimeout(clientEmailTimeout);
      }
    } else {
      dispatch(clearClientEmail());
    }
  }

  const searchClientPhone = () => {
    if (formik.values.phone && formik.values.phone.length >= 2) {

      let phone = formik.values.phone;

      const clientPhoneTimeout = setTimeout(() => {
        dispatch(fetchClientPhone(phone));
      }, 1000);

      return () => {
        clearTimeout(clientPhoneTimeout);
      }
    } else {
      dispatch(clearClientPhone());
    }
  }

  const updateClientPhoto = (photo) => {
    setOpenClientPhoto(false);

    formik.setFieldValue('photo', photo, false);
  }

  const dispatchFetchAssociateClient = (client) => {
    dispatch(fetchAssociateClient(client, branchSelected.id));
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const beforeMaskedCpfChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    }

    return {
      ...newState,
      value
    };
  };

  const renderError = (error) => {
    return (error ? (
      <Grid item xs={12}>
        <div className="fv-plugins-message-container">
          {(typeof error) == "string" &&
            <div className="fv-help-block">{error}</div>
          }

          {Array.isArray(error) && error.map((item) =>
            <div className="fv-help-block">{item}</div>
          )}
        </div>
      </Grid>
    ) : null);
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      cpf: '',
      birthday: '',
      skills: '',
    },
    validationSchema: createClientSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        values.phone = values.phone;
        values.branchId = [branchSelected.id];
        values.type = isClient() ? 'client' : 'instructor';

        if (selectedClient) {
          values.responsibleId = selectedClient.id;
        }

        if (values.skills) {
          values.skills = values.skills.map((value, index) => {
            return {
              sport: index,
              skill: value
            }
          }).filter(function( element ) {
             return element.skill !== undefined && element.skill !== '0';
          });
        }

        dispatch(fetchCreateClient(values));

        setSubmitting(false);
      }, 500);
    },
  });

  useEffect(() => {
    return searchClientEmail();
  }, [formik.values.email]);

  useEffect(() => {
    return searchClientPhone();
  }, [formik.values.phone]);

  return (<>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      size="xl"
      contenStyle={{maxWidth: '1000px', backgroundColor: 'transparent !important'}}
      onHide={() => close()}
      show={props.show && !openClientPhoto}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cadastrar {isClient() ? 'cliente' : 'professor'}
          </Modal.Title>
          <CloseIcon onClick={close} className="mt-3 mr-3 cursor-pointer" style={{alignSelf: 'flex-end', position: 'absolute', right: 10, top: 10}} />
        </Modal.Header>
        <Modal.Body>

          <div className="card-body pt-10 pb-10 pl-15 pr-15">
            <div class="row">
            <div class="col-lg-6">
              <Grid container className={classes.label}>
                <Grid item xs={12}>
                  <div class="image-input image-input-outline image-input-circle cursor-pointer" id="kt_user_avatar" onClick={() => setOpenClientPhoto(true)}>
                    <div className="image-input-wrapper" style={{backgroundPosition: 'center', backgroundImage: `url(${formik.values.photo ? `data:image/jpg;base64,${formik.values.photo}` : toAbsoluteUrl('media/users/blank.png')})`}}></div>
                    <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change">
                      <i className="fa fa-pen icon-sm text-muted"></i>
                    </label>
                    {formik.values.photo && <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove">
                      <i className="ki ki-bold-close icon-xs text-muted"></i>
                    </span>}
                  </div>
                </Grid>
              </Grid>

              <Grid container className={classes.label}>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">
                    Dados do {isClient() ? 'cliente' : 'professor'}
                  </span>
                </Grid>
              </Grid>

              <Grid container className={classes.label} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Nome *"
                    fullWidth
                    className={`${classes.textField} is-invalid`}
                    variant="outlined"
                    autoComplete="new-password"
                    {...formik.getFieldProps("name")}
                  />

                  {clients.error && renderError(clients.error.name)}

                  {formik.touched.name && formik.errors.name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.name}</div>
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>

                  <PhoneInput
                    key="client-phone"
                    defaultCountry="BR"
                    international
                    countryCallingCodeEditable={false}
                    withCountryCallingCode
                    numberInputProps={{ className: classes.textField }}
                    value={formik.values.phone ?? ''}
                    onChange={(value) => formik.setFieldValue('phone', value, false)}
                    inputComponent={TextFieldPhoneInput}
                    fullWidth
                    label={
                      selectedClient.id ? "Telefone" : "Telefone *"
                    }
                    variant="outlined"
                    autoComplete="new-password"
                  />

                  {/*<InputMask
                    disabled={false}
                    mask="(99) 9999-99999"
                    maskChar={null}
                    beforeMaskedValueChange={beforeMaskedValueChange}
                    autoComplete="new-password"
                    {...formik.getFieldProps("phone")}
                  >
                    {() => <TextField
                      fullWidth
                      name="phone"
                      label={selectedClient.id ? "Telefone" : "Telefone *"}
                      className={classes.textField}
                      variant="outlined"
                      autoComplete="new-password"
                    />}
                  </InputMask>*/}

                  {clients.error && renderError(clients.error.phone)}

                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.phone}</div>
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label={selectedClient.id ? "E-mail" : "E-mail *"}
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    autoComplete="new-password"
                    {...formik.getFieldProps("email")}
                  />

                  {clients.error && renderError(clients.error.email)}

                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </Grid>
                {isClient() && <Grid item xs={12}>
                  <div class="mt-3 text-dark">
                    Cliente é dependente?
                  </div>
                  <span className={`switch switch-sm ${(openSelectClient || (selectedClient && selectedClient.id)) ? 'switch-success' :  'switch-disabled'}`}>
                    <label>
                      <input
                        type="checkbox"
                        name="dependent"
                        checked={openSelectClient || (selectedClient && selectedClient.id)}
                        onChange={(value) => clearOpenSelectClient()}
                      />
                      <span></span>
                    </label>
                  </span>
                </Grid>}
                {selectedClient && selectedClient.id && <Grid item xs={12}>
                  <div class="font-weight-bold text-dark">
                    Responsável
                  </div>

                  <div class="mt-3 text-dark">
                    Nome: {selectedClient.attributes.name}
                    {selectedClient.attributes.is_dependent && <span className={`ml-2 label label-lg label-light label-inline`}>
                      Dependente
                    </span>}
                  </div>
                  <div class="mt-3 text-dark">
                    E-mail: {selectedClient.attributes.email}{!selectedClient.attributes.email && `-`}
                  </div>
                  <div class="mt-3 text-dark">
                    Telefone: {formatPhoneToString(selectedClient.attributes.phone)}{!selectedClient.attributes.phone && `-`}
                  </div>
                </Grid>}

                <Grid item xs={12}>
                  <div class="mt-4 mb-5 font-weight-bold text-dark">
                    Identificação
                  </div>

                  <InputMask
                    fullWidth
                    mask="999.999.999-99"
                    maskChar={null}
                    beforeMaskedValueChange={beforeMaskedCpfChange}
                    autoComplete="cpf"
                    {...formik.getFieldProps("cpf")}
                  >
                    {() => <TextField
                      fullWidth
                      name="cpf"
                      label="Cpf"
                      className={classes.textField}
                      variant="outlined"
                      autoComplete="cpf"
                    />}
                  </InputMask>

                  {clients.error && renderError(clients.error.cpf)}

                  {formik.touched.cpf && formik.errors.cpf ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.cpf}</div>
                    </div>
                  ) : null}
                </Grid>

                <Grid item xs={12}>
                  <div class="mt-4 mb-5 font-weight-bold text-dark">
                    Data nascimento
                  </div>

                  <TextField
                    name="birthday"
                    label={'Data de nascimento'}
                    type="date"
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...formik.getFieldProps("birthday")}
                  />

                  {clients.error && renderError(clients.error.birthday)}

                  {formik.touched.birthday && formik.errors.birthday ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.birthday}</div>
                    </div>
                  ) : null}
                </Grid>

              </Grid>

              {(clients.clientPhone.id || clients.clientEmail.id) && <>
                <div className="separator separator-dashed my-5"></div>


                  <label className="col-12 col-form-label font-weight-normel text-left">
                    Encontramos esse(s) cliente(s) com as informações que você digitou
                  </label>

                  <label className="col-12 col-form-label font-weight-normel text-left">
                    Associe o cliente para aparecer na listagem de clientes
                  </label>


                {clients.clientPhone.id && <>
                  <div className="separator separator-dashed my-5"></div>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark">Nome</span>: {clients.clientPhone.attributes.name}
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark">Telefone</span>: {clients.clientPhone.attributes.phone}
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark">E-mail</span>: {clients.clientPhone.attributes.email}
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <Button fullWidth variant="outlined" color="primary" size="large" disabled={formik.isSubmitting} onClick={() => dispatchFetchAssociateClient(clients.clientPhone.id)}>
                        Associar {(formik.isSubmitting || clients.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                      </Button>
                    </Grid>
                  </Grid>
                </>}

                {clients.clientEmail.id && <>
                  <div className="separator separator-dashed my-5"></div>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark">Nome</span>: {clients.clientEmail.attributes.name}
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark">Telefone</span>: {clients.clientEmail.attributes.phone}
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark">E-mail</span>: {clients.clientEmail.attributes.email}
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <Button fullWidth variant="outlined" color="primary" size="large" disabled={formik.isSubmitting} onClick={() => dispatchFetchAssociateClient(clients.clientEmail.id)}>
                        Associar {(formik.isSubmitting || clients.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                      </Button>
                    </Grid>
                  </Grid>
                </>}
              </>}
            </div>
            <div class="col-lg-6">
              <Grid item xs={12}>
                <div class="mb-10 font-weight-bold text-dark">
                  Nível de habilidade por esporte
                </div>

                {sports.map(sport => <div class="form-group">
                  <label>{sport.label}</label>
                  <select
                    name={`skills[${sport.value}]`}
                    class="form-control"
                    {...formik.getFieldProps(`skills.${sport.value}`)}
                  >
                    <option value="0">Não informado</option>
                    <option value="1">Iniciante</option>
                    <option value="2">Intermediario</option>
                    <option value="3">Avançado</option>
                    <option value="4">Profissional</option>
                  </select>
                </div>)}
              </Grid>
            </div>
            </div>

            {(typeof clients.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {clients.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={formik.isSubmitting}>
                Cadastrar {(formik.isSubmitting || clients.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>

    <SelectClient
      show={openSelectClient}
      title={'Selecionar responsável pelo dependente'}
      selectClient={selectClient}
      onHide={() => closeOpenSelectClient()}
    />

    <ClientPhoto
      show={openClientPhoto}
      takePhoto={updateClientPhoto}
      onHide={() => setOpenClientPhoto(false)}
      style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
    />
  </>);
};

export default CreateClient;