import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

import { fetchCreateClient, fetchClientName } from "../_redux/clientActions";

import { createClientSchema } from "../_schemas/createClientSchema";

import Snackbar from "../../../components/Snackbar";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
  detailedExpansionPanel: {
    marginBottom: 5,
    boxShadow: 'none !important'
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

const SearchClient = (props) => {
  const dispatch = useDispatch();
  const { clients, branchSelected } = useSelector((state) => ({
    clients: state.clients,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();

  const [showClient, setShowClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});

  const searchClientName = () => {
    if (formik.values.name && formik.values.name.length >= 1) {
      const clientPhoneTimeout = setTimeout(() => {
        dispatch(fetchClientName(formik.values.name, branchSelected.id, false, props.type == 'all' ? '' : 'client'));
        setShowClient(true);
      }, 1000);

      return () => {
        clearTimeout(clientPhoneTimeout);
      }
    }
  }

  const selectClient = (client) => {
    setSelectedClient(client);
    setShowClient(false);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('clientId', client.id, false);

    props.onChange(client.id);
  }

  const clearSelectClient = () => {
    setSelectedClient({});
    formik.setFieldValue('clientId', '', false);
    props.onChange('');
  }

  const isClient = () => {
    return props.type == 'instructor' ? null : true;
  }

  const close = () => {
    props.onHide && props.onHide();
    setShowClient(false);

    formik.setFieldValue('name', '', false);
  }

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: createClientSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    },
  });

  useEffect(() => {
    if (clients.created == true) {
      close();
    }
  }, [clients]);

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);

  return (<>
    <Grid className={classes.label}>
      <Grid item xs={12}>
        <TextField
          name="name"
          label="Pesquisar por nome"
          fullWidth
          className={classes.textField}
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {clients.actionsLoading && <CircularProgress size={30} />}
              </InputAdornment>
            ),
          }}
          {...formik.getFieldProps("name")}
        />

        {formik.touched.name && formik.errors.clientId ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.clientId}</div>
          </div>
        ) : null}
      </Grid>

      {selectedClient.id && <Grid item xs={12}>
        <Chip
          avatar={<Avatar>{selectedClient.attributes.name.match(/\b(\w)/g).join('').slice(0,2)}</Avatar>}
          label={selectedClient.attributes.name}
          color={selectedClient.attributes.type == 'client' ? 'default' : 'primary' }
          onDelete={clearSelectClient}
          className={classes.chip}
          variant="outlined"
        />
      </Grid>}

      {showClient && formik.values.name.length > 0 && <Grid item xs={12} style={{overflow: 'overlay', maxHeight: '200px'}}>
        {clients.clientName.data && clients.clientName.data.map((client) =>
          <div className={classes.detailedExpansionPanel}>
            <DetailedExpansionPanel client={client} onSelect={selectClient} />
          </div>
        )}
        {clients.clientName.data && !clients.clientName.data.length && <span>Nenhum cliente encontrado</span>}
      </Grid>}

      <TextField
        type="hidden"
        disabled={true}
        name="clientId"
        {...formik.getFieldProps("clientId")}
      />
    </Grid>
  </>);
};

export default SearchClient;