import * as Yup from "yup";

export const createPriceSchema = (props) => {
  return Yup.object().shape({
    startAt: Yup.string()
      .required(
        'Horário de inicio deve ser preenchido'
      ),
    finishAt: Yup.string()
      .required(
        'Horário de inicio deve ser preenchido'
      ),
    time: Yup.number()
      .required(
        'Tempo deve ser preenchido'
      ),
    price: Yup.number()
      .required(
        'Preço deve ser preenchido'
      ),
  });
}