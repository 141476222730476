import React, { useState, useEffect } from 'react';
import TimePicker from 'react-times';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';

const useStyles = makeStyles({
  root: {
  },
});

export default function WeekRangeTime(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [checked, setChecked] = useState(true);
  const [focusedStartAt, setFocusedStartAt] = useState(false);
  const [focusedFinishAt, setFocusedFinishAt] = useState(false);

  const [day, setDay] = useState({});

  useEffect(() => {
    setDay({...day, ...props.day});

    if (props.notChecked && (!props.day.start_at && !props.day.finish_at)) {
      setChecked(false);
    }
  }, [props.day]);

  useEffect(() => {
    if (!checked) {
      let params = {
        start_at: '',
        finish_at: '',
      };

      props.setDay({...day, ...params});
    }

  }, [checked]);

  const changedCheck = (value) => {
    setChecked(!checked);
  }


  return (<>
    <Grid item xs={2}>
      <Grid item>
        <span className="text-dark">
          {day.weekday_label}
        </span>
      </Grid>
      <Grid item>
        <span className={`switch switch-sm ${checked ? 'switch-success' :  'switch-danger'}`}>
          <label>
            <input
              type="checkbox"
              name="sendCopyToPersonalEmail"
              checked={checked}
              onChange={changedCheck}
            />
            <span></span>
          </label>
        </span>
      </Grid>
    </Grid>

    <Grid item xs={5}>
      <TimePicker
        timeConfig={{
          step: 30,
          unit: 'minutes'
        }}
        focused={focusedStartAt}
        onFocusChange={setFocusedStartAt}
        withoutIcon
        colorPalette="light"
        theme="classic"
        time={day.start_at}
        disabled={!checked}
        timezone="America/Sao_Paulo"
        onTimeChange={(param) => props.setDay({...props.day, start_at: `${param.hour}:${param.minute}`})}
        trigger={(
          <TextField
            onClick={() => setFocusedStartAt(true)}
            value={day.start_at}
            name="startAt"
            label="Horario abertura"
            type="time"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: "900", // 15 min
              readOnly: true
            }}
            disabled={!checked}
          />
        )}
      />
    </Grid>

    <Grid item xs={5}>
      <TimePicker
        timeConfig={{
          step: 30,
          unit: 'minutes'
        }}
        focused={focusedFinishAt}
        onFocusChange={setFocusedFinishAt}
        withoutIcon
        colorPalette="light"
        theme="classic"
        time={day.finish_at}
        disabled={!checked}
        timezone="America/Sao_Paulo"
        onTimeChange={(param) => props.setDay({...props.day, finish_at: `${param.hour}:${param.minute}`})}
        trigger={(
          <TextField
            onClick={() => {setFocusedFinishAt(true)}}
            value={day.finish_at}
            name="finishAt"
            label="Horario fechamento"
            type="time"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: "900", // 15 min
              readOnly: true
            }}
            disabled={!checked}
          />
        )}
      />
    </Grid>
  </>);
}
