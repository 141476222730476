import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup'

import CloseIcon from '@material-ui/icons/Close';

// import { fetchCreateClient } from "../_redux/clientActions";

// import { createClientSchema } from "../_schemas/createClientSchema";
import { fetchClientName } from "../../Client/_redux/clientActions";
import { fetchinstructors } from "../../Configuration/User/_redux/userActions";

import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import { fetchListRecurrenceDays } from "../_redux/scheduleActions";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const SelectParticipant = (props) => {
  const dispatch = useDispatch();
  const { schedules, clients, users, prices, branchSelected } = useSelector((state) => ({
    schedules: state.schedules,
    clients: state.clients,
    users: state.users,
    prices: state.prices,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();

  const [selectedClient, setSelectedClient] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const [showClient, setShowClient] = useState(false);
  const [branchFilter, setBranchFilter] = useState(true);

  const [type, setType] = useState('single');
  const [contractType, setContractType] = useState('');
  const [startDateAt, setStartDateAt] = useState('');
  const [finishDateAt, setFinishDateAt] = useState('infinite');
  const [weekdays, setWeekdays] = useState([]);
  const [dates, setDates] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (schedules.addClient == true) {
      close();
    }
  }, [schedules]);

  useEffect(() => {
    if (!props.show) {
      close();
    } else {
      dispatchSearchClientName();
    }
  }, [props.show]);

  const close = () => {
    props.onHide();

    setShowClient(false);
    clearSelectClient();
    setSelectedService({});
    setContractType('');
    setStartDateAt('');
    setWeekdays([]);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('startDateAt', '', false);
  }

  const selectClient = (client) => {
    setSelectedClient(client);
  }

  const selectRecurrenceClient = () => {
    dispatchClient(selectedClient);
  }

  const dispatchClient = (attributes) => {
    let client = JSON.parse(JSON.stringify(attributes));

    if (props.selectClient) {
      props.selectClient(client);
    }
  }

  const clearSelectClient = () => {
    setSelectedClient({});
  }

  const dispatchSearchClientName = () => {
    let branch = branchFilter ? branchSelected && branchSelected.id : '';

    if (props.show) {
      dispatch(fetchinstructors(formik.values.name, branch));
    }
  }

  const searchClientName = () => {
    if (props.show) {
      const clientNameRequestTimeout = setTimeout(() => {
        dispatchSearchClientName();
        setShowClient(true);
      }, 400);

      return () => {
        clearTimeout(clientNameRequestTimeout);
      };
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      startDateAt: '',
      serviceId: '',
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    },
  });

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);

  useEffect(() => {
    dispatchSearchClientName();
  }, [branchFilter]);

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Associar professor
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          {!selectedClient.id && <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Pesquisar por nome"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {users.actionsLoading && <CircularProgress size={30} />}
                      </InputAdornment>
                    ),
                  }}
                  {...formik.getFieldProps("name")}
                />

                {formik.touched.name && formik.errors.clientId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.clientId}</div>
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <div class="w-100 align-self-end">
                  <ul class="nav nav-pills nav-pills-sm nav-dark-75 float-right pr-0" role="tablist">
                    <li class="nav-item">
                      <a class={branchFilter == false ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setBranchFilter(false)}>
                        <span class="nav-text font-size-sm">Todas unidades</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class={branchFilter == true ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setBranchFilter(true)}>
                        <span class="nav-text font-size-sm">
                          Esta unidade
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Grid>

              {<Grid item xs={12}>
                {users.entities && users.entities.map((client) =>
                  <div className={classes.detailedExpansionPanel}>
                    <DetailedExpansionPanel client={client} onSelect={selectClient} />
                  </div>
                )}
                {users.entities && !users.entities.length && <span>Nenhum professor encontrado</span>}
              </Grid>}
            </Grid>

            {(typeof users.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {users.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>}

          {selectedClient.id && <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Confirme os dados do professor
                </span>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">Nome: {selectedClient.attributes.name}</div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">E-mail: {selectedClient.attributes.email}</div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">Telefone: {selectedClient.attributes.phone}</div>
                </div>
              </Grid>
            </Grid>
          </Container>}
        </Modal.Body>

        {selectedClient.id && <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" onClick={selectRecurrenceClient} disabled={(formik.isSubmitting)}>
                Confirmar {(schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>}
      </form>
    </Modal>
  </>);
};

export default SelectParticipant;