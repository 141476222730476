import { toast } from 'react-toastify';

export const notifySuccess = (value, id, time = 3000) => {
  toast.success(value, {
    position: "top-right",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "light",
    toastId: id
  });
};

export const notifyError = (value, id, time = 3000) => {
  toast.error(value, {
    position: "top-right",
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "light",
    toastId: id
  });
};