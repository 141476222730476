import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  makeStyles,
  Grid,
  TextField,
  Container,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  OutlinedInput,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import ClientPhoto from '../../../Client/components/ClientPhoto';
import { notifySuccess } from "app/utils/Notify";
import { fetchCreateUser } from "app/modules/Configuration/User/_redux/userActions";
import { createUserSchema } from "../_schemas/createUserSchema";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    //
  },
}));

const USER_STATUS = [
  {
    name: "active",
    display_name: "Ativo",
  },
  {
    name: "blocked",
    display_name: "Bloqueado",
  },
];

const CreateUser = (props) => {
  const dispatch = useDispatch();
  const { branches, userPermissions, users } = useSelector(
    (state) => ({
      branches: state.branches,
      userPermissions: state.permissions,
      users: state.users,
    }),
    shallowEqual
  );

  const classes = useStyles();
  const [openUserPhoto, setOpenUserPhoto] = useState(false);
  const [type, setType] = useState('');

  const isUser = () => {
    return props.type == 'instructor' ? null : true;
  }

  const updateUserPhoto = (photo) => {
    setOpenUserPhoto(false);

    formik.setFieldValue('photo', photo, false);
  }

  const selectAllPermissions = () => {
    formik.setFieldValue('permissions', userPermissions.entities.map((permission) => {
      return permission.id;
    }), false);
  }

  useEffect(() => {
    if (users.created) {
      close();
      notifySuccess("Usuário criado com sucesso", "user-created", 2000);
    }
  }, [users]);

  useEffect(() => {
    formik.setFieldValue("status", "active", false);
  }, []);

  // const toStep = (step) => {
  //   setStep(step);
  // };

  const close = () => {
    formik.setFieldValue("name", "", false);
    formik.setFieldValue("photo", "", false);
    formik.setFieldValue("email", "", false);
    formik.setFieldValue("branchesId", [], false);
    formik.setFieldValue("status", "", false);
    formik.setFieldValue("permissions", [], false);

    props.onHide();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      status: "",
      branchesId: [],
      permissions: [],
    },
    validationSchema: createUserSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        values.type = isUser() ? 'user' : 'instructor';

        dispatch(fetchCreateUser(values));
        setSubmitting(false);
      }, 500);
    },
  });

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={true}
        show={props.show && !openUserPhoto}
      >
        <form
          className="form form-label-right"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          variant="outlined"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Criar {isUser() ? 'usuário' : 'professor'}
            </Modal.Title>
            <CloseIcon onClick={close} className="mt-3 mr-3 cursor-pointer" />
          </Modal.Header>
          <Modal.Body>
            <Container maxWidth="xl">
              <Grid
                container
                className={classes.content}
                alignItems="flex-start"
                justify="space-evenly"
              >
                <Grid container md={6} spacing={3} className="px-5">
                  <Grid item xs={12} className={classes.label}>
                    <div class="image-input image-input-outline image-input-circle cursor-pointer" id="kt_user_avatar" onClick={() => setOpenUserPhoto(true)}>
                      <div className="image-input-wrapper" style={{backgroundPosition: 'center', backgroundImage: `url(${formik.values.photo ? `data:image/jpg;base64,${formik.values.photo}` : toAbsoluteUrl('../media/users/blank.png')})`}}></div>
                      <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change">
                        <i className="fa fa-pen icon-sm text-muted"></i>
                      </label>
                      {formik.values.photo && <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove">
                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                      </span>}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label="Nome *"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label="E-mail *"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Status *"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        },
                      }}
                      name="status"
                      fullWidth
                      className={classes.textField}
                      {...formik.getFieldProps("status")}
                    >
                      {USER_STATUS.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.display_name}
                        </MenuItem>
                      ))}
                    </TextField>

                    {formik.touched.status && formik.errors.status ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.status}
                        </div>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid container md={6} spacing={3} className="px-5 pt-5">
                  <Grid item xs={12}>
                    <div className="d-flex">
                      <InputLabel id="permissions-label">Permissões</InputLabel>
                      <span className="font-weight-bolder text-primary text-right" style={{flex: 1}}>
                        <span className="cursor-pointer" onClick={() => selectAllPermissions()}>Selecionar todos</span>
                      </span>
                    </div>
                    <Select
                      multiple
                      fullWidth
                      displayEmpty
                      labelId="permissions-label"
                      name="permissions"
                      input={<OutlinedInput />}
                      renderValue={(selected) =>
                        userPermissions.entities.length ? (
                          selected.length ? (
                            userPermissions.entities
                              .filter((entity) => selected.includes(entity.id))
                              .map((el) => el.attributes.description)
                              .join(", ")
                          ) : (
                            "⚠️ Selecione uma permissão"
                          )
                        ) : (
                          <div className="d-flex align-items-center">
                            <div className="mr-2 text-muted">Carregando...</div>
                            <div className="spinner spinner-primary mr-10"></div>
                          </div>
                        )
                      }
                      {...formik.getFieldProps("permissions")}
                    >
                      {userPermissions.entities.length ? (
                        userPermissions.entities.map((perm) => (
                          <MenuItem key={perm.id} value={perm.id}>
                            <Checkbox
                              checked={formik?.values?.permissions.includes(
                                perm.id
                              )}
                            />
                            {perm.attributes.description}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>
                          <div className="d-flex align-items-center">
                            <div className="mr-2 text-muted">Carregando...</div>
                            <div className="spinner spinner-primary mr-10"></div>
                          </div>
                        </MenuItem>
                      )}
                    </Select>

                    {formik.touched.permissions && formik.errors.permissions ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.permissions}
                        </div>
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel id="branchesId-label">Unidades</InputLabel>
                    <Select
                      multiple
                      fullWidth
                      displayEmpty
                      labelId="branchesId-label"
                      name="branchesId"
                      input={<OutlinedInput />}
                      renderValue={(selected) =>
                        branches.branchesCompany.length ? (
                          selected.length ? (
                            branches.branchesCompany
                              .filter((branch) => selected.includes(branch.id))
                              .map((el) => el.attributes.name)
                              .join(", ")
                          ) : (
                            "⚠️ Selecione uma unidade"
                          )
                        ) : (
                          <div className="d-flex align-items-center">
                            <div className="mr-2 text-muted">Carregando...</div>
                            <div className="spinner spinner-primary mr-10"></div>
                          </div>
                        )
                      }
                      {...formik.getFieldProps("branchesId")}
                    >
                      {branches.branchesCompany.length ? (
                        branches.branchesCompany.map((branch) => (
                          <MenuItem key={branch.id} value={branch.id}>
                            <Checkbox
                              checked={formik?.values?.branchesId.includes(
                                branch.id
                              )}
                            />
                            {branch.attributes.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>Carregando...</MenuItem>
                      )}
                    </Select>

                    {formik.touched.branchesId && formik.errors.branchesId ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.branchesId}
                        </div>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Grid container spacing={3}>
              <Grid item xs style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled={users.actionsLoading}
                >
                  Confirmar
                  {users.actionsLoading && (
                    <div className="spinner spinner-primary mr-10 ml-5"></div>
                  )}
                </Button>
              </Grid>
              <Grid item xs style={{ textAlign: "left" }}>
                <Button size="large" onClick={close}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Modal.Footer>
        </form>
      </Modal>

      <ClientPhoto
        show={openUserPhoto}
        takePhoto={updateUserPhoto}
        onHide={() => setOpenUserPhoto(false)}
        style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
      />
    </>
  );
};

export default CreateUser;
