import axios from "axios";

export const GET_LOGS = `${process.env.REACT_APP_LOG_URL}/logs/events`;
export const CREATE_OBSERVATION = `${process.env.REACT_APP_LOG_URL}/logs/observation`;

export function getLogsEvents(params) {
  return axios.get(GET_LOGS, { params: params });
}

export function createObservation(params) {
  return axios.post(CREATE_OBSERVATION, params);
}
