import React from "react";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error.scss";

export default function Error404() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-13.jpg")})`
        }}
      >
        <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
          <h1 className="error-title text-stroke text-transparent">404</h1>
          {/*<p className="display-4 font-weight-boldest text-white mb-12">
            Como você chegou aqui?
          </p>*/}
          <p className="font-size-h1 font-weight-boldest text-white">
            Parece que não conseguimos encontrar
            <br />
            a página que você está procurando.
          </p>
          <p className="font-size-h4 line-height-md text-white">
            Pode haver um erro de ortografia na URL inserida,
            <br />
            ou a página que você está procurando pode não existir mais.
          </p>
        </div>
      </div>
    </div>
  );
}
