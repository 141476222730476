import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entityForEdit: undefined,
  paymentChargeback: {},
  lastError: null,
  created: false,
  updated: false,
  renewed: false,
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const paymentChargebackSlice = createSlice({
  name: "paymentChargebacks",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    loadingFinish: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.created = false;
      state.updated = false;
    },

    paymentChargebacksFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.created = false;
      state.updated = false;
      state.totalCount = 0;

      if (entities && entities.meta && entities.meta.total) {
        state.totalCount = entities.meta.total;
      }
    },

    paymentChargebackFetched: (state, action) => {
      state.listLoading = false;
      state.paymentChargeback = action.payload.paymentChargeback;
      state.created = false;
      state.updated = false;
      state.renewed = false;
      state.error = null;
    },

    paymentChargebackCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.created = true;
    },

    paymentChargebackUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.updated = true;
    },

    paymentChargebackRenewed: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.renewed = true;
    },

    paymentChargebackDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
  }
});
