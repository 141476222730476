import React, { useEffect, useState } from "react";

import SVG from "react-inlinesvg";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers";

import {
    fetchGetClientDayUse
} from "../../DayUse/_redux/dayUseActions";

import EditDayUse from "../../DayUse/components/EditDayUse";

const DayUseList = (props) => {
  const { branchSelected } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const dispatch = useDispatch();

  const [dayUse, setDayUse] = useState([]);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatchFetchClientDayUse = () => {
    setIsLoading(true);
    dispatch(fetchGetClientDayUse(props.client_id, branchSelected.id)).then(response => {
      setIsLoading(false);
      setDayUse(response.data);
    });
  }

  useEffect(() => {
    dispatchFetchClientDayUse();
  }, []);

  const openEditEntity = (signature) => {
    setEditEntity(signature);
    setEditEntityShow(true);
  }

  const closeEditEntity = () => {
    setEditEntityShow(false);
    dispatchFetchClientDayUse();
  }

  return (
    <>
      <div>
        <h4 class="card-title">
          Day use
        </h4>
        <div className="table-responsive">
          <Table
            className="table table-head-custom table-head-bg table-borderless table-vertical-center"
            aria-labelledby="tableTitle"
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell>Plano</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dayUse && dayUse.map((day_use, index) => {
                return <TableRow
                  tabIndex={-1}
                  key={'clubinho-client-' + day_use.id}
                >
                  <TableCell component="th" scope="row" padding="none">
                    {day_use.relationships.plan.attributes.name}
                  </TableCell>
                  <TableCell align="left">{moment(day_use.attributes.day).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align="left">{day_use.relationships.status.attributes.display_name}</TableCell>
                  <TableCell align="right">
                    <a
                      title="Editar assinatura"
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      onClick={() => openEditEntity(day_use)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          title="Editar Day use"
                          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                        />
                      </span>
                    </a>
                  </TableCell>
                </TableRow>
              })}

              {!isLoading && dayUse && dayUse.length == 0 &&
                <TableRow><TableCell colSpan={12} className="text-center pt-5">Nenhum registro encontrado</TableCell></TableRow>
              }
            </TableBody>
          </Table>

          <div className="d-flex align-items-center py-3">
            {isLoading && <div className="d-flex align-items-center">
                <div className="mr-2 text-muted">Carregando...</div>
                <div className="spinner spinner-primary mr-10"></div>
            </div>}
          </div>
        </div>
      </div>
      <EditDayUse show={editEntityShow} onHide={closeEditEntity} event={editEntity} hideClient={true} />
    </>
  );
}

export default DayUseList;