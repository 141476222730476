import * as requestFromServer from "./priceCrud";
import { priceSlice, callTypes } from "./priceSlice";

const { actions } = priceSlice;

export const fetchPrices = (serviceId = null) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getPrices({serviceId: serviceId})
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.pricesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.pricesFetched({ totalCount: 0, entities: null }));
    });
};

export const fetchPriceService = (fieldId, startAt, endAt, modality, type) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPriceServiceByDate(fieldId, startAt, endAt, modality, type)
    .then(response => {
      const data = response.data.data;
      dispatch(actions.priceServiceFetched({ data: data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find remark";
      dispatch(actions.priceServiceFetched({ data: [] }));
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCreatePrice = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let values = {
    price: params.price,
    time: params.time,
    weekday: params.weekday,
    start_at: params.startAt,
    finish_at: params.finishAt,
  };

  if (params.memberPrice) {
    values.member_price = params.memberPrice;
  }

  if (params.priceQuarterly) {
    values.price_quarterly = params.priceQuarterly;
  }

  if (params.priceSemester) {
    values.price_semester = params.priceSemester;
  }

  if (params.priceYearly) {
    values.price_yearly = params.priceYearly;
  }

  return requestFromServer
    .createPrice(params.serviceId, values).then(response => {
      dispatch(actions.priceCreated());
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdatePrice = (priceId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let values = {
    price_value: params.price,
    time: params.time,
    weekday: params.weekday,
    start_at: params.startAt,
    finish_at: params.finishAt,
    price_quarterly: 0,
    price_semester: 0,
    price_yearly: 0,
  };

  if (params.memberPrice) {
    values.member_price = params.memberPrice;
  }

  if (params.priceQuarterly) {
    values.price_quarterly = params.priceQuarterly;
  }

  if (params.priceSemester) {
    values.price_semester = params.priceSemester;
  }

  if (params.priceYearly) {
    values.price_yearly = params.priceYearly;
  }

  return requestFromServer
    .updatePrice(priceId, values)
    .then(response => {
      dispatch(actions.priceUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRemovePrice = (priceId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .removePrice(priceId)
    .then(response => {
      dispatch(actions.priceUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};