import axios from "axios";

export const GET_SERVICES = `${process.env.REACT_APP_API_URL}/services`;
export const CREATE_SERVICE = `${process.env.REACT_APP_API_URL}/services`;
export const UPDATE_SERVICE = `${process.env.REACT_APP_API_URL}/services`;

export function getServices(params) {
  return axios.get(GET_SERVICES, { params: params });
}

export function createService(params) {
  return axios.post(CREATE_SERVICE, params);
}

export function updateService(serviceId, params) {
  params.recurrence_period = 'weekly'; // TODO: remove

  if (!params.recurrence_times) {
    delete params.recurrence_times;
  }

  if (!params.billing_type) {
    delete params.billing_type;
  }

  return axios.put(`${UPDATE_SERVICE}/${serviceId}`, params);
}