import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  passwordUpdated: false,
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const accountSlice = createSlice({
  name: "account",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;

      if (action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    notifyPasswordUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.passwordUpdated = true;
    },

    notifiedPasswordUpdated: (state, action) => {
      state.passwordUpdated = false;
    },
  }
});
