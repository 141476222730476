import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  entities: [],
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const bankSlice = createSlice({
  name: "banks",
  initialState: initialState,
  reducers: {

    banksFetched: (state, action) => {
      const { entities } = action.payload;
      state.entities = entities;
    },
  }
});
