import axios from "axios";

export const GET_CREDIT_PURHCASES = `${process.env.REACT_APP_API_URL}/credit_purchases`;
export const CREATE_CREDIT_PURHCASES = `${process.env.REACT_APP_API_URL}/credit_purchases`;
export const UPDATE_CREDIT_PURHCASES = `${process.env.REACT_APP_API_URL}/credit_purchases`;

export function getCreditPurchases(params) {
  return axios.get(GET_CREDIT_PURHCASES, { params: params });
}

export function getAvailableCreditPurchases(clientId, params) {
  return axios.get(`${GET_CREDIT_PURHCASES}/${clientId}`, { params: params });
}

export function getClientCreditPurchases(clientId, branchId) {
  let params = {
    client_id: clientId,
    branch_id: branchId,
  }

  return axios.get(GET_CREDIT_PURHCASES, { params: params });
}

export function getCreditPurchaseById(creditPurchaseId) {
  return axios.get(`${GET_CREDIT_PURHCASES}/${creditPurchaseId}`);
}

export function renewCreditPurchase(creditPurchaseId) {
  return axios.post(`${GET_CREDIT_PURHCASES}/${creditPurchaseId}/renew`);
}

export function createCreditPurchase(params) {
  return axios.post(CREATE_CREDIT_PURHCASES, params);
}

export function updateCreditPurchase(creditPurchaseId, params) {
  return axios.put(`${UPDATE_CREDIT_PURHCASES}/${creditPurchaseId}`, params);
}

export function deleteCreditPurchase(creditPurchaseId, params) {
  return axios.delete(`${UPDATE_CREDIT_PURHCASES}/${creditPurchaseId}`, {data: params});
}
