import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import Webcam from "react-webcam";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup'

import CloseIcon from '@material-ui/icons/Close';

// import { fetchCreateClient } from "../_redux/clientActions";

// import { createClientSchema } from "../_schemas/createClientSchema";
// import { fetchClientName } from "../../Client/_redux/clientActions";
// import { fetchListRecurrenceDays } from "../_redux/scheduleActions";

import { notifySuccess, notifyError } from "../../../utils/Notify";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const ClientPhoto = (props) => {
  const dispatch = useDispatch();
  const { schedules } = useSelector((state) => ({
    schedules: state.schedules,
  }), shallowEqual);

  const classes = useStyles();
  const webcamRef = useRef(null);

  const [openWebcam, setOpenWebcam] = useState(false);
  const [image, setImage] = useState('');

  // useEffect(() => {
  //   if (schedules.canceled == true) {
  //     close();
  //     notifyError('Cancelado com sucesso', 'schedules-canceled');
  //   }
  // }, [schedules]);

  const close = () => {
    props.onHide();

    setOpenWebcam(false);
    setImage('');
  }

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setImage(imageSrc);
  }

  const save = () => {
    props.takePhoto(image.split(',')[1]);

    close();
  }

  useEffect(() => {
    if (props.show) {

    }
  }, [props.show]);

  const formik = useFormik({
    initialValues: {
      date: '',
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    },
  });

  return (<>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Foto do cliente
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}  className="text-center">
                <label className="col-12 col-form-label font-weight-normel text-center">
                  Você pode escolher um arquivo ou tirar foto de uma camera
                </label>

                <a class="btn btn-light-info font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => console.log(true)}>
                  Escolher arquivo
                </a>

                <a class="btn btn-light-success font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => setOpenWebcam(!openWebcam)}>
                  {!openWebcam && `Abrir camera`}
                  {openWebcam && `Fechar camera`}
                </a>
              </Grid>
              {openWebcam && <Grid item xs={12}  className="text-center">

                {!image && <a class="btn btn-light-primary font-weight-bolder font-size-sm mr-5 mt-2 mb-2" onClick={() => capture()}>
                  Tirar foto
                </a>}

                {image && <>
                  <a class="btn btn-light-success font-weight-bolder font-size-sm mr-5 mt-2 mb-2" onClick={() => save()}>
                    Salvar foto
                  </a>

                  <a class="btn btn-light-primary font-weight-bolder font-size-sm mr-5 mt-2 mb-2" onClick={() => setImage('')}>
                    Tirar outra foto
                  </a>
                </>}

                {!image && <Webcam
                  className="w-100"
                  mirrored={true}
                  screenshotFormat="image/jpeg"
                  ref={webcamRef}
                />}
              </Grid>}

              {image && <img src={image} className="w-100" />}
            </Grid>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>
  </>);
};

export default ClientPhoto;