import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import ListGroup from 'react-bootstrap/ListGroup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Chip from '@material-ui/core/Chip';

import {
  fetchUpdatePlan,
  fetchDeletePlan
} from "../_redux/planActions";

import {
  fetchPrices,
  fetchCreatePrice,
  fetchRemovePrice,
} from "../../Price/_redux/priceActions";

import { fetchBranches } from "../../Branch/_redux/branchActions";

import { createPlanSchema } from "../_schemas/createPlanSchema";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import CreatePrice from '../../Price/components/CreatePrice';
import EditPrice from '../../Price/components/EditPrice';

import { notifySuccess } from "../../../utils/Notify";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditPlan = (props) => {
  const dispatch = useDispatch();
  const { plans, branches } = useSelector((state) => ({
    plans: state.plans,
    branches: state.branches,
  }), shallowEqual);

  const classes = useStyles();

  const [showEntity, setShowEntity] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [weekdays, setWeekdays] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);

  useEffect(() => {
    if (plans.updated == true) {
      close();
      notifySuccess('Atualizado com sucesso', 'plans-updated', 2000);
    }
  }, [plans]);

  useEffect(() => {
    if (props.event && props.event.id && props.show) {
      if (!branches.entities.length) {
        dispatch(fetchBranches());
      }

      setShowCancel(false);

      formik.setFieldValue('planId', props.event.id, false);
      formik.setFieldValue('name', props.event.attributes.name, false);
      formik.setFieldValue('description', props.event.attributes.description, false);
      formik.setFieldValue('type', props.event.attributes.type, false);
      formik.setFieldValue('duration', props.event.attributes.duration, false);
      formik.setFieldValue('price', props.event.attributes.price, false);
      formik.setFieldValue('memberPrice', props.event.attributes.member_price ?? '', false);
      formik.setFieldValue('availableApp', props.event.attributes.available_app == true ? 'yes' : 'no', false);

      let filteredBranches = [];

      props.event.relationships.branches.map(branch => filteredBranches.push(branch.id));

      setSelectedBranches(filteredBranches);

      let days = dayOfWeekAsInt(props.event.attributes.weekdays);

      formik.setFieldValue('weekdays', days, false);
      setWeekdays(days);

      if (
        props.event.relationships.package &&
        props.event.relationships.package.attributes &&
        props.event.relationships.package.attributes.prices
      ) {
        props.event.relationships.package.attributes.prices.map(price => {
          if (price.amount == 3) {
            formik.setFieldValue('package3Unit', price.price, false);
          }
          if (price.amount == 5) {
            formik.setFieldValue('package5Unit', price.price, false);
          }
          if (price.amount == 10) {
            formik.setFieldValue('package10Unit', price.price, false);
          }
        });
      }
    } else {
      close();
    }
  }, [props.show]);

  const close = () => {
    props.onHide();
    setShowEntity(false);

    formik.setFieldValue('planId', '', false);
    formik.setFieldValue('name', '', false);
    formik.setFieldValue('description', '', false);
    formik.setFieldValue('type', '', false);
    formik.setFieldValue('duration', '', false);
    formik.setFieldValue('price', '', false);
    formik.setFieldValue('memberPrice', '', false);
    formik.setFieldValue('package3Unit', '', false);
    formik.setFieldValue('package5Unit', '', false);
    formik.setFieldValue('package10Unit', '', false);
    formik.setFieldValue('availableApp', 'no', false);

    formik.setFieldValue('weekdays', [2,4,8,16,32,64,1], false);
    setWeekdays([2,4,8,16,32,64,1]);
  }

  const dayOfWeekAsInt = (days) => {
    return days.map(weekday => {
      return [1,2,4,8,16,32,64][weekday] || '';
    });
  }

  const renderError = (error) => {
    return (error ? (
      <Grid item xs={12}>
        <div className="fv-plugins-message-container">
          {(typeof error) == "string" &&
            <div className="fv-help-block">{error}</div>
          }

          {Array.isArray(error) && error.map((item) =>
            <div className="fv-help-block">{item}</div>
          )}
        </div>
      </Grid>
    ) : null);
  }

  const toggleShowCancel = () => {
    setShowCancel(!showCancel);
  }

  const branchCheck = (serviceId) => {
    if (branchIsChecked(serviceId)) {
      setSelectedBranches(selectedBranches.filter(arrayItem => arrayItem !== serviceId));
    } else {
      setSelectedBranches([...selectedBranches, serviceId]);
    }
  }

  const branchIsChecked = (branchId) => {
    return selectedBranches.includes(branchId);
  }

  const formik = useFormik({
    initialValues: {
      planId: '',
      name: '',
      description: '',
      type: 'signature',
      price: '',
      memberPrice: '',
      duration: '30',
      weekdays: [2,4,8,16,32,64,1],
      package3Unit: '',
      package5Unit: '',
      package10Unit: '',
      availableApp: 'no',
    },
    validationSchema: createPlanSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        values.weekday = weekdays.reduce((x, y) => x + y, 0);
        values.branches = selectedBranches;

        if (values.type == 'day_use' && values.availableApp == 'yes') {
          values.package = [
            {
              amount: 3,
              price: values.package3Unit,
            },
            {
              amount: 5,
              price: values.package5Unit,
            },
            {
              amount: 10,
              price: values.package10Unit,
            }
          ];
        }

        dispatch(fetchUpdatePlan(values.planId, values));

        setSubmitting(false);
      }, 500);
    },
  });

  const selectedWeekDayColor = (weekday) => {
    return weekdays.includes(weekday) ? "primary" : "default";
  }

  const selectWeekDay = (weekday) => {
    if(!weekdays.includes(weekday)) {
      setWeekdays([...weekdays, weekday]);
    } else{
        var array = [...weekdays];
        var index = array.indexOf(weekday);

        if (index !== -1) {
          array.splice(index, 1);

          setWeekdays(array);
        }
    }
  }

  const type = [
    { label: 'Clubinho', value: 'signature' },
    { label: 'Day use', value: 'day_use' },
  ];

  const availableApps = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  return (<>
    {<Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editar plano
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="sm">
            <Grid container className={classes.label}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Dados de exibição
                </span>
              </Grid>
            </Grid>

            <Grid container className={classes.label} spacing={2}>
              <TextField
                type="hidden"
                disabled={true}
                name="planId"
                {...formik.getFieldProps("planId")}
              />

              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Nome"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  {...formik.getFieldProps("name")}
                />

                {plans.error && renderError(plans.error.name)}

                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="description"
                  label="Descrição"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  multiline
                  rows={3}
                  maxRows={5}
                  {...formik.getFieldProps("description")}
                />

                {plans.error && renderError(plans.error.description)}

                {formik.touched.description && formik.errors.description ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.description}</div>
                  </div>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Tipo de plano e preço
                </span>
              </Grid>

              <Grid item xs={12}>
                <Grid item xs={6}>
                  <TextField
                    select
                    label="Tipo de plano"
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        }
                      }
                    }}
                    name="type"
                    fullWidth
                    disabled={true}
                    className={classes.textField}
                    {...formik.getFieldProps("type")}
                  >
                    {type.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                  </TextField>

                  {plans.error && renderError(plans.error.type)}
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="price"
                  label="Preço"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  {...formik.getFieldProps("price")}
                />

                {plans.error && renderError(plans.error.price)}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="memberPrice"
                  label="Preço para alunos"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  {...formik.getFieldProps("memberPrice")}
                />

                {plans.error && renderError(plans.error.member_price)}
              </Grid>

              {formik.getFieldProps('type').value == 'signature' && <Grid item xs={6}>
                <TextField
                  name="duration"
                  label="Duração"
                  fullWidth
                  className={`${classes.textField} is-invalid`}
                  variant="outlined"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">mês</InputAdornment>,
                  }}
                  {...formik.getFieldProps("duration")}
                  disabled
                />

                {plans.error && renderError(plans.error.duration)}
              </Grid>}

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Disponivel no aplicativo
                </span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  select
                  label="Disponivel no app"
                  variant="outlined"
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      }
                    }
                  }}
                  name="availableApp"
                  fullWidth
                  className={classes.textField}
                  {...formik.getFieldProps("availableApp")}
                >
                  {availableApps.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                </TextField>

                {plans.error && renderError(plans.error.available_app)}
              </Grid>

              {formik.getFieldProps('availableApp').value == 'yes' && formik.getFieldProps('type').value == 'day_use' && <>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">
                    Pacotes de créditos
                  </span>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    name="package3Unit"
                    label="3 créditos"
                    fullWidth
                    className={`${classes.textField} is-invalid`}
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    {...formik.getFieldProps("package3Unit")}
                  />

                  {plans.error && renderError(plans.error['package.0.price'])}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="package5Unit"
                    label="5 créditos"
                    fullWidth
                    className={`${classes.textField} is-invalid`}
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    {...formik.getFieldProps("package5Unit")}
                  />

                  {plans.error && renderError(plans.error['package.1.price'])}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="package10Unit"
                    label="10 créditos"
                    fullWidth
                    className={`${classes.textField} is-invalid`}
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                    {...formik.getFieldProps("package10Unit")}
                  />

                  {plans.error && renderError(plans.error['package.2.price'])}
                </Grid>
              </>}

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  {formik.getFieldProps('type').value == 'signature' && "Dias da semana que o jogador pode fazer check-in:"}
                  {formik.getFieldProps('type').value == 'day_use' && "Dias da semana que pode ser utilizado:"}
                </span>
              </Grid>

              <Grid container className={classes.label} spacing={1}>
                <Grid item xs><Chip onClick={() => selectWeekDay(2)} clickable color={selectedWeekDayColor(2)} variant="outlined" label="Seg" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(4)} clickable color={selectedWeekDayColor(4)} variant="outlined" label="Ter" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(8)} clickable color={selectedWeekDayColor(8)} variant="outlined" label="Qua" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(16)} clickable color={selectedWeekDayColor(16)} variant="outlined" label="Qui" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(32)} clickable color={selectedWeekDayColor(32)} variant="outlined" label="Sex" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(64)} clickable color={selectedWeekDayColor(64)} variant="outlined" label="Sab" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(1)} clickable color={selectedWeekDayColor(1)} variant="outlined" label="Dom" /></Grid>
              </Grid>

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Selecione as unidades que participam do plano <Icon className={classes.icon}>info_outlined</Icon>
                </span>
              </Grid>

              <Grid item xs={12}>
                <div className="checkbox-list">
                  {branches.entities.map(branch => <>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        key={branch.id}
                        name={branch.id}
                        checked={branchIsChecked(branch.id)}
                        onChange={() => branchCheck(branch.id)}
                      />
                      <span></span>{branch.attributes.name}
                    </label>
                  </>)}
                </div>

                {plans.error && renderError(plans.error.service_id)}
              </Grid>
            </Grid>

            {(typeof plans.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {plans.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>
        </Modal.Body>

        {showCancel && <Modal.Body style={{backgroundColor: '#ffe2e2'}}>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{textAlign: 'left'}}>
              <span className="font-weight-bolder text-dark">
                Deseja desativar o plano?
              </span>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right'}}>
              <CloseIcon fontSize="small" onClick={toggleShowCancel} style={{cursor:  'pointer'}} />
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right'}}>
              <Button style={{backgroundColor: '#ff6565', color: 'white'}} variant="contained" size="large" onClick={() => dispatch(fetchDeletePlan(props.event.id))}>
                Desativar
              </Button>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'left'}}>
              <Button size="large" onClick={() => toggleShowCancel()}>
                Não
              </Button>
            </Grid>
          </Grid>
        </Modal.Body>}

        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={formik.isSubmitting || props.readOnly}>
                Salvar {(formik.isSubmitting || plans.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs={4} style={{textAlign: 'left'}}>
              <Button size="large" onClick={() => close()}>
                Fechar
              </Button>
            </Grid>
            <Grid item xs={2} style={{textAlign: 'right'}}>
              {!props.readOnly && <Tooltip title="Desativar plano" placement="top">
                <IconButton variant="outlined" aria-label="delete" onClick={toggleShowCancel} disableFocusRipple={true}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>}

    {/*<CreatePrice show={createEntityShow} onHide={closeCreateEntity} onSubmit={(values) => requestCreatePrice(values)} />
    <EditPrice show={editEntityShow} onHide={closeEditEntity} event={editEntity} />*/}
  </>);
};

export default EditPlan;