import * as Yup from "yup";

export const createScheduleSchema = (props) => {
  return Yup.object().shape({
    fieldId: Yup.string()
      .required(
        'Selecione um campo'
      ).nullable(),
    // clientId: Yup.string()
    //   .required(
    //     'Cliente deve ser selecionado'
    //   ),
  });
}