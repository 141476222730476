import axios from "axios";

export const GET_DAY_USE = `${process.env.REACT_APP_API_URL}/day_uses`;
export const CREATE_DAY_USE = `${process.env.REACT_APP_API_URL}/day_uses`;
export const UPDATE_DAY_USE = `${process.env.REACT_APP_API_URL}/day_uses`;

export function getDayUses(perPage, page, status = '', day = '', branchId = '') {
  let params = {
    per_page: perPage,
    page: page,
  };

  if (status) {
    params.status_id = status;
  }

  if (day) {
    params.day = day;
  }

  if (branchId) {
    params.branch_id = branchId;
  }

  return axios.get(GET_DAY_USE, { params: params });
}

export function getClientDayUse(clientId, branchId) {
  let params = {
    client_id: clientId,
    branch_id: branchId,
  }
  return axios.get(GET_DAY_USE, { params: params });
}

export function getDayUseById(dayUseId) {
  return axios.get(`${GET_DAY_USE}/${dayUseId}`);
}

export function renewDayUse(dayUseId) {
  return axios.post(`${GET_DAY_USE}/${dayUseId}/renew`);
}

export function createDayUse(params) {
  return axios.post(CREATE_DAY_USE, params);
}

export function updateDayUse(dayUseId, params) {
  return axios.put(`${UPDATE_DAY_USE}/${dayUseId}`, params);
}

export function deleteDayUse(dayUseId, params) {
  return axios.delete(`${UPDATE_DAY_USE}/${dayUseId}`, {data: params});
}
