import axios from "axios";

export const GET_SCHEDULES = `${process.env.REACT_APP_API_URL}/schedules`;
export const GET_SCHEDULES_COMPANY = `${process.env.REACT_APP_API_URL}/schedules/by_company`;
export const CREATE_SCHEDULES = `${process.env.REACT_APP_API_URL}/fields`;
export const UPDATE_SCHEDULES = `${process.env.REACT_APP_API_URL}/schedules`;
export const CANCEL_SCHEDULES = `${process.env.REACT_APP_API_URL}/schedules`;
export const APPROVE_SCHEDULES = `${process.env.REACT_APP_API_URL}/schedules`;
export const CANCEL_SCHEDULE_SPOT = `${process.env.REACT_APP_API_URL}/schedule_spots`;
export const REMOVE_PARTICIPANT_SCHEDULES = `${process.env.REACT_APP_API_URL}/schedules/participant`;
export const LIST_RECURRENCE_DAYS = `${process.env.REACT_APP_API_URL}/schedules/recurrence_days`;

export function getSchedules(params) {
  return axios.get(GET_SCHEDULES, { params: params });
}

export function getScheduleById(scheduleId, params) {
  return axios.get(`${GET_SCHEDULES}/${scheduleId}`, { params: params });
}

export function createSchedule(fieldId, params) {
  return axios.post(`${CREATE_SCHEDULES}/${fieldId}/schedules`, params);
}

export function moveSchedule(scheduleId, params) {
  return axios.put(`${UPDATE_SCHEDULES}/${scheduleId}/move`, params);
}

export function updateSchedule(scheduleId, params) {
  return axios.put(`${UPDATE_SCHEDULES}/${scheduleId}`, params);
}

export function addClientSchedule(scheduleId, params) {
  return axios.post(`${UPDATE_SCHEDULES}/${scheduleId}/client`, params);
}

export function addContractSchedule(scheduleId, params) {
  return axios.post(`${UPDATE_SCHEDULES}/${scheduleId}/contracts`, params);
}

export function addParticipanteSchedule(scheduleId, params) {
  return axios.post(`${UPDATE_SCHEDULES}/${scheduleId}/participant`, params);
}

export function cancelSchedule(scheduleId, params) {
  return axios.delete(`${CANCEL_SCHEDULES}/${scheduleId}`, {data: params});
}

export function cancelScheduleSpot(scheduleSpotId, params) {
  return axios.delete(`${CANCEL_SCHEDULE_SPOT}/${scheduleSpotId}`, {data: params});
}

export function removeParticipantSchedule(participantScheduleId, params) {
  return axios.delete(`${REMOVE_PARTICIPANT_SCHEDULES}/${participantScheduleId}`, {data: params});
}

export function getSchedulesCompany(statusId = '') {
  let params = {};

  if (statusId) {
    params.status_id = statusId;
  }

  return axios.get(GET_SCHEDULES_COMPANY, { params: params });
}

export function approveSchedule(scheduleId) {
  return axios.patch(`${APPROVE_SCHEDULES}/${scheduleId}`);
}

export function listRecurrenceDays(params) {
  return axios.get(LIST_RECURRENCE_DAYS, { params: params });
}