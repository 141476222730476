import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListGroup from 'react-bootstrap/ListGroup';
import { formatPhoneToString } from "../utils/PhoneParse";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // margin: theme.spacing(1),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  chip: {
    marginRight: 5,
  },
}));

export default function DetailedExpansionPanel(props) {
  const classes = useStyles();

  const [client, setClient] = useState({});
  const [expanded, setExpanded] = useState(false);

  const onSelect = () => {
    setExpanded(false);
    props.onSelect(client);
  }

  useEffect(() => {
    setClient(props.client);
  }, [props.client]);

  return (
    <div className={classes.root}>
      <ListGroup className="my-3" key={client.id} onClick={() => onSelect()} style={{cursor: 'pointer'}}>
        {client.attributes && <ListGroup.Item variant="light">
          <div className="d-flex flex-column flex-grow-1 mr-2">
            <span className="font-weight-normel mb-1">
              <span className="font-weight-bold">Nome</span>: {client.attributes.name}

              {client.attributes.is_dependent && <span className={`ml-2 label label-lg label-light label-inline`}>
                Dependente
              </span>}

              {client.attributes.type == 'instructor' && <span className={`ml-2 label label-lg label-light-success label-inline`}>
                Professor
              </span>}

              {client.attributes.is_member && <span className={`ml-2 label label-lg label-light-primary label-inline`}>
                Aluno
              </span>}
            </span>
          </div>

          <div className="d-flex flex-column flex-grow-1 mr-2">
            <span className="font-weight-normel mb-1">
              <span className="font-weight-bold">Telefone</span>: {formatPhoneToString(client.attributes.phone)}
              {!client.attributes.phone && `-`}
            </span>
          </div>

          <div className="d-flex flex-column flex-grow-1 mr-2">
            <span className="font-weight-normel mb-1">
              <span className="font-weight-bold">E-mail</span>: {client.attributes.email}
              {!client.attributes.email && `-`}
            </span>
          </div>
        </ListGroup.Item>}
      </ListGroup>
    </div>
  );
}