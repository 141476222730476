import axios from "axios";

export const GET_SIGNATURES = `${process.env.REACT_APP_API_URL}/signatures`;
export const CREATE_SIGNATURES = `${process.env.REACT_APP_API_URL}/signatures`;
export const UPDATE_SIGNATURES = `${process.env.REACT_APP_API_URL}/signatures`;

export function getSignatures(params) {
  return axios.get(GET_SIGNATURES, { params: params });
}

export function getClientSignatures(clientId, branchId) {
  let params = {
    client_id: clientId,
    branch_id: branchId,
  }

  return axios.get(GET_SIGNATURES, { params: params });
}

export function getSignatureById(signatureId) {
  return axios.get(`${GET_SIGNATURES}/${signatureId}`);
}

export function renewSignature(signatureId) {
  return axios.post(`${GET_SIGNATURES}/${signatureId}/renew`);
}

export function createSignature(params) {
  return axios.post(CREATE_SIGNATURES, params);
}

export function updateSignature(signatureId, params) {
  return axios.put(`${UPDATE_SIGNATURES}/${signatureId}`, params);
}

export function deleteSignature(signatureId, params) {
  return axios.delete(`${UPDATE_SIGNATURES}/${signatureId}`, {data: params});
}
