import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup'

import CloseIcon from '@material-ui/icons/Close';

import Snackbar from "../../../components/Snackbar";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import DayPicker from '../../../components/DayPicker';
import { RenderError } from '../../../components/RenderError';

import { makeStyles } from '@material-ui/core/styles';

import {
  fetchDeleteCredit,
} from "../_redux/creditActions";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const CancelCredit = (props) => {
  const dispatch = useDispatch();
  const { credits } = useSelector((state) => ({
    credits: state.credits,
  }), shallowEqual);

  const classes = useStyles();

  const close = () => {
    props.onHide();
  }

  const confirm = () => {
    close();
  }

  const dispatchCancelCredit = () => {
    dispatch(fetchDeleteCredit(props.event.id));
  }

  const formik = useFormik({
    initialValues: {
      //
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        dispatchCancelCredit();

        setSubmitting(false);
      }, 500);
    },
  });

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Expirar crédito
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          {props.event && props.event.id && <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <label className="col-12 col-form-label font-weight-normel text-left">
                  O cliente será notificado sobre a expiração do crédito.
                </label>

                <label className="col-12 col-form-label font-weight-normel text-left">
                  O cancelamento é irreversível, o crédito passará a ser listado na aba "Expirados".
                </label>
              </Grid>

              <Grid item xs={12} className="text-center">
                <Button
                  type="submit"
                  fullWidth
                  style={{backgroundColor: '#ff6565', color: 'white'}}
                  variant="contained"
                  size="large"
                  disabled={formik.isSubmitting}
                >
                  Expirar crédito {(formik.isSubmitting || credits.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                </Button>
              </Grid>
            </Grid>
          </Container>}
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>
  </>);
};

export default CancelCredit;