import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers";
import { formatMoney } from "../../../utils/IntegerParse";

import {
  getPaymentTypeLabel,
  getColorPaymentStatus,
  getLabelPaymentStatus,
  getBrandImage,
} from "../../../utils/Labels";

import { fetchPayments } from "../_redux/paymentActions";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export const PaymentList = (props) => {
  const { payments, branchSelected } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
    payments: state.payments,
  }), shallowEqual);

  const classes = useStyles();

  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('created_at');

  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY'));

  useEffect(() => {
    requestEntities();
  }, [branchSelected, year, month]);

  useEffect(() => {
    if (payments.entities) {
      setRows(payments.entities);
    }
  }, [payments]);

  const requestEntities = async () => {
    try {
      dispatch(fetchPayments({
        branchId: branchSelected.id,
        start_at: moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD'),
        finish_at: moment(`${year}-${month}`).endOf('month').format('YYYY-MM-DD'),
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const headRows = [
    { id: 'client_name', ordered: false, disablePadding: false, label: 'Nome' },
    { id: 'price', ordered: false, disablePadding: false, label: 'Valor' },
    { id: 'origin.name', ordered: false, disablePadding: false, label: 'Origem' },
    { id: 'origin_type', ordered: false, disablePadding: false, label: 'método' },
    { id: 'created_at', ordered: false, disablePadding: false, label: 'Data' },
    { id: 'status', ordered: false, disablePadding: false, label: 'Status' },
  ];

  return (<>
    <div class="d-flex">
      <div class="col-6">
        <h3 class="card-title">Pagamentos realizados</h3>
      </div>
    </div>

    <div className="separator separator-dashed mt-1 mb-10"></div>

    <div className="row">
      <div className="col-lg-3">
        <div className="form-group">
          <select
            class="custom-select form-control form-control-solid form-control-lg"
            value={month}
            onChange={(value) => setMonth(value.target.value)}
          >
            <option value="01">Janeiro</option>
            <option value="02">Fevereiro</option>
            <option value="03">Março</option>
            <option value="04">Abril</option>
            <option value="05">Maio</option>
            <option value="06">Junho</option>
            <option value="07">Julho</option>
            <option value="08">Agosto</option>
            <option value="09">Setembro</option>
            <option value="10">Outubro</option>
            <option value="11">Novembro</option>
            <option value="12">Dezembro</option>
          </select>
          <span className="form-text text-muted">Mês de início</span>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="form-group">
          <select
            class="custom-select form-control form-control-solid form-control-lg"
            value={year}
            onChange={(value) => setYear(value.target.value)}
          >
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
          <span className="form-text text-muted">Ano de início</span>
        </div>
      </div>
    </div>

    <div className={classes.tableWrapper}>
      <div className="table-responsive">
        <Table
          className="table table-head-custom table-head-bg table-borderless table-vertical-center"
          aria-labelledby="tableTitle"
          size="large"
        >
          <TableHead>
            <TableRow>
              {headRows.map(row => (
                <TableCell
                  key={row.id}
                  align="left"
                  padding={row.disablePadding ? 'none' : 'default'}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    disabled={!row.ordered}
                  >
                    <span style={{
                      color: "#B5B5C3",
                      textTransform: "uppercase"
                    }}>
                    {row.label}
                    </span>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows && rows.map((row, index) => {
              return <TableRow
                tabIndex={-1}
                key={'payment-chargeback-' + row.id}
              >
                <TableCell component="th" scope="row" padding="none">
                  {row.relationships.invoices[0].relationships.origin.relationships.client.attributes.name}
                </TableCell>
                <TableCell component="th" scope="row" padding="none">
                  {formatMoney(row.attributes.price)}
                </TableCell>
                <TableCell align="left">
                  {row.relationships?.invoices?.[0]?.relationships?.origin?.type == 'credit_purchase' && row.relationships?.invoices?.[0]?.relationships?.origin?.relationships?.origin?.attributes?.name}
                  {row.relationships?.invoices?.[0]?.relationships?.origin?.type == 'signature' && row.relationships?.invoices?.[0]?.relationships?.origin?.relationships?.plan?.attributes?.name}
                  {row.relationships?.invoices?.[0]?.relationships?.origin?.type == 'day_use' && row.relationships?.invoices?.[0]?.relationships?.origin?.relationships?.plan?.attributes?.name}
                  {row.relationships?.invoices?.[0]?.relationships?.origin?.type == 'contract' && row.relationships?.invoices?.[0]?.relationships?.origin?.relationships?.service?.attributes?.name}
                </TableCell>
                <TableCell align="left">
                  {row.attributes.type && <div className="d-flex flex-column">
                    {getPaymentTypeLabel(row.attributes.type)}

                    {row.relationships?.client_card?.attributes?.brand && <span>
                      <img
                        className="mr-2"
                        src={toAbsoluteUrl(getBrandImage(row.relationships.client_card.attributes.brand))}
                        style={{width: "1.5rem"}}
                      />

                      ••• {row.relationships.client_card.attributes.last_four_digits}
                    </span>}
                  </div>}
                </TableCell>
                <TableCell align="left">
                  {row.attributes.created_at && <span>
                    {moment(row.attributes.created_at).format('DD/MM/YYYY HH:mm')}
                  </span>}
                </TableCell>
                <TableCell align="left">
                  <span className={`label label-lg label-light-${getColorPaymentStatus(row.relationships.status.attributes.name)} label-inline`} style={{minWidth: 'max-content'}}>
                    {getLabelPaymentStatus(row.relationships.status.attributes.name)}
                  </span>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </div>
    </div>

    <div className="d-flex align-items-center py-3">
      {payments.listLoading && <div className="d-flex align-items-center">
        <div className="mr-2 text-muted">Carregando...</div>
        <div className="spinner spinner-primary mr-10"></div>
      </div>}
    </div>
  </>);
}
