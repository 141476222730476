import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import { format } from 'date-fns';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import ControlPoint from '@material-ui/icons/ControlPoint';
import CloseIcon from '@material-ui/icons/Close';

import { RenderError } from "../../../components/RenderError";
import { notifySuccess } from "../../../utils/Notify";
import { formatMoney } from "../../../utils/IntegerParse";
import DayPicker from '../../../components/DayPicker';
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CreateClient from "../../Client/components/CreateClient";
import EditPlan from "../../Plan/components/EditPlan";

import { makeStyles } from '@material-ui/core/styles';

import { fetchCreditPurchases, fetchCreateCreditPurchase } from "../_redux/creditPurchaseActions";
import { fetchClientName } from "../../Client/_redux/clientActions";
import { fetchPlans } from "../../Plan/_redux/planActions";
import { fetchServices } from "../../Service/_redux/serviceActions";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
  lineThrough: {
    textDecoration: 'line-through',
    fontSize: '10px'
  },
}));

const CreateCreditPurchase = (props) => {
  const dispatch = useDispatch();
  const { creditPurchases, clients, plans, branchSelected } = useSelector((state) => ({
    creditPurchases: state.creditPurchases,
    clients: state.clients,
    plans: state.plans,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();
  const nameRef = useRef(null);

  const [rows, setRows] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [showClient, setShowClient] = useState(false);
  const [originType, setOriginType] = useState('');
  // const [startDateAt, setStartDateAt] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [selectedOrigin, setSelectedOrigin] = useState({});
  const [isExpire, setIsExpire] = useState(false);
  const [customizeAmount, setCustomizeAmount] = useState(false);

  useEffect(() => {
    if (creditPurchases.created == true) {
      notifySuccess('Cadastrado com sucesso', 'creditPurchases-created', 2000);
    }
  }, [creditPurchases]);

  // useEffect(() => {
  //   if (plans.entities) {
  //     setRows(plans.entities);
  //   }
  // }, [plans]);

  useEffect(() => {
    if (props.show) {
      nameRef.current.focus();
    }

    if (!props.show) {
      close();
    }
  }, [props.show]);

  useEffect(() => {
    if (props.show && originType) {
      setRows([]);
      setSelectedOrigin({});
      // setStartDateAt(new Date);
      // formik.setFieldValue('billing_day', moment().format('DD'), false);
      // formik.setFieldValue('billing_day', moment().format('DD'), false);

      if (originType == 'day_use') {
        dispatch(fetchPlans('active', 'day_use', '', branchSelected.id)).then((data) => {
          setRows(data);
        });
      }

      if (originType == 'class' || originType == 'reservation') {
        dispatch(fetchServices(branchSelected.id, originType, 'single')).then((data) => {
          setRows(data);
        });
      }
    }
  }, [originType]);

  const renew = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const close = () => {
    props.onHide();
    setShowClient(false);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('quantity', '1', false);
    formik.setFieldValue('price', '', false);
    formik.setFieldValue('duration', '60', false);
    formik.setFieldValue('expired_at', '', false);
    formik.setFieldValue('payment_method', '', false);

    setSelectedClient({});
    setSelectedOrigin({});
    setOriginType('');
    setIsExpire(false);
  }

  const selectClient = (client) => {
    setSelectedClient(client);
  }


  const selectOrigin = (value) => {
    setSelectedOrigin(value);

    formik.setFieldValue('quantity', '', false);
    formik.setFieldValue('price', '', false);
  }

  const selectPackage = (value) => {
    formik.setFieldValue('quantity', value.amount, false)
    formik.setFieldValue('price', value.price, false)
    formik.setFieldValue('expired_at', moment().add(value.expired, 'day').format('YYYY-MM-DD'), false);
    setIsExpire(true);
  }


  const getPrices = (entitiesPrice) => {
    let prices = [];

    if (entitiesPrice.length > 0) {
      entitiesPrice.map(price => {
        prices.push(price.attributes.price);
      });

      let minMax = getMinMax(prices);

      if (minMax.min == minMax.max) {
        return <>
          R$ {minMax.min} <br />{entitiesPrice[0].attributes.time} min
        </>
      }

      return <>
        R$ {minMax.min} ~ R$ {minMax.max} <br />{entitiesPrice[0].attributes.time} min
      </>
    }
  }

  const getMinMax = (arr) => {
    return arr.reduce(({min, max}, v) => ({
      min: min < v ? min : v,
      max: max > v ? max : v,
    }), { min: arr[0], max: arr[0] });
  }

  const searchClientName = () => {
    if (formik.values.name && formik.values.name.length >= 2) {
      const clientNameRequestTimeout = setTimeout(() => {
        dispatch(fetchClientName(formik.values.name, branchSelected.id));
        setShowClient(true);
      }, 400);

      return () => {
        clearTimeout(clientNameRequestTimeout);
      };
    }
  }

  const planSelected = (value) => {
    if (selectedOrigin.id && value == selectedOrigin.id) {
      return true
    }

    return false;
  }

  const originTypeClass = (item) => {
    return originType == item ? "contained" : "outlined";
  }

  const formik = useFormik({
    initialValues: {
      // billing_day: '',
      // auto_renew: 'yes',
      quantity: '1',
      duration: '',
      expired_at: '',
      payment_method: '',
    },
    // validationSchema: createClientcreditPurchaseschema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        let params = {
          origin_id: selectedOrigin.id,
          client_id: selectedClient.id,
          quantity: values.quantity,
          price: values.price,
          payment_method: values.paymentMethod,
          origin_type: originType,
        };

        if (originType == 'class' || originType == 'reservation' ) {
          params.duration = values.duration;
        }

        if (isExpire) {
          params.expired_at = values.expired_at;
        }

        dispatch(fetchCreateCreditPurchase(params));
      }, 200);
    },
  });

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);


  const headRows = [
    { id: 'check', numeric: false, disablePadding: false, label: '' },
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Preço' },
    // { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  const paymentMethods = [
    { label: 'Crédito', value: 'credit' },
    { label: 'Débito', value: 'debit' },
    { label: 'Pix', value: 'pix' },
    { label: 'Dinheiro', value: 'cash' },
  ];

  const showCard = (entities, title = '') => {
    return <>

        <div className={classes.tableWrapper}>
          <Table
            key="tableWrapper"
                className="table table-head-custom table-head-bg table-borderless table-vertical-center"
                aria-labelledby="tableTitle"
                size="large"
          >
            <TableHead>
              <TableRow>
                {headRows.map(row => (
                  <TableCell
                    key={row.id}
                    align="left"
                    padding={row.disablePadding ? 'none' : 'default'}
                  >
                    <span style={{
                      color: "#B5B5C3",
                      textTransform: "uppercase"
                    }}>
                    {row.label}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {entities.map((row, index) => {
                {/*const isItemSelected = isSelected(row.name);*/}
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => selectOrigin(row)}
                    className="cursor-pointer"
                  >
                    <TableCell component="th" scope="row" padding="none">
                      <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
                        <input
                          type="checkbox"
                          key={row.id}
                          name={row.id}
                          checked={planSelected(row.id)}
                          onChange={() => selectOrigin(row)}
                        />
                        <span></span>
                      </label>
                    </TableCell>

                    <TableCell component="th" scope="row" padding="none" align="left">
                      {row.attributes.name}
                    </TableCell>

                    <TableCell align="left" className="flex">
                      {originType == 'day_use' && <>
                        {selectedClient.attributes.is_member && row.attributes.member_price && <span className="mr-2">R$ {row.attributes.member_price}</span>}
                        <span className={selectedClient.attributes.is_member && row.attributes.member_price && classes.lineThrough}>R$ {row.attributes.price}</span>

                        <br />
                        {row.attributes.type && row.attributes.type == 'creditPurchase' && <span>{row.attributes.duration} {row.attributes.duration > 1 ? 'meses' : ' mês'}</span>}
                        {row.attributes.type && row.attributes.type == 'day_use' && <span>Por dia</span>}
                      </>}

                      {(originType == 'class' || originType == 'reservation' ) && <>
                        {(row.relationships && row.relationships.prices && row.relationships.prices.length > 0) && getPrices(row.relationships.prices)}
                        {(row.relationships && row.relationships.prices && row.relationships.prices.length <= 0) && `R$ 0,00`}
                      </>}

                    </TableCell>

                    {/*<TableCell align="right">
                      <a
                        title="Ver plano"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => openEditEntity(row)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            title="Ver plano"
                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                          />
                        </span>
                      </a>
                    </TableCell>*/}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
    </>
  }

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !createEntityShow && !editEntityShow}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cadastrar venda de pacote
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          {!selectedClient.id && <Container maxWidth="sm">
            <Grid container spacing={2}>
              <Grid item xs={12} className="text-right">
                <a className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                  <ControlPoint fontSize="inherit" /> Cadastrar cliente
                </a>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Pesquisar por nome"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {clients.actionsLoading && <CircularProgress size={30} />}
                      </InputAdornment>
                    ),
                  }}
                  {...formik.getFieldProps("name")}
                  inputRef={nameRef}
                />

                {formik.touched.name && formik.errors.clientId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.clientId}</div>
                  </div>
                ) : null}
              </Grid>

              {showClient && <Grid item xs={12}>
                {clients.clientName.data && clients.clientName.data.map((client) =>
                  <div className={classes.detailedExpansionPanel}>
                    <DetailedExpansionPanel client={client} onSelect={selectClient} />
                  </div>
                )}
                {clients.clientName.data && !clients.clientName.data.length && <div class="d-flex text-center pt-5">
                  <span>Nenhum cliente encontrado</span>
                </div>}
              </Grid>}
            </Grid>

            {(typeof clients.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {clients.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>}

          {selectedClient.id && <>
            <Container maxWidth="sm">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">
                    Dados do assinante
                  </span>
                </Grid>

                <Grid item xs={12}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-block">Nome: {selectedClient.attributes.name}</div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-block">E-mail: {selectedClient.attributes.email}</div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-block">Telefone: {selectedClient.attributes.phone}</div>
                  </div>
                </Grid>
              </Grid>
            </Container>

            <div className="separator separator-dashed my-3"></div>

            <Container maxWidth="sm">
              <Grid item xs={12}>
                <span className="text-dark">
                  Selecione o tipo de crédito que o jogador irá receber
                </span>
              </Grid>
              <Grid container className={classes.label} spacing={2}>
                <Grid item xs>
                  <Button fullWidth variant={originTypeClass('reservation')} onClick={() => setOriginType('reservation')} color="primary" size="large">
                    Locação
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button fullWidth variant={originTypeClass('class')} onClick={() => setOriginType('class')} color="primary" size="large">
                    Aula
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button fullWidth variant={originTypeClass('day_use')} onClick={() => setOriginType('day_use')} color="primary" size="large">
                    Day use
                  </Button>
                </Grid>
              </Grid>

              {originType && <>
                <Grid container className={classes.label} spacing={2}>
                  <Grid item xs={12}>
                    {showCard(rows)}

                    {creditPurchases.error && <RenderError error={creditPurchases.error.plan_id} />}
                  </Grid>
                </Grid>

                {selectedOrigin && selectedOrigin.id && <>
                  {selectedOrigin.relationships && selectedOrigin.relationships.package && selectedOrigin.relationships.package.id && <Grid container className={classes.label}>
                    <div className="row">
                      {selectedOrigin.relationships.package.attributes.prices.map(servicePackage => <div className="col-lg-4">
                        <label className="option option-plain">
                          <span className="option-control">
                            <span className="radio">
                              <input type="radio" checked={formik.values.quantity == servicePackage.amount} onClick={() => selectPackage(servicePackage)} />
                              <span></span>
                            </span>
                          </span>
                          <span className="option-label">
                            <span className="option-head">
                              <span className="option-title">{servicePackage.amount} Créditos</span>

                            </span>
                            <span className="option-body">{formatMoney(servicePackage.price)}</span>
                          </span>
                        </label>
                      </div>)}
                      {/*<div className="col-lg-3">
                        <label className="option option-plain">
                          <span className="option-control">
                            <span className="radio">
                              <input type="radio" checked={false} onClick={() => console.log('single')} />
                              <span></span>
                            </span>
                          </span>
                          <span className="option-label">
                            <span className="option-head">
                              <span className="option-title">Customizar</span>

                            </span>
                            <span className="option-body">Customizar os valores</span>
                          </span>
                        </label>
                      </div>*/}
                    </div>
                  </Grid>}

                  <Grid container className={classes.label} spacing={2}>
                    {((selectedOrigin.relationships && !selectedOrigin.relationships.package) || customizeAmount) && <>
                      <Grid item xs={6}>
                        <TextField
                          select
                          label={'Quantidade de créditos'}
                          variant="outlined"
                          SelectProps={{
                            MenuProps: {
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              }
                            }
                          }}
                          name="quantity"
                          fullWidth
                          className={classes.textField}
                          {...formik.getFieldProps("quantity")}
                        >
                          <MenuItem key={`quantity-1`} value={1}>1</MenuItem>
                          <MenuItem key={`quantity-2`} value={2}>2</MenuItem>
                          <MenuItem key={`quantity-3`} value={3}>3</MenuItem>
                          <MenuItem key={`quantity-4`} value={4}>4</MenuItem>
                          <MenuItem key={`quantity-5`} value={5}>5</MenuItem>
                          <MenuItem key={`quantity-6`} value={6}>6</MenuItem>
                          <MenuItem key={`quantity-7`} value={7}>7</MenuItem>
                          <MenuItem key={`quantity-8`} value={8}>8</MenuItem>
                          <MenuItem key={`quantity-9`} value={9}>9</MenuItem>
                          <MenuItem key={`quantity-10`} value={10}>10</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="price"
                          label={`Valor a ser pago`}
                          fullWidth
                          className={classes.textField}
                          placeholder="ex: 260"
                          variant="outlined"
                          type="number"
                          min="1"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                          {...formik.getFieldProps("price")}
                        />

                        {creditPurchases.error && <RenderError error={creditPurchases.error.price} />}
                      </Grid>
                    </>}

                    {(originType == 'class' || originType == 'reservation') && <Grid item xs={6}>
                      <TextField
                        name="duration"
                        label="Duração"
                        fullWidth
                        className={`${classes.textField} is-invalid`}
                        variant="outlined"
                        autoComplete="new-password"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
                        }}
                        {...formik.getFieldProps("duration")}
                      />

                      {creditPurchases.error && <RenderError error={creditPurchases.error.duration} />}
                    </Grid>}
                  </Grid>

                  <Grid container className={classes.label} spacing={2}>
                    <div className="row">
                      <div className="col-xl-12 pt-3">
                        <label>Os créditos tem data de expiração?</label>
                        <span className="switch switch-brand switch-icon">
                          <label>
                            <input
                              key="credit-purchase-is-expired"
                              name="credit-purchase-is-expired"
                              type="checkbox"
                              checked={isExpire ? "checked" : null}
                              onClick={() => setIsExpire(!isExpire)} name="select"
                            />
                          <span></span>
                        </label>
                       </span>
                      </div>
                    </div>


                    {isExpire && <>
                      <div className="row">
                        <div className="col-xl-12 pt-5">
                          <label>Escolha a data que expira os créditos</label>
                          <TextField
                            name="expired_at"
                            label={'Data de expiração'}
                            type="date"
                            fullWidth
                            className={classes.textField}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            {...formik.getFieldProps("expired_at")}
                          />
                        </div>
                      </div>
                    </>}
                  </Grid>
                </>}
              </>}
            </Container>
          </>}

          {(typeof creditPurchases.error) == "string" && <Grid item xs={12}>
            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                  {creditPurchases.error}
                </span>
              </div>
            </div>
          </Grid>}
        </Modal.Body>

        {selectedClient.id && <Modal.Footer>
          <Grid container spacing={3}>
            {selectedOrigin && selectedOrigin.id && formik.values.price && <>
              <Grid item xs={7} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <span className=" font-size-sm text-dark-50 font-weight-bolder pt-2">
                  Forma de pagamento
                </span>
              </Grid>
              <Grid item xs={5} className="text-right">
                <TextField
                  select
                  label="Forma de pagamento"
                  variant="outlined"
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      }
                    }
                  }}
                  name="paymentMethod"
                  fullWidth
                  {...formik.getFieldProps("paymentMethod")}
                >
                  {paymentMethods.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                </TextField>
                {creditPurchases.error && <RenderError error={creditPurchases.error.payment_method} />}
              </Grid>
              <Grid item xs={6}>
                <span className=" font-size-h4 text-dark-50 font-weight-bolder mb-2">
                  Subtotal
                </span>
              </Grid>
              <Grid item xs={6} className="text-right">
                <span className=" font-size-h4 text-dark-50 font-weight-bolder mb-2 text-right">
                  {formatMoney(formik.values.price)}
                </span>
              </Grid>
            </>}

            <Grid item xs style={{textAlign: 'right'}}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                size="large"
                disabled={(!originType || !selectedOrigin.id)}
              >
                Confirmar {(creditPurchases.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>

          {/*<Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                size="large"
                disabled={(!originType || !selectedOrigin.id)}
              >
                Confirmar {(creditPurchases.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>*/}
        </Modal.Footer>}
      </form>
    </Modal>

    <CreateClient show={createEntityShow} onHide={() => setCreateEntityShow(false)} />
    <EditPlan readOnly={true} show={editEntityShow} onHide={() => setEditEntityShow(false)} event={editEntity} />
  </>);
};

export default CreateCreditPurchase;