import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

import ListGroup from 'react-bootstrap/ListGroup';
import Overlay from 'react-bootstrap/Overlay';

import ControlPoint from '@material-ui/icons/ControlPoint';
import Settings from '@material-ui/icons/Settings';
import DeleteForever from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import { fetchPriceService } from "../../Price/_redux/priceActions";
import { fetchClientPhone, fetchClientName } from "../../Client/_redux/clientActions";
import {
  fetchMoveSchedule
} from "../_redux/scheduleActions";

import { createScheduleSchema } from "../_schemas/createScheduleSchema";

import Snackbar from "../../../components/Snackbar";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import { RenderError } from "../../../components/RenderError";
import DayPicker from '../../../components/DayPicker';

import CreateClient from "../../Client/components/CreateClient";
import CreateClientSchedule from "./CreateClientSchedule";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
  detailedExpansionPanel: {
    marginBottom: 5,
    boxShadow: 'none !important'
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

const MoveSchedule = (props) => {
  const dispatch = useDispatch();
  const { fields, prices, clients, schedules } = useSelector((state) => ({
    fields: state.fields,
    prices: state.prices,
    clients: state.clients,
    schedules: state.schedules,
  }), shallowEqual);

  const classes = useStyles();

  const [modality, setModality] = useState('');
  const [step, setStep] = useState(1);
  const [recurrenceInfinity, setRecurrenceInfinity] = useState(false);
  const [services, setServices] = useState([]);
  const [type, setType] = useState('');
  const [durations, setDurations] = useState([]);
  const target = useRef(null);

  useEffect(() => {
    if (schedules.moved == true) {
      close();
    }
  }, [schedules]);

  useEffect(() => {
    if (props.show) {
      //
    } else {
      close();
    }
  }, [props.show]);

  useEffect(() => {
    setType('single');

    if (props.show && props.schedule && props.schedule.id && props.schedule.attributes) {
      console.log(props.schedule);
      setType(props.schedule.attributes.recurrence ? 'recurrence' : 'single');
      formik.setFieldValue('startDateAt', moment(props.schedule.attributes.start_at).format('YYYY-MM-DD'), false);
      formik.setFieldValue('endDateAt', moment(props.schedule.attributes.finish_at).format('YYYY-MM-DD'), false);
      formik.setFieldValue('recurrenceStartAt', moment(props.schedule.attributes.start_at).format('YYYY-MM-DD'), false);
      formik.setFieldValue('recurrenceFinishAt', moment(props.schedule.attributes.recurrence_finish_at).format('YYYY-MM-DD'), false);

      if(props.schedule.attributes.recurrence_finish_at) {
        setRecurrenceInfinity(true);
      }

      let startAt = moment(props.schedule.attributes.start_at);

      if (!props.schedule.attributes.start_at) {
        const remainder = 30 - (startAt.minute() % 30);

        startAt = moment().add(remainder, 'minutes');
      }

      let endAt = moment(props.schedule.attributes.finish_at);

      if (!props.schedule.attributes.finish_at) {
        endAt = moment(startAt).add(1, 'hours');
      }

      let duration = endAt.diff(startAt, 'minutes');

      formik.setFieldValue('startAt', startAt.format('HH:mm'), false);
      formik.setFieldValue('endAt', endAt.format('HH:mm'), false);
      formik.setFieldValue('duration', duration, false);

      calculateDurations(duration);

      let fieldFilter = fields.entities.find(element => {
        return element.id === props.schedule.relationships.field.id;
      });

      if (fieldFilter) {
        formik.setFieldValue('fieldId', fieldFilter.id, false);

        if (fieldFilter.relationships.services.length) {
          formik.setFieldValue('serviceId', fieldFilter.relationships.services[0].id, false);
        }
      }
    }
  }, [props.schedule, props.show]);

  const calculateDurations = (duration) => {
    let newDurations = [];
    let count = 15;

    while(count <= duration || count <= 480) {
      newDurations.push({
        label: convertMinsToHrsMins(count),
        value: count
      });

      count += 15;
    }

    setDurations(newDurations);
  }

  const close = () => {
    props.onHide();

    setModality('');
    setStep(1);
    setRecurrenceInfinity(false);

    formik.setFieldValue('fieldId', '', false);
    formik.setFieldValue('serviceId', '', false);
    formik.setFieldValue('clientId', '', false);
    formik.setFieldValue('mobile', '', false);
    formik.setFieldValue('name', '', false);
    formik.setFieldValue('email', '', false);
  }

  const convertMinsToHrsMins = (mins) => {
    if (mins < 60) {
      return `${mins} min`;
    }

    let h = Math.floor(mins / 60);
    let m = mins % 60;

    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;

    return `${h}:${m} hrs`;
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const formik = useFormik({
    initialValues: {
      recurrenceFinishAt: '',
      startDateAt: '',
      endDateAt: '',
      startAt: '',
      endAt: '',
      duration: '',
      fieldId: '',
      price: '',
      serviceId: '',
    },
    validationSchema: createScheduleSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        // if (props.event.attributes.recurrence) {
        //   values.recurrence_start_at = values.startDateAt;

        //   values.recurrence_finish_at = 'infinite';

        //   if (values.recurrenceFinishAt != 'infinite') {
        //     values.recurrence_finish_at = moment(values.startDateAt).add(values.recurrenceFinishAt, 'months').format('YYYY-MM-DD')
        //   }
        // }

        // if (type == 'recurrence') {
        // //   values.recurrence_period = 'weekly';
        //   values.recurrence_start_at = values.startDateAt;

        //   if (recurrenceInfinity) {
        //     values.recurrence_finish_at = values.recurrenceFinishAt;
        //   } else {
        //     values.recurrence_finish_at = '';
        //   }
        // } else {
        // //   values.recurrence_period = '';
        // //   values.spots = '';
        //   values.recurrence_start_at = '';
        //   values.recurrence_finish_at = '';
        // //   values.recurrence_days = '';
        // }

        dispatch(fetchMoveSchedule(props.schedule.id, values));

        setSubmitting(false);

        // if (modality == 'class') {
        //   values.spots = 1;
        // }

        // values.schedule_spots = mapClients();
        // values.participants = mapPlayers();
        // values.type = type;
        // values.modality = modality;

        // console.log(values);
        // dispatch(fetchCreateSchedule(values.fieldId, values));

        // setSubmitting(false);
      }, 500);
    },
  });

  useEffect(() => {
    if(prices.actionsLoading == false) {
      if (type && props.schedule && props.schedule.id && props.show) {
        const priceTimeout = setTimeout(() => {
          dispatch(fetchPriceService(
            formik.values.fieldId ?? props.schedule.relationships.field.id,
            `${formik.values.startDateAt} ${formik.values.startAt}`,
            moment(`${formik.values.startDateAt} ${formik.values.startAt}`).add(formik.values.duration, 'minutes').format('YYYY-MM-DD HH:mm'),
            props.schedule.attributes.modality,
            type,
          ))
        }, 300);

        return () => {
          clearTimeout(priceTimeout);
        };
      }
    }
  }, [
    modality,
    type,
    formik.values.startDateAt,
    formik.values.startAt,
    formik.values.duration,
    formik.values.fieldId,
    props.show
  ]);

  useEffect(() => {
    let endAt = moment(`${formik.values.startDateAt} ${formik.values.startAt}`).add(formik.values.duration, 'minutes')

    formik.setFieldValue('endDateAt', endAt.format('YYYY-MM-DD'), false);
    formik.setFieldValue('endAt', endAt.format('HH:mm'), false);
  }, [
    formik.values.startDateAt,
    formik.values.startAt,
    formik.values.duration,
  ]);

  const getType = (type) => {
    return type == 'single' ? 'Avulso' : 'recurrence' ? 'Mensalista' : 'Indisponivel';
  }

  const getStatusColor = (type) => {
    if (type != 'recurrence' && modality == 'schedule') {
      return '#17c191';
    }
    if (type == 'recurrence' && modality == 'schedule') {
      return '#a6bf54';
    }

    if (type != 'recurrence' && modality == 'class') {
      return '#1772c1';
    }
    if (type == 'recurrence' && modality == 'class') {
      return '#54a6bf';
    }

    return 'gray';
  }

  return (<>
  <Modal
    {...props}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    // centered
    backdrop="static"
    keyboard={true}
    show={props.show}
    contenStyle={{maxWidth: '1000px', backgroundColor: 'transparent !important'}}
  >
    <form className="form" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">

      <div className="card card-custom card-transparent">

        <CloseIcon onClick={close} className="mt-3 mr-3 cursor-pointer" style={{alignSelf: 'flex-end'}} />

        <div className="card-body p-0">

          <div className="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="step-first" data-wizard-clickable="true">
            <div className="wizard-nav">
              <div className="wizard-steps px-8 pb-8 px-lg-15 pb-lg-3">
                <div className="wizard-step" data-wizard-type="step" data-wizard-state={"current"}>
                  <div className="wizard-label">
                    <h3 className="wizard-title">
                    <span>1.</span>Mover agendamento</h3>
                    <div className="wizard-bar"></div>
                  </div>
                </div>
                {/*<div className="wizard-step" data-wizard-type="step" data-wizard-state={step == 2 && "current"}>
                  <div className="wizard-label">
                    <h3 className="wizard-title">
                    <span>2.</span>Informações</h3>
                    <div className="wizard-bar"></div>
                  </div>
                </div>*/}
              </div>
            </div>
            <div className="card card-custom card-shadowless rounded-top-0">
              <div className="card-body p-0">
                <div className="row justify-content-center px-4 py-lg-7 px-lg-5">
                  <div className="col-lg-10 col-xl-10 col-xxl-10">

                    {/*{(typeof schedules.error) == "string" && <Grid item xs={12}>
                      <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                          <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                            {schedules.error}
                          </span>
                        </div>
                      </div>
                    </Grid>}*/}

                    <div className="row">
                      <div className="col-xs-12 col-lg-6 col-xl-6 col-xxl-6 py-4">
                        <div className="pb-0">

                          {/*<div className="pt-5 mb-10 font-weight-bold text-dark">Campo</div>
                          <div className="form-group">
                            <label>Escolha o campo que deseja agendar</label>
                          </div>*/}
                          <div className="mb-5 font-weight-bold text-dark">Campo</div>
                          <div className="mb-1 font-weight-bold font-size-sm">Escolha o campo que deseja agendar</div>

                          <div className="row">
                            <div className="col-xl-12">
                              <TextField
                                select
                                label="Campo"
                                variant="outlined"
                                SelectProps={{
                                  MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    }
                                  }
                                }}
                                name="fieldId"
                                fullWidth
                                className={classes.textField}
                                {...formik.getFieldProps("fieldId")}
                              >
                                {fields.entities.map((item) => <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>)}
                              </TextField>
                              {formik.touched.fieldId && formik.errors.fieldId ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{formik.errors.fieldId}</div>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="pt-10 mb-5 font-weight-bold text-dark">Data e horário</div>
                          <div className="mb-1 font-weight-bold font-size-sm">
                            {modality == 'class' && type == 'single' && "Escolha o dia vai acontecer a aula"}
                            {modality == 'class' && type == 'recurrence' && "Escolha o dia que inicia as aulas"}

                            {modality == 'reservation' && type == 'single' && "Escolha o dia vai acontecer o jogo"}
                            {modality == 'reservation' && type == 'recurrence' && "Escolha o dia que inicia os jogos"}
                          </div>
                          {(type == 'recurrence') && <div className="mb-3 font-size-sm">Por padrão a recorrencia não tem data de termino, você pode definir uma data de termino se preferir.</div>}

                          <div className="row">
                            <div className="col-xl-12">
                              <TextField
                                name="startDateAt"
                                label={'Data Inicio'}
                                type="date"
                                fullWidth
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...formik.getFieldProps("startDateAt")}
                              />
                            </div>
                            {/*{(type == 'recurrence') && <div className="col-xl-12 pt-3">
                              <label>Definir uma data de termino?</label>
                              <span className="switch switch-brand switch-icon">
                                <label>
                                  <input type="checkbox" check={recurrenceInfinity} onClick={() => setRecurrenceInfinity(!recurrenceInfinity)} name="select"/>
                                <span></span>
                              </label>
                             </span>
                            </div>}*/}
                          </div>

                          {/*{(type == 'recurrence') && recurrenceInfinity && <>
                            <div className="mt-3 font-weight-bold font-size-sm">Escolha a data que termina a recorrencia</div>

                            <div className="row pt-5">
                              <div className="col-xl-12">
                                <TextField
                                  name="recurrenceFinishAt"
                                  label={'Data termino'}
                                  type="date"
                                  fullWidth
                                  className={classes.textField}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  {...formik.getFieldProps("recurrenceFinishAt")}
                                />
                              </div>
                            </div>
                          </>}
*/}
                          <div className="row">
                            <div className="col-xl-6 pt-5">
                              <TextField
                                // value={startAt}
                                name="startAt"
                                label="Horario Inicio"
                                type="time"
                                fullWidth
                                step={15}
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: "900", // 15 min
                                }}
                                {...formik.getFieldProps("startAt")}
                              />
                            </div>
                            <div className="col-xl-6 pt-5">
                              <TextField
                                select
                                label="Duração"
                                variant="outlined"
                                SelectProps={{
                                  MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    }
                                  }
                                }}
                                name="duration"
                                fullWidth
                                className={classes.textField}
                                {...formik.getFieldProps("duration")}
                              >
                                {durations.length > 0 && durations.map(duration => <MenuItem key={`duration-${duration.value}`} value={duration.value}>
                                  {duration.label}
                                </MenuItem>)}
                              </TextField>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-lg-6 col-xl-6 col-xxl-6 py-4">
                        <div className="mb-5 font-weight-bold text-dark">Preços</div>
                        <div className="mt-3 font-weight-bold font-size-sm mb-5">Tabela de preços por serviço para o data selecionada</div>

                        {prices.priceService && prices.priceService.filter(item => item.service.attributes.type == 'single').map((item, index) => <>
                          <div className="separator separator-dashed my-3"></div>
                          <div className="d-flex align-items-center flex-wrap mb-3">
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                              <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">{item.service.attributes.name}</a>
                              <span className="font-weight-bold" style={{color: getStatusColor(item.service.attributes.type)}}>
                                {getType(item.service.attributes.type)}
                              </span>
                              <span className="text-muted font-weight-bold">
                                Entre {convertMinsToHrsMins(item.service.attributes.minTime)} ~ {convertMinsToHrsMins(item.service.attributes.maxTime)}, a cada {convertMinsToHrsMins(item.service.attributes.granularity)}
                              </span>
                            </div>
                            <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                              {item.price != 0.00 && `R$ ${parseFloat(item.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
                              {item.price == 0.00 && `Grátis`}
                            </span>
                          </div>
                        </>)}

                        {prices.priceService && prices.priceService.filter(item => item.service.attributes.type == 'recurrence').map((item, index) => <>
                          {/*{index == 0 && <div className="mt-3 font-weight-bold font-size-sm mb-5">Serviços recorrente:</div>}*/}
                          <div className="separator separator-dashed my-3"></div>
                          <div className="d-flex align-items-center flex-wrap mb-3">
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                              <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">{item.service.attributes.name}</a>
                              <span className="text-muted font-weight-bold">
                                <span style={{color: getStatusColor(item.service.attributes.type)}}>{getType(item.service.attributes.type)}</span> - {item.service.attributes.recurrence_times}x por semana
                              </span>
                              <span className="text-muted font-weight-bold">
                                Entre {convertMinsToHrsMins(item.service.attributes.minTime)} ~ {convertMinsToHrsMins(item.service.attributes.maxTime)}, a cada {convertMinsToHrsMins(item.service.attributes.granularity)}
                              </span>
                            </div>
                            <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                              {item.price != 0.00 && `R$ ${parseFloat(item.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
                              {item.price == 0.00 && `Grátis`}
                            </span>
                          </div>
                        </>)}
                      </div>
                    </div>

                    {(typeof schedules.error) == "string" && <Grid item xs={12}>
                      <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                          <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                            {schedules.error}
                          </span>
                        </div>
                      </div>
                    </Grid>}

                    <div className="d-flex justify-content-between border-top mt-5 pt-10">
                      <div className="mr-2">
                        <button type="button" className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4" onClick={() => close()}>Fechar</button>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                          data-wizard-type="action-next"
                          disabled={(formik.isSubmitting || schedules.actionsLoading)}
                          onClick={formik.current?.submitForm}
                        >
                          Mover {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-white"></span>}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </Modal>
  </>);
};

export default MoveSchedule;
