import * as requestFromServer from "./userCrud";
import { actions as authPersistor } from "app/modules/Auth/_redux/authRedux";
import { userSlice, callTypes } from "./userSlice";
import { notifyError } from "app/utils/Notify";

const { actions } = userSlice;

export const fetchUsers = (name = '', branchId = '', type = 'user') => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  let params = {
    type: type,
  };

  if (name) {
    params.name = name;
  }

  if (branchId) {
    params.branch_id = branchId;
  }

  return requestFromServer
    .getUsers(params)
    .then((response) => {
      const entities = response.data;
      const totalCount = entities.length;

      console.log({ entities });

      dispatch(actions.usersFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      dispatch(actions.usersFetched({ totalCount: 0, entities: null }));
    });
};

export const fetchinstructors = (name = '', branchId = '') => (dispatch) => {
  dispatch(fetchUsers(name, branchId, 'instructor'));
};

export const fetchCreateUser = (values) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let params = {
    name: values.name,
    type: values.type,
  }

  if (values.phone) {
    params.phone = values.phone;
  }

  if (values.email) {
    params.email = values.email;
  }

  if (values.branchesId) {
    params.branch_id = values.branchesId;
  }

  if (values.permissions) {
    params.permissions = values.permissions;
  }

  if (values.status) {
    params.status_id = values.status;
  }

  if (values.photo) {
    params.photo = values.photo;
  }

  return requestFromServer
    .createUser(params)
    .then((response) => {
      dispatch(actions.userCreated());
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'user-failed-update', 2000);
      }
    });
};

export const fetchUpdateUser = (userId, params) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  const { branchesId, status, userId, password, ...rest } = params;

  return requestFromServer
    .updateUser(userId, {
      ...rest,
      branch_id: branchesId,
      status_id: status,
    })
    .then((response) => {
      console.log({ response });
      dispatch(actions.userUpdated());
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'user-failed-update', 2000);
      }
    });
};

export const fetchUpdateUserPassword = (userId, password) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateUserPassword(userId, {
      password: password
    })
    .then((response) => {
      console.log({ response });
      dispatch(actions.userUpdated());
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'user-failed-update', 2000);
      }
    });
};


export const fetchUpdateUserPhoto = (userId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateUserPhoto(userId, params)
    .then(response => {
      dispatch(actions.userPhotoUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'user-failed-update', 2000);
      }
    });
};
