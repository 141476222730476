import * as requestFromServer from "./dayUseCrud";
import { dayUseSlice, callTypes } from "./dayUseSlice";

const { actions } = dayUseSlice;

export const fetchDayUses = (perPage = 10, page = 1, status = 'active', day = '', branchId = '') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDayUses(perPage, page, status, day, branchId)
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.dayUsesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.dayUseMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.dayUsesFetched({ totalCount: 0, entities: null }));
    });
};

export const fetchDayUse = (dayUseId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDayUseById(dayUseId)
    .then(response => {
      const dayUse = response.data;


      dispatch(actions.dayUseFetched({ dayUse: dayUse }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.dayUseFetched({ dayUse: {} }));
    });
};

export const fetchGetClientDayUse = (clientId, branchId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .getClientDayUse(clientId, branchId)
      .then(response => {
        dispatch(actions.loadingFinish());
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);
      });
  });
};

export const clearDayUse = () => dispatch => {
  dispatch(actions.dayUseFetched({ dayUse: {} }));
};

export const fetchCreateDayUse = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let data = {
    plan_id: params.plan_id,
    client_id: params.client_id,
    day: params.day,
    status_id: params.status_id,
    payment_type: params.paymentType,
  };

  if (params.paymentMethod) {
    data.payment_method = params.paymentMethod
  }

  if (params.branch_id) {
    data.branch_id = params.branch_id
  }

  if (params.credit_id) {
    data.credit_id = params.credit_id
  }

  return new Promise((resolve, reject) => {
    requestFromServer
      .createDayUse(data).then(response => {
        dispatch(actions.dayUseCreated());
        dispatch(actions.dayUseFetched({ dayUse: response.data }));
        resolve(response.data);
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const fetchRenewDayUse = (dayUseId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .renewDayUse(dayUseId)
    .then(response => {
      dispatch(actions.dayUseRenewed());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateDayUse = (dayUseId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateDayUse(dayUseId, {
      auto_renew: params.auto_renew,
    })
    .then(response => {
      dispatch(actions.dayUseUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDeleteDayUse = (dayUseId, canceledAt) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deleteDayUse(dayUseId, { canceled_at: canceledAt })
    .then(response => {
      dispatch(actions.dayUseUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};