import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';

// import { createPriceSchema } from "../_schemas/createPriceSchema";

import { notifySuccess } from "../../../utils/Notify";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { RenderError } from "../../../components/RenderError";
import { convertWeekdayBitMask } from "../../../components/DateUtils";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const CreateCreditRestriction = (props) => {
  const dispatch = useDispatch();
  const { prices, services } = useSelector((state) => ({
    prices: state.prices,
    services: state.services,
  }), shallowEqual);

  const classes = useStyles();

  const [weekdays, setWeekdays] = useState([2,4,8,16,32,64,1]);
  const [dates, setDates] = useState([{
      start_minutes: '00:00',
      end_minutes: '23:59'
  }]);

  useEffect(() => {
    if (!props.show) {
      close();
    }
  }, [props.show]);

  const close = () => {
    props.onHide();

    setWeekdays([2,4,8,16,32,64,1]);
    setDates([{
      start_minutes: '00:00',
      end_minutes: '23:59'
    }]);
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const ValueLabelComponent = (props) => {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={`${value}C*`}>
        {children}
      </Tooltip>
    );
  }

  // const renderError = (error) => {
  //   return (error ? (
  //     <Grid item xs={12}>
  //       <div className="fv-plugins-message-container">
  //         {(typeof error) == "string" &&
  //           <div className="fv-help-block">{error}</div>
  //         }

  //         {Array.isArray(error) && error.map((item) =>
  //           <div className="fv-help-block">{item}</div>
  //         )}
  //       </div>
  //     </Grid>
  //   ) : null);
  // }

  const addDates = () => {
    setDates([...dates, {
      start_minutes: '00:00',
      end_minutes: '23:59'
    }])
  }

  const removeDate = (index) => {
    if (dates.length === 1) return
    const filteredDates = dates.filter((input, i) => i !== index);
    setDates(filteredDates);
  }

  const formik = useFormik({
    initialValues: {
      //
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      if(weekdays.length == 0) {
        setSubmitting(false);

        return;
      }

      if(dates.length > 0) {
        let dateError = false;

        dates.map(date => {
          if (!date.start_minutes || !date.end_minutes) {
            dateError = true
          }
        })

        if (dateError) {
          setSubmitting(false);

          return;
        }
      }

      let data = {
        weekday: weekdays.reduce((x, y) => x + y, 0),
        selected_weekdays: convertWeekdayBitMask(weekdays),
        range: dates,
      };

      props.onSubmit(data);

      setSubmitting(false);
    },
  });

  const selectedWeekDayColor = (weekday) => {
    return weekdays.includes(weekday) ? "primary" : "default";
  }

  const selectWeekDay = (weekday) => {
    if(!weekdays.includes(weekday)) {
      setWeekdays([...weekdays, weekday]);
    } else{
        var array = [...weekdays];
        var index = array.indexOf(weekday);

        if (index !== -1) {
          array.splice(index, 1);

          setWeekdays(array);
        }
    }
  }

  const changeDate = (key, index, value) => {
    const newDate = { ...dates[index], [key]: value };
    const newDates = [...dates];
    newDates[index] = newDate;
    setDates(newDates);
  }

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cadastrar preço
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="sm">
            {/*<Grid container className={classes.label}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Dados de exibição
                </span>
              </Grid>
            </Grid>*/}

            <Grid container className={classes.label} spacing={2}>
              {/*<Grid item xs={12}>
                <TextField
                  select
                  label="Serviço"
                  variant="outlined"
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      }
                    }
                  }}
                  name="serviceId"
                  fullWidth
                  className={classes.textField}
                  {...formik.getFieldProps("serviceId")}
                >
                  {services.entities.map((item) => <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>)}
                </TextField>

                {formik.touched.serviceId && formik.errors.serviceId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.serviceId}</div>
                  </div>
                ) : null}

                {prices.error && renderError(prices.error.service_id)}
              </Grid>*/}

              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Defina o período <Icon className={classes.icon}>info_outlined</Icon>
                </span>
              </Grid>

              {dates.map((date, index) => <>
                <Grid item xs={5}>
                  <TextField
                    name="start_minutes"
                    label="Horario Inicio"
                    type="time"
                    fullWidth
                    step={15}
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: "900", // 15 min
                    }}
                    value={date.start_minutes}
                    onChange={(e) => {
                      changeDate("start_minutes", index, e.target.value);
                    }}
                    // {...formik.getFieldProps("start_minutes")}
                  />

                  {prices.error && <RenderError error={prices.error.start_minutes} />}
                </Grid>

                <Grid item xs={5}>
                  <TextField
                    name="end_minutes"
                    label="Horario Fim"
                    type="time"
                    fullWidth
                    step={15}
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: "900", // 15 min
                    }}
                    value={date.end_minutes}
                    onChange={(e) => {
                      changeDate("end_minutes", index, e.target.value);
                    }}
                    // {...formik.getFieldProps("end_minutes")}
                  />

                  {prices.error && <RenderError error={prices.error.end_minutes} />}
                </Grid>
                <Grid item xs={2}>
                  <a
                    title="Edit customer"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={() => removeDate(index)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Home/Trash.svg"
                        )}
                      />
                    </span>
                  </a>
                </Grid>
              </>)}
            </Grid>

            <a
              href="#"
              className="btn btn-light-primary font-weight-bold"
              onClick={() => addDates()}
            >
              <i className="ki ki-plus "></i> Adicionar horário
            </a>

            <Grid container className={classes.label}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Selecione os dias da semana
                </span>
              </Grid>

              <Grid container className={classes.label} spacing={1}>
                <Grid item xs><Chip onClick={() => selectWeekDay(2)} clickable color={selectedWeekDayColor(2)} variant="outlined" label="Seg" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(4)} clickable color={selectedWeekDayColor(4)} variant="outlined" label="Ter" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(8)} clickable color={selectedWeekDayColor(8)} variant="outlined" label="Qua" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(16)} clickable color={selectedWeekDayColor(16)} variant="outlined" label="Qui" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(32)} clickable color={selectedWeekDayColor(32)} variant="outlined" label="Sex" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(64)} clickable color={selectedWeekDayColor(64)} variant="outlined" label="Sab" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(1)} clickable color={selectedWeekDayColor(1)} variant="outlined" label="Dom" /></Grid>
              </Grid>
            </Grid>

            {(typeof prices.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {prices.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={formik.isSubmitting}>
                Cadastrar {(formik.isSubmitting || prices.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>
  </>);
};

export default CreateCreditRestriction;