import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup'

import CloseIcon from '@material-ui/icons/Close';

import { fetchListRecurrenceDays } from "../_redux/scheduleActions";

import { notifySuccess, notifyError } from "../../../utils/Notify";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";

import { makeStyles } from '@material-ui/core/styles';

import {
  fetchCancelScheduleSpot,
} from "../_redux/scheduleActions";

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const CancelScheduleSpot = (props) => {
  const dispatch = useDispatch();
  const { schedules } = useSelector((state) => ({
    schedules: state.schedules,
  }), shallowEqual);

  const classes = useStyles();

  const [selectedClient, setSelectedClient] = useState({});
  const [checked, setChecked] = useState(false);
  const [type, setType] = useState('single');
  const [finishDateAt, setFinishDateAt] = useState('infinite');
  const [weekdays, setWeekdays] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (schedules.removeScheduleSpot == true) {
      close();

      notifyError("Removido com sucesso", 'schedules-removeScheduleSpot');
    }
  }, [schedules]);

  const close = () => {
    props.onHide();

    formik.setFieldValue('name', '', false);
  }

  useEffect(() => {
    if (props.show) {
      listRecurrenceDays();
    }
  }, [props.show]);

  const confirm = () => {
    close();
  }

  const changedCheck = (value) => {
    setChecked(!checked);
  }

  const listRecurrenceDays = () => {
    let startAt = moment(props.schedule_spot.attributes.start_at).format('YYYY-MM-DD');
    let finishAt = '';

    if (props.schedule_spot.relationships.schedule.attributes.recurrence_finish_at) {
      finishAt = moment(props.schedule_spot.relationships.schedule.attributes.recurrence_finish_at).format('YYYY-MM-DD');
    }
    if (props.schedule_spot.attributes.finish_at) {
      finishAt = moment(props.schedule_spot.attributes.finish_at).format('YYYY-MM-DD');
    }

    dispatch(fetchListRecurrenceDays(props.schedule_spot.relationships.schedule.attributes.recurrence_days, startAt, finishAt, 50)).then((data) => {
      setDates(data);
    });
  }

  const dispatchCancelScheduleSpot = (force = false) => {
    let params = {
      type: force ? 'event' : 'gt',
    };

    if (formik.values.date) {
      params.date = formik.values.date
    }

    if (params.type == 'event') {
      params.date = '';
    }

    dispatch(fetchCancelScheduleSpot(props.schedule_spot.id, params));
  }

  const formik = useFormik({
    initialValues: {
      date: '',
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    },
  });

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Finalizar inscrição
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          {props.schedule_spot && props.schedule_spot.id && <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
                {
                  props.schedule_spot &&
                  props.schedule_spot.attributes &&
                  !props.schedule_spot.attributes.recurrence && <>
                  <Grid item xs={12}  className="text-center">
                    <label className="col-12 col-form-label font-weight-normel text-left">
                      Cancelando a inscrição do jogador nessa partida você poderá adiciona-lo em outra partida
                    </label>

                    <label className="col-12 col-form-label font-weight-normel text-left">
                      O cancelamento é irreversível, porém você para adiciona-lo novamente nessa mesma partida
                    </label>

                    <a class="btn btn-light-danger font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelScheduleSpot(true)} disabled={formik.isSubmitting}>
                      Cancelar inscrição {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                    </a>
                  </Grid>
                </>}

              {
                props.schedule_spot &&
                props.schedule_spot.attributes &&
                props.schedule_spot.attributes.recurrence && <>

                <Grid item xs={12} className="text-center">
                  <label className="col-12 col-form-label font-weight-normel text-center">
                    Para finalizar a inscrição selecione a data da última partida que o jogador participou
                  </label>
                </Grid>

                <Grid item xs={3} />

                <Grid item xs={6}>
                  <TextField
                    select
                    label={'Data da última partida'}
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        }
                      }
                    }}
                    name="date"
                    fullWidth
                    className={classes.textField}
                    {...formik.getFieldProps("date")}
                  >
                    {dates.length > 0 && dates.map((day, index) => <MenuItem key={`day-${index}`} value={day}>
                      {moment(day).format('DD/MM/YYYY')}
                    </MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item xs={3} />

                <Grid container className={classes.label}>
                  <Grid item xs={12} className="text-center">
                    <a class="btn btn-light-warning font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelScheduleSpot()} disabled={formik.isSubmitting}>
                      Finalizar inscrição {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                    </a>
                  </Grid>


                  <Grid item xs={12} className="text-center">
                    <div className="separator separator-dashed mt-6 mb-4 mx-5"></div>

                    <label className="col-12 col-form-label font-weight-normel text-center">
                      Se você criou a inscrição por engano, você pode cancelar por completo removendo totalmente o jogador das partidas
                    </label>

                    <a class="btn btn-light-danger font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelScheduleSpot(true)} disabled={formik.isSubmitting}>
                      Cancelar inscrição inteira {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
                    </a>
                  </Grid>
                </Grid>
              </>}
            </Grid>

            {(typeof schedules.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {schedules.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>}
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>
  </>);
};

export default CancelScheduleSpot;