import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import clsx from 'clsx';
import SVG from "react-inlinesvg";
import ReactMarkdown from 'react-markdown'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import { green, pink } from '@material-ui/core/colors';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';

import ControlPoint from '@material-ui/icons/ControlPoint';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DeleteForever from '@material-ui/icons/DeleteForever';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import CreateClientSchedule from "./CreateClientSchedule";
import SelectParticipant from "./SelectParticipant";
import { fetchClientPhone } from "../../Client/_redux/clientActions";
import { fetchPriceService } from "../../Price/_redux/priceActions";

import {
  fetchLogsEvents,
  fetchCreateObservation
} from "../../Log/_redux/logActions";

import {
  fetchGetScheduleById,
  fetchUpdateSchedule,
  fetchUpdateScheduleLevel,
  fetchUpdateScheduleSpots,
  fetchCancelSchedule,
  fetchCancelScheduleSpot,
  fetchAddClientSchedule,
  fetchApproveSchedule,
  fetchAddParticipantSchedule,
  fetchRemoveParticipantSchedule,
  fetchListRecurrenceDays,
  fetchAddContractSchedule,
} from "../_redux/scheduleActions";

import { createScheduleSchema } from "../_schemas/createScheduleSchema";

import Snackbar from "../../../components/Snackbar";
import { RenderError } from "../../../components/RenderError";
import { formatMoney } from "../../../utils/IntegerParse";
import { notifySuccess } from "../../../utils/Notify";
import { hasPermission } from "../../../utils/Permission";
import {
  getWeekDays,
  formatLongDateTime
} from "../../../components/DateUtils";
import CreateClient from "../../Client/components/CreateClient";
import InvoicePage from "../../Invoice/pages/InvoicePage";
import ShowInvoice from "../../Invoice/components/ShowInvoice";
import CancelContract from "../components/CancelContract";
import CancelSchedule from "../components/CancelSchedule";
import MoveSchedule from "../components/MoveSchedule";
import SelectContract from "../components/SelectContract";
import EditClient from "../../Client/components/EditClient";

import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    paddingTop: 50
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerPaperOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: 'absolute',
    borderRadius: '7px 0 0 7px'
  },
  drawerPaperClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    position: 'absolute',
    borderRadius: '7px 0 0 7px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    // flexGrow: 1,
    padding: theme.spacing(3),
  },
  success: {
    padding: 3,
    color: '#fff',
    backgroundColor:  green[500],
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  danger: {
    padding: 3,
    color: '#fff',
    backgroundColor:  pink[500],
    width: theme.spacing(3),
    height: theme.spacing(3),

  },
  info: {
    padding: 3,
    color: '#fff',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  today: {
    padding: 3,
    color: '#fff',
    backgroundColor: '#3699FF',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  lineThrough: {
    textDecoration: 'line-through',
    fontSize: '10px',
    paddingRight: theme.spacing(1),
  },
}));

const EditSchedule = (props) => {
  const dispatch = useDispatch();

  const { fields, prices, clients, schedules, invoices, contracts, logs, user } = useSelector((state) => ({
    fields: state.fields,
    prices: state.prices,
    clients: state.clients,
    schedules: state.schedules,
    invoices: state.invoices,
    contracts: state.contracts,
    logs: state.logs,
    user: state.auth.user,
  }), shallowEqual);

  const classes = useStyles();
  const theme = useTheme();

  // const [typeSchedule, setTypeSchedule] = useState('client');

  const [open, setOpen] = useState(false);

  const [createClientScheduleStartDateAt, setCreateClientScheduleStartDateAt] = useState();
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [invoicePageShow, setInvoicePageShow] = useState(false);
  const [showEditLevel, setShowEditLevel] = useState(false);
  const [showEditSpots, setShowEditSpots] = useState(false);
  const [showInvoiceShow, setShowInvoiceShow] = useState(false);
  const [selectedContract, setSelectedContract] = useState({});
  const [clientScheduleShow, setClientScheduleShow] = useState(false);
  const [moveScheduleShow, setMoveScheduleShow] = useState(false);
  const [contractScheduleShow, setContractScheduleShow] = useState(false);
  const [participantScheduleShow, setParticipantScheduleShow] = useState(false);
  const [cancelContractShow, setCancelContractShow] = useState(false);
  const [cancelScheduleShow, setCancelScheduleShow] = useState(false);
  const [cancelContract, setCancelContract] = useState({});
  const [canCreate, setCanCreate] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canCancel, setCanCancel] = useState(false);

  const [event, setEvent] = useState({});
  const [field, setField] = useState({});
  const [weekdays, setWeekdays] = useState([]);
  const [type, setType] = useState('single');
  const [scheduleStartAt, setScheduleStartAt] = useState('');
  const [recurrence, setRecurrence] = useState(false);
  const [paymentOnline, setPaymentOnline] = useState(false);
  const [paymentExpireAt, setPaymentExpireAt] = useState(null);
  const [modality, setModality] = useState('reservation');
  const [scheduleStatus, setScheduleStatus] = useState({});

  const [editClientShow, setEditClientShow] = useState(false);
  const [editClient, setEditClient] = useState({});
  const [contractsRecurrence, setContractsRecurrence] = useState([]);
  const [contractsSingle, setContractsSingle] = useState([]);
  const [participants, setParticipants] = useState([]);

  const [logsEvents, setLogsEvents] = useState([]);
  const [observations, setObservations] = useState([]);

  const [showCancel, setShowCancel] = useState(false);
  const [dates, setDates] = useState([]);
  const [tab, setTab] = useState([]);

  useEffect(() => {
    if (
      schedules.approved == true ||
      schedules.updated == true ||
      schedules.moved == true ||
      schedules.addClient == true ||
      schedules.removeContract == true ||
      schedules.removeScheduleSpot == true
    ) {
      getSchedule();
      setShowEditLevel(false);
      setShowEditSpots(false);
    }

    if (schedules.canceled == true) {
      close();
    }

    if (schedules.updated == true) {
      notifySuccess("Agendamento atualizado com sucesso", 'schedules-updated', 2000);
    }

    if (schedules.moved == true) {
      notifySuccess("Agendamento movido com sucesso", 'schedules-moved', 2000);
    }
  }, [schedules]);

  useEffect(() => {
    if (
      logs.created == true ||
      logs.updated == true ||
      logs.deleted == true
    ) {
      getObservations();
      formikObservation.setFieldValue('message', '', false);
    }
  }, [
    logs.created,
    logs.updated,
    logs.deleted,
  ]);

  useEffect(() => {
    if ((invoices.updated || invoices.priceUpdated) && !invoices.actionsLoading) {
      closeShowInvoice();
    }
  }, [invoices]);

  useEffect(() => {
    if (props.show) {
      getSchedule();
    }

    if (!props.show) {
      close();
    }
  }, [props.show]);

  useEffect(() => {
    if (event && event.id) {
      getListRecurrenceDays();
    }
  }, [event]);

  useEffect(() => {
    if (tab == 'logs') {
      dispatch(fetchLogsEvents({
        origin_id: event.id,
        category: 'schedule',
      })).then((data) => {
        setLogsEvents(data);
      });
    }

    if (tab == 'observations') {
      getObservations();
    }
  }, [tab]);

  const getObservations = () => {
    dispatch(fetchLogsEvents({
      origin_id: event.id,
      category: 'observation',
    })).then((data) => {
      setObservations(data);
    });
  }

  const close = () => {
    props.onHide();

    setEvent({});
    setField({});
    setWeekdays([]);
    setScheduleStartAt('');
    setType('single');
    setTab('schedule');
    setRecurrence(false);
    setPaymentOnline(false);
    setModality('reservation');
    setScheduleStatus({});

    setSelectedStatus({});
    setContractsRecurrence([]);
    setContractsSingle([]);
    setParticipants([]);
    setDates([]);

    setShowCancel(false);
    setShowEditLevel(false);
    setShowEditSpots(false);
    setSelectedContract({});
  }

  const getSchedule = (date = '') => {
    if (props.event.event && props.event.event.id) {
      setCanCreate(hasPermission(user, 'schedule-create'));
      setCanUpdate(hasPermission(user, 'schedule-update'));

      let value = date != '' ? date : (scheduleStartAt != '' ? scheduleStartAt : props.event.event.attributes.start_at);

      dispatch(fetchGetScheduleById(
        props.event.event.id,
        moment(value).format('YYYY-MM-DD'))
      ).then((data) => {
        let schedule = data;

        setShowCancel(false);

        if (schedule) {
          let scheduleRecurrence = schedule.attributes.recurrence ? true : false;
          let scheduleModality = schedule.attributes.modality;

          setCanCancel(hasPermission(user, 'schedule-cancel') && schedule.attributes.can_cancel);
          setEvent(schedule);
          setType(schedule.attributes.recurrence ? 'recurrence' : 'single');
          setRecurrence(schedule.attributes.recurrence ? true : false);
          setModality(scheduleModality);
          setScheduleStatus(schedule.relationships.status);
          setField(schedule.relationships.field);
          setSelectedStatus(schedule.relationships.status);
          setScheduleStartAt(schedule.attributes.start_at);

          setContractsRecurrence(schedule.relationships.schedule_spot.filter(contract => contract.relationships.contract.relationships.service.attributes.type == 'recurrence'));
          setContractsSingle(schedule.relationships.schedule_spot.filter(contract => contract.relationships.contract.relationships.service.attributes.type == 'single'));
          setParticipants(schedule.relationships.participants);

          convertRecurrenceDaysBitMask(schedule.attributes.recurrence_days_bit_mask);

          formik.setFieldValue('level', schedule.attributes.level, false);
          formik.setFieldValue('spots', schedule.attributes.spots, false);

          if (!scheduleRecurrence && scheduleModality == 'reservation') {
            let currentInvoice = schedule.relationships?.schedule_spot?.[0]?.relationships?.contract?.relationships?.invoices?.[0];

            setPaymentOnline(currentInvoice?.attributes?.payment_type == 'online' ? true : false);
            setPaymentExpireAt(currentInvoice?.relationships?.payments?.[0]?.attributes?.expired_at);
          }
        }
      });
    }
  }

  const getListRecurrenceDays = () => {
    if (event && event.attributes && event.attributes.recurrence &&  dates.length == 0) {
      let recurrencestartAt =  moment(event.attributes.recurrence_start_at).format('YYYY-MM-DD');
      let recurrenceFinishAt = event.attributes.recurrence_finish_at ? moment(event.attributes.recurrence_finish_at).format('YYYY-MM-DD') : null;

      dispatch(fetchListRecurrenceDays(event.attributes.recurrence_days, recurrencestartAt, recurrenceFinishAt, 120)).then((data) => {
        setDates(data);
      });
    }
  }

  const dispatchCancelSchedule = (type) => {
    dispatch(fetchCancelSchedule(event.id, {
      date: moment(event.attributes.start_at).format('YYYY-MM-DD'),
      type: type,
    }));
  }

  const approveSchedule = () => {
    dispatch(fetchApproveSchedule(event.id));
  }

  const dispatchCancelScheduleSpot = (type, scheduleSpotId) => {
    dispatch(fetchCancelScheduleSpot(scheduleSpotId, {
      date: moment(event.attributes.start_at).format('YYYY-MM-DD'),
      type: type,
    }));
  }

  const dispatchRemoveParticipantSchedule = (participantScheduleId) => {
    dispatch(fetchRemoveParticipantSchedule(participantScheduleId));
  }

  const dispatchUpdateLevel = () => {
    dispatch(fetchUpdateScheduleLevel(event.id, formik.values.level));
  }

  const dispatchUpdateSpots = () => {
    dispatch(fetchUpdateScheduleSpots(event.id, formik.values.spots));
  }

  const getInvoiceStatusLabel = (invoice) => {
    let label = <span class="label label-lg label-light-primary label-inline">A vencer</span>;

    if (invoice.attributes && invoice.attributes.is_canceled) {
      label = <span class="label label-lg label-light-danger label-inline">Cancelado</span>;
    }

    if (invoice.attributes && invoice.attributes.is_paid) {
      label = <span class="label label-lg label-light-success label-inline">Pago</span>;
    }

    if (invoice.attributes && invoice.attributes.is_expired && invoice.attributes.payment_type !== 'online') {
      label = <span class="label label-lg label-light-warning label-inline">Vencido</span>;
    }

    if (invoice.attributes && invoice.attributes.is_expired && invoice.attributes.payment_type === 'online') {
      label = <span class="label label-lg label-light-warning label-inline">Aguardando pagamento</span>;
    }

    return <div className="flex-column">
      {label}
      {invoice.attributes.payment_type == 'online' && <>
        <br />
        <span class="label label-lg label-outline-info label-inline ml-1 mt-2">Pagamento Online</span>
      </>}
    </div>
  }

  const formik = useFormik({
    initialValues: {
      scheduleId: '',
      clientId: '',
      recurrenceFinishAt: 'infinite',
      startDateAt: '',
      startAt: '',
      endDateAt: '',
      endAt: '',
      fieldId: '',
      price: '',
      // serviceId: '',
      statusId: '',
      level: '',
    },
    validationSchema: createScheduleSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        // if (props.event.attributes.recurrence) {
        //   values.recurrence_start_at = values.startDateAt;

        //   values.recurrence_finish_at = 'infinite';

        //   if (values.recurrenceFinishAt != 'infinite') {
        //     values.recurrence_finish_at = moment(values.startDateAt).add(values.recurrenceFinishAt, 'months').format('YYYY-MM-DD')
        //   }

        // }

        // dispatch(fetchUpdateSchedule(values.scheduleId, values));

        // setSubmitting(false);
      }, 500);
    },
  });

  const formikObservation = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      dispatch(fetchCreateObservation({
        message: values.message,
        origin_id: event.id
      }));

      setSubmitting(false);
    },
  });

  const closeCreateClient = () => {
    setCreateEntityShow(false);
  }

  const closeCancelContract = () => {
    setCancelContractShow(false);
  }

  const openCancelContract = (value) => {
    setCancelContract(value)
    setCancelContractShow(true);
  }

  const closeCancelSchedule = () => {
    setCancelScheduleShow(false);
  }

  const openCancelSchedule = () => {
    setCancelScheduleShow(true);
  }

  const convertRecurrenceDaysBitMask = (days) => {
    let values = [
      1,
      2,
      4,
      8,
      16,
      32,
      64,
    ];

    let data = [];

    days.map(day => {
      data.push(values[day]);
    })

    return setWeekdays(data);
  }

  const openInvoice = (scheduleSpot) => {
    setInvoicePageShow(true);
    setSelectedContract(scheduleSpot.relationships.contract);
  }

  const closeInvoice = () => {
    getSchedule();
    setInvoicePageShow(false);
    setSelectedContract({});
  }

  const closeShowInvoice = () => {
    getSchedule();
    setShowInvoiceShow(false);
    setSelectedInvoice({});
  }

  const openShowInvoice = (invoice) => {
    setShowInvoiceShow(true);
    setSelectedInvoice(invoice);
  }

  const closeClientSchedule = () => {
    setClientScheduleShow(false);

  }

  const openClientSchedule = () => {
    getServices();
    setClientScheduleShow(true);
  }

  const closeContractSchedule = () => {
    setContractScheduleShow(false);

  }

  const openContractSchedule = () => {
    setContractScheduleShow(true);
  }

  const closeMoveSchedule = () => {
    setMoveScheduleShow(false);

  }

  const openMoveSchedule = () => {
    setMoveScheduleShow(true);
  }

  const openEditClient = (event) => {
    setEditClient(event);

    setEditClientShow(true);
  }

  const closeEditClient = () => {
    setEditClientShow(false);
  }

  useEffect(() => {
    formik.setFieldValue('endDateAt', formik.values.startDateAt, false);
  }, [formik.values.startDateAt]);

  useEffect(() => {
    if (formik.values.fieldId) {
      let fieldFilter = fields.entities.find(element => {
        return element.id === formik.values.fieldId;
      });
    }
  }, [formik.values.fieldId]);

  const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
      <a
        ref={ref}
        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        onClick={e => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        {props.children}
      </a>
    );
  });

  const selectClient = (client) => {
    let data = {
      client_id: client.id,
      start_at: client.attributes.start_at,
      billing_day: client.attributes.billing_day,
      plan: client.attributes.plan,
      service_id: client.relationships.service.id,
    };

    if (client.attributes.credit_id) {
      data.credit_id = client.attributes.credit_id;
    }

    dispatch(fetchAddClientSchedule(event.id, data));
  }

  const selectParticipant = (user) => {
    dispatch(fetchAddParticipantSchedule(event.id, {
      participant_id: user.id,
    }));
  }

  const selectContract = (value) => {
    closeContractSchedule();

    dispatch(fetchAddContractSchedule(event.id, {
      contract_id: value.id,
      start_at: value.startDateAt
    }));
  }

  const getServices = (client) => {
    dispatch(fetchPriceService(
      field.id,
      event.attributes.start_at,
      event.attributes.finish_at,
      modality,
      type,
    ))
  }

  const nextSchedule = () => {
    getSchedule(moment(event.attributes.start_at).add('7', 'days'));
  }

  const previousSchedule = () => {
    getSchedule(moment(event.attributes.start_at).add('-7', 'days'));
  }

  const showScheduleInfo = (date) => {
    getSchedule(date);
    setTab('schedule');
  }

  const getPlanLabel = (value) => {
    if (value == 'monthly') { return 'Mensal' };
    if (value == 'quarterly') { return 'Trimestral' };
    if (value == 'semester') { return 'Semestral' };
    if (value == 'yearly') { return 'Anual' };

    return '';
  }

  const getLogBadge = (value) => {
    if (value == 'create') { return 'bg-success' };
    if (value == 'delete') { return 'bg-danger' };
    if (value == 'cancel') { return 'bg-danger' };
    if (value == 'update') { return 'bg-primary' };

    return 'bg-primary';
  }

  const classEditInfo = () => {
    return <>
      <Grid item xs={12}>
        <span className="text-dark">
          <span className="font-weight-bolder text-dark-50">Nível da aula</span>: {event.attributes && event.attributes.level ? event.attributes.level_formatted : `Não definido`}
          {canUpdate && !showEditLevel && <span class="cursor-pointer ml-2" onClick={() => setShowEditLevel(!showEditLevel)}>
            <i class="fas fa-edit icon-nm text-dark-50"></i>
          </span>}
        </span>
      </Grid>

      {showEditLevel && <Grid container className={classes.label} spacing={2} style={{paddingTop: 10, paddingBottom: 10}}>
        <div class="col-lg-6 py-1">
          <TextField
            select
            label={'Nível da aula'}
            variant="outlined"
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                }
              }
            }}
            name="level"
            fullWidth
            className={classes.textField}
            {...formik.getFieldProps("level")}
          >
            <MenuItem key={`level-1`} value={1}>
              Iniciante
            </MenuItem>
            <MenuItem key={`level-2`} value={2}>
              Intermediário
            </MenuItem>
            <MenuItem key={`level-3`} value={3}>
              Avançado
            </MenuItem>
            <MenuItem key={`level-4`} value={4}>
              Profissional
            </MenuItem>
          </TextField>
        </div>

        <div class="col-lg-6 py-1 flex-column align-items-center">
          <span class="label label-xl font-weight-boldest label-success mt-5 cursor-pointer" onClick={() => dispatchUpdateLevel()}>
            <i class="ki ki-check text-white"></i>
          </span>
          <span class="label label-xl font-weight-boldest label-danger mt-5 ml-2 cursor-pointer" onClick={() => setShowEditLevel(false)}>
            <i class="ki ki-close text-white"></i>
          </span>
        </div>
      </Grid>}

      <Grid item xs={12}>
        <span className="text-dark">
          <span className="font-weight-bolder text-dark-50">Máximo por aula</span>
            : {event.attributes && event.attributes.spots > 1 ? event.attributes.spots + ` jogadores` : `1 jogador`}
          {canUpdate && !showEditSpots && <span class="cursor-pointer ml-2" onClick={() => setShowEditSpots(!showEditSpots)}>
            <i class="fas fa-edit icon-nm text-dark-50"></i>
          </span>}
        </span>
      </Grid>

      {showEditSpots && <Grid container className={classes.label} spacing={2} style={{paddingTop: 10, paddingBottom: 10}}>
        <div class="col-lg-6 py-1">
          <TextField
            select
            label={'Número de Jogadores'}
            variant="outlined"
            SelectProps={{
              MenuProps: {
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                }
              }
            }}
            name="spots"
            fullWidth
            className={classes.textField}
            {...formik.getFieldProps("spots")}
          >
            <MenuItem key={`spots-1`} value={1}>1</MenuItem>
            <MenuItem key={`spots-2`} value={2}>2</MenuItem>
            <MenuItem key={`spots-3`} value={3}>3</MenuItem>
            <MenuItem key={`spots-4`} value={4}>4</MenuItem>
            <MenuItem key={`spots-5`} value={5}>5</MenuItem>
            <MenuItem key={`spots-6`} value={6}>6</MenuItem>
            <MenuItem key={`spots-7`} value={7}>7</MenuItem>
            <MenuItem key={`spots-8`} value={8}>8</MenuItem>
            <MenuItem key={`spots-9`} value={9}>9</MenuItem>
            <MenuItem key={`spots-10`} value={10}>10</MenuItem>
          </TextField>
        </div>

        <div class="col-lg-6 py-1 flex-column align-items-center">
          <span class="label label-xl font-weight-boldest label-success mt-5 cursor-pointer" onClick={() => dispatchUpdateSpots()}>
            <i class="ki ki-check text-white"></i>
          </span>
          <span class="label label-xl font-weight-boldest label-danger mt-5 ml-2 cursor-pointer" onClick={() => setShowEditSpots(false)}>
            <i class="ki ki-close text-white"></i>
          </span>
        </div>
      </Grid>}
    </>
  }

  return (<>
  <Modal
    {...props}
    size="xl"
    centered={type == 'single'}
    aria-labelledby="contained-modal-title-vcenter"
    backdrop="static"
    keyboard={true}
    show={
      props.show &&
      !createEntityShow &&
      !invoicePageShow &&
      !showInvoiceShow &&
      !cancelContractShow &&
      !cancelScheduleShow &&
      !editClientShow &&
      !contractScheduleShow
    }
    contenStyle={{maxWidth: '1000px', backgroundColor: 'transparent !important'}}
  >
    <form className="form" autoComplete="off" variant="outlined">
      <div className="card card-custom card-transparent">
        <div className="card-header card-header-tabs-line nav-tabs-line-2x">
          <div className="card-toolbar">
            <ul className="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-2x">
              <li className="nav-item mr-3">
                <a className={tab == 'schedule' ? 'nav-link active' : 'nav-link'} data-toggle="tab" onClick={() => setTab('schedule')}>
                  <span className="nav-icon">
                    <span className="svg-icon">
                      <i className="ki ki-info"></i>
                    </span>
                  </span>
                  <span className="nav-text font-size-lg">Informações</span>
                </a>
              </li>
              {type == 'recurrence' && <li className="nav-item mr-3">
                <a className={tab == 'calendar' ? 'nav-link active' : 'nav-link'} data-toggle="tab" onClick={() => setTab('calendar')}>
                  <span className="nav-icon">
                    <span className="svg-icon">
                      <i className="ki ki-calendar-2"></i>
                    </span>
                  </span>
                  <span className="nav-text font-size-lg">Calendário</span>
                </a>
              </li>}
              <li className="nav-item mr-3">
                <a className={tab == 'observations' ? 'nav-link active' : 'nav-link'} data-toggle="tab" onClick={() => setTab('observations')}>
                  <span className="nav-icon">
                    <span className="svg-icon">
                      <i className="flaticon-notes"></i>
                    </span>
                  </span>
                  <span className="nav-text font-size-lg">Anotações</span>
                </a>
              </li>
              <li className="nav-item mr-3">
                <a className={tab == 'logs' ? 'nav-link active' : 'nav-link'} data-toggle="tab" onClick={() => setTab('logs')}>
                  <span className="nav-icon">
                    <span className="svg-icon">
                      <i className="ki ki-outline-info"></i>
                    </span>
                  </span>
                  <span className="nav-text font-size-lg">Histórico</span>
                </a>
              </li>
            </ul>
          </div>

          <CloseIcon onClick={close} className="mt-3 mr-3 cursor-pointer" style={{alignSelf: 'flex-end', position: 'absolute', right: 10, top: 10}} />
        </div>

        <div className="card-body pt-10 pb-10 pl-15 pr-15 ribbon ribbon-right">
          {(typeof schedules.error) == "string" && !clientScheduleShow && <Grid item xs={12}>
            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                  {schedules.error}
                </span>
              </div>
            </div>
          </Grid>}

          {tab == 'schedule' && <div class="row">
            {event?.attributes?.origin == 'client' && <div class="ribbon-target" style={{backgroundColor: '#DAFB6D', color: '#1F2933', top: "10px", right: "0px"}}><p style={{fontSize: '1em', margin: 0}}>Aplicativo</p></div>}
            <div class="col-lg-6">
              <Grid container className={classes.label}>
                {type == 'single' && <Grid item xs>
                  <span className="font-weight-bolder text-dark">
                    {modality == 'class' && 'Aula avulso'}
                    {modality == 'reservation' && 'Locação avulso'}
                    {modality == 'unavailable' && 'Horário indisponível'}
                  </span>
                </Grid>}

                {type == 'recurrence' && <>
                  <Grid item xs>
                    {event && event.attributes && dates.length > 0 && dates.indexOf(moment(event.attributes.start_at).format('YYYY-MM-DD')) > 0 && <a className="font-weight-bold" onClick={() => previousSchedule()}>
                      <ChevronLeft fontSize="inherit" /> {modality == 'unavailable' && 'Semana'}{modality == 'class' && 'Aula'}{modality == 'reservation' && 'Partida'} anterior
                    </a>}
                  </Grid>

                  <Grid item xs className="text-center">
                    <span className="font-weight-bolder text-dark">
                      {modality == 'unavailable' && 'Bloqueio'}{modality == 'class' && 'Aula'}{modality == 'reservation' && 'Partida'} #{event.attributes.occurrence}
                    </span>
                  </Grid>

                  <Grid item xs className="text-right">
                    {event && event.attributes && dates.length > 0 && dates.indexOf(moment(event.attributes.start_at).format('YYYY-MM-DD')) < (dates.length - 1) && <a className="font-weight-bold" onClick={() => nextSchedule()}>
                      Próxima {modality == 'unavailable' && 'semana'}{modality == 'class' && 'aula'}{modality == 'reservation' && 'partida'} <ChevronRight fontSize="inherit" />
                    </a>}
                  </Grid>
                </>}
              </Grid>

              <div className="separator separator-dashed mb-5 mt-1"></div>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <span className="text-dark">
                    <span className="font-weight-bolder text-dark-50">Início:</span> {event && event.attributes && moment(event.attributes.start_at).format('DD/MM/YYYY - HH:mm')}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className="text-dark">
                    <span className="font-weight-bolder text-dark-50">Duração:</span> {event && event.attributes && moment.utc(moment(event.attributes.finish_at).diff(moment(event.attributes.start_at))).format("HH:mm")}
                  </span>
                </Grid>

                {field && field.attributes && <Grid item xs={12}>
                  <span className="text-dark">
                    <span className="font-weight-bolder text-dark-50">Local:</span> {field.attributes.name}
                  </span>
                </Grid>}

                {modality == 'class' && type == 'single' && <>
                  {classEditInfo()}
                </>}

                {event && event.attributes && event.attributes.is_canceled == true && <Grid item xs={12}>
                  <Alert key="alert-accept-schedule" variant="warning">
                    {modality == 'class' && 'Aula cancelada'}
                    {modality == 'reservation' && 'Partida cancelada'}
                    {modality == 'unavailable' && 'Horário indisponível cancelado'}
                  </Alert>
                </Grid>}

                {selectedStatus.attributes && selectedStatus.attributes.name != 'pending' && <div class="card-toolbar">
                  {canUpdate && canCancel && modality == 'class' && type == 'single' && <a class="btn btn-primary font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => openMoveSchedule()}>
                    Alterar aula
                  </a>}

                  {canUpdate && canCancel && modality == 'reservation' && type == 'single' && <a class="btn btn-primary font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => openMoveSchedule()}>
                    Alterar partida
                  </a>}

                  {/*{modality == 'reservation' && type == 'recurrence' && <a class="btn btn-primary font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => openMoveSchedule()}>
                    Mover partida
                  </a>}*/}

                  {canCancel && event && event.attributes && !event.attributes.is_canceled && type == 'single' && <a
                    class="btn btn-light-warning font-weight-bolder font-size-sm mr-5 mt-2"
                    // onClick={() => dispatchCancelSchedule('event')}
                    onClick={() => openCancelSchedule()}
                  >
                    {modality == 'class' && "Cancelar aula"}
                    {modality == 'reservation' && "Cancelar partida"}
                    {modality == 'unavailable' && 'Cancelar horário'}
                  </a>}

                  {canCancel && event && event.attributes && !event.attributes.is_canceled && type == 'recurrence' && <a class="btn btn-light-warning font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => dispatchCancelSchedule('equal')}>
                    {modality == 'class' && "Cancelar somente essa aula"}
                    {modality == 'reservation' && "Cancelar somente essa partida"}
                    {modality == 'unavailable' && "Cancelar somente esse horário"}
                  </a>}
                </div>}
              </Grid>

              {type == 'recurrence' && <>
                <Grid container className="pt-6">
                  <Grid item xs>
                    <span className="font-weight-bolder text-dark">
                      {type == 'recurrence' && <>
                        {modality == 'class' && 'Aula mensal'}
                        {modality == 'reservation' && 'Locação mensal'}
                        {modality == 'unavailable' && 'Bloqueio mensal'}
                      </>}
                    </span>
                  </Grid>
                </Grid>

                <div className="separator separator-dashed mb-5 mt-1"></div>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Início da recorrência</span>: {event.attributes && moment(event.attributes.recurrence_start_at).format('DD/MM/YYYY')}
                    </span>
                  </Grid>
                  {event.attributes.recurrence_finish_at && <Grid item xs={12}>
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Termino da recorrência</span>: {event.attributes && moment(event.attributes.recurrence_finish_at).format('DD/MM/YYYY')}
                    </span>
                  </Grid>}
                  {event.attributes.recurrence_days_bit_mask && <Grid item xs={12}>
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Dias da semana</span>: {event.attributes && getWeekDays(event.attributes.recurrence_days_bit_mask, true)}
                    </span>
                  </Grid>}
                  {modality == 'class' && <>
                    {classEditInfo()}
                  </>}
                </Grid>

                <div class="card-toolbar">
                  {/*{<a class="btn btn-primary font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => openClientSchedule()}>
                    Alterar recorrência
                  </a>}*/}

                  {canCancel && <a class="btn btn-light-primary font-weight-bolder font-size-sm mr-5 mt-2" onClick={() => openCancelSchedule()}>
                    Finalizar recorrência
                  </a>}
                </div>
              </>}

              {selectedStatus.attributes && selectedStatus.attributes.name == 'pending' && <>
                {!paymentOnline && <Grid container spacing={2} className="pt-5">
                  <Grid item xs={12}>
                    <Alert key="alert-accept-schedule" variant="primary">
                      Esse agendamento foi feito pelo aplicativo.<br />
                      Deseja aprovar esse agendamento?
                    </Alert>
                  </Grid>
                  <Grid item xs={6} style={{textAlign: 'right'}}>
                    <Button style={{backgroundColor: '#dafb6d', color: '#1F2933'}} variant="contained" size="large" onClick={() => approveSchedule()}>
                      Aprovar
                    </Button>
                  </Grid>
                  <Grid item xs={6} style={{textAlign: 'left'}}>
                    <Button style={{backgroundColor: '#ff6565', color: 'white'}} variant="contained" size="large" onClick={() => dispatchCancelSchedule('event')}>
                      Recusar
                    </Button>
                  </Grid>
                </Grid>}

                {paymentOnline && <Grid container spacing={2} className="pt-5">
                  <Grid item xs={12}>
                    <Alert key="alert-accept-schedule" variant="warning">
                      Pagamento em processamento.<br />
                      Aguarde o pagamento ou cancelamento automático{paymentExpireAt && `: ${formatLongDateTime(paymentExpireAt)}`}
                    </Alert>
                  </Grid>
                </Grid>}
              </>}

              {(modality == 'class' || participants.length > 0) && <>
                <div className="separator separator-dashed mb-3 mt-5"></div>
                <Grid container className="pt-5">
                  <Grid item xs={8}>
                    <span className="font-weight-bolder text-dark pt-5">
                      Professor
                    </span>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <ListGroup>
                    {participants.map(client => <>
                      <ListGroup.Item>
                        <Grid container>
                          <Grid item xs={9} fontSize="large">
                            <Chip
                              size="small"
                              color={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'default' : 'primary' }
                              label={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'Cliente' : 'Professor' }
                              variant="outlined"
                              style={{
                                marginRight: 5,
                              }}
                            />
                            {client.attributes.name}
                          </Grid>
                          <Grid item xs={3} style={{textAlign: 'right'}}>
                            {canUpdate && <Dropdown className="dropdown-inline" drop="down" alignRight>
                              <Dropdown.Toggle
                                  as={DropdownCustomToggler}
                              id="dropdown-toggle-top">
                                <i className="ki ki-bold-more-hor" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <ul className="navi navi-hover py-5">
                                  <li className="navi-item" onClick={() => dispatchRemoveParticipantSchedule(client.relationships.participant_schedule.id)}>
                                    <a className="navi-link">
                                      <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                      <span className="navi-text">
                                        Remover
                                      </span>
                                    </a>
                                  </li>
                              </ul>
                              </Dropdown.Menu>
                            </Dropdown>}
                          </Grid>
                        </Grid>
                      </ListGroup.Item>
                    </>)}
                    {canUpdate && modality == 'class' && <ListGroup.Item as="a" action style={{textAlign: 'center'}} onClick={() => setParticipantScheduleShow(true)}>
                      <ControlPoint fontSize="inherit" />Selecionar professor
                    </ListGroup.Item>}
                  </ListGroup>

                  {schedules.error && <RenderError error={schedules.error.participants} />}
                </Grid>
              </>}
            </div>

            {(modality == 'class' || modality == 'reservation') && <div class="col-lg-6">
              {contractsRecurrence.length > 0 && <>
                <Grid container className={classes.label}>
                  <Grid item xs={8}>
                    <span className="font-weight-bolder text-dark">
                      {modality == 'class' && "Jogadores mensalistas"}
                      {modality == 'reservation' && "Dados do jogador"}
                    </span>
                  </Grid>

                  <Grid item xs={4}>
                    {hasPermission(user, 'client-create') && modality == 'class' && <a className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                      <ControlPoint fontSize="inherit" /> Cadastrar cliente
                    </a>}
                  </Grid>
                </Grid>

                <div className="separator separator-dashed mb-5 mt-1"></div>

                {contractsRecurrence.map(scheduleSpot => <>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="d-flex mb-1">
                        <span class="text-dark-50 w-30 font-weight-bold">Nome</span>
                        <span class="text-dark w-70 font-weight-bold">
                          {scheduleSpot.relationships.contract.relationships.client.attributes.name}
                        </span>
                      </div>
                    </div>


                    {
                      scheduleSpot.relationships &&
                      scheduleSpot.relationships.contract &&
                      scheduleSpot.relationships.contract.relationships &&
                      scheduleSpot.relationships.contract.relationships.service &&
                      scheduleSpot.relationships.contract.relationships.service.attributes && <div class="col-lg-12">
                      {/*<span className="text-dark">
                        <span className="font-weight-bolder text-dark-50">Serviço: </span>
                        {scheduleSpot.relationships.contract.relationships.service.attributes.type == 'single' ? 'Avulso' : 'Mensal'} - {scheduleSpot.relationships.contract.relationships.service.attributes.name}
                      </span>*/}
                      <div class="d-flex mb-1">
                        <span class="text-dark-50 w-30 font-weight-bold">Serviço</span>
                        <span class="text-dark w-70 font-weight-bold">
                          {scheduleSpot.relationships.contract.relationships.service.attributes.type == 'single' ? 'Avulso' : 'Mensal'} - {scheduleSpot.relationships.contract.relationships.service.attributes.name}
                        </span>
                      </div>
                    </div>}

                    {
                      scheduleSpot.relationships &&
                      scheduleSpot.relationships.contract &&
                      scheduleSpot.relationships.contract.relationships.service &&
                      scheduleSpot.relationships.contract.relationships.service.attributes &&
                      scheduleSpot.relationships.contract.relationships.service.attributes.type == 'recurrence' &&
                      scheduleSpot.relationships.contract.attributes &&
                      scheduleSpot.relationships.contract.attributes.price && <div class="col-lg-12">
                      <div class="d-flex mb-1">
                        <span class="text-dark-50 w-30 font-weight-bold">Valor do plano</span>
                        <span class="text-dark w-70 font-weight-bold">
                          {formatMoney(scheduleSpot.relationships.contract.attributes.price)}
                        </span>
                      </div>
                    </div>}

                    {
                      scheduleSpot.relationships &&
                      scheduleSpot.relationships.contract &&
                      scheduleSpot.relationships.contract.attributes &&
                      scheduleSpot.relationships.contract.attributes.plan && <div class="col-lg-12">
                      <div class="d-flex mb-1">
                        <span class="text-dark-50 w-30 font-weight-bold">Pagamento</span>
                        <span class="text-dark w-70 font-weight-bold">
                          {getPlanLabel(scheduleSpot.relationships.contract.attributes.plan)}
                        </span>
                      </div>
                    </div>}

                    {scheduleSpot.attributes && scheduleSpot.attributes.start_at && type == 'recurrence' && <div class="col-lg-12">
                      <div class="d-flex mb-1">
                        <span class="text-dark-50 w-30 font-weight-bold">{scheduleSpot.relationships.contract.relationships.service.attributes.type == 'recurrence' ? 'Início na turma' : 'Data'}</span>
                        <span class="text-dark w-70 font-weight-bold">
                          {moment(scheduleSpot.attributes.start_at).format('DD/MM/YYYY')}
                        </span>
                      </div>
                    </div>}

                    {scheduleSpot.relationships.contract.relationships.service.attributes.type == 'recurrence' && <>
                      {scheduleSpot.attributes && scheduleSpot.attributes.finish_at && <div class="col-lg-12">
                        <div class="d-flex mb-1">
                          <span class="text-dark-50 w-30 font-weight-bold">Termino na turma</span>
                          <span class="text-dark w-70 font-weight-bold">
                            {moment(scheduleSpot.attributes.finish_at).format('DD/MM/YYYY')}
                          </span>
                        </div>
                      </div>}

                      {scheduleSpot.attributes && scheduleSpot.attributes.canceled_at && <div class="col-lg-12">
                        <div class="d-flex mb-1">
                          <span class="text-dark-50 w-30 font-weight-bold">Cancelamento da inscrição</span>
                          <span class="text-dark w-70 font-weight-bold">
                            {moment(scheduleSpot.attributes.canceled_at).format('DD/MM/YYYY HH:mm')}
                          </span>
                        </div>
                      </div>}

                      <div class="col-lg-12">
                        <div class="card-toolbar">
                          <a class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-2" onClick={() => openEditClient(scheduleSpot.relationships.contract.relationships.client)}>
                            Ver cliente
                          </a>
                          {hasPermission(user, 'contract-list') && <a class="btn btn-light-success font-weight-bolder font-size-sm mt-2 mr-2" onClick={() => openInvoice(scheduleSpot)}>
                            Ver contrato
                          </a>}
                          {canCancel && modality == 'class' && <a class="btn btn-light-danger font-weight-bolder font-size-sm mt-2 mr-2" onClick={() => dispatchCancelScheduleSpot('equal', scheduleSpot.id)}>
                            Marcar falta
                          </a>}
                        </div>
                      </div>
                    </>}
                  </div>

                  <div className="separator separator-dashed my-3 mx-5"></div>
                </>)}
              </>}

              {contractsSingle.length > 0 && <>
                <Grid container className={classes.label}>
                  <Grid item xs={8}>
                    <span className="font-weight-bolder text-dark pt-10">
                      {modality == 'class' && "Jogadores avulso"}
                      {modality == 'reservation' && "Dados do jogador"}
                    </span>
                  </Grid>

                  <Grid item xs={4}>
                    {hasPermission(user, 'client-create') && modality == 'class' && contractsRecurrence.length == 0 && <a className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                      <ControlPoint fontSize="inherit" /> Cadastrar cliente
                    </a>}
                  </Grid>
                </Grid>

                <div className="separator separator-dashed mb-5 mt-1"></div>

                {contractsSingle.map(scheduleSpot => <>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="d-flex mb-1">
                        <span class="text-dark-50 w-30 font-weight-bold">Nome</span>
                        <span class="text-dark w-70 font-weight-bold">
                          {scheduleSpot.relationships.contract.relationships.client.attributes.name}
                        </span>
                      </div>
                    </div>

                    {
                      scheduleSpot.relationships &&
                      scheduleSpot.relationships.contract &&
                      scheduleSpot.relationships.contract.relationships &&
                      scheduleSpot.relationships.contract.relationships.service &&
                      scheduleSpot.relationships.contract.relationships.service.attributes && <div class="col-lg-12">
                      <div class="d-flex mb-1">
                        <span class="text-dark-50 w-30 font-weight-bold">Serviço</span>
                        <span class="text-dark w-70 font-weight-bold">
                          {scheduleSpot.relationships.contract.relationships.service.attributes.type == 'single' ? 'Avulso' : 'Mensal'} - {scheduleSpot.relationships.contract.relationships.service.attributes.name}
                        </span>
                      </div>
                    </div>}

                    {
                      scheduleSpot.relationships &&
                      scheduleSpot.relationships.contract &&
                      scheduleSpot.relationships.contract.attributes &&
                      scheduleSpot.relationships.contract.attributes.plan && <div class="col-lg-12">
                        <div class="d-flex mb-1">
                          <span class="text-dark-50 w-30 font-weight-bold">Pagamento</span>
                          <span class="text-dark w-70 font-weight-bold">
                            {getPlanLabel(scheduleSpot.relationships.contract.attributes.plan)}
                          </span>
                        </div>
                    </div>}

                    {scheduleSpot.attributes && scheduleSpot.attributes.start_at && type == 'recurrence' && <div class="col-lg-12" style={{borderBottom: 1}}>
                      <div class="d-flex mb-1">
                        <span class="text-dark-50 w-30 font-weight-bold">{scheduleSpot.relationships.contract.relationships.service.attributes.type == 'recurrence' ? 'Início da inscrição' : 'Data'}</span>
                        <span class="text-dark w-70 font-weight-bold">
                          {moment(scheduleSpot.attributes.start_at).format('DD/MM/YYYY')}
                        </span>
                      </div>
                    </div>}

                    {
                      scheduleSpot.relationships &&
                      scheduleSpot.relationships.contract &&
                      scheduleSpot.relationships.contract.relationships &&
                      scheduleSpot.relationships.contract.relationships.service.attributes.type == 'single' &&
                      scheduleSpot.relationships.contract.relationships.invoices &&
                      scheduleSpot.relationships.contract.relationships.invoices[0] &&
                      scheduleSpot.relationships.contract.relationships.invoices[0].attributes && <>
                        <div class="col-lg-12">
                          <div class="d-flex mb-1">
                            <span class="text-dark-50 w-30 font-weight-bold">Valor</span>
                            <span class="text-dark w-70">
                              {scheduleSpot.relationships.contract.relationships.invoices[0].attributes.discount > 0 && <span className={classes.lineThrough}>
                                {scheduleSpot.relationships.contract.relationships.invoices[0].attributes.price != 0.00 && `R$ ${parseFloat(scheduleSpot.relationships.contract.relationships.invoices[0].attributes.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
                                {scheduleSpot.relationships.contract.relationships.invoices[0].attributes.price == 0.00 && `Isento`}
                              </span>}
                              <span className="font-weight-bold">

                                {scheduleSpot.relationships.contract.relationships.invoices[0].attributes.full_price != 0.00 && formatMoney(scheduleSpot.relationships.contract.relationships.invoices[0].attributes.full_price)}

                                {scheduleSpot.relationships.contract.relationships.invoices[0].attributes.price == 0.00 &&
                                scheduleSpot.relationships.contract.relationships.invoices[0].attributes.payment_method != 'credit' &&
                                scheduleSpot.relationships.contract.relationships.invoices[0].attributes.payment_type != 'online' && `Isento`}

                                {scheduleSpot.relationships.contract.relationships.invoices[0].attributes.payment_method == 'credit' &&
                                scheduleSpot.relationships.contract.relationships.invoices[0].attributes.payment_type == 'online' && `1 Crédito`}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="d-flex mb-1">
                            <span class="text-dark-50 w-30 font-weight-bold">Pagamento</span>
                            <span class="text-dark w-70 font-weight-bold">
                              {getInvoiceStatusLabel(scheduleSpot.relationships.contract.relationships.invoices[0])}
                            </span>
                          </div>
                        </div>

                        {scheduleSpot.relationships.contract.relationships.invoices[0].attributes.is_paid && <div class="col-lg-12">
                          <div class="d-flex mb-1">
                            <span class="text-dark-50 w-30 font-weight-bold">Data pagamento</span>
                            <span class="text-dark w-70 font-weight-bold">
                              {moment(scheduleSpot.relationships.contract.relationships.invoices[0].attributes.paid_at).format('DD/MM/YYYY HH:mm')}
                            </span>
                          </div>
                        </div>}

                        <div class="col-lg-12">
                          <div class="card-toolbar">
                            <a class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-2" onClick={() => openEditClient(scheduleSpot.relationships.contract.relationships.client)}>
                              Ver cliente
                            </a>
                            <a class="btn btn-light-success font-weight-bolder font-size-sm mt-2 mr-2" onClick={() => openShowInvoice(scheduleSpot.relationships.contract.relationships.invoices[0])}>
                              Visualizar fatura
                            </a>
                            {modality == 'class' && <a class="btn btn-light-warning font-weight-bolder font-size-sm mt-2" onClick={() => openCancelContract(scheduleSpot.relationships.contract)}>
                              Remover jogador
                            </a>}
                          </div>
                        </div>
                      </>
                    }
                  </div>


                  {/*<div className="row mx-3">
                    <div className="col-12">
                      <div className="row bg-gray-100 py-1 font-weight-bold text-center">
                        <div className="col-6 text-left px-2 text-dark-50">Nome</div>
                        <div className="col-6 text-right">Guilherme Duarte</div>
                      </div>
                      <div className="row bg-white py-1 font-weight-bold text-center">
                        <div className="col-6 text-left px-2 text-dark-50">Serviço</div>
                        <div className="col-6 text-right">Avulso - Beach - Avulso</div>
                      </div>
                      <div className="row bg-gray-100 py-1 font-weight-bold text-center">
                        <div className="col-6 text-left px-2 text-dark-50">Valor</div>
                        <div className="col-6 text-right">R$ 120,00</div>
                      </div>
                      <div className="row bg-white py-1 font-weight-bold text-center">
                        <div className="col-6 text-left px-2 text-dark-50">Tipo de pagamento</div>
                        <div className="col-6 text-right">Na unidade</div>
                      </div>
                      <div className="row bg-gray-100 py-1 font-weight-bold text-center">
                        <div className="col-6 text-left px-2 text-dark-50">Pagamento</div>
                        <div className="col-6 text-right">Não informado</div>
                      </div>
                      <div className="row bg-white py-1 font-weight-bold text-center">
                        <div className="col-6 text-left px-2 text-dark-50">Status do pagamento</div>
                        <div className="col-6 text-right">Pago</div>
                      </div>
                      <div className="row bg-gray-100 py-1 font-weight-bold text-center">
                        <div className="col-6 text-left px-2 text-dark-50">Pago em</div>
                        <div className="col-6 text-right">05/08/2023 02:58</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="card-toolbar">
                      <a class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-2" onClick={() => openEditClient(scheduleSpot.relationships.contract.relationships.client)}>
                        Ver cliente
                      </a>
                      <a class="btn btn-light-success font-weight-bolder font-size-sm mt-2 mr-2" onClick={() => openShowInvoice(scheduleSpot.relationships.contract.relationships.invoices[0])}>
                        {!scheduleSpot.relationships.contract.relationships.invoices[0].attributes.is_paid && "Pagar fatura"}
                        {scheduleSpot.relationships.contract.relationships.invoices[0].attributes.is_paid && "Ver fatura"}
                      </a>
                      {modality == 'class' && <a class="btn btn-light-warning font-weight-bolder font-size-sm mt-2" onClick={() => openCancelContract(scheduleSpot.relationships.contract)}>
                        Remover jogador
                      </a>}
                    </div>
                  </div>*/}


                  <div className="separator separator-dashed my-3 mx-5"></div>
                </>)}
              </>}

              <div class="card-toolbar">
                {canCreate && modality == 'class' && <a class="btn btn-success font-weight-bolder font-size-sm mr-5 mb-1" onClick={() => openClientSchedule()}>
                  Adicionar jogador
                </a>}
                {/*{canCreate && type == 'recurrence' && modality == 'class' && <a class="btn btn-primary font-weight-bolder font-size-sm mb-1" onClick={() => openContractSchedule()}>
                  Associar contrato existente
                </a>}*/}
              </div>
              {schedules.error && <RenderError error={schedules.error.clients} />}
            </div>}

            {modality == 'unavailable' && <div class="col-lg-6">

            </div>}
          </div>}

          {tab == 'calendar' && <div class="row">
            {dates && dates.length > 0 && dates.map((date, index) => <>
              <div class="col-lg-6 offset-lg-3">
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <div class="col-xs-12">
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark-50">
                          {modality == 'class' && 'Aula '}
                          {modality == 'reservation' && 'Partida '}
                          {modality == 'unavailable' && 'Semana '}
                          #{index + 1}
                        </span>
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span className="text-dark">
                        <span className="font-weight-bolder text-dark-50">Data: </span> {moment(date).format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <div class="card-toolbar text-right align-items-right">
                      <a class="btn btn-light-success font-weight-bolder font-size-sm mt-2 ml-20" onClick={() => showScheduleInfo(date)}>
                        {modality == 'class' && 'Ver aula'}
                        {modality == 'reservation' && 'Ver partida'}
                        {modality == 'unavailable' && 'Ver semana'}
                      </a>
                    </div>
                  </Grid>
                </Grid>

                <div className="separator separator-dashed mb-5 mt-1"></div>
              </div>
            </>)}
          </div>}

          {tab == 'logs' && <div class="row">
            <div class="col-lg-6 offset-lg-3">
              <div class="timeline timeline-justified timeline-4 mb-10">
                <div class="timeline-bar"></div>
                <div class="timeline-items">
                  {logsEvents.map(log => <>
                    {log.attributes && <div class="timeline-item">
                      <div class="timeline-badge">
                        <div class={getLogBadge(log.attributes.event)}></div>
                      </div>

                      <div class="timeline-label">
                        <span class="font-weight-bold">{log.attributes.user_name}</span>
                        <span class="text-primary font-weight-bold"> - {moment(log.attributes.created_at).format('DD/MM/YYYY - HH:mm')}</span>
                        <span class="text-dark-75"> - {moment(log.attributes.created_at).locale('pt-br').fromNow()}</span>
                      </div>

                      <div class="timeline-content" style={{whiteSpace: "pre-line"}}>
                        <ReactMarkdown children={log.attributes.message} />
                      </div>
                    </div>}
                  </>)}

                  {logsEvents.length == 0 && <div class="timeline-item">
                    <div class="timeline-badge">
                        <div class="bg-danger"></div>
                      </div>

                      <div class="timeline-label">Indisponível</div>

                      <div class="timeline-content">
                        Nenhum log disponível
                      </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>}

          {tab == 'observations' && <div class="row">

            <div class="col-lg-6">

              <Grid container className={classes.label}>
                <Grid item xs>
                  <span className="font-weight-bolder text-dark">
                    Adicionar anotação
                  </span>
                </Grid>
              </Grid>

              <div className="separator separator-dashed mb-5 mt-1"></div>

              <div className="mb-1 font-weight-bold font-size-sm">Escreve a mensagem que deseja anotar</div>

              <form className="form" autoComplete="off" onSubmit={formikObservation.handleSubmit} variant="outlined">
                <div className="row">
                  <div className="col-xl-12">
                    <TextField
                      name="message"
                      label={'Mensagem'}
                      fullWidth
                      className={classes.textField}
                      variant="outlined"
                      autoComplete="new-password"
                      {...formikObservation.getFieldProps("message")}
                      multiline
                      rows={4}
                      maxRows={6}
                    />

                    {logs.error && <RenderError error={logs.error.message} />}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-xs btn-success font-weight-bolder text-uppercase px-9 py-4 my-3"
                        data-wizard-type="action-next"
                        disabled={(formikObservation.isSubmitting || logs.actionsLoading || formikObservation.values.message.length == 0)}
                        onClick={formikObservation.current?.submitForm}
                      >
                        Adicionar anotação {(formikObservation.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-white"></span>}
                      </button>
                  </div>
                </div>
              </form>
            </div>

            <div class="col-lg-6">
              <div class="timeline timeline-justified timeline-4 my-10">
                <div class="timeline-bar"></div>
                <div class="timeline-items">
                  {observations.map(observation => <>
                    {observation.attributes && <div class="timeline-item">
                      <div class="timeline-badge">
                        <div class={getLogBadge(observation.attributes.event)}></div>
                      </div>

                      <div class="timeline-label">
                        <span class="font-weight-bold">{observation.attributes.user_name}</span>
                        <span class="text-primary font-weight-bold"> - {moment(observation.attributes.created_at).format('DD/MM/YYYY - HH:mm')}</span>
                        <span class="text-dark-75"> - {moment(observation.attributes.created_at).locale('pt-br').fromNow()}</span>
                      </div>

                      <div class="timeline-content" style={{whiteSpace: "pre-line"}}>
                        {observation.attributes.message}
                      </div>
                    </div>}
                  </>)}

                  {observations.length == 0 && <div class="timeline-item">
                    <div class="timeline-badge">
                        <div class="bg-danger"></div>
                      </div>

                      <div class="timeline-label">Indisponível</div>

                      <div class="timeline-content">
                        Nenhuma anotação disponível
                      </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </form>
  </Modal>

  <CreateClient show={createEntityShow} onHide={() => closeCreateClient()} />

  <CreateClientSchedule
    startDateAt={event && event.attributes && event.attributes.start_at}
    endDateAt={event && event.attributes && type == 'recurrence' && event.attributes.recurrence_finish_at}
    weekdays={weekdays}
    show={clientScheduleShow}
    selectClient={selectClient}
    selectContract={selectContract}
    onHide={() => closeClientSchedule()}
    style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
    modality={modality}
    type={type}
    fieldId={event && event.relationships && event.relationships.field && event.relationships.field.id}
    duration={event && event.attributes && event.attributes.start_at && moment(event.attributes.finish_at).diff(moment(event.attributes.start_at), 'minutes')}
  />

  <SelectParticipant
    show={participantScheduleShow}
    selectClient={selectParticipant}
    onHide={() => setParticipantScheduleShow(false)}
    style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
  />

  <CancelContract
    contract={cancelContract}
    show={cancelContractShow}
    date={event && event.attributes && event.attributes.start_at}
    onHide={() => closeCancelContract()}
  />

  <CancelSchedule
    schedule={event}
    show={cancelScheduleShow}
    onHide={() => closeCancelSchedule()}
  />

  <MoveSchedule
    schedule={event}
    show={moveScheduleShow}
    onHide={() => closeMoveSchedule()}
  />

  <SelectContract
    modality={modality}
    field_id={event && event.relationships && event.relationships.field && event.relationships.field.id}
    startDateAt={event && event.attributes && event.attributes.start_at}
    endDateAt={event && event.attributes && type == 'recurrence' && event.attributes.recurrence_finish_at}
    weekdays={weekdays}
    show={contractScheduleShow}
    onHide={() => closeContractSchedule()}
    selectContract={selectContract}
  />

  <InvoicePage show={invoicePageShow} onHide={() => closeInvoice()} contract={selectedContract} displayName={type == 'single' || type == 'unavailable'} />

  <ShowInvoice show={showInvoiceShow} onHide={() => closeShowInvoice()} invoice={selectedInvoice} />

  <EditClient show={editClientShow} onHide={closeEditClient} event={editClient} type={type} />
  </>);
};

export default EditSchedule;
