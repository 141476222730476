import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import moment from 'moment/min/moment-with-locales';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import { lighten, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';

import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers";
import { formatMoney } from "../../../utils/IntegerParse";

import DayPicker from '../../../components/DayPicker';
import SearchClient from "../../Client/components/SearchClient";
import InvoicePage from "../../Invoice/pages/InvoicePage";
import EditSchedule from "../../Calendar/components/EditSchedule";

import { fetchGetContracts } from "../_redux/contractActions";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

export const ContractList = (props) => {
  const { contracts, branchSelected } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
    contracts: state.contracts,
  }), shallowEqual);

  const classes = useStyles();

  const dispatch = useDispatch();
  const location = useLocation();

  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState('active');
  const [originType, setOriginType] = useState('');
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [clientId, setClientId] = useState('');
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('client_name');
  const [sort, setSort] = useState('client_name');
  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY'));
  const [editEventShow, setEditEventShow] = useState(false);
  const [editedEvent, setEditedEvent] = useState({});

  // useEffect(() => {

  // }, [location.pathname]);

  useEffect(() => {
    requestEntities();
  }, [status, branchSelected, clientId, originType, year, month]);

  // useEffect(() => {
  //   if (contracts.entities) {
  //     setRows(contracts.entities.data);
  //   }
  // }, [contracts]);

  const checkLocation = (value) => {
    if (value == 'reservation-single') {
      return checkIsActive(location, '/contratos/locacoes');
    }
    if (value == 'reservation-recurrence') {
      return checkIsActive(location, '/contratos/mensalistas');
    }
    if (value == 'class-single') {
      return checkIsActive(location, '/contratos/aulas');
    }
    if (value == 'class-recurrence') {
      return checkIsActive(location, '/contratos/alunos');
    }
  }

  const requestEntities = async () => {
    try {
      let params = {
        perPage: rowsPerPage,
        page: page + 1,
        statusId: status,
        branch_id: branchSelected.id,
        client_id: clientId,
        // month_start_at: '2023-08-01',
        // finish_at: '2023-08-30',
        // originType: originType
      };

      if (checkLocation('reservation-single')) {
        params.modality = 'reservation';
        params.type = 'single';

        if (status != 'active') {
          params.month_start_at = moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD');
        }
      }
      if (checkLocation('reservation-recurrence')) {
        params.modality = 'reservation';
        params.type = 'recurrence';
      }
      if (checkLocation('class-single')) {
        params.modality = 'class';
        params.type = 'single';

        if (status != 'active') {
          params.month_start_at = moment(`${year}-${month}`).startOf('month').format('YYYY-MM-DD');
        }
      }
      if (checkLocation('class-recurrence')) {
        params.modality = 'class';
        params.type = 'recurrence';
      }

      dispatch(fetchGetContracts(params)).then((value) => {
        setRows(value);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const closeCreateEntity = () => {
    {createEntityShow &&
      requestEntities();
    };

    setCreateEntityShow(false);
  };

  const openEditEntity = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }

  const closeEditEntity = () => {
    {editEntityShow &&
      requestEntities();
    };

    setEditEntityShow(false);
  }

  const openEditEvent = (event) => {
    setEditedEvent({event: event.relationships.schedule_spot[0].relationships.schedule});

    setEditEventShow(true);
  }

  const closeEditEvent = () => {
    setEditedEvent({});
    setEditEventShow(false);
  }

  const getLabelDate = (day) => {
    if (day <= 3) {
      return 'danger';
    }

    if (day > 3 && day <= 10) {
      return 'warning';
    }

    return 'primary';
  }

  const getLabelStatus = (value) => {
    if (value.attributes.is_active && value.relationships.status.attributes.name == 'active') {
      return 'Ativo';
    }

    if (!value.attributes.is_active && value.relationships.status.attributes.name == 'active') {
      return 'Finalizado';
    }

    if (value.relationships.status.attributes.name == 'canceled') {
      return 'Cancelado';
    }

    return '';
  }

  const getColorStatus = (value) => {
    if (value.attributes.is_active && value.relationships.status.attributes.name == 'active') {
      return 'success';
    }

    if (!value.attributes.is_active && value.relationships.status.attributes.name == 'active') {
      return 'default';
    }

    if (value.relationships.status.attributes.name == 'canceled') {
      return 'danger';
    }

    return 'primary';
  }

  const getPlanLabel = (value) => {
    if (value == 'monthly') { return 'Mensal' };
    if (value == 'quarterly') { return 'Trimestral' };
    if (value == 'semester') { return 'Semestral' };
    if (value == 'yearly') { return 'Anual' };

    return '';
  }

  const selectClient = (value) => {
    setPage(0);
    setClientId(value);
  }

  const handleRequestSort = (property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    setSort(isDesc ? property : `-${property}`);
  }

  const headRows = [
    { id: 'client_name', ordered: true, disablePadding: true, label: 'Nome' },
    { id: 'origin.name', ordered: false, disablePadding: true, label: 'Serviço / Plano' },
    { id: 'origin_type', ordered: false, disablePadding: false, label: 'Valor' },
    { id: 'finish_at', ordered: true, disablePadding: false, label: 'Vigência' },
    { id: 'status', ordered: false, disablePadding: false, label: 'Status' },
    { id: 'actions', ordered: false, disablePadding: false, label: '' },
  ];

  return (<>
    <div class="d-flex">
      <div class="col-6">
        <h3 class="card-title">Contratos de locação</h3>
      </div>

      {/*<div class="col-6 text-right">
        <a href="#" className="btn btn-light-success font-weight-bold uppercase" onClick={() => setCreateEntityShow(true)}>
          <i className="ki ki-plus "></i> Nova venda
        </a>
      </div>*/}
    </div>

    <div className="separator separator-dashed mt-1 mb-10"></div>

    <div class="row">
      <div class="col-lg-12">
        <SearchClient onChange={(value) => selectClient(value)} />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3">
        {(checkLocation('reservation-single') || checkLocation('class-single')) && status != 'active' && <div className="form-group">
          <select
            class="custom-select form-control form-control-solid form-control-lg"
            value={month}
            onChange={(value) => setMonth(value.target.value)}
          >
            <option value="01">Janeiro</option>
            <option value="02">Fevereiro</option>
            <option value="03">Março</option>
            <option value="04">Abril</option>
            <option value="05">Maio</option>
            <option value="06">Junho</option>
            <option value="07">Julho</option>
            <option value="08">Agosto</option>
            <option value="09">Setembro</option>
            <option value="10">Outubro</option>
            <option value="11">Novembro</option>
            <option value="12">Dezembro</option>
          </select>
          <span className="form-text text-muted">Mês de início</span>
        </div>}
      </div>
      <div className="col-lg-3">
        {(checkLocation('reservation-single') || checkLocation('class-single')) && status != 'active' && <div className="form-group">
          <select
            class="custom-select form-control form-control-solid form-control-lg"
            value={year}
            onChange={(value) => setYear(value.target.value)}
          >
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
          <span className="form-text text-muted">Ano de início</span>
        </div>}
      </div>
      <div class="col-lg-6 pb-5">
        <ul class="nav nav-pills nav-pills-sm nav-dark-75 float-right pr-0" role="tablist">
          {/*<li class="nav-item">
            <a class={status == '' ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setStatus('')}>
              <span class="nav-text font-size-sm">Todos</span>
            </a>
          </li>*/}
          <li class="nav-item">
            <a class={status == 'active' ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setStatus('active')}>
              <span class="nav-text font-size-sm">Ativos</span>
            </a>
          </li>
          <li class="nav-item">
            <a class={status == 'confirmed' ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setStatus('confirmed')}>
              <span class="nav-text font-size-sm">Finalizados</span>
            </a>
          </li>
          <li class="nav-item">
            <a class={status == 'canceled' ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setStatus('canceled')}>
              <span class="nav-text font-size-sm">Cancelados</span>
            </a>
          </li>
          {/*<li class="nav-item">
            <a class={status == 'canceled' ? "nav-link py-2 px-4 active" : "nav-link py-2 px-4"} data-toggle="tab" onClick={() => setStatus('canceled')}>
              <span class="nav-text font-size-sm">Cancelados</span>
            </a>
          </li>*/}
        </ul>
      </div>
      </div>

    {/*<div class="row pb-3">
      <div class="col-lg-12 align-self-end">

      </div>
    </div>*/}

    <div className={classes.tableWrapper}>
      <div className="table-responsive">
        <Table
          className="table table-head-custom table-head-bg table-borderless table-vertical-center"
          aria-labelledby="tableTitle"
          size="large"
        >
          <TableHead>
            <TableRow>
              {headRows.map(row => (
                <TableCell
                  key={row.id}
                  align="left"
                  padding={row.disablePadding ? 'none' : 'default'}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={() => handleRequestSort(row.id)}
                    disabled={!row.ordered}
                  >
                    <span style={{
                      color: "#B5B5C3",
                      textTransform: "uppercase"
                    }}>
                    {row.label}
                    </span>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows && rows.map((row, index) => {
              return <TableRow
                tabIndex={-1}
                key={'contractos-' + row.id}
              >
                <TableCell component="th" scope="row" padding="none">
                  {row.relationships.client.attributes.name}
                </TableCell>
                <TableCell align="left">
                  {row.relationships.service.attributes.name}

                  {row.relationships.service.attributes.type == 'recurrence' && row.attributes.available_spots > 0 && <span style={{
                    color: "#59ba32",
                    textTransform: "uppercase"
                  }}>
                    <br />
                    {row.attributes.available_spots == 1 ? `1 slot disponível` : `${row.attributes.available_spots} slots disponíveis`}
                  </span>}
                </TableCell>
                <TableCell align="left">
                  {formatMoney(row.attributes.price)}
                  <span style={{
                    color: "#B5B5C3"
                  }}>
                    <br />{getPlanLabel(row.attributes.plan)}
                  </span>
                </TableCell>
                <TableCell align="left">
                  {row.attributes.start_at && <span style={{
                    color: row.attributes.finish_at && row.relationships.service.attributes.type == 'recurrence' ? "#B5B5C3" : '#000'
                  }}>
                    {moment(row.attributes.start_at).format('DD/MM/YYYY')}
                  </span>}
                  {row.relationships.service.attributes.type == 'recurrence' && <span style={{
                    color: !row.attributes.finish_at ? "#B5B5C3" : '#000'
                  }}>
                    <br />{!row.attributes.finish_at && `-`}
                    {row.attributes.finish_at && moment(row.attributes.finish_at).format('DD/MM/YYYY')}
                  </span>}
                </TableCell>
                <TableCell align="left">
                  <span className={`label label-lg label-light-${getColorStatus(row)} label-inline`} style={{minWidth: 'max-content'}}>
                    {getLabelStatus(row)}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <div class="card-toolbar">
                    {status != 'canceled' && <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="user-notification-tooltip">
                          Ver agendamento
                        </Tooltip>
                      }
                    >
                      <a
                        title="Ver agendamento"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1 my-1"
                        onClick={() => openEditEvent(row)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            title="Ver Agendamento"
                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                          />
                        </span>
                      </a>
                    </OverlayTrigger>}

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="user-notification-tooltip">
                          Ver contrato
                        </Tooltip>
                      }
                    >
                      <a
                        title="Editar contrato"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1 my-1"
                        onClick={() => openEditEntity(row)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            title="Editar contrato"
                            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                          />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </div>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </div>
    </div>

    <div className="d-flex align-items-center py-3">
      {contracts.listLoading && <div className="d-flex align-items-center">
        <div className="mr-2 text-muted">Carregando...</div>
        <div className="spinner spinner-primary mr-10"></div>
      </div>}

      <TablePagination
        labelRowsPerPage="Por página"
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={contracts.totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Página anterior',
        }}
        nextIconButtonProps={{
          'aria-label': 'Próxima Página',
        }}
        onChangePage={(event, newPage) => setPage(newPage)}
        onChangeRowsPerPage={(event) => setRowsPerPage(+event.target.value)}
        labelDisplayedRows={(page) =>
          `${page.from} até ${page.to === -1 ? page.count : page.to} total de ${
            page.count
          }`
        }
      />
    </div>

    <InvoicePage show={editEntityShow} onHide={() => closeEditEntity()} contract={editEntity} displayName={true} />
    <EditSchedule show={editEventShow} onHide={closeEditEvent} event={editedEvent} />
  </>);
}
