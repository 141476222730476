import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entityForEdit: undefined,
  priceService: [],
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const priceSlice = createSlice({
  name: "prices",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error.response &&
          action.payload.error.response.data &&
          action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    priceFetched: (state, action) => {
      state.actionsLoading = false;
      state.entityForEdit = action.payload.entityForEdit;
      state.error = null;
      state.created = false;
      state.updated = false;
    },

    priceServiceFetched: (state, action) => {
      state.actionsLoading = false;
      state.priceService = action.payload.data;
      state.error = null;
      state.created = false;
      state.updated = false;
    },

    pricesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.created = false;
      state.updated = false;
      state.totalCount = totalCount;
    },

    priceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.created = true;
    },

    priceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.updated = true;
    },

    priceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    pricesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },

    pricesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
