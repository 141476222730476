import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

import ListGroup from 'react-bootstrap/ListGroup';
import Overlay from 'react-bootstrap/Overlay';

import ControlPoint from '@material-ui/icons/ControlPoint';
import Settings from '@material-ui/icons/Settings';
import DeleteForever from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';

import { fetchPriceService } from "../../Price/_redux/priceActions";
import { fetchClientPhone, fetchClientName } from "../../Client/_redux/clientActions";
import { fetchCreateSchedule } from "../_redux/scheduleActions";

import { createScheduleSchema } from "../_schemas/createScheduleSchema";

import Snackbar from "../../../components/Snackbar";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import { RenderError } from "../../../components/RenderError";

import CreateClient from "../../Client/components/CreateClient";
import CreateClientSchedule from "./CreateClientSchedule";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
  detailedExpansionPanel: {
    marginBottom: 5,
    boxShadow: 'none !important'
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

const CreateEvent = (props) => {
  const dispatch = useDispatch();
  const { fields, prices, clients, schedules } = useSelector((state) => ({
    fields: state.fields,
    prices: state.prices,
    clients: state.clients,
    schedules: state.schedules,
  }), shallowEqual);

  const classes = useStyles();

  const [modality, setModality] = useState('');
  const [startDateAt, setStartDateAt] = useState();
  const [startAt, setStartAt] = useState();
  const [endDateAt, setEndDateAt] = useState();
  const [endAt, setEndAt] = useState();
  const [services, setServices] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedService, setSelectedService] = useState({});
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [type, setType] = useState('');
  const [showClient, setShowClient] = useState(false);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [clientScheduleShow, setClientScheduleShow] = useState(false);
  const [playerScheduleShow, setPlayerScheduleShow] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('-');
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [weekdays, setWeekdays] = useState([]);
  const target = useRef(null);

  useEffect(() => {
    formik.setFieldValue('price', parseInt(prices.priceService).toLocaleString('pt-br', { minimumFractionDigits: 2 }), false);
  }, [prices.priceService]);

  useEffect(() => {
    if (schedules.created == true) {
      close();
      setOpenSuccessMessage(true);
    }
  }, [schedules]);

  useEffect(() => {
    setType('single');

    formik.setFieldValue('startDateAt', moment(props.event.start).format('YYYY-MM-DD'), false);
    formik.setFieldValue('endDateAt', moment(props.event.end).format('YYYY-MM-DD'), false);

    let startAt = moment(props.event.start);

    if (!props.event.start) {
      const remainder = 30 - (startAt.minute() % 30);

      startAt = moment().add(remainder, 'minutes');
    }

    let endAt = moment(props.event.end);

    if (!props.event.end) {
      endAt = moment(startAt).add(1, 'hours');
    }

    formik.setFieldValue('startAt', startAt.format('HH:mm'), false);
    formik.setFieldValue('endAt', endAt.format('HH:mm'), false);

    formik.setFieldValue('statusId', status[0].value, false);

    let fieldFilter = fields.entities.find(element => {
      return element.id === props.event.resourceId;
    });

    if (fieldFilter) {
      formik.setFieldValue('fieldId', fieldFilter.id, false);

      if (fieldFilter.relationships.services.length) {
        formik.setFieldValue('serviceId', fieldFilter.relationships.services[0].id, false);
      }
    }

    convertWeekday(moment(props.event.start).weekday());
  }, [props.event]);

  const status = [
    { label: 'Pendente', value: 'pending' },
    { label: 'Pago', value: 'paid' },
    { label: 'Cancelado', value: 'canceled' }
  ];

  const close = () => {
    props.onHide();

    setSelectedService({});
    setSelectedClient({});
    setSelectedClients([]);
    setSelectedPlayers([]);
    setShowClient(false);

    formik.setFieldValue('fieldId', '', false);
    formik.setFieldValue('serviceId', '', false);
    formik.setFieldValue('clientId', '', false);
    formik.setFieldValue('mobile', '', false);
    formik.setFieldValue('name', '', false);
    formik.setFieldValue('email', '', false);
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const selectedWeekDayColor = (weekday) => {
    return weekdays.includes(weekday) ? "primary" : "default";
  }

  const selectWeekDay = (weekday) => {
    if(!weekdays.includes(weekday)) {
      if (selectedService.attributes && weekdays.length < selectedService.attributes.recurrence_times) {
        setWeekdays([...weekdays, weekday]);
      }

      if (selectedService.attributes && selectedService.attributes.recurrence_times == 1) {
        setWeekdays([weekday]);
      }
    } else{
        var array = [...weekdays];
        var index = array.indexOf(weekday);

        if (index !== -1) {
          array.splice(index, 1);

          setWeekdays(array);
        }
    }
  }

  const selectClients = (client) => {
    setSelectedClients([...selectedClients, client]);
    closeClientSchedule();
  }

  const selectPlayers = (client) => {
    setSelectedPlayers([...selectedPlayers, client]);
    closePlayersSchedule();
  }

  const clearSelectClient = () => {
    setSelectedClient({});
    formik.setFieldValue('clientId', '', false);
  }

  const typeClass = (item) => {
    return type == item ? "contained" : "outlined";
  };

  const searchClientName = () => {
    if (formik.values.name && formik.values.name.length >= 2) {
      const clientPhoneTimeout = setTimeout(() => {
        dispatch(fetchClientName(formik.values.name));
        setShowClient(true);
      }, 1000);

      return () => {
        clearTimeout(clientPhoneTimeout);
      };
    }
  }

  const closeCreateClient = () => {
    setCreateEntityShow(false);

    return searchClientName();
  }

  const closeClientSchedule = () => {
    setClientScheduleShow(false);

    // return searchClientName();
  }

  const closePlayersSchedule = () => {
    setPlayerScheduleShow(false);
  }

  const mapClients = () => {
    return selectedClients.map(client => {
      let data = {
        id: client.id
      };

      if (selectedService.attributes && selectedService.attributes.type == 'recurrence') {
        data.recurrence_days = client.attributes.recurrence_days;
        data.start_at = client.attributes.start_at;
        data.finish_at = client.attributes.finish_at;
        data.billing_day = client.attributes.billing_day;
      }

      return data;
    });
  }

  const mapPlayers = () => {
    return selectedPlayers.map(client => {
      return {
        id: client.id,
      }
    });
  }

  const openClientSchedule = () => {
    if (selectedService && selectedService.attributes && selectedService.id) {
      setClientScheduleShow(true);
    } else {
      setErrorMessage('Selecione um serviço para continuar');
      setOpenErrorMessage(true);
    }
  }

  const removeSelectedClients = (client) => {
    if (selectedClients.includes(client)) {
      setSelectedClients(selectedClients.filter(arrayClient => arrayClient.id !== client.id));
    }
  }

  const removeSelectedPlayers = (client) => {
    if (selectedPlayers.includes(client)) {
      setSelectedPlayers(selectedPlayers.filter(arrayClient => arrayClient.id !== client.id));
    }
  }

  const formik = useFormik({
    initialValues: {
      recurrenceFinishAt: 'infinite',
      startDateAt: '',
      startAt: '',
      endDateAt: '',
      endAt: '',
      fieldId: '',
      price: '',
      serviceId: '',
      statusId: '',
    },
    validationSchema: createScheduleSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        if (selectedService.attributes && selectedService.attributes.type == 'recurrence') {
          values.recurrence_start_at = values.startDateAt;

          if (values.recurrenceFinishAt != 'infinite') {
            values.recurrence_finish_at = moment(values.startDateAt).add(values.recurrenceFinishAt, 'months').format('YYYY-MM-DD')
          }

          values.recurrence_days = weekdays.reduce((x, y) => x + y, 0)
        }

        values.clients = mapClients();
        values.participants = mapPlayers();

        dispatch(fetchCreateSchedule(values.fieldId, values));

        setSubmitting(false);
      }, 500);
    },
  });

  useEffect(() => {
    if(prices.actionsLoading == false) {
      if (formik.values.fieldId && formik.values.serviceId) {
        const priceTimeout = setTimeout(() => {
          dispatch(fetchPriceService(formik.values.serviceId, `${formik.values.startDateAt} ${formik.values.startAt}`, `${formik.values.endDateAt} ${formik.values.endAt}`))
        }, 1000);

        return () => {
          clearTimeout(priceTimeout);
        };
      }
    }
  }, [
    formik.values.serviceId,
    formik.values.startDateAt,
    formik.values.startAt,
    formik.values.endDateAt,
    formik.values.endAt,
    weekdays
  ]);

  useEffect(() => {
    if (formik.values.serviceId) {
      var filtered = services.filter((service) => service.id === formik.values.serviceId);

      if (filtered[0]) {
        setSelectedService(filtered[0]);
      }
    } else {
      setSelectedService(services[0]);
    }
  }, [services, formik.values.serviceId]);

  const convertWeekday = (value) => {
    let values = [
      1,
      2,
      4,
      8,
      16,
      32,
      64,
    ];

    return setWeekdays([values[value]]);
  }

  useEffect(() => {
    formik.setFieldValue('endDateAt', formik.values.startDateAt, false);
    convertWeekday(moment(formik.values.startDateAt).weekday());
  }, [formik.values.startDateAt]);

  useEffect(() => {
    if (formik.values.fieldId) {
      let fieldFilter = fields.entities.find(element => {
        return element.id === formik.values.fieldId;
      });

      if (fieldFilter) {
        let serviceFiltered = fieldFilter.relationships.services.filter(service => {
          if (service.relationships.status.attributes.name === 'active') {
            if (type == 'single') {
              return service.attributes.type == 'single'
            }

            if (type == 'recurrence') {
              return service.attributes.type == 'recurrence'
            }

            if (type == 'unavailable') {
              return service.attributes.type == 'unavailable'
            }
          }

          return false;
        });

        setServices(serviceFiltered);

        if (serviceFiltered[0]) {
          formik.setFieldValue('serviceId', serviceFiltered[0].id, false);
        } else {
          formik.setFieldValue('serviceId', '', false);
        }

        setSelectedClients([]);
      }
    }
  }, [formik.values.fieldId, type]);

  useEffect(() => {
    if (formik.values.statusId) {
      setSelectedStatus(formik.values.statusId);
    }
  }, [formik.values.statusId]);

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);

  const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
      <a
        ref={ref}
        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        onClick={e => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        {props.children}
      </a>
    );
  });

  return (<>
  <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    keyboard={true}
    show={props.show && !createEntityShow}
  >
    <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
      <Modal.Body>
        <Container maxWidth="sm">
          <Grid container className={classes.label}>
            <Grid item xs>
              <span className="font-weight-bolder text-dark">
                Tipo agendamento <Icon className={classes.icon}>info_outlined</Icon>
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                label="Campo"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    }
                  }
                }}
                name="fieldId"
                fullWidth
                className={classes.textField}
                {...formik.getFieldProps("fieldId")}
              >
                {fields.entities.map((item) => <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>)}
              </TextField>
              {formik.touched.fieldId && formik.errors.fieldId ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.fieldId}</div>
                </div>
              ) : null}
            </Grid>
          </Grid>
          <Grid container className={classes.label} spacing={2}>
            <Grid item xs>
              <Button fullWidth variant={typeClass('single')} onClick={() => setType('single')} color="primary" size="large">
                Avulso
              </Button>
            </Grid>
            <Grid item xs>
              <Button fullWidth variant={typeClass('recurrence')} onClick={() => setType('recurrence')} color="primary" size="large">
                Mensalista
              </Button>
            </Grid>
            <Grid item xs>
              <Button fullWidth variant={typeClass('unavailable')} onClick={() => setType('unavailable')} color="primary" size="large">
                Indisponível
              </Button>
            </Grid>
          </Grid>

          <Grid container className={classes.label} spacing={2}>
            {services.length == 0 && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    Nenhum serviço associado para campo selecionado.
                  </span>
                </div>
              </div>
            </Grid>}

            <Grid item xs={12} style={{ display: ((services.length > 0) ? 'block' : 'none')}}>
              <TextField
                select
                label="Serviço"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    }
                  }
                }}
                name="serviceId"
                fullWidth
                className={classes.textField}
                {...formik.getFieldProps("serviceId")}
              >
                {services.map((item) => <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>)}
              </TextField>

              {schedules.error && <RenderError error={schedules.error.service_id} />}
            </Grid>
          </Grid>

          <Grid container className={classes.label}>
            <Grid item xs>
              <span className="font-weight-bolder text-dark">
                Agendamento <Icon className={classes.icon}>info_outlined</Icon>
              </span>
            </Grid>
          </Grid>

          <Grid container className={classes.label} spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="startDateAt"
                label={type == 'single' || type == 'unavailable' ? 'Data Inicio' : 'Inicio contrato'}
                type="date"
                fullWidth
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("startDateAt")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={startAt}
                name="startAt"
                label="Horario Inicio"
                type="time"
                fullWidth
                step={15}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: "900", // 15 min
                }}
                {...formik.getFieldProps("startAt")}
              />
            </Grid>

            {prices.error && <RenderError error={prices.error.start_at} />}
            {schedules.error && <RenderError error={schedules.error.start_at} />}

            {(type == 'single' || type == 'unavailable') && <Grid item xs={6}>
              <TextField
                value={endDateAt}
                name="endDateAt"
                label="Data fim"
                type="date"
                fullWidth
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("endDateAt")}
              />
            </Grid>}

            {(type == 'recurrence') && <Grid item xs={6}>
              <TextField
                select
                label="Prazo contrato"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    }
                  }
                }}
                name="recurrenceFinishAt"
                fullWidth
                className={classes.textField}
                {...formik.getFieldProps("recurrenceFinishAt")}
              >
                <MenuItem key="infinite" value="infinite">Indeterminado</MenuItem>
                <MenuItem key="3" value="3">3 meses</MenuItem>
                <MenuItem key="6" value="6">6 meses</MenuItem>
                <MenuItem key="12" value="12">1 ano</MenuItem>
              </TextField>

              {schedules.error && <RenderError error={schedules.error.recurrence_start_at} />}
            </Grid>}

            <Grid item xs={6}>
              <TextField
                value={endAt}
                name="endAt"
                label="Horario Fim"
                type="time"
                fullWidth
                step={15}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: "900", // 15 min
                }}
                {...formik.getFieldProps("endAt")}
              />
            </Grid>

            {prices.error && <RenderError error={prices.error.end_at} />}
            {schedules.error && <RenderError error={schedules.error.end_at} />}

            <Grid item xs={6}>
              <TextField
                name="price"
                label={`Preço ${(type == 'recurrence') ? 'mensal' : 'total'}`}
                fullWidth
                className={classes.textField}
                variant="outlined"
                readOnly={true}
                disabled={true}
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  endAdornment: (
                    <InputAdornment position="end">
                      {prices.actionsLoading && <CircularProgress size={30} />}
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("price")}
              />
            </Grid>

            {/*{(type == 'single') && <Grid item xs={6}>
              <TextField
                select
                label="Status do pagamento"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    }
                  }
                }}
                name="fieldId"
                fullWidth
                className={classes.textField}
                {...formik.getFieldProps("statusId")}
              >
                {status.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
              </TextField>

              {schedules.error && <RenderError error={schedules.error.status_id} />}
            </Grid>}*/}
          </Grid>

          {(type == 'recurrence' && selectedService && selectedService.attributes) && <Grid container className={classes.label}>
            <Grid item xs={12}>
              <span className="font-weight-bolder text-dark">
                Selecione {selectedService.attributes.recurrence_times} {selectedService.attributes.recurrence_times > 1 ? 'dias' : 'dia'} da semana
              </span>
            </Grid>

            <Grid container className={classes.label} spacing={1}>
              <Grid item xs><Chip onClick={() => selectWeekDay(2)} clickable color={selectedWeekDayColor(2)} variant="outlined" label="Seg" /></Grid>
              <Grid item xs><Chip onClick={() => selectWeekDay(4)} clickable color={selectedWeekDayColor(4)} variant="outlined" label="Ter" /></Grid>
              <Grid item xs><Chip onClick={() => selectWeekDay(8)} clickable color={selectedWeekDayColor(8)} variant="outlined" label="Qua" /></Grid>
              <Grid item xs><Chip onClick={() => selectWeekDay(16)} clickable color={selectedWeekDayColor(16)} variant="outlined" label="Qui" /></Grid>
              <Grid item xs><Chip onClick={() => selectWeekDay(32)} clickable color={selectedWeekDayColor(32)} variant="outlined" label="Sex" /></Grid>
              <Grid item xs><Chip onClick={() => selectWeekDay(64)} clickable color={selectedWeekDayColor(64)} variant="outlined" label="Sab" /></Grid>
              <Grid item xs><Chip onClick={() => selectWeekDay(1)} clickable color={selectedWeekDayColor(1)} variant="outlined" label="Dom" /></Grid>
            </Grid>
          </Grid>}

          <Grid container className={classes.label}>
            <Grid item xs={8}>
              <span className="card-title font-weight-bolder text-dark">
                Dados do responsável
              </span>
            </Grid>

            <Grid item xs={4}>
              <a href="#" className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                <ControlPoint fontSize="inherit" /> Cadastrar cliente
              </a>
            </Grid>
          </Grid>

          <Grid container className={classes.label} spacing={2}>
            <Grid item xs={12}>
              <ListGroup>
                {selectedClients.map(client => <>
                  <ListGroup.Item>
                    <Grid container>
                      <Grid item xs={9} fontSize="large">
                        <Chip
                          size="small"
                          color={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'default' : 'primary' }
                          label={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'Cliente' : 'Professor' }
                          variant="outlined"
                          style={{
                            marginRight: 5,
                          }}
                        />
                        {client.attributes.name}
                      </Grid>
                      <Grid item xs={3} style={{textAlign: 'right'}}>
                        {/*{selectedService.attributes && selectedService.attributes.type == 'recurrence' &&
                          <Settings fontSize="medium" className={classes.icon} onClick={() => console.log(client)} />
                        }*/}

                        <Dropdown className="dropdown-inline" drop="down" alignRight>
                          <Dropdown.Toggle
                              as={DropdownCustomToggler}
                          id="dropdown-toggle-top">
                            <i className="ki ki-bold-more-hor" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <ul className="navi navi-hover py-5">
                              <li className="navi-item" onClick={() => removeSelectedClients(client)}>
                                  <a className="navi-link">
                                      <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                      <span className="navi-text">Remover</span>
                                  </a>
                              </li>
                          </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Grid>
                    </Grid>
                  </ListGroup.Item>
                </>)}
                <ListGroup.Item as="a" action style={{textAlign: 'center'}} onClick={() => openClientSchedule()}>
                  <ControlPoint fontSize="inherit" /> Associar cliente
                </ListGroup.Item>
              </ListGroup>

              {schedules.error && <RenderError error={schedules.error.clients} />}
            </Grid>
          </Grid>

          <Grid container className={classes.label}>
            <Grid item xs={12}>
              <span className="card-title font-weight-bolder text-dark">
                Convidados
              </span>
            </Grid>
          </Grid>

          <Grid container className={classes.label} spacing={2}>
            <Grid item xs={12}>
              <ListGroup>
                {selectedPlayers.map(client => <>
                  <ListGroup.Item>
                    <Grid container>
                      <Grid item xs={9} fontSize="large">
                        <Chip
                          size="small"
                          color={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'default' : 'primary' }
                          label={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'Cliente' : 'Professor' }
                          variant="outlined"
                          style={{
                            marginRight: 5,
                          }}
                        />
                        {client.attributes.name}
                      </Grid>
                      <Grid item xs={3} style={{textAlign: 'right'}}>
                        {/*<DeleteForever fontSize="medium" className={classes.icon} onClick={() => console.log(client)} />*/}

                        <Dropdown className="dropdown-inline" drop="down" alignRight>
                          <Dropdown.Toggle
                              as={DropdownCustomToggler}
                          id="dropdown-toggle-top">
                            <i className="ki ki-bold-more-hor" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <ul className="navi navi-hover py-5">
                              <li className="navi-item" onClick={() => removeSelectedPlayers(client)}>
                                  <a className="navi-link">
                                      <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                      <span className="navi-text">Remover</span>
                                  </a>
                              </li>
                          </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Grid>
                    </Grid>
                  </ListGroup.Item>
                </>)}
                <ListGroup.Item as="a" action style={{textAlign: 'center'}} onClick={() => setPlayerScheduleShow(true)}>
                  <ControlPoint fontSize="inherit" /> Convidar
                </ListGroup.Item>
              </ListGroup>
            </Grid>
          </Grid>

          {(typeof schedules.error) == "string" && <Grid item xs={12}>
            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                  {schedules.error}
                </span>
              </div>
            </div>
          </Grid>}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Grid container spacing={3}>
          <Grid item xs style={{textAlign: 'right'}}>
            <Button type="submit" variant="outlined" color="primary" size="large" disabled={(formik.isSubmitting || services.length == 0 || !selectedService)} onClick={formik.current?.submitForm}>
              AGENDAR {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
            </Button>
          </Grid>
          <Grid item xs style={{textAlign: 'left'}}>
            <Button size="large" onClick={close}>
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Modal.Footer>
    </form>
  </Modal>

  <CreateClient show={createEntityShow} onHide={() => closeCreateClient()} />{/*style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}*/}

  <CreateClientSchedule
    service={selectedService}
    startDateAt={formik.values.startDateAt}
    weekdays={weekdays}
    show={clientScheduleShow}
    selectClient={selectClients}
    onHide={() => closeClientSchedule()}
    style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
  />

  <CreateClientSchedule
    service={{}}
    show={playerScheduleShow}
    selectClient={selectPlayers}
    onHide={() => closePlayersSchedule()}
    style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
  />

  <Snackbar
    duration={5000}
    message="Agendamento realizado com sucesso"
    type="success"
    open={openSuccessMessage}
    close={() => setOpenSuccessMessage(false)}
  />

  <Snackbar
    duration={3000}
    message={errorMessage}
    type="warning"
    open={openErrorMessage}
    close={() => setOpenErrorMessage(false)}
  />
  </>);
};

export default CreateEvent;