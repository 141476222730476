import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  balance: undefined,
  operations: [],
  payables: [],
  withdrawals: [],
  lastError: null,
  created: false,
  updated: false
};
export const callTypes = {
  action: "action",
  balance: "balance",
  operations: "operations",
  payables: "payables",
  withdrawals: "withdrawals",
};

export const withdrawalSlice = createSlice({
  name: "withdrawals",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;

      if (action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.balance) {
        state.balanceLoading = true;
      }
      if (action.payload.callType === callTypes.operations) {
        state.operationsLoading = true;
      }
      if (action.payload.callType === callTypes.payables) {
        state.payablesLoading = true;
      }
      if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      }
    },

    balanceFetched: (state, action) => {
      state.balanceLoading = false;
      state.error = null;
      state.balance = action.payload.data;
      state.created = false;
      state.updated = false;
    },

    operationsFetched: (state, action) => {
      state.operationsLoading = false;
      state.error = null;
      state.operations = action.payload.data;
      state.created = false;
      state.updated = false;
    },

    payablesFetched: (state, action) => {
      state.payablesLoading = false;
      state.error = null;
      state.payables = action.payload.data;
      state.created = false;
      state.updated = false;
    },

    withdrawalsFetched: (state, action) => {
      state.withdrawalsLoading = false;
      state.error = null;
      state.withdrawals = action.payload.data;
      state.created = false;
      state.updated = false;
    },

    withdrawalCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.created = true;
    },

    withdrawalUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.updated = true;
    },
  }
});
