import * as requestFromServer from "./authCrud";
import { actions } from "./authRedux";
import { populateBranchesCompany } from "../../Branch/_redux/branchActions";

export const fetchLogged = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .getUserByToken()
      .then(response => {
        const user = response.data;

        dispatch(actions.fulfillUser(user));
        dispatch(populateBranchesCompany(user.relationships.branches));

        resolve(true);
      })
      .catch(error => {
        dispatch(actions.logout());
        reject(error);
      });
  });
};