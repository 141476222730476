import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ControlPoint from '@material-ui/icons/ControlPoint';

import {
  fetchUpdateService,
  fetchStatusUpdateService
} from "../_redux/serviceActions";
import { fetchFields } from "../../Field/_redux/fieldActions";
import {
  fetchPrices,
  fetchCreatePrice,
  fetchRemovePrice,
} from "../../Price/_redux/priceActions";

import { createServiceSchema } from "../_schemas/createServiceSchema";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import CreatePrice from '../../Price/components/CreatePrice';
import EditPrice from '../../Price/components/EditPrice';
import { getWeekDays } from "../../../components/DateUtils";
import CreateCreditRestriction from './CreateCreditRestriction';

import { notifySuccess } from "../../../utils/Notify";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditService = (props) => {
  const dispatch = useDispatch();
  const { services, fields, prices, branchSelected } = useSelector((state) => ({
    services: state.services,
    fields: state.fields,
    prices: state.prices,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();

  const [showEntity, setShowEntity] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [selectedFields, setSelectedFields] = useState([]);
  const [creditRestrictions, setCreditRestrictions] = useState([]);
  const [createCreditRestrictionShow, setCreateCreditRestrictionShow] = useState(false);

  useEffect(() => {
    if (services.updated == true) {
      close();
      notifySuccess('Atualizado com sucesso', 'services-updated', 2000);
    }
  }, [services]);

  useEffect(() => {
    if (prices.updated == true) {
      requestPrices()
    }
  }, [prices]);

  useEffect(() => {
    if (props.show) {
      dispatch(fetchFields(branchSelected.id));
    }
  }, [props.show]);

  useEffect(() => {
    if (props.event && props.event.id) {
      requestPrices();

      setShowCancel(false);

      formik.setFieldValue('serviceId', props.event.id, false);
      formik.setFieldValue('name', props.event.attributes.name, false);
      formik.setFieldValue('minTime', props.event.attributes.minTime, false);
      formik.setFieldValue('maxTime', props.event.attributes.maxTime, false);
      formik.setFieldValue('granularity', props.event.attributes.granularity, false);
      formik.setFieldValue('type', props.event.attributes.type, false);
      formik.setFieldValue('modality', props.event.attributes.modality, false);
      formik.setFieldValue('recurrenceTimes', props.event.attributes.recurrence_times, false);
      formik.setFieldValue('billingType', props.event.attributes.billing_type, false);
      formik.setFieldValue('availableApp', props.event.attributes.available_app == true ? 'yes' : 'no', false);

      let filteredFields = [];

      props.event.relationships.fields.map(field => filteredFields.push(field.id));

      setSelectedFields(filteredFields);
      setCreditRestrictions(props.event.attributes.credit_restriction ?? []);

      if (
        props.event.relationships.package &&
        props.event.relationships.package.attributes &&
        props.event.relationships.package.attributes.prices
      ) {
        props.event.relationships.package.attributes.prices.map(price => {
          if (price.amount == 3) {
            formik.setFieldValue('package3Unit', price.price, false);
          }
          if (price.amount == 5) {
            formik.setFieldValue('package5Unit', price.price, false);
          }
          if (price.amount == 10) {
            formik.setFieldValue('package10Unit', price.price, false);
          }
        });
      }
    }
  }, [props.event]);

  const close = () => {
    props.onHide();
    setShowEntity(false);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('minTime', '', false);
    formik.setFieldValue('maxTime', '', false);
    formik.setFieldValue('granularity', '', false);
    formik.setFieldValue('type', 'single', false);
    formik.setFieldValue('modality', 'reservation', false);
    formik.setFieldValue('recurrenceTimes', '', false);
    formik.setFieldValue('billingType', 'monthly', false);
    formik.setFieldValue('availableApp', 'no', false);
    formik.setFieldValue('package3Unit', '', false);
    formik.setFieldValue('package5Unit', '', false);
    formik.setFieldValue('package10Unit', '', false);

    setSelectedFields([]);
    setCreditRestrictions([]);
  }

  const requestPrices = () => {
    try {
      if (props.event && props.event.id) {
        dispatch(fetchPrices(props.event.id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const dispatchRemovePrice = (priceId) => {
    try {
      if (priceId) {
        dispatch(fetchRemovePrice(priceId));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const requestCreatePrice = (values) => {
    try {
      values.serviceId = props.event.id;

      dispatch(fetchCreatePrice(values));
    } catch (error) {
      console.error(error);
    }
  };

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const renderError = (error) => {
    return (error ? (
      <Grid item xs={12}>
        <div className="fv-plugins-message-container">
          {(typeof error) == "string" &&
            <div className="fv-help-block">{error}</div>
          }

          {Array.isArray(error) && error.map((item) =>
            <div className="fv-help-block">{item}</div>
          )}
        </div>
      </Grid>
    ) : null);
  }

  const toggleShowCancel = () => {
    setShowCancel(!showCancel);
  }


  const openEditEntity = (event) => {
    setEditEntity(event);

    setEditEntityShow(true);
  }

  const getTime = (time) => {
    return `Valor referente a ${time} minutos`;
  }

  const fieldCheck = (fieldId) => {
    if (fieldIsChecked(fieldId)) {
      setSelectedFields(selectedFields.filter(arrayItem => arrayItem !== fieldId));
    } else {
      setSelectedFields([...selectedFields, fieldId]);
    }
  }

  const fieldIsChecked = (fieldId) => {
    return selectedFields.includes(fieldId);
  }

  const closeCreateEntity = () => {
    {createEntityShow &&
      requestPrices();
    };

    setCreateEntityShow(false);
  };

  const closeEditEntity = () => {
    {editEntityShow &&
      requestPrices();
    };

    setEditEntityShow(false);
  }

  const addCreditRestrictions = (restriction) => {
    setCreditRestrictions([...creditRestrictions, restriction]);
    setCreateCreditRestrictionShow(false);
  }

  const removeCreditRestriction = (removeIndex) => {
    setCreditRestrictions(creditRestrictions.filter((arrayPrice, index) => index !== removeIndex));
  }

  const hoursOfWeek = (dayIndex) => {
    return [
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
    ]
  }

  const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
      <a
        ref={ref}
        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        onClick={e => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        {props.children}
      </a>
    );
  });

  const formik = useFormik({
    initialValues: {
      serviceId: '',
      name: '',
      minTime: '',
      maxTime: '',
      granularity: '',
      type: 'single',
      modality: 'reservation',
      recurrenceTimes: '',
      availableApp: 'no',
      package3Unit: '',
      package5Unit: '',
      package10Unit: '',
    },
    validationSchema: createServiceSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        values.prices = prices;
        values.fieldId = selectedFields;

        if (values.type == 'unavailable') {
          values.availableApp = 'no';
        }

        if (values.type == 'single' && values.availableApp == 'yes') {
          values.package = [
            {
              amount: 3,
              price: values.package3Unit,
            },
            {
              amount: 5,
              price: values.package5Unit,
            },
            {
              amount: 10,
              price: values.package10Unit,
            }
          ];

          if (creditRestrictions) {
            values.creditRestriction = creditRestrictions
          }
        }

        dispatch(fetchUpdateService(values.serviceId, values));

        setSubmitting(false);
      }, 500);
    },
  });

  const type = [
    { label: 'Avulso', value: 'single' },
    { label: 'Mensalista', value: 'recurrence' },
    { label: 'Indisponível', value: 'unavailable' },
  ];

  const modalities = [
    { label: 'Locação', value: 'reservation' },
    { label: 'Aula', value: 'class' },
  ];

  const billingTypes = [
    { label: 'Mensal', value: 'monthly' },
    { label: 'Por evento', value: 'event' },
  ];

  const availableApps = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  return (<>
    {!createEntityShow && !editEntityShow && !createCreditRestrictionShow && <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      contenStyle={{maxWidth: '1000px', backgroundColor: 'transparent !important'}}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editar serviço
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="xl">
            <Grid container className={classes.label}>
              <Grid item xs={6}>
                <Grid container className={classes.label}>
                  <Grid item xs={12}>
                    <span className="font-weight-bolder text-dark">
                      Dados de exibição
                    </span>
                  </Grid>
                </Grid>

                <Grid container className={classes.label} spacing={2}>
                  <TextField
                    type="hidden"
                    disabled={true}
                    name="serviceId"
                    {...formik.getFieldProps("serviceId")}
                  />
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label="Nome"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      {...formik.getFieldProps("name")}
                    />

                    {services.error && renderError(services.error.name)}

                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.name}</div>
                      </div>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <span className="font-weight-bolder text-dark">
                      Modalidade
                    </span>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Modalidade"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          }
                        }
                      }}
                      name="modalityId"
                      fullWidth
                      disabled={true}
                      className={classes.textField}
                      {...formik.getFieldProps("modality")}
                    >
                      {modalities.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </TextField>

                    {services.error && renderError(services.error.modality)}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Tipo de agendamento"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          }
                        }
                      }}
                      name="fieldId"
                      fullWidth
                      disabled={true}
                      className={classes.textField}
                      {...formik.getFieldProps("type")}
                    >
                      {type.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                    </TextField>

                    {services.error && renderError(services.error.type)}
                  </Grid>

                  <Grid item xs={12}>
                    <span className="font-weight-bolder text-dark">
                      Defina o período <Icon className={classes.icon}>info_outlined</Icon>
                    </span>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="minTime"
                      label="Tempo minimo"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
                      }}
                      {...formik.getFieldProps("minTime")}
                    />

                    {services.error && renderError(services.error.min_time)}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Granularidade"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          }
                        }
                      }}
                      name="granularity"
                      fullWidth
                      className={classes.textField}
                      {...formik.getFieldProps("granularity")}
                    >
                      <MenuItem key="15" value="15">15 minutos</MenuItem>
                      <MenuItem key="30" value="30">30 minutos</MenuItem>
                      <MenuItem key="60" value="60">1 hora</MenuItem>
                    </TextField>

                    {services.error && renderError(services.error.granularity)}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="maxTime"
                      label="Tempo máximo"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
                      }}
                      {...formik.getFieldProps("maxTime")}
                    />

                    {services.error && renderError(services.error.max_time)}
                  </Grid>

                  {formik.getFieldProps('type').value == 'recurrence' && <>
                    <Grid item xs={12}>
                      <span className="font-weight-bolder text-dark">
                        Configuração mensal
                      </span>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="recurrenceTimes"
                        label="Agendamentos"
                        fullWidth
                        className={`${classes.textField} is-invalid`}
                        variant="outlined"
                        autoComplete="new-password"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">por semana</InputAdornment>,
                        }}
                        {...formik.getFieldProps("recurrenceTimes")}
                      />

                      {services.error && renderError(services.error.recurrence_times)}
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        select
                        label="Tipo de cobrança"
                        variant="outlined"
                        SelectProps={{
                          MenuProps: {
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            }
                          }
                        }}
                        name="billingType"
                        fullWidth
                        className={classes.textField}
                        {...formik.getFieldProps("billingType")}
                      >
                        {billingTypes.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                      </TextField>

                      {services.error && renderError(services.error.billing_type)}
                    </Grid>

                    {formik.getFieldProps('billingType').value == 'event' && <Grid item xs={12}>
                      <Alert key="alert-accept-schedule" variant="secondary">
                        Caso o ciclo de cobrança tenha 5 eventos, será adicionado 25% do valor mensal na fatura do mês vigente.
                      </Alert>
                      <Alert key="alert-accept-schedule" variant="secondary">
                        Para faturas proporcional cada evento terá o custo de 25% do valor mensal.
                      </Alert>
                    </Grid>}
                  </>}

                  {formik.getFieldProps('type').value != 'unavailable' && <>
                    {formik.getFieldProps('type').value == 'single' && <>
                      <Grid item xs={12}>
                        <span className="font-weight-bolder text-dark">
                          Disponibilidade <Icon className={classes.icon}>info_outlined</Icon>
                        </span>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          select
                          label="Disponivel no app"
                          variant="outlined"
                          SelectProps={{
                            MenuProps: {
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              }
                            }
                          }}
                          name="availableApp"
                          fullWidth
                          className={classes.textField}
                          {...formik.getFieldProps("availableApp")}
                        >
                          {availableApps.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                        </TextField>

                        {services.error && renderError(services.error.available_app)}
                      </Grid>

                      {formik.getFieldProps('availableApp').value == 'yes' && <>
                        <Grid item xs={12}>
                          <span className="font-weight-bolder text-dark">
                            Pacotes de créditos
                          </span>
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            name="package3Unit"
                            label="3 créditos"
                            fullWidth
                            className={`${classes.textField} is-invalid`}
                            variant="outlined"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            {...formik.getFieldProps("package3Unit")}
                          />

                          {services.error && renderError(services.error['package.0.price'])}
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            name="package5Unit"
                            label="5 créditos"
                            fullWidth
                            className={`${classes.textField} is-invalid`}
                            variant="outlined"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            {...formik.getFieldProps("package5Unit")}
                          />

                          {services.error && renderError(services.error['package.1.price'])}
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            name="package10Unit"
                            label="10 créditos"
                            fullWidth
                            className={`${classes.textField} is-invalid`}
                            variant="outlined"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            {...formik.getFieldProps("package10Unit")}
                          />

                          {services.error && renderError(services.error['package.2.price'])}
                        </Grid>


                        {formik.values.type == 'single' && formik.values.modality == 'reservation' && <>
                          <Grid item xs={6}>
                            <span className="font-weight-bolder text-dark">
                              Restrição no estorno de créditos
                            </span>
                          </Grid>

                          <Grid item xs={6}>
                            <a className="font-weight-bold" onClick={() => setCreateCreditRestrictionShow(true)}>
                              <ControlPoint fontSize="inherit" /> Cadastrar restrição de crédito
                            </a>
                          </Grid>

                          <Grid item xs={12}>
                            <ListGroup>
                              {creditRestrictions && creditRestrictions.length == 0 && <div className="d-flex flex-center text-center">
                                Nenhuma restrição cadastrada
                              </div>}

                              {creditRestrictions && creditRestrictions.map((value, index) => <>
                                <ListGroup.Item>
                                  <Grid container>
                                    <Grid item xs={9} fontSize="large">
                                      {value.range?.map(date => <p style={{fontSize: '1em'}}>
                                        <span className="text-dark">
                                          <span className="font-weight-bolder text-dark">Hórario:</span> {date.start_minutes} até {date.end_minutes}
                                        </span>
                                      </p>)}

                                      <p style={{fontSize: '1em'}}>
                                        <span className="text-dark">
                                          <span className="font-weight-bolder text-dark">Dias:</span> {getWeekDays(value.selected_weekdays, true)}
                                        </span>
                                      </p>
                                    </Grid>

                                    <Grid item xs={3} style={{textAlign: 'right'}}>
                                      <Dropdown className="dropdown-inline" drop="down" alignRight>
                                        <Dropdown.Toggle
                                            as={DropdownCustomToggler}
                                        id="dropdown-toggle-top">
                                          <i className="ki ki-bold-more-hor" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                          <ul className="navi navi-hover py-5">
                                            <li className="navi-item" onClick={() => removeCreditRestriction(index)}>
                                              <a className="navi-link">
                                                <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                                <span className="navi-text">
                                                  Remover
                                                </span>
                                              </a>
                                            </li>
                                        </ul>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Grid>
                                  </Grid>
                                </ListGroup.Item>
                              </>)}
                            </ListGroup>
                          </Grid>
                        </>}
                      </>}
                    </>}
                  </>}
                </Grid>
              </Grid>

              <Grid item xs={6}>
                {formik.getFieldProps('type').value != 'unavailable' && <Grid container className={`${classes.label} ml-5`}>
                  <Grid item xs={8}>
                    <span className="font-weight-bolder text-dark">
                      Preços <Icon className={classes.icon}>info_outlined</Icon>
                    </span>
                  </Grid>

                  <Grid item xs={4}>
                    <a className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                      <ControlPoint fontSize="inherit" /> Cadastrar preço
                    </a>
                  </Grid>

                  <Grid item xs={12}>
                    <ListGroup>
                      {prices && prices.entities && prices.entities.length == 0 && <div className="d-flex flex-center text-center">
                        Nenhum preço cadastrado
                      </div>}
                      {prices && prices.entities.map(price => <>
                        <ListGroup.Item>
                          <Grid container>
                            <Grid item xs={9} fontSize="large">

                              <p style={{fontSize: '1em'}}>
                                <span className="text-dark">
                                  <span className="font-weight-bolder text-dark">Hórario:</span> {price.attributes.startAt} até {price.attributes.finishAt}
                                </span>
                              </p>

                              <p style={{fontSize: '1em'}}>
                                <span className="text-dark">
                                  <span className="font-weight-bolder text-dark">Dias:</span> {getWeekDays(price.attributes.weekdays, true)}
                                </span>
                              </p>

                              <p style={{fontSize: '1em'}}>
                                <span className="text-dark">
                                  <span className="font-weight-bolder text-dark">Preço:</span> R$ {price.attributes.price} / {price.attributes.time}min
                                </span>
                              </p>

                              {price.attributes.member_price && <p style={{fontSize: '1em'}}>
                                <span className="text-dark">
                                  <span className="font-weight-bolder text-dark">Preço para alunos:</span> R$ {price.attributes.member_price} / {price.attributes.time}min
                                </span>
                              </p>}
                            </Grid>

                            <Grid item xs={3} style={{textAlign: 'right'}}>
                              <Dropdown className="dropdown-inline" drop="down" alignRight>
                                <Dropdown.Toggle
                                    as={DropdownCustomToggler}
                                id="dropdown-toggle-top">
                                  <i className="ki ki-bold-more-hor" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                  <ul className="navi navi-hover py-5">
                                    <li className="navi-item" onClick={() => openEditEntity(price)}>
                                      <a className="navi-link">
                                        <span className="navi-icon"><i className="flaticon-edit-1"></i></span>
                                        <span className="navi-text">
                                          Editar
                                        </span>
                                      </a>
                                    </li>
                                    <li className="navi-item" onClick={() => dispatchRemovePrice(price.id)}>
                                      <a className="navi-link">
                                        <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                        <span className="navi-text">
                                          Remover
                                        </span>
                                      </a>
                                    </li>
                                </ul>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Grid>

                          </Grid>
                        </ListGroup.Item>
                      </>)}
                    </ListGroup>
                  </Grid>

                  <Grid item xs={8} className="pt-5">
                    <span className="card-title font-weight-bolder text-dark">
                      Campos
                    </span>
                  </Grid>

                  <Grid item xs={4}>
                    {/*<a href="#" className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                      <ControlPoint fontSize="inherit" /> Cadastrar serviço
                    </a>*/}
                  </Grid>

                  <Grid item xs={12}>
                    <div className="checkbox-list">
                      {fields.entities.map(field => <>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            key={field.id}
                            name={field.id}
                            checked={fieldIsChecked(field.id)}
                            onChange={() => fieldCheck(field.id)}
                          />
                          <span></span>{field.attributes.name}
                        </label>
                      </>)}
                    </div>

                    {services.error && renderError(services.error.field_id)}
                  </Grid>
                </Grid>}
              </Grid>

              {(typeof services.error) == "string" && <Grid item xs={12}>
                <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                      {services.error}
                    </span>
                  </div>
                </div>
              </Grid>}

              {/*{false && <table style={{border: '1px solid #000', width: '100%'}}>
                <tr>
                  <td style={{border: '1px solid #000', height: '10px'}}></td>
                  <td style={{border: '1px solid #000', height: '10px'}}>Seg</td>
                  <td style={{border: '1px solid #000', height: '10px'}}>Ter</td>
                  <td style={{border: '1px solid #000', height: '10px'}}>Qua</td>
                  <td style={{border: '1px solid #000', height: '10px'}}>Qui</td>
                  <td style={{border: '1px solid #000', height: '10px'}}>Sex</td>
                  <td style={{border: '1px solid #000', height: '10px'}}>Sáb</td>
                  <td style={{border: '1px solid #000', height: '10px'}}>Dom</td>
                </tr>
                {hoursOfWeek().map(hour => <>
                  <tr>
                    <td style={{border: '1px solid #000', height: '10px'}}  rowspan="4"><span class="font-size-xs">{hour}</span></td>
                    <td style={{border: '1px solid #000', height: '10px', backgroundColor: 'green'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                  </tr>
                  <tr>
                    <td style={{border: '1px solid #000', height: '10px', backgroundColor: 'green'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                  </tr>
                  <tr>
                    <td style={{border: '1px solid #000', height: '10px', backgroundColor: 'green'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                  </tr>
                  <tr>
                    <td style={{border: '1px solid #000', height: '10px', backgroundColor: 'green'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                    <td style={{border: '1px solid #000', height: '10px'}}></td>
                  </tr>
                </>)}
              </table>}*/}
            </Grid>
          </Container>
        </Modal.Body>

        {showCancel && <Modal.Body style={{backgroundColor: '#ffe2e2'}}>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{textAlign: 'left'}}>
              <span className="font-weight-bolder text-dark">
                Deseja desativar o serviço?
              </span>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right'}}>
              <CloseIcon fontSize="small" onClick={toggleShowCancel} />
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right'}}>
              <Button style={{backgroundColor: '#ff6565', color: 'white'}} variant="contained" size="large" onClick={() => dispatch(fetchStatusUpdateService(props.event.id, false))}>
                Desativar
              </Button>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'left'}}>
              <Button size="large" onClick={() => toggleShowCancel()}>
                Não
              </Button>
            </Grid>
          </Grid>
        </Modal.Body>}

        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={formik.isSubmitting}>
                Salvar {(formik.isSubmitting || services.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs={4} style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
            <Grid item xs={2} style={{textAlign: 'right'}}>
              <Tooltip title="Ver opções de cancelamento" placement="top">
                <IconButton variant="outlined" aria-label="delete" onClick={toggleShowCancel} disableFocusRipple={true}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>}

    <CreatePrice show={createEntityShow} type={formik.values.type} onHide={closeCreateEntity} onSubmit={(values) => requestCreatePrice(values)} />
    <EditPrice show={editEntityShow} type={formik.values.type} onHide={closeEditEntity} event={editEntity} />
    <CreateCreditRestriction show={createCreditRestrictionShow} onHide={() => setCreateCreditRestrictionShow(false)} onSubmit={(values) => addCreditRestrictions(values)} />
  </>);
};

export default EditService;