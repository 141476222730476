import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

import ListGroup from 'react-bootstrap/ListGroup';
import Overlay from 'react-bootstrap/Overlay';

import ControlPoint from '@material-ui/icons/ControlPoint';
import Settings from '@material-ui/icons/Settings';
import DeleteForever from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import { fetchPriceService } from "../../Price/_redux/priceActions";
import { fetchClientPhone, fetchClientName } from "../../Client/_redux/clientActions";
import { fetchCreateSchedule } from "../_redux/scheduleActions";

import { createScheduleSchema } from "../_schemas/createScheduleSchema";

import Snackbar from "../../../components/Snackbar";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";
import { RenderError } from "../../../components/RenderError";
import DayPicker from '../../../components/DayPicker';

import { notifySuccess } from "../../../utils/Notify";

import CreateClient from "../../Client/components/CreateClient";
import CreateClientSchedule from "./CreateClientSchedule";
import SelectParticipant from "./SelectParticipant";
import SelectContract from "./SelectContract";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
  detailedExpansionPanel: {
    marginBottom: 5,
    boxShadow: 'none !important'
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

const CreateSchedule = (props) => {
  const dispatch = useDispatch();
  const { fields, prices, clients, schedules, branchSelected } = useSelector((state) => ({
    fields: state.fields,
    prices: state.prices,
    clients: state.clients,
    schedules: state.schedules,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();

  const [modality, setModality] = useState('');
  const [step, setStep] = useState(1);
  const [recurrenceInfinity, setRecurrenceInfinity] = useState(false);
  const [showAccordion, setShowAccordion] = useState(false);

  const [services, setServices] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [type, setType] = useState('');
  const [showClient, setShowClient] = useState(false);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [clientScheduleShow, setClientScheduleShow] = useState(false);
  const [playerScheduleShow, setPlayerScheduleShow] = useState(false);
  const [contractScheduleShow, setContractScheduleShow] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [weekdays, setWeekdays] = useState([]);
  const [durations, setDurations] = useState([]);
  const target = useRef(null);

  useEffect(() => {
    if (schedules.created == true) {
      close();
      notifySuccess('Agendamento realizado com sucesso', 'schedules-created');
    }
  }, [schedules]);

  useEffect(() => {
    if (props.show) {
      //
    } else {
      close();
    }
  }, [props.show]);

  useEffect(() => {
    setType('single');

    formik.setFieldValue('startDateAt', moment(props.event.start).format('YYYY-MM-DD'), false);
    formik.setFieldValue('endDateAt', moment(props.event.end).format('YYYY-MM-DD'), false);
    formik.setFieldValue('recurrenceFinishAt', moment(props.event.end).format('YYYY-MM-DD'), false);

    let startAt = moment(props.event.start);

    if (!props.event.start) {
      const remainder = 30 - (startAt.minute() % 30);

      startAt = moment().add(remainder, 'minutes');
    }

    let endAt = moment(props.event.end);

    if (!props.event.end) {
      endAt = moment(startAt).add(1, 'hours');
    }

    let duration = endAt.diff(startAt, 'minutes');

    formik.setFieldValue('startAt', startAt.format('HH:mm'), false);
    formik.setFieldValue('endAt', endAt.format('HH:mm'), false);
    formik.setFieldValue('duration', duration, false);

    calculateDurations(duration);

    formik.setFieldValue('statusId', status[0].value, false);

    let fieldFilter = fields.entities.find(element => {
      return element.id === props.event.resourceId;
    });

    if (fieldFilter) {
      formik.setFieldValue('fieldId', fieldFilter.id, false);

      if (fieldFilter.relationships.services.length) {
        formik.setFieldValue('serviceId', fieldFilter.relationships.services[0].id, false);
      }
    }

    convertWeekday(moment(props.event.start).weekday());
  }, [props.event]);

  const status = [
    { label: 'Pendente', value: 'pending' },
    { label: 'Pago', value: 'paid' },
    { label: 'Cancelado', value: 'canceled' }
  ];

  const calculateDurations = (duration) => {
    let newDurations = [];
    let count = 15;

    while(count <= duration || count <= 480) {
      newDurations.push({
        label: convertMinsToHrsMins(count),
        value: count
      });

      count += 15;
    }

    setDurations(newDurations);
  }

  const close = () => {
    props.onHide();

    setModality('');
    setStep(1);
    setRecurrenceInfinity(false);
    setShowAccordion(false);

    setSelectedClients([]);
    setSelectedPlayers([]);
    setShowClient(false);

    formik.setFieldValue('level', '', false);
    formik.setFieldValue('spots', 1, false);
    formik.setFieldValue('fieldId', '', false);
    formik.setFieldValue('serviceId', '', false);
    formik.setFieldValue('clientId', '', false);
    formik.setFieldValue('mobile', '', false);
    formik.setFieldValue('name', '', false);
    formik.setFieldValue('email', '', false);
  }

  const convertMinsToHrsMins = (mins) => {
    if (mins < 60) {
      return `${mins} min`;
    }

    let h = Math.floor(mins / 60);
    let m = mins % 60;

    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;

    return `${h}:${m} hrs`;
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const selectedWeekDayColor = (weekday) => {
    return weekdays.includes(weekday) ? "primary" : "default";
  }

  const selectWeekDay = (weekday) => {
    if(!weekdays.includes(weekday)) {
      setWeekdays([...weekdays, weekday]);
    } else{
        var array = [...weekdays];
        var index = array.indexOf(weekday);

        if (index !== -1) {
          array.splice(index, 1);

          setWeekdays(array);
        }
    }
  }

  const selectClients = (client) => {
    console.log(client);

    let data = {
      type: 'client',
      id: client.id,
      name: client.attributes.name,
      start_at: client.attributes.start_at,
      service_id: client.relationships.service.id,
      service_name: client.relationships.service.attributes.name,
      service_type: client.relationships.service.attributes.type,
      service_recurrence_times: client.relationships.service.attributes.recurrence_times,
      recurrence_days: client.attributes.recurrence_days,
      finish_at: client.attributes.finish_at,
      billing_day: client.attributes.billing_day,
      plan: client.attributes.plan,
      credit_id: client.attributes.credit_id,
    }

    setSelectedClients([...selectedClients, data]);
    closeClientSchedule();
  }

  const selectPlayers = (client) => {
    setSelectedPlayers([...selectedPlayers, client]);
    closePlayersSchedule();
  }

  const typeClass = (item) => {
    return type == item ? "contained" : "outlined";
  };

  const searchClientName = () => {
    if (formik.values.name && formik.values.name.length >= 2) {
      const clientPhoneTimeout = setTimeout(() => {
        dispatch(fetchClientName(formik.values.name, branchSelected.id));
        setShowClient(true);
      }, 1000);

      return () => {
        clearTimeout(clientPhoneTimeout);
      };
    }
  }

  const closeCreateClient = () => {
    setCreateEntityShow(false);

    return searchClientName();
  }

  const closeClientSchedule = () => {
    setClientScheduleShow(false);
  }

  const closePlayersSchedule = () => {
    setPlayerScheduleShow(false);
  }

  const closeContractSchedule = () => {
    setContractScheduleShow(false);

  }

  const openContractSchedule = () => {
    setContractScheduleShow(true);
  }

  const selectContract = (contract) => {
    let data = {
      type: 'contract',
      id: contract.id,
      name: contract.relationships.client.attributes.name,
      start_at: contract.startDateAt,
      service_id: contract.relationships.service.id,
      service_name: contract.relationships.service.attributes.name,
      service_type: contract.relationships.service.attributes.type,
      service_recurrence_times: contract.relationships.service.attributes.recurrence_times,
    }

    setSelectedClients([...selectedClients, data]);
    closeContractSchedule();
    closeClientSchedule();
  }

  const mapClients = () => {
    return selectedClients.map(client => {
      if (client.type == 'client') {
        let data = {
          client_id: client.id,
          start_at: client.start_at,
          service_id: client.service_id,
        };

        if (client.service_type == 'recurrence') {
          data.recurrence_days = client.recurrence_days;
          data.finish_at = client.finish_at;
          data.billing_day = client.billing_day;
          data.plan = client.plan;
        }

        if (client.type == 'client' && client.credit_id) {
          data.credit_id = client.credit_id;
        }

        return data;
      }

      if (client.type == 'contract') {
        return {
          'contract_id': client.id,
          start_at: client.start_at,
        }
      }
    });
  }

  const mapPlayers = () => {
    return selectedPlayers.map(client => {
      return {
        id: client.id,
      }
    });
  }

  const openClientSchedule = () => {
      setClientScheduleShow(true);
  }

  const removeSelectedClients = (client) => {
    if (selectedClients.includes(client)) {
      setSelectedClients(selectedClients.filter(arrayClient => arrayClient.id !== client.id));
    }
  }

  const removeSelectedPlayers = (client) => {
    if (selectedPlayers.includes(client)) {
      setSelectedPlayers(selectedPlayers.filter(arrayClient => arrayClient.id !== client.id));
    }
  }

  const formik = useFormik({
    initialValues: {
      recurrenceFinishAt: '',
      startDateAt: '',
      endDateAt: '',
      startAt: '',
      endAt: '',
      duration: '',
      fieldId: '',
      price: '',
      serviceId: '',
      statusId: '',
      level: '',
      spots: 1,
    },
    validationSchema: createScheduleSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        if (type == 'recurrence') {
          values.recurrence_period = 'weekly';
          values.recurrence_start_at = values.startDateAt;

          if (recurrenceInfinity) {
            values.recurrence_finish_at = values.recurrenceFinishAt;
          } else {
            values.recurrence_finish_at = '';
          }

          values.recurrence_days = weekdays.reduce((x, y) => x + y, 0)
        } else {
          values.recurrence_period = '';
          values.recurrence_start_at = '';
          values.recurrence_finish_at = '';
          values.recurrence_days = '';
        }

        if (modality == 'class') {
          values.spots = values.spots;
        } else {
          values.level = '';
          values.spots = '';
        }

        values.schedule_spots = mapClients();
        values.participants = mapPlayers();
        values.type = type;
        values.modality = modality;

        dispatch(fetchCreateSchedule(values.fieldId, values));

        setSubmitting(false);
      }, 500);
    },
  });

  useEffect(() => {
    if (step == 2) {
      setSelectedClients([]);
      setSelectedPlayers([]);
    }
  }, [step]);

  useEffect(() => {
    // if(prices.actionsLoading == false) {
      if (modality && (modality == 'class' || modality == 'reservation') && type && step == 2) {
        // const priceTimeout = setTimeout(() => {
          dispatch(fetchPriceService(
            formik.values.fieldId,
            `${formik.values.startDateAt} ${formik.values.startAt}`,
            moment(`${formik.values.startDateAt} ${formik.values.startAt}`).add(formik.values.duration, 'minutes').format('YYYY-MM-DD HH:mm'),
            modality,
            type,
          ))
        // }, 500);

        // return () => {
        //   clearTimeout(priceTimeout);
        // };
      }
    // }
  }, [
    modality,
    type,
    step,
    weekdays,
    formik.values.startDateAt,
    formik.values.startAt,
    formik.values.duration,
    formik.values.fieldId
  ]);

  useEffect(() => {
    let endAt = moment(`${formik.values.startDateAt} ${formik.values.startAt}`).add(formik.values.duration, 'minutes')

    formik.setFieldValue('endDateAt', endAt.format('YYYY-MM-DD'), false);
    formik.setFieldValue('endAt', endAt.format('HH:mm'), false);
  }, [
    formik.values.startAt,
    formik.values.startDateAt,
    formik.values.duration,
  ]);

  const convertWeekday = (value) => {
    let values = [
      1,
      2,
      4,
      8,
      16,
      32,
      64,
    ];

    return setWeekdays([values[value]]);
  }

  const getType = (type) => {
    return type == 'single' ? 'Avulso' : 'recurrence' ? 'Mensalista' : 'Indisponivel';
  }

  const getStatusColor = (type) => {
    if (type != 'recurrence' && modality == 'schedule') {
      return '#17c191';
    }
    if (type == 'recurrence' && modality == 'schedule') {
      return '#a6bf54';
    }

    if (type != 'recurrence' && modality == 'class') {
      return '#1772c1';
    }
    if (type == 'recurrence' && modality == 'class') {
      return '#54a6bf';
    }

    return 'gray';
  }

  useEffect(() => {
    // formik.setFieldValue('endDateAt', formik.values.startDateAt, false);
    convertWeekday(moment(formik.values.startDateAt).weekday());
  }, [formik.values.startDateAt]);

  // useEffect(() => {
  //   if (formik.values.fieldId) {
  //     let fieldFilter = fields.entities.find(element => {
  //       return element.id === formik.values.fieldId;
  //     });

  //     if (fieldFilter) {
  //       let serviceFiltered = fieldFilter.relationships.services.filter(service => {
  //         if (service.relationships.status.attributes.name === 'active') {
  //           if (type == 'single') {
  //             return service.attributes.type == 'single'
  //           }

  //           if (type == 'recurrence') {
  //             return service.attributes.type == 'recurrence'
  //           }

  //           if (type == 'unavailable') {
  //             return service.attributes.type == 'unavailable'
  //           }
  //         }

  //         return false;
  //       });

  //       setServices(serviceFiltered);

  //       if (serviceFiltered[0]) {
  //         formik.setFieldValue('serviceId', serviceFiltered[0].id, false);
  //       } else {
  //         formik.setFieldValue('serviceId', '', false);
  //       }

  //       setSelectedClients([]);
  //     }
  //   }
  // }, [formik.values.fieldId, type]);

  useEffect(() => {
    if (formik.values.statusId) {
      setSelectedStatus(formik.values.statusId);
    }
  }, [formik.values.statusId]);

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);

  const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
      <a
        ref={ref}
        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        onClick={e => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        {props.children}
      </a>
    );
  });

  return (<>
  <Modal
    {...props}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    // centered
    backdrop="static"
    keyboard={true}
    show={
      props.show &&
      !createEntityShow &&
      !contractScheduleShow
    }
    contenStyle={{maxWidth: '1000px', backgroundColor: 'transparent !important'}}
  >
    <form className="form" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">

      <div className="card card-custom card-transparent">

        <CloseIcon onClick={close} className="mt-3 mr-3 cursor-pointer" style={{alignSelf: 'flex-end'}} />

        <div className="card-body p-0">

          <div className="wizard wizard-3" id="kt_wizard_v3" data-wizard-state="step-first" data-wizard-clickable="true">
            <div className="wizard-nav">
              <div className="wizard-steps px-8 pb-8 px-lg-15 pb-lg-3">
                <div className="wizard-step" data-wizard-type="step" data-wizard-state={step == 1 && "current"}>
                  <div className="wizard-label">
                    <h3 className="wizard-title">
                    <span>1.</span>Modalidade</h3>
                    <div className="wizard-bar"></div>
                  </div>
                </div>
                <div className="wizard-step" data-wizard-type="step" data-wizard-state={step == 2 && "current"}>
                  <div className="wizard-label">
                    <h3 className="wizard-title">
                    <span>2.</span>Informações</h3>
                    <div className="wizard-bar"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-custom card-shadowless rounded-top-0">
              <div className="card-body p-0">
                {step == 1 && <div className="row justify-content-center py-4 px-4 py-lg-7 px-lg-5">
                  <div className="col-xl-12 col-xxl-10">
                    <div className="pb-5">
                      <div className="mb-10 font-weight-bold text-dark">Modalidade</div>
                      <div className="form-group">
                        <label>Escolha a modalidade que deseja agendar</label>
                      </div>
                      <div className="row">
                        <div className="col-xl-4">
                          <span onClick={() => setModality('reservation')} className="card card-custom bg-info bg-hover-state-info card-stretch card-stretch gutter-b cursor-pointer">
                            <div className="card-body">
                              {modality == 'reservation' && <span className="svg-icon svg-icon-2x svg-icon-info">
                                <i className="ki ki-bold-check-1 text-white icon-2x" style={{
                                  position: 'absolute',
                                  'right': '20px'
                                }} />
                              </span>}
                              <div className="text-inverse-info font-weight-bolder font-size-h5 mb-2 mt-5">Locação</div>
                              <div className="font-weight-bold text-inverse-info font-size-sm">Locação de quadra</div>
                            </div>
                          </span>
                        </div>
                        <div className="col-xl-4">
                          <span onClick={() => setModality('class')} className="card card-custom bg-success bg-hover-state-success card-stretch card-stretch gutter-b cursor-pointer">
                            <div className="card-body">
                              {modality == 'class' && <span className="svg-icon svg-icon-2x svg-icon-info">
                                <i className="ki ki-bold-check-1 text-white icon-2x" style={{
                                  position: 'absolute',
                                  'right': '20px'
                                }} />
                              </span>}
                              <div className="text-inverse-success font-weight-bolder font-size-h5 mb-2 mt-5">Aula</div>
                              <div className="font-weight-bold text-inverse-success font-size-sm">Aula com professor</div>
                            </div>
                          </span>
                        </div>
                        <div className="col-xl-4">
                          <span onClick={() => setModality('unavailable')} className="card card-custom bg-light-info bg-hover-state-light card-stretch card-stretch gutter-b cursor-pointer">
                            <div className="card-body">
                              {modality == 'unavailable' && <span className="svg-icon svg-icon-2x svg-icon-info">
                                <i className="ki ki-bold-check-1 text-dark icon-2x" style={{
                                  position: 'absolute',
                                  'right': '20px'
                                }} />
                              </span>}
                              <div className="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 mt-5">Indisponivel</div>
                              <div className="font-weight-bold text-muted font-size-sm">Bloquear horário da quadra</div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>

                    {modality && (modality == 'reservation' || modality == 'class' || modality == 'unavailable') && <div className="pb-2">
                      <div className="form-group">
                        <div className="form-group">
                          {modality == 'reservation' && <div className="mt-3 font-weight-bold font-size-sm">Agende um único hórario ou que repete automaticamente toda semana</div>}
                          {modality == 'class' && <div className="mt-3 font-weight-bold font-size-sm">Agende uma aula que acontece uma única vez ou que repete automaticamente toda semana</div>}
                          {modality == 'unavailable' && <div className="mt-3 font-weight-bold font-size-sm">Bloqueie um único hórario ou que repete automaticamente toda semana</div>}
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <label className="option option-plain">
                              <span className="option-control">
                                <span className="radio">
                                  <input type="radio" checked={type == 'single'} onClick={() => setType('single')} />
                                  <span></span>
                                </span>
                              </span>
                              <span className="option-label">
                                <span className="option-head">
                                  {modality == 'reservation' && <span className="option-title">Agendamento avulso</span>}
                                  {modality == 'class' && <span className="option-title">Aula avulso</span>}
                                  {modality == 'unavailable' && <span className="option-title">Bloqueio avulso</span>}
                                </span>
                                {modality == 'reservation' && <span className="option-body">Reserve um horário que acontecerá uma unica vez</span>}
                                {modality == 'class' && <span className="option-body">Reserve uma aula avulsa ou em grupo que acontece uma única vez</span>}
                                {modality == 'unavailable' && <span className="option-body">Bloqueie um horário que acontecerá uma unica vez</span>}
                              </span>
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="option option option-plain">
                              <span className="option-control">
                                <span className="radio">
                                  <input type="radio" checked={type == 'recurrence'} onClick={() => setType('recurrence')} />
                                  <span></span>
                                </span>
                              </span>
                              <span className="option-label">
                                <span className="option-head">
                                  {modality == 'reservation' && <span className="option-title">Agendamento mensal</span>}
                                  {modality == 'class' && <span className="option-title">Aula mensal</span>}
                                  {modality == 'unavailable' && <span className="option-title">Bloqueio recorrente</span>}
                                </span>
                                {modality == 'reservation' && <span className="option-body">Agendamento com horário fixo que repete toda semana, quais dias da semana quiser</span>}
                                {modality == 'class' && <span className="option-body">Aula em grupo com horário fixo que repete toda semana, quais dias da semana quiser</span>}
                                {modality == 'unavailable' && <span className="option-body">Bloqueie um horário fixo que repete toda semana, quais dias da semana quiser</span>}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>}
                    <div className="d-flex justify-content-between border-top mt-5 pt-10">
                      <div className="mr-2">
                        <button type="button" className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4" onClick={() => close()}>Cancelar</button>
                      </div>
                      <div>
                        {/*<button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4" data-wizard-type="action-submit">Submit</button>*/}
                        <button type="button" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4" disabled={!modality} onClick={() => setStep(2)}>Próximo</button>
                      </div>
                    </div>
                  </div>
                </div>}

                {step == 2 && <div className="row justify-content-center px-4 py-lg-7 px-lg-5">
                  <div className="col-lg-10 col-xl-10 col-xxl-10">

                    {/*{(typeof schedules.error) == "string" && <Grid item xs={12}>
                      <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                          <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                            {schedules.error}
                          </span>
                        </div>
                      </div>
                    </Grid>}*/}

                    <div className="row">
                      <div className="col-xs-12 col-lg-6 col-xl-6 col-xxl-6 py-4">
                        <div className="pb-0">

                          {/*<div className="pt-5 mb-10 font-weight-bold text-dark">Campo</div>
                          <div className="form-group">
                            <label>Escolha o campo que deseja agendar</label>
                          </div>*/}
                          <div className="mb-5 font-weight-bold text-dark">Campo</div>
                          <div className="mb-1 font-weight-bold font-size-sm">Escolha o campo que deseja agendar</div>

                          <div className="row">
                            <div className="col-xl-12">
                              <TextField
                                select
                                label="Campo"
                                variant="outlined"
                                SelectProps={{
                                  MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    }
                                  }
                                }}
                                name="fieldId"
                                fullWidth
                                className={classes.textField}
                                {...formik.getFieldProps("fieldId")}
                              >
                                {fields.entities.map((item) => <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>)}
                              </TextField>
                              {formik.touched.fieldId && formik.errors.fieldId ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{formik.errors.fieldId}</div>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="pt-10 mb-5 font-weight-bold text-dark">Data e horário</div>
                          <div className="mb-1 font-weight-bold font-size-sm">
                            {modality == 'class' && type == 'single' && "Escolha o dia vai acontecer a aula"}
                            {modality == 'class' && type == 'recurrence' && "Escolha o dia que inicia as aulas"}

                            {modality == 'reservation' && type == 'single' && "Escolha o dia vai acontecer o jogo"}
                            {modality == 'reservation' && type == 'recurrence' && "Escolha o dia que inicia os jogos"}
                          </div>
                          {(type == 'recurrence') && <div className="mb-3 font-size-sm">Por padrão a recorrencia não tem data de termino, você pode definir uma data de termino se preferir.</div>}

                          <div className="row">
                            <div className="col-xl-12">
                              <TextField
                                name="startDateAt"
                                label={'Data Inicio'}
                                type="date"
                                fullWidth
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                {...formik.getFieldProps("startDateAt")}
                              />
                            </div>
                            {(type == 'recurrence') && <div className="col-xl-12 pt-3">
                              <label>Definir uma data de termino?</label>
                              <span className="switch switch-brand switch-icon">
                                <label>
                                  <input type="checkbox" check={recurrenceInfinity} onClick={() => setRecurrenceInfinity(!recurrenceInfinity)} name="select"/>
                                <span></span>
                              </label>
                             </span>
                            </div>}
                          </div>

                          {(type == 'recurrence') && recurrenceInfinity && <>
                            <div className="mt-3 font-weight-bold font-size-sm">Escolha a data que termina a recorrencia</div>

                            <div className="row pt-5">
                              <div className="col-xl-12">
                                <TextField
                                  name="recurrenceFinishAt"
                                  label={'Data termino'}
                                  type="date"
                                  fullWidth
                                  className={classes.textField}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  {...formik.getFieldProps("recurrenceFinishAt")}
                                />
                              </div>
                            </div>
                          </>}

                          {(type == 'recurrence') && <>
                            <div className="mt-3 font-weight-bold font-size-sm">Selecione os dias da semana que vão ocorrer as aulas</div>

                            <div className="row pt-2 px-5">
                              <div className="col-xs-12">
                                <div className="d-flex flex-row align-items-center">
                                  <div className="pr-3"><Chip onClick={() => selectWeekDay(2)} clickable color={selectedWeekDayColor(2)} variant="outlined" label="Seg" /></div>
                                  <div className="pr-3"><Chip onClick={() => selectWeekDay(4)} clickable color={selectedWeekDayColor(4)} variant="outlined" label="Ter" /></div>
                                  <div className="pr-3"><Chip onClick={() => selectWeekDay(8)} clickable color={selectedWeekDayColor(8)} variant="outlined" label="Qua" /></div>
                                  <div className="pr-3"><Chip onClick={() => selectWeekDay(16)} clickable color={selectedWeekDayColor(16)} variant="outlined" label="Qui" /></div>
                                  <div className="pr-3"><Chip onClick={() => selectWeekDay(32)} clickable color={selectedWeekDayColor(32)} variant="outlined" label="Sex" /></div>
                                  <div className="pr-3"><Chip onClick={() => selectWeekDay(64)} clickable color={selectedWeekDayColor(64)} variant="outlined" label="Sab" /></div>
                                  <div className="pr-3"><Chip onClick={() => selectWeekDay(1)} clickable color={selectedWeekDayColor(1)} variant="outlined" label="Dom" /></div>
                                </div>
                              </div>
                            </div>
                          </>}

                          <div className="row">
                            <div className="col-xl-6 pt-5">
                              <TextField
                                // value={startAt}
                                name="startAt"
                                label="Horario Inicio"
                                type="time"
                                fullWidth
                                step={15}
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: "900", // 15 min
                                }}
                                {...formik.getFieldProps("startAt")}
                              />
                            </div>
                            <div className="col-xl-6 pt-5">
                              <TextField
                                select
                                label="Duração"
                                variant="outlined"
                                SelectProps={{
                                  MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    }
                                  }
                                }}
                                name="duration"
                                fullWidth
                                className={classes.textField}
                                {...formik.getFieldProps("duration")}
                              >
                                {durations.length > 0 && durations.map(duration => <MenuItem key={`duration-${duration.value}`} value={duration.value}>
                                  {duration.label}
                                </MenuItem>)}
                              </TextField>
                            </div>
                          </div>
                        </div>

                        {modality == 'class' && <>
                          <div className="row">
                            <div className="col-xl-6 pt-5">
                              <TextField
                                select
                                label={'Nível da aula'}
                                variant="outlined"
                                SelectProps={{
                                  MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    }
                                  }
                                }}
                                name="level"
                                fullWidth
                                className={classes.textField}
                                {...formik.getFieldProps("level")}
                              >
                                <MenuItem key={`level-0`} value={''}>
                                  Não informado
                                </MenuItem>
                                <MenuItem key={`level-1`} value={1}>
                                  Iniciante
                                </MenuItem>
                                <MenuItem key={`level-2`} value={2}>
                                  Intermediário
                                </MenuItem>
                                <MenuItem key={`level-3`} value={3}>
                                  Avançado
                                </MenuItem>
                                <MenuItem key={`level-4`} value={4}>
                                  Profissional
                                </MenuItem>
                              </TextField>
                            </div>
                            <div className="col-xl-6 pt-5">
                              <TextField
                                select
                                label={'Número de Jogadores'}
                                variant="outlined"
                                SelectProps={{
                                  MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    }
                                  }
                                }}
                                name="spots"
                                fullWidth
                                className={classes.textField}
                                {...formik.getFieldProps("spots")}
                              >
                                <MenuItem key={`spots-1`} value={1}>1</MenuItem>
                                <MenuItem key={`spots-2`} value={2}>2</MenuItem>
                                <MenuItem key={`spots-3`} value={3}>3</MenuItem>
                                <MenuItem key={`spots-4`} value={4}>4</MenuItem>
                                <MenuItem key={`spots-5`} value={5}>5</MenuItem>
                                <MenuItem key={`spots-6`} value={6}>6</MenuItem>
                                <MenuItem key={`spots-7`} value={7}>7</MenuItem>
                                <MenuItem key={`spots-8`} value={8}>8</MenuItem>
                                <MenuItem key={`spots-9`} value={9}>9</MenuItem>
                                <MenuItem key={`spots-10`} value={10}>10</MenuItem>
                              </TextField>
                            </div>
                          </div>

                          <div className="pt-10 mb-5 font-weight-bold text-dark">Professores</div>
                          <div className="mb-1 font-weight-bold font-size-sm">Selecione os professores responsaveis por essa aula</div>

                          <div className="row">
                            <Grid item xs={12}>
                              <ListGroup>
                                {selectedPlayers.map(client => <>
                                  <ListGroup.Item>
                                    <Grid container>
                                      <Grid item xs={9} fontSize="large">
                                        <Chip
                                          size="small"
                                          color={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'default' : 'primary' }
                                          label={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'Cliente' : 'Professor' }
                                          variant="outlined"
                                          style={{
                                            marginRight: 5,
                                          }}
                                        />
                                        {client.attributes.name}
                                      </Grid>
                                      <Grid item xs={3} style={{textAlign: 'right'}}>
                                        <IconButton size="small" aria-label="Delete">
                                          <DeleteIcon  onClick={() => removeSelectedPlayers(client)} />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </ListGroup.Item>
                                </>)}
                                <ListGroup.Item as="a" action style={{textAlign: 'center'}} onClick={() => setPlayerScheduleShow(true)}>
                                  <ControlPoint fontSize="inherit" /> Adicionar professor
                                </ListGroup.Item>
                              </ListGroup>

                              {schedules.error && <RenderError error={schedules.error.participants} />}
                            </Grid>
                          </div>
                        </>}
                      </div>

                      {(modality == 'class' || modality == 'reservation') && <div className="col-xs-12 col-lg-6 col-xl-6 col-xxl-6 py-4">

                        <Grid container className={classes.label}>
                          <Grid item xs={8}>
                            <span className="mb-5 font-weight-bold text-dark">Jogadores</span>
                          </Grid>

                          <Grid item xs={4}>
                            <a className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                              <ControlPoint fontSize="inherit" /> Cadastrar cliente
                            </a>
                          </Grid>
                        </Grid>

                        <div className="mb-1 font-weight-bold font-size-sm">Selecione quem vai jogar essa partida</div>

                        <div className="row mb-5 mx-1">
                          <Grid item xs={12}>
                            <ListGroup>
                              {selectedClients.map(client => <>
                                <ListGroup.Item>
                                  <Grid container>
                                    <Grid item xs={9} fontSize="large">
                                      <Grid item xs={12}>
                                        <span className="font-weight-bolder text-dark">Nome: </span>
                                        <span className="text-dark">{client.name}</span>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <span className="font-weight-bolder text-dark">Início: </span>
                                        <span className="text-dark">{client.start_at && moment(client.start_at).format('DD/MM/YYYY')}</span>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <span className="font-weight-bolder text-dark">Serviço: </span>
                                        <span className="text-dark">{client.service_name}</span>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <span className="text-dark">
                                          {getType(client.service_type)}
                                          {client.service_type == 'recurrence' && ` - ${client.service_recurrence_times}x por semana`}
                                        </span>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={3} style={{textAlign: 'right'}}>
                                      <Dropdown className="dropdown-inline" drop="down" alignRight>
                                        <Dropdown.Toggle
                                            as={DropdownCustomToggler}
                                        id="dropdown-toggle-top">
                                          <i className="ki ki-bold-more-hor" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                          <ul className="navi navi-hover py-5">
                                            <li className="navi-item" onClick={() => removeSelectedClients(client)}>
                                                <a className="navi-link">
                                                    <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                                    <span className="navi-text">Remover</span>
                                                </a>
                                            </li>
                                        </ul>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Grid>
                                  </Grid>
                                </ListGroup.Item>
                              </>)}
                              {/*<ListGroup.Item as="a" action style={{textAlign: 'center'}} onClick={() => openClientSchedule()}>
                                <ControlPoint fontSize="inherit" /> Novo contrato
                              </ListGroup.Item>
                              <ListGroup.Item as="a" action style={{textAlign: 'center'}} onClick={() => openClientSchedule()}>
                                <ControlPoint fontSize="inherit" /> Selecionar contrato
                              </ListGroup.Item>*/}
                            </ListGroup>
                          </Grid>
                          {(modality == 'class' || selectedClients.length < 1) && <Grid item xs={12}>
                            <div class="card-toolbar mt-2">
                              <a class="btn btn-success font-weight-bolder font-size-sm mr-5" onClick={() => openClientSchedule()}>
                                Adicionar jogador
                              </a>
                              {/*{type == 'recurrence' && <a class="btn btn-primary font-weight-bolder font-size-sm" onClick={() => openContractSchedule()}>
                                Associar contrato existente
                              </a>}*/}
                            </div>
                          </Grid>}

                          {schedules.error && <RenderError error={schedules.error.schedule_spots} />}
                        </div>

                        {/*{type == 'recurrence' && <div className="row pb-5">
                          <div className="col-xs-12 col-lg-12 col-xl-12 col-xxl-12">
                            <div class="accordion accordion-toggle-arrow" id="accordionExample1">
                                <div class="card">
                                    <div class="card-header" onClick={() => setShowAccordion(!showAccordion)}>
                                        <div class={showAccordion ? "card-title" : "card-title collapsed"} data-toggle="collapse" data-target="#collapseOne1">
                                            Calendário
                                        </div>
                                    </div>
                                    <div id="collapseOne1" class={showAccordion ? "collapse show" : "collapse"} data-parent="#accordionExample1">
                                        <div class="card-body">
                                          {type == "recurrence" && <div className="col-xl-12 col-xs-12">
                                            <div className="mt-3 font-weight-bold font-size-sm mb-5">Simulação das próximas 10 aulas que vão aparecer no calendário</div>

                                            <div className="separator separator-dashed my-3"></div>
                                            <div className="d-flex align-items-center flex-wrap mb-2">
                                              <div className="mr-2">
                                                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">14/10/2022 - 07:00</a>
                                              </div>
                                            </div>
                                            <div className="separator separator-dashed my-3"></div>
                                            <div className="d-flex align-items-center flex-wrap mb-2">
                                              <div className="mr-2">
                                                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">14/10/2022 - 07:00</a>
                                              </div>
                                            </div>
                                            <div className="separator separator-dashed my-3"></div>
                                            <div className="d-flex align-items-center flex-wrap mb-2">
                                              <div className="mr-2">
                                                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">14/10/2022 - 07:00</a>
                                              </div>
                                            </div>
                                            <div className="separator separator-dashed my-3"></div>
                                            <div className="d-flex align-items-center flex-wrap mb-2">
                                              <div className="mr-2">
                                                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">14/10/2022 - 07:00</a>
                                                <span class="label label-rounded label-primary label-pill label-inline mx-3">Feriado</span>
                                              </div>
                                            </div>
                                            <div className="separator separator-dashed my-3"></div>
                                            <div className="d-flex align-items-center flex-wrap mb-2">
                                              <div className="mr-2">
                                                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">14/10/2022 - 07:00</a>
                                              </div>
                                            </div>
                                            <div className="separator separator-dashed my-3"></div>
                                            <div className="d-flex align-items-center flex-wrap mb-2">
                                              <div className="mr-2">
                                                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">14/10/2022 - 07:00</a>
                                              </div>
                                            </div>
                                            <div className="separator separator-dashed my-3"></div>
                                            <div className="d-flex align-items-center flex-wrap mb-2">
                                              <div className="mr-2">
                                                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">14/10/2022 - 07:00</a>
                                              </div>
                                            </div>
                                            <div className="separator separator-dashed my-3"></div>
                                            <div className="d-flex align-items-center flex-wrap mb-2">
                                              <div className="mr-2">
                                                <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">14/10/2022 - 07:00</a>
                                              </div>
                                            </div>
                                          </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>}*/}

                        <div className="mb-5 font-weight-bold text-dark">Preços</div>
                        <div className="mt-3 font-weight-bold font-size-sm mb-5">Tabela de preços por serviço para o data selecionada</div>

                        {prices.priceService && prices.priceService.filter(item => item.service.attributes.type == 'single').map((item, index) => <>
                          {/*{index == 0 && <div className="mt-3 font-weight-bold font-size-sm mb-5">Serviços avulso:</div>}*/}
                          <div className="separator separator-dashed my-3"></div>
                          <div className="d-flex flex-wrap mb-3">
                            {/*<div className="radio-list">
                              <label className="radio radio-lg mb-7">
                                <input type="radio" name="price" />
                                <span></span>
                              </label>
                            </div>*/}
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                              <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">{item.service.attributes.name}</a>
                              <span className="font-weight-bold" style={{color: getStatusColor(item.service.attributes.type)}}>
                                {getType(item.service.attributes.type)}
                              </span>
                              <span className="text-muted font-weight-bold">
                                Entre {convertMinsToHrsMins(item.service.attributes.minTime)} ~ {convertMinsToHrsMins(item.service.attributes.maxTime)}, a cada {convertMinsToHrsMins(item.service.attributes.granularity)}
                              </span>
                            </div>
                            <span className="label label-lg label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">
                              {item.price != 0.00 && `R$ ${parseFloat(item.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
                              {item.price == 0.00 && `Grátis`}
                            </span>
                          </div>
                        </>)}

                        {prices.priceService && prices.priceService.filter(item => item.service.attributes.type == 'recurrence').map((item, index) => <>
                          {/*{index == 0 && <div className="mt-3 font-weight-bold font-size-sm mb-5">Serviços recorrente:</div>}*/}
                          <div className="separator separator-dashed my-3"></div>
                          <div className="d-flex mb-3">
                            {/*<div className="radio-list">
                              <label className="radio radio-lg mb-7">
                                <input type="radio" name="price" />
                                <span></span>
                              </label>
                            </div>*/}
                            <div className="d-flex flex-column flex-grow-1 mr-2">
                              <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-md mb-1">{item.service.attributes.name}</a>
                              <span className="text-muted font-weight-bold">
                                <span style={{color: getStatusColor(item.service.attributes.type)}}>{getType(item.service.attributes.type)}</span> - {item.service.attributes.recurrence_times}x por semana
                              </span>
                              <span className="text-muted font-weight-bold">
                                Entre {convertMinsToHrsMins(item.service.attributes.minTime)} ~ {convertMinsToHrsMins(item.service.attributes.maxTime)}, a cada {convertMinsToHrsMins(item.service.attributes.granularity)}
                              </span>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="label label-lg label-light label-inline mt-2 text-dark-50 font-weight-bolder mb-2 strikethrough">
                                {item.price != 0.00 && `R$ ${parseFloat(item.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}/mês`}
                                {item.price == 0.00 && `Grátis`}
                              </span>

                              {item.price_quarterly != item.price && item.price_quarterly != 0.00 && <span className="label label-lg label-light label-inline mt-2 text-dark-50 font-weight-bolder mb-2 strikethrough">
                                {`R$ ${parseFloat(item.price_quarterly).toLocaleString('pt-br', { minimumFractionDigits: 2 })}/trimestral`}
                              </span>}

                              {item.price_semester != item.price_quarterly && item.price_semester != 0.00 && <span className="label label-lg label-light label-inline mt-2 text-dark-50 font-weight-bolder mb-2 strikethrough">
                                {`R$ ${parseFloat(item.price_semester).toLocaleString('pt-br', { minimumFractionDigits: 2 })}/semestral`}
                              </span>}

                              {item.price_yearly != item.price_semester && item.price_yearly != 0.00 && <span className="label label-lg label-light label-inline mt-2 text-dark-50 font-weight-bolder mb-2 strikethrough">
                                {`R$ ${parseFloat(item.price_yearly).toLocaleString('pt-br', { minimumFractionDigits: 2 })}/anual`}
                              </span>}
                            </div>
                          </div>
                        </>)}
                      </div>}
                    </div>

                    {(typeof schedules.error) == "string" && <Grid item xs={12}>
                      <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                          <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                            {schedules.error}
                          </span>
                        </div>
                      </div>
                    </Grid>}

                    <div className="d-flex justify-content-between border-top mt-5 pt-10">
                      <div className="mr-2">
                        <button type="button" className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4" onClick={() => setStep(1)}>Voltar</button>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                          data-wizard-type="action-next"
                          disabled={(formik.isSubmitting || schedules.actionsLoading || weekdays.length == 0)}
                          onClick={formik.current?.submitForm}
                        >
                          Cadastrar {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-white"></span>}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </Modal>

  <CreateClient show={createEntityShow} onHide={() => closeCreateClient()} />

  <CreateClientSchedule
    startDateAt={formik.values.startDateAt}
    endDateAt={(type == 'recurrence') && !recurrenceInfinity ? '' : formik.values.recurrenceFinishAt}
    weekdays={weekdays}
    show={clientScheduleShow}
    selectClient={selectClients}
    selectContract={selectContract}
    onHide={() => closeClientSchedule()}
    style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
    modality={modality}
    type={type}
    fieldId={formik.values.fieldId}
    duration={formik.values.duration}
  />

  <SelectParticipant
    show={playerScheduleShow}
    selectClient={selectPlayers}
    onHide={() => closePlayersSchedule()}
    style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
  />

  <SelectContract
    modality={modality}
    field_id={formik.values.fieldId}
    startDateAt={formik.values.startDateAt}
    endDateAt={formik.values.endDateAt}
    weekdays={weekdays}
    show={contractScheduleShow}
    onHide={() => closeContractSchedule()}
    selectContract={selectContract}
  />
  </>);
};

export default CreateSchedule;
