import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import ListGroup from 'react-bootstrap/ListGroup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Chip from '@material-ui/core/Chip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Alert from 'react-bootstrap/Alert';

import {
  fetchApprovePaymentChargeback,
  fetchCancelPaymentChargeback,
} from "../_redux/paymentChargebackActions";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import EditClient from "../../Client/components/EditClient";
import ShowInvoice from "../../Invoice/components/ShowInvoice";

import { notifySuccess } from "../../../utils/Notify";
import DayPicker from '../../../components/DayPicker';
import { RenderError } from "../../../components/RenderError";
import { formatMoney } from "../../../utils/IntegerParse";
import {
  getLabelPaymentChargebackStatus,
  getLabelInvoiceStatus,
  getLabelPaymentChargebackOrigin,
  getColorInvoiceStatus,
  getColorPaymentChargebackStatus,
  getLabelPaymentChargebackRefundType,
} from "../../../utils/Labels";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditPaymentChargeback = (props) => {
  const dispatch = useDispatch();
  const { paymentChargebacks, branches } = useSelector((state) => ({
    paymentChargebacks: state.paymentChargebacks,
    branches: state.branches,
  }), shallowEqual);

  const classes = useStyles();

  const [editClientShow, setEditClientShow] = useState(false);
  const [editClient, setEditClient] = useState({});
  // const [showEditExpiredAt, setShowEditExpiredAt] = useState(false);
  const [event, setEvent] = useState({});
  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  useEffect(() => {
    if (paymentChargebacks.updated == true) {
      close();
      notifySuccess('Atualizado com sucesso', 'paymentChargebacks-updated', 2000);
    }
  }, [paymentChargebacks]);

  // useEffect(() => {
  //   if (!showEditExpiredAt) {
  //     formik.setFieldValue('expired_at', '', false);
  //   }
  // }, [showEditExpiredAt]);

  useEffect(() => {
    // if (creditPurchases.creditPurchase && creditPurchases.creditPurchase.id) {
    //   setEvent(creditPurchases.creditPurchase);

    //   formik.setFieldValue('expiredAt', moment(creditPurchases.creditPurchase.attributes.expired_at).format('YYYY-MM-DD'), false);
    // }
  }, [props.show]);

  useEffect(() => {
    if (props.event && props.event.id && props.show) {
      setEvent(props.event);

      formik.setFieldValue('expiredAt', moment(props.event.attributes.expired_at).format('YYYY-MM-DD'), false);
      console.log(props.event);
      console.log('222222222222');
    } else {
      close();
    }
  }, [props.show]);

  const close = () => {
    props.onHide();

    // setShowEditExpiredAt(false);

    formik.setFieldValue('expiredAt', '', false);

    setEvent({});
    // dispatch(clearCreditPurchase());
  }

  const closeShowInvoice = () => {
    setShowInvoice(false);
    setSelectedInvoice({});
  }

  const openShowInvoice = (invoice) => {
    setShowInvoice(true);
    setSelectedInvoice(invoice);
  }

  const openEditClient = (value) => {
    setEditClient(value);

    setEditClientShow(true);
  }

  const closeEditClient = () => {
    setEditClientShow(false);
  }

  const dayOfWeekAsString = (dayIndex) => {
    return ["Dom", "Seg","Ter","Qua","Qui","Sex","Sab"][dayIndex] || '';
  }

  const getWeekDays = (weekdays) => {
    let string = '';

    weekdays = weekdays.map(weekday => {
      return dayOfWeekAsString(weekday);
    });

    return weekdays.join(', ');
  }

  const getPaymentTypeLabel = (value) => {
    let type = paymentTypes.find(type => type.value == value)

    return type ? type.label : value;
  }

  const getPaymentMethodLabel = (value) => {
    let type = paymentMethods.find(type => type.value == value)

    return type ? type.label : value;
  }

  const approvePaymentChargeback = () => {
    dispatch(fetchApprovePaymentChargeback(event.id));

    close();
  }

  const cancelPaymentChargeback = () => {
    dispatch(fetchCancelPaymentChargeback(event.id));

    close();
  }

  const formik = useFormik({
    initialValues: {
      expiredAt: '',
    },
    // validationSchema: createCreditPurchaseSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        // dispatch(fetchUpdateCreditPurchase(event.id, values));

        setSubmitting(false);
      }, 500);
    },
  });

  const renew = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const headRows = [
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Mês' },
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Valor' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  const paymentTypes = [
    { label: 'Pagamento na unidade', value: 'locally' },
    { label: 'Pagamento Online', value: 'online' },
  ];

  const paymentMethods = [
    { label: 'Não informado', value: 'unknown' },
    { label: 'Crédito', value: 'credit' },
    { label: 'Cartão de crédito', value: 'credit_card' },
    { label: 'Débito', value: 'debit' },
    { label: 'Pix', value: 'pix' },
    { label: 'Dinheiro', value: 'cash' },
  ];

  return (<>
    {event.id && <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !editClientShow && !showInvoice}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Informações da solicitação de estorno
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7 mb-5"></div>

          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Nome</span>: {event.relationships.payment.relationships.invoices[0].relationships.origin.relationships.client.attributes.name}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">E-mail</span>: {event.relationships.payment.relationships.invoices[0].relationships.origin.relationships.client.attributes.email}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Telefone</span>: {event.relationships.payment.relationships.invoices[0].relationships.origin.relationships.client.attributes.phone}
                    </span>
                  </div>
                </div>
              </Grid>

              {!props.hideClient && <Grid item xs={12}>
                <a class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-5" onClick={() => openEditClient(event.relationships.payment.relationships.invoices[0].relationships.origin.relationships.client)}>
                  Ver cliente
                </a>
              </Grid>}
            </Grid>
          </Container>

          <Container maxWidth="sm">
            <Grid container className="mt-5">
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7"></div>

          <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <div className="d-flex flex-column mx-3">


                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Status
                      </label>
                      <div className=" col-12">
                        <label>
                          <span className={`label label-lg label-light-${getColorPaymentChargebackStatus(event.relationships.status.attributes.name)} label-inline`} style={{minWidth: 'max-content'}}>
                            {getLabelPaymentChargebackStatus(event.relationships.status.attributes.name)}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Serviço
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>
                          {event.relationships.payment.relationships.invoices[0].relationships.origin.type == 'credit_purchase' && event.relationships.payment.relationships.invoices[0].relationships.origin.plan.attributes.name}
                          {event.relationships.payment.relationships.invoices[0].relationships.origin.type == 'signature' && event.relationships.payment.relationships.invoices[0].relationships.origin.relationships.plan.attributes.name}
                          {event.relationships.payment.relationships.invoices[0].relationships.origin.type == 'day_use' && event.relationships.payment.relationships.invoices[0].relationships.originrelationships.plan.attributes.name}
                          {event.relationships.payment.relationships.invoices[0].relationships.origin.type == 'contract' && event.relationships.payment.relationships.invoices[0].relationships.origin.relationships.service.attributes.name}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/*<div className="col-4">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Quantidade
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>3 créditos
                        </label>
                      </div>
                    </div>
                  </div>*/}
                  <div className="col-6">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Solicitado pelo
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>
                          {getLabelPaymentChargebackOrigin(event.attributes.origin)}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Solicitado em
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>
                          {event.attributes.payment_requested_at && moment(event.attributes.payment_requested_at).format('DD/MM/YYYY HH:mm')}
                        </label>
                      </div>
                    </div>
                  </div>
                  {event.attributes.paid_at && <div className="col-6">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Estorno pago em
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>
                          {event.attributes.paid_at && moment(event.attributes.paid_at).format('DD/MM/YYYY HH:mm')}
                        </label>
                      </div>
                    </div>
                  </div>}

                  {event.attributes.refund_type && <div className="col-6">
                    <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Tipo de estorno
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>
                          {getLabelPaymentChargebackRefundType(event.attributes.refund_type)}
                        </label>
                      </div>
                    </div>
                  </div>}

                  {event.attributes.observation && <div className="col-4">
                    <div className="row">
                      <label className="col-12 col-form-label font-weight-bold text-left">
                        Observação
                      </label>
                      <div className=" col-12">
                        <label>
                          <span></span>{event.attributes.observation}
                        </label>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </Grid>
          </Container>

          {event.relationships.payment.relationships.invoices[0].attributes && <>
            <Container maxWidth="sm">
              <Grid container>
                <Grid item xs={12}>
                  <span className="font-weight-bolder text-dark">Fatura</span>
                </Grid>
              </Grid>
            </Container>

            <div className="separator separator-dashed mx-7 mb-5"></div>

            <Container maxWidth="sm">
              <Grid container className={classes.label} spacing={2}>
                <div className="row mx-4">
                  <Grid container spacing={2}>
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <label className="col-12  col-form-label font-weight-bold text-left">
                            Status
                          </label>
                          <div className=" col-12">
                            <span className={`label label-md label-light-${getColorInvoiceStatus(event.relationships.payment.relationships.invoices[0])} label-inline`}>
                              {getLabelInvoiceStatus(event.relationships.payment.relationships.invoices[0])}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col">
                        <div className="row">
                          <label className="col-12  col-form-label font-weight-bold text-left">
                            Valor total
                          </label>
                          <div className=" col-12">
                            <label>
                              <span></span>{formatMoney(event.relationships.payment.relationships.invoices[0].attributes.full_price)}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col mw-300">
                        <div className="row">
                          <label className="col-12 col-form-label font-weight-bold text-right">
                            <a
                              title="Ver fatura"
                              className="btn btn-light btn-hover-primary btn-sm mx-3"
                              onClick={() => openShowInvoice(event.relationships.payment.relationships.invoices[0])}
                            >
                              <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                  title="Ver fatura"
                                  src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                /> Abrir
                              </span>
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <label className="col-12  col-form-label font-weight-bold text-left">
                        Forma de pagamento
                      </label>
                      <div className="col-12">
                        <label>
                          <span></span>
                          {getPaymentTypeLabel(event.relationships.payment.relationships.invoices[0].attributes.payment_type)}
                          {" - "}
                          {getPaymentMethodLabel(event.relationships.payment.relationships.invoices[0].attributes.payment_method)}
                        </label>
                      </div>
                    </div>
                  </Grid>
                </div>
              </Grid>
            </Container>
          </>}
        </Modal.Body>

        <Modal.Footer>
          <Grid container spacing={3}>
            <>
              <Grid item xs={6}>
                <span className=" font-size-h4 text-dark-50 font-weight-bolder mb-2">
                  Estorno solicitado
                </span>
              </Grid>
              <Grid item xs={6} className="text-right">
                {/*<span class="label label-xl font-weight-boldest mr-2 cursor-pointer" onClick={() => console.log('1231231')}>
                  <i class="fas fa-pen"></i>
                </span>*/}
                <span className=" font-size-h4 text-dark-50 font-weight-bolder mb-2 text-right">
                  {formatMoney(event.attributes.price)}
                </span>
              </Grid>
            </>

            {event.relationships && event.relationships.status && event.relationships.status.attributes.name == 'pending' && <>
              <Grid item xs={6} className="text-right">
                <Button style={{backgroundColor: '#dafb6d', color: '#1F2933'}} variant="contained" size="large" onClick={() => approvePaymentChargeback()}>
                  Aprovar
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button style={{backgroundColor: '#ff6565', color: 'white'}} variant="contained" size="large" onClick={() => cancelPaymentChargeback()}>
                  Recusar
                </Button>
              </Grid>
            </>}

            <Grid item xs={12} style={{textAlign: 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>}

    <ShowInvoice show={showInvoice} onHide={() => closeShowInvoice()} invoice={selectedInvoice} />
    <EditClient show={editClientShow} onHide={closeEditClient} event={editClient} />
  </>);
};

export default EditPaymentChargeback;