import axios from "axios";

export const GET_BRANCH_INTEGRATION = `${process.env.REACT_APP_MARACANA_URL}/branches/info`;
export const GET_SERVICES = `${process.env.REACT_APP_API_URL}/branches`;
export const CREATE_SERVICE = `${process.env.REACT_APP_API_URL}/branches`;
export const UPDATE_SERVICE = `${process.env.REACT_APP_API_URL}/branches`;

export function getBranches() {
  return axios.get(GET_SERVICES);
}

export function createBranch(name, address, business_hours, max_period, phone, tags, zipcode) {
  let params = {
    name: name,
    address: address,
    business_hours: business_hours,
    max_period: max_period,
    phone: phone,
    tags: tags,
    zipcode: zipcode
  };

  return axios.post(CREATE_SERVICE, params);
}

export function updateBranch(branchId, params) {
  return axios.put(`${UPDATE_SERVICE}/${branchId}`, params);
}

export function getBranchAppIntegration(id) {
  return axios.get(`${GET_BRANCH_INTEGRATION}`, {
    params: {
      partner_id: id,
    }
  });
}