import * as requestFromServer from "./paymentCrud";
import { paymentSlice, callTypes } from "./paymentSlice";
import { notifyError } from "../../../utils/Notify";

const { actions } = paymentSlice;

export const fetchPayments = (data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    start_at: data.start_at,
    finish_at: data.finish_at,
    sort: '-created_at'
  };

  if (data.branchId) {
    params.branch_id = data.branchId;
  }

  if (data.statusId) {
    params.status_id = data.statusId;
  }

  return requestFromServer
    .getPayments(params)
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.paymentsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.paymentMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.paymentsFetched({ totalCount: 0, entities: null }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'payment-list-error', 2000);
      }
    });
};