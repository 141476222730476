import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
// import * as calendar from "../app/modules/Calendar/_redux/calendar";
import {fieldSlice} from "../app/modules/Field/_redux/fieldSlice";
import {scheduleSlice} from "../app/modules/Calendar/_redux/scheduleSlice";
import {priceSlice} from "../app/modules/Price/_redux/priceSlice";
import {serviceSlice} from "../app/modules/Service/_redux/serviceSlice";
import {planSlice} from "../app/modules/Plan/_redux/planSlice";
import {clientSlice} from "../app/modules/Client/_redux/clientSlice";
import {branchSlice} from "../app/modules/Branch/_redux/branchSlice";
import {branchRecipientSlice} from "../app/modules/BranchRecipient/_redux/branchRecipientSlice";
import {accountSlice} from "../app/modules/Account/_redux/accountSlice";
import {tagSlice} from "../app/modules/Tag/_redux/tagSlice";
import {invoiceSlice} from "../app/modules/Invoice/_redux/invoiceSlice";
import {contractSlice} from "../app/modules/Contract/_redux/contractSlice";
import {signatureSlice} from "../app/modules/Signature/_redux/signatureSlice";
import {dayUseSlice} from "../app/modules/DayUse/_redux/dayUseSlice";
import {logSlice} from "../app/modules/Log/_redux/logSlice";
import {reportSlice} from "../app/modules/Report/_redux/reportSlice";
import {permissionSlice} from "../app/modules/Configuration/Permission/_redux/permissionSlice.js";
import {userSlice} from "../app/modules/Configuration/User/_redux/userSlice.js";
import {creditSlice} from "../app/modules/Credit/_redux/creditSlice";
import {couponSlice} from "../app/modules/Coupon/_redux/couponSlice";
import {creditPurchaseSlice} from "../app/modules/CreditPurchase/_redux/creditPurchaseSlice";
import {paymentSlice} from "../app/modules/Payment/_redux/paymentSlice";
import {paymentChargebackSlice} from "../app/modules/PaymentChargeback/_redux/paymentChargebackSlice";
import {bankSlice} from "../app/modules/Bank/_redux/bankSlice";
import {withdrawalSlice} from "../app/modules/Withdrawal/_redux/withdrawalSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  // calendar: calendar.reducer,
  fields: fieldSlice.reducer,
  schedules: scheduleSlice.reducer,
  prices: priceSlice.reducer,
  services: serviceSlice.reducer,
  plans: planSlice.reducer,
  clients: clientSlice.reducer,
  branches: branchSlice.reducer,
  branchRecipients: branchRecipientSlice.reducer,
  account: accountSlice.reducer,
  tags: tagSlice.reducer,
  invoices: invoiceSlice.reducer,
  contracts: contractSlice.reducer,
  signatures: signatureSlice.reducer,
  dayUses: dayUseSlice.reducer,
  logs: logSlice.reducer,
  reports: reportSlice.reducer,
  permissions: permissionSlice.reducer,
  users: userSlice.reducer,
  credits: creditSlice.reducer,
  coupons: couponSlice.reducer,
  creditPurchases: creditPurchaseSlice.reducer,
  payments: paymentSlice.reducer,
  paymentChargebacks: paymentChargebackSlice.reducer,
  banks: bankSlice.reducer,
  withdrawals: withdrawalSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
