import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import ListGroup from 'react-bootstrap/ListGroup'

import CloseIcon from '@material-ui/icons/Close';

// import { fetchCreateClient } from "../_redux/clientActions";

// import { createClientSchema } from "../_schemas/createClientSchema";
import { fetchClientName } from "../../Client/_redux/clientActions";

import Snackbar from "../../../components/Snackbar";
import DetailedExpansionPanel from "../../../components/DetailedExpansionPanel";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const UpdateClientSchedule = (props) => {
  const dispatch = useDispatch();
  const { clients, branchSelected } = useSelector((state) => ({
    clients: state.clients,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();

  const [selectedClient, setSelectedClient] = useState({});
  const [showClient, setShowClient] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [checked, setChecked] = useState(false);
  const [type, setType] = useState('single');
  const [startDateAt, setStartDateAt] = useState('');
  const [finishDateAt, setFinishDateAt] = useState('infinite');
  const [weekdays, setWeekdays] = useState([]);

  useEffect(() => {
    if (clients.created == true) {
      close();
      setOpenSuccessMessage(true);
    }
  }, [clients]);

  useEffect(() => {
    if (props.service && props.service.attributes) {
      setType(props.service.attributes.type);
    }
  }, [props.service]);

  useEffect(() => {
    if (props.startDateAt) {
      setStartDateAt(props.startDateAt);
    }
  }, [props.startDateAt]);

  useEffect(() => {
    if (props.weekdays) {
      setWeekdays(props.weekdays);
    }
  }, [props.weekdays]);

  const isClient = () => {
    return props.type == 'instructor' ? null : true;
  }

  const close = () => {
    props.onHide();
    setShowClient(false);
    clearSelectClient();

    formik.setFieldValue('name', '', false);
  }

  const selectClient = (client) => {
    console.log({client});
    setSelectedClient(client);

    if (type == 'single') {
      dispatchClient(client);

      close();
    }
    // formik.setFieldValue('name', '', false);
    // formik.setFieldValue('clientId', client.id, false);
  }

  const selectRecurrenceClient = () => {
    dispatchClient(selectedClient);

    close();
  }

  const dispatchClient = (attributes) => {
    let client = JSON.parse(JSON.stringify(attributes));

    if (type == 'recurrence') {
      client.attributes.start_at = startDateAt;
      client.attributes.recurrence_days = weekdays.reduce((x, y) => x + y, 0);
      client.attributes.billing_day = formik.values.billing_day;

      if (finishDateAt != 'infinite') {
        client.attributes.finish_at = moment(startDateAt).add(finishDateAt, 'months').format('YYYY-MM-DD')
      }
    }

    if (props.selectClient) {
      props.selectClient(client);
    }
  }

  const clearSelectClient = () => {
    setSelectedClient({});
    // formik.setFieldValue('clientId', '', false);
  }

  const changedCheck = (value) => {
    setChecked(!checked);
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const renderError = (error) => {
    return (error ? (
      <Grid item xs={12}>
        <div className="fv-plugins-message-container">
          {(typeof error) == "string" &&
            <div className="fv-help-block">{error}</div>
          }

          {Array.isArray(error) && error.map((item) =>
            <div className="fv-help-block">{item}</div>
          )}
        </div>
      </Grid>
    ) : null);
  }

  const searchClientName = () => {
    if (formik.values.name && formik.values.name.length >= 2) {
      const clientNameRequestTimeout = setTimeout(() => {
        dispatch(fetchClientName(formik.values.name, branchSelected.id));
        setShowClient(true);
      }, 400);

      return () => {
        clearTimeout(clientNameRequestTimeout);
      };
    }
  }

  const typeClass = (item) => {
    return type == item ? "contained" : "outlined";
  }

  const styleWeekDay = (weekday) => {
    if (!checkWeekDay(weekday)) {
      return {
        border: '1px solid rgb(158 158 158 / 27%)',
        color: 'rgb(0 0 0 / 29%)'
      };
    }

    return {};
  }

  const selectedWeekDayColor = (weekday) => {
    return weekdays.includes(weekday) ? "primary" : "default";
  }

  const checkWeekDay = (weekday) => {
    if (props.weekdays) {
      return props.weekdays.includes(weekday) ? true : false;
    }

    return true;
  }

  const selectWeekDay = (weekday) => {
    if (checkWeekDay(weekday)) {
      if(!weekdays.includes(weekday)) {
        if (props.service.attributes && weekdays.length < props.service.attributes.recurrence_times) {
          setWeekdays([...weekdays, weekday]);
        }

        if (props.service.attributes && props.service.attributes.recurrence_times == 1) {
          setWeekdays([weekday]);
        }
      } else{
          var array = [...weekdays];
          var index = array.indexOf(weekday);

          if (index !== -1) {
            array.splice(index, 1);

            setWeekdays(array);
          }
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
    },
    // validationSchema: createClientScheduleSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        values.phone = values.phone.replace(/\D/g,'');

        values.type = isClient() ? 'client' : 'instructor';

        // dispatch(fetchCreateClientSchedule(values));

        setSubmitting(false);
      }, 500);
    },
  });

  useEffect(() => {
    return searchClientName();
  }, [formik.values.name]);

  return (<>
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Associar cliente
          </Modal.Title>
          <CloseIcon onClick={close} />
        </Modal.Header>
        <Modal.Body>
          {!selectedClient.id && <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Pesquisar por nome"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {clients.actionsLoading && <CircularProgress size={30} />}
                      </InputAdornment>
                    ),
                  }}
                  {...formik.getFieldProps("name")}
                />

                {formik.touched.name && formik.errors.clientId ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.clientId}</div>
                  </div>
                ) : null}
              </Grid>

              {showClient && <Grid item xs={12}>
                {clients.clientName.data && clients.clientName.data.map((client) =>
                  <div className={classes.detailedExpansionPanel}>
                    <DetailedExpansionPanel client={client} onSelect={selectClient} />
                  </div>
                )}
                {clients.clientName.data && !clients.clientName.data.length && <span>Nenhum cliente encontrado</span>}
              </Grid>}
            </Grid>

            {(typeof clients.error) == "string" && <Grid item xs={12}>
              <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                    {clients.error}
                  </span>
                </div>
              </div>
            </Grid>}
          </Container>}


          {selectedClient.id && type == 'recurrence' && <Container maxWidth="sm">
            <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Contrato
                </span>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="startDateAt"
                  label="Inicio contrato"
                  type="date"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={startDateAt}
                  onChange={(input) => setStartDateAt(input.currentTarget.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="billing_day"
                  label="Dia vencimento"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  autoComplete="off"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  {...formik.getFieldProps("billing_day")}
                />

                {formik.touched.name && formik.errors.billing_day ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.billing_day}</div>
                  </div>
                ) : null}
              </Grid>

              {/*<Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Selecione {props.service && props.service.attributes && props.service.attributes.recurrence_times} dia(s) da semana
                </span>
              </Grid>

              <Grid container className={classes.label} spacing={1}>
                <Grid item xs><Chip onClick={() => selectWeekDay(2)}  clickable={checkWeekDay(2)} color={selectedWeekDayColor(2)} style={styleWeekDay(2)} variant="outlined" label="Seg" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(4)}  clickable={checkWeekDay(4)} color={selectedWeekDayColor(4)} style={styleWeekDay(4)} variant="outlined" label="Ter" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(8)}  clickable={checkWeekDay(8)} color={selectedWeekDayColor(8)} style={styleWeekDay(8)} variant="outlined" label="Qua" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(16)}  clickable={checkWeekDay(16)} color={selectedWeekDayColor(16)} style={styleWeekDay(16)} variant="outlined" label="Qui" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(32)}  clickable={checkWeekDay(32)} color={selectedWeekDayColor(32)} style={styleWeekDay(32)} variant="outlined" label="Sex" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(64)}  clickable={checkWeekDay(64)} color={selectedWeekDayColor(64)} style={styleWeekDay(64)} variant="outlined" label="Sab" /></Grid>
                <Grid item xs><Chip onClick={() => selectWeekDay(1)}  clickable={checkWeekDay(1)} color={selectedWeekDayColor(1)} style={styleWeekDay(1)} variant="outlined" label="Dom" /></Grid>
              </Grid>*/}

              {/*<Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Dados do responsável
                </span>
              </Grid>*/}

              {/*<Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">Guilherme Duarte</div>
                </div>
              </Grid>*/}

            {/* Forma de pagamento */}
              {/*<Grid item xs={12}>
                <span className="font-weight-bolder text-dark">
                  Forma de pagamento
                </span>
              </Grid>

              <Grid container className={classes.label} spacing={2}>
                <Grid item xs={6}>
                  <Button fullWidth variant={typeClass('single1')} onClick={() => setType('single1')} color="primary" size="large">
                    Dinheiro
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={typeClass('recurrence')} onClick={() => setType('recurrence')} color="primary" size="large">
                    Débito
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={typeClass('unavailable')} onClick={() => setType('unavailable')} color="primary" size="large">
                    Crédito
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={typeClass('unavailable')} onClick={() => setType('unavailable')} color="primary" size="large">
                    Pix
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant={typeClass('unavailable')} onClick={() => setType('unavailable')} color="primary" size="large">
                    Cheque
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={classes.label} spacing={2}>
              <Grid item xs={12}>
                <span className={`switch switch-sm ${checked ? 'switch-success' :  'switch-danger'}`}>
                  <label>
                    <input
                      type="checkbox"
                      name="sendCopyToPersonalEmail"
                      checked={checked}
                      onChange={changedCheck}
                    />
                    <span></span>
                  </label>
                </span>
                <span className="text-dark">
                  Pagamento realizado?
                </span>
              </Grid>
              </Grid>*/}
            </Grid>
          </Container>}
        </Modal.Body>
        {selectedClient.id && <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" onClick={selectRecurrenceClient}>
                Confirmar
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>}
      </form>
    </Modal>

    <Snackbar
      duration={5000}
      message="Cadastrado com sucesso"
      type="success"
      open={openSuccessMessage}
      close={() => setOpenSuccessMessage(false)}
    />
  </>);
};

export default UpdateClientSchedule;