import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import { Modal, Dropdown } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import ControlPoint from '@material-ui/icons/ControlPoint';

import { fetchCreateService } from "../_redux/serviceActions";
import { fetchFields } from "../../Field/_redux/fieldActions";
import {
  fetchCreatePrice,
} from "../../Price/_redux/priceActions";

import { createServiceSchema } from "../_schemas/createServiceSchema";

import { notifySuccess } from "../../../utils/Notify";

import CreatePrice from '../../Price/components/CreatePrice';
import CreateCreditRestriction from './CreateCreditRestriction';
import { RenderError } from "../../../components/RenderError";
import { getWeekDays } from "../../../components/DateUtils";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const CreateService = (props) => {
  const dispatch = useDispatch();
  const { services, fields, branchSelected } = useSelector((state) => ({
    services: state.services,
    fields: state.fields,
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const classes = useStyles();

  const [showClient, setShowClient] = useState(false);
  const [prices, setPrices] = useState([]);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [createCreditRestrictionShow, setCreateCreditRestrictionShow] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [creditRestrictions, setCreditRestrictions] = useState([]);

  useEffect(() => {
    if (props.show) {
      dispatch(fetchFields(branchSelected.id));

      if (props.modality && props.type) {
        formik.setFieldValue('modality', props.modality, false);
        formik.setFieldValue('type', props.type, false);
      }
    }
  }, [props.show]);

  useEffect(() => {
    if (services.created == true) {
      close();
      notifySuccess('Cadastrado com sucesso', 'services-created', 2000);
    }
  }, [services]);

  const close = () => {
    props.onHide();
    setShowClient(false);

    formik.setFieldValue('name', '', false);
    formik.setFieldValue('minTime', '60', false);
    formik.setFieldValue('maxTime', '120', false);
    formik.setFieldValue('granularity', '30', false);
    formik.setFieldValue('modality', '', false);
    formik.setFieldValue('type', '', false);
    formik.setFieldValue('recurrenceTimes', '1', false);
    formik.setFieldValue('billingType', 'monthly', false);
    formik.setFieldValue('availableApp', 'no', false);
    formik.setFieldValue('package3Unit', '', false);
    formik.setFieldValue('package5Unit', '', false);
    formik.setFieldValue('package10Unit', '', false);

    setPrices([]);
    setSelectedFields([]);
    setCreditRestrictions([]);
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const ValueLabelComponent = (props) => {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={`${value}C*`}>
        {children}
      </Tooltip>
    );
  }

  const requestCreateService = (values) => {
    if (values.type == 'unavailable') {
      values.availableApp = 'no';
    }

    if (values.type == 'single' && values.availableApp == 'yes') {
      values.package = [
        {
          amount: 3,
          price: values.package3Unit,
        },
        {
          amount: 5,
          price: values.package5Unit,
        },
        {
          amount: 10,
          price: values.package10Unit,
        }
      ];

      if (creditRestrictions) {
        values.creditRestriction = creditRestrictions
      }
    }

    if (values.type == 'single' || values.type == 'unavailable') {
      delete values.billingType
      delete values.recurrenceTimes
    }

    values.fieldId = selectedFields;

    dispatch(fetchCreateService(values)).then((service) => {
      if (values.type != 'unavailable') {
        prices.map(price => {
          price.serviceId = service.id;

          dispatch(fetchCreatePrice(price));
        });
      }
    });
  }

  const fieldCheck = (fieldId) => {
    if (fieldIsChecked(fieldId)) {
      setSelectedFields(selectedFields.filter(arrayItem => arrayItem !== fieldId));
    } else {
      setSelectedFields([...selectedFields, fieldId]);
    }
  }

  const fieldIsChecked = (fieldId) => {
    return selectedFields.includes(fieldId);
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      minTime: 60,
      maxTime: 120,
      granularity: 30,
      type: '',
      modality: '',
      recurrenceTimes: '1',
      billingType: 'monthly',
      availableApp: 'no',
      package3Unit: '',
      package5Unit: '',
      package10Unit: '',
    },
    validationSchema: createServiceSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {

      setTimeout(() => {
        requestCreateService(values);

        setSubmitting(false);
      }, 100);
    },
  });

  const sliderLabel = (value) => {
    return `${value}°C`;
  };

  const type = [
    { label: 'Avulso', value: 'single' },
    { label: 'Mensalista', value: 'recurrence' },
  ];

  const billingTypes = [
    { label: 'Mensal', value: 'monthly' },
    { label: 'Por evento', value: 'event' },
  ];

  const modalities = [
    { label: 'Locação', value: 'reservation' },
    { label: 'Aula', value: 'class' },
  ];

  const availableApps = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const getTime = (time) => {
    return `Valor referente a ${time} minutos`;
  }

  const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
      <a
        ref={ref}
        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        onClick={e => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        {props.children}
      </a>
    );
  });

  const createPrice = (price) => {
    setPrices([...prices, price]);
    setCreateEntityShow(false);
  }

  const removePrice = (removeIndex) => {
    setPrices(prices.filter((arrayPrice, index) => index !== removeIndex));
  }

  const addCreditRestrictions = (restriction) => {
    setCreditRestrictions([...creditRestrictions, restriction]);
    setCreateCreditRestrictionShow(false);
  }

  const removeCreditRestriction = (removeIndex) => {
    setCreditRestrictions(creditRestrictions.filter((arrayPrice, index) => index !== removeIndex));
  }

  return (<>
    {!createEntityShow && !createCreditRestrictionShow && <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      contenStyle={{maxWidth: '1000px', backgroundColor: 'transparent !important'}}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Cadastrar serviço
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container maxWidth="xl">
            <Grid container className={classes.label}>
              <Grid item xs={6}>
                <Grid container className={classes.label}>
                  <Grid item xs={12}>
                    <span className="font-weight-bolder text-dark">
                      Dados de exibição
                    </span>
                  </Grid>
                </Grid>

                <Grid container className={classes.label} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label="Nome"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      {...formik.getFieldProps("name")}
                    />

                    {services.error && <RenderError error={services.error.name} />}

                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.name}</div>
                      </div>
                    ) : null}
                  </Grid>

                  {!props.modality && !props.type && <>
                    <Grid item xs={12}>
                      <span className="font-weight-bolder text-dark">
                        Modalidade
                      </span>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        select
                        label="Modalidade"
                        variant="outlined"
                        SelectProps={{
                          MenuProps: {
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            }
                          }
                        }}
                        name="modalityId"
                        fullWidth
                        className={classes.textField}
                        {...formik.getFieldProps("modality")}
                      >
                        {modalities.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                      </TextField>

                      {services.error && <RenderError error={services.error.modality} />}
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        select
                        label="Tipo de agendamento"
                        variant="outlined"
                        SelectProps={{
                          MenuProps: {
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            }
                          }
                        }}
                        name="fieldId"
                        fullWidth
                        className={classes.textField}
                        {...formik.getFieldProps("type")}
                      >
                        {type.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                      </TextField>

                      {services.error && <RenderError error={services.error.type} />}
                    </Grid>
                  </>}

                  <Grid item xs={12}>
                    <span className="font-weight-bolder text-dark">
                      Defina o período <Icon className={classes.icon}>info_outlined</Icon>
                    </span>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="minTime"
                      label="Tempo minimo"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
                      }}
                      {...formik.getFieldProps("minTime")}
                    />

                    {services.error && <RenderError error={services.error.min_time} />}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Granularidade"
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          }
                        }
                      }}
                      name="granularity"
                      fullWidth
                      className={classes.textField}
                      {...formik.getFieldProps("granularity")}
                    >
                      <MenuItem key="15" value="15">15 minutos</MenuItem>
                      <MenuItem key="30" value="30">30 minutos</MenuItem>
                      <MenuItem key="60" value="60">1 hora</MenuItem>
                    </TextField>

                    {services.error && <RenderError error={services.error.granularity} />}
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      name="maxTime"
                      label="Tempo máximo"
                      fullWidth
                      className={`${classes.textField} is-invalid`}
                      variant="outlined"
                      autoComplete="new-password"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">minutos</InputAdornment>,
                      }}
                      {...formik.getFieldProps("maxTime")}
                    />

                    {services.error && <RenderError error={services.error.max_time} />}
                  </Grid>

                  {formik.getFieldProps('type').value == 'recurrence' && <>
                    <Grid item xs={12}>
                      <span className="font-weight-bolder text-dark">
                        Configuração mensal
                      </span>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="recurrenceTimes"
                        label="Agendamentos"
                        fullWidth
                        className={`${classes.textField} is-invalid`}
                        variant="outlined"
                        autoComplete="new-password"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">por semana</InputAdornment>,
                        }}
                        {...formik.getFieldProps("recurrenceTimes")}
                      />

                      {services.error && <RenderError error={services.error.recurrence_times} />}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        select
                        label="Tipo de cobrança"
                        variant="outlined"
                        SelectProps={{
                          MenuProps: {
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            }
                          }
                        }}
                        name="billingType"
                        fullWidth
                        className={classes.textField}
                        {...formik.getFieldProps("billingType")}
                      >
                        {billingTypes.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                      </TextField>

                      {services.error && <RenderError error={services.error.billing_type} />}
                    </Grid>

                    {formik.getFieldProps('billingType').value == 'event' && <Grid item xs={12}>
                      <Alert key="alert-accept-schedule" variant="secondary">
                        Caso o ciclo de cobrança tenha 5 eventos, será adicionado 25% do valor mensal na fatura do mês vigente.
                      </Alert>
                      <Alert key="alert-accept-schedule" variant="secondary">
                        Para faturas proporcional cada evento terá o custo de 25% do valor mensal.
                      </Alert>
                    </Grid>}
                  </>}

                  {formik.getFieldProps('type').value != 'unavailable' && <>
                    {formik.getFieldProps('type').value == 'single' && <>
                      <Grid item xs={12}>
                        <span className="font-weight-bolder text-dark">
                          Aplicativo <Icon className={classes.icon}>info_outlined</Icon>
                        </span>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          select
                          label="Disponivel no app"
                          variant="outlined"
                          SelectProps={{
                            MenuProps: {
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              }
                            }
                          }}
                          name="availableApp"
                          fullWidth
                          className={classes.textField}
                          {...formik.getFieldProps("availableApp")}
                        >
                          {availableApps.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                        </TextField>

                        {services.error && <RenderError error={services.error.available_app} />}
                      </Grid>

                      {formik.getFieldProps('availableApp').value == 'yes' && <>
                        <Grid item xs={12}>
                          <span className="font-weight-bolder text-dark">
                            Pacotes de créditos
                          </span>
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            name="package3Unit"
                            label="3 créditos"
                            fullWidth
                            className={`${classes.textField} is-invalid`}
                            variant="outlined"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            {...formik.getFieldProps("package3Unit")}
                          />

                          {services.error && <RenderError error={services.error['package.0.price']} />}
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            name="package5Unit"
                            label="5 créditos"
                            fullWidth
                            className={`${classes.textField} is-invalid`}
                            variant="outlined"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            {...formik.getFieldProps("package5Unit")}
                          />

                          {services.error && <RenderError error={services.error['package.1.price']} />}
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            name="package10Unit"
                            label="10 créditos"
                            fullWidth
                            className={`${classes.textField} is-invalid`}
                            variant="outlined"
                            InputProps={{
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            {...formik.getFieldProps("package10Unit")}
                          />

                          {services.error && <RenderError error={services.error['package.2.price']} />}
                        </Grid>

                        {formik.values.type == 'single' && formik.values.modality == 'reservation' && <>
                          <Grid item xs={6}>
                            <span className="font-weight-bolder text-dark">
                              Restrição no estorno de créditos
                            </span>
                          </Grid>

                          <Grid item xs={6}>
                            <a className="font-weight-bold" onClick={() => setCreateCreditRestrictionShow(true)}>
                              <ControlPoint fontSize="inherit" /> Cadastrar restrição de crédito
                            </a>
                          </Grid>

                          <Grid item xs={12}>
                            <ListGroup>
                              {creditRestrictions && creditRestrictions.length == 0 && <div className="d-flex flex-center text-center">
                                Nenhuma restrição cadastrada
                              </div>}

                              {creditRestrictions && creditRestrictions.map((value, index) => <>
                                <ListGroup.Item>
                                  <Grid container>
                                    <Grid item xs={9} fontSize="large">
                                      {value.range?.map(date => <p style={{fontSize: '1em'}}>
                                        <span className="text-dark">
                                          <span className="font-weight-bolder text-dark">Hórario:</span> {date.start_minutes} até {date.end_minutes}
                                        </span>
                                      </p>)}

                                      {/*<p style={{fontSize: '1em'}}>
                                        <span className="text-dark">
                                          <span className="font-weight-bolder text-dark">Hórario:</span> 00:00 até 17:59
                                        </span>
                                      </p>

                                      <p style={{fontSize: '1em'}}>
                                        <span className="text-dark">
                                          <span className="font-weight-bolder text-dark">Hórario:</span> 18:00 até 23:59
                                        </span>
                                      </p>*/}

                                      <p style={{fontSize: '1em'}}>
                                        <span className="text-dark">
                                          <span className="font-weight-bolder text-dark">Dias:</span> {getWeekDays(value.selected_weekdays, true)}
                                        </span>
                                      </p>
                                    </Grid>

                                    <Grid item xs={3} style={{textAlign: 'right'}}>
                                      <Dropdown className="dropdown-inline" drop="down" alignRight>
                                        <Dropdown.Toggle
                                            as={DropdownCustomToggler}
                                        id="dropdown-toggle-top">
                                          <i className="ki ki-bold-more-hor" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                          <ul className="navi navi-hover py-5">
                                            <li className="navi-item" onClick={() => removeCreditRestriction(index)}>
                                              <a className="navi-link">
                                                <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                                <span className="navi-text">
                                                  Remover
                                                </span>
                                              </a>
                                            </li>
                                        </ul>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </Grid>
                                  </Grid>
                                </ListGroup.Item>
                              </>)}

                              {/*<ListGroup.Item>
                                <Grid container>
                                  <Grid item xs={9} fontSize="large">
                                    <p style={{fontSize: '1em'}}>
                                      <span className="text-dark">
                                        <span className="font-weight-bolder text-dark">Hórario:</span> 19:00 até 23:59
                                      </span>
                                    </p>

                                    <p style={{fontSize: '1em'}}>
                                      <span className="text-dark">
                                        <span className="font-weight-bolder text-dark">Dias:</span> {getWeekDays([5], true)}
                                      </span>
                                    </p>
                                  </Grid>

                                  <Grid item xs={3} style={{textAlign: 'right'}}>
                                    <Dropdown className="dropdown-inline" drop="down" alignRight>
                                      <Dropdown.Toggle
                                          as={DropdownCustomToggler}
                                      id="dropdown-toggle-top">
                                        <i className="ki ki-bold-more-hor" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                        <ul className="navi navi-hover py-5">
                                          <li className="navi-item" onClick={() => console.log(true)}>
                                            <a className="navi-link">
                                              <span className="navi-icon"><i className="flaticon-edit-1"></i></span>
                                              <span className="navi-text">
                                                Editar
                                              </span>
                                            </a>
                                          </li>
                                          <li className="navi-item" onClick={() => console.log(true)}>
                                            <a className="navi-link">
                                              <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                              <span className="navi-text">
                                                Remover
                                              </span>
                                            </a>
                                          </li>
                                      </ul>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Grid>

                                </Grid>
                              </ListGroup.Item>*/}
                            </ListGroup>
                          </Grid>
                        </>}
                      </>}
                    </>}

                  </>}
                </Grid>
              </Grid>

              <Grid item xs={6}>
                {formik.getFieldProps('type').value != 'unavailable' && <Grid container className={`${classes.label} ml-5`}>
                  <Grid item xs={8}>
                    <span className="font-weight-bolder text-dark">
                      Preços <Icon className={classes.icon}>info_outlined</Icon>
                    </span>
                  </Grid>

                  <Grid item xs={4}>
                    <a className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                      <ControlPoint fontSize="inherit" /> Cadastrar preço
                    </a>
                  </Grid>

                  <Grid item xs={12} className="pt-5">
                    <ListGroup>
                      {prices && prices.length == 0 && <div className="d-flex flex-center text-center">
                        Nenhum preço cadastrado
                      </div>}
                      {prices.map((price, index) => <>
                        <ListGroup.Item>
                          <Grid container>
                            <Grid item xs={9} fontSize="large">

                              <p style={{fontSize: '1em'}}>
                                <span className="text-dark">
                                  <span className="font-weight-bolder text-dark">Hórario:</span> {price.startAt} até {price.finishAt}
                                </span>
                              </p>

                              <p style={{fontSize: '1em'}}>
                                <span className="text-dark">
                                  <span className="font-weight-bolder text-dark">Dias:</span> {getWeekDays(price.selectedWeekdays)}
                                </span>
                              </p>

                              <p style={{fontSize: '1em'}}>
                                <span className="text-dark">
                                  <span className="font-weight-bolder text-dark">Preço:</span> R$ {price.price} / {price.time}min
                                </span>
                              </p>

                              {price.memberPrice && <p style={{fontSize: '1em'}}>
                                <span className="text-dark">
                                  <span className="font-weight-bolder text-dark">Preço para alunos:</span> R$ {price.memberPrice} / {price.time}min
                                </span>
                              </p>}
                            </Grid>

                            <Grid item xs={3} style={{textAlign: 'right'}}>
                              <Dropdown className="dropdown-inline" drop="down" alignRight>
                                <Dropdown.Toggle
                                    as={DropdownCustomToggler}
                                id="dropdown-toggle-top">
                                  <i className="ki ki-bold-more-hor" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                  <ul className="navi navi-hover py-5">
                                    {/*<li className="navi-item" onClick={() => console.log(price)}>
                                      <a className="navi-link">
                                        <span className="navi-icon"><i className="flaticon-edit-1"></i></span>
                                        <span className="navi-text">
                                          Editar
                                        </span>
                                      </a>
                                    </li>*/}
                                    <li className="navi-item" onClick={() => removePrice(index)}>
                                      <a className="navi-link">
                                        <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                        <span className="navi-text">
                                          Remover
                                        </span>
                                      </a>
                                    </li>
                                </ul>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Grid>

                          </Grid>
                        </ListGroup.Item>
                      </>)}
                    </ListGroup>
                  </Grid>

                  <Grid item xs={8} className="pt-5">
                    <span className="card-title font-weight-bolder text-dark">
                      Campos
                    </span>
                  </Grid>

                  <Grid item xs={4}>
                    {/*<a href="#" className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                      <ControlPoint fontSize="inherit" /> Cadastrar serviço
                    </a>*/}
                  </Grid>

                  <Grid item xs={12}>
                    <div className="checkbox-list">
                      {fields.entities.map(field => <>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            key={field.id}
                            name={field.id}
                            checked={fieldIsChecked(field.id)}
                            onChange={() => fieldCheck(field.id)}
                          />
                          <span></span>{field.attributes.name}
                        </label>
                      </>)}
                    </div>

                    {services.error && <RenderError error={services.error.field_id} />}
                  </Grid>
                </Grid>}
              </Grid>

              {(typeof services.error) == "string" && <Grid item xs={12}>
                <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
                  <div className="d-flex flex-column flex-grow-1 mr-2">
                    <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                      {services.error}
                    </span>
                  </div>
                </div>
              </Grid>}
            </Grid>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs style={{textAlign: 'right'}}>
              <Button type="submit" variant="outlined" color="primary" size="large" disabled={formik.isSubmitting}>
                Cadastrar {(formik.isSubmitting || services.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
              </Button>
            </Grid>
            <Grid item xs style={{textAlign: 'left'}}>
              <Button size="large" onClick={close}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>}

    <CreatePrice show={createEntityShow} type={formik.values.type} onHide={() => setCreateEntityShow(false)} onSubmit={(values) => createPrice(values)} />
    <CreateCreditRestriction show={createCreditRestrictionShow} onHide={() => setCreateCreditRestrictionShow(false)} onSubmit={(values) => addCreditRestrictions(values)} />
  </>);
};

export default CreateService;