import * as requestFromServer from "./serviceCrud";
import { serviceSlice, callTypes } from "./serviceSlice";

const { actions } = serviceSlice;

export const fetchServices = (branchId = '', modality = '', type = '') => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  let params = {};

  if (branchId) {
    params.branch_id = branchId;
  }

  if (modality) {
    params.modality = modality;
  }

  if (type) {
    params.type = type;
  }

  return new Promise((resolve, reject) => {
    requestFromServer
      .getServices(params)
      .then(response => {
        const entities = response.data;
        const totalCount = entities.length;

        dispatch(actions.servicesFetched({ totalCount, entities }));

        resolve(entities);
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.list }));

        dispatch(actions.servicesFetched({ totalCount: 0, entities: null }));

        reject(error);
      });
    });
};

export const fetchCreateService = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  if (params.type == 'recurrence') {
    params.recurrencePeriod = 'weekly';
  }

  return new Promise((resolve, reject) => {
    let data = {
      name: params.name,
      granularity: params.granularity,
      min_time: params.minTime,
      max_time: params.maxTime,
      modality: params.modality,
      type: params.type,
      available_app: params.availableApp == 'yes' ? true : false,
    };

    if (params.recurrencePeriod != '') {
      data.recurrence_period = params.recurrencePeriod;
    }

    if (params.recurrenceTimes != '') {
      data.recurrence_times = params.recurrenceTimes;
    }

    if (params.billingType != '') {
      data.billing_type = params.billingType;
    }

    if (params.fieldId) {
      data.field_id = params.fieldId;
    }

    if (params.package) {
      data.package = params.package;
    }

    if (params.creditRestriction) {
      data.credit_restriction = params.creditRestriction;
    }

    requestFromServer
      .createService(data).then(response => {
        dispatch(actions.serviceCreated());
        resolve(response.data);
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const fetchUpdateService = (serviceId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let data = {
    name: params.name,
    granularity: params.granularity,
    min_time: params.minTime,
    max_time: params.maxTime,
    type: params.type,
    modality: params.modality,
    recurrence_period: params.recurrencePeriod,
    recurrence_times: params.recurrenceTimes,
    billing_type: params.billingType,
    field_id: params.fieldId,
    available_app: params.availableApp == 'yes' ? true : false,
  }

  if (params.package) {
    data.package = params.package;
  }

  if (params.creditRestriction) {
    data.credit_restriction = params.creditRestriction;
  }

  return requestFromServer
    .updateService(serviceId, data)
    .then(response => {
      dispatch(actions.serviceUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchStatusUpdateService = (serviceId, active = true) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateService(serviceId, {
      status_id: active ? 'active' : 'blocked',
    })
    .then(response => {
      dispatch(actions.serviceUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
