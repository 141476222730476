import axios from "axios";

export const GET_COUPONS = `${process.env.REACT_APP_API_URL}/coupons`;
export const CREATE_COUPONS = `${process.env.REACT_APP_API_URL}/coupons`;
export const UPDATE_COUPONS = `${process.env.REACT_APP_API_URL}/coupons`;

export function getCoupons(params) {
  return axios.get(GET_COUPONS, { params: params });
}

export function getAvailableCoupons(clientId, params) {
  return axios.get(`${GET_COUPONS}/${clientId}`, { params: params });
}

export function getClientCoupons(clientId, branchId) {
  let params = {
    client_id: clientId,
    branch_id: branchId,
  }

  return axios.get(GET_COUPONS, { params: params });
}

export function getCouponById(couponId) {
  return axios.get(`${GET_COUPONS}/${couponId}`);
}

export function renewCoupon(couponId) {
  return axios.post(`${GET_COUPONS}/${couponId}/renew`);
}

export function createCoupon(params) {
  return axios.post(CREATE_COUPONS, params);
}

export function updateCoupon(couponId, params) {
  return axios.put(`${UPDATE_COUPONS}/${couponId}`, params);
}

export function deleteCoupon(couponId, params) {
  return axios.delete(`${UPDATE_COUPONS}/${couponId}`, {data: params});
}
