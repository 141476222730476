import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';

import ControlPoint from '@material-ui/icons/ControlPoint';
import DeleteForever from '@material-ui/icons/DeleteForever';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

// import { fetchPriceService } from "../../Price/_redux/priceActions";
import CreateClientSchedule from "./CreateClientSchedule";
import UpdateClientSchedule from "./UpdateClientSchedule";
import { fetchClientPhone } from "../../Client/_redux/clientActions";
import {
  fetchUpdateSchedule,
  fetchCancelSchedule,
  fetchCancelContract,
  fetchAddClientSchedule,
  fetchApproveSchedule,
  fetchAddParticipantSchedule,
  fetchRemoveParticipantSchedule,
} from "../_redux/scheduleActions";

import { createScheduleSchema } from "../_schemas/createScheduleSchema";

import Snackbar from "../../../components/Snackbar";
import { RenderError } from "../../../components/RenderError";
import CreateClient from "../../Client/components/CreateClient";
import InvoicePage from "../../Invoice/pages/InvoicePage";
import CancelContract from "../components/CancelContract";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditEvent = (props) => {
  const dispatch = useDispatch();
  const { fields, prices, clients, schedules } = useSelector((state) => ({
    fields: state.fields,
    prices: state.prices,
    clients: state.clients,
    schedules: state.schedules,
  }), shallowEqual);

  const classes = useStyles();

  const [typeSchedule, setTypeSchedule] = useState('client');

  const [field, setField] = useState(null);
  const [startDateAt, setStartDateAt] = useState();
  const [startAt, setStartAt] = useState();
  const [endDateAt, setEndDateAt] = useState();
  const [eventStartDateAt, setEventStartDateAt] = useState();
  const [createClientScheduleStartDateAt, setCreateClientScheduleStartDateAt] = useState();
  const [eventEndDateAt, setEventEndDateAt] = useState();
  const [endAt, setEndAt] = useState();
  const [service, setService] = useState(null);
  const [services, setServices] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedService, setSelectedService] = useState({});
  const [selectedClients, setSelectedClients] = useState([]);
  const [activeClients, setActiveClients] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [canceledClients, setCanceledClients] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [createEntityShow, setCreateEntityShow] = useState(false);
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [invoiceClient, setInvoiceClient] = useState('');
  const [type, setType] = useState('single');
  const [showCancel, setShowCancel] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [clientScheduleShow, setClientScheduleShow] = useState(false);
  const [participantScheduleShow, setParticipantScheduleShow] = useState(false);
  const [playerScheduleShow, setPlayerScheduleShow] = useState(false);
  const [cancelClientScheduleShow, setCancelClientScheduleShow] = useState(false);
  const [cancelClientSchedule, setCancelClientSchedule] = useState({});
  const [disableClient, setDisableClient] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const [weekdays, setWeekdays] = useState([]);

  // useEffect(() => {
  //   formik.setFieldValue('price', parseInt(prices.priceService).toLocaleString('pt-br', { minimumFractionDigits: 2 }), false);
  // }, [prices.priceService]);

  useEffect(() => {
    if (schedules.created == true || schedules.approved == true || schedules.updated == true) {
      close();
      setOpenSuccessMessage(true);
    }
  }, [schedules]);

  useEffect(() => {
    if (props.event.event && props.event.event.id) {
      setShowCancel(false);

      setSelectedStatus(props.event.event.relationships.status);

      if (props.event.start) {
        formik.setFieldValue('startDateAt', moment(props.event.start).format('YYYY-MM-DD'), false);
        formik.setFieldValue('startAt', moment(props.event.start).format('HH:mm'), false);
      } else {
        setStartDateAt(moment(props.event.event.attributes.start_at).format('DD/MM'));
        setEventStartDateAt(moment(props.event.event.attributes.start_at).format('DD/MM - HH:mm'));
        setCreateClientScheduleStartDateAt(moment(props.event.event.attributes.start_at).format('YYYY-MM-DD'));

        if (props.event.event.relationships.service.attributes.type == 'recurrence') {
          formik.setFieldValue('startDateAt', moment(props.event.event.attributes.recurrence_start_at).format('YYYY-MM-DD'), false);
        } else {
          formik.setFieldValue('startDateAt', moment(props.event.event.attributes.start_at).format('YYYY-MM-DD'), false);
        }
        formik.setFieldValue('startAt', moment(props.event.event.attributes.start_at).format('HH:mm'), false);
      }

      if (props.event.end) {
        formik.setFieldValue('endDateAt', moment(props.event.end).format('YYYY-MM-DD'), false);
        formik.setFieldValue('endAt', moment(props.event.end).format('HH:mm'), false);
      } else {
        setEventEndDateAt(moment(props.event.event.attributes.finish_at).format('DD/MM - HH:mm'))

        if (props.event.event.relationships.service.attributes.type == 'recurrence') {
          formik.setFieldValue('endDateAt', moment(props.event.event.attributes.recurrence_start_at).format('YYYY-MM-DD'), false);
        } else {
          formik.setFieldValue('endDateAt', moment(props.event.event.attributes.finish_at).format('YYYY-MM-DD'), false);
        }
        formik.setFieldValue('endAt', moment(props.event.event.attributes.finish_at).format('HH:mm'), false);
      }

      calculateRecurrenceFinishAt(props.event.event.attributes.recurrence_start_at, props.event.event.attributes.recurrence_finish_at);

      convertWeekday(props.event.event.attributes.recurrence_days_bit_mask);

      // TODO: implement status endpoint
      formik.setFieldValue('price', parseInt(props.event.event.attributes.price).toLocaleString('pt-br', { minimumFractionDigits: 2 }), false);

      formik.setFieldValue('scheduleId', props.event.event.id, false);

      setSelectedClients(props.event.event.relationships.clients.filter(client => client.attributes.isOnContract))
      setActiveClients(props.event.event.relationships.clients.filter(client => !client.relationships.client_schedule.attributes.canceled_at));
      setCanceledClients(props.event.event.relationships.clients.filter(client => client.relationships.client_schedule.attributes.canceled_at));

      setParticipants(props.event.event.relationships.participants);

      // formik.setFieldValue('clientId', props.event.event.relationships.clients[0].id, false);
      // formik.setFieldValue('mobile', props.event.event.relationships.clients[0].attributes.phone , false);
      // formik.setFieldValue('name', props.event.event.relationships.clients[0].attributes.name , false);
      // formik.setFieldValue('email', props.event.event.relationships.clients[0].attributes.email , false);

      let fieldSelected = props.event.resourceId ? props.event.resourceId : props.event.event.relationships.field.id;

      let fieldFilter = fields.entities.find(element => {
        return element.id === fieldSelected;
      });

      if (fieldFilter) {
        formik.setFieldValue('fieldId', fieldFilter.id, false);


        let serviceFilter = fieldFilter.relationships.services.find(element => {
          return element.id === props.event.event.relationships.service.id;
        });

        if (serviceFilter) {
          formik.setFieldValue('serviceId', serviceFilter.id, false);
          setSelectedService(serviceFilter);

          setType(serviceFilter.attributes.type);
        } else {
          setService(fieldFilter.relationships.services[0].id);
          formik.setFieldValue('serviceId', fieldFilter.relationships.services[0].id, false);

          setType(fieldFilter.relationships.services[0].attributes.type);
        }

        let statusFilter = status.find(element => {
          return element.value === props.event.event.relationships.status.attributes.name;
        });

        if (statusFilter) {
          formik.setFieldValue('statusId', statusFilter.value, false);
        }
      }
    }
  }, [props.event]);

  const status = [
    { label: 'Pendente', value: 'pending' },
    { label: 'Pago', value: 'paid' },
    { label: 'Cancelado', value: 'canceled' }
  ];

  const close = () => {
    props.onHide();

    setService(null);
    setField(null);
    setShowClient(false);
    setAdvanced(false);

    formik.setFieldValue('clientId', '', false);
    formik.setFieldValue('mobile', '', false);
    formik.setFieldValue('name', '', false);
    formik.setFieldValue('email', '', false);
  }

  const beforeMaskedValueChange = (newState: InputState) => {
    let { value } = newState;

    const newValue = value.replace(/\D/g, "");
    if (newValue.length === 11) {
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    return {
      ...newState,
      value
    };
  };

  const selectedWeekDayColor = (weekday) => {
    return weekdays.includes(weekday) ? "primary" : "default";
  }

  const selectWeekDay = (weekday) => {
    if(!weekdays.includes(weekday)) {
      if (selectedService.attributes && weekdays.length < selectedService.attributes.recurrence_times) {
        setWeekdays([...weekdays, weekday]);
      }

      if (selectedService.attributes && selectedService.attributes.recurrence_times == 1) {
        setWeekdays([weekday]);
      }
    } else{
        var array = [...weekdays];
        var index = array.indexOf(weekday);

        if (index !== -1) {
          array.splice(index, 1);

          setWeekdays(array);
        }
    }
  }

  const cancelSchedule = (type) => {
    dispatch(fetchCancelSchedule(props.event.event.id, {
      date: moment(props.event.event.attributes.start_at).format('YYYY-MM-DD'),
      type: type,
    }));
  }

  const approveSchedule = () => {
    dispatch(fetchApproveSchedule(props.event.event.id));
  }

  const dispatchCancelClientSchedule = (type, clientScheduleId) => {
    dispatch(fetchCancelContract(clientScheduleId, {
      date: moment(props.event.event.attributes.start_at).format('YYYY-MM-DD'),
      type: type,
    }));
  }

  const dispatchRemoveParticipantSchedule = (participantScheduleId) => {
    dispatch(fetchRemoveParticipantSchedule(participantScheduleId));
  }

  const toggleShowCancel = () => {
    setShowCancel(!showCancel);
  }

  const typeClass = (item) => {
    return type == item ? "contained" : "outlined";
  };

  const renderError = (error) => {
    return (error ? (
      <Grid item xs={12}>
        <div className="fv-plugins-message-container">
          {(typeof error) == "string" &&
            <div className="fv-help-block">{error}</div>
          }

          {Array.isArray(error) && error.map((item) =>
            <div className="fv-help-block">{item}</div>
          )}
        </div>
      </Grid>
    ) : null);
  }

  const formik = useFormik({
    initialValues: {
      scheduleId: '',
      clientId: '',
      recurrenceFinishAt: 'infinite',
      startDateAt: '',
      startAt: '',
      endDateAt: '',
      endAt: '',
      fieldId: '',
      price: '',
      serviceId: '',
      statusId: '',
    },
    validationSchema: createScheduleSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        // if (selectedService && selectedService.attributes.type == 'recurrence') {
        //   values.recurrence_start_at = values.startDateAt;

        //   values.recurrence_finish_at = 'infinite';

        //   if (values.recurrenceFinishAt != 'infinite') {
        //     values.recurrence_finish_at = moment(values.startDateAt).add(values.recurrenceFinishAt, 'months').format('YYYY-MM-DD')
        //   }

        //   values.recurrence_days = weekdays.reduce((x, y) => x + y, 0)
        // }

        // dispatch(fetchUpdateSchedule(values.scheduleId, values));

        // setSubmitting(false);
      }, 500);
    },
  });

  /*
  useEffect(() => {
    if(prices.actionsLoading == false) {
      if (formik.values.fieldId && formik.values.serviceId) {

        let serviceFiltered = services.filter((service) => service.id === formik.values.serviceId)[0];
        if (serviceFiltered) {
          setSelectedService(serviceFiltered);
        }

        const priceTimeout = setTimeout(() => {
          dispatch(fetchPriceService(formik.values.serviceId, `${formik.values.startDateAt} ${formik.values.startAt}`, `${formik.values.endDateAt} ${formik.values.endAt}`))
        }, 1000);

        return () => {
          clearTimeout(priceTimeout);
        };
      }
    }
  }, [
    formik.values.serviceId,
    // formik.values.fieldId,
    formik.values.startDateAt,
    formik.values.startAt,
    formik.values.endDateAt,
    formik.values.endAt
  ]);
  */

  const closeCreateClient = () => {
    setCreateEntityShow(false);
  }

  const closeCancelClientSchedule = () => {
    setCancelClientScheduleShow(false);
  }

  const openCancelClientSchedule = (client) => {
    setCancelClientSchedule(client)
    setCancelClientScheduleShow(true);
  }

  const openInvoice = (client) => {
    setInvoiceShow(true);

    setInvoiceClient('');

    if (type == 'recurrence') {
      setInvoiceClient(client);
    }
  }

  const closeInvoice = () => {
    setInvoiceShow(false);
  }

  const closeClientSchedule = () => {
    setClientScheduleShow(false);

  }

  const openClientSchedule = () => {
    setClientScheduleShow(true);
  }

  const convertWeekday = (days) => {
    let values = [
      1,
      2,
      4,
      8,
      16,
      32,
      64,
    ];

    let data = [];

    days.map(day => {
      data.push(values[day]);
    })

    return setWeekdays(data);
  }

  useEffect(() => {
    formik.setFieldValue('endDateAt', formik.values.startDateAt, false);
  }, [formik.values.startDateAt]);

  const calculateRecurrenceFinishAt = (start, end) => {
    let value = 'infinite';

    if (end) {
      let recurrenceStartAt = moment(start);
      let recurrenceFinishAt = moment(end);

      var diffDuration = recurrenceFinishAt.diff(recurrenceStartAt, 'months', true);

      value = diffDuration;
    }

    formik.setFieldValue('recurrenceFinishAt', value, false);
  }

  useEffect(() => {
    if (formik.values.fieldId) {
      let fieldFilter = fields.entities.find(element => {
        return element.id === formik.values.fieldId;
      });

      setField(fieldFilter);

      if (fieldFilter) {
        let serviceFiltered = fieldFilter.relationships.services.filter(service => {
          if (type == 'single') {
            return service.attributes.type == 'single'
          }

          if (type == 'recurrence') {
            return service.attributes.type == 'recurrence'
          }

          if (type == 'unavailable') {
            return service.attributes.type == 'unavailable'
          }
        });

        setServices(serviceFiltered);

        let serviceFilter = serviceFiltered.find(element => {
          return element.id === props.event.event.relationships.service.id;
        });

        if (serviceFilter) {
          formik.setFieldValue('serviceId', serviceFilter.id, false);

          return;
        }

        if (serviceFiltered[0]) {
          formik.setFieldValue('serviceId', serviceFiltered[0].id, false);
        } else {
          formik.setFieldValue('serviceId', null, false);
        }
      }
    }
  }, [formik.values.fieldId]);

  // useEffect(() => {
  //   if (formik.values.statusId) {
  //     setSelectedStatus(formik.values.statusId);
  //   }
  // }, [formik.values.statusId]);

  const DropdownCustomToggler = React.forwardRef((props, ref) => {
    return (
      <a
        ref={ref}
        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        onClick={e => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        {props.children}
      </a>
    );
  });

  const selectClient = (client) => {
    dispatch(fetchAddClientSchedule(props.event.event.id, {
      client_id: client.id,
      start_at: client.attributes.start_at,
      billing_day: client.attributes.billing_day,
    }));
  }

  const selectParticipant = (client) => {
    dispatch(fetchAddParticipantSchedule(props.event.event.id, {
      client_id: client.id,
    }));
  }

  return (<>
  <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    keyboard={true}
    show={props.show && !createEntityShow && !invoiceShow && !cancelClientScheduleShow}
  >
    <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit}>
      <Modal.Body>
        {type == 'recurrence' && advanced == false && <Container maxWidth="sm">
          <Grid container className={classes.label}>
            <Grid item xs>
              <span className="font-weight-bolder text-dark">
                Agendamento {type == 'single' || type == 'unavailable'  ? 'avulso' : 'mensal'}<Icon className={classes.icon}>info_outlined</Icon>
              </span>
            </Grid>
          </Grid>

          <div className="separator separator-dashed my-5"></div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <span className="text-dark">
                <span className="font-weight-bolder text-dark">Inicio</span>: {eventStartDateAt}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="text-dark">
                <span className="font-weight-bolder text-dark">Termino</span>: {eventEndDateAt}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="text-dark">
                <span className="font-weight-bolder text-dark">Local</span>: {field && field.attributes && field.attributes.name}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className="text-dark">
                <span className="font-weight-bolder text-dark">Serviço</span>: {selectedService && selectedService.attributes && selectedService.attributes.name}
              </span>
            </Grid>
          </Grid>

          <div className="separator separator-dashed my-10"></div>

          <Grid container className={classes.label}>
            <Grid item xs>
              <span className="font-weight-bolder text-dark">
                Controle de presença
                <Tooltip title={<h5 className="font-weight-lighter text-white">Controle de presenta por evento</h5>}>
                  <Icon className={classes.icon}>info_outlined</Icon>
                </Tooltip>
              </span>
            </Grid>

            <Grid item xs>
              <div className="text-right">
                <Dropdown className="dropdown-inline" drop="down" alignRight>
                  <Dropdown.Toggle
                    id="dropdown-toggle-top2"
                    variant="transparent"
                    className="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle">
                    Alterar mensal
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <ul className="navi navi-hover">
                      <li className="navi-header pb-1">
                          <span className="text-primary text-uppercase font-weight-bold font-size-sm">Opções:</span>
                      </li>
                      <li className="navi-item" onClick={() => setAdvanced(!advanced)}>
                          <a className="navi-link">
                              <span className="navi-icon"><i className="flaticon-edit-1"></i></span>
                              <span className="navi-text">Alterar mensal</span>
                          </a>
                      </li>
                      <li className="navi-item">
                          <a className="navi-link">
                              <span className="navi-icon"><i className="flaticon2-calendar-8"></i></span>
                              <span className="navi-text">Ver todos eventos</span>
                          </a>
                      </li>
                      <li className="navi-item" onClick={() => cancelSchedule('equal')}>
                          <a className="navi-link">
                              <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                              <span className="navi-text">Cancelar evento {startDateAt}</span>
                          </a>
                      </li>
                  </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ListGroup>
              {selectedClients.map(client => <>
                <ListGroup.Item>
                  <Grid container>
                    <Grid item xs={9} fontSize="large">
                      <Chip
                        size="small"
                        label={client.attributes && client.attributes.isOnSchedule && client.attributes.isOnSchedule == true ? 'Confirmado' : 'Ausente' }
                        variant="outlined"
                        style={{
                          marginRight: 5,
                          color: `${client.attributes && client.attributes.isOnSchedule && client.attributes.isOnSchedule == true ? '#17c191' : '#ffcc00' }`,
                          border: `1px solid ${client.attributes && client.attributes.isOnSchedule && client.attributes.isOnSchedule == true ? '#17c191' : '#ffcc00' }`
                        }}
                      />
                        {/*<span className="label label-lg label-light-success label-inline">
                          Confirmado
                        </span>*/}
                      {client.attributes.name}
                    </Grid>
                    <Grid item xs={3} style={{textAlign: 'right'}}>
                      {/*{selectedService.attributes && selectedService.attributes.type == 'recurrence' &&
                        <Settings fontSize="medium" className={classes.icon} onClick={() => console.log(client)} />
                      }*/}

                      <Dropdown className="dropdown-inline" drop="down" alignRight>
                        <Dropdown.Toggle
                            as={DropdownCustomToggler}
                        id="dropdown-toggle-top">
                          <i className="ki ki-bold-more-hor" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <ul className="navi navi-hover py-1">
                            {client.attributes && client.attributes.isOnSchedule && client.attributes.isOnSchedule == true && <li className="navi-item" onClick={() => selectedClients.length > 1 && dispatchCancelClientSchedule('equal', client.relationships.client_schedule.id)}>
                                <a className="navi-link">
                                    <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                    <span className="navi-text">Marcar ausente {selectedClients.length <= 1 && <span>
                                      <p className="navi-item">
                                        <span className="navi-link-badge">
                                          <span className="label label-light-default label-inline font-weight-bold">
                                            Indisponível
                                          </span>
                                        </span>
                                      </p>
                                    </span>}</span>
                                </a>
                            </li>}
                            {client.attributes && !client.attributes.isOnSchedule && <li className="navi-item"  onClick={() => dispatchCancelClientSchedule('reactive', client.relationships.client_schedule.id)}>
                                <a className="navi-link">
                                    <span className="navi-icon"><i className="flaticon-like"></i></span>
                                    <span className="navi-text">Marcar confirmado</span>
                                </a>
                              </li>}
                          </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Grid>
                    </Grid>
                  </ListGroup.Item>
                </>)}

                {selectedClients.length == 0 && <ListGroup.Item>
                  <Grid item xs={12} fontSize="large">
                    Nenhum participante encontrado
                  </Grid>
                </ListGroup.Item>}
              </ListGroup>

              {schedules.error && <RenderError error={schedules.error.clients} />}
          </Grid>
        </Container>}

        {((type == 'recurrence' && advanced == true) || type == 'single' || type == 'unavailable') && <Container maxWidth="sm">
          <Grid container className={classes.label}>
            <Grid item xs>
              <span className="font-weight-bolder text-dark">
                Tipo agendamento <Icon className={classes.icon}>info_outlined</Icon>
              </span>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                label="Campo"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    }
                  }
                }}
                name="fieldId"
                fullWidth
                className={classes.textField}
                {...formik.getFieldProps("fieldId")}
                disabled
              >
                {fields.entities.map((item) => <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>)}
              </TextField>
              {formik.touched.fieldId && formik.errors.fieldId ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.fieldId}</div>
                </div>
              ) : null}
            </Grid>
          </Grid>

          <Grid container className={classes.label} spacing={2}>
            <Grid item xs>
              <Button fullWidth variant={typeClass('single')} color="primary" size="large" disabled={type != 'single' ? 'disabled' : ''}>
                Avulso
              </Button>
            </Grid>
            <Grid item xs>
              <Button fullWidth variant={typeClass('recurrence')} color="primary" size="large" disabled={type != 'recurrence' ? 'disabled' : ''}>
                Mensalista
              </Button>
            </Grid>
            <Grid item xs>
              <Button fullWidth variant={typeClass('unavailable')} color="primary" size="large" disabled={type != 'unavailable' ? 'disabled' : ''}>
                Indisponível
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} style={{ display: ((services.length > 1) ? 'block' : 'none')}}>
              <TextField
                select
                label="Serviço"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    }
                  }
                }}
                name="serviceId"
                fullWidth
                className={classes.textField}
                {...formik.getFieldProps("serviceId")}
                disabled
              >
                {services.map((item) => <MenuItem key={item.id} value={item.id}>{item.attributes.name}</MenuItem>)}
              </TextField>
            </Grid>
          </Grid>

          <Grid container className={classes.label}>
            <Grid item xs>
              <span className="font-weight-bolder text-dark">
                Agendamento <Icon className={classes.icon}>info_outlined</Icon>
              </span>
            </Grid>
          </Grid>

          <Grid container className={classes.label} spacing={2}>
            <TextField
              type="hidden"
              disabled={true}
              name="scheduleId"
              {...formik.getFieldProps("scheduleId")}
            />
            <Grid item xs={6}>
              <TextField
                name="startDateAt"
                label={type == 'single' || type == 'unavailable' ? 'Data Inicio' : 'Contrato Inicio'}
                type="date"
                fullWidth
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("startDateAt")}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={startAt}
                name="startAt"
                label="Horario Inicio"
                type="time"
                fullWidth
                step={15}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: "900", // 15 min
                }}
                {...formik.getFieldProps("startAt")}
                disabled
              />
            </Grid>

            {/*{prices.error && renderError(prices.error.start_at)}*/}

            {(type == 'single' || type == 'unavailable') && <Grid item xs={6}>
              <TextField
                value={endDateAt}
                name="endDateAt"
                label="Data fim"
                type="date"
                fullWidth
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                {...formik.getFieldProps("endDateAt")}
                disabled
              />
            </Grid>}

            {(type == 'recurrence') && <Grid item xs={6}>
              <TextField
                select
                label="Prazo contrato"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    }
                  }
                }}
                name="recurrenceFinishAt"
                fullWidth
                className={classes.textField}
                {...formik.getFieldProps("recurrenceFinishAt")}
                disabled
              >
                <MenuItem key="infinite" value="infinite">Indeterminado</MenuItem>
                <MenuItem key="3" value="3">3 meses</MenuItem>
                <MenuItem key="6" value="6">6 meses</MenuItem>
                <MenuItem key="12" value="12">1 ano</MenuItem>
              </TextField>

              {schedules.error && renderError(schedules.error.recurrence_start_at)}
            </Grid>}

            <Grid item xs={6}>
              <TextField
                value={endAt}
                name="endAt"
                label="Horario Fim"
                type="time"
                fullWidth
                step={15}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: "900", // 15 min
                }}
                {...formik.getFieldProps("endAt")}
                disabled
              />
            </Grid>

            {/*{prices.error && renderError(prices.error.end_at)}*/}

            {/*<Grid item xs={6}>
              <TextField
                name="price"
                label="Preço"
                fullWidth
                className={classes.textField}
                variant="outlined"
                readOnly={true}
                disabled={true}
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  endAdornment: (
                    <InputAdornment position="end">
                      {prices.actionsLoading && <CircularProgress size={30} />}
                    </InputAdornment>
                  ),
                }}
                {...formik.getFieldProps("price")}
              />
            </Grid>*/}

            {/*{(type == 'single') && <Grid item xs={6}>
              <TextField
                select
                label="Status do pagamento"
                variant="outlined"
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    }
                  }
                }}
                name="fieldId"
                fullWidth
                className={classes.textField}
                {...formik.getFieldProps("statusId")}
              >
                {status.map((item) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
              </TextField>

              {schedules.error && renderError(schedules.error.status_id)}
            </Grid>}*/}
          </Grid>

          {(type == 'recurrence' && selectedService && selectedService.attributes) && <Grid container className={classes.label}>
            <Grid item xs={12}>
              <span className="font-weight-bolder text-dark">
                Dias da semana
              </span>
            </Grid>

            <Grid container className={classes.label} spacing={1}>
              <Grid item xs><Chip color={selectedWeekDayColor(2)} variant="outlined" label="Seg" /></Grid>
              <Grid item xs><Chip color={selectedWeekDayColor(4)} variant="outlined" label="Ter" /></Grid>
              <Grid item xs><Chip color={selectedWeekDayColor(8)} variant="outlined" label="Qua" /></Grid>
              <Grid item xs><Chip color={selectedWeekDayColor(16)} variant="outlined" label="Qui" /></Grid>
              <Grid item xs><Chip color={selectedWeekDayColor(32)} variant="outlined" label="Sex" /></Grid>
              <Grid item xs><Chip color={selectedWeekDayColor(64)} variant="outlined" label="Sab" /></Grid>
              <Grid item xs><Chip color={selectedWeekDayColor(1)} variant="outlined" label="Dom" /></Grid>
            </Grid>
          </Grid>}

          <Grid container className={classes.label}>
            <Grid item xs={8}>
              <span className="font-weight-bolder text-dark">
                Contratos ativos
              </span>
            </Grid>

            <Grid item xs={4}>
              <a className="font-weight-bold" onClick={() => setCreateEntityShow(true)}>
                <ControlPoint fontSize="inherit" /> Cadastrar cliente
              </a>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ListGroup>
              {activeClients.map(client => <>
                <ListGroup.Item>
                  <Grid container>
                    <Grid item xs={9} fontSize="large">
                      <Chip
                        size="small"
                        color={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'default' : 'primary' }
                        label={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'Cliente' : 'Professor' }
                        variant="outlined"
                        style={{
                          marginRight: 5,
                        }}
                      />
                      {client.attributes.name}
                    </Grid>
                    <Grid item xs={3} style={{textAlign: 'right'}}>
                      {/*{selectedService.attributes && selectedService.attributes.type == 'recurrence' &&
                        <Settings fontSize="medium" className={classes.icon} onClick={() => console.log(client)} />
                      }*/}

                      <Dropdown className="dropdown-inline" drop="down" alignRight>
                        <Dropdown.Toggle
                            as={DropdownCustomToggler}
                        id="dropdown-toggle-top">
                          <i className="ki ki-bold-more-hor" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <ul className="navi navi-hover py-5">
                            <li className="navi-item" onClick={() => openInvoice(client.id)}>
                              <a className="navi-link">
                                  <span className="navi-icon"><i className="flaticon2-rocket-1"></i></span>
                                  <span className="navi-text">Ver contrato</span>
                                  {/*<span className="navi-link-badge">
                                    <span className="label label-light-primary label-inline font-weight-bold">novo</span>
                                  </span>*/}
                              </a>
                            </li>

                            {/*<li className="navi-item">
                              <a className="navi-link">
                                  <span className="navi-icon"><i className="flaticon2-file-1"></i></span>
                                  <span className="navi-text">Ver contrato</span>
                              </a>
                            </li>*/}

                            <li className="navi-separator my-3"></li>

                            <li className="navi-item" onClick={() => activeClients.length > 1 && openCancelClientSchedule(client)}>
                              <a className="navi-link">
                                <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                <span className="navi-text">
                                  Cancelamento
                                  {activeClients.length <= 1 && <span>
                                    <p className="navi-item">
                                      <span className="navi-link-badge">
                                        <span className="label label-light-default label-inline font-weight-bold">Indisponível</span>
                                      </span>
                                    </p>
                                  </span>}
                                </span>
                              </a>
                            </li>
                        </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Grid>
                  </Grid>
                </ListGroup.Item>
              </>)}
              <ListGroup.Item as="a" action style={{textAlign: 'center'}} onClick={() => openClientSchedule()}>
                <ControlPoint fontSize="inherit" /> Associar cliente
              </ListGroup.Item>
            </ListGroup>

            {schedules.error && <RenderError error={schedules.error.clients} />}
          </Grid>

          <Grid container className={classes.label}>
            <Grid item xs={8}>
              <span className="font-weight-bolder text-dark">
                Participantes
              </span>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ListGroup>
              {participants.map(client => <>
                <ListGroup.Item>
                  <Grid container>
                    <Grid item xs={9} fontSize="large">
                      <Chip
                        size="small"
                        color={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'default' : 'primary' }
                        label={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'Cliente' : 'Professor' }
                        variant="outlined"
                        style={{
                          marginRight: 5,
                        }}
                      />
                      {client.attributes.name}
                    </Grid>
                    <Grid item xs={3} style={{textAlign: 'right'}}>
                      {/*{selectedService.attributes && selectedService.attributes.type == 'recurrence' &&
                        <Settings fontSize="medium" className={classes.icon} onClick={() => console.log(client)} />
                      }*/}

                      <Dropdown className="dropdown-inline" drop="down" alignRight>
                        <Dropdown.Toggle
                            as={DropdownCustomToggler}
                        id="dropdown-toggle-top">
                          <i className="ki ki-bold-more-hor" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <ul className="navi navi-hover py-5">
                            <li className="navi-item" onClick={() => dispatchRemoveParticipantSchedule(client.relationships.participant_schedule.id)}>
                              <a className="navi-link">
                                <span className="navi-icon"><i className="flaticon-cancel"></i></span>
                                <span className="navi-text">
                                  Remover
                                </span>
                              </a>
                            </li>
                        </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Grid>
                  </Grid>
                </ListGroup.Item>
              </>)}
              <ListGroup.Item as="a" action style={{textAlign: 'center'}} onClick={() => setParticipantScheduleShow(true)}>
                <ControlPoint fontSize="inherit" />Convidar
              </ListGroup.Item>
            </ListGroup>

            {schedules.error && <RenderError error={schedules.error.participants} />}
          </Grid>

          {canceledClients.length > 0 && <>
            <Grid container className={classes.label}>
              <Grid item xs={8}>
                <span className="font-weight-bolder text-dark">
                  Contratos cancelados
                </span>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ListGroup>
                {canceledClients.map(client => <>
                  <ListGroup.Item>
                    <Grid container>
                      <Grid item xs={9} fontSize="large">
                        <Chip
                          size="small"
                          color={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'default' : 'primary' }
                          label={client.attributes && client.attributes.type && client.attributes.type == 'client' ? 'Cliente' : 'Professor' }
                          variant="outlined"
                          style={{
                            marginRight: 5,
                          }}
                        />
                        {client.attributes.name}
                      </Grid>
                      <Grid item xs={3} style={{textAlign: 'right'}}>
                        {/*{selectedService.attributes && selectedService.attributes.type == 'recurrence' &&
                          <Settings fontSize="medium" className={classes.icon} onClick={() => console.log(client)} />
                        }*/}

                        <Dropdown className="dropdown-inline" drop="down" alignRight>
                          <Dropdown.Toggle
                              as={DropdownCustomToggler}
                          id="dropdown-toggle-top">
                            <i className="ki ki-bold-more-hor" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <ul className="navi navi-hover py-5">
                              <li className="navi-item" onClick={() => openInvoice(client.id)}>
                                <a className="navi-link">
                                    <span className="navi-icon"><i className="flaticon2-rocket-1"></i></span>
                                    <span className="navi-text">Ver contrato</span>
                                </a>
                              </li>
                          </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Grid>
                    </Grid>
                  </ListGroup.Item>
                </>)}
              </ListGroup>
            </Grid>
          </>}

          {(typeof schedules.error && !clientScheduleShow) == "string" && <Grid item xs={12}>
            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-5">
              <div className="d-flex flex-column flex-grow-1 mr-2">
                <span className="font-weight-normel text-dark-75 font-size-lg mb-1">
                  {schedules.error}
                </span>
              </div>
            </div>
          </Grid>}
        </Container>}
      </Modal.Body>

      {showCancel && <Modal.Body style={{backgroundColor: '#ffe2e2'}}>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{textAlign: 'left'}}>
            <span className="font-weight-bolder text-dark">
              Deseja cancelar o evento?
            </span>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right'}}>
            <CloseIcon fontSize="small" onClick={toggleShowCancel} />
          </Grid>
          {(type == 'recurrence') && <>
            {/*<Grid item xs={12} style={{textAlign: 'center'}}>
              <Button fullWidth variant="outlined" color="default" size="large" onClick={() => cancelSchedule('equal')}>
                Apenas {startDateAt}
              </Button>
            </Grid>*/}
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <Button fullWidth variant="outlined" color="default" size="large" onClick={() => cancelSchedule('gt')}>
                Todos a partir de {startDateAt}
              </Button>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <Button fullWidth variant="outlined" color="default" size="large" onClick={() => cancelSchedule('event')}>
                Todos eventos
              </Button>
            </Grid>
          </>}

          {(type == 'single' || type == 'unavailable') && <>
            <Grid item xs={6} style={{textAlign: 'right'}}>
              <Button style={{backgroundColor: '#ff6565', color: 'white'}} variant="contained" size="large" onClick={() => cancelSchedule('event')}>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'left'}}>
              <Button size="large" onClick={() => toggleShowCancel()}>
                Não
              </Button>
            </Grid>
          </>}
        </Grid>
      </Modal.Body>}

      {selectedStatus.attributes && selectedStatus.attributes.name == 'pending' && <Modal.Body>
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert key="alert-accept-schedule" variant="primary">
                Esse agendamento foi feito pelo aplicativo.<br />
                Deseja aprovar esse agendamento?
              </Alert>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right'}}>
              <Button style={{backgroundColor: '#dafb6d', color: '#1F2933'}} variant="contained" size="large" onClick={() => approveSchedule()}>
                Aprovar
              </Button>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'left'}}>
              <Button style={{backgroundColor: '#ff6565', color: 'white'}} variant="contained" size="large" onClick={() => cancelSchedule('event')}>
                Recusar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Modal.Body>}

      <Modal.Footer>
        <Grid container spacing={3}>
          {/*<Grid item xs={6} style={{textAlign: 'right'}}>
            <Button type="submit" variant="outlined" color="primary" size="large" disabled={formik.isSubmitting}>
              ALTERAR {(formik.isSubmitting || schedules.actionsLoading) && <span className="ml-2 spinner spinner-success"></span>}
            </Button>
          </Grid>*/}
          <Grid item xs={7} style={{textAlign: 'right'}}>
            <Button size="large" onClick={close}>
              Fechar
            </Button>
          </Grid>
          <Grid item xs={5} style={{textAlign: 'right'}}>
            {/*<Button size="large" color="inherit" onClick={close}>
              Cancelar
            </Button>*/}
            {(advanced || type == 'single' || type == 'unavailable') && (selectedStatus.attributes && selectedStatus.attributes.name != 'pending') && <Tooltip title="Ver opções de cancelamento" placement="top">
              <IconButton variant="outlined" aria-label="delete" onClick={toggleShowCancel} disableFocusRipple={true}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>}
          </Grid>
        </Grid>
      </Modal.Footer>
    </form>
  </Modal>

  <CreateClient show={createEntityShow} onHide={() => closeCreateClient()} />

  <CreateClientSchedule
    service={selectedService}
    startDateAt={createClientScheduleStartDateAt}
    weekdays={weekdays}
    show={clientScheduleShow}
    selectClient={selectClient}
    onHide={() => closeClientSchedule()}
    style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
  />

  <CreateClientSchedule
    show={participantScheduleShow}
    selectClient={selectParticipant}
    onHide={() => setParticipantScheduleShow(false)}
    style={{backgroundColor: 'rgba(0,0,0, 0.5)'}}
  />

  <CancelContract
    type={type}
    client={cancelClientSchedule}
    show={cancelClientScheduleShow}
    onHide={() => closeCancelClientSchedule()}
  />

  <InvoicePage show={invoiceShow} onHide={() => closeInvoice()} client={invoiceClient} schedule={formik.values.scheduleId} displayName={type == 'single' || type == 'unavailable'} />

  <Snackbar
    duration={3000}
    message="Agendamento atualizado com sucesso"
    type="success"
    open={openSuccessMessage}
    close={() => setOpenSuccessMessage(false)}
  />
  </>);
};

export default EditEvent;
