import React, { useEffect, useState } from "react";

import SVG from "react-inlinesvg";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { toAbsoluteUrl, checkIsActive } from "../../../../_metronic/_helpers";

import {
    fetchGetClientSignatures
} from "../../Signature/_redux/signatureActions";

import EditSignature from "../../Signature/components/EditSignature";

const SignaturesList = (props) => {
  const { branchSelected } = useSelector((state) => ({
    branchSelected: state.auth.branchSelected,
  }), shallowEqual);

  const dispatch = useDispatch();

  const [signatures, setSignatures] = useState([]);
  const [editEntityShow, setEditEntityShow] = useState(false);
  const [editEntity, setEditEntity] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatchFetchClientSignatures = () => {
    setIsLoading(true);
    dispatch(fetchGetClientSignatures(props.client_id, branchSelected.id)).then(response => {
      setSignatures(response.data);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    dispatchFetchClientSignatures();
  }, []);

  const openEditEntity = (signature) => {
    setEditEntity(signature);
    setEditEntityShow(true);
  }

  const closeEditEntity = () => {
    setEditEntityShow(false);
    dispatchFetchClientSignatures();
  }

  return (
    <>
      <div>
        <div className="table-responsive">
          <h4 class="card-title">
            Clubinho
          </h4>
          <Table
              className="table table-head-custom table-head-bg table-borderless table-vertical-center"
              aria-labelledby="tableTitle"
              size="large"
          >
              <TableHead>
                  <TableRow>
                      <TableCell>Plano</TableCell>
                      <TableCell>Início da assinatura</TableCell>
                      <TableCell>Válido até</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell></TableCell>
                  </TableRow>
              </TableHead>

              <TableBody>
                {signatures && signatures.map((signature, index) => {
                  return <TableRow
                    tabIndex={-1}
                    key={'clubinho-client-' + signature.id}
                  >
                    <TableCell component="th" scope="row" padding="none">
                        {signature.relationships.plan.attributes.name}
                    </TableCell>
                    <TableCell align="left">{moment(signature.attributes.start_at).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align="left">{(moment(signature.attributes.finish_at).isValid()) ? moment(signature.attributes.finish_at).format('DD/MM/YYYY') : '--'}</TableCell>
                    <TableCell align="left">{signature.relationships.status.attributes.display_name}</TableCell>
                    <TableCell align="right">
                    <a
                      title="Editar assinatura"
                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                      onClick={() => openEditEntity(signature)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          title="Editar assinatura"
                          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                        />
                      </span>
                    </a>
                  </TableCell>
                </TableRow>
              })}

              {!isLoading && signatures && signatures.length == 0 &&
                <TableRow><TableCell colSpan={12} className="text-center pt-5">Nenhum registro encontrado</TableCell></TableRow>
              }
            </TableBody>
          </Table>

          <div className="d-flex align-items-center py-3">
            {isLoading && <div className="d-flex align-items-center">
              <div className="mr-2 text-muted">Carregando...</div>
              <div className="spinner spinner-primary mr-10"></div>
            </div>}
          </div>
        </div>
      </div>

      <EditSignature show={editEntityShow} onHide={closeEditEntity} event={editEntity} hideClient={true} />
    </>
  );
}

export default SignaturesList;