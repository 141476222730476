import axios from "axios";

export const GET_CONTRACTS = `${process.env.REACT_APP_API_URL}/contracts`;
export const GET_AVAILABLE_CONTRACTS = `${process.env.REACT_APP_API_URL}/contracts/available`;
export const CREATE_CONTRACT = `${process.env.REACT_APP_API_URL}/contracts`;
export const CANCEL_SCHEDULE_SPOT = `${process.env.REACT_APP_API_URL}/schedule_spots`;

export function getContracts(params) {
  return axios.get(GET_CONTRACTS, { params: params });
}

export function getAvailableContracts(params) {
  return axios.get(GET_AVAILABLE_CONTRACTS, { params: params });
}

export function createContract(params) {
  return axios.post(CREATE_CONTRACT, params);
}

export function updateContractPrice(contractId, params) {
  return axios.patch(`${CREATE_CONTRACT}/${contractId}/price`, params);
}

export function renewContractInvoice(contractId) {
  return axios.post(`${CREATE_CONTRACT}/${contractId}/renew_invoice`);
}

export function cancelContract(contractId, params) {
  return axios.delete(`${CREATE_CONTRACT}/${contractId}`, {data: params});
}

export function cancelScheduleSpot(scheduleSpotId, params) {
  return axios.delete(`${CANCEL_SCHEDULE_SPOT}/${scheduleSpotId}`, {data: params});
}