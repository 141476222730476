import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  selected: [],
  error: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const permissionSlice = createSlice({
  name: "permissions",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;

      if (
        action.payload.error.response &&
        action.payload.error.response.data &&
        action.payload.error.response.data.message
      ) {
        state.error = action.payload.error.response.data.message;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    permissionsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      return {
        ...state,
        entities: entities,
        totalCount: totalCount,
        listLoading: false,
      };
    },

    permissionsSelected: (state, action) => {
      const { selected } = action.payload;
      state.selected = selected;
    },
  },
});
