import store from "../../../../redux/store";

import * as requestFromServer from "./branchCrud";
import { actions as authPersistor } from "../../Auth/_redux/authRedux";
import { branchSlice, callTypes } from "./branchSlice";

const { actions } = branchSlice;

export const fetchBranches = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getBranches()
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.branchesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.branchesFetched({ totalCount: 0, entities: null }));
    });
};

export const populateBranchesCompany = (branches) => dispatch => {
  const {
    auth: { branchSelected }
  } = store.getState();

  dispatch(actions.branchesCompanyFetched({ branches }));

  if (!branchSelected || !branchSelected.id) {
    dispatch(setBranchSelected(branches[0]));
  }
};

export const setBranchSelected = (branch) => dispatch => {
  dispatch(authPersistor.setBranchSelected(branch));
};

export const fetchCreateBranch = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let business_hours = [];

  params.business_hours.map(days => {
    if (days.start_at) {
      business_hours.push(days);
    }
  });

  params.business_hours = business_hours;

  return requestFromServer
    .createBranch(
      params.name,
      params.address,
      params.business_hours,
      params.max_period,
      params.phone,
      params.tags,
      params.zipcode
    ).then(response => {
      dispatch(actions.branchCreated());
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateBranch = (branchId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let business_hours = [];

  params.business_hours.map(days => {
    if (days.start_at) {
      business_hours.push(days);
    }
  });

  params.business_hours = business_hours;

  return requestFromServer
    .updateBranch(branchId, {
      name: params.name,
      address: params.address,
      business_hours: params.business_hours,
      max_period: params.max_period,
      phone: params.phone,
      tags: params.tags,
      zipcode: params.zipcode
    })
    .then(response => {
      dispatch(actions.branchUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchBranchAppIntegration = (id) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .getBranchAppIntegration(id)
      .then(response => {
        resolve(response.data?.data?.[0]);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};