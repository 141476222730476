/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Badge from '@material-ui/core/Badge';

import { useHtmlClassService } from "../../../../_metronic/layout/_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { DropdownTopbarItemToggler } from "../../../../_metronic/_partials/dropdowns";

import { fetchSchedulesNotifications } from "../_redux/scheduleActions";
import { formatLongDateTime } from "../../../components/DateUtils";

import EditEvent from './EditEvent';
import EditSchedule from './EditSchedule';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function SchedulePendingDropDown() {
  const [key, setKey] = useState("pending");
  const [editEventShow, setEditEventShow] = useState(false);
  const [editedEvent, setEditedEvent] = useState({});

  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const dispatch = useDispatch();

  const { schedules } = useSelector((state) => ({
    schedules: state.schedules,
  }), shallowEqual);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  useEffect(() => {
    requestSchedulesNotification();

    const intervalId = setInterval(() => {
      requestSchedulesNotification();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const requestSchedulesNotification = async () => {
    try {
      dispatch(fetchSchedulesNotifications());
    } catch (error) {
      console.error(error);
    }
  };

  const closeEditEvent = () => {
    {editEventShow &&
      requestSchedulesNotification();
    };

    setEditEventShow(false);
  };

  function editSlot(event) {
    setEditedEvent(event);
    setEditEventShow(true);
  }

  return (
    <>
      <EditSchedule show={editEventShow} onHide={closeEditEvent} event={{event: editedEvent}} />

      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="kt_quick_notifications_toggle"
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="user-notification-tooltip">
                Agendamentos pelo app
              </Tooltip>
            }
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
              id="kt_quick_notifications_toggle"
            >

              <Badge badgeContent={schedules.notifications.length} color="error">
                <span className="svg-icon svg-icon-xl">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Devices/iPhone-X.svg")}
                  />
                </span>
              </Badge>

              {schedules.notifications.length > 0 && <span className="pulse-ring" />}
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
          <form>
            {/** Head */}
            <div
              className="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              {/*<h4 className="d-flex flex-center rounded-top">
                <span className="text-white mt-7">Agendamentos pelo app</span>
              </h4>*/}

              <Tab.Container defaultActiveKey={key}>
                <Nav
                  as="ul"
                  className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                  onSelect={(_key) => setKey(_key)}
                >
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      eventKey="pending"
                      className={`nav-link show ${
                        key === "pending" ? "active" : ""
                      }`}
                    >
                      Pendentes
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content className="tab-content">
                  <Tab.Pane eventKey="pending" className="pt-4">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="navi navi-hover scroll my-4"
                      style={{ maxHeight: "300px", position: "relative" }}
                    >
                      {schedules.notifications.map(notification =>
                          <a className="navi-item" onClick={() => editSlot(notification)}>
                            <div className="navi-link">
                              <div className="navi-icon mr-2">
                                <i className="flaticon-clock-2 text-success"></i>
                              </div>
                              <div className="navi-text">
                                <div className="font-weight-bold">
                                  {formatLongDateTime(notification.attributes.start_at)}
                                </div>
                                <div className="text-muted">
                                  {notification.relationships.field && notification.relationships.field.attributes.name} - {notification.relationships.service && notification.relationships.service.attributes.name}
                                </div>
                              </div>
                            </div>
                          </a>
                      )}

                      {(!schedules.notifications || schedules.notifications.length == 0) && <div className="d-flex flex-center text-center text-muted min-h-200px">
                        Tudo tranquilo!
                        <br />
                        Nenhum agendamento por aqui.
                      </div>}
                    </PerfectScrollbar>
                  </Tab.Pane>

                  <Tab.Pane eventKey="active" id="topbar_notifications_events">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="navi navi-hover scroll my-4"
                      style={{ maxHeight: "300px", position: "relative" }}
                    >
                      <a href="#" className="navi-item">
                        <div className="navi-link">
                          <div className="navi-icon mr-2">
                            <i className="flaticon2-line-chart text-success"></i>
                          </div>
                          <div className="navi-text">
                            <div className="font-weight-bold">
                              New report has been received
                            </div>
                            <div className="text-muted">23 hrs ago</div>
                          </div>
                        </div>
                      </a>
                    </PerfectScrollbar>
                  </Tab.Pane>

                  <Tab.Pane eventKey="canceled" id="topbar_notifications_logs">
                    <div className="d-flex flex-center text-center text-muted min-h-200px">
                      Tudo tranquilo!
                      <br />
                      Nenhum agendamento por aqui.
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
