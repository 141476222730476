import React from "react";
import moment from 'moment/min/moment-with-locales';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';

import {
  Popover,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import StatusChip from "../../../components/StatusChip";

import Chip from '@material-ui/core/Chip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import LoopIcon from '@material-ui/icons/Loop';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import AttachMoney from '@material-ui/icons/AttachMoney';

const CustomEvent = (props) => {
  const stringToColour = function(str) {
    var hash = 0;
    for (var a = 0; a < str.length; a++) {
      hash = str.charCodeAt(a) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var b = 0; b < 3; b++) {
      var value = (hash >> (b * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  const getLevelColor = (level) => {
    if (level == 1) {
      return '#b4eead';
    }
    if (level == 2) {
      return '#8ba3e1';
    }
    if (level == 3) {
      return '#f0b64f';
    }
    if (level == 4) {
      return '#d88fe6';
    }

    return '#ccc';
  }

  const getChipStatusIcon = (name) => {
    if (name == 'canceled') {
      return <BlockIcon fontSize="small" style={{ fill: "red" }} />;
    }

    if (name == 'pending') {
      return <ErrorOutlineIcon fontSize="small" style={{ fill: "#ffcc00" }} />;
    }

    return <DoneIcon fontSize="small" style={{ fill: "#17c191" }} />;
  }

  const getDot = (event) => {
    let color = event && event.relationships && event.relationships.field && stringToColour(event.relationships.field.id);

    return <span className="dot" style={{
      height: '10px',
      width: '10px',
      backgroundColor: color ?? 'white',
      borderRadius: '50%',
      display: 'inline-block'
    }}></span>
  }

  const renderTooltip = (popper) => {
    const startAt = props.event.attributes.start_at;
    const finishAt = props.event.attributes.finish_at;
    const recurrence = props.event.attributes.recurrence;
    const modality = props.event.attributes.modality;

    return (
      <Popover id="popover-contained" {...popper}>
        <Popover.Title as="h4">{getDot(props.event)} {props.event.relationships.field.attributes.name}</Popover.Title>
        <Popover.Content>
          <p style={{fontSize: '1em'}}>Horário: {moment(startAt).format('DD/MM - HH:mm')} até {moment(finishAt).format('HH:mm')}</p>
          <p style={{fontSize: '1em'}}>Duração: {moment.utc(moment(finishAt).diff(moment(startAt))).format("HH:mm")}</p>
          {props.event.attributes.level && <p style={{fontSize: '1em'}}>Nível: {props.event.attributes.level_formatted}</p>}
          {/*<p style={{fontSize: '1em'}}>Serviço: {props.event.relationships.service.attributes.name}</p>*/}

          {props.event.relationships.schedule_spot && props.event.relationships.schedule_spot.length > 0 && <span className="font-weight-bolder text-dark">Jogadores ({props.event.relationships.schedule_spot.length}/{props.event.attributes.spots})</span>}
          {props.event.relationships.schedule_spot.map(schedule_spot => (<p className="mt-0 mb-0 font-weight-500 text-dark">
              {schedule_spot.relationships.contract.relationships.client.attributes.name}
            </p>))}

          {props.event.relationships.participants && props.event.relationships.participants.length > 0 && <>
            <p className="mt-3 mb-0 font-weight-bolder text-dark">Professores:</p>
            {props.event.relationships.participants.map(participant => <p className="mt-0 mb-0 font-weight-500 text-dark">
              {participant.attributes.name}
            </p>)}
          </>}

          {!recurrence && modality == 'reservation' && <>
            <Chip
              label="Avulso"
              style={{color: '#17c191', border: '1px solid #17c191'}}
              deleteIcon={<LoopIcon fontSize="small" style={{ fill: "#17c191" }} />}
              variant="outlined"
              size="small"
            />
          </>}

          {recurrence && modality == 'reservation' && <>
            <Chip
              label="Mensalista"
              style={{color: '#a6bf54', border: '1px solid #a6bf54'}}
              deleteIcon={<LoopIcon fontSize="small" style={{ fill: "#a6bf54" }} />}
              variant="outlined"
              size="small"
            />
          </>}

          {!recurrence && modality == 'class' && <>
            <Chip
              label="Aula avulso"
              style={{color: '#1772c1', border: '1px solid #1772c1'}}
              deleteIcon={<LoopIcon fontSize="small" style={{ fill: "#1772c1" }} />}
              variant="outlined"
              size="small"
            />
          </>}

          {recurrence && modality == 'class' && <>
            <Chip
              label="Aula mensal"
              style={{color: '#54a6bf', border: '1px solid #54a6bf'}}
              deleteIcon={<LoopIcon fontSize="small" style={{ fill: "#54a6bf" }} />}
              variant="outlined"
              size="small"
            />
          </>}
        </Popover.Content>
      </Popover>
    );
  };

  const customDiv = (<OverlayTrigger placement="auto-start" trigger={["hover", "focus"]} delay={{ show: 100, hide: 100 }} overlay={renderTooltip}>
    <div style={{ paddingTop: 1, height : '100%' }}>
      {props.event.attributes.origin == 'client' && <div class="ribbon-target" style={{backgroundColor: '#DAFB6D', color: '#1F2933', bottom: "10px", right: "-12px"}}><p style={{fontSize: '.8em', margin: 0}}>Aplicativo</p></div>}
      <Grid container justify="center">
        <Grid item xs={10}>
          <p style={{fontSize: '.8em', margin: 0}}>
            {getDot(props.event)} {props.label}

            {/*{props.event.relationships.service.attributes.type == 'single' && getChipStatusIcon(props.event.relationships.status.attributes.name)}*/}
            {props.event.attributes.recurrence && <LoopIcon fontSize="small" style={{ fill: "#a6bf54" }} />}
          </p>

          {props.event.relationships.participants && props.event.relationships.participants.length > 0 && <>
            {props.event.relationships.participants.map(participant => <p style={{fontSize: '.8em', margin: 0}}>
              {participant.attributes.name.split(' ').slice(0,1).join(' ')} {participant.attributes.name.split(' ').slice(1,2).join(' ').substring(0,1)}
            </p>)}
          </>}

          {props.event.attributes.level && <span class="label label-inline mr-1" style={{backgroundColor: getLevelColor(props.event.attributes.level)}}>{props.event.attributes.level_formatted}</span>}
          {props.event.attributes.modality == 'class' && <span class={`label label-inline mr-1 ${props.event.relationships.schedule_spot.length < props.event.attributes.spots ? `label-light-success` : `label-light-danger`}`}>
            {props.event.relationships.schedule_spot.length}/{props.event.attributes.spots}
          </span>}

          {props.event.attributes.modality == 'unavailable'  && <p style={{fontSize: '.8em', margin: 0}}>Horário bloqueado</p>}
        </Grid>
        <Grid item xs={2} style={{textAlign: 'right'}} onClick={() => props.editSlot(props)}>
          <Icon style={{ fontSize: '1em' }}>edit</Icon>
        </Grid>
      </Grid>
    </div>
  </OverlayTrigger>);

  const eventDiv = React.cloneElement(props.children.props.children, {}, customDiv);
  const wrapper = React.cloneElement(props.children, {}, eventDiv);

  return wrapper;
}
export default CustomEvent;