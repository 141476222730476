import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import SVG from "react-inlinesvg";

import { lighten, makeStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import CreateWithdrawal from "../components/CreateWithdrawal";

import { Box } from "../../../components/Box";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { formatMoney } from "../../../utils/IntegerParse";
import { formatLongDate } from "../../../components/DateUtils";
import { notifySuccess } from "../../../utils/Notify";

import { fetchWithdrawals } from "../_redux/withdrawalActions";

const useStyles = makeStyles(theme => ({
  //
}));

export const WithdrawalPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { branchRecipients, branchSelected, withdrawals } = useSelector((state) => ({
    branchRecipients: state.branchRecipients,
    branchSelected: state.auth.branchSelected,
    withdrawals: state.withdrawals,
  }), shallowEqual);

  const [toggle, setToggle] = useState(true);
  const [type, setType] = useState('payable');
  const [createEntityShow, setCreateEntityShow] = useState(false);

  const requestEntities = async () => {
    try {
      dispatch(fetchWithdrawals(branchSelected.id));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    requestEntities();
  }, []);

  useEffect(() => {
    if (withdrawals.created == true) {
      setType('withdrawals');
      notifySuccess('transferência criada com sucesso', 'withdrawals-created', 2000);
    }
  }, [withdrawals]);

  function closeCreateEntity() {
    requestEntities();
    setCreateEntityShow(false);
  }

  const getLabelStatus = (value) => {
    let label = value;

    if (value == 'prepaid') {
      label = 'Pré-pago';
    }

    if (value == 'paid') {
      label = 'Pago';
    }

    if (value == 'failed') {
      label = 'Falhou';
    }

    if (value == 'available') {
      label = 'Disponível para saque';
    }

    if (value == 'transferred') {
      label = 'Transferido';
    }

    if (value == 'waiting_funds') {
      label = 'Aguardando liberação';
    }

    return label;
  }

  const getLabelType = (value) => {
    if (value == 'payable') {
      value = 'Recebível';
    }

    if (value == 'fee_collection') {
      value = 'Cobrança de taxa';
    }

    if (value == 'refund') {
      value = 'Devolução de saldo';
    }

    if (value == 'transfer') {
      value = 'Transferência';
    }

    if (value == 'credit') {
      value = 'Recebível';
    }

    if (value == 'refund') {
      value = 'Estornado';
    }

    if (value == 'refund_reversal') {
      value = 'Estorno revertido';
    }

    if (value == 'chargeback') {
      value = 'Chargeback';
    }

    if (value == 'chargeback_refund') {
      value = 'Chargeback revertido';
    }

    return value;
  }

  return (<div className="px-lg-15">
    <div className="row">
      <div className="col">
        <Box
          className="bg-success"
          icon="Navigation/Check"
          value={withdrawals.balanceLoading ?
            <span className="ml-2 spinner spinner-white"></span> :
            (withdrawals.balance &&
             withdrawals.balance.attributes &&
             withdrawals.balance.attributes.available_amount) ? formatMoney(withdrawals.balance.attributes.available_amount, true) : `R$ 0,00`
          }
          description="Saldo"
        />
      </div>
      <div className="col">
        <Box
          className="bg-primary"
          icon="Home/Clock"
          value={withdrawals.balanceLoading ?
            <span className="ml-2 spinner spinner-white"></span> :
            (withdrawals.balance &&
             withdrawals.balance.attributes &&
             withdrawals.balance.attributes.waiting_funds_amount) ? formatMoney(withdrawals.balance.attributes.waiting_funds_amount, true) : `R$ 0,00`
          }
          description="A receber"
        />
      </div>
    </div>
    <div class="pt-3">
      <div class="text-right mb-2">
        {branchRecipients?.entity?.relationships?.status?.attributes?.name === 'active' && <a className="btn btn-light-success font-weight-bold my-1" onClick={() => setCreateEntityShow(true)}>
          <i className="ki ki-check"></i> Solicitar saque
        </a>}
        {/* <a href="#" className="btn btn-light-primary font-weight-bold ml-2 my-1" onClick={() => console.log(true)}>
          <i className="ki ki-clock"></i> Simular antecipação
        </a> */}
      </div>
      <div class="col-12 d-flex mt-10" style={{alignItems: 'center'}}>
        {/*<h3 class="card-title mb-0">Pagamentos a receber</h3>*/}
        <ul class="nav nav-pills nav-pills-sm nav-dark-75 float-right pr-0" role="tablist">
          <li class="nav-item">
            <a class={type == 'payable' ? "nav-link py-2 px-4 mx-1 my-1 active" : "nav-link py-2 px-4 mx-1 my-1 btn-light"} data-toggle="tab" onClick={() => setType('payable')}>
              <span class="nav-text font-size-sm">A receber</span>
            </a>
          </li>
          <li class="nav-item">
            <a class={type == 'operations' ? "nav-link py-2 px-4 mx-1 my-1 active" : "nav-link py-2 px-4 mx-1 my-1 btn-light"} data-toggle="tab" onClick={() => setType('operations')}>
              <span class="nav-text font-size-sm">Histórico</span>
            </a>
          </li>
          <li class="nav-item">
            <a class={type == 'withdrawals' ? "nav-link py-2 px-4 mx-1 my-1 active" : "nav-link py-2 px-4 mx-1 my-1 btn-light"} data-toggle="tab" onClick={() => setType('withdrawals')}>
              <span class="nav-text font-size-sm">Solicitações de saque</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div className="separator separator-dashed mt-1 mb-10"></div>

    {type == 'payable' && <>
      {(!withdrawals.payables || withdrawals.payables.length == 0) && <div className="row">
        <div className="w-100 text-center">
          <img class="" src={toAbsoluteUrl('/media/custom/empty-box.png')} alt="empty" style={{heigth: 150, width: 150}} onClick={() => setToggle(true)} />
          <h5 class="card-title mb-5 mt-3">Nenhum valor a ser liberado</h5>
        </div>
      </div>}

      {withdrawals.payables && withdrawals.payables.length > 0 && <div className="row mb-5" style={{overflowX: 'auto'}}>
        <Table
          key="tableWrapper"
          style={{minWidth: 750}}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              <TableCell
                key={'created_at'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Data a receber
                </span>
              </TableCell>
              <TableCell
                key={'price'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Valor
                </span>
              </TableCell>
              <TableCell
                key={'status'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Status
                </span>
              </TableCell>
              <TableCell
                key={'actions'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Tipo
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {withdrawals.payables && withdrawals.payables.map(payable => <TableRow key={payable.id}>
              <TableCell component="th" id={'1312312'} scope="row">
                {formatLongDate(payable.attributes.payment_date, true)}
              </TableCell>
              <TableCell align="left">
                {formatMoney(payable.attributes.amount, true)}
              </TableCell>
              <TableCell align="left">
                {getLabelStatus(payable.attributes.status)}
              </TableCell>
              <TableCell align="left">
                {getLabelType(payable.attributes.type)}
              </TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </div>}
    </>}

    {type == 'operations' && <>
      {(!withdrawals.operations || withdrawals.operations.length == 0) && <div className="row">
        <div className="w-100 text-center">
          <img class="" src={toAbsoluteUrl('/media/custom/empty-box.png')} alt="empty" style={{heigth: 150, width: 150}} onClick={() => setToggle(true)} />
          <h5 class="card-title mb-5 mt-3">Nenhum valor liberado para saque</h5>
        </div>
      </div>}

      {withdrawals.operations && withdrawals.operations.length > 0 && <div className="row mb-5" style={{overflowX: 'auto'}}>
        <Table
          key="tableWrapper"
          style={{minWidth: 750}}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              <TableCell
                key={'created_at'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Data
                </span>
              </TableCell>
              <TableCell
                key={'price'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Valor
                </span>
              </TableCell>
              <TableCell
                key={'status'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Status
                </span>
              </TableCell>
              <TableCell
                key={'status'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Tipo
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {withdrawals.operations && withdrawals.operations.map(operation => <TableRow key={operation.id}>
              <TableCell component="th" id={'1312312'} scope="row">
                {formatLongDate(operation.attributes.created_at, true)}
              </TableCell>
              <TableCell align="left">
                {formatMoney(operation.attributes.amount, true)}
              </TableCell>
              <TableCell align="left">
                {getLabelStatus(operation.attributes.status)}
              </TableCell>
              <TableCell align="left">
                {getLabelType(operation.attributes.type)}
              </TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </div>}
    </>}

    {type == 'withdrawals' && <>
      {(!withdrawals.withdrawals || withdrawals.withdrawals.length == 0) && <div className="row">
        <div className="w-100 text-center">
          <img class="" src={toAbsoluteUrl('/media/custom/empty-box.png')} alt="empty" style={{heigth: 150, width: 150}} onClick={() => setToggle(true)} />
          <h5 class="card-title mb-5 mt-3">Nenhum valor a ser liberado</h5>
        </div>
      </div>}

      {withdrawals.withdrawals && withdrawals.withdrawals.length > 0 && <div className="row mb-5" style={{overflowX: 'auto'}}>
        <Table
          key="tableWrapper"
          style={{minWidth: 750}}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              <TableCell
                key={'created_at'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Data
                </span>
              </TableCell>
              <TableCell
                key={'price'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Valor
                </span>
              </TableCell>
              <TableCell
                key={'status'}
                align="left"
                padding={'default'}
                sortDirection={false}
              >
                <span style={{
                  color: "#B5B5C3",
                  textTransform: "uppercase"
                }}>
                  Status
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {withdrawals.withdrawals && withdrawals.withdrawals.map(withdrawal => <TableRow key={withdrawal.id}>
              <TableCell component="th" id={'1312312'} scope="row">
                {formatLongDate(withdrawal.attributes.created_at.date, true)}
              </TableCell>
              <TableCell align="left">
                {formatMoney(withdrawal.attributes.amount, true)}
              </TableCell>
              <TableCell align="left">
                {getLabelStatus(withdrawal.attributes.status)}
              </TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </div>}
    </>}

    <CreateWithdrawal show={createEntityShow} onHide={closeCreateEntity} />
  </div>);
}
