import * as requestFromServer from "./creditCrud";
import { creditSlice, callTypes } from "./creditSlice";
import { notifyError } from "../../../utils/Notify";

const { actions } = creditSlice;

export const fetchCredits = (data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    per_page: data.perPage,
    page: data.page,
  };

  if (data.statusId) {
    params.status_id = data.statusId;
  }

  if (data.branchId) {
    params.branch_id = data.branchId;
  }

  if (data.clientId) {
    params.client_id = data.clientId;
  }

  if (data.originType) {
    params.origin_type = data.originType;
  }

  if (data.fieldId) {
    params.field_id = data.fieldId;
  }

  if (data.duration) {
    params.duration = data.duration;
  }

  return requestFromServer
    .getCredits(params)
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.creditsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.creditMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.creditsFetched({ totalCount: 0, entities: null }));

      if (error.response &&
        error.response.data &&
        error.response.data.message &&
        (typeof error.response.data.message) == "string"
      ) {
        notifyError(error.response.data.message, 'client-failed-update', 2000);
      }
    });
};

export const fetchAvailableCredits = (data) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  let params = {
    origin_type: data.originType,
    field_id: data.fieldId,
    branch_id: data.branchId,
    duration: data.duration,
  };

  if (data.date) {
    params.date = data.date;
  }

  return new Promise((resolve, reject) => {
    requestFromServer
      .getAvailableCredits(data.clientId, params)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        if (error.response &&
          error.response.data &&
          error.response.data.message &&
          (typeof error.response.data.message) == "string"
        ) {
          notifyError(error.response.data.message, 'client-failed-list', 2000);
        }

        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);

      });
    });
};

export const fetchGetClientCredits = (clientId, branchId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .getClientCredits(clientId, branchId)
      .then(response => {
        dispatch(actions.loadingFinish());
        resolve(response.data);
      })
      .catch(error => {
        dispatch(actions.catchError({ error, callType: callTypes.list }));
        reject(error);
      });
  });
};

export const fetchCredit = (creditId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCreditById(creditId)
    .then(response => {
      const credit = response.data;


      dispatch(actions.creditFetched({ credit: credit }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.creditFetched({ credit: {} }));
    });
};

export const clearCredit = () => dispatch => {
  dispatch(actions.creditFetched({ credit: {} }));
};

export const fetchCreateCredit = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return new Promise((resolve, reject) => {
    requestFromServer
      .createCredit(params).then(response => {
        dispatch(actions.creditFetched({ credit: response.data }));
        dispatch(actions.creditCreated());
        resolve(response.data);
      })
      .catch(error => {
        error.clientMessage = "Not found";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        reject(error);
      });
  });
};

export const fetchRenewCredit = (creditId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .renewCredit(creditId)
    .then(response => {
      dispatch(actions.creditRenewed());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateCreditExpiredAt = (creditId, expiredAt) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateCredit(creditId, {expired_at: expiredAt ?? null})
    .then(response => {
      dispatch(actions.creditUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDeleteCredit = (creditId, canceledAt) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deleteCredit(creditId, { canceled_at: canceledAt })
    .then(response => {
      dispatch(actions.creditUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};