import * as Yup from "yup";

export const createFieldSchema = (props) => {
  return Yup.object().shape({
    // phone: Yup.string()
    //   .required(
    //     'Telefone deve ser preenchido'
    //   ),
    // email: Yup.string()
    //   .required(
    //     'E-mail deve ser preenchido'
    //   ),
    name: Yup.string()
      .required(
        'Nome deve ser preenchido'
      ),
  });
}