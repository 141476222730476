import axios from "axios";

export const GET_WITHDRAWALS = `${process.env.REACT_APP_API_URL}/branches`;

export function getBranchRecipientBalance(branchId) {
  return axios.get(`${GET_WITHDRAWALS}/${branchId}/branch_recipient/balance`);
}

export function getBranchRecipientBalanceOperations(branchId) {
  return axios.get(`${GET_WITHDRAWALS}/${branchId}/branch_recipient/balance/operations`);
}

export function getBranchRecipientPayables(branchId) {
  return axios.get(`${GET_WITHDRAWALS}/${branchId}/branch_recipient/payables`);
}

export function getBranchRecipientWithdrawals(branchId) {
  return axios.get(`${GET_WITHDRAWALS}/${branchId}/branch_recipient/withdrawals`);
}

export function createWithdrawal(branchId, params) {
  return axios.post(`${GET_WITHDRAWALS}/${branchId}/branch_recipient/withdrawals`, params);
}

export function updateWithdrawal(branchId, params) {
  return axios.put(`${GET_WITHDRAWALS}/${branchId}/branch_recipient`, params);
}