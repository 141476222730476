import {
  parse,
  parseISO,
  format,
  isToday,
  isTomorrow,
  getDay,
  addDays,
  addMinutes,
  eachDayOfInterval,
  differenceInMinutes,
} from 'date-fns';

const getLabel = (weekday) => {
  let label = 'Domingo';

  if (weekday == 1) { label = 'Segunda'; }
  if (weekday == 2) { label = 'Terça'; }
  if (weekday == 3) { label = 'Quarta'; }
  if (weekday == 4) { label = 'Quinta'; }
  if (weekday == 5) { label = 'Sexta'; }
  if (weekday == 6) { label = 'Sábado'; }

  return label;
}
const getLabelAbbreviated = (weekday) => {
  let label = 'Dom';
  if (weekday == 1) { label = 'Seg'; }
  if (weekday == 2) { label = 'Ter'; }
  if (weekday == 3) { label = 'Qua'; }
  if (weekday == 4) { label = 'Qui'; }
  if (weekday == 5) { label = 'Sex'; }
  if (weekday == 6) { label = 'Sáb'; }

  return label;
}

const formatDate = (date, parseDate = true) => {
  if (parseDate) {
    date = parseISO(date);
  }

  if (isToday(date)) {
    return 'Hoje';
  }

  if (isTomorrow(date)) {
    return 'Amanhã';
  }

  return getLabel(getDay(date)) + ' • ' + format(date, 'dd/MM');
}

const formatLongDate = (value, parseDate = false) => {
  if (value) {
    let date = '';

    if (!parseDate) {
      date = parse(value, 'yyyy-MM-dd', new Date());
    } else {
      date = parseISO(value);
    }

    if (isToday(date)) {
      return 'Hoje';
    }

    if (isTomorrow(date)) {
      return 'Amanhã';
    }

    return format(date, 'dd/MM/yyyy');
  }
}

const formatLongDateTime = (value, parseDate = false) => {
  if (value) {
    let date = '';

    if (!parseDate) {
      date = parse(value, 'yyyy-MM-dd HH:mm:ss', new Date());
    } else {
      date = parseISO(value);
    }

    let string = format(date, 'dd/MM/yyyy');

    if (isToday(date)) {
      string = 'Hoje';
    }

    if (isTomorrow(date)) {
      string = 'Amanhã';
    }

    return string + ' • ' + format(date, 'HH:mm')
  }
}

const calculateDiffMinutes = (start, finish) => {
  let startAt = parse(start, 'yyyy-MM-dd HH:mm:ss', new Date());
  let finishAt = parse(finish, 'yyyy-MM-dd HH:mm:ss', new Date());

  return differenceInMinutes(finishAt, startAt);
}

const calculateMinutes = (value, duration) => {
  let date = parse(value, 'yyyy-MM-dd HH:mm', new Date());

  return format(addMinutes(date, duration.minutes), 'yyyy-MM-dd HH:mm');
}

const calculateDates = (days = 14 ) => {
  let dates = eachDayOfInterval({ start: new Date(), end: addDays(new Date(), days) });

  const label = [];
  const value = [];

  dates.map((day) => {
    label.push(formatDate(day, false));
    value.push(format(day, 'yyyy-MM-dd'));
  });

  return {
    label: label,
    value: value
  };
}

const dayOfWeekAsString = (weekday) => {
  let label = '';

  if (weekday == 1) { label = 'Domingo'; }
  if (weekday == 2) { label = 'Segunda'; }
  if (weekday == 4) { label = 'Terça'; }
  if (weekday == 8) { label = 'Quarta'; }
  if (weekday == 16) { label = 'Quinta'; }
  if (weekday == 32) { label = 'Sexta'; }
  if (weekday == 64) { label = 'Sábado'; }

  return label;
}


const getWeekDays = (weekdays, byLabel = false) => {
  weekdays = weekdays.map(weekday => {
    if (byLabel) {
      return getLabel(weekday);
    }

    return dayOfWeekAsString(weekday);
  });

  return weekdays.join(', ');
}

const getWeekDaysAbbreviated = (weekdays) => {
  if (weekdays.length == 7) {
    return 'Todos os dias';
  }

  weekdays = weekdays.map(weekday => {
    return getLabelAbbreviated(weekday);
  });

  return weekdays.join(', ');
}

const convertWeekday = (days) => {
  let values = [
    1,
    2,
    4,
    8,
    16,
    32,
    64,
  ];

  let data = [];

  days.map(day => {
    data.push(values[day]);
  })

  return getWeekDays(data);
}

const convertWeekdayBitMask = (days) => {
  let values = {
    1: 0,
    2: 1,
    4: 2,
    8: 3,
    16: 4,
    32: 5,
    64: 6,
  };

  let data = [];

  days.map(day => {
    data.push(values[day]);
  })

  return data;
}

const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

export {
  getLabel,
  formatDate,
  formatLongDate,
  formatLongDateTime,
  calculateDiffMinutes,
  calculateMinutes,
  calculateDates,
  dayOfWeekAsString,
  getWeekDays,
  getWeekDaysAbbreviated,
  convertWeekday,
  convertWeekdayBitMask,
  getAge,
}