import * as requestFromServer from "./tagCrud";
import { tagSlice, callTypes } from "./tagSlice";

const { actions } = tagSlice;

export const fetchTags = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTags()
    .then(response => {
      const entities = response.data;
      const totalCount = entities.length;

      dispatch(actions.tagsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.list }));

      dispatch(actions.tagsFetched({ totalCount: 0, entities: null }));
    });
};


export const fetchCreateTag = (params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .createTag(
      params.branchId,
      params.name,
      params.groundType,
      params.maxPlayers,
      params.serviceId
    ).then(response => {
      dispatch(actions.tagCreated());
    })
    .catch(error => {
      error.clientMessage = "Not found";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchUpdateTag = (tagId, params) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateTag(tagId,
      params.branchId,
      params.name,
      params.groundType,
      params.maxPlayers,
      params.serviceId
    ).then(response => {
      dispatch(actions.tagUpdated());
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};