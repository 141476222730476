import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import InputMask, { InputState } from "react-input-mask";
import { Modal, Dropdown } from "react-bootstrap";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from 'moment/min/moment-with-locales';
import _ from 'underscore';
import SVG from "react-inlinesvg";

import ListGroup from 'react-bootstrap/ListGroup';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Chip from '@material-ui/core/Chip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Alert from 'react-bootstrap/Alert';

import {
  getWeekDaysAbbreviated
} from "../../../components/DateUtils";

import {
  fetchUpdateCreditExpiredAt,
  fetchRenewCredit,
  fetchCredit,
  clearCredit,
} from "../_redux/creditActions";

import {
  fetchPrices,
  fetchCreatePrice,
  fetchRemovePrice,
} from "../../Price/_redux/priceActions";

import { fetchBranches } from "../../Branch/_redux/branchActions";

// import { createCreditSchema } from "../_schemas/createCreditSchema";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import CreatePrice from '../../Price/components/CreatePrice';
import EditPrice from '../../Price/components/EditPrice';
import CancelCredit from "./CancelCredit";
import EditClient from "../../Client/components/EditClient";
import ShowLogs from "../../Log/components/ShowLogs";

import { notifySuccess } from "../../../utils/Notify";
import DayPicker from '../../../components/DayPicker';
import { RenderError } from "../../../components/RenderError";
import { formatMoney } from "../../../utils/IntegerParse";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //
  },
}));

const EditCredit = (props) => {
  const dispatch = useDispatch();
  const { credits, branches } = useSelector((state) => ({
    credits: state.credits,
    branches: state.branches,
  }), shallowEqual);

  const classes = useStyles();

  const [editClientShow, setEditClientShow] = useState(false);
  const [editClient, setEditClient] = useState({});
  const [showCancelCredit, setShowCancelCredit] = useState(false);
  const [showEditExpiredAt, setShowEditExpiredAt] = useState(false);
  const [event, setEvent] = useState({});

  useEffect(() => {
    if (credits.updated == true) {
      close();
      setShowCancelCredit(false);
      notifySuccess('Atualizado com sucesso', 'credits-updated', 2000);
    }
  }, [credits]);

  // useEffect(() => {
  //   if (!showEditExpiredAt) {
  //     formik.setFieldValue('expired_at', '', false);
  //   }
  // }, [showEditExpiredAt]);

  useEffect(() => {
    if (credits.credit && credits.credit.id) {
      setEvent(credits.credit);

      formik.setFieldValue('expiredAt', moment(credits.credit.attributes.expired_at).format('YYYY-MM-DD'), false);
      console.log('111111111');
    }
  }, [credits.credit]);

  useEffect(() => {
    if (props.event && props.event.id && props.show) {
      setEvent(props.event);

      formik.setFieldValue('expiredAt', moment(props.event.attributes.expired_at).format('YYYY-MM-DD'), false);
      console.log(props.event);
      console.log('222222222222');
    } else {
      close();
    }
  }, [props.show]);

  const close = () => {
    props.onHide();

    setShowEditExpiredAt(false);
    setShowCancelCredit(false);

    formik.setFieldValue('expiredAt', '', false);

    setEvent({});
    dispatch(clearCredit());
  }

  const getLabelType = (value) => {
    if (value == 'branch') {
      return 'Criado pelo operador';
    }

    if (value == 'purchase') {
      return 'Pacote de crédito';
    }

    if (value == 'refund') {
      return 'Reposição';
    }

    return '';
  }

  const getLabelStatus = (status, isExpired) => {
    if (status == 'active') {
      if (isExpired) {
        return 'Expirado';
      }

      return 'Disponível para uso';
    }

    if (status == 'expired') {
      return 'Expirado';
    }

    if (status == 'paid') {
      return 'Já utilizado';
    }

    return '';
  }

  const getColorStatus = (status, isExpired) => {
    if (status == 'active') {
      if (isExpired) {
        return 'warning';
      }

      return 'primary';
    }

    if (status == 'paid') {
      return 'success';
    }

    if (status == 'expired') {
      return 'warning';
    }

    return 'primary';
  }

  const openEditClient = (event) => {
    setEditClient(event);

    setEditClientShow(true);
  }

  const closeEditClient = () => {
    setEditClientShow(false);
  }

  const dispatchUpdateExpiredAt = () => {
    dispatch(fetchUpdateCreditExpiredAt(event.id, formik.values.expiredAt));
  }

  const formik = useFormik({
    initialValues: {
      expiredAt: '',
    },
    // validationSchema: createCreditSchema,
    onSubmit: (values, { setStatus, setErrors, setSubmitting }) => {
      setTimeout(() => {
        // dispatch(fetchUpdateCredit(event.id, values));

        setSubmitting(false);
      }, 500);
    },
  });

  const renew = [
    { label: 'Sim', value: 'yes' },
    { label: 'Não', value: 'no' },
  ];

  const headRows = [
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Mês' },
    { id: 'attributes.name', numeric: false, disablePadding: true, label: 'Vencimento' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Valor' },
    { id: 'attributes.price', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'actions', numeric: false, disablePadding: false, label: '' },
  ];

  return (<>
    {event.id && <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={true}
      show={props.show && !showCancelCredit && !editClientShow}
    >
      <form className="form form-label-right" autoComplete="off" onSubmit={formik.handleSubmit} variant="outlined">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editar crédito
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7 mb-5"></div>

          <Container maxWidth="sm">
            <Grid container>
              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Nome</span>: {event.relationships.client.attributes.name}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">E-mail</span>: {event.relationships.client.attributes.email}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Telefone</span>: {event.relationships.client.attributes.phone}
                    </span>
                  </div>
                </div>
              </Grid>

              {!props.hideClient && <Grid item xs={12}>
                <a class="btn btn-primary font-weight-bolder font-size-sm mr-2 mt-5" onClick={() => openEditClient(event.relationships.client)}>
                  Ver cliente
                </a>

                {/*{event.relationships.status.attributes.name == 'active' && !event.attributes.is_expired && <a class="btn btn-light-warning font-weight-bolder font-size-sm mr-2 mt-5" onClick={() => console.log('event')}>
                  Transferir crédito
                </a>}*/}
              </Grid>}
            </Grid>
          </Container>

          <Container maxWidth="sm">
            <Grid container className="mt-5">
              <Grid item xs={12}>
                <span className="font-weight-bolder text-dark">Informações</span>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7"></div>

          <Container maxWidth="sm">
            <Grid container className={classes.label}>
              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Tipo</span>
                      : {event.relationships.origin.type == 'plan' && event.relationships.origin.attributes.type == 'day_use' && `Day use`}
                        {event.relationships.origin.type == 'service' && event.relationships.origin.attributes.modality == 'class' && `Aula`}
                        {event.relationships.origin.type == 'service' && event.relationships.origin.attributes.modality == 'reservation' && `Locação`}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Serviço</span>: {event.relationships.origin.attributes.name}

                      {event.attributes.duration && <>
                        (<span className="font-weight-light text-dark-50">{event.attributes.duration}min</span>)
                      </>}
                    </span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Origem</span>: {getLabelType(event.attributes.type)}
                    </span>
                  </div>
                </div>
              </Grid>

              {event.attributes.weekdays?.length > 0 && <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Dias da semana</span>: {getWeekDaysAbbreviated(event.attributes.weekdays)}
                    </span>
                  </div>
                </div>
              </Grid>}

              {event.attributes.start_at && event.attributes.end_at && <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Limite de horário</span>: {event.attributes.start_at} até {event.attributes.end_at}
                    </span>
                  </div>
                </div>
              </Grid>}

              {/*<TableCell align="left">
                  {row.attributes.weekdays && <>
                    {getWeekDays(row.attributes.weekdays, true)}
                  </>}
                  {row.attributes.start_at && <span style={{
                    color: "#B5B5C3",
                    textTransform: "uppercase"
                  }}>
                    <br />{row.attributes.start_at} até {row.attributes.end_at}
                  </span>}
                </TableCell>*/}

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block">
                    <span className="text-dark">
                      <span className="font-weight-bolder text-dark-50">Vencimento</span>: {event.attributes.expired_at && <span className={`label label-lg label-light label-inline`}>
                          {moment(event.attributes.expired_at).format('DD/MM/YYYY')}
                        </span>}
                        {!event.attributes.expired_at && `Sem validade`}
                    </span>
                    {event.relationships.status.attributes.name == 'active' && !event.attributes.is_expired && !showEditExpiredAt && <span class="cursor-pointer ml-2" onClick={() => setShowEditExpiredAt(!showEditExpiredAt)}>
                      <i class="fas fa-edit icon-nm text-dark-50"></i>
                    </span>}
                  </div>
                </div>
              </Grid>

              {showEditExpiredAt && <>
                <Grid item xs={6} className="pt-3">
                  <TextField
                    name="expiredAt"
                    label={'Data de vencimento'}
                    type="date"
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...formik.getFieldProps("expiredAt")}
                  />
                </Grid>
                <Grid item xs={6} className="pt-3">
                  <div class="pl-3 py-1 flex-column align-items-center">
                    <span class="label label-xl font-weight-boldest label-success mt-5 cursor-pointer" onClick={() => dispatchUpdateExpiredAt()}>
                      <i class="ki ki-check text-white"></i>
                    </span>
                    <span class="label label-xl font-weight-boldest label-danger mt-5 ml-2 cursor-pointer" onClick={() => setShowEditExpiredAt(false)}>
                      <i class="ki ki-close text-white"></i>
                    </span>
                  </div>
                </Grid>

                {credits.error && <RenderError error={credits.error.expired_at} />}
              </>}

              <Grid item xs={12}>
                <div className="fv-plugins-message-container">
                  <div className="fv-block pt-3">
                    <span className="text-dark">
                      <span className={`label label-lg label-light-${getColorStatus(event.relationships.status.attributes.name, event.attributes.is_expired)} label-inline`} style={{minWidth: 'max-content'}}>
                        {getLabelStatus(event.relationships.status.attributes.name, event.attributes.is_expired)}
                      </span>
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>

          <div className="separator separator-dashed mx-7"></div>

          <Container maxWidth="sm">
            <ShowLogs show={true} data={{origin_id: event.id, type: "credits"}} />
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Grid container spacing={3}>
            <Grid item xs={2} />
            <Grid item xs={8} style={{textAlign: 'center'}}>
              <Button size="large" onClick={close}>
                Fechar
              </Button>
            </Grid>
            <Grid item xs={2} style={{textAlign: 'right'}}>
              {!props.readOnly && event && event.relationships && event.relationships.status.attributes.name == 'active' && !event.attributes.is_expired && <Tooltip title="Expirar crédito" placement="top">
                <IconButton variant="outlined" aria-label="delete" onClick={() => setShowCancelCredit(true)} disableFocusRipple={true}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
            </Grid>
          </Grid>
        </Modal.Footer>
      </form>
    </Modal>}

    <CancelCredit show={showCancelCredit} onHide={() => setShowCancelCredit(false)} event={event} />
    <EditClient show={editClientShow} onHide={closeEditClient} event={editClient} />
  </>);
};

export default EditCredit;