import React, { useEffect, useState } from "react";
import moment from 'moment/min/moment-with-locales';
import { Nav, Tab } from "react-bootstrap";
import SVG from "react-inlinesvg";

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

import DayPicker from '../../../components/DayPicker';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const CustomToolbar = (toolbar) => {
  const classes = useStyles();

  const [values, setValues] = useState({
    view: 'day',
    start: null,
    end: null,
  });

  const [key, setKey] = useState('');
  const [activeTab, setActiveTab] = useState('');

  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  const onDateChange = (date) => {
    toolbar.onNavigate('date', date);
  }

  /*const label = () => {
    const date = moment(toolbar.date);
    return (
      <span>
        {toolbar.view === 'week' && moment(date).locale('pt-br').format('MMMM YYYY')}
        {toolbar.view === 'day' && moment(date).locale('pt-br').format('DD/MM/YYYY')}
      </span>
    );
  };*/

  useEffect(() => {
    toolbar.onNavigate('TODAY');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.start && values.end) {
      toolbar.onChange(values);
    }
  }, [values]);

  useEffect(() => {
    setValues(oldValues => ({
      ...oldValues,
      start: toolbar.start,
    }));
  }, [toolbar.start]);

  useEffect(() => {
    setValues(oldValues => ({
      ...oldValues,
      end: toolbar.end,
    }));
  }, [toolbar.end]);

  useEffect(() => {
    setValues(oldValues => ({
      ...oldValues,
      view: toolbar.view,
    }));
  }, [toolbar.view]);

  function handleViewChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));

    toolbar.onView(event.target.value);
    toolbar.onChange(values);
  };

  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }

  return (
    <div className="toolbar-container">
      <Box component="span" m={5}>
        <Grid container spacing={1} m={1}>
          <Grid item xs={12} md={4}>
            <a onClick={goToBack} className="btn btn-outline-secondary btn-icon ">
              <span className="svg-icon svg-icon-md">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-left.svg"
                  )}
                ></SVG>
              </span>
            </a>

            <a onClick={goToNext} className="btn btn-outline-secondary btn-icon mx-5">
              <span className="svg-icon svg-icon-md">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-right.svg"
                  )}
                ></SVG>
              </span>
            </a>

            <a onClick={goToCurrent} className="btn btn-outline-secondary">
              <i className="flaticon2-crisp-icons-1" /> {values.view == 'day' ? `Hoje` : `Semana atual`}
            </a>

            {<DayPicker date={toolbar.date} onDateChange={onDateChange} />}
          </Grid>
          <Grid item xs={12} md={4}>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-view">
                Visão
              </InputLabel>
              <Select
                value={values.view}
                onChange={handleViewChange}
                input={<OutlinedInput labelWidth={100} name="view" id="outlined-view" />}
              >
                <MenuItem value={'week'}>Semanal</MenuItem>
                <MenuItem value={'day'}>Dia</MenuItem>
              </Select>
            </FormControl>

              {/*<div className="card-toolbar">
                <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                  <li className="nav-item">
                    <a
                      className={`nav-link py-2 px-4 active`}
                      data-toggle="tab"
                      // href={`#${tabs.tab1}`}
                      // onClick={() => setActiveTab(tabs.tab1)}
                    >
                      Month
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link py-2 px-4`}
                      data-toggle="tab"
                      // href={`#${tabs.tab2}`}
                      // onClick={() => setActiveTab(tabs.tab2)}
                    >
                      Week
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link py-2 px-4`}
                      data-toggle="tab"
                      // href={`#${tabs.tab3}`}
                      // onClick={() => setActiveTab(tabs.tab3)}
                    >
                      Day
                    </a>
                  </li>
                </ul>
              </div>*/}
          </Grid>

          <Grid item xs={12} md={1}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-start">
                Inicio
              </InputLabel>
              <Select
                value={(values.start ? values.start : 0)}
                input={<OutlinedInput labelWidth={100} name="start" id="outlined-start" />}
                onChange={handleChange}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={13}>13</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={17}>17</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={19}>19</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={21}>21</MenuItem>
                <MenuItem value={22}>22</MenuItem>
                <MenuItem value={23}>23</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={1}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-end">
                Fim
              </InputLabel>
              <Select
                value={(values.end ? values.end : 23)}
                input={<OutlinedInput labelWidth={100} name="end" id="outlined-end" />}
                onChange={handleChange}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={13}>13</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={17}>17</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={19}>19</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={21}>21</MenuItem>
                <MenuItem value={22}>22</MenuItem>
                <MenuItem value={23}>23</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </div >
  );
};

export default CustomToolbar;